<template>
  <div class="space-y-8">
    <div
      v-if="!user.is_recruiter"
      class="flex items-start gap-2 rounded-lg bg-secondary/5 p-4"
    >
      <Icon class="mt-0.5 text-xl text-zinc-800" name="info" />

      <div>
        <span class="block font-medium text-zinc-800">
          {{ __('Group Invite Limit') }}
        </span>
        <span class="block text-sm text-zinc-600">
          {{ __('You can invite up to 20 friends per group.') }}
        </span>
      </div>
    </div>

    <form
      v-if="renderInviteForm"
      class="space-y-4"
      @submit.prevent="handleSubmit"
    >
      <Combobox by="email" multiple :model-value="values">
        <div class="relative">
          <ComboboxLabel
            :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
          >
            {{ __('Invite friends') }}
          </ComboboxLabel>

          <ComboboxButton
            id="combobox-button"
            :class="`${errors.length ? 'ring-error' : 'ring-gray-200'}  flex w-full cursor-default items-center justify-between gap-2 overflow-hidden rounded-md bg-white px-3.5 py-2.5 shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary`"
          >
            <Icon class="text-lg text-neutral-400" name="search" is-filled />

            <ComboboxInput
              :placeholder="
                __(
                  'Invite people to join the group. You can send bulk invite by using comma between emails.'
                )
              "
              class="w-full truncate border-none bg-transparent p-0 text-zinc-800 placeholder:text-zinc-500 focus:ring-0"
              @input="handleInput"
            />

            <Icon class="text-xl text-zinc-600" name="expand_more" />
          </ComboboxButton>

          <TransitionRoot
            leave-from="opacity-100"
            leave-to="opacity-0"
            @after-leave="handleAfterLeave"
          >
            <ComboboxOptions
              v-if="query.length"
              class="shadow-card absolute z-10 mt-1 max-h-60 w-max min-w-full max-w-full overflow-y-auto rounded-md border border-gray-200 bg-white p-1 text-sm leading-normal text-zinc-800 focus:outline-none"
            >
              <li
                class="cursor-default select-none px-3 py-1.5 transition-colors hover:bg-zinc-50"
                @click="handleCustomOptionClick"
              >
                <span class="block">
                  {{ __('Click here to add the following users') }}
                </span>
                <span class="block text-xs text-zinc-600">
                  {{ query }}
                </span>
              </li>
            </ComboboxOptions>
          </TransitionRoot>

          <div v-if="values.length" class="mt-2 flex flex-wrap gap-1">
            <button
              v-for="value in values"
              :key="value"
              :aria-label="__('Clear')"
              type="button"
              class="flex items-center gap-1 rounded-lg border border-secondary px-2 py-1 text-sm text-zinc-800 transition-colors hover:bg-secondary/5"
              @click="handleClearButtonClick(value)"
            >
              <span>{{ value.email }}</span>

              <Icon class="text-error" name="close" />
            </button>
          </div>

          <div v-if="errors.length" class="mt-2 space-y-1">
            <span
              v-for="(error, index) in errors"
              :key="index"
              class="block text-xs text-error"
            >
              {{ error }}
            </span>
          </div>
        </div>
      </Combobox>

      <Button
        class="mx-auto flex items-center justify-center gap-1.5"
        :type="isLoadingSubmit ? 'button' : 'submit'"
        :disabled="isLoadingSubmit"
      >
        <Icon
          v-if="isLoadingSubmit"
          class="animate-spin text-lg"
          name="progress_activity"
        />
        <span>{{ __(isLoadingSubmit ? 'Loading...' : 'Invite') }}</span>
      </Button>
    </form>

    <div class="space-y-4">
      <div class="shadow-element overflow-auto rounded-md">
        <table class="w-max min-w-full">
          <thead>
            <tr>
              <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                {{ __('Name') }}
              </th>
              <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                {{ __('Invitation sent on') }}
              </th>
              <th class="px-8 py-4">
                <div class="flex items-center gap-1">
                  <span class="font-medium leading-normal text-zinc-600">
                    {{ __('Action') }}
                  </span>

                  <Popover position="right">
                    <template #trigger>
                      <Button variant="icon" tabindex="-1">
                        <Icon class="text-xl" name="info" />
                      </Button>
                    </template>
                    <template #content>
                      <div class="p-4">
                        <span class="block text-sm text-zinc-800">
                          {{
                            __(
                              'Invites can be sent to each user once every 7 days.'
                            )
                          }}
                        </span>
                      </div>
                    </template>
                  </Popover>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading" class="border-t">
              <td :colSpan="2" class="px-8 py-4">
                <span class="text-sm text-zinc-600">
                  {{ __('Loading...') }}
                </span>
              </td>
            </tr>

            <tr v-else-if="!invitations.length" class="border-t">
              <td :colSpan="2" class="px-8 py-4">
                <span class="text-sm text-zinc-600">
                  {{ __('No pending invitations') }}
                </span>
              </td>
            </tr>

            <template v-else>
              <tr
                v-for="invitation in invitations"
                :key="invitation.id"
                :class="`${user && invitation.id === user.id ? 'bg-violet-50' : 'bg-white'} border-y last:border-b-0`"
              >
                <td class="px-8 py-4">
                  <div class="flex items-center gap-4">
                    <img
                      class="h-12 w-12 rounded-full border border-zinc-200"
                      :src="
                        invitation.profile_photo
                          ? `/uploads/${invitation.profile_photo}`
                          : placeholderImage
                      "
                      :alt="__('Profile picture')"
                    />

                    <div class="space-y-0.5">
                      <span class="block text-sm font-medium text-zinc-800">
                        {{ invitation.name }}
                      </span>
                      <span class="block text-sm text-zinc-600">
                        {{ invitation.email }}
                      </span>
                    </div>
                  </div>
                </td>

                <td class="px-8 py-4">
                  <span class="text-sm text-zinc-600">
                    {{ formatDateTime(invitation.invited_on) }}
                  </span>
                </td>

                <td class="px-8 py-4">
                  <Button :disabled="!invitation.canInviteAgain">
                    <span>{{ __('Invite again') }}</span>
                  </Button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div
        v-if="!isPreviousButtonDisabled || !isNextButtonDisabled"
        class="flex justify-center gap-2"
      >
        <button
          class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
          :disabled="isPreviousButtonDisabled"
          :aria-label="__('Previous')"
          @click="handlePreviousButtonClick"
        >
          <Icon class="text-xl text-primary" name="arrow_back" />
        </button>

        <button
          class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
          :disabled="isNextButtonDisabled"
          :aria-label="__('Next')"
          @click="handleNextButtonClick"
        >
          <Icon class="text-xl text-primary" name="arrow_forward" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { formatDateTime } from '@/scripts/date.script';
  import { isValidEmail } from '@/scripts/validate.script';
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOptions,
    TransitionRoot
  } from '@headlessui/vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Popover } from '@/components/block/popover';

  const props = defineProps({
    group: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const placeholderImage = '/images/logo_placeholder.png';

  const isPreviousButtonDisabled = computed(() => {
    return page.value <= 1 || isLoading.value;
  });

  const isNextButtonDisabled = computed(() => {
    return page.value >= totalPages.value || isLoading.value;
  });

  const renderInviteForm = computed(() => {
    return props.user.can_invite;
  });

  const values = ref([]);
  const errors = ref([]);
  const isLoadingSubmit = ref(false);
  const query = ref('');
  const invitations = ref([]);
  const isLoading = ref(false);
  const page = ref(1);
  const totalPages = ref(0);

  function handleAfterLeave() {
    query.value = '';
  }

  async function handleInput(event) {
    query.value = event.target.value;
  }

  async function handleCustomOptionClick() {
    const element = document.getElementById('combobox-button');

    if (element) {
      element.click();
    }

    errors.value = [];

    const emails = query.value
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email)
      .filter((email, index, self) => self.indexOf(email) === index);

    emails
      .filter((email) => !isValidEmail(email))
      .forEach((email) => {
        errors.value = [
          ...errors.value,
          `${email} ${__('is not a valid email address')}`
        ];
      });

    emails
      .filter((email) =>
        values.value.map((value) => value.email).includes(email)
      )
      .forEach((email) => {
        errors.value = [
          ...errors.value,
          `${email} ${__('has already been added to this list')}`
        ];
      });

    const validEmails = emails.filter(
      (email) =>
        isValidEmail(email) &&
        !values.value.map((value) => value.email).includes(email)
    );

    validEmails.forEach((email) => {
      values.value = [...values.value, { id: null, email }];
    });
  }

  function handleClearButtonClick(option) {
    values.value = values.value.filter((value) => value.email !== option.email);
    errors.value = [];
  }

  async function handleSubmit() {
    if (!values.value.length) {
      return;
    }

    errors.value = [];

    isLoadingSubmit.value = true;

    try {
      const response = await axios.post(
        route('redesign.groups.inviteFriends', {
          slug: props.group.details.slug
        }),
        { data: values.value }
      );

      if (response?.data?.errors?.length) {
        errors.value = response?.data?.errors;
      } else if (response?.data?.message) {
        toast.success(response.data.message);
      }

      values.value = [];

      if (page.value !== 1) {
        page.value = 1;
      }

      await fetchInvitations(page.value);
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingSubmit.value = false;
  }

  async function handlePreviousButtonClick() {
    page.value--;

    await fetchInvitations(page.value);
  }

  async function handleNextButtonClick() {
    page.value++;

    await fetchInvitations(page.value);
  }

  async function fetchInvitations(page) {
    const limit = 10;
    const offset = (page - 1) * limit;

    isLoading.value = true;

    try {
      const response = await axios.get(
        route('redesign.groups.paginateGuests', {
          slug: props.group.details.slug
        }),
        { params: { offset, limit } }
      );

      invitations.value = response.data.guests;
      totalPages.value = Math.ceil(response.data.total / limit);
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }

  onMounted(async () => {
    await Promise.all([fetchInvitations(page.value)]);
  });
</script>
