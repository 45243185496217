<template>
  <div
    v-if="value"
    class="relative w-full overflow-hidden rounded border border-dashed border-primary"
  >
    <img
      class="h-full w-full"
      :src="
        value.includes('data') || value.includes('/uploads/')
          ? value
          : `/uploads/${value}`
      "
      :alt="__('Cover')"
    />

    <button
      class="absolute inset-0 flex items-center justify-center bg-zinc-800/75 opacity-0 transition-opacity hover:opacity-100 focus:opacity-100"
      type="button"
      :aria-label="__('Delete')"
      @click="handleDeleteButtonClick"
    >
      <Icon class="text-xl text-white" name="delete" />
    </button>
  </div>

  <div v-else>
    <label
      :class="`${isDragging ? 'bg-primary/5' : 'bg-zinc-50'} flex h-40 w-full cursor-pointer flex-col items-center justify-center rounded border border-dashed border-primary p-4 transition-colors`"
      for="cover"
      @drop.prevent="handleDrop"
      @dragover.prevent="handleDragOver"
      @dragleave.prevent="handleDragLeave"
    >
      <Icon class="text-2xl text-primary" name="backup" is-filled />

      <span class="mt-1 block text-center text-sm font-medium text-primary">
        {{ `${__('Upload cover')}*` }}
      </span>
      <span
        class="mt-2.5 block text-center text-xs leading-normal text-zinc-600"
      >
        {{ __('Click to upload or drag and drop') }}
      </span>
      <span class="mt-1 block text-center text-xs leading-normal text-zinc-600">
        {{ `JPG, JPEG ${__('or')} PNG (max. 2 MB)` }}
      </span>
    </label>

    <input
      id="cover"
      class="sr-only"
      type="file"
      accept=".jpg,.jpeg,.png"
      @change="handleInputChange"
    />

    <ImageCropper
      v-model:open="isCropperOpen"
      :value="base64"
      :aspect-ratio="4 / 1"
      @change="handleCropperChange"
    />

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';
  import { ImageCropper } from '@/components/other/image-cropper';

  const props = defineProps({
    value: {
      type: [String, null],
      required: true
    },
    file: {
      type: [File, null],
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:value', 'update:file', 'update:errors']);

  const maxFileSize = 2;

  const base64 = ref('');
  const isCropperOpen = ref(false);
  const isDragging = ref(false);

  function readFiles(files) {
    if (!files || !files.length) {
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      base64.value = reader.result;
      isCropperOpen.value = true;
    };

    reader.readAsDataURL(files[0]);
  }

  function handleInputChange(event) {
    readFiles(event.target.files);
    event.target.value = '';
  }

  function handleDrop(event) {
    readFiles(event.dataTransfer.files);

    isDragging.value = false;
  }

  function handleDragOver() {
    isDragging.value = true;
  }

  function handleDragLeave() {
    isDragging.value = false;
  }

  function handleDeleteButtonClick() {
    emits('update:value', null);
    emits('update:file', null);
    emits('update:errors', []);
  }

  function handleCropperChange(base64, file) {
    if (file.size > maxFileSize * 1024 * 1024) {
      emits('update:errors', [
        `${__('File size exceeds limit. Please upload a file smaller than')} ${maxFileSize} MB.`
      ]);
      return;
    }

    emits('update:value', base64);
    emits('update:file', file);
    emits('update:errors', []);
  }
</script>
