<template>
  <a :href="groupUrl" class="flex flex-wrap gap-x-8 gap-y-4 sm:flex-nowrap">
    <img
      :src="preview"
      :alt="__('Preview photo')"
      class="w-full rounded sm:h-56 sm:w-auto"
    />

    <div class="space-y-4">
      <div class="space-y-2">
        <span class="block text-2xl font-medium text-zinc-800">{{ name }}</span>

        <div class="flex items-center gap-1.5">
          <Icon name="group" is-filled class="text-lg text-zinc-600" />
          <span class="text-sm text-zinc-600">
            {{ `${totalMembers} ${__('members')}` }}
          </span>
        </div>
      </div>

      <div v-if="hasJoinedGroup" class="space-y-4">
        <div class="flex items-center gap-1.5">
          <Icon name="beenhere" is-filled class="text-xl text-success" />
          <span class="text-sm font-medium text-zinc-600">
            {{ __('You are part of this group') }}
          </span>
        </div>

        <Button
          v-if="canLeaveGroup"
          class="flex items-center justify-center gap-1.5"
          :disabled="isLoadingLeave"
          @click.prevent="handleLeaveButtonClick"
        >
          <Icon
            v-if="isLoadingLeave"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __('Leave group') }}</span>
        </Button>
      </div>

      <Button
        v-else-if="canJoinGroup"
        class="flex items-center justify-center gap-1.5"
        :disabled="isLoadingJoin"
        @click.prevent="handleJoinButtonClick"
      >
        <Icon
          v-if="isLoadingJoin"
          class="animate-spin text-lg"
          name="progress_activity"
        />
        <span>{{ __('Join group') }}</span>
      </Button>
    </div>
  </a>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    group: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const groupUrl = computed(() => {
    return route('redesign.groups.view', { slug: group.value.slug });
  });

  const preview = computed(() => {
    return group.value.photo.includes('/uploads/')
      ? group.value.photo
      : `/uploads/${group.value.photo}`;
  });

  const name = computed(() => {
    return group.value.name;
  });

  const canJoinGroup = computed(() => {
    return group.value.canJoinGroup;
  });

  const canLeaveGroup = computed(() => {
    return group.value.canLeaveGroup;
  });

  const hasJoinedGroup = computed(() => {
    return group.value.hasJoinedGroup;
  });

  const group = ref(JSON.parse(JSON.stringify(props.group)));
  const isLoadingJoin = ref(false);
  const isLoadingLeave = ref(false);
  const totalMembers = ref(0);

  async function handleJoinButtonClick() {
    isLoadingJoin.value = true;

    try {
      const response = await axios.post(
        route('redesign.groups.joinGroup', { slug: group.value.slug })
      );

      if (response?.data?.redirect_url) {
        window.location.href = response.data.redirect_url;
        return;
      }

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      group.value.hasJoinedGroup = true;
      group.value.canLeaveGroup = true;
      totalMembers.value++;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingJoin.value = false;
  }

  async function handleLeaveButtonClick() {
    isLoadingLeave.value = true;

    try {
      const response = await axios.post(
        route('redesign.groups.exitGroup', { slug: group.value.slug })
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      group.value.hasJoinedGroup = false;
      totalMembers.value--;
    } catch (error) {
      console.log(error);
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingLeave.value = false;
  }

  onMounted(async () => {
    try {
      const response = await axios.get(
        route('redesign.groups.paginateMembers', {
          slug: group.value.slug
        }),
        { params: { offset: 0, limit: 10 } }
      );

      totalMembers.value = response.data.total;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }
  });
</script>
