<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Password')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="lock" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
      <form @submit.prevent="handleSubmit">
        <div class="grid gap-4 md:grid-cols-2">
          <InputPassword
            id="password_current"
            v-model:value="values.password_current"
            v-model:errors="errors.password_current"
            :label="__('Current password')"
            :placeholder="__('********')"
            required
          />

          <InputPassword
            id="password"
            v-model:value="values.password"
            v-model:errors="errors.password"
            :label="__('New password')"
            :placeholder="__('********')"
            required
          />

          <InputPassword
            id="password_confirmation"
            v-model:value="values.password_confirmation"
            v-model:errors="errors.password_confirmation"
            :label="__('Confirm new password')"
            :placeholder="__('********')"
            required
            class="md:col-start-2"
          />
        </div>

        <div
          class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
        >
          <Button
            class="flex items-center justify-center gap-1.5"
            :type="isLoading ? 'button' : 'submit'"
            :disabled="isLoading"
          >
            <Icon
              v-if="isLoading"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span>{{ __('Save') }}</span>
          </Button>
          <!--            <Button variant="ghost">-->
          <!--              <span>{{ __('Cancel') }}</span>-->
          <!--            </Button>-->
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputPassword } from '@/components/form/input-password';
  import { SectionHeader } from '@/pages/components/section-header';

  const toast = useToast();

  const values = ref({
    password_current: '',
    password: '',
    password_confirmation: ''
  });
  const errors = ref({
    password_current: [],
    password: [],
    password_confirmation: []
  });
  const isLoading = ref(false);

  async function handleSubmit() {
    const requiredFieldKeys = [
      'password_current',
      'password',
      'password_confirmation'
    ];
    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (values.value.password.length && values.value.password.length < 12) {
      errors.value.password = [
        __('Your password must be at least 12 characters long')
      ];
    }

    if (values.value.password !== values.value.password_confirmation) {
      errors.value.password_confirmation = [
        __('Your new password must match the confirmation password')
      ];
    }

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.candidate.settings.updatePassword'),
        values.value
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else if (error?.response?.data?.message) {
        toast.error(__(error?.response?.data?.message))
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
