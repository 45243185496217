<template>
  <AlertModal
    open
    :title="title"
    :description="description"
    :is-loading="isLoading"
    @cancel="handleCancelButtonClick"
    @continue="handleContinueButtonClick"
  />
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { AlertModal } from '@/components/other/alert-modal';

  const props = defineProps({
    group: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['cancel', 'continue']);

  const toast = useToast();

  const status = computed(() => {
    return props.group.active === 1 || props.group.details?.active === 1
      ? true
      : false;
  });

  const title = computed(() => {
    return __(
      status.value
        ? 'Are you sure you want to deactivate this group?'
        : 'Are you sure you want to activate this group?'
    );
  });

  const description = computed(() => {
    return __(
      status.value
        ? 'All group members will be notified via email that the group is no longer active.'
        : 'All group members will be notified via email that the group is now active.'
    );
  });

  const isLoading = ref(false);

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleContinueButtonClick() {
    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.company.groups.changeActiveStatus', {
          id: props.group.id
        })
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('continue', status.value ? 0 : 1);
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }
</script>
