<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Education')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="school" is-filled />
      </template>
      <template v-if="canEdit" #button>
        <div class="ml-auto">
          <Button
            class="flex items-center gap-1.5"
            variant="ghost"
            @click="handleAddButtonClick"
          >
            <Icon class="text-lg" name="add_box" is-filled />
            <span>{{ __('Add education') }}</span>
          </Button>
        </div>
      </template>
    </SectionHeader>

    <div
      class="shadow-card relative overflow-hidden rounded-2xl bg-white p-6 md:p-8"
    >
      <div
        v-if="progress"
        class="absolute right-0 top-0 rounded-l-full bg-secondary px-3 py-1"
      >
        <span class="block text-xs leading-none text-white">
          {{ progress }}
        </span>
      </div>

      <EmptySection v-if="!educations.length" :title="__('Add your education')">
        <template #icon>
          <Icon class="text-xl text-zinc-200" name="school" is-filled />
        </template>
      </EmptySection>

      <div v-else class="space-y-8">
        <Education
          v-for="education in isListExpanded
            ? educations
            : educations.slice(0, 3)"
          :key="education.id"
          :education="education"
          :can-edit="canEdit"
          @edit="handleEditButtonClick(education)"
          @delete="handleDeleteButtonClick(education)"
        />

        <Button
          v-if="educations.length > 3"
          class="mx-auto"
          variant="outline"
          @click="handleExpandListButtonClick"
        >
          {{ __(isListExpanded ? 'See less' : 'See more') }}
        </Button>
      </div>

      <EducationFormModal
        v-if="isFormModalOpen"
        :education="selected"
        :options="options"
        @cancel="handleCancelButtonClick"
        @save="handleSaveButtonClick"
      />

      <EducationDeleteModal
        v-if="isDeleteModalOpen"
        :education="selected"
        @cancel="handleCancelButtonClick"
        @continue="handleContinueButtonClick"
      />
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { EmptySection } from '@/pages/components/empty-section';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Education } from './components/education';
  import { EducationDeleteModal } from './components/education-delete-modal';
  import { EducationFormModal } from './components/education-form-modal';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['update-is-editing', 'update-percentages']);

  const progress = computed(() => {
    return fields.value.total
      ? `${fields.value.completed}/${fields.value.total}`
      : '';
  });

  const fields = ref(JSON.parse(JSON.stringify(props.fields)));
  const educations = ref(JSON.parse(JSON.stringify(props.data)));
  const selected = ref(null);
  const isFormModalOpen = ref(false);
  const isDeleteModalOpen = ref(false);
  const pendingAction = ref('');
  const isListExpanded = ref(false);

  function handleExpandListButtonClick() {
    isListExpanded.value = !isListExpanded.value;
  }

  function handleAddButtonClick() {
    selected.value = null;
    pendingAction.value = 'add';

    emits('update-is-editing', true);
  }

  function handleEditButtonClick(education) {
    selected.value = JSON.parse(JSON.stringify(education));
    pendingAction.value = 'edit';

    emits('update-is-editing', true);
  }

  function handleDeleteButtonClick(education) {
    selected.value = JSON.parse(JSON.stringify(education));
    pendingAction.value = 'delete';

    emits('update-is-editing', true);
  }

  function handleCancelButtonClick() {
    emits('update-is-editing', false);
  }

  function handleSaveButtonClick(data) {
    const existingEducation = educations.value.find(
      (education) => education.id === data.education.id
    );

    if (existingEducation) {
      educations.value = educations.value.map((education) => {
        if (education.id === data.education.id) {
          return data.education;
        }

        return education;
      });
    } else {
      educations.value = [data.education, ...educations.value];
    }

    fields.value = data.fields;

    emits('update-is-editing', false);
    emits('update-percentages', {
      sectionPercentage: data.sectionPercentage,
      resumePercentage: data.resumePercentage
    });
  }

  function handleContinueButtonClick(data) {
    educations.value = educations.value.filter(
      (education) => education.id !== data.education.id
    );

    fields.value = data.fields;

    emits('update-is-editing', false);
    emits('update-percentages', {
      sectionPercentage: data.sectionPercentage,
      resumePercentage: data.resumePercentage
    });
  }

  watch(
    () => props.isEditing,
    (value) => {
      if (pendingAction.value === 'add' || pendingAction.value === 'edit') {
        isFormModalOpen.value = value;
      } else if (pendingAction.value === 'delete') {
        isDeleteModalOpen.value = value;
      }
    }
  );
</script>
