// Create a file named useForm.js

import { ref } from "vue";
import axios from "axios"; // Import Axios or your preferred HTTP library
import { __ } from "../helpers";

function useForm(initialData = {}, axiosConfig = {}) {
  // Create a reactive ref for form data
  const formData = ref(initialData);

  // Create a reactive ref for the loading state
  const isLoading = ref(false);

  // Create a reactive ref for validation errors
  const errors = ref({});

  // Method to update form data
  const updateFormData = (key, value) => {
    formData.value[key] = value;
  };

  // Method to reset form data to initial state
  const resetForm = () => {
    formData.value = { ...initialData };
  };

  // upload other object in initialData

  // Method to submit the form data to an API using Axios
  const submitForm = async () => {
    if (isLoading.value) {
      // Prevent multiple form submissions while one is in progress
      return;
    }

    try {
      isLoading.value = true;

      // Check if the URL is specified in axiosConfig
      if (!axiosConfig.url) {
        throw new Error(__("URL is not specified for form submission."));
      }

      // Use Axios to send the form data to the specified URL with the given config
      const response = await axios({
        method: axiosConfig.method || "post", // Default to 'post' if method is not provided
        url: axiosConfig.url, // Use the provided URL
        data: formData.value,
        ...axiosConfig, // Additional Axios config options
      });

      // Check if the response status code is 200 (OK)
      if (response.status === 200) {
        // After successful submission, you can reset the form
        // resetForm();

        // Clear any previous errors on successful submission
        errors.value = {};
        // Handle the response data as needed
        return response.data;
      } else if (response.status === 500 || response.status === 400) {
        // raise exception
        throw new Error(response.data.message);
      } else {
        // Handle non-200 status codes as needed (e.g., display an error message)
        console.error(
          __("Form submission failed with status code:"),
          response.status
        );

        // Display validation errors if available
        if (response.data && response.data.errors) {
          errors.value = response.data.errors;
        }
      }
    } catch (error) {
      // Handle errors, display validation errors, etc.
      console.error(__("Form submission error:"), error);

      // Display validation errors if available
      if (error.response && error.response.data && error.response.data.errors) {
        errors.value = error.response.data.errors;
      }

      // raise exception
      throw error;
    } finally {
      isLoading.value = false;
    }
  };

  // Return the form state, loading state, and methods
  return {
    formData,
    isLoading,
    updateFormData,
    resetForm,
    submitForm,
    errors,
  };
}

export default useForm;
