<template>
  <div
    class="space-y-6 rounded-2xl bg-gradient-to-b from-zinc-100 to-transparent p-4"
  >
    <div class="flex items-start justify-between gap-4">
      <div class="space-y-2">
        <span class="block text-zinc-800">
          <span class="text-lg font-medium">{{ name }}</span>
          <span v-if="organization">{{ ` @ ${organization}` }}</span>
        </span>

        <span v-if="date" class="block text-sm text-zinc-600">{{ date }}</span>
      </div>

      <div v-if="canEdit" class="flex flex-wrap justify-end gap-2">
        <Button
          :aria-label="__('Edit')"
          variant="icon"
          @click="handleEditButtonClick"
        >
          <Icon class="text-xl" name="edit" />
        </Button>

        <Button
          :aria-label="__('Delete')"
          variant="icon"
          @click="handleDeleteButtonClick"
        >
          <Icon class="text-xl" name="delete" />
        </Button>
      </div>
    </div>

    <a
      v-if="link"
      :href="link"
      target="_blank"
      class="block w-fit rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
    >
      {{ link }}
    </a>

    <p v-if="description" class="leading-normal text-zinc-600">
      <span>{{ description }}</span>

      <button
        v-if="isDescriptionExpandButtonVisible"
        class="text-primary underline-offset-2 hover:underline"
        @click="handleDescriptionExpandButtonClick"
      >
        {{ __('see more') }}
      </button>
    </p>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { __ } from '@/helpers';
  import { formatDateToMonthYear } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    achievement: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    }
  });

  const emits = defineEmits(['edit', 'delete']);

  const name = computed(() => {
    return props.achievement.name;
  });

  const organization = computed(() => {
    return props.achievement.organization;
  });

  const date = computed(() => {
    if (!props.achievement.start_date) {
      return '';
    }

    const isCurrentlyStudyingHere = props.achievement.current;
    const startDate = props.achievement.start_date ? new Date(props.achievement.start_date) : '-';
    const endDate = isCurrentlyStudyingHere
        ? new Date()
        : props.achievement.end_date
            ? new Date(props.achievement.end_date)
            : '-';

    let years = (startDate === '-' || endDate === '-') ? '0' : endDate.getFullYear() - startDate.getFullYear();
    let months = (startDate === '-' || endDate === '-') ? '0' : endDate.getMonth() - startDate.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    if (months > 0) {
      years++;
    }

    return `${startDate === '-' ? __('Date not set') : formatDateToMonthYear(startDate)} - ${
        isCurrentlyStudyingHere
            ? __('Present')
            : endDate === '-' ? __('Date not set') : formatDateToMonthYear(endDate)
    } (${years} ${__('years')})`;
  });

  const link = computed(() => {
    return props.achievement.link;
  });

  const description = computed(() => {
    if (!props.achievement.description) {
      return '';
    }

    return props.achievement.description.length <= 250 ||
      isDescriptionExpanded.value
      ? props.achievement.description
      : `${props.achievement.description.substring(0, 250)}...`;
  });

  const isDescriptionExpandButtonVisible = computed(() => {
    if (!props.achievement.description) {
      return false;
    }

    return (
      props.achievement.description.length > 250 && !isDescriptionExpanded.value
    );
  });

  const isDescriptionExpanded = ref(false);

  function handleDescriptionExpandButtonClick() {
    isDescriptionExpanded.value = true;
  }

  function handleEditButtonClick() {
    emits('edit');
  }

  function handleDeleteButtonClick() {
    emits('delete');
  }
</script>
