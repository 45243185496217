<template>
  <div class="shadow-card space-y-4 rounded-2xl bg-white p-6 md:p-8">
    <h2 class="text-3xl font-medium text-zinc-800">
      {{ __('Upload Resume') }}
    </h2>

    <div class="space-y-2">
      <h3 class="text-lg font-medium leading-none text-zinc-800">
        {{ __('Got a recent resume?') }}
      </h3>

      <p class="leading-normal text-zinc-600">
        {{
          __(
            'Upload your resume to have your profile autocompleted in just a few seconds. Review and edit information after importing.'
          )
        }}
      </p>
    </div>

    <form>
      <label
        :class="`${!canUpload || isLoading ? 'pointer-events-none opacity-50' : ''} block w-fit cursor-pointer rounded-md px-4 py-2 text-sm font-medium text-primary ring-1 ring-primary transition-colors hover:bg-primary hover:text-white`"
      >
        <span>{{ __(isLoading ? 'Uploading...' : 'Upload file') }}</span>
        <input
          class="sr-only"
          type="file"
          accept=".pdf,.doc,.docx"
          :disabled="!canUpload || isLoading"
          @change="handleChange"
        />
      </label>
    </form>

    <span class="mt-4 block text-sm text-zinc-600">
      {{
        canUpload
          ? `${__('Max 1 upload/day')} - pdf, doc, docx (5MB)`
          : __("Looks like you've reached your maximum upload limit for resumes today. Remember, you can upload one resume per day.")
      }}
    </span>
    <div v-if="cvErrors.file && cvErrors.file.length" class="mt-2 space-y-1">
      <span
          v-for="(error, index) in cvErrors"
          :key="index"
          class="block text-xs text-error"
      >
        {{ error[0] }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';

  defineProps({
    canUpload: {
      type: Boolean,
      required: true
    }
  });

  const toast = useToast();

  const isLoading = ref(false);
  const cvErrors = ref([]);
  async function handleChange(event) {
    const files = event.target.files;

    if (!files || !files.length) {
      return;
    }

    isLoading.value = true;

    try {
      const formData = new FormData();
            formData.append('file', files[0]);

      const response = await axios.post(route('redesign.cv.upload-cv'),formData);

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      window.location.reload();
    } catch (error) {
      toast.error(error?.response?.data?.message || __('Something went wrong while uploading CV.'));
      cvErrors.value = error?.response?.data?.errors;
    }

    isLoading.value = false;
  }
</script>
