<template>
  <div>
    <div v-if="error" id="leaderboard" class="candidate-gamification__tab">
      <div class="matching-lists__message">
        <p style="text-align: center">
          {{ __("Something went wrong. Please check again later.") }}
        </p>
      </div>
    </div>

    <div v-if="user" id="leaderboard" class="candidate-gamification__tab">
      <div class="candidate-gamification__module-header flex sa aic">
        <h3
          class="candidate-gamification__subtab-link selected"
          data-subtab="monthly"
        >
          {{ __("Monthly") }}
        </h3>
        <h3 class="candidate-gamification__subtab-link" data-subtab="weekly">
          {{ __("Weekly") }}
        </h3>
        <h3 class="candidate-gamification__subtab-link" data-subtab="friends">
          {{ __("Friends") }}
        </h3>
      </div>

      <div class="candidate-gamification__module-cnt">
        <div class="candidate-gamification__subtabs">
          <gamification-leaderboard-monthly
            :user="user"
          ></gamification-leaderboard-monthly>
          <gamification-leaderboard-weekly
            :user="user"
          ></gamification-leaderboard-weekly>
          <gamification-leaderboard-friends
            :user="user"
          ></gamification-leaderboard-friends>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["error", "user"],
};
</script>
