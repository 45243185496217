<template>
  <div class="flex flex-col gap-8">
    <SectionHeader :title="__('Pipelines')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="filter_alt" />
      </template>
      <template #button>
        <Button
          class="ml-auto flex items-center gap-1.5"
          variant="ghost"
          href="/company/pipelines"
          target="_blank"
        >
          <Icon class="text-lg" name="open_in_new" />
          <span>{{ __('Create new pipeline') }}</span>
        </Button>
      </template>
    </SectionHeader>

    <div
      class="shadow-element flex max-h-[25rem] flex-grow flex-col gap-8 rounded-2xl bg-white p-6 md:p-8"
    >
      <div v-if="pipelines.length" class="flex-grow space-y-4 overflow-y-auto">
        <a
          v-for="pipeline in pipelines"
          :key="pipeline.id"
          class="group flex items-center gap-2 text-gray-600"
          href="/company/pipelines"
          target="_blank"
        >
          <Icon class="text-lg" name="open_in_new" />
          <span
            class="text-sm font-medium leading-none group-hover:underline group-hover:underline-offset-2"
          >
            {{ pipeline.name }}
          </span>
        </a>
      </div>

      <span v-else class="text-zinc-600">
        {{ __('No pipelines found') }}
      </span>

      <Popover
        v-if="availablePipelines.length"
        @close="handlePipelinePopoverClose"
      >
        <template #trigger>
          <Button
            tabindex="-1"
            variant="outline"
            class="flex items-center gap-1.5"
          >
            <span>
              {{ __('Add to one of your pipelines') }}
            </span>
            <Icon name="expand_more" />
          </Button>
        </template>
        <template #content="{ close }">
          <form
            class="flex flex-col items-end gap-4 p-4"
            @submit.prevent="handlePipelineSubmit(close)"
          >
            <InputSelect
              id="pipeline_select"
              v-model:value="values.pipeline"
              class="min-w-80"
              :label="__('Pipeline')"
              :placeholder="__('Select pipeline')"
              :options="availablePipelines"
            />

            <Button
              class="flex items-center gap-1.5"
              type="submit"
              :disabled="isLoadingPipeline"
            >
              <Icon
                v-if="isLoadingPipeline"
                class="animate-spin text-lg"
                name="progress_activity"
              />
              <span>
                {{ __(isLoadingPipeline ? 'Loading...' : 'Add to pipeline') }}
              </span>
            </Button>
          </form>
        </template>
      </Popover>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Popover } from '@/components/block/popover';
  import { InputSelect } from '@/components/form/input-select';
  import { SectionHeader } from '@/pages/components/section-header';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    recruiter: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const userId = computed(() => {
    return props.data.about_me.user.user_id;
  });

  const pipelines = ref(JSON.parse(JSON.stringify(props.recruiter.pipelines)));
  const availablePipelines = ref(
    JSON.parse(JSON.stringify(props.recruiter.available_pipelines))
  );
  const values = ref({ pipeline: null });
  const isLoadingPipeline = ref(false);

  function handlePipelinePopoverClose() {
    values.value.pipeline = null;
  }

  async function handlePipelineSubmit(close) {
    if (!values.value.pipeline) {
      return;
    }

    isLoadingPipeline.value = true;

    try {
      const response = await axios.post(route('redesign.cv.addToPipeline'), {
        pipeline_id: values.value.pipeline.id,
        user_id: userId.value
      });

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      pipelines.value = [...pipelines.value, values.value.pipeline];
      availablePipelines.value = availablePipelines.value.filter(
        (availablePipeline) => availablePipeline.id !== values.value.pipeline.id
      );

      close();
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingPipeline.value = false;
  }
</script>
