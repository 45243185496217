<template>
  <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
    <form @submit.prevent="handleSubmit">
      <div>
        <div class="pb-9">
          <div class="relative">
            <UploadCover
              v-model:value="values.cover_url"
              v-model:file="files.cover"
              v-model:errors="errors.cover_url"
            />
            <UploadLogo
              v-model:value="values.logo_url"
              v-model:file="files.logo"
              v-model:errors="errors.logo_url"
              class="absolute left-5 top-24"
            />
          </div>
        </div>

        <div
          v-if="[...errors.cover_url, ...errors.logo_url].length"
          class="mt-2 space-y-1"
        >
          <span
            v-for="error in [...errors.cover_url, ...errors.logo_url]"
            :key="error"
            class="block text-xs text-error"
          >
            {{ error }}
          </span>
        </div>
      </div>

      <div class="mt-8 grid grid-cols-1 gap-4 md:grid-cols-6 md:gap-y-8">
        <InputText
          id="name"
          v-model:value="values.name"
          v-model:errors="errors.name"
          class="col-span-1 md:col-span-3"
          :label="__('Company name')"
          :placeholder="__('Add company name')"
          required
        />
        <InputText
          id="industry"
          v-model:value="values.industry"
          v-model:errors="errors.industry"
          class="col-span-1 md:col-span-3"
          :label="__('Company industry')"
          :placeholder="__('Add company industry')"
          required
        />
        <InputText
          id="short_description"
          v-model:value="values.short_description"
          v-model:errors="errors.short_description"
          class="col-span-1 md:col-span-6"
          :label="__('Company tagline')"
          :placeholder="__('Add company motto/vision')"
        />
        <InputSelect
          id="employee_number"
          v-model:value="values.employee_number"
          v-model:errors="errors.employee_number"
          class="col-span-1 md:col-span-2"
          :label="__('Employees')"
          :placeholder="__('Add number of employees')"
          :options="options.employee_numbers"
        />
        <InputMultiSelect
          id="work_type"
          v-model:values="values.work_type"
          v-model:errors="errors.work_type"
          class="col-span-1 md:col-span-2"
          :label="__('Type of work')"
          :placeholder="__('Add type of work')"
          :options="options.work_types"
        />
        <InputText
          id="website"
          v-model:value="values.website"
          v-model:errors="errors.website"
          class="col-span-1 md:col-span-2"
          :label="__('Company website')"
          :placeholder="__('Add company website')"
        />
      </div>

      <div class="mt-8 space-y-6">
        <div class="flex items-center gap-x-2">
          <Icon class="text-2xl text-primary" name="add_link" />

          <h3 class="text-2xl font-medium text-zinc-800">
            {{ __('Social Media Links') }}
          </h3>
        </div>

        <div class="grid grid-cols-1 gap-4 md:grid-cols-4">
          <InputText
            id="facebook"
            v-model:value="values.social_links.facebook"
            v-model:errors="errors.social_links.facebook"
            class="col-span-1"
            :label="__('Facebook')"
            :placeholder="__('Add Facebook')"
          />
          <InputText
            id="instagram"
            v-model:value="values.social_links.instagram"
            v-model:errors="errors.social_links.instagram"
            class="col-span-1"
            :label="__('Instagram')"
            :placeholder="__('Add Instagram')"
          />
          <InputText
            id="linkedin"
            v-model:value="values.social_links.linkedin"
            v-model:errors="errors.social_links.linkedin"
            class="col-span-1"
            :label="__('LinkedIn')"
            :placeholder="__('Add LinkedIn')"
          />
          <InputText
            id="twitter"
            v-model:value="values.social_links.twitter"
            v-model:errors="errors.social_links.twitter"
            class="col-span-1"
            :label="__('X (Twitter)')"
            :placeholder="__('Add X (Twitter)')"
          />
          <InputText
            id="youtube"
            v-model:value="values.social_links.youtube"
            v-model:errors="errors.social_links.youtube"
            class="col-span-1"
            :label="__('YouTube')"
            :placeholder="__('Add YouTube')"
          />
          <InputText
            id="tiktok"
            v-model:value="values.social_links.tiktok"
            v-model:errors="errors.social_links.tiktok"
            class="col-span-1"
            :label="__('TikTok')"
            :placeholder="__('Add TikTok')"
          />
        </div>
      </div>

      <span
        v-if="errorMessage"
        class="mt-8 block text-center text-sm text-error"
      >
        {{ errorMessage }}
      </span>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { isValidHttpUrl } from '@/scripts/validate.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputMultiSelect } from '@/components/form/input-multi-select';
  import { InputSelect } from '@/components/form/input-select';
  import { InputText } from '@/components/form/input-text';
  import { UploadCover } from './components/upload-cover';
  import { UploadLogo } from './components/upload-logo';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  });

  const emits = defineEmits(['save', 'cancel']);

  const toast = useToast();

  const data = ref(JSON.parse(JSON.stringify(props.data)));
  const values = ref({
    cover_url: data.value.cover_url || null,
    logo_url: data.value.logo_url || null,
    name: data.value.name || '',
    industry: data.value.industry || '',
    short_description: data.value.short_description || '',
    employee_number: data.value.employee_number || null,
    work_type: data.value.work_type || null,
    website: data.value.website || '',
    social_links: {
      facebook: data.value.social_links.facebook || '',
      instagram: data.value.social_links.instagram || '',
      linkedin: data.value.social_links.linkedin || '',
      twitter: data.value.social_links.twitter || '',
      youtube: data.value.social_links.youtube || '',
      tiktok: data.value.social_links.tiktok || ''
    }
  });
  const errors = ref({
    cover_url: [],
    logo_url: [],
    name: [],
    industry: [],
    short_description: [],
    employee_number: [],
    work_type: [],
    website: [],
    social_links: {
      facebook: [],
      instagram: [],
      linkedin: [],
      twitter: [],
      youtube: [],
      tiktok: []
    }
  });
  const isLoading = ref(false);
  const files = ref({ cover: null, logo: null });

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    const requiredFieldKeys = ['name', 'industry'];
    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    Object.keys(values.value.social_links).forEach((key) => {
      if (
        values.value.social_links[key] &&
        !isValidHttpUrl(values.value.social_links[key])
      ) {
        errors.value.social_links[key] = [
          __('Invalid URL. Please ensure the URL is correctly formatted.')
        ];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (
      Object.values(errors.value).some((error) => {
        if (Array.isArray(error)) {
          return error.length > 0;
        } else if (typeof error === 'object' && error !== null) {
          return Object.values(error).some(
            (nestedError) => nestedError.length > 0
          );
        }
        return false;
      })
    ) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      if (files.value.cover) {
        const formData = new FormData();

        formData.append('file', files.value.cover);

        const response = await axios.post(
          route('redesign.company.uploadCoverPhoto'),
          formData
        );

        values.value.cover_url = response.data.full_path;
      }

      if (files.value.logo) {
        const formData = new FormData();

        formData.append('file', files.value.logo);

        const response = await axios.post(
          route('redesign.company.uploadFile'),
          formData
        );

        values.value.logo_url = response.data.full_path;
      }

      const response = await axios.post(
        route('redesign.company.update.branding'),
        values.value
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', response.data.data);
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
