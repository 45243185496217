<template>
  <div class="space-y-8">
    <div class="flex flex-wrap items-center justify-between gap-4">
      <h1 class="text-5xl font-medium text-zinc-800">
        {{ __('Speakers') }}
      </h1>

      <Button
        variant="outline"
        class="flex items-center gap-1.5"
        @click="handleAddButtonClick"
      >
        <Icon name="add_box" is-filled class="text-lg text-zinc-800" />
        <span class="text-zinc-800">{{ __('Add new speaker') }}</span>
      </Button>
    </div>

    <div class="space-y-6">
      <Speaker
        v-for="speaker in speakers"
        :key="speaker.id"
        :speaker="speaker"
        @edit="handleEditButtonClick(speaker)"
        @delete="handleDeleteButtonClick(speaker)"
      />
    </div>

    <SpeakerFormModal
      v-if="isFormModalOpen"
      :speaker="selected"
      @cancel="handleCancelButtonClick"
      @save="handleSaveButtonClick"
    />

    <SpeakerDeleteModal
      v-if="isDeleteModalOpen"
      :speaker="selected"
      @cancel="handleCancelButtonClick"
      @continue="handleContinueButtonClick"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Speaker } from './components/speaker';
  import { SpeakerDeleteModal } from './components/speaker-delete-modal';
  import { SpeakerFormModal } from './components/speaker-form-modal';

  const props = defineProps({
    speakers: {
      type: Array,
      required: true
    }
  });

  const speakers = ref(JSON.parse(JSON.stringify(props.speakers)));
  const selected = ref(null);
  const isFormModalOpen = ref(false);
  const isDeleteModalOpen = ref(false);

  function handleAddButtonClick() {
    selected.value = null;
    isFormModalOpen.value = true;
  }

  function handleEditButtonClick(speaker) {
    selected.value = JSON.parse(JSON.stringify(speaker));
    isFormModalOpen.value = true;
  }

  function handleDeleteButtonClick(speaker) {
    selected.value = JSON.parse(JSON.stringify(speaker));
    isDeleteModalOpen.value = true;
  }

  function handleCancelButtonClick() {
    selected.value = null;
    isFormModalOpen.value = false;
    isDeleteModalOpen.value = false;
  }

  function handleSaveButtonClick(speaker) {
    const existingSpeaker = speakers.value.find(
      (item) => item.id === speaker.id
    );

    if (existingSpeaker) {
      speakers.value = speakers.value.map((item) => {
        if (item.id === speaker.id) {
          return speaker;
        }

        return item;
      });
    } else {
      speakers.value = [...speakers.value, speaker];
    }

    isFormModalOpen.value = false;
  }

  function handleContinueButtonClick(speaker) {
    speakers.value = speakers.value.filter((item) => item.id !== speaker.id);
    isDeleteModalOpen.value = false;
  }
</script>
