<template>
  <div
    class="relative mt-8 rounded-app bg-white p-8 shadow-md"
    :class="{ 'bg-zinc-50 [&>*]:!text-zinc-400': !job.active }"
  >
    <!-- div overlay if !job.active -->

    <div>
      <div class="flex justify-between">
        <div>
          <h3
            class="my-2 text-lg"
            :class="{
              '!text-zinc-400': !job.active,
              'text-[#212429]': job.active
            }"
          >
            <Icon
              name="work"
              :is_filled="true"
              :class="{
                'text-zinc-400': !job.active,
                'text-primary': job.active
              }"
            />
            {{ job.title }}
          </h3>
        </div>

        <div class="text-primary">
          <a
            v-if="job.conditions.can_edit"
            :href="route('redesign.company.jobs.edit', job.id)"
            target="_BLANK"
          >
            <Icon name="edit" class="text-xl" />
          </a>
          <Icon
            name="expand_content"
            @click="openModal"
            role="button"
            class="cursor-pointer text-xl"
          />
        </div>
      </div>

      <!-- <div v-if="job.internal">
        <span class="text-sm text-body">
          <Icon name="apartment" class="!text-sm text-primary" />
          {{ __("Internal job") }}
        </span>
      </div> -->
    </div>

    <p class="text-sm text-body" v-if="job.city">
      <span v-for="(city, index) in job.city" :key="index"
        >{{ city.name }}
        <span v-if="index !== job.city.length - 1">, </span>
      </span>
    </p>

    <div
      v-if="job?.vulnerability?.length || job?.disability?.length"
      class="mt-4"
    >
      <span class="text-sm" :class="{ '!text-zinc-400': !job.active }">
        <Icon
          name="diversity_1"
          class="!text-sm"
          :class="{
            'text-zinc-400': !job.active,
            'text-primary': job.active
          }"
        />
        {{ __('We embrace diversity and equal opportunity.') }}
      </span>
    </div>

    <JobOverview :job="job" :disabled="!job.active" />

    <div class="text-xs text-body">
      <div>
        <span>
          {{ __('Created') }}
          {{ job.posted_at }}
        </span>
        <!-- <span> | HR (mock) </span> -->
        <span> | {{ __('Assigned to') }} {{ job.assigned_recruiters }} </span>
      </div>
      <!-- <div>
        <span> {{ __("Posted on") }}: TODO </span>
      </div> -->
    </div>

    <div class="mt-4 justify-between md:flex">
      <div>
        <a
          v-if="job.active && job.conditions.can_edit"
          :href="route('redesign.company.dashboard', job.id)"
          target="_BLANK"
        >
          <Button variation="normal" content="See candidates" icon="groups" />
        </a>
      </div>

      <div class="flex gap-4" v-if="!job.closed && job.conditions.can_edit">
        <label
          class="items-center justify-between pb-1 text-sm text-zinc-600 md:flex"
        >
          {{ job.active ? __('Active') : __('Inactive') }}
          <div class="ml-2">
            <Toggle @change="handleToggle" :value="job.active" />
          </div>
        </label>

        <Button
          v-if="job.active && hasIntegrations"
          variation="fill"
          content="Post on Job Boards"
          @click="openJobBoardsModal"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { Button, Icon, Toggle } from '../../../../components/global';
  import { __ } from '../../../../helpers';
  import { JobOverview } from '../../../candidate/jobs/components';
  import { useLoading } from 'vue-loading-overlay';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';

  const props = defineProps({
    job: {
      type: Object,
      required: true
    },
    hasIntegrations: {
      type: Boolean,
      required: true
    }
  });

  const emit = defineEmits([
    'openModal',
    'openJobBoardsModal',
    'handleJobToggle'
  ]);

  const openModal = () => {
    emit('openModal');
  };

  const openJobBoardsModal = () => {
    emit('openJobBoardsModal');
  };

  const $loading = useLoading();
  const toast = useToast();

  const handleToggle = () => {
    if (props.job.active) {
      const confirm = window.confirm(
        __('Are you sure you want to inactivate this job?') +
          '\n\n' +
          __(
            'Please note that if you proceed, all unanswered applications will be automatically rejected, and an email will be sent to inform candidates that the job is no longer active. '
          )
      );

      if (!confirm) {
        return;
      }
    }

    const loader = $loading.show();

    axios
      .post(route('redesign.company.job.toggle', { job: props.job.id }), {
        active: !props.job.active
      })
      .then((response) => {
        toast.success(response.data.message);
        props.job.active = !props.job.active;

        emit('handleJobToggle', props.job);

        loader.hide();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        loader.hide();
      });
  };
</script>
