<template>
  <div id="values">
    <div class="mt-8 md:grid md:grid-cols-2">
      <SectionHeader icon="globe" title="Languages" />
    </div>

    <div class="p-8 shadow-md mt-8 rounded-app bg-white relative">
      <div>
        <div class="md:grid md:grid-cols-2 md:gap-x-20">
          <div v-for="(value, index) in data.job" :key="index" class="mb-4">
            <div class="mb-4 p-4 shadow-md rounded-app">
              <!-- Delete X -->
              <div class="flex justify-end">
                <button type="button" @click="data.job.splice(index, 1)">
                  <span class="material-symbols-rounded" aria-hidden="true">
                    close
                  </span>
                  <span class="sr-only">{{ __("Delete") }}</span>
                </button>
              </div>
              <div class="md:grid md:grid-cols-2 md:gap-x-2">
                <div class="my-4 md:my-0">
                  <label
                    for="language"
                    class="text-sm text-[#58585E] mb-2 block"
                  >
                    {{ __("Language") }} *
                  </label>

                  <FormSingleselect
                    v-model="value.language"
                    :values="available_languages"
                    :placeholder="__('Select language')"
                    :clearable="true"
                    @clear="value.language = {}"
                    type="static"
                    :errors="errors['languages.' + index + '.language']"
                    required
                  />
                </div>

                <div class="my-4 md:my-0">
                  <label
                    for="proficiency"
                    class="text-sm text-[#58585E] mb-2 block"
                  >
                    {{ __("Proficiency") }} *
                  </label>
                  <FormSingleselect
                    v-model="value.proficiency"
                    :values="data.options.proficiencies"
                    :placeholder="__('Select proficiency')"
                    :clearable="true"
                    @clear="value.proficiency = {}"
                    type="static"
                    :errors="errors['languages.' + index + '.proficiency']"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          :content="__('Add language')"
          type="button"
          @click="data.job.push({ language: {}, proficiency: {} })"
          variation="normal"
          icon="add_box"
        />
        <p class="text-xs text-error mt-1" v-html="errors.languages"></p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import {
  Button,
  Icon,
  FormMultiselect,
  SectionHeader,
  Textarea,
  FormSingleselect,
} from "../../../../components/global";

import { useForm } from "../../../../utils";
import { useToast } from "vue-toastification";
import { __ } from "../../../../helpers";

const props = defineProps({
  data: {
    type: Object,
    mandatory: true,
  },
  errors: {
    type: Object,
    mandatory: true,
  },
});
console.log(props.data);
const toast = useToast();

const available_languages = computed(() => {
  //  return data.options.languages without pageFields

  return props.data.options.languages.filter(
    (language) =>
      !props.data.job.some(
        (pageField) => pageField.language?.id === language.id
      )
  );
});
</script>
