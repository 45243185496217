<template>
  <div class="shadow-card rounded-2xl bg-white p-6 md:p-8">
    <EmptySection v-if="!data.length" :title="__('Add questions')">
      <template #icon>
        <Icon class="text-xl text-zinc-200" name="quiz" is-filled />
      </template>
    </EmptySection>

    <div v-else class="space-y-6">
      <button
        v-for="item in data"
        :key="item.id"
        class="shadow-card flex w-full items-start justify-between gap-4 rounded-lg px-5 py-4 text-left"
        type="button"
        @click="handleButtonClick(item.id)"
      >
        <div class="space-y-2">
          <span class="block text-lg font-medium text-zinc-800">
            {{ item.question }}
          </span>

          <span v-if="item.isOpen" class="block leading-normal text-zinc-600">
            {{ item.answer }}
          </span>
        </div>

        <Icon
          class="text-xl text-zinc-600"
          :name="item.isOpen ? 'expand_less' : 'expand_more'"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { EmptySection } from '@/pages/components/empty-section';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    }
  });

  const data = ref(
    JSON.parse(JSON.stringify(props.data)).map((item) => ({
      ...item,
      isOpen: false
    }))
  );

  function handleButtonClick(id) {
    data.value = data.value.map((item) => {
      if (item.id === id) {
        return { ...item, isOpen: !item.isOpen };
      }

      return { ...item, isOpen: false };
    });
  }
</script>
