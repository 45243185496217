<template>
  <div class="space-y-4">
    <div class="shadow-element overflow-auto rounded-md">
      <table class="w-max min-w-full">
        <thead>
          <tr>
            <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
              {{ __('Name') }}
            </th>
            <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
              {{ __('Status') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading" class="border-t">
            <td :colSpan="2" class="px-8 py-4">
              <span class="text-sm text-zinc-600">
                {{ __('Loading...') }}
              </span>
            </td>
          </tr>

          <tr v-else-if="!attendees.length" class="border-t">
            <td :colSpan="2" class="px-8 py-4">
              <span class="text-sm text-zinc-600">
                {{ __('No attendees') }}
              </span>
            </td>
          </tr>

          <template v-else>
            <Attendee
              v-for="attendee in attendees"
              :key="attendee"
              :attendee="attendee"
              :event="event"
            />
          </template>
        </tbody>
      </table>
    </div>

    <div
      v-if="!isPreviousButtonDisabled || !isNextButtonDisabled"
      class="flex justify-center gap-2"
    >
      <button
        class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
        :disabled="isPreviousButtonDisabled"
        :aria-label="__('Previous')"
        @click="handlePreviousButtonClick"
      >
        <Icon class="text-xl text-primary" name="arrow_back" />
      </button>

      <button
        class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
        :disabled="isNextButtonDisabled"
        :aria-label="__('Next')"
        @click="handleNextButtonClick"
      >
        <Icon class="text-xl text-primary" name="arrow_forward" />
      </button>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';
  import { Attendee } from './components/attendee';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const isPreviousButtonDisabled = computed(() => {
    return page.value <= 1 || isLoading.value;
  });

  const isNextButtonDisabled = computed(() => {
    return page.value >= totalPages.value || isLoading.value;
  });

  const attendees = ref([]);
  const isLoading = ref(false);
  const page = ref(1);
  const totalPages = ref(0);

  async function handlePreviousButtonClick() {
    page.value--;

    await fetchAttendees(page.value);
  }

  async function handleNextButtonClick() {
    page.value++;

    await fetchAttendees(page.value);
  }

  async function fetchAttendees(page) {
    isLoading.value = true;

    try {
      const response = await axios.get(
        route('redesign.company.events.confirmedAttendees', {
          id: props.event.id
        }),
        { params: { page } }
      );

      attendees.value = response.data.members;
      totalPages.value = response.data.pagination.total;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }

  onMounted(async () => {
    await fetchAttendees(page.value);
  });
</script>
