<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell">
    <div class="flex justify-center gap-1.5">
      <div v-for="(subValue, subKey) in columnData" :key="subKey" >
        <p v-if="subKey === 'label'">{{ capitalize(subValue) }}</p>
        <a v-if="subKey === 'kanban'" @click.prevent="onAddToKanban(subValue['action']['board_id'], subValue['action']['contact_id'], candidateIndex)" class="block cursor-pointer rounded-md bg-brandColor1 px-2 py-1 text-center text-xs text-white shadow-redesign hover:bg-brandColor1/[.80]">{{ __(capitalize(subValue['label'])) }}</a>
      </div>
    </div>
  </td>
</template>

<script setup>
import {__, capitalize} from "../../../../../helpers";
import axios from "axios";
import {useToast} from "vue-toastification";
import {inject} from "vue";
const loadUsers = inject('loadUsers');

const toast = useToast();
const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});

const onAddToKanban = (boardId, contctId, candidateIndex) => {
  console.log(boardId)
  console.log(contctId)
  axios.post(route('company.kanban.cards.add'),{
        board_id: boardId,
        contact_id: contctId
      }
  ).then(response => {
    if(response.data.status){
      toast.success(response.data.message)
      loadUsers();
    }else{
      toast.error(response.data.message)
    }
  }).catch( err => {
    toast.error(err.message);
  });
}


</script>