<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Media')">
      <template #icon>
        <Icon
          class="text-3xl text-primary"
          name="gallery_thumbnail"
          is-filled
        />
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
      <div class="space-y-1.5">
        <span class="block leading-normal text-zinc-800">
          {{ __('Add photo gallery (max. 6 photos)') }}
        </span>

        <div
          class="grid grid-cols-2 gap-2 sm:grid-cols-3 sm:gap-3 md:grid-cols-4 md:gap-4"
        >
          <Image
            v-for="(value, index) in values"
            :key="value"
            :value="value"
            @delete="handleDeleteImageButtonClick(index)"
          />

          <UploadImage
            :errors="errors"
            :class="`${values.length ? (values.length === 6 ? 'hidden' : 'block') : 'col-span-2 sm:col-span-3 md:col-span-4'}`"
            @change="handleUploadImageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Image } from './components/image';
  import { UploadImage } from './components/upload-image';

  const props = defineProps({
    values: {
      type: Array,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    errors: {
      type: Array,
      required: true
    }
  });

  const emits = defineEmits(['update:values', 'update:files', 'update:errors']);

  function handleUploadImageChange(base64, file) {
    if (props.values.length >= 6) {
      return;
    }

    emits('update:values', [...props.values, { photo_url: base64 }]);
    emits('update:files', [...props.files, file]);
    emits('update:errors', []);
  }

  function handleDeleteImageButtonClick(index) {
    emits(
      'update:values',
      props.values.filter((_, i) => i !== index)
    );
  }
</script>
