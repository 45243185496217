<template>
  <Menu as="div" class="relative">
    <MenuButton :disabled="disabled" class="rounded-md">
      <slot name="trigger" />
    </MenuButton>

    <MenuItems
      :class="`shadow-card absolute ${position}-0 z-10 mt-1 w-max min-w-full origin-top-${position} rounded-md border bg-white p-1 focus:outline-none`"
    >
      <MenuItem
        v-for="option in options"
        :key="option.id"
        v-slot="{ active }"
        @click="handleSelect(option)"
      >
        <button
          :class="[
            active ? 'bg-zinc-50' : 'bg-white',
            'flex w-full cursor-default items-center gap-1.5 rounded-md px-3 py-1.5 text-left text-sm leading-normal text-zinc-800'
          ]"
        >
          <Icon
            v-if="option.icon"
            class="text-xl text-primary"
            :name="option.icon"
          />

          <span>{{ option.name }}</span>
        </button>
      </MenuItem>
    </MenuItems>
  </Menu>
</template>

<script setup>
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
  import { Icon } from '@/components/block/icon';

  defineProps({
    position: {
      type: String,
      default: 'left'
    },
    disabled: {
      type: Boolean,
      required: false
    },
    options: {
      type: Array,
      required: true
    }
  });

  const emits = defineEmits(['select']);

  function handleSelect(option) {
    emits('select', option);
  }
</script>
