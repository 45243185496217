<template>
  <div v-if="value" class="flex gap-2">
    <a
      class="group -mx-4 flex w-fit items-center gap-1.5 px-4 py-2 text-primary"
      :href="`/uploads/${value.url}`"
      target="_blank"
    >
      <Icon class="text-xl" name="picture_as_pdf" is-filled />
      <span
        class="text-sm font-medium group-hover:underline group-hover:underline-offset-2"
      >
        {{ value.name }}
      </span>
    </a>

    <Button variant="icon" @click="handleDeleteButtonClick">
      <Icon class="text-xl" name="delete" />
    </Button>
  </div>
  <div v-else>
    <label
      :class="`${isLoading ? 'pointer-events-none opacity-50' : ''} ${isDragging ? 'bg-primary/5' : 'bg-zinc-50'} mx-auto flex h-40 w-full max-w-sm cursor-pointer flex-col items-center justify-center gap-1 rounded border border-dashed border-primary p-4 transition-colors`"
      for="upload-resume"
      @drop.prevent="handleDrop"
      @dragover.prevent="handleDragOver"
      @dragleave.prevent="handleDragLeave"
    >
      <Icon class="text-2xl text-primary" name="backup" is-filled />

      <span class="block text-center text-sm font-medium text-primary">
        {{ __(`${isLoading ? 'Uploading...' : 'Upload your CV'}`) }}
      </span>
    </label>

    <input
      id="upload-resume"
      class="sr-only"
      type="file"
      accept=".pdf,.doc,.docx"
      :disabled="isLoading"
      @change="handleInputChange"
    />

    <div v-if="cvErrors.file && cvErrors.file.length" class="mt-2 space-y-1 text-center">
      <span
        v-for="(error, index) in cvErrors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error[0] }}
      </span>
    </div>
    <div v-if="errors.length" class="mt-2 space-y-1 text-center">
      <span
          v-for="(error, index) in errors"
          :key="index"
          class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    value: {
      type: [Object, null],
      default: null
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const cvErrors = ref([]);
  const emits = defineEmits(['update:value', 'update:errors']);

  const toast = useToast();

  const isDragging = ref(false);
  const isLoading = ref(false);

  async function uploadResume(files) {
    isLoading.value = true;

    try {
      const formData = new FormData();
            formData.append('file', files[0]);

      const response = await axios.post(route('onboarding.uploadCv'), formData);

      emits('update:value', response.data);
      emits('update:errors', []);
      cvErrors.value = [];
    } catch (error) {
      cvErrors.value = error?.response?.data?.errors;
      toast.error( __('Something went wrong while uploading CV.') );
      emits('update:errors', []);
    }

    isLoading.value = false;
  }

  async function handleInputChange(event) {
    await uploadResume(event.target.files);
    event.target.value = '';
  }

  async function handleDrop(event) {
    isDragging.value = false;
    await uploadResume(event.dataTransfer.files);
  }

  function handleDragOver() {
    isDragging.value = true;
  }

  function handleDragLeave() {
    isDragging.value = false;
  }

  function handleDeleteButtonClick() {
    cvErrors.value = [];
    emits('update:value', null);
    emits('update:errors', []);
  }
</script>
