<template>
  <div class="sticky bottom-2 mt-8">
    <div
      class="relative rounded-app bg-white p-2 shadow-[0_0_15px_-3px_rgba(0,0,0,0.1),0_0_6px_-4px_rgba(0,0,0,0.1)]"
    >
      <div class="gap-4 md:grid md:grid-cols-2">
        <div>
          <a :href="route('redesign.company.jobs.index', 'active')">
            <Button
              content="Cancel"
              :disabled="isLoading"
              variation="normal"
              type="button"
            />
          </a>
        </div>
        <div class="text-right">
          <Button
            :content="metadata.status ? __('Unlist') : __('Save')"
            type="submit"
            :disabled="isLoading"
            variation="outline"
            @click="updateAction('save')"
            value="Save"
          />
          <Button
            :content="metadata.status ? 'Save' : 'Post job'"
            type="submit"
            :disabled="isLoading"
            variation="fill"
            @click="updateAction('post')"
            value="Post"
            class="ml-2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { reactive } from 'vue';

  import { Button } from '../../../../components/global';
  import { __ } from '../../../../helpers';

  const props = defineProps({
    isLoading: {
      type: Boolean,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    }
  });

  const emit = defineEmits(['update:status', 'updateAction']);

  const updateAction = (val) => {
    emit('updateAction', val);
  };

  const setStatus = (val) => {
    emit('update:status', val);
  };
</script>
