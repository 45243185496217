<template>
  <div>
    <div class="space-y-1">
      <span
        :class="`${errors.length ? 'text-error' : 'text-zinc-800'} block font-medium lg:text-lg`"
      >
        {{ `${label}${required ? '*' : ''}` }}
      </span>
      <span
        v-if="description"
        :class="`${errors.length ? 'text-error' : 'text-zinc-800'} block text-sm leading-4`"
      >
        {{ description }}
      </span>
    </div>

    <div class="mt-4 flex flex-wrap gap-3">
      <button
        v-for="option in options"
        :key="option.id"
        type="button"
        :class="`${isOptionSelected(option) ? 'bg-secondary text-white' : 'bg-secondary/10 text-zinc-800'} rounded-lg px-4 py-2 text-left font-medium`"
        @click="handleOptionButtonClick(option)"
      >
        {{ option.name }}
      </button>
    </div>

    <div v-if="errors.length" class="mt-3 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    label: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Array, Object, null],
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  const isMultipleSelection = computed(() => {
    return Array.isArray(props.value);
  });

  function isOptionSelected(option) {
    if (isMultipleSelection.value) {
      return props.value.find((item) => item.id === option.id);
    }

    return props.value && props.value.id === option.id;
  }

  function handleOptionButtonClick(option) {
    if (isMultipleSelection.value) {
      let items;

      if (props.value.find((item) => item.id === option.id)) {
        items = props.value.filter((item) => item.id !== option.id);
      } else {
        items = [...props.value, option];
      }

      emits('update:value', items);
    } else {
      emits('update:value', option);
    }

    emits('update:errors', []);
  }
</script>
