
<template>
  <Listbox as="div" class="sm:w-52 sm:ml-auto" v-model="selectedLocations" multiple>
    <div class="relative">
      <ListboxButton
          class="w-full bg-white py-1.5 pl-3 pr-10 font-semibold shadow-sm rounded-app border border-gray-200 sm:text-sm sm:leading-6 focus:ring-0 focus:border-gray-200"
      >
        <span class="block truncate text-left leading-6 sm:text-right">
          {{ selectedLocations.length > 0 ? __('Selected') : __('Select') }}
        </span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <span class="material-symbols-rounded group-hover:text-white">expand_more</span>
        </span>
      </ListboxButton>

      <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
      >
        <ListboxOptions
            class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm"
        >
          <ListboxOption
              as="template"
              v-for="(item, index) in componentFilters"
              :key="index"
              :value="item"
              v-slot="{ active, selected }"
          >
            <li :class="[active ? 'bg-black text-white' : 'text-gray-900', 'relative text-right cursor-default select-none py-2 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold ' : 'font-normal', 'block truncate']">{{ item.name }}</span>

              <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pl-3 pr-1.5']">
                 <span class="material-symbols-rounded text-secondary"> done </span>
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import { ref, onMounted, inject, watch, nextTick } from 'vue';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { __ } from '../../../../helpers';

const emit = defineEmits(['toggleFilter']);

const componentFilters = inject('componentFilters');
const selectedLocations = ref([]);
let isUserInteraction = false;
const jobHasChanged = inject('selectedJob');
const tabHasChanged = inject('tabHasChanged');

onMounted(async () => {
  selectedLocations.value = [];
  // Set isUserInteraction after component is loaded to prevent watcher from firing on component load
  await nextTick();
  isUserInteraction = true;
});

watch(selectedLocations, (newSelectedLocations, oldSelectedLocations) => {
  if (isUserInteraction) {
    const hasNullValue = newSelectedLocations.some((item) => item.id === null);
    // if (hasNullValue) {
    //   selectedLocations.value = newSelectedLocations.filter((item) => item.id === null);
    // }
    const newIds = hasNullValue ? null : newSelectedLocations.map((item) => item.id);
    emit('toggleFilter', newIds);
  }
});

watch(jobHasChanged, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    selectedLocations.value = [];
  }
});

watch(tabHasChanged, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    selectedLocations.value = [];
  }
});
</script>

