export function formatInputDate(value) {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function formatInputDateTime(value) {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function formatDateWithoutUTC(dateString) {
  const [datePart] = dateString.split(' ');
  const [year, month, day] = datePart.split('-');

  return `${day}.${month}.${year}`;
}

export function formatTimeWithoutUTC(dateString) {
  const [, timePart] = dateString.split(' ');
  const [hours, minutes] = timePart.split(':');

  return `${hours}:${minutes}`;
}

export function formatDate(date) {
  return new Date(date).toLocaleDateString('ro-RO', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
}

export function formatTime(date) {
  return new Date(date).toLocaleTimeString('ro-RO', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
}

export function formatDateTime(date) {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const formattedTime = new Date(date).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });

  return `${formattedDate} ${formattedTime}`;
}

export function formatDateToMonthYear(date) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short'
  });
}

export function getTimezone() {
  const offset = new Date().getTimezoneOffset();
  const offsetHours = Math.floor(Math.abs(offset) / 60);
  const offsetMinutes = Math.abs(offset) % 60;
  const offsetSign = offset > 0 ? '-' : '+';

  return {
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    offset: `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`
  };
}
