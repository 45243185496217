<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell">
    <Popover v-if="props.columnData !== '-'" v-slot="{ open }" class="relative text-center">

      <PopoverButton :class="open ? 'text-white' : 'text-white/90'" class="relative group inline-flex items-center">
        <div class="absolute -top-[0.95rem] -right-[0.85rem]">
          <p class="text-[.70rem] leading-[.70rem] bg-brandColor1/[.06] text-black rounded-full py-[.365rem] px-[.525rem] font-bold">{{ props.columnData.length }}</p>
        </div>
        <span class="material-symbols-rounded text-brandColor1 hover:text-brandColor1/[.80] text-2xl">edit_note</span>
      </PopoverButton>

      <transition enter-active-class="transition duration-200 ease-out" enter-from-class="translate-y-1 opacity-0" enter-to-class="translate-y-0 opacity-100" leave-active-class="transition duration-150 ease-in" leave-from-class="translate-y-0 opacity-100" leave-to-class="translate-y-1 opacity-0">
        <PopoverPanel class="absolute min-w-[22rem] hasEar shadow-redesign text-left left-1/2 z-30 mt-3 -translate-x-1/2 transform px-4 sm:px-0">
          <div class="rounded-lg shadow-lg ring-1 ring-black/5 px-3 pt-3 bg-white">
            <div class="relative">

              <div class="max-h-40 overflow-y-scroll">
                <div v-for="(note, noteIndex) in props.columnData" :key="noteIndex" :class="noteIndex === props.columnData.length - 1 ? '' : 'border-b-2 border-gray-400 pb-2 mb-2'">
                  <p class="text-gray-400">{{ note.date }}</p>
                  <p class="text-black">{{ note.text }}</p>
                </div>
              </div>

              <div class="relative mt-6">
                <Textarea
                    v-model="notesFormData.text"
                    :value="notesFormData.text"
                    :placeholder="__('Add a note...')"
                    :id="'note-'+candidateIndex"
                    :name="'note-'+candidateIndex"
                ></Textarea>
                <div class="flex py-3">
                  <div class="flex-auto text-left">
                    <button @click="saveNote(props.person, notesFormData.text, candidateIndex)" class="rounded-md bg-brandColor1 px-2 py-1 text-center text-xs text-white shadow-redesign hover:bg-brandColor1/[.80]">{{ __('Save') }}</button>
                  </div>
                  <div class="flex-auto text-right">

                    <PopoverButton @click="onNoteDiscard" class="text-xs text-brandColor1 font-semibold hover:text-brandColor1/[.80">{{ __('Discard') }}</PopoverButton>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </PopoverPanel>
      </transition>
    </Popover>
    <p v-else class="text-center">{{ props.columnData }}</p>
  </td>
</template>

<script setup>

import {__} from "../../../../../helpers";
import {Popover, PopoverButton, PopoverPanel} from "@headlessui/vue";
import {Textarea} from "../../../../global";
import axios from "axios";
import { useToast } from "vue-toastification";
import {inject, ref} from "vue";
import {formatDate} from "../../../../../utils/helpers";

const toast = useToast();
const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});
const recruiter = inject('recruiter');

const notesFormData = ref({
  text: '',
})

const saveNote = (person, text, candidateIndex) => {
  const currentNotes = props.people[candidateIndex]['Notes'];

  axios.post(route('redesign.matching.addNote'), {
    user_id: person.user_id,
    text: text
  }).then((response) => {
    currentNotes.push(
        {
          date: formatDate(new Date())+' - '+recruiter.first_name+' '+recruiter.last_name,
          text: text
        }
    )
    notesFormData.value.text = ''
    toast.success(response.data)
  }).catch((err) => {
    toast.error(err.response.data);
  });
}

const onNoteDiscard = () => {
  notesFormData.value.text = '';
}

</script>