<template>
  <div class="space-y-12">
    <Header
      v-model:user="user"
      :group="group"
      :can-join-group="canJoinGroup"
      :can-leave-group="canLeaveGroup"
      :has-joined-group="hasJoinedGroup"
    />

    <TabGroup>
      <TabList class="flex gap-2 overflow-auto rounded-lg bg-white p-3">
        <Tab v-slot="{ selected }" as="template">
          <button
            :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
          >
            {{ __('About') }}
          </button>
        </Tab>
        <Tab v-slot="{ selected }" as="template">
          <button
            :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
          >
            {{ __('Events') }}
          </button>
        </Tab>
        <Tab v-if="renderMembersTab" v-slot="{ selected }" as="template">
          <button
            :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
          >
            {{ __('Members') }}
          </button>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <About :group="group" />
        </TabPanel>
        <TabPanel>
          <Events :group="group" />
        </TabPanel>
        <TabPanel v-if="renderMembersTab">
          <Members :group="group" :user="user" />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
  import { About } from './components/group/about';
  import { Events } from './components/group/events';
  import { Header } from './components/group/header';
  import { Members } from './components/group/members';

  const props = defineProps({
    group: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    canJoinGroup: {
      type: Boolean,
      default: false
    },
    canLeaveGroup: {
      type: Boolean,
      default: false
    },
    hasJoinedGroup: {
      type: Boolean,
      default: false
    }
  });

  const renderMembersTab = computed(() => {
    return !!props.user.id;
  });

  const user = ref(JSON.parse(JSON.stringify(props.user)));
</script>
