<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell">
    <div class="flex justify-center gap-1.5">
      <a v-for="(value, key) in columnData" :key="key" :href="value" target="_blank" class="text-brandColor1 font-semibold hover:text-brandColor1/[.80]">{{ __(capitalize(key)) }}</a>
    </div>
  </td>
</template>

<script setup>

import {__, capitalize} from "../../../../../helpers";
import axios from "axios";
import { useToast } from "vue-toastification";
import { inject } from 'vue';

const toast = useToast();
const updateCounters = inject('updateCounters');
const updateComponentTotal = inject('updateComponentTotal');

const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});

const rejectFormData = {
  reject_reason: '',
}

const onAcceptReject = (endpoint, candidateIndex) => {
  let rejectReason = (rejectFormData.reject_reason != null) ? rejectFormData.reject_reason : '';

  axios.post(endpoint, {
    reject_reason: rejectReason
  }).then((response) => {
    if (response.data.success){
      props.people.splice(candidateIndex, 1)
      updateCounters();
      updateComponentTotal(props.total - 1)
      toast.success(response.data.message)
    } else {
      toast.error(response.data.message)
    }
  }).catch((err) => {
    toast.error(err.message);
  })

}

</script>