<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Volunteering')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="potted_plant" is-filled />
      </template>
      <template v-if="canEdit" #button>
        <div class="ml-auto">
          <Button
            class="flex items-center gap-1.5"
            variant="ghost"
            @click="handleAddButtonClick"
          >
            <Icon class="text-lg" name="add_box" is-filled />
            <span>{{ __('Add volunteering') }}</span>
          </Button>
        </div>
      </template>
    </SectionHeader>

    <div
      class="shadow-card relative overflow-hidden rounded-2xl bg-white p-6 md:p-8"
    >
      <div
        v-if="progress"
        class="absolute right-0 top-0 rounded-l-full bg-secondary px-3 py-1"
      >
        <span class="block text-xs leading-none text-white">
          {{ progress }}
        </span>
      </div>

      <EmptySection
        v-if="!volunteerings.length"
        :title="__('Add your volunteering')"
      >
        <template #icon>
          <Icon class="text-xl text-zinc-200" name="potted_plant" is-filled />
        </template>
      </EmptySection>

      <div v-else class="space-y-8">
        <Volunteering
          v-for="volunteering in isListExpanded
            ? volunteerings
            : volunteerings.slice(0, 3)"
          :key="volunteering.id"
          :volunteering="volunteering"
          :can-edit="canEdit"
          @edit="handleEditButtonClick(volunteering)"
          @delete="handleDeleteButtonClick(volunteering)"
        />

        <Button
          v-if="volunteerings.length > 3"
          class="mx-auto"
          variant="outline"
          @click="handleExpandListButtonClick"
        >
          {{ __(isListExpanded ? 'See less' : 'See more') }}
        </Button>
      </div>

      <VolunteeringFormModal
        v-if="isFormModalOpen"
        :volunteering="selected"
        @cancel="handleCancelButtonClick"
        @save="handleSaveButtonClick"
      />

      <VolunteeringDeleteModal
        v-if="isDeleteModalOpen"
        :volunteering="selected"
        @cancel="handleCancelButtonClick"
        @continue="handleContinueButtonClick"
      />
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { EmptySection } from '@/pages/components/empty-section';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Volunteering } from './components/volunteering';
  import { VolunteeringDeleteModal } from './components/volunteering-delete-modal';
  import { VolunteeringFormModal } from './components/volunteering-form-modal';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['update-is-editing', 'update-percentages']);

  const progress = computed(() => {
    return fields.value.total
      ? `${fields.value.completed}/${fields.value.total}`
      : '';
  });

  const fields = ref(JSON.parse(JSON.stringify(props.fields)));
  const volunteerings = ref(JSON.parse(JSON.stringify(props.data)));
  const selected = ref(null);
  const isFormModalOpen = ref(false);
  const isDeleteModalOpen = ref(false);
  const pendingAction = ref('');
  const isListExpanded = ref(false);

  function handleExpandListButtonClick() {
    isListExpanded.value = !isListExpanded.value;
  }

  function handleAddButtonClick() {
    selected.value = null;
    pendingAction.value = 'add';

    emits('update-is-editing', true);
  }

  function handleEditButtonClick(volunteering) {
    selected.value = JSON.parse(JSON.stringify(volunteering));
    pendingAction.value = 'edit';

    emits('update-is-editing', true);
  }

  function handleDeleteButtonClick(volunteering) {
    selected.value = JSON.parse(JSON.stringify(volunteering));
    pendingAction.value = 'delete';

    emits('update-is-editing', true);
  }

  function handleCancelButtonClick() {
    emits('update-is-editing', false);
  }

  function handleSaveButtonClick(data) {
    const existingVolunteering = volunteerings.value.find(
      (volunteering) => volunteering.id === data.volunteering.id
    );

    if (existingVolunteering) {
      volunteerings.value = volunteerings.value.map((volunteering) => {
        if (volunteering.id === data.volunteering.id) {
          return data.volunteering;
        }

        return volunteering;
      });
    } else {
      volunteerings.value = [data.volunteering, ...volunteerings.value];
    }

    fields.value = data.fields;

    emits('update-is-editing', false);
    emits('update-percentages', {
      sectionPercentage: data.sectionPercentage,
      resumePercentage: data.resumePercentage
    });
  }

  function handleContinueButtonClick(data) {
    volunteerings.value = volunteerings.value.filter(
      (volunteering) => volunteering.id !== data.volunteering.id
    );

    fields.value = data.fields;

    emits('update-is-editing', false);
    emits('update-percentages', {
      sectionPercentage: data.sectionPercentage,
      resumePercentage: data.resumePercentage
    });
  }

  watch(
    () => props.isEditing,
    (value) => {
      if (pendingAction.value === 'add' || pendingAction.value === 'edit') {
        isFormModalOpen.value = value;
      } else if (pendingAction.value === 'delete') {
        isDeleteModalOpen.value = value;
      }
    }
  );
</script>
