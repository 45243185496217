<template>
  <div
    class="shadow-card relative overflow-hidden rounded-2xl bg-white p-6 md:p-8"
  >
    <div
      v-if="progress"
      class="absolute right-0 top-0 rounded-l-full bg-secondary px-3 py-1"
    >
      <span class="block text-xs leading-none text-white">{{ progress }}</span>
    </div>

    <EmptySection v-if="!data.length" :title="__('Add your languages')">
      <template #icon>
        <Icon class="text-xl text-zinc-200" name="globe" is-filled />
      </template>
    </EmptySection>

    <div v-else class="grid gap-4 md:grid-cols-2 md:gap-6">
      <div
        v-for="language in data"
        :key="language.language.id"
        class="shadow-element flex items-center justify-between rounded-lg bg-white px-3 py-4"
      >
        <span class="font-medium text-zinc-800">
          {{ language.language.name }}
        </span>

        <div class="flex items-center gap-1">
          <Icon
            class="text-xl text-primary"
            :name="language.proficiency.icon"
            is-filled
          />

          <span class="text-zinc-600">
            {{ language.language.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { EmptySection } from '@/pages/components/empty-section';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    }
  });

  const progress = computed(() => {
    return props.fields.total
      ? `${props.fields.completed}/${props.fields.total}`
      : '';
  });
</script>
