<template>
  <Modal
    v-if="showModal"
    open
    @close="closeModal"
  >
    <div class="flex min-h-full flex-col justify-center p-5">
      <h2 class="pb-8 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
        {{ __('Register') }}
      </h2>
      <form @submit.prevent="handleSubmit">

        <div class="w-full h-10 pb-4 border-b border-[#d71f70] justify-center items-center gap-2.5 inline-flex">
            <div class="grow shrink basis-0 text-zinc-800 text-xl font-medium font-['Ubuntu'] leading-normal">
                {{ __('Company Details') }}
            </div>
        </div>

        <div class="grid gap-4 md:grid-cols-2 pt-8">
          <InputText
              id="cui"
              v-model:value="fields.cui.value"
              v-model:errors="fields.cui.errors"
              :label="__('CUI')"
              :placeholder="__('Enter Company CUI')"
              :required="fields.cui.mandatory"
          />
          <InputText
              id="company_name"
              v-model:value="fields.company_name.value"
              v-model:errors="fields.company_name.errors"
              :label="__('Company name')"
              :placeholder="__('Enter Company name')"
              :required="fields.company_name.mandatory"
          />
      </div>
      
      <div class="pb-8 pt-4">
          <InputText
              id="company_address"
              v-model:value="fields.company_address.value"
              v-model:errors="fields.company_address.errors"
              :label="__('Company address')"
              :placeholder="__('Add Company address')"
              :required="fields.company_address.mandatory"
          />
      </div>

      <div class="flex items-center pb-8">
          <InputCheckbox
            id="terms_and_conditions"
            v-model:value="fields.terms_and_conditions.value"
            v-model:errors="fields.terms_and_conditions.errors"
            :html="termsAndConditions"
            class="md:col-span-2 text-sm"
          />
      </div>


      <div class="flex justify-center">
          <Button class="px-5 flex items-center text-center gap-1.5 text-sm font-medium" type="submit">
              <Icon
                  v-if="isLoadingUpdate"
                  class="animate-spin text-lg"
                  name="progress_activity"
              />
              <span>{{ __('Sign Up') }}</span>
          </Button>
      </div>
      </form>
    </div>
  </Modal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { formatInputDate } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Modal } from '@/components/block/modal';
  import { InputCheckbox } from '@/components/form/input-checkbox';
  import { InputDate } from '@/components/form/input-date';
  import { InputLocation } from '@/components/form/input-location';
  import { InputSelect } from '@/components/form/input-select';
  import { InputText } from '@/components/form/input-text';
  import { InputTextarea } from '@/components/form/input-textarea';

  const toast = useToast();

  const fields = ref({
    cui: {
      mandatory: true,
      value: null,
      errors: [],
      options: []
    },
    company_name: {
      mandatory: true,
      value: null,
      errors: [],
      options: []
    },
    company_address: {
      mandatory: true,
      value: null,
      errors: [],
      options: []
    },
    terms_and_conditions: {
      mandatory: true,
      value: false,
      errors: []
    },
  });

  // Define the dynamic label with HTML content
  const termsAndConditions = `
    ${__('I agree with the')} 
    <a class="underline" href="/terms-and-conditions-candidates" target="_blank">
      ${__('terms and conditions')}
    </a>
    ${__('and')} 
    <a class="underline" href="/terms-and-conditions-candidates#confidentialitate" target="_blank">
      ${__('privacy policy')}
    </a>
    .
  `;
  
  // State
  const showModal = ref(false);
  
  // Methods
  const openModal = async () => {
    showModal.value = true;
  };
  
  const closeModal = () => {
    console.log('close modal test');
    showModal.value = false;
  };
  
  // Expose openModal to parent component
  defineExpose({ openModal });

  const emit = defineEmits(['update-companies']);

  async function handleSubmit() {
    const requiredFieldKeys = Object.keys(fields.value).filter(
      (key) => fields.value[key].mandatory
    );
    let firstInvalidField;

    requiredFieldKeys.forEach((key) => {
      if (!isValid(fields.value[key].value)) {
        fields.value[key].errors = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstInvalidField) {
          firstInvalidField = element;
        }
      }
    });

    if (
      Object.values(fields.value).some(
        (field) => field.errors && field.errors.length
      )
    ) {
      if (firstInvalidField) {
        firstInvalidField.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
      return;
    }

    const payload = Object.keys(fields.value).reduce((acc, key) => {
      acc[key] = fields.value[key].value;
      return acc;
    }, {});

    try {
        const response = await axios.post(
            route('redesign.company.recruiter.register'),
            { ...payload }
        );
    
        if (response?.data?.message) {
            toast.success(response.data.message);
        }

        emit('update-companies', response.data.company);
        resetFields();

    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).forEach((key) => {
          if (fields.value[key]) {
            fields.value[key].errors = error.response.data.errors[key];
          }
        });
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }
    closeModal();
  }

  function isValid(value) {
    if (!value) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'object') {
      for (const prop in value) {
        if (value[prop] === null) {
          return false;
        }
      }
      return true;
    }

    return true;
  }

  function resetFields() {
    Object.keys(fields.value).forEach((key) => {
      fields.value[key].value = null;
      fields.value[key].errors = [];
      fields.value[key].options = [];
    });
  }

</script>