<template>
  <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
    <form @submit.prevent="handleSubmit">
      <InputMultiSelect
        id="perks"
        v-model:values="values.perks"
        v-model:errors="errors.perks"
        :label="__('Perk')"
        :placeholder="__('Perk')"
        :options="options"
      />

      <span
        v-if="errorMessage"
        class="mt-8 block text-center text-sm text-error"
      >
        {{ errorMessage }}
      </span>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputMultiSelect } from '@/components/form/input-multi-select';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  });

  const emits = defineEmits(['save', 'cancel']);

  const toast = useToast();

  const values = ref({ perks: JSON.parse(JSON.stringify(props.data)) });
  const errors = ref({ perks: [] });
  const isLoading = ref(false);

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    let firstElement;

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      const response = await axios.put(
        route('redesign.cv.update.perks'),
        values.value.perks
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', {
        data: values.value.perks,
        fields: response.data.fields,
        sectionPercentage: response.data.section_percentage,
        resumePercentage: response.data.percentage
      });
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
