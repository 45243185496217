<template>
  <div
    :class="`${isExpanded ? 'flex-col' : 'flex-row items-center'} ${isDisabled ? 'opacity-50 shadow-none' : 'shadow-card'} flex flex-wrap gap-4 rounded-md bg-white p-4`"
  >
    <div class="flex items-center gap-4">
      <img class="h-14 w-14" :src="logo" :alt="name" />

      <div>
        <span class="block text-lg font-medium leading-normal text-zinc-800">
          {{ name }}
        </span>
        <span class="block leading-normal text-zinc-600">
          {{ description }}
        </span>
        <span class="block leading-normal text-zinc-600">
          {{ integration.expire_copy }}
        </span>
      </div>
    </div>

    <div class="ml-auto flex gap-2">
      <template v-if="isSuspended">
        <span class="rounded-md bg-secondary px-4 py-2 text-sm font-medium text-white">
          {{ __('Suspended') }}
        </span>
        <Button
            class="flex items-center justify-center gap-1.5"
            :disabled="isDisabled || isLoading"
            @click="handleReactivateButtonClick"
        >
          <Icon
              v-if="isLoading"
              class="animate-spin text-lg"
              name="progress_activity"
          />
          <span>{{ __('Reactivate') }}</span>
        </Button>
      </template>

      <template v-else-if="isExpired">
        <span  class="rounded-md bg-secondary px-4 py-2 text-sm font-medium text-white">
          {{ __('Expired') }}
        </span>
        <Button
            class="flex items-center justify-center gap-1.5"
            :disabled="isDisabled || isLoading"
            @click="handleRepostButtonClick"
        >
          <Icon
              v-if="isLoading"
              class="animate-spin text-lg"
              name="progress_activity"
          />
          <span>{{ __('Repost') }}</span>
        </Button>
      </template>

      <template v-else-if="isPublished && type === 'api'">
        <span
            v-if="isPublished"
            class="rounded-md bg-success px-4 py-2 text-sm font-medium text-white"
        >
        {{ __('Published') }}
      </span>
        <Button
            class="flex items-center justify-center gap-1.5"
            :disabled="isDisabled || isLoading"
            @click="handleSuspendButtonClick"
        >
          <Icon
              v-if="isLoading"
              class="animate-spin text-lg"
              name="progress_activity"
          />
          <span>{{ __('Suspend job') }}</span>
        </Button>
      </template>

      <template v-else-if="isPublished && type !== 'api'">
        <span class="rounded-md bg-success px-4 py-2 text-sm font-medium text-white">
        {{ __('Published') }}
      </span>
      </template>

      <template v-else>
        <Button
          v-if="isExpanded"
          variant="outline"
          @click="handleCancelButtonClick"
        >
          {{ __('Cancel') }}
        </Button>

        <Button
          class="flex items-center justify-center gap-1.5"
          :disabled="isDisabled || isLoading"
          @click="handlePostButtonClick"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ typeCopy }}</span>
        </Button>
      </template>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    integration: {
      type: Object,
      required: true
    },
    job: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['cancel', 'post', 'suspend', 'reactivate', 'repost']);

  const logo = computed(() => {
    return `/uploads/${props.integration.logo}`;
  });

  const name = computed(() => {
    return props.integration.name;
  });

  const description = computed(() => {

    if (isExpanded.value) {
      if (isPublished.value){
        return `${__('Are you sure you suspend the job on')} ${props.integration.name}?`
      } else if (isExpired.value) {
        return `${__('Are you sure you want to repost this job on')} ${props.integration.name}?`
      } else if (isSuspended.value) {
        return `${__('Are you sure you want to reactivate this job on')} ${props.integration.name}?`
      } else if (isNotPublished.value) {
        return `${__('Are you sure you want to post on')} ${props.integration.name}?`
      }
    } else {
      return props.integration.description;
    }

  });

  const type = computed(() => {
    return props.integration.type;
  });

  const typeCopy = computed(() => {
    switch (type.value) {
      case 'api':
        return __('Post Job');
      case 'feed':
        return __('Share Job');
      case 'custom1':
        return __('Integrate with Ideed');
      default:
        return __('Integration not available');
    }
  });

  const isPublished = computed(() => {
    return props.integration.state === 'PUBLISHED';
  });

  const isNotPublished = computed(() => {
    return props.integration.state === 'NOT_PUBLISHED';
  });

  const isExpired = computed(() => {
    return props.integration.state === 'EXPIRED';
  });

  const isSuspended = computed(() => {
    return props.integration.state === 'SUSPENDED';
  });


  const isExpanded = computed(() => {
    return props.integration.isExpanded;
  });

  const isDisabled = computed(() => {
    return props.integration.isDisabled;
  });

  const isLoading = computed(() => {
    return props.integration.isLoading;
  });

  function handleCancelButtonClick() {
    emits('cancel');
  }

  function handlePostButtonClick() {
    emits('post');
  }
  function handleSuspendButtonClick() {
    emits('suspend');
  }
  function handleReactivateButtonClick() {
    emits('reactivate');
  }
  function handleRepostButtonClick() {
    emits('repost');
  }

</script>
