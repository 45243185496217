<template>
  <div>
    <div>
      <h1 class="text-5xl font-medium leading-none text-zinc-800">
        {{ __('My jobs') }}
      </h1>
      <p class="mt-2 text-base text-zinc-600">
        {{ __('Manage the jobs you are most interested in') }}
      </p>
    </div>

    <Matches
      :matches="matches"
      @openModal="handleModal"
      :config_general_single_company="config_general_single_company"
    />
    <Invitations
      :invitations="invitations"
      :matches="matches"
      @openModal="handleModal"
      :config_general_single_company="config_general_single_company"
    />
    <Applications
      :applications="applications"
      @openModal="handleModal"
      :config_general_single_company="config_general_single_company"
    />
    <SavedJobs
      :saved="saved"
      @openModal="handleModal"
      :config_general_single_company="config_general_single_company"
    />

    <JobModal
      :open="modalOpen"
      @close="modalOpen = false"
      :job="modalOpenJob"
      user_type="user"
      @save="handleSave"
      @accept_invitation="handleAcceptInvitation"
      @decline_invitation="handleDeclineInvitation"
      :config_general_single_company="config_general_single_company"
    />
  </div>
</template>

<script setup>
  import { __ } from '../../../helpers';
  import { Icon, Button } from '../../../components/global';
  import {
    Matches,
    Invitations,
    JobModal,
    Applications,
    SavedJobs
  } from './partials';
  import { computed, reactive, ref } from 'vue';

  const props = defineProps({
    matches: {
      type: Array,
      required: true
    },
    invitations: {
      type: Array,
      required: true
    },
    applications: {
      type: Array,
      required: true
    },
    saved: {
      type: Array,
      required: true
    },
    config_general_single_company: {
      type: Number,
      required: true
    }
  });

  const matches = reactive(props.matches);
  const invitations = reactive(props.invitations);
  const applications = reactive(props.applications);
  const saved = reactive(props.saved);

  const modalOpen = ref(false);
  const modalOpenJob = ref(null);

  const handleModal = (job) => {
    modalOpen.value = true;
    modalOpenJob.value = job;
  };

  const handleSave = (job) => {
    // remove job from saved where id === job.id
    const index = saved.findIndex((j) => j.job.id === job.id);

    if (index !== -1) {
      saved.splice(index, 1);
    } else {
      saved.push({
        job: job,
        new: 1,
        saved_ago: __('Just now')
      });
    }
    console.log(job, job.id, saved);
  };

  const handleAcceptInvitation = () => {
    // remove job from invitations where id === job.id
    console.log('Accept invitation', modalOpenJob);

    const index = invitations.findIndex(
      (i) => i.job.id === modalOpenJob.value?.id
    );
    if (index !== -1) {
      matches.push(invitations[index]);
      invitations.splice(index, 1);
    }
  };

  const handleDeclineInvitation = () => {
    // remove job from invitations where id === job.id
    const index = invitations.findIndex(
      (j) => j.job.id === modalOpenJob.value?.id
    );

    if (index !== -1) {
      invitations.splice(index, 1);
    }
  };
</script>
