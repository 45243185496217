<template>
  <tr
    :class="`${isCurrentUser ? 'bg-violet-50' : 'bg-white'} border-b last:border-b-0`"
  >
    <td class="px-8 py-4">
      <div class="flex items-center gap-4">
        <img
          class="h-12 w-12 rounded-full border border-zinc-200"
          :src="profilePicture"
          :alt="__('Profile picture')"
        />

        <div class="space-y-0.5">
          <span class="block text-sm font-medium text-zinc-800">
            {{ name }}
          </span>
          <span class="block text-sm text-zinc-600">{{ email }}</span>
        </div>
      </div>
    </td>

    <td v-if="canEdit" class="px-8 py-4">
      <InputToggle
        v-if="!isCurrentUser"
        :id="`active-${member.id}`"
        :value="active"
        :is-loading="isLoadingActive"
        @update:value="handleActiveToggle"
      />
    </td>

    <td class="px-8 py-4">
      <div class="flex items-center gap-2">
        <span class="text-sm text-zinc-600">{{ role }}</span>

        <Popover
          v-if="canEdit && !isCurrentUser"
          @close="handleRolePopoverClose"
        >
          <template #trigger>
            <button
              class="flex items-center rounded-md p-2 transition-colors hover:bg-primary/5"
              tabindex="-1"
              :aria-label="__('Change role')"
              @click="click"
            >
              <Icon class="text-xl text-primary" name="settings" />
            </button>
          </template>
          <template #content="{ close }">
            <form
              class="flex flex-col items-end gap-4 p-4"
              @submit.prevent="handleRoleSubmit(close)"
            >
              <InputSelect
                :id="`role-${member.id}`"
                v-model:value="values.role"
                v-model:errors="errors.role"
                class="min-w-[20rem]"
                :label="__('Role')"
                :placeholder="__('Role')"
                :options="options"
                required
              >
                <template #icon>
                  <Icon class="text-lg text-neutral-400" name="search" />
                </template>
              </InputSelect>

              <Button
                class="flex items-center gap-1.5"
                type="submit"
                :disabled="isLoadingRole"
              >
                <Icon
                  v-if="isLoadingRole"
                  class="animate-spin text-lg"
                  name="progress_activity"
                />
                <span>{{ __(isLoadingRole ? 'Loading...' : 'Save') }}</span>
              </Button>
            </form>
          </template>
        </Popover>
      </div>
    </td>

    <td class="px-8 py-4">
      <span class="block text-sm text-zinc-600">
        {{ __('Member since') }}
        <br />
        {{ date }}
      </span>
    </td>

    <td v-if="canEdit" class="px-8 py-4">
      <Button
        v-if="!isCurrentUser"
        variant="outline"
        @click="handleRemoveButtonClick"
      >
        <span>{{ __('Remove') }}</span>
      </Button>
    </td>
  </tr>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { formatDateTime } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Popover } from '@/components/block/popover';
  import { InputSelect } from '@/components/form/input-select';
  import { InputToggle } from '@/components/form/input-toggle';

  const props = defineProps({
    member: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    isCurrentUser: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['update', 'remove']);

  const toast = useToast();

  const placeholderImage = '/images/logo_placeholder.png';

  const profilePicture = computed(() => {
    return props.member.profile_picture || placeholderImage;
  });

  const name = computed(() => {
    return props.member.name;
  });

  const email = computed(() => {
    return props.member.email;
  });

  const active = computed(() => {
    return props.member.active;
  });

  const role = computed(() => {
    return props.member.is_company_owner
      ? __('Company Owner')
      : props.member.type;
  });

  const date = computed(() => {
    return formatDateTime(props.member.created_at);
  });

  const options = computed(() => {
    return props.options.types.filter(
      (type) => type.name !== props.member.type
    );
  });

  const values = ref({ role: null });
  const errors = ref({ role: [] });
  const isLoadingActive = ref(false);
  const isLoadingRole = ref(false);

  function handleRolePopoverClose() {
    values.value.role = null;
  }

  function handleRemoveButtonClick() {
    emits('remove');
  }

  async function handleActiveToggle() {
    isLoadingRole.value = true;

    try {
      const response = await axios.post(
        route('redesign.company.members.suspend'),
        { member_id: props.member.id }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('update', {
        ...props.member,
        active: response.data.recruiter.active
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingRole.value = false;
  }

  async function handleRoleSubmit(close) {
    if (!values.value.role) {
      errors.value.role = [__('This field is required')];
      return;
    }

    isLoadingRole.value = true;

    try {
      const response = await axios.post(
        route('redesign.company.members.update'),
        { member_id: props.member.id, role_id: values.value.role.id }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('update', { ...props.member, type: response.data.recruiter.type });
      close();
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingRole.value = false;
  }
</script>
