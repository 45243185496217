<template>
  <div id="values">
    <div class="mt-8 md:grid md:grid-cols-2">
      <SectionHeader icon="accessibility" title="Job Inclusivity" />
    </div>

    <div class="p-8 shadow-md mt-8 rounded-app bg-white relative">
      <div>
        <div class="md:grid md:grid-cols-2 gap-4">
          <div>
            <label class="text-sm text-[#58585E] mb-2 block">{{
              __("Vulnerabilities")
            }}</label>
            <FormMultiselect
              v-model="data.job.vulnerability"
              :values="data.options.vulnerability"
              multiple
              placeholder="Select vulnerabilities"
              icon="psychiatry"
            />
          </div>
          <div>
            <label class="text-sm text-[#58585E] mb-2 block">{{
              __("Disabilities")
            }}</label>
            <FormMultiselect
              v-model="data.job.disability"
              :values="data.options.disability"
              multiple
              placeholder="Select disabilities"
              icon="accessibility"
            />
          </div>
        </div>

        <VisualEditor
          v-model="job.job.inclusion_details"
          label="Inclusion details"
          class="mt-4"
          :error="errors.inclusion_details"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  Button,
  Icon,
  FormMultiselect,
  SectionHeader,
  Textarea,
  VisualEditor,
} from "../../../../components/global";

import { useForm } from "../../../../utils";
import { useToast } from "vue-toastification";
import { __ } from "../../../../helpers";

const props = defineProps({
  data: {
    type: Object,
    mandatory: true,
  },
  job: {
    type: Object,
    mandatory: true,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});
console.log(props.data);
const toast = useToast();
</script>
