<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Integrations')">
      <template #icon>
        <Icon
          class="text-3xl text-primary"
          name="integration_instructions"
          is-filled
        />
      </template>
    </SectionHeader>

    <div
      class="shadow-card space-y-8 rounded-lg bg-white px-4 py-6 md:px-6 md:py-8"
    >
      <Integration
        v-for="(integration, index) in integrations"
        :key="integration.id"
        v-model:integration="integrations[index]"
      />
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Integration } from './components/integration';

  const toast = useToast();

  const integrations = ref([]);

  async function fetchIntegrations() {
    try {
      const response = await axios.get(
        route('redesign.list.company.integration')
      );

      integrations.value = response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
            __('An unexpected error has occured. Please try again later.')
        );
      }
    }
  }

  onMounted(async () => {
    await fetchIntegrations();
  });
</script>
