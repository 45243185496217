<template>
  <div class="space-y-8">
    <SectionHeader :title="__('My Profile')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="account_box" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
      <form @submit.prevent="handleSubmit">
        <div class="space-y-4">
          <UploadImage
              v-model:value="values.profile_photo"
              v-model:file="files.profile"
              v-model:errors="errors.profile_photo"
          />

          <div class="grid gap-4 md:grid-cols-2">
            <InputText
              id="first_name"
              v-model:value="values.first_name"
              v-model:errors="errors.first_name"
              :label="__('First Name')"
              :placeholder="__('First Name')"
              required
            />

            <InputText
              id="last_name"
              v-model:value="values.last_name"
              v-model:errors="errors.last_name"
              :label="__('Last Name')"
              :placeholder="__('Last Name')"
              required
            />

            <InputSelect
              id="preferred_language"
              v-model:value="values.preferred_language"
              v-model:errors="errors.preferred_language"
              :label="__('Preferred Language')"
              :placeholder="__('Preferred Language')"
              :options="options.languages"
            >
              <template #icon>
                <Icon class="text-lg text-neutral-400" name="work" is-filled />
              </template>
            </InputSelect>
          </div>
        </div>

        <div
          class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
        >
          <Button
            class="flex items-center justify-center gap-1.5"
            :type="isLoading ? 'button' : 'submit'"
            :disabled="isLoading"
          >
            <Icon
              v-if="isLoading"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span>{{ __('Save') }}</span>
          </Button>
<!--          <Button variant="ghost" @click="handleCancelButtonClick">-->
<!--            <span>{{ __('Cancel') }}</span>-->
<!--          </Button>-->
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '../../../../helpers';
  import { Button } from '../../../../components/block/button';
  import { Icon } from '../../../../components/block/icon';
  import { InputSelect } from '../../../../components/form/input-select';
  import { InputText } from '../../../../components/form/input-text';
  import { SectionHeader } from '../../../components/section-header';
  import { UploadImage } from '../../resume/components/about-me/components/about-me-edit/components/upload-image';

  import axios from 'axios';

  const props = defineProps({
    profile: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  });
  const emits = defineEmits(['cancel']);

  const toast = useToast();

  const values = ref({
    profile_photo: props.profile.profile_photo || null,
    first_name: props.profile.first_name,
    last_name: props.profile.last_name,
    preferred_language: props.profile.preferred_language || null,
  });
  const errors = ref({
    profile_photo: [],
    first_name: [],
    last_name: [],
    preferred_language: [],
  });
  const files = ref({ profile_photo: null });
  const isLoading = ref(false);


  async function processUserImage()
  {
    if (files.value.profile) {
      try {
        const formData = new FormData();
        formData.append('profile_photo', files.value.profile);

        const response = await axios.post(route('redesign.candidate.settings.uploadPhoto'), formData);
        values.value.profile_photo = response.data.full_path;
      } catch (error) {
        toast.error(error?.response?.data?.message || __('Something went wrong while uploading the profile image.'));
        throw error;
      }
    }
  }

  async function handleSubmit() {
    const requiredFieldKeys = [
      'first_name',
      'last_name',
      'preferred_language'
    ];
    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      await processUserImage();

      const response = await axios.post(
        route('redesign.candidate.settings.updateDetails'),
        values.value
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }
    isLoading.value = false;
  }
</script>
