<template>
  <div class="font-primary mt-12">
    <div
      class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3"
    >
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">
          {{ __("Personal Information") }}
        </h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">
          {{ __("Update your photo and personal details.") }}
        </p>
      </div>

      <div
        class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 bg-white p-4 rounded-app shadow-md"
      >
        <div
          class="sm:col-span-3"
          v-for="(field, index) in fields"
          :key="field.id"
        >
          <label
            :for="field.id"
            class="block text-sm font-medium leading-6 text-gray-900"
            >{{ field.placeholder }}</label
          >
          <div class="mt-2">
            <InputText
              :type="field.type"
              :placeholder="__('Fill') + ' ' + __(field.name)"
              v-model="fields[index].value"
              :value="fields[index].value"
              :id="field.id"
              :name="field.id"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 pt-4"
    >
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">
          {{ __("Documents") }}
        </h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">
          {{ __("Update your portfolio and bio.") }}
        </p>
      </div>

      <div class="grid md:col-span-2 bg-white p-4 rounded-app shadow-md">
        <div
          v-for="(document, index) in documents"
          :key="document.name"
          class="mt-4"
        >
          <label
            for="first-name"
            class="block text-sm font-medium leading-6 text-gray-900"
            >{{ document.name }}</label
          >
          <Dropfile
            v-model="uploadedDocuments[document.name]"
            :name="index"
            :extensions="document.extensions"
            :maxFileSize="document.max_file_size"
          />
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            @click="handleCancel"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {{ __("Cancel") }}
          </button>
          <button
            type="submit"
            @click="handleModal"
            className="rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
          >
            {{ __("Save") }}
          </button>
        </div>
      </div>
    </div>
    <Modal
      :open="modalIsOpen"
      @submit="handleSubmit"
      title="Are you sure?"
      description="Are you sure all the informations and documents are correct?"
      icon="warning"
    />
    <LoadingOverlay
      :open="loading"
      title="Loading..."
      description="Please wait while we are processing your request."
    />
  </div>
</template>

<script setup>
import { ref, toRef, toRefs } from "vue";
import { __ } from "../../../helpers";
import { InputText, Modal, LoadingOverlay } from "../../global";
import Dropfile from "./DropFile.vue";

const props = defineProps({
  documents: {
    type: Array,
    required: true,
  },
});

// Create a copy of documents props
const uploadedDocuments = ref({});

// Loading
const loading = ref(false);

// Fields
const fields = ref([
  {
    name: __("First Name"),
    value: "",
    placeholder: __("First Name"),
    type: "text",
    id: "first-name",
  },
  {
    name: __("Last Name"),
    value: "",
    placeholder: __("Last Name"),
    type: "text",
    id: "last-name",
  },
  {
    name: __("ID Number"),
    value: "",
    placeholder: __("ID Number"),
    type: "number",
    id: "id-number",
  },
]);

const modalIsOpen = ref(false);

const handleModal = () => {
  modalIsOpen.value = !modalIsOpen.value;
};

const handleSubmit = () => {
  modalIsOpen.value = !modalIsOpen.value;

  loading.value = true;

  // TODO OCR:
  // Check if documents are correct

  // Check if fields are correct

  // Make an request with first name, last name and id number

  // For each document uploaded make a request to upload it with id number

  // Make requests for each uploaded document

  // Simulate request
  setTimeout(() => {
    // Disable loading
    loading.value = false;

    // Clear fields and documents to prevent double submit

    // Go to thank you page
    document.location.href = "/";
  }, 5000);
};

const handleCancel = () => {
  modalIsOpen.value = !modalIsOpen.value;
  console.log("cancel");
};
</script>
