<template>
  <div class="relative h-40 overflow-hidden rounded-md">
    <iframe
      v-if="video.player_type === 'youtube'"
      :src="`https://www.youtube.com/embed/${video.video_id}`"
      class="pointer-events-none h-full w-full"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    />

    <button
      class="absolute inset-0 flex items-center justify-center bg-zinc-800/75 opacity-0 transition-opacity hover:opacity-100 focus:opacity-100"
      type="button"
      :aria-label="__('Delete')"
      @click="handleDeleteButtonClick"
    >
      <Icon class="text-xl text-white" name="delete" />
    </button>
  </div>
</template>

<script setup>
  import { Icon } from '@/components/block/icon';

  defineProps({
    video: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['delete']);

  function handleDeleteButtonClick() {
    emits('delete');
  }
</script>
