<template>
  <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
    <form class="space-y-8" @submit.prevent="handleSubmit">
      <div>
        <span class="mb-1.5 block leading-normal text-zinc-800">
          {{ __('Add photo gallery (max. 6 photos)') }}
        </span>

        <div
          class="grid grid-cols-2 gap-2 sm:grid-cols-3 sm:gap-3 md:grid-cols-4 md:gap-4"
        >
          <Image
            v-for="(item, index) in values.images"
            :key="item"
            :image="item"
            @delete="handleDeleteImageButtonClick(index)"
          />

          <UploadImage
            v-model:errors="errors.imageUpload"
            :class="`${values.images.length ? (values.images.length === 6 ? 'hidden' : 'block') : 'col-span-2 sm:col-span-3 md:col-span-4'}`"
            @change="handleUploadImageChange"
          />
        </div>
      </div>
      <div>
        <span class="mb-1.5 block leading-normal text-zinc-800">
          {{ __('Add videos (YouTube and Cloudfare links)') }}
        </span>

        <div class="space-y-4">
          <div
            v-if="values.videos.length"
            class="grid grid-cols-2 gap-2 sm:grid-cols-3 sm:gap-3 md:grid-cols-4 md:gap-4"
          >
            <Video
              v-for="(item, index) in values.videos"
              :key="item"
              :video="item"
              @delete="handleDeleteVideoButtonClick(index)"
            />
          </div>

          <div class="flex items-start gap-2">
            <InputText
              id="video-url"
              v-model:value="values.videoUrl"
              v-model:errors="errors.videoUrl"
              class="w-full"
              :placeholder="__('Add video link')"
            />

            <Button
              class="flex items-center justify-center py-3"
              :aria-label="__('Submit')"
              @click="handleAddVideoButtonClick"
            >
              <Icon class="text-xl" name="arrow_forward" />
            </Button>
          </div>
        </div>
      </div>

      <span
        v-if="errorMessage"
        class="mt-8 block text-center text-sm text-error"
      >
        {{ errorMessage }}
      </span>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputText } from '@/components/form/input-text';
  import { Image } from './components/image';
  import { UploadImage } from './components/upload-image';
  import { Video } from './components/video';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  });

  const emits = defineEmits(['save', 'cancel']);

  const toast = useToast();

  const values = ref({
    images: Object.values(JSON.parse(JSON.stringify(props.data))).filter(
      (item) => item.type === 'image'
    ),
    videos: Object.values(JSON.parse(JSON.stringify(props.data))).filter(
      (item) => item.type === 'video'
    ),
    videoUrl: ''
  });
  const errors = ref({ imageUpload: [], videoUrl: [] });
  const isLoading = ref(false);

  function handleUploadImageChange(base64, file) {
    if (values.value.images.length >= 6) {
      return;
    }

    values.value.images.push({
      url: base64,
      file: file,
      type: 'image'
    });
  }

  function handleAddVideoButtonClick() {
    // TODO: Handle Cloudflare links

    const youtubeRegex =
      /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;

    const youtubeMatch = values.value.videoUrl.trim().match(youtubeRegex);

    if (youtubeMatch && youtubeMatch[1]) {
      values.value.videos.push({
        url: values.value.videoUrl,
        video_id: youtubeMatch[1],
        type: 'video'
      });

      values.value.videoUrl = '';
    } else {
      errors.value.videoUrl = [
        __(
          'Invalid video link. Please ensure the link is a valid YouTube or Cloudflare URL.'
        )
      ];
    }
  }

  function handleDeleteImageButtonClick(index) {
    values.value.images = values.value.images.filter((_, i) => i !== index);
  }

  function handleDeleteVideoButtonClick(index) {
    values.value.videos = values.value.videos.filter((_, i) => i !== index);
  }

  async function uploadFile(file) {
    const formData = new FormData();

    formData.append('file', file);

    const response = await axios.post(
      route('redesign.company.uploadFile'),
      formData
    );

    return response.data.full_path;
  }

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    isLoading.value = true;

    try {
      const imageFiles = values.value.images
        .map((item, index) => ({ file: item.file, index }))
        .filter((item) => item.file);

      const imageUploadPromiseList = imageFiles.map((item) =>
        uploadFile(item.file)
      );

      const imageUrlList = await Promise.all(imageUploadPromiseList);

      imageFiles.forEach((item, index) => {
        values.value.images[item.index].url = imageUrlList[index];
      });

      const response = await axios.post(
        route('redesign.company.update.media'),
        [...values.value.images, ...values.value.videos]
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', response.data.data);
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
