<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Details')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="local_activity" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-card space-y-6 rounded-lg bg-white p-6 md:p-8">
      <div v-if="streamingPlatform" class="space-y-3">
        <span class="block text-lg font-medium text-zinc-800">
          {{ __('Streaming platform') }}
        </span>

        <a :href="streamingPlatform" target="_blank" class="flex gap-1.5">
          <Icon name="link" class="text-xl text-primary" />
          <span
            class="text-sm text-zinc-800 hover:underline hover:underline-offset-2"
          >
            {{ streamingPlatform }}
          </span>
        </a>
      </div>

      <div v-if="isOnSite" class="space-y-3">
        <span class="block text-lg font-medium text-zinc-800">
          {{ __('Location') }}
        </span>

        <div class="flex gap-1.5">
          <Icon name="distance" is-filled class="text-xl text-primary" />
          <span class="text-sm text-zinc-800">
            {{ `${address}, ${location}` }}
          </span>
        </div>
      </div>

      <div class="space-y-3">
        <span class="block text-lg font-medium text-zinc-800">
          {{ __('About the event') }}
        </span>

        <p class="block leading-normal text-zinc-600">
          <span>{{ description }}</span>

          <button
            v-if="isDescriptionExpandButtonVisible"
            class="text-primary underline-offset-2 hover:underline"
            @click="handleDescriptionExpandButtonClick"
          >
            {{ __('see more') }}
          </button>
        </p>
      </div>
      
      <div class="space-y-3" v-if="points">
        <span class="block text-lg font-medium text-zinc-800">
          {{ __('Reward') }}
        </span>

        <div class="flex gap-1.5">
          <Icon name="sports_esports" class="text-xl text-primary" />
          <span class="text-sm text-zinc-800">
            {{ __('By attending this event you gain') }}: <strong> {{ points }} {{ __('points') }}.</strong>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';
  import { __ } from '@/helpers';

  const props = defineProps({
    details: {
      type: Object,
      required: true
    },
    points: {
      type: String,
      required: false
    }
  });

  const isOnSite = computed(() => {
    return props.details.type.id === 2;
  });

  const streamingPlatform = computed(() => {
    return props.details.streaming_platform;
  });

  const location = computed(() => {
    return `${props.details.location.city.name}, ${props.details.location.country.name}`;
  });

  const address = computed(() => {
    return props.details.address;
  });

  const description = computed(() => {
    return props.details.description.length <= 250 ||
      isDescriptionExpanded.value
      ? props.details.description
      : `${props.details.description.substring(0, 250)}...`;
  });

  const isDescriptionExpandButtonVisible = computed(() => {
    return (
      props.details.description.length > 250 && !isDescriptionExpanded.value
    );
  });

  const isDescriptionExpanded = ref(false);

  function handleDescriptionExpandButtonClick() {
    isDescriptionExpanded.value = true;
  }
</script>
