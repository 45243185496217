<template>
  <div>
    <div v-if="type !== 'checkbox'">
      <label
        v-if="label"
        class="mb-2 block text-sm text-[#58585E]"
        :for="id || label"
      >
        <span v-html="translatedLabel"></span> <span v-if="required">*</span>
      </label>
      <div class="relative">
        <input
          :id="id || label"
          v-capitalize="props.capitalized"
          :type="type"
          :value="value"
          :placeholder="__(placeholder)"
          :disabled="disabled"
          :required="required"
          :aria-label="label"
          :accept="accept"
          :min="type == 'date' ? (min_date ? min_date : '1940-01-01') : null"
          :max="type == 'date' ? (max_date ? max_date : '20250-01-01') : null"
          :minlength="minlength"
          :maxlength="maxlength"
          :class="[
            {
              'ring-error': status === 'error',
              'ring-success': status === 'success',
              'ring-warning': status === 'warning',
              'ring-[#E5E7EB]': status === 'default'
            },
            'w-full rounded-app border-0 bg-white px-3 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-secondary disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400',
            classes
          ]"
          @input="$emit('update:modelValue', $event.target.value)"
          @blur="emits('blur')"
        />
        <Icon
          v-if="icon"
          :name="icon"
          aria-hidden="true"
          class="absolute left-3 top-1/2 -translate-y-1/2 transform text-[#A3A3A3]"
        />
      </div>
    </div>
    <div v-else>
      <div class="flex items-center">
        <input
          :id="id || label"
          :type="type"
          :value="value"
          :checked="value == 1 ? true : false"
          :placeholder="__(placeholder)"
          :disabled="disabled"
          :required="required"
          :class="[
            {
              'ring-error': status === 'error',
              'ring-success': status === 'success',
              'ring-warning': status === 'warning',
              'ring-[#E5E7EB]': status === 'default'
            },
            'rounded-app border-0 bg-white text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-1 focus:ring-inset focus:ring-secondary disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400'
          ]"
          @input="$emit('update:modelValue', $event.target.checked)"
          @blur="emits('blur')"
        />
        <label
          v-if="label"
          class="ml-2 text-sm text-[#58585E]"
          :for="id || label"
        > <span v-html="translatedLabel"></span> <span v-if="required">*</span>
        </label>
      </div>
    </div>

    <div v-if="errors">
      <p
        v-for="(error, index) in errors"
        :key="index"
        class="mt-1 text-xs text-error"
      >
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script setup>
  import { Icon } from '..';
  import { computed } from 'vue';
  import { __ } from '../../../helpers';

  const props = defineProps({
    value: {
      type: [String, Boolean, Number],
      default: ''
    },
    type: String,
    placeholder: String,
    label: String,
    icon: String,
    errors: {
      type: Array,
      default: () => []
    },
    status: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['default', 'error', 'success', 'warning'].includes(value);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    capitalized: {
      type: Boolean,
      default: false
    },
    id: String,
    accept: String,
    min_date: String,
    max_date: String,
    minlength: Number,
    maxlength: Number
  });

  // compute classes if icon
  const classes = computed(() => {
    return props.icon ? 'pl-12' : '';
  });

  // emit blur
  const emits = defineEmits(['blur']);

  const translatedLabel = computed(() => {
    const translated = __(props.label);
    return DOMPurify.sanitize(translated);
  });

</script>
