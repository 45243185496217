<template>
  <Modal open @close="handleCancelButtonClick">
    <div class="space-y-8">
      <img :src="coverUrl" :alt="__('Cover')" class="w-full rounded" />

      <div class="space-y-4">
        <div class="space-y-2.5">
          <span class="block text-4xl font-medium text-primary">
            {{ name }}
          </span>

          <div class="flex items-center gap-1.5">
            <Icon name="group" is-filled class="text-xl text-primary" />
            <span class="text-sm text-zinc-800">
              {{ `${checkedInAttendees} ${__('people attended')}` }}
            </span>
          </div>
        </div>

        <div class="space-y-2.5">
          <span class="block text-lg font-medium leading-normal text-zinc-800">
            {{ __('How would you rate this event?') }}
          </span>

          <div class="flex items-center gap-2.5">
            <span class="text-2xl font-medium leading-normal text-zinc-800">
              {{ `${values.event_rating}/5` }}
            </span>

            <div class="flex items-center gap-1.5">
              <button
                v-for="(_, index) in 5"
                :key="index"
                @click="handleEventRatingButtonClick(index + 1)"
              >
                <svg
                  :class="`${values.event_rating >= index + 1 ? 'fill-primary' : 'fill-zinc-300'}`"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0019 21.905L9.81443 25.03C9.58526 25.1759 9.34568 25.2384 9.09568 25.2175C8.84568 25.1967 8.62693 25.1134 8.43943 24.9675C8.25193 24.8217 8.1061 24.6394 8.00193 24.4206C7.89776 24.2019 7.87693 23.9571 7.93943 23.6863L9.31443 17.78L4.72068 13.8113C4.51235 13.6238 4.38214 13.4102 4.33005 13.1707C4.27797 12.9311 4.2936 12.6967 4.37693 12.4675C4.46026 12.2384 4.58526 12.0509 4.75193 11.905C4.9186 11.7592 5.14776 11.6654 5.43943 11.6238L11.5019 11.0925L13.8457 5.53C13.9498 5.28 14.1113 5.0925 14.33 4.9675C14.5488 4.8425 14.7727 4.78 15.0019 4.78C15.2311 4.78 15.455 4.8425 15.6738 4.9675C15.8925 5.0925 16.054 5.28 16.1581 5.53L18.5019 11.0925L24.5644 11.6238C24.8561 11.6654 25.0852 11.7592 25.2519 11.905C25.4186 12.0509 25.5436 12.2384 25.6269 12.4675C25.7102 12.6967 25.7259 12.9311 25.6738 13.1707C25.6217 13.4102 25.4915 13.6238 25.2831 13.8113L20.6894 17.78L22.0644 23.6863C22.1269 23.9571 22.1061 24.2019 22.0019 24.4206C21.8977 24.6394 21.7519 24.8217 21.5644 24.9675C21.3769 25.1134 21.1581 25.1967 20.9081 25.2175C20.6581 25.2384 20.4186 25.1759 20.1894 25.03L15.0019 21.905Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="values.speakers.length" class="space-y-4">
        <span class="block text-2xl font-medium leading-normal text-zinc-800">
          {{ __('Rate the speakers') }}
        </span>

        <div class="flex flex-wrap gap-6">
          <SpeakerRating
            v-for="(speaker, index) in values.speakers"
            :key="speaker.id"
            v-model:speaker="values.speakers[index]"
          />
        </div>
      </div>

      <div class="flex flex-col gap-2 md:flex-row-reverse">
        <Button
          :disabled="isLoading"
          class="flex items-center justify-center gap-1.5"
          @click="handleSaveButtonClick"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __('Save') }}</span>
        </Button>

        <Button
          :disabled="isLoading"
          variant="ghost"
          @click="handleCancelButtonClick"
        >
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Modal } from '@/components/block/modal';
  import { SpeakerRating } from './components/speaker-rating';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['cancel', 'save']);

  const toast = useToast();

  const coverUrl = computed(() => {
    return props.event.details.cover.includes('/uploads/')
      ? props.event.details.cover
      : `/uploads/${props.event.details.cover}`;
  });

  const name = computed(() => {
    return props.event.details.name;
  });

  const checkedInAttendees = computed(() => {
    return props.event.details.checked_in_attendees;
  });

  const values = ref({
    event_rating: 0,
    speakers: props.event.speakers.map((speaker) => ({
      ...speaker,
      rating: 0
    }))
  });
  const isLoading = ref(false);

  function handleEventRatingButtonClick(value) {
    values.value.event_rating = value;
  }

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSaveButtonClick() {
    if (!values.value.event_rating) {
      return;
    }

    const filteredValues = {
      ...values.value,
      speakers: values.value.speakers
        .map((speaker) => ({ id: speaker.id, rating: speaker.rating }))
        .filter((speaker) => speaker.rating !== 0)
    };

    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.events.rate', {
          slug: props.event.details.slug
        }),
        filteredValues
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save');
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }
</script>
