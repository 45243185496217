<template>
  <div>
    <label
      v-if="label"
      :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
      :for="id"
    >
      {{ `${label}${required ? '*' : ''}` }}
    </label>

    <div class="flex flex-wrap items-center gap-4">
      <label
        v-for="(option, index) in options"
        :key="option.id"
        class="flex items-center gap-2"
      >
        <input
          :id="`${id}-${index}`"
          type="radio"
          class="border-primary text-primary focus:ring-primary"
          :name="id"
          :value="option.id"
          :checked="option.id === value?.id"
          @change="handleChange(option)"
        />
        <span class="leading-normal text-zinc-600">{{ option.name }}</span>
      </label>
    </div>

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, null],
      default: null
    },
    errors: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  function handleChange(option) {
    emits('update:value', option);
    emits('update:errors', []);
  }
</script>
