<template>
  <Modal
    open
    :title="__(project ? 'Edit project' : 'Add project')"
    @close="handleCancelButtonClick"
  >
    <form @submit.prevent="handleSubmit">
      <div class="grid gap-6 md:grid-cols-2">
        <InputText
          id="name"
          v-model:value="values.name"
          v-model:errors="errors.name"
          :label="__('Project name')"
          :placeholder="__('Project name')"
          required
        />

        <InputText
          id="organization"
          v-model:value="values.organization"
          v-model:errors="errors.organization"
          :label="__('Organization name')"
          :placeholder="__('Organization name')"
        />

        <InputText
          id="link"
          v-model:value="values.link"
          v-model:errors="errors.link"
          :label="__('Project link')"
          :placeholder="__('Project link')"
          class="md:col-span-2"
        />

        <InputDate
          id="start_date"
          v-model:value="values.start_date"
          v-model:errors="errors.start_date"
          :label="__('Start date')"
          :placeholder="__('Pick a date')"
          :max-value="
            values.end_date ? values.end_date : formatInputDate(new Date())
          "
          @update:value="handleStartDateUpdate"
        />

        <InputDate
          id="end_date"
          v-model:value="values.end_date"
          v-model:errors="errors.end_date"
          :label="__('End date')"
          :placeholder="__('Pick a date')"
          :disabled="values.current"
          :min-value="values.start_date ? values.start_date : ''"
          :max-value="formatInputDate(new Date())"
          @update:value="handleEndDateUpdate"
        />

        <InputCheckbox
          id="current"
          v-model:value="values.current"
          v-model:errors="errors.current"
          :label="__('I’m currently involved in this project')"
          class="md:col-span-2"
          @update:value="handleCurrentUpdate"
        />

        <InputTextarea
          id="description"
          v-model:value="values.description"
          v-model:errors="errors.description"
          :label="__('Description')"
          :placeholder="
            __(
              'Tell us a bit more about this project: for example your role, responsibilities and achievements. Please keep it short and to the point.'
            )
          "
          class="md:col-span-2"
        />
      </div>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </Modal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { formatInputDate } from '@/scripts/date.script';
  import { isValidHttpUrl } from '@/scripts/validate.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Modal } from '@/components/block/modal';
  import { InputCheckbox } from '@/components/form/input-checkbox';
  import { InputDate } from '@/components/form/input-date';
  import { InputText } from '@/components/form/input-text';
  import { InputTextarea } from '@/components/form/input-textarea';

  const props = defineProps({
    project: {
      type: [Object, null],
      required: true
    }
  });

  const emits = defineEmits(['cancel', 'save']);

  const toast = useToast();

  const project = ref(JSON.parse(JSON.stringify(props.project)));
  const values = ref({
    name: project.value?.name || '',
    organization: project.value?.organization || '',
    link: project.value?.link || '',
    start_date: project.value?.start_date || '',
    end_date: project.value?.end_date || '',
    current: project.value?.current ? true : false,
    description: project.value?.description || ''
  });
  const errors = ref({
    name: [],
    organization: [],
    link: [],
    start_date: [],
    end_date: [],
    current: [],
    description: []
  });
  const isLoading = ref(false);

  function handleStartDateUpdate() {
    errors.value.end_date = [];
  }

  function handleEndDateUpdate() {
    errors.value.start_date = [];
  }

  function handleCurrentUpdate(value) {
    values.value.end_date = '';
    errors.value.end_date = [];

    if (!value) {
      errors.value.start_date = [];
    }
  }

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    const requiredFieldKeys = ['name'];

    if (values.value.start_date && !values.value.current) {
      requiredFieldKeys.push('end_date');
    }

    if (values.value.end_date || values.value.current) {
      requiredFieldKeys.push('start_date');
    }

    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (values.value.link && !isValidHttpUrl(values.value.link)) {
      errors.value.link = [
        __('Invalid URL. Please ensure the URL is correctly formatted.')
      ];

      const element = document.getElementById('link');

      if (element && !firstElement) {
        firstElement = element;
      }
    }

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      let response;

      if (project.value) {
        response = await axios.put(
          route('redesign.cv.update.projects', {
            id: project.value.id,
            ...values.value
          })
        );
      } else {
        response = await axios.post(
          route('redesign.cv.save.projects', values.value)
        );
      }

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', {
        project: {
          id: project.value?.id || response.data.data,
          ...values.value
        },
        fields: response.data.fields,
        sectionPercentage: response.data.section_percentage,
        resumePercentage: response.data.percentage
      });
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
