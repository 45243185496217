<template>
  <div>
    <div v-for="(value, index) in getSkillsCategories()" :key="index">
      <div class="mb-4">
        <h4 class="text-base font-semibold mb-4">{{ index }}</h4>
        <div class="flex flex-wrap gap-2 mt-2">
          <div
            v-for="(value, index) in getSkillsCategories()[index]"
            :key="index"
            class="chip rounded-app flex items-center border-0 px-2 py-1 shadow-sm ring-1 ring-inset ring-secondary focus:ring-2 text-sm sm:leading-6 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {{ value.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  skills: {
    type: Array,
    mandatory: true,
  },
});

const getSkillsCategories = () => {
  const sortedCategories = {};

  // Iterate through the array of objects
  props.skills?.forEach((e) => {
    const {
      category: { name },
    } = e;

    // Check if the category exists in the sortedCategories object
    if (!sortedCategories[name]) {
      sortedCategories[name] = []; // Initialize an empty array for the category if it doesn't exist
    }

    // Append the object to the corresponding category array
    sortedCategories[name].push(e);
  });

  return sortedCategories;
};
</script>
