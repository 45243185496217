<template>
  <div id="values">
    <div class="mt-8 md:grid md:grid-cols-2">
      <SectionHeader icon="target" title="Recruitment process" />
    </div>
    <div class="p-8 shadow-md mt-8 rounded-app bg-white relative">
      <div>
        <RecruitmentProcessEdit :process="data.job" :errors="errors" />
      </div>

      <Button
        class="mt-4"
        @click="autoFill"
        type="button"
        variation="outline"
        icon="add_box"
        content="Autofill from company profile"
        v-if="data.company.length"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  Button,
  Icon,
  FormMultiselect,
  SectionHeader,
  Textarea,
  FormSingleselect,
  Input,
} from "../../../../components/global";

import { useForm } from "../../../../utils";
import { useToast } from "vue-toastification";
import { __ } from "../../../../helpers";
import { RecruitmentProcessEdit } from "../../../candidate/jobs/components";

const props = defineProps({
  data: {
    type: Object,
    mandatory: true,
  },
  errors: {
    type: Object,
    mandatory: true,
  }
});
console.log(props.data);
const toast = useToast();

const addNewStep = () => {
  console.log("add new step");
  props.data.job.push("");
};

const autoFill = () => {
  // props.data.job deep copy
  props.data.job = JSON.parse(JSON.stringify(props.data.company));
};
</script>
