<template>
  <div class="shadow-card rounded-2xl bg-white p-6 md:p-8">
    <EmptySection v-if="!data.length" :title="__('Add team members')">
      <template #icon>
        <Icon class="text-xl text-zinc-200" name="diversity_3" is-filled />
      </template>
    </EmptySection>

    <div v-else>
      <div class="flex gap-2.5 overflow-x-auto py-3.5">
        <button
          :class="`${selectedDepartments.length ? 'bg-transparent' : 'bg-[#f6f3f9]'} whitespace-nowrap  rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 transition-colors hover:bg-[#f6f3f9]`"
          @click="handleAllDepartmentsButtonClick"
        >
          {{ __('All') }}
        </button>
        <button
          v-for="department in departments"
          :key="department"
          :class="`${selectedDepartments.includes(department) ? 'bg-[#f6f3f9]' : 'bg-transparent'} max-w-72 truncate rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 transition-colors hover:bg-[#f6f3f9]`"
          @click="handleDepartmentButtonClick(department)"
        >
          {{ department }}
        </button>
      </div>

      <ul
        class="mt-11 grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5"
      >
        <li
          v-for="item in filteredData"
          :key="item.id"
          class="shadow-card space-y-4 rounded-md p-4 pb-6"
        >
          <div class="flex gap-6">
            <div>
              <img
                class="rounded object-cover"
                :src="
                  item.picture_url
                    ? item.picture_url.includes('uploads')
                      ? item.picture_url
                      : `/uploads/${item.picture_url}`
                    : placeholderImage
                "
                :alt="__('Profile picture')"
              />
            </div>

            <div class="h-5 w-5 shrink-0">
              <a
                v-if="item.linkedin_url"
                class="-m-2 flex p-2 text-primary"
                :href="item.linkedin_url"
                target="_blank"
                :aria-label="__('LinkedIn')"
              >
                <Icon class="text-xl" name="open_in_new" />
              </a>
            </div>
          </div>

          <div class="space-y-2">
            <span class="block font-medium leading-normal text-zinc-600">
              {{ item.name }}
            </span>
            <span class="block text-sm text-secondary">
              {{ item.role }}
            </span>
          </div>
        </li>
      </ul>

      <div
        v-if="filteredData.length >= maxItems"
        class="mt-6 flex justify-center"
      >
        <Button @click="handleViewMoreButtonClick">
          <span>{{ isViewMore ? __('Hide') : __('View more') }}</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { EmptySection } from '@/pages/components/empty-section';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    }
  });

  const placeholderImage = '/images/logo_placeholder.png';
  const maxItems = 5;

  const filteredData = computed(() => {
    let value = props.data;

    if (selectedDepartments.value.length) {
      value = props.data.filter((person) =>
        selectedDepartments.value.includes(person.department)
      );
    }

    if (!isViewMore.value) {
      value = value.slice(0, maxItems);
    }

    return value;
  });

  const departments = computed(() => {
    return new Set(props.data.map((item) => item.department));
  });

  const selectedDepartments = ref([]);
  const isViewMore = ref(false);

  function handleAllDepartmentsButtonClick() {
    selectedDepartments.value = [];
  }

  function handleDepartmentButtonClick(department) {
    if (selectedDepartments.value.includes(department)) {
      selectedDepartments.value = selectedDepartments.value.filter(
        (item) => item !== department
      );
    } else {
      selectedDepartments.value = [...selectedDepartments.value, department];
    }
  }

  function handleViewMoreButtonClick() {
    isViewMore.value = !isViewMore.value;
  }
</script>
