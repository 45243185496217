<template>
  <div>
    <label
      v-if="label"
      :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
      :for="id"
    >
      {{ `${label}${required ? '*' : ''}` }}
    </label>

    <div
      class="divide-y divide-zinc-400 rounded-md border border-zinc-400 [&_em]:italic [&_ol]:list-decimal [&_ol]:space-y-1 [&_ol]:pl-4 [&_p]:leading-normal [&_strong]:font-bold [&_ul]:list-disc [&_ul]:space-y-1 [&_ul]:pl-4"
    >
      <div class="flex flex-wrap divide-x divide-zinc-400">
        <div class="my-1.5 flex flex-wrap gap-1 px-2">
          <button
            class="flex h-8 w-8 shrink-0 items-center justify-center rounded-md text-gray-600 hover:bg-blue-50 disabled:bg-transparent disabled:text-gray-300"
            type="button"
            :disabled="
              !editorInstance ||
              !editorInstance.can().chain().focus().undo().run()
            "
            :aria-label="__('Undo')"
            @click="editorInstance.chain().focus().undo().run()"
          >
            <Icon class="text-xl" name="undo" />
          </button>
          <button
            class="flex h-8 w-8 shrink-0 items-center justify-center rounded-md text-gray-600 hover:bg-blue-50 disabled:bg-transparent disabled:text-gray-300"
            type="button"
            :disabled="
              !editorInstance ||
              !editorInstance.can().chain().focus().redo().run()
            "
            :aria-label="__('Redo')"
            @click="editorInstance.chain().focus().redo().run()"
          >
            <Icon class="text-xl" name="redo" />
          </button>
        </div>

        <div v-if="isHeadingsEnabled" class="my-1.5 flex flex-wrap gap-1 px-2">
          <button
            :class="`${editorInstance && editorInstance.isActive('heading', { level: 1 }) ? 'bg-blue-200 text-blue-800 hover:bg-opacity-80' : 'text-gray-600 hover:bg-blue-50'} flex h-8 w-8 shrink-0 items-center justify-center rounded-md disabled:bg-transparent disabled:text-gray-300`"
            type="button"
            :aria-label="__('Heading 1')"
            @click="
              editorInstance.chain().focus().toggleHeading({ level: 1 }).run()
            "
          >
            <Icon class="text-xl" name="format_h1" />
          </button>
          <button
            :class="`${editorInstance && editorInstance.isActive('heading', { level: 2 }) ? 'bg-blue-200 text-blue-800 hover:bg-opacity-80' : 'text-gray-600 hover:bg-blue-50'} flex h-8 w-8 shrink-0 items-center justify-center rounded-md disabled:bg-transparent disabled:text-gray-300`"
            type="button"
            :aria-label="__('Heading 2')"
            @click="
              editorInstance.chain().focus().toggleHeading({ level: 2 }).run()
            "
          >
            <Icon class="text-xl" name="format_h2" />
          </button>
          <button
            :class="`${editorInstance && editorInstance.isActive('heading', { level: 3 }) ? 'bg-blue-200 text-blue-800 hover:bg-opacity-80' : 'text-gray-600 hover:bg-blue-50'} flex h-8 w-8 shrink-0 items-center justify-center rounded-md disabled:bg-transparent disabled:text-gray-300`"
            type="button"
            :aria-label="__('Heading 3')"
            @click="
              editorInstance.chain().focus().toggleHeading({ level: 3 }).run()
            "
          >
            <Icon class="text-xl" name="format_h3" />
          </button>
        </div>

        <div class="my-1.5 flex flex-wrap gap-1 px-2">
          <button
            :class="`${editorInstance && editorInstance.isActive('bold') ? 'bg-blue-200 text-blue-800 hover:bg-opacity-80' : 'text-gray-600 hover:bg-blue-50'} flex h-8 w-8 shrink-0 items-center justify-center rounded-md disabled:bg-transparent disabled:text-gray-300`"
            type="button"
            :aria-label="__('Bold')"
            @click="editorInstance.chain().focus().toggleBold().run()"
          >
            <Icon class="text-xl" name="format_bold" />
          </button>
          <button
            :class="`${editorInstance && editorInstance.isActive('italic') ? 'bg-blue-200 text-blue-800 hover:bg-opacity-80' : 'text-gray-600 hover:bg-blue-50'} flex h-8 w-8 shrink-0 items-center justify-center rounded-md disabled:bg-transparent disabled:text-gray-300`"
            type="button"
            :aria-label="__('Italic')"
            @click="editorInstance.chain().focus().toggleItalic().run()"
          >
            <Icon class="text-xl" name="format_italic" />
          </button>
          <button
            :class="`${editorInstance && editorInstance.isActive('underline') ? 'bg-blue-200 text-blue-800 hover:bg-opacity-80' : 'text-gray-600 hover:bg-blue-50'} flex h-8 w-8 shrink-0 items-center justify-center rounded-md disabled:bg-transparent disabled:text-gray-300`"
            type="button"
            :aria-label="__('Underline')"
            @click="editorInstance.chain().focus().toggleUnderline().run()"
          >
            <Icon class="text-xl" name="format_underlined" />
          </button>
          <button
            :class="`${editorInstance && editorInstance.isActive('strike') ? 'bg-blue-200 text-blue-800 hover:bg-opacity-80' : 'text-gray-600 hover:bg-blue-50'} flex h-8 w-8 shrink-0 items-center justify-center rounded-md disabled:bg-transparent disabled:text-gray-300`"
            type="button"
            :aria-label="__('Strikethrough')"
            @click="editorInstance.chain().focus().toggleStrike().run()"
          >
            <Icon class="text-xl" name="format_strikethrough" />
          </button>
        </div>

        <div class="my-1.5 flex flex-wrap gap-1 px-2">
          <button
            :class="`${editorInstance && editorInstance.isActive('bulletList') ? 'bg-blue-200 text-blue-800 hover:bg-opacity-80' : 'text-gray-600 hover:bg-blue-50'} flex h-8 w-8 shrink-0 items-center justify-center rounded-md disabled:bg-transparent disabled:text-gray-300`"
            type="button"
            :aria-label="__('Bullet list')"
            @click="editorInstance.chain().focus().toggleBulletList().run()"
          >
            <Icon class="text-xl" name="format_list_bulleted" />
          </button>
          <button
            :class="`${editorInstance && editorInstance.isActive('orderedList') ? 'bg-blue-200 text-blue-800 hover:bg-opacity-80' : 'text-gray-600 hover:bg-blue-50'} flex h-8 w-8 shrink-0 items-center justify-center rounded-md disabled:bg-transparent disabled:text-gray-300`"
            type="button"
            :aria-label="__('Ordered list')"
            @click="editorInstance.chain().focus().toggleOrderedList().run()"
          >
            <Icon class="text-xl" name="format_list_numbered" />
          </button>
        </div>

        <div class="my-1.5 flex flex-wrap gap-1 px-2">
          <button
            class="flex h-8 w-8 shrink-0 items-center justify-center rounded-md text-gray-600 hover:bg-blue-50 disabled:bg-transparent disabled:text-gray-300"
            type="button"
            :aria-label="__('Horizontal line')"
            @click="editorInstance.chain().focus().setHorizontalRule().run()"
          >
            <Icon class="text-xl" name="horizontal_rule" />
          </button>
        </div>
      </div>

      <EditorContent :id="id" :editor="editorInstance" />
    </div>

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { computed, onBeforeUnmount } from 'vue';
  import { EditorContent, useEditor } from '@tiptap/vue-3';
  import Bold from '@tiptap/extension-bold';
  import BulletList from '@tiptap/extension-bullet-list';
  import Dropcursor from '@tiptap/extension-dropcursor';
  import Document from '@tiptap/extension-document';
  import Gapcursor from '@tiptap/extension-gapcursor';
  import Heading from '@tiptap/extension-heading';
  import History from '@tiptap/extension-history';
  import Italic from '@tiptap/extension-italic';
  import Link from '@tiptap/extension-link';
  import ListItem from '@tiptap/extension-list-item';
  import OrderedList from '@tiptap/extension-ordered-list';
  import Paragraph from '@tiptap/extension-paragraph';
  import Placeholder from '@tiptap/extension-placeholder';
  import Strike from '@tiptap/extension-strike';
  import Text from '@tiptap/extension-text';
  import Underline from '@tiptap/extension-underline';
  import { Blockquote } from '@tiptap/extension-blockquote';
  import { HardBreak } from '@tiptap/extension-hard-break';
  import { HorizontalRule } from '@tiptap/extension-horizontal-rule';
  import { Youtube } from '@tiptap/extension-youtube';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    isHeadingsEnabled: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  const contentResult = computed({
    get() {
      return editorInstance.value ? editorInstance.value.getHTML() : '';
    },
    set(value) {
      emits('update:value', value);
      emits('update:errors', []);
    }
  });

  const editorInstance = useEditor({
    content: props.value,
    editorProps: {
      attributes: {
        class: 'max-h-96 overflow-auto'
      }
    },
    extensions: [
      Blockquote,
      Bold,
      BulletList,
      Document,
      Dropcursor.configure({ width: 2, color: '#2563eb' }),
      Gapcursor,
      HardBreak,
      Heading.configure({ levels: [1, 2, 3] }),
      History,
      HorizontalRule,
      Italic,
      Link.configure({ openOnClick: false }),
      ListItem,
      OrderedList,
      Paragraph,
      Placeholder.configure({ placeholder: __(props.placeholder) }),
      Strike,
      Text,
      Underline,
      Youtube
    ],
    onUpdate: ({ editor }) => {
      contentResult.value = editor.getHTML();
    }
  });

  onBeforeUnmount(() => {
    if (editorInstance.value) {
      editorInstance.value.destroy();
    }
  });
</script>
