<template>
    <div class="summary-card h-full space-y-6 md:space-y-8 overflow-hidden rounded-app bg-white p-8 relative grid grid-rows-[auto,1fr] shadow-[0px_12px_16px_-4px_rgb(85_105_135_/_20%)]">
        <div class="text-2xl font-medium"> 
            {{ __('Activity Summary') }} 
        </div>

        <div class="mt-0 bg-white shadow-none rounded h-full overflow-hidden">
            <Carousel
                ref="carouselRef"
                v-bind="carouselConfig">
                <Slide
                    v-for="(slide, index) in notifications" :key="index">
                    <div class="activity-summary-card border-green-500 bg-zinc-100 mt-5 h-[14rem]">
                        <div class="p-6">
                            <div class="flex">
                                <div class="flex-none w-14">
                                    <div v-if="slide.icon == 'message'">
                                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="20" cy="20.5" r="20" fill="#4A148C"/>
                                            <mask id="mask0_1022_3527" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="8" y="8" width="24" height="25">
                                            <rect x="8" y="8.5" width="24" height="24" fill="#D9D9D9"/>
                                            </mask>
                                            <g mask="url(#mask0_1022_3527)">
                                            <path d="M27 14.5C26.1667 14.5 25.4583 14.2083 24.875 13.625C24.2917 13.0417 24 12.3333 24 11.5C24 10.6667 24.2917 9.95833 24.875 9.375C25.4583 8.79167 26.1667 8.5 27 8.5C27.8333 8.5 28.5417 8.79167 29.125 9.375C29.7083 9.95833 30 10.6667 30 11.5C30 12.3333 29.7083 13.0417 29.125 13.625C28.5417 14.2083 27.8333 14.5 27 14.5ZM10 30.5V12.5C10 11.95 10.1958 11.4792 10.5875 11.0875C10.9792 10.6958 11.45 10.5 12 10.5H22.1C22.0333 10.8333 22 11.1667 22 11.5C22 11.8333 22.0333 12.1667 22.1 12.5C22.3333 13.6667 22.9083 14.625 23.825 15.375C24.7417 16.125 25.8 16.5 27 16.5C27.5333 16.5 28.0583 16.4167 28.575 16.25C29.0917 16.0833 29.5667 15.8333 30 15.5V24.5C30 25.05 29.8042 25.5208 29.4125 25.9125C29.0208 26.3042 28.55 26.5 28 26.5H14L10 30.5Z" fill="#FAFAFA"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div v-else-if="slide.icon == 'match'">
                                        <svg width="40" height="40" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="20" cy="20" r="20" fill="#4A148C"/>
                                            <mask id="mask0_973_8578" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="8" y="8" width="24" height="24">
                                            <rect x="8" y="8" width="24" height="24" fill="#D9D9D9"/>
                                            </mask>
                                            <g mask="url(#mask0_973_8578)">
                                            <path d="M16 27C14.05 27 12.3958 26.3208 11.0375 24.9625C9.67917 23.6042 9 21.95 9 20C9 18.05 9.67917 16.3958 11.0375 15.0375C12.3958 13.6792 14.05 13 16 13C16.45 13 16.8875 13.0417 17.3125 13.125C17.7375 13.2083 18.15 13.325 18.55 13.475C18.2667 13.7083 18 13.9625 17.75 14.2375C17.5 14.5125 17.2667 14.8 17.05 15.1C16.8833 15.0667 16.7125 15.0417 16.5375 15.025C16.3625 15.0083 16.1833 15 16 15C14.6167 15 13.4375 15.4875 12.4625 16.4625C11.4875 17.4375 11 18.6167 11 20C11 21.3833 11.4875 22.5625 12.4625 23.5375C13.4375 24.5125 14.6167 25 16 25C16.1833 25 16.3625 24.9917 16.5375 24.975C16.7125 24.9583 16.8833 24.9333 17.05 24.9C17.2667 25.2 17.5 25.4875 17.75 25.7625C18 26.0375 18.2667 26.2917 18.55 26.525C18.15 26.675 17.7375 26.7917 17.3125 26.875C16.8875 26.9583 16.45 27 16 27ZM24 27C23.55 27 23.1125 26.9583 22.6875 26.875C22.2625 26.7917 21.85 26.675 21.45 26.525C21.7333 26.2917 22 26.0375 22.25 25.7625C22.5 25.4875 22.7333 25.2 22.95 24.9C23.1333 24.9333 23.3083 24.9583 23.475 24.975C23.6417 24.9917 23.8167 25 24 25C25.3833 25 26.5625 24.5125 27.5375 23.5375C28.5125 22.5625 29 21.3833 29 20C29 18.6167 28.5125 17.4375 27.5375 16.4625C26.5625 15.4875 25.3833 15 24 15C23.8167 15 23.6417 15.0083 23.475 15.025C23.3083 15.0417 23.1333 15.0667 22.95 15.1C22.7333 14.8 22.5 14.5125 22.25 14.2375C22 13.9625 21.7333 13.7083 21.45 13.475C21.85 13.325 22.2625 13.2083 22.6875 13.125C23.1125 13.0417 23.55 13 24 13C25.95 13 27.6042 13.6792 28.9625 15.0375C30.3208 16.3958 31 18.05 31 20C31 21.95 30.3208 23.6042 28.9625 24.9625C27.6042 26.3208 25.95 27 24 27ZM20 25.75C19.05 25.1 18.3125 24.2667 17.7875 23.25C17.2625 22.2333 17 21.15 17 20C17 18.85 17.2625 17.7667 17.7875 16.75C18.3125 15.7333 19.05 14.9 20 14.25C20.95 14.9 21.6875 15.7333 22.2125 16.75C22.7375 17.7667 23 18.85 23 20C23 21.15 22.7375 22.2333 22.2125 23.25C21.6875 24.2667 20.95 25.1 20 25.75Z" fill="white"/>
                                            </g>
                                        </svg>

                                    </div>
                                    <div v-else>
                                        <svg width="40" height="40" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="20" cy="20" r="20" fill="#4A148C"/>
                                            <mask id="mask0_973_8606" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="8" y="8" width="24" height="24">
                                            <rect x="8" y="8" width="24" height="24" fill="#D9D9D9"/>
                                            </mask>
                                            <g mask="url(#mask0_973_8606)">
                                            <path d="M20.5 19.95C20.9833 19.4167 21.3542 18.8083 21.6125 18.125C21.8708 17.4417 22 16.7333 22 16C22 15.2667 21.8708 14.5583 21.6125 13.875C21.3542 13.1917 20.9833 12.5833 20.5 12.05C21.5 12.1833 22.3333 12.625 23 13.375C23.6667 14.125 24 15 24 16C24 17 23.6667 17.875 23 18.625C22.3333 19.375 21.5 19.8167 20.5 19.95ZM26 28V25C26 24.4 25.8667 23.8292 25.6 23.2875C25.3333 22.7458 24.9833 22.2667 24.55 21.85C25.4 22.15 26.1875 22.5375 26.9125 23.0125C27.6375 23.4875 28 24.15 28 25V28H26ZM28 21V19H26V17H28V15H30V17H32V19H30V21H28ZM16 20C14.9 20 13.9583 19.6083 13.175 18.825C12.3917 18.0417 12 17.1 12 16C12 14.9 12.3917 13.9583 13.175 13.175C13.9583 12.3917 14.9 12 16 12C17.1 12 18.0417 12.3917 18.825 13.175C19.6083 13.9583 20 14.9 20 16C20 17.1 19.6083 18.0417 18.825 18.825C18.0417 19.6083 17.1 20 16 20ZM8 28V25.2C8 24.6333 8.14583 24.1125 8.4375 23.6375C8.72917 23.1625 9.11667 22.8 9.6 22.55C10.6333 22.0333 11.6833 21.6458 12.75 21.3875C13.8167 21.1292 14.9 21 16 21C17.1 21 18.1833 21.1292 19.25 21.3875C20.3167 21.6458 21.3667 22.0333 22.4 22.55C22.8833 22.8 23.2708 23.1625 23.5625 23.6375C23.8542 24.1125 24 24.6333 24 25.2V28H8Z" fill="#FAFAFA"/>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div class="grow">
                                    <div class="flex">
                                        <span class="text-base xl:text-lg"> {{ slide.title }} </span>
                                    </div>
                                    <div class="carousel__item text-left">
                                        <span class="text-zinc-700 text-sm xl:text-base"> {{ slide.content }} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-4 fixed left">
                                <div class="flex lg:gap-0 xl:gap-2 xl:p-2 py-4">
                                    <Button
                                        class="activity-button lg:ml-0 flex items-center gap-2 hover:bg-transparent lg:p-0"
                                        variant="ghost"
                                        @click.prevent="handleDismissNotification(slide.id)"
                                        >
                                        <Icon name="cancel" class="text-xl text-primary" />
                                        <span>Dismiss</span>
                                    </Button>
                                    <Button
                                        class="activity-button flex items-center gap-2 hover:bg-transparent md:p-0 ml-5"
                                        variant="ghost"
                                        :href="slide.url"
                                        target="_blank"
                                        >
                                        <span>{{ slide.urlMessage }}</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slide>
            </Carousel>
            <div class="text-center pt-4">
                <Icon
                    @click="prev" 
                    class="carousel_btn_prev text-4xl text-zinc-500" 
                    name="chevron_left" />
                <Icon 
                    @click="next"
                    class="carousel_btn_next text-4xl text-zinc-500" 
                    name="chevron_right" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { __ } from "@/helpers";
import { Button } from "@/components/block/button";
import { Icon } from "@/components/block/icon";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { ref } from "vue";
import { useToast } from 'vue-toastification';
import { error } from "jquery";

const toast = useToast();

const carouselConfig = {
    itemsToShow: 1,
    breakpointMode: 'carousel'
}

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const carouselRef = ref();

const next = () => carouselRef.value.next();
const prev = () => carouselRef.value.prev();

const notifications = ref(JSON.parse(JSON.stringify(props.data)));

async function handleDismissNotification(notificationId) {
    axios.post(route('redesign.profile.dismissNotification', { id: notificationId }))
    .then(function (response) {
        if (response.data.status) {
            notifications.value = notifications.value.filter(
                (notification) => notification.id !== notificationId
            );
        } else {
            toast.error(
                __('An unexpected error has occured. Please try again later.')
            );
        }
    })
    .catch((error) => {
        toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    });

   
}

</script>
