<template>
  <div>
    <div class="relative font-primary">
      <!-- Top section -->
      <div
        class="m-0 mx-7 mb-2 font-semibold text-gray-700 md:flex md:justify-between"
      >
        <div class="flex items-center gap-4">
          <div
              v-if="first_link"
              class="inline-flex flex-nowrap text-base"
              style="margin-right: 10px"
          >
            <a
              :href="first_link"
              class="cursor-pointer rounded-app bg-primary px-5 py-2 text-sm font-normal text-button-color"
            >
              {{ toggle == 'closed' ? __('Show open') : __('Show closed') }}
            </a>
          </div>

          <input-select
            v-model:value="selectedBoard"
            class="my-4"
            by="id"
            :options="availableBoards"
          />

          <div
            class="inline-flex flex-nowrap text-base"
            style="color: green"
            v-if="this.board?.job.active"
          >
            {{ __('Active job') }}
          </div>

          <div
            class="inline-flex flex-nowrap text-base"
            style="color: black"
            v-else-if="this.board?.job.closed"
          >
            {{ __('Closed job') }}
          </div>

          <div
            class="inline-flex flex-nowrap text-base"
            style="color: red"
            v-else
          >
            {{ __('Inactive job') }}
          </div>

          <div
            class="inline-flex flex-nowrap text-base"
            v-if="!board?.job.closed && board?.job.active"
          >
            <div
              class="cursor-pointer rounded-app bg-primary px-5 py-2 text-sm font-normal text-button-color duration-200"
              v-if="canClosePosition()"
              @click="showClosePositionModal"
            >
              {{ __('Close position') }}
            </div>
            <div
              class="rounded-app bg-gray-400 px-5 py-2 text-sm font-normal text-white"
              v-if="!canClosePosition()"
            >
              {{ __('Close position') }}
            </div>
          </div>

          <div class="inline-flex flex-nowrap text-base" style="color: black">
            {{ board?.job.openings }}
            {{ board?.job.openings == 1 ? __('opening') : __('openings') }}
          </div>

          <!-- Job Closed -->
          <div
            class="inline-flex flex-nowrap text-base"
            v-if="board?.job.closed"
          >
            <div
              class="cursor-pointer rounded-app bg-primary px-5 py-2 text-sm font-normal text-button-color duration-200"
              @click="showReopenPositionModal"
            >
              {{ __('Reopen position') }}
            </div>
          </div>
          <!-- Job Closed -->
        </div>

        <div>
          <svg
              class="inline cursor-pointer fill-[#6b7280]"
              xmlns="http://www.w3.org/2000/svg"
              height="25"
              width="25"
              @click="showInfoModal"
          >
            <use xlink:href="/images/front/r_SVG-sprite.svg#qmk-circle"></use>
          </svg>
          <select
            class="no-select2 my-2 ml-2 cursor-pointer rounded-app border-0 bg-white px-3 py-2 pr-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
            @change="filterCandidates($event)"
          >
            <option value="0">{{ __('All candidates') }}</option>
            <option value="1">{{ __('My candidates') }}</option>
          </select>
        </div>
      </div>

      <div
          v-if="loadedIssue"
          id="issue-message"
          class="m-0 mb-2 ml-7 text-base font-semibold text-mediumGray"
          style="display: none"
      >
        {{
          __(
            'There was an issue loading your lists. Please come back again later.'
          )
        }}
      </div>

      <!-- ./Top section -->

      <!-- Main section -->
      <div
        class="relative flex flex-col-reverse gap-5 p-2 sm:h-[70vh] sm:flex-row sm:gap-0 sm:overflow-x-auto"
        style="height: 70vh"
      >
        <!-- Includes -->
        <!-- Info modal -->
        <div
          id="info-modal"
          v-if="displayInfoModal"
          class="fixed left-0 top-0 z-[99999] flex h-full w-full items-center justify-center"
          style="display: none"
        >
          <div
            @click.self="hideAllModals"
            class="absolute h-full w-full bg-gray-900 opacity-50"
          ></div>

          <div
            class="z-50 mx-3 mt-6 max-h-[80%] w-full overflow-y-auto rounded bg-white shadow-lg md:mx-auto md:w-[650px]"
          >
            <div class="relative p-7 text-left">
              <p>
                {{
                  __(
                    'This board will help you track your candidates through the recruitment process.'
                  )
                }}
              </p>
              <br/>
              <ul class="ml-7 list-disc">
                <li>
                  {{
                    __(
                      'You can reorder your candidates by dragging them around. You can do that only for active jobs, not for inactive or closed ones.'
                    )
                  }}
                </li>
                <li>
                  {{
                    __(
                      'You can create labels and assign them to your candidates in order to differentiate them or point out relevant details.'
                    )
                  }}
                </li>
                <li>
                  {{
                    __(
                      'You and your team can share opinions regarding the candidate by adding comments.'
                    )
                  }}
                </li>
                <li>
                  {{
                    __(
                      'You can close a position only if you have a number of candidates in the Hired lists greater or equal than the number of openings on the job. Also, the job must be active for you to close it.'
                    )
                  }}
                </li>
              </ul>
              <br/>
            </div>
          </div>
        </div>
        <!-- ./Info modal -->

        <!-- Confirm modal -->
        <div
          v-if="displayConfirmModal"
          id="confirm-modal"
          class="fixed left-0 top-0 z-[99999] flex h-full w-full items-center justify-center"
          style="display: none"
        >
          <div
            @click.self="hideAllModals"
            class="absolute h-full w-full bg-gray-900 opacity-50"
          ></div>

          <div
            class="z-50 mx-3 mt-6 max-h-[80%] min-h-[45%] w-full overflow-y-auto rounded bg-white text-gray-700 shadow-lg md:mx-auto md:w-[650px]"
          >
            <div
              class="relative flex p-7 text-left"
              style="
                flex-direction: column;
                justify-content: space-between;
                min-height: 80vh;
              "
            >
              <div>
                <h1 class="mb-6 text-center font-semibold">
                  {{ __('Move candidate') }}
                </h1>
                <div class="font-normal">
                  <div class="mb-6 flex items-center">
                    <img
                      class="mr-2.5 rounded-[50%] border-2"
                      :src="changeEvent.card.picUrl"
                      width="45"
                      height="45"
                      alt="pic"
                    />

                    <span>{{
                      changeEvent.card.name +
                      ' (' +
                      lists[changeEvent.from_list_idx].title +
                      ' -> ' +
                      lists[changeEvent.to_list_idx].title +
                      ')'
                    }}</span>
                  </div>

                  <label class="block pb-1 pl-4 text-gray-400">
                    {{ __('Email subject') }}</label
                  >
                  <input
                    type="text"
                    class="my-2 w-full cursor-pointer rounded-app border-0 bg-white px-3 py-2 pr-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    :placeholder="__('Subject')"
                    v-model="emailSubject"
                  />

                  <label class="block pb-1 pl-4 text-gray-400">{{
                    __('Email message')
                  }}</label>
                  <div class="relative">
                    <div ref="editor" v-html="emailMessage"></div>
                  </div>

                  <div
                      class="mt-6"
                      v-if="
                      emailMessage != emailMessageTranslated ||
                      emailSubject != emailSubjectTranslated
                    "
                  >
                    <hr class="mb-6"/>

                    <div>
                      <span class="font-bold">{{ __('Warning') }}</span>
                      {{
                        __(
                            "The candidate's selected language is different from yours."
                        )
                      }}
                      <br/>
                      <a
                        class="font-bold"
                        @click="displayTranslation = !displayTranslation"
                      >
                        {{
                          displayTranslation
                            ? __('Hide translation')
                            : __('See the translation of the original email')
                        }}
                      </a>
                    </div>

                    <div v-show="displayTranslation">
                      <label class="mt-2 block pb-1 pl-4 text-gray-400">{{
                        __('Email subject')
                      }}</label>
                      <div
                        class="mb-6 w-full rounded-app border-2 bg-gray-100 p-3 text-left"
                        v-html="emailSubjectTranslated"
                      ></div>

                      <label class="block pb-1 pl-4 text-gray-400">{{
                        __('Email message')
                      }}</label>
                      <div
                        class="mb-6 min-h-[50px] w-full rounded-app border-2 bg-gray-100 p-3 text-left"
                        v-html="emailMessageTranslated"
                      ></div>
                    </div>

                    <hr class="mb-6 mt-6" />
                  </div>
                </div>
              </div>

              <div class="flex justify-between">
                <button @click="cancelChangeEvent">{{ __('Cancel') }}</button>
                <div>
                  <button
                    @click="confirmAndPersistChangeEvent"
                    class="mr-2.5 rounded-app px-5 py-2 text-sm"
                    :class="
                      isLoading
                        ? 'bg-gray-400'
                        : 'cursor-pointer bg-primary text-button-color duration-200'
                    "
                      :disabled="isLoading"
                  >
                    {{
                      isLoading ? __('Loading...') : __('Send email and move')
                    }}
                  </button>
                  <button
                    @click="confirmAndPersistChangeEvent(false)"
                    class="mr-2.5 rounded-app bg-primary px-5 py-2 text-sm text-button-color duration-200"
                  >
                    {{ __('Move directly') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Confirm Modal -->

        <!-- User Modal -->

        <!-- User modal -->
        <div
          id="user-modal"
          v-if="displayCardModal"
          class="fixed left-0 top-0 z-[99999] flex h-full w-full items-center justify-center"
          style="display: none"
        >
          <div
            @click.self="hideAllModals"
            class="absolute h-full w-full bg-gray-900 opacity-50"
          ></div>

          <!-- Labels modal -->
          <div
            v-on:click.stop
            v-if="displayLabelsModal"
            class="absolute z-[51] h-[60%] w-[80%] overflow-y-auto rounded bg-white shadow-3xl sm:h-[40%] sm:w-[304px]"
          >
            <button @click="hideLabelsModal" class="absolute right-0 top-0 p-2">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  width="20"
                  class="block"
              >
                <use xlink:href="/images/front/r_SVG-sprite.svg#x"></use>
              </svg>
            </button>
            <button
              v-if="displayCreateLabel"
              @click="displayCreateLabel = false"
              class="absolute left-0 top-0 p-2"
            >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  class="block"
              >
                <use
                    xlink:href="/images/front/r_SVG-sprite.svg#chevron-back"
                ></use>
              </svg>
            </button>
            <button
              v-if="displayEditLabel"
              @click="displayEditLabel = false"
              class="absolute left-0 top-0 p-2"
            >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  class="block"
              >
                <use
                    xlink:href="/images/front/r_SVG-sprite.svg#chevron-back"
                ></use>
              </svg>
            </button>
            <div v-if="!displayCreateLabel && !displayEditLabel" class="p-3">
              <div class="mb-2 text-center text-base font-bold">
                {{ __('Labels') }}
              </div>

              <div class="text-mediumGray" v-if="labels.length == 0">
                {{ __('No labels') }}
              </div>
              <div
                v-for="(label, id) in labels"
                class="mb-2 flex items-center gap-1"
                v-bind:key="id"
              >
                <button
                  @click="toggleLabel(parseInt(id))"
                  :class="
                    'relative mr-2 flex h-7 w-full items-center rounded-app px-3 text-sm font-semibold hover:border-l-4 hover:border-solid ' +
                    colors[label.color].class
                  "
                >
                  {{ label.text }}
                  <span
                      v-show="
                      lists[displayList].cards[displayCard].labels.includes(
                        parseInt(id)
                      )
                    "
                      class="absolute right-0 mr-2"
                  >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="15"
                        width="15"
                        class="block"
                        :fill="colors[label.color].dark"
                    >
                      <use
                          xlink:href="/images/front/r_SVG-sprite.svg#check"
                      ></use>
                    </svg>
                  </span>
                </button>
                <button @click="showEditLabel(id)">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      class="block"
                      fill="#6b7280"
                  >
                    <use
                        xlink:href="/images/front/r_SVG-sprite.svg#edit_small"
                    ></use>
                  </svg>
                </button>
              </div>
              <div class="flex items-center justify-center">
                <button
                  @click="displayCreateLabel = true"
                  class="mt-2 cursor-pointer rounded-app bg-primary px-5 py-2 text-sm font-normal text-button-color duration-200"
                >
                  {{ __('Create label') }}
                </button>
              </div>
            </div>

            <!-- Create label -->
            <div v-if="displayCreateLabel" class="p-3">
              <div class="mb-2 text-center text-base font-bold">
                {{ __('Create label') }}
              </div>
              <hr class="mb-4"/>
              <label
                class="mb-1 inline-block cursor-pointer pl-5 text-xs text-mediumGray"
                >{{ __('Name') }}</label
              >
              <input
                type="text"
                v-model="newLabelName"
                :placeholder="__('Name')"
                class="transition-[box-shadow 0.25s ease-in, 0.25s ease-in] my-2 w-full cursor-pointer rounded-app border-0 bg-lightGray px-3 py-2 shadow-sm ring-1 ring-inset ring-gray-300 filter transition-shadow focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
              />
              <label
                class="mb-1 inline-block cursor-pointer pl-5 text-xs text-mediumGray"
                >{{ __('Color') }}</label
              >
              <div class="mb-4 flex flex-wrap justify-between">
                <button
                  v-for="(color, hex) in colors"
                  v-bind:key="hex"
                  @click="selectColor(hex)"
                  :class="
                    'relative flex h-7 w-7 items-center rounded px-3 ' +
                    color.class +
                    ' ' +
                    (newLabelColor == hex
                      ? 'border-2 border-solid ' + color.bg_color
                      : 'hover:border-2 hover:border-solid')
                  "
                    :style="'border-color:' + color.dark"
                ></button>
              </div>
              <div class="mt-2 flex items-center justify-center">
                <button
                  @click="createLabel"
                  class="cursor-pointer rounded-app bg-primary px-5 py-2 text-sm text-button-color duration-200 sm:px-10"
                >
                  {{ __('Create') }}
                </button>
              </div>
            </div>
            <!-- ./Create label -->

            <!-- Edit label -->
            <div v-if="displayEditLabel" class="p-3">
              <div class="mb-2 text-center text-base font-bold">
                {{ __('Edit label') }}
              </div>
              <hr class="mb-4"/>
              <label
                class="mb-1 inline-block cursor-pointer pl-5 text-xs text-mediumGray"
                >{{ __('Name') }}</label
              >
              <input
                type="text"
                v-model="newLabelName"
                :placeholder="__('Name')"
                class="transition-[box-shadow 0.25s ease-in, 0.25s ease-in] my-2 w-full cursor-pointer rounded-app border-0 bg-lightGray px-3 py-2 text-textAreaGray shadow-sm ring-1 ring-inset ring-gray-300 filter transition-shadow focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
              />
              <label
                class="mb-1 inline-block cursor-pointer pl-5 text-xs text-mediumGray"
                >{{ __('Color') }}</label
              >
              <div class="mb-4 flex flex-wrap justify-between">
                <button
                  v-for="(color, hex) in colors"
                  v-bind:key="hex"
                  @click="selectColor(hex)"
                  :class="
                    'relative flex h-7 w-7 items-center rounded px-3 ' +
                    color.class +
                    ' ' +
                    (newLabelColor == hex
                      ? 'border-2 border-solid ' + color.bg_color
                      : 'hover:border-2 hover:border-solid')
                  "
                ></button>
              </div>
              <div class="mt-2 flex items-center justify-between gap-5">
                <button
                  @click="updateLabel()"
                  class="w-full cursor-pointer rounded-app bg-primary px-5 py-2 text-sm text-button-color duration-200"
                >
                  {{ __('Save') }}
                </button>
                <button
                  @click="deleteLabel()"
                  class="w-full cursor-pointer rounded-app bg-primary px-5 py-2 text-sm text-button-color duration-200"
                >
                  {{ __('Delete') }}
                </button>
              </div>
            </div>
            <!-- ./Edit label -->
          </div>
          <!-- ./Labels modal -->

          <div
            @click="hideLabelsModal"
            class="z-50 mx-3 mt-6 max-h-[80%] min-h-[45%] w-full overflow-y-auto rounded bg-white shadow-lg md:mx-auto md:w-[650px]"
          >
            <div class="relative p-7 text-left">
              <button
                @click="displayCardModal = false"
                class="absolute right-0 top-0 p-3"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    width="20px"
                    class="block"
                >
                  <use xlink:href="/images/front/r_SVG-sprite.svg#x"></use>
                </svg>
              </button>

              <!-- About -->
              <div class="mb-6 flex items-center">
                <img
                  class="mr-2.5 rounded-[50%] border-2"
                  :src="lists[displayList].cards[displayCard].picUrl"
                  width="50"
                  height="50"
                  alt="pic"
                />
                <a
                  :href="lists[displayList].cards[displayCard].cvUrl"
                  target="_blank"
                  class="text-2xl font-bold duration-200 hover:text-secondary"
                >
                  {{ lists[displayList].cards[displayCard].name }}
                </a>
                <span
                  v-if="!lists[displayList].cards[displayCard].hasAccount"
                  class="ml-2.5 font-bold italic text-gray-400"
                >
                  {{ __('No account') }}
                </span>
              </div>
              <!-- ./About -->

              <!-- Recruiter -->
              <div class="mb-6">
                <div class="mb-3 flex flex-col items-start text-base">
                  <div class="flex font-bold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20px"
                      width="20px"
                      class="icon-svg gray mr-2 mt-[3px] block"
                    >
                      <use
                          xlink:href="/images/front/r_SVG-sprite.svg#user"
                      ></use>
                    </svg>

                    {{ __('Recruiter in charge') }}
                  </div>
                  <select
                    @change="editCardRecruiter($event)"
                    class="my-2 w-full cursor-pointer rounded-app border-0 bg-lightGray px-3 py-2 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                  >
                    <option disabled selected>
                      {{ __('Select recruiter') }}
                    </option>
                    <option
                        v-for="(item, index) in recruiters"
                        v-bind:key="index"
                        :value="index"
                        :selected="
                        lists[displayList].cards[displayCard].recruiter &&
                        lists[displayList].cards[displayCard].recruiter.id ==
                          index
                      "
                        class="cursor-pointer"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- ./Recruiter-->

              <!-- Move candidate -->

              <!-- ./Move candidate -->

              <!-- Labels -->
              <div class="mb-6">
                <div class="mb-3 flex text-base font-bold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    width="20px"
                    class="mr-2 mt-[3px] block"
                  >
                    <use xlink:href="/images/front/r_SVG-sprite.svg#tag"></use>
                  </svg>
                  {{ __('Labels') }}
                </div>
                <div class="flex flex-wrap gap-1">
                  <button
                      v-on:click.stop
                      @click="displayLabelsModal = true"
                      v-for="(labelId, index) in lists[displayList].cards[
                      displayCard
                    ].labels"
                    v-bind:key="index"
                    :class="
                      'mb-1 mr-0.5 flex h-7 items-center rounded-app px-3 text-sm font-semibold ' +
                      colors[labels[labelId].color].class
                    "
                  >
                    {{ labels[labelId].text }}
                  </button>
                  <button
                    v-on:click.stop
                    @click="displayLabelsModal = true"
                    class="mb-1 mr-0.5 flex h-6 items-center rounded-app bg-gray-200 px-3 text-sm font-semibold text-gray-500"
                  >
                    +
                  </button>
                </div>
              </div>
              <!-- ./Labels -->

              <!-- Description -->
              <div class="mb-6">
                <div class="mb-3 flex text-base font-bold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    width="20px"
                    class="mr-2 mt-[3px] block"
                  >
                    <use
                        xlink:href="/images/front/r_SVG-sprite.svg#justify-left"
                    ></use>
                  </svg>
                  {{ __('Description') }}
                </div>
                <div>
                  <textarea
                    class="special-area my-2 w-full cursor-pointer rounded-app border-0 bg-lightGray px-3 py-2 text-textAreaGray shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                    v-model="lists[displayList].cards[displayCard].description"
                    @focusout="editCardDescription"
                    :placeholder="__('Description')"
                  ></textarea>
                </div>
              </div>
              <!-- ./Description -->

              <!-- Processes -->
              <div class="mb-6" v-if="!this.config_display_users_anonymously">
                <div class="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    width="20px"
                    class="icon-svg gray mr-2 mt-[3px] block"
                  >
                    <use
                        xlink:href="/images/front/r_SVG-sprite.svg#briefcase"
                    ></use>
                  </svg>
                  <div
                    class="mb-3 flex flex-col items-start text-base font-bold"
                  >
                    {{ __('Other processes') }}
                  </div>
                </div>
                <div
                    v-for="process in lists[displayList].cards[displayCard]
                    .processes"
                    v-bind:key="process"
                >
                  {{ process }}
                </div>
                <div
                    v-if="!lists[displayList].cards[displayCard].processes.length"
                >
                  {{ __('None') }}
                </div>
                <div class="mb-3"></div>

                <div>
                  <select
                    v-model="newJobBoard"
                    class="my-2 w-full cursor-pointer rounded-app border-0 bg-lightGray px-3 py-2 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                  >
                    <option disabled selected value="">
                      {{ __('Select Kanban') }}
                    </option>
                    <option
                        v-for="(item, index) in lists[displayList].cards[
                        displayCard
                      ].availableProcesses"
                        v-bind:key="index"
                        :value="index"
                        class="cursor-pointer"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <div class="flex justify-end font-normal">
                    <div
                      @click="addToJobBoard"
                      class="cursor-pointer rounded-app bg-primary px-5 py-2 text-sm text-button-color"
                    >
                      {{ __('Add to Kanban') }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- ./Processes -->

              <!-- Activity -->
              <div>
                <div class="mb-3 flex text-base font-bold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    width="20px"
                    class="mr-2 mt-0.5 block"
                  >
                    <use
                        xlink:href="/images/front/r_SVG-sprite.svg#chat-bubble"
                    ></use>
                  </svg>
                  {{ __('Activity') }}
                </div>
                <div>
                  <textarea
                    v-model="newComment"
                    class="special-area my-2 w-full cursor-pointer rounded-app border-0 bg-lightGray px-3 py-2 text-textAreaGray shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                    name="text"
                    cols="30"
                    rows="10"
                    :placeholder="__('Write a comment...')"
                  ></textarea>
                  <div class="flex justify-end font-normal">
                    <div
                      @click="addComment(displayList, displayCard)"
                      class="cursor-pointer rounded-app bg-primary px-5 py-2 text-sm text-button-color"
                    >
                      {{ __('Add comment') }}
                    </div>
                  </div>
                </div>

                <div
                    class="px-0 py-2.5"
                    v-for="(comment, index) in lists[displayList].cards[
                    displayCard
                  ].comments"
                    v-bind:key="index"
                    style="border-bottom: 1px solid rgba(15, 35, 63, 0.15)"
                >
                  <div class="flex items-start">
                    <img
                      class="mr-2.5 rounded-[50%] border-2"
                      :src="comment.picUrl"
                      width="45"
                      height="45"
                      alt="pic"
                    />

                    <div>
                      <div
                        class="text-sm font-normal leading-normal text-gray-500"
                      >
                        <p>
                          {{
                            comment.recruiter
                          }}<span class="mx-1 text-xs">&bull;</span>@{{
                            comment.date
                          }}
                        </p>
                      </div>
                      <div 
                        v-if="!displayEditComment[comment.id]" 
                        class="mb-1 flex justify-between">
                        <p class="text-grey-darkest font-normal leading-normal">
                          {{ comment.text }}
                        </p>
                      </div>
                    </div>
                    <div 
                      v-if="comment.recruiter_id === loggedRecruiter.id"
                      class="ml-auto">
                      <button 
                        v-if="displayEditComment[comment.id]"
                        class="flex items-center gap-1.5 p-4"
                        @click="closeEditComment(comment)">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" class="block">
                          <use xlink:href="/images/front/r_SVG-sprite.svg#x"></use>
                        </svg>
                      </button>
                      <button 
                        v-else
                        class="flex items-center gap-1.5 p-4"
                        @click="editComment(comment)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            class="block"
                            fill="#6b7280"
                        >
                          <use xlink:href="/images/front/r_SVG-sprite.svg#edit_small"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div v-if="displayEditComment[comment.id]">
                    <div
                      class="flex items-start">
                      <textarea
                        v-model="editedComment[comment.id]"
                        class="special-area my-2 w-full cursor-pointer rounded-app border-0 bg-lightGray px-3 py-2 text-textAreaGray shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                        name="text"
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                    <div class="flex justify-end font-normal">
                      <div
                          @click="saveComment(comment, displayList, displayCard)"
                          class="cursor-pointer rounded-app bg-primary px-5 py-2 text-sm text-button-color"
                        >
                        {{ __('Save comment') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ./Activity -->
            </div>
          </div>
        </div>
        <!-- ./User modal -->

        <!-- Info modal -->
        <div
          id="close-position-modal"
          v-if="displayClosePositionModal"
          class="fixed left-0 top-0 z-[99999] flex h-full w-full items-center justify-center"
          style="display: none"
        >
          <div
            @click.self="hideAllModals"
            class="absolute h-full w-full bg-gray-900 opacity-50"
          ></div>

          <div
            class="z-50 mx-3 mt-6 max-h-[80%] w-full overflow-y-auto rounded bg-white shadow-lg md:mx-auto md:w-[650px]"
          >
            <div class="relative p-7 text-left">
              <p>
                {{
                  __(
                    'Are you sure you want to close this position? Make sure all your candidates are in the right lists, as you will not be able to move them around anymore.'
                  )
                }}
              </p>
              <br/>
              <p class="text-red" style="color: red" v-if="errorClosePosition">
                {{ errorClosePosition }}
              </p>
              <br/>
              <div class="flex justify-between">
                <button @click="hideAllModals">{{ __('Cancel') }}</button>
                <button
                  @click="closePosition"
                  class="cursor-pointer rounded-app bg-primary px-5 py-2 text-sm text-button-color"
                >
                  {{ __('Close position') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Info modal -->

        <!-- Info modal -->
        <div
          id="reopen-position-modal"
          v-if="displayReopenPositionModal"
          class="fixed left-0 top-0 z-[99999] flex h-full w-full items-center justify-center"
          style="display: none"
        >
          <div
            @click.self="hideAllModals"
            class="absolute h-full w-full bg-gray-900 opacity-50"
          ></div>

          <div
            class="z-50 mx-3 mt-6 max-h-[80%] w-full overflow-y-auto rounded bg-white shadow-lg md:mx-auto md:w-[650px]"
          >
            <div class="relative p-7 text-left">
              <p>{{ __('Are you sure you want to reopen this position?') }}</p>
              <br />
              <p class="text-red" style="color: red" v-if="errorReopenPosition">
                {{ errorReopenPosition }}
              </p>
              <br/>
              <div class="flex justify-between">
                <button @click="hideAllModals">{{ __('Cancel') }}</button>
                <button
                  @click="reopenPosition"
                  class="cursor-pointer rounded-app bg-primary px-5 py-2 text-sm text-button-color"
                >
                  {{ __('Reopen position') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Info modal -->

        <!-- Dragabble -->

        <draggable
          class="flex h-full select-none flex-col gap-5 sm:flex-row sm:gap-0"
          v-model="lists"
          v-bind="taskDragOptions"
          item-key="'list' + id"
          group="lists"
          handle=".handle"
          :force-fallback="true"
          :scroll-sensitivity="200"
          :disabled="true"
        >
          <template #item="{ element }" item-key="id">
            <div
              class="h-full w-full flex-1 shrink-0 sm:mx-2 sm:w-4/5 sm:max-w-xs"
              :class="windowWidth < 650 ? '' : 'handle'"
            >
              <div
                class="h-[95%] overflow-hidden rounded-md shadow-md sm:w-[272px]"
              >
                <!-- List head -->
                <div
                  class="flex items-center justify-between gap-1 bg-brandColor1 px-5 py-5"
                >
                  <div class="flex items-center gap-2">
                    <div class="handle -m-2 cursor-move p-2 sm:hidden">
                      <svg
                        class="icon-svg white block"
                        xmlns="http://www.w3.org/2000/svg"
                        height="20px"
                        width="20px"
                      >
                        <use
                            xlink:href="/images/front/r_SVG-sprite.svg#drag"
                        ></use>
                      </svg>
                    </div>
                    <input
                      :id="'list-' + element.id"
                      class="max-w-[200px] border-0 bg-transparent p-0 text-base font-medium text-white shadow-none outline-0 sm:w-[150px]"
                      type="text"
                      v-model="element.title"
                      :disabled="true"
                    />
                  </div>
                  <div class="flex items-center gap-2">
                    <span
                      v-if="cardsCount(element)"
                      class="ml-2 flex h-5 w-5 items-center justify-center rounded bg-white bg-opacity-90 text-sm font-semibold text-brandColor1"
                    >
                      {{ cardsCount(element) }}
                    </span>

                    <svg
                      v-if="cardsCount(element)"
                      class="block cursor-pointer p-1 sm:hidden"
                      :class="
                        displayModalMobile.includes(element.id) ? 'hidden' : ''
                      "
                        height="25"
                        width="25"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="showModalMobile(element.id)"
                    >
                      <use
                          xlink:href="/images/front/r_SVG-sprite.svg#arrow_right"
                          style="stroke: white"
                      ></use>
                    </svg>

                    <svg
                      v-if="cardsCount(element)"
                      class="mt-1 block cursor-pointer p-1 sm:hidden"
                      :class="
                        !displayModalMobile.includes(element.id) ? 'hidden' : ''
                      "
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="hideModalMobile()"
                    >
                      <use
                          xlink:href="/images/front/r_SVG-sprite.svg#arrow_down"
                          style="stroke: white"
                      ></use>
                    </svg>

                    <svg
                      v-if="!cardsCount(element)"
                      class="block p-1 sm:hidden"
                      :class="
                        !displayModalMobile.includes(element.id) ? 'hidden' : ''
                      "
                        height="25"
                        width="25"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="hideModalMobile()"
                    >
                      <use
                          xlink:href="/images/front/r_SVG-sprite.svg#arrow_down"
                          style="stroke: white"
                      ></use>
                    </svg>

                    <svg
                      v-if="!cardsCount(element)"
                      class="-mb-1.5 block cursor-pointer p-1 sm:hidden"
                      :class="
                        displayModalMobile.includes(element.id) ? 'hidden' : ''
                      "
                        height="25"
                        width="25"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="showModalMobile(element.id)"
                    >
                      <use
                          xlink:href="/images/front/r_SVG-sprite.svg#arrow_right"
                          style="stroke: white"
                      ></use>
                    </svg>
                  </div>
                </div>
                <!-- ./List head -->

                <!-- List body -->
                <div
                  class="h-[85%] flex-1 flex-col overflow-x-hidden p-2 sm:flex"
                  :class="
                    displayModalMobile.includes(element.id) ? 'flex' : 'hidden'
                  "
                >
                  <!-- Candidate -->
                  <draggable
                      :force-fallback="true"
                      :scroll-sensitivity="200"
                      class="flex-1"
                      v-bind="taskDragOptions"
                      item-key="id"
                      v-model="element.cards"
                      group="element.cards"
                      handle=".handle"
                      :id="'draglist-' + element.id"
                      @end="editCardPosition"
                      :disabled="this.board?.job.closed || !this.board?.job.active"
                  >
                    <template
                      #item="{ element }"
                      tag="div"
                      class="shadow-xs flex h-full flex-1 flex-col overflow-y-auto overflow-x-hidden rounded"
                    >
                      <transition-group
                          v-show="
                          !filteredCandidates ||
                          (element.recruiter &&
                            element.recruiter.id == loggedRecruiter.id)
                        "
                          :id="'dragCard-' + element.id"
                          tag="div"
                          mode="out-in"
                          name="disabled"
                      >
                        <div
                            :class="[
                            windowWidth < 650 ? '' : 'handle',
                            displayCardModal
                              ? 'mb-3 flex cursor-pointer flex-col rounded-md bg-white p-3 shadow'
                              : 'mb-3 flex transform cursor-pointer flex-col rounded-md bg-white p-3 shadow hover:shadow-lg'
                          ]"
                            :key="element.id"
                            @click="showCardModal(element.id)"
                        >
                          <!-- Candidate labels-->
                          <div class="flex justify-between">
                            <div
                              v-if="element.labels"
                              class="mb-2 flex flex-wrap"
                            >
                              <span
                                v-for="(labelId, index) in element.labels"
                                v-bind:key="index"
                                :class="
                                  'mb-1 mr-0.5 flex h-6 items-center rounded-app px-3 text-xs font-bold ' +
                                  colors[labels[labelId].color].class
                                "
                              >
                                {{ labels[labelId].text }}
                              </span>
                            </div>
                            <div
                              class="handle primary -m-2 cursor-move p-2 sm:hidden"
                            >
                              <svg
                                class="icon-svg gray block"
                                xmlns="http://www.w3.org/2000/svg"
                                height="20"
                                width="20"
                              >
                                <use
                                    xlink:href="/images/front/r_SVG-sprite.svg#drag"
                                ></use>
                              </svg>
                            </div>
                          </div>
                          <!-- ./Candidate labels-->

                          <!-- Candidate details-->
                          <div class="mb-2 flex items-center">
                            <img
                              class="mr-2.5 rounded-[50%] border-2"
                              :src="element.picUrl"
                              width="50"
                              height="50"
                              alt="pic"
                            />
                            <div>
                              <span
                                  class="block text-sm font-normal text-gray-700"
                              >
                                {{ element.name }}
                              </span>
                              <div
                                class="mt-2 flex items-center text-xs text-gray-500"
                              >
                                <svg
                                    v-if="element.description"
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="20"
                                    width="20"
                                    class="block"
                                >
                                  <use
                                      xlink:href="/images/front/r_SVG-sprite.svg#justify-left"
                                  ></use>
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="18"
                                  width="18"
                                  class="ml-2 mr-0.5 block fill-[#6b7280]"
                                >
                                  <use
                                      xlink:href="/images/front/r_SVG-sprite.svg#clock-outline"
                                  ></use>
                                </svg>
                                <span> {{ element.createdAt }} </span>
                                <svg
                                  v-if="element.comments.length"
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="20"
                                  width="20"
                                  class="ml-2 mr-0.5 block"
                                >
                                  <use
                                      xlink:href="/images/front/r_SVG-sprite.svg#chat-bubble"
                                  ></use>
                                </svg>
                                <span v-if="element.comments.length">
                                  {{ element.comments.length }}
                                </span>
                                <svg
                                  v-if="element.processes.length"
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="20"
                                  width="20"
                                  class="ml-2 mr-0.5 block fill-[#6b7280]"
                                >
                                  <use
                                      xlink:href="/images/front/r_SVG-sprite.svg#briefcase"
                                  ></use>
                                </svg>
                                <span v-if="element.processes.length">
                                  {{ element.processes.length }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- ./Candidate details-->

                          <div class="flex justify-end text-gray-500">
                            <span class="text-xs">{{
                              element.recruiter
                                ? '@' + element.recruiter.name
                                : ''
                            }}</span>
                          </div>
                        </div>
                      </transition-group>
                    </template>
                  </draggable>
                  <!-- ./Candidate -->

                  <!-- No candidates -->
                  <span
                    v-if="!cardsCount(element)"
                    class="mb-20 flex flex-1 flex-col items-center p-4 text-gray-600"
                  >
                    {{ __('No candidates yet') }}
                  </span>
                  <!-- ./No candidates -->
                </div>
                <!-- ./List body -->
              </div>
            </div>
          </template>
        </draggable>

        <!-- ./Dragabble -->
      </div>

      <!-- ./Main section -->
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { __ } from '../helpers.js';
  import { OldMultiselect } from './global';
  import { InputSelect } from '@/components/form/input-select';
  // write data and computed
  export default {
    // params from blade
    components: {
      InputSelect
    },
    props: [
      'board',
      'csrf',
      'boards',
      'config_display_users_anonymously',
      'toggle',
      'first_link'
    ],
    data: function () {
      return {
        loadedIssue: false,
        mounted: false,
        timer: null,
        windowWidth: window.innerWidth,
        filteredCandidates: 0,
        loggedRecruiter: null,
        changeEvent: {},
        // Conditional rendering
        displayCardModal: false,
        displayInfoModal: false,
        displayConfirmModal: false,
        displayClosePositionModal: false,
        displayReopenPositionModal: false,
        displayLabelsModal: false,
        displayCreateLabel: false,
        displayEditLabel: false,
        displayList: 0,
        displayCard: 0,
        displayModalMobile: [],
        displayTranslation: false,
        displayEditComment: [],
        // Input fields
        newDescription: '',
        newComment: '',
        editedComment: [],
        newLabelName: '',
        newLabelColor: '#fce7f3',
        newJobBoard: '',
        editingLabel: '',
        editor: null,
        emailSubject: null,
        // Others
        colors: {
          '#fce7f3': {
            class: 'text-pink-500 bg-pink-100 border-pink-500',
            dark: '#ec4899',
            bg_color: 'bg-pink-500'
          },
          '#dcfce7': {
            class: 'text-green-500 bg-green-100 border-green-500',
            dark: '#22c55e',
            bg_color: 'bg-green-500'
          },
          '#fef9c3': {
            class: 'text-yellow-500 bg-yellow-100 border-yellow-500',
            dark: '#eab308',
            bg_color: 'bg-yellow-500'
          },
          '#dbeafe': {
            class: 'text-blue-500 bg-blue-100 border-blue-500',
            dark: '#3b82f6',
            bg_color: 'bg-blue-500'
          },
          '#ffedd5': {
            class: 'text-orange-500 bg-orange-100 border-orange-500 ',
            dark: '#f97316',
            bg_color: 'bg-orange-500'
          },
          '#f3e8ff': {
            class: 'text-purple-500 bg-purple-100 border-purple-500',
            dark: '#a855f7',
            bg_color: 'bg-purple-500'
          },
          '#fee2e2': {
            class: 'text-red-500 bg-red-100 border-red-500',
            dark: '#ef4444',
            bg_color: 'bg-red-500'
          }
        },
        labels: {},
        lists: [],
        recruiters: [],
        defaultLang: null,
        isLoading: false,
        isRequestPending: false,
        errorClosePosition: null,
        errorReopenPosition: null,
        boardsShowRoute: route('company.kanban.boards.show', ''),
        selectedBoard: {
          id: this.board.id,
          name: Object.values(this.boards).find(
            (board) => board.id === this.board.id
          )?.job.title
        }
      };
    },
    watch: {
      selectedBoard: function (newVal) {
        if (newVal) {
          const boardId = newVal.id;
          const route =
            this.toggle == 'closed'
              ? this.boardsShowRoute + '/' + boardId + '/closed'
              : this.boardsShowRoute + '/' + boardId;

          window.location.href = route;
        }
      }
    },

    mounted() {
      var parent = this;
      // Load lists and cards
      axios
        .get(route('company.kanban.boards.load', this.board.id))
        .then(function (response) {
          parent.lists = response.data.lists.map(function (list) {
            list.titleBackup = list.title;
            list.cards = list.cards.map(function (card) {
              card.descriptionBackup = card.description;
              card.recruiterBackup = card.recruiter;
              return card;
            });
            return list;
          });
          parent.labels = response.data.labels;
          parent.recruiters = response.data.recruiters;
          parent.loggedRecruiter = response.data.loggedRecruiter;
          parent.defaultLang = response.data.defaultLang;

          parent.timer = setInterval(() => {
            if (!parent.isRequestPending) {
              axios
                .get(route('company.kanban.boards.load', parent.board.id))
                .then(function (response) {
                  parent.lists = response.data.lists.map(function (list) {
                    list.titleBackup = list.title;
                    list.cards = list.cards.map(function (card) {
                      card.descriptionBackup = card.description;
                      card.recruiterBackup = card.recruiter;
                      return card;
                    });
                    return list;
                  });
                  parent.labels = response.data.labels;
                  parent.recruiters = response.data.recruiters;
                  parent.loggedRecruiter = response.data.loggedRecruiter;
                })
                .catch(() => {
                  parent.loadedIssue = true;
                  parent.timer = null;
                  this.$nextTick(() => {
                    $('#issue-message').css('display', 'block');
                  });
                });
            }
          }, 20000);
        })
        .catch(() => {
          parent.loadedIssue = true;
          this.$nextTick(() => {
            $('#issue-message').css('display', 'block');
          });
        })
        .finally(() => {
          parent.mounted = true;
        });
    },
    computed: {
      availableBoards() {
        return Object.values(this.boards).map((board) => ({
          id: board.id,
          name: board?.job.title
        }));
      },
      taskDragOptions() {
        return {
          animation: 200,
          group: 'task-list',
          ghostClass: 'ghost'
        };
      },
      emailMessage() {
        var message =
          this.changeEvent.card.language in
          this.lists[this.changeEvent.to_list_idx].email.metadata
            ? this.lists[this.changeEvent.to_list_idx].email.metadata[
                this.changeEvent.card.language
              ]['message']
            : this.lists[this.changeEvent.to_list_idx].email.metadata[
                this.defaultLang
              ]['message'];

        return this.parseMessage(message);
      },
      emailMessageTranslated() {
        var message =
          this.loggedRecruiter.language in
          this.lists[this.changeEvent.to_list_idx].email.metadata
            ? this.lists[this.changeEvent.to_list_idx].email.metadata[
                this.loggedRecruiter.language
              ]['message']
            : this.lists[this.changeEvent.to_list_idx].email.metadata[
                this.defaultLang
              ]['message'];

        return this.parseMessage(message);
      },
      emailSubjectTranslated() {
        return this.loggedRecruiter.language in
          this.lists[this.changeEvent.to_list_idx].email.metadata
          ? this.lists[this.changeEvent.to_list_idx].email.metadata[
              this.loggedRecruiter.language
            ]['subject']
          : this.lists[this.changeEvent.to_list_idx].email.metadata[
              this.defaultLang
            ]['subject'];
      }
    },
    methods: {
      filterCandidates(event) {
        this.filteredCandidates = parseInt(event.target.value);
      },
      cardsCount(list) {
        if (this.filteredCandidates) {
          var parent = this;
          return list.cards.filter(function (card) {
            return (
              card.recruiter && card.recruiter.id == parent.loggedRecruiter.id
            );
          }).length;
        }
        return list.cards.length;
      },
      // Conditional rendering
      showCardModal(cardId) {
        this.displayList = this.lists.findIndex((object) => {
          return (
            object.cards.filter(function (user) {
              return user.id === cardId;
            }).length > 0
          );
        });
        this.displayCard = this.lists[this.displayList].cards.findIndex(
          (object) => {
            return object.id === cardId;
          }
        );
        this.displayCardModal = true;

        this.$nextTick(() => {
          $('#user-modal').css('display', '');

          var expandArea = function () {
            const $el = $(this);
            const offset = $el.innerHeight() - $el.height();

            if ($el.innerHeight() < this.scrollHeight) {
              $el.height(this.scrollHeight - offset);
            } else {
              $el.height(1);
              $el.height(this.scrollHeight - offset);
            }
          };
          $('.special-area').each(expandArea);
          $('.special-area').on('keyup paste', expandArea);
        });
      },
      showModalMobile(id) {
        this.displayModalMobile = [];
        this.displayModalMobile.push(id);
      },
      showInfoModal() {
        this.displayInfoModal = true;
        this.$nextTick(() => {
          $('#info-modal').css('display', '');
        });
      },
      showClosePositionModal() {
        this.displayClosePositionModal = true;
        this.$nextTick(() => {
          $('#close-position-modal').css('display', '');
        });
      },
      showReopenPositionModal() {
        this.displayReopenPositionModal = true;
        this.$nextTick(() => {
          $('#reopen-position-modal').css('display', '');
        });
      },
      showEditLabel(id) {
        this.newLabelName = this.labels[id].text;
        this.newLabelColor = this.labels[id].color;
        this.editingLabel = id;
        this.displayEditLabel = true;
      },
      editComment(currentComment) {
        this.editedComment[currentComment.id] = currentComment.text;
        this.displayEditComment[currentComment.id] = true;
      },
      closeEditComment(currentComment) {
        this.displayEditComment[currentComment.id] = false;
      },
      hideModalMobile() {
        this.displayModalMobile = [];
      },
      hideLabelsModal() {
        this.displayLabelsModal = false;
      },
      hideAllModals() {
        this.displayCardModal = false;
        this.displayInfoModal = false;
        this.displayConfirmModal = false;
        this.displayClosePositionModal = false;
        this.displayReopenPositionModal = false;
        this.displayLabelsModal = false;
        this.displayCreateLabel = false;
        this.displayTranslation = false;

        this.newJobBoard = '';
        this.newComment = '';
        this.newLabelName = '';
        this.newLabelColor = '#fce7f3';
      },
      canClosePosition() {
        var hiredLists = this.lists
          .filter(function (list) {
            return list.type == 5;
          })
          .map(function (list) {
            return list.cards.length;
          });

        return hiredLists.reduce((a, b) => a + b, 0) >= this.board?.job.openings;
      },
      closePosition() {
        if (!this.canClosePosition()) return;

        var parent = this;
        axios
          .post(
            route('company.role.close', [this.board?.job.id, this.board.id]),
            {
              _token: this.csrf
            },
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          )
          .then(function (response) {
            if (response.data.status === 'success') {
              window.location.reload();
            } else {
              parent.errorClosePosition = response.data.message;
              setTimeout(function () {
                window.location.reload();
              }, 4000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      reopenPosition() {
        if (!parseInt(this.board?.job.closed)) return;

        var parent = this;
        axios
          .post(
            route('company.role.reopen', this.board?.job.id),
            {
              _token: this.csrf
            },
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          )
          .then(function (response) {
            if (response.data.status === 'success') {
              window.location.reload();
            } else {
              parent.errorReopenPosition = response.data.message;
              setTimeout(function () {
                window.location.reload();
              }, 4000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      // Cards
      editCardRecruiter(event) {
        var parent = this;
        parent.isRequestPending = true;
        axios
          .post(
            route('company.kanban.cards.edit'),
            {
              _token: this.token,
              card_id: this.lists[this.displayList].cards[this.displayCard].id,
              recruiter_id: event.target.value
            },
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          )
          .then(function (response) {
            if (response.data.status === 'success') {
              parent.lists[parent.displayList].cards[
                parent.displayCard
              ].recruiter = response.data.recruiter;
              parent.lists[parent.displayList].cards[
                parent.displayCard
              ].recruiterBackup = response.data.recruiter;
              if (parent.filteredCandidates) {
                parent.hideAllModals();
              }
            } else {
              // console.log(response.data.message);
              parent.lists[parent.displayList].cards[
                parent.displayCard
              ].recruiter =
                parent.lists[parent.displayList].cards[
                  parent.displayCard
                ].recruiterBackup;
            }
          })
          .catch((error) => {
            // console.log(error);;
            parent.lists[parent.displayList].cards[
              parent.displayCard
            ].recruiter =
              parent.lists[parent.displayList].cards[
                parent.displayCard
              ].recruiterBackup;
          })
          .finally(() => {
            parent.isRequestPending = false;
          });
      },
      editCardDescription() {
        var parent = this;
        parent.isRequestPending = true;
        axios
          .post(
            route('company.kanban.cards.edit'),
            {
              _token: this.csrf,
              card_id: this.lists[this.displayList].cards[this.displayCard].id,
              description:
                this.lists[this.displayList].cards[this.displayCard].description
            },
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          )
          .then(function (response) {
            if (response.data.status === 'success') {
              parent.lists[parent.displayList].cards[
                parent.displayCard
              ].descriptionBackup =
                parent.lists[parent.displayList].cards[
                  parent.displayCard
                ].description;
            } else {
              // console.log(response.data.message);
              parent.lists[parent.displayList].cards[
                parent.displayCard
              ].description =
                parent.lists[parent.displayList].cards[
                  parent.displayCard
                ].descriptionBackup;
            }
          })
          .catch((error) => {
            // console.log(error);;
            parent.lists[parent.displayList].cards[
              parent.displayCard
            ].description =
              parent.lists[parent.displayList].cards[
                parent.displayCard
              ].descriptionBackup;
          })
          .finally(() => {
            parent.isRequestPending = false;
          });
      },
      editCardPosition(event) {
        var from_list_id = event.from.id.split('-')[1];
        var from_list_idx = this.lists.findIndex((object) => {
          return object.id == from_list_id;
        });
        var to_list_id = event.to.id.split('-')[1];
        var to_list_idx = this.lists.findIndex((object) => {
          return object.id == to_list_id;
        });
        var card =
          this.lists[to_list_idx].cards[
            this.lists[to_list_idx].cards.findIndex((object) => {
              return object.id == event.item.id.split('-')[1];
            })
          ];

        this.changeEvent = {
          card: card,
          from_list: from_list_id,
          from_list_idx: from_list_idx,
          to_list: to_list_id,
          to_list_idx: to_list_idx,
          old_index: event.oldIndex,
          new_index: event.newIndex
        };

        // If no email exists or candidate is moved to different position in the same list
        // no email need to be sent, so no confirm modal is shown
        if (!this.lists[to_list_idx].email || from_list_id == to_list_id) {
          this.persistChangeEvent();
          return;
        }

        // Revert position until confirming the change in the modal
        this.lists[to_list_idx].cards.splice(event.newIndex, 1);
        this.lists[from_list_idx].cards.splice(event.oldIndex, 0, card);

        this.displayConfirmModal = true;
        this.emailSubject =
          card.language in this.lists[to_list_idx].email.metadata
            ? this.lists[to_list_idx].email.metadata[card.language]['subject']
            : this.lists[to_list_idx].email.metadata[this.defaultLang][
                'subject'
              ];

        this.$nextTick(() => {
          $('#confirm-modal').css('display', '');

          this.editor = new Quill(this.$refs.editor, {
            modules: {
              toolbar: [
                [
                  {
                    header: [1, 2, 3, 4, false]
                  }
                ],
                ['bold', 'italic', 'underline', 'link']
              ]
            },
            theme: 'snow',
            formats: ['bold', 'underline', 'header', 'italic', 'link'],
            placeholder: __('Type something in here!')
          });
        });
      },
      persistChangeEvent() {
        var parent = this;
        parent.isRequestPending = true;
        axios
          .post(
            route('company.kanban.cards.change-position'),
            {
              _token: this.token,
              card_id: parent.changeEvent.card.id,
              from_list: parent.changeEvent.from_list,
              to_list: parent.changeEvent.to_list,
              from_position: parent.changeEvent.old_index + 1,
              to_position: parent.changeEvent.new_index + 1
            },
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          )
          .then(function (response) {
            if (response.data.status != 'success') {
              parent.lists[parent.changeEvent.to_list_idx].cards.splice(
                parent.changeEvent.new_index,
                1
              );
              parent.lists[parent.changeEvent.from_list_idx].cards.splice(
                parent.changeEvent.old_index,
                0,
                parent.changeEvent.card
              );
            }
          })
          .catch((error) => {
            parent.lists[parent.changeEvent.to_list_idx].cards.splice(
              parent.changeEvent.new_index,
              1
            );
            parent.lists[parent.changeEvent.from_list_idx].cards.splice(
                parent.changeEvent.old_index,
                0,
                parent.changeEvent.card
            );
          })
          .finally(() => {
            parent.changeEvent = {};
            parent.isRequestPending = false;
          });
      },
      confirmAndPersistChangeEvent(sendEmail = true) {
        var parent = this;
        parent.isRequestPending = true;
        parent.isLoading = true;

        var data = {
          _token: this.csrf,
          card_id: parent.changeEvent.card.id,
          from_list: parent.changeEvent.from_list,
          to_list: parent.changeEvent.to_list,
          from_position: parent.changeEvent.old_index + 1,
          to_position: parent.changeEvent.new_index + 1
        };
        if (sendEmail) {
          data['email_subject'] = parent.emailSubject;
          data['email_message'] = parent.editor.root.innerHTML;
          parent.isLoading = true;
        }

        axios
          .post(route('company.kanban.cards.change-position'), data, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest'
            }
          })
          .then(function (response) {
            if (response.data.status == 'success') {
              // If reordering succeeds on backend, reorder cards on frontend
              parent.lists[parent.changeEvent.to_list_idx].cards.splice(
                parent.changeEvent.new_index,
                0,
                parent.changeEvent.card
              );
              parent.lists[parent.changeEvent.from_list_idx].cards.splice(
                parent.changeEvent.old_index,
                1
              );
            } else {
              // console.log(response.data.message)
            }
          })
          .catch((error) => {
            // console.log(error);
          })
          .finally(() => {
            parent.changeEvent = {};
            parent.editor = null;
            parent.displayConfirmModal = false;
            parent.isLoading = false;
            parent.isRequestPending = false;
          });
      },
      cancelChangeEvent() {
        this.changeEvent = {};
        this.displayConfirmModal = false;
      },
      // Comments
      addComment(displayList, displayCard) {
        if (this.newComment == '') {
          return;
        }

        var parent = this;
        parent.isRequestPending = true;
        axios
          .post(
            route('company.kanban.comments.add'),
            {
              _token: this.csrf,
              card_id: parent.lists[displayList].cards[displayCard].id,
              text: this.newComment
            },
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          )
          .then(function (response) {
            if (response.data.status === 'success') {
              parent.lists[displayList].cards[displayCard].comments.push(
                response.data.comment
              );
              parent.newComment = '';
            } else {
              // console.log(response.data.message);
            }
          })
          .catch((error) => {
            // console.log(error.message);
          })
          .finally(() => {
            parent.isRequestPending = false;
          });
      },
      saveComment(comment, displayList, displayCard) {
        var parent = this;
        var editedCommentText = this.editedComment[comment.id]
        axios
          .post(
            route('company.kanban.comments.edit', {'id': comment.id}),
            {
              _token: this.csrf,
              card_id: this.lists[displayList].cards[displayCard].id,
              text: editedCommentText
            },
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          )
          .then(function (response) {
            if (response.data.status) {
              comment.text = editedCommentText;
            }
          })
          .catch((error) => {
          })
          .finally(() => {
            parent.displayEditComment[comment.id] = false;
          });
      },
      addToJobBoard() {
        var parent = this;
        if (!parent.newJobBoard) {
          return;
        }

        parent.isRequestPending = true;
        axios
          .post(
            route('company.kanban.cards.add'),
            {
              _token: this.csrf,
              user_id:
                parent.lists[parent.displayList].cards[parent.displayCard]
                  .user_id,
              board_id: parent.newJobBoard
            },
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          )
          .then(function (response) {
            if (response.data.status === true) {
              parent.lists[parent.displayList].cards[
                parent.displayCard
              ].processes.push(response.data.label);
              delete parent.lists[parent.displayList].cards[parent.displayCard]
                .availableProcesses[parent.newJobBoard];
            } else {
              // console.log(response.data.message);
            }
          })
          .catch((error) => {
            // console.log(error.message);
          })
          .finally(() => {
            parent.isRequestPending = false;
            parent.newJobBoard = '';
          });
      },
      // Labels
      selectColor(hex) {
        this.newLabelColor = hex;
      },
      createLabel() {
        if (!this.newLabelName || !this.newLabelColor) {
          return;
        }

        var parent = this;
        parent.isRequestPending = true;
        axios
          .post(
            route('company.kanban.labels.add'),
            {
              _token: this.csrf,
              board_id: this.board.id,
              name: parent.newLabelName,
              color: parent.newLabelColor
            },
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          )
          .then(function (response) {
            if (response.data.status === 'success') {
              parent.labels[response.data.label.id] = {
                text: parent.newLabelName,
                color: parent.newLabelColor
              };
              parent.newLabelName = '';
              parent.newLabelColor = '#fce7f3';
              parent.displayCreateLabel = false;
            } else {
              // console.log(response.data.message);
            }
          })
          .catch((error) => {
            // console.log(error.message);
          })
          .finally(() => {
            parent.isRequestPending = false;
          });
      },
      updateLabel() {
        if (!this.newLabelName || !this.newLabelColor) {
          return;
        }

        var parent = this;
        parent.isRequestPending = true;
        axios
          .post(
            route('company.kanban.labels.edit'),
            {
              _token: this.csrf,
              label_id: parent.editingLabel,
              name: parent.newLabelName,
              color: parent.newLabelColor
            },
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          )
          .then(function (response) {
            if (response.data.status === 'success') {
              parent.labels[parent.editingLabel] = {
                text: parent.newLabelName,
                color: parent.newLabelColor
              };
              parent.newLabelName = '';
              parent.newLabelColor = '#fce7f3';
              parent.editingLabel = '';
              parent.displayEditLabel = false;
            } else {
              // console.log(response.data.message);
            }
          })
          .catch((error) => {
            // console.log(error.message);
          })
          .finally(() => {
            parent.isRequestPending = false;
          });
      },
      deleteLabel() {
        var parent = this;
        parent.isRequestPending = true;
        axios
          .post(
            route('company.kanban.labels.delete'),
            {
              _token: this.csrf,
              label_id: this.editingLabel
            },
            {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          )
          .then(function (response) {
            if (response.data.status === 'success') {
              for (list in parent.lists) {
                for (card in parent.lists[list].cards) {
                  if (parent.lists[list].cards[card].labels.length) {
                    parent.lists[list].cards[card].labels = parent.lists[
                      list
                    ].cards[card].labels.filter(function (label) {
                      return label != parseInt(parent.editingLabel);
                    });
                  }
                }
              }
              delete parent.labels[parent.editingLabel];
              parent.newLabelName = '';
              parent.newLabelColor = '#fce7f3';
              parent.editingLabel = '';
              parent.displayEditLabel = false;
            } else {
              // console.log(response.data.message);
            }
          })
          .catch((error) => {
            // console.log(error.message);
          })
          .finally(() => {
            parent.isRequestPending = false;
          });
      },
      toggleLabel(labelId) {
        var parent = this;
        parent.isRequestPending = true;

        if (
          this.lists[this.displayList].cards[this.displayCard].labels.includes(
            labelId
          )
        ) {
          // Remove label
          axios
            .post(
              route('company.kanban.card-labels.remove'),
              {
                _token: this.csrf,
                label_id: labelId,
                card_id:
                  parent.lists[parent.displayList].cards[parent.displayCard].id
              },
              {
                headers: {
                  'X-Requested-With': 'XMLHttpRequest'
                }
              }
            )
            .then(function (response) {
              if (response.data.status === 'success') {
                var arr =
                  parent.lists[parent.displayList].cards[parent.displayCard]
                    .labels;
                arr.splice(
                  parent.lists[parent.displayList].cards[
                    parent.displayCard
                  ].labels.indexOf(labelId),
                  1
                );
                parent.lists[parent.displayList].cards[
                  parent.displayCard
                ].labels = arr;
              } else {
                // console.log(response.data.message);
              }
            })
            .catch((error) => {
              // console.log(error.message);
            })
            .finally(() => {
              parent.isRequestPending = false;
            });
        } else {
          // Assign label
          axios
            .post(
              route('company.kanban.card-labels.assign'),
              {
                _token: this.csrf,
                label_id: labelId,
                card_id:
                  parent.lists[parent.displayList].cards[parent.displayCard].id
              },
              {
                headers: {
                  'X-Requested-With': 'XMLHttpRequest'
                }
              }
            )
            .then(function (response) {
              if (response.data.status === 'success') {
                parent.lists[parent.displayList].cards[
                  parent.displayCard
                ].labels.push(labelId);
              } else {
                // console.log(response.data.message);
              }
            })
            .catch((error) => {
              // console.log(error.message);
            })
            .finally(() => {
              parent.isRequestPending = false;
            });
        }
      },
      parseMessage(message) {
        message = message.replace('[job_title]', this.board?.job.title);
        message = message.replace(
          '[from_process_step]',
          this.lists[this.changeEvent.from_list_idx].title
        );
        message = message.replace(
          '[to_process_step]',
          this.lists[this.changeEvent.to_list_idx].title
        );
        message = message.replace(
          '[first_name]',
          this.changeEvent.card.firstName
        );
        message = message.replace(
          '[last_name]',
          this.changeEvent.card.lastName
        );
        message = message.replace('[full_name]', this.changeEvent.card.name);
        return message;
      }
    }
  };
</script>
