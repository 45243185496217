<template>
  <div
    class="md:shadow-card h-fit w-full space-y-6 rounded-lg bg-white px-6 py-8 md:space-y-8 md:p-14"
  >
    <div class="space-y-2">
      <h1 class="text-2xl font-medium text-zinc-800 md:text-4xl">
        {{ __('Personalize your experience. Choose your path!') }}
      </h1>
      <p class="text-sm font-medium text-zinc-800 lg:text-lg">
        {{
          __('Select carefully—once your path is chosen, it can’t be changed.')
        }}
      </p>
    </div>

    <div class="grid gap-4 lg:grid-cols-2 lg:gap-6">
      <button
        v-for="path in paths"
        :key="path.id"
        :class="`${isSelected(path) ? 'bg-secondary text-white' : 'bg-secondary/5 text-zinc-800'} cursor-pointer space-y-2 rounded-2xl p-5 pb-6 text-left transition hover:shadow-md`"
        @click="handlePathButtonClick(path)"
      >
        <span class="block text-lg font-medium">
          {{ path.name }}
        </span>

        <span class="block">
          {{ __('Tap the card to start.') }}
        </span>
      </button>
    </div>

    <div class="flex items-center justify-between">
      <Button
        v-if="!hideBackButton"
        class="flex items-center gap-1.5"
        variant="ghost"
        @click="handleBackButtonClick"
      >
        <Icon class="text-lg" name="arrow_back_ios_new" />
        <span>{{ __('Go Back') }}</span>
      </Button>

      <Button
        class="ml-auto flex items-center gap-1.5"
        :disabled="isNextButtonDisabled"
        @click="handleSubmit"
      >
        <Icon
          v-if="isLoading"
          class="animate-spin text-lg"
          name="progress_activity"
        />

        <span>{{ __('Next') }}</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    languages: {
      type: Array,
      default: () => []
    },
    paths: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:selected-path']);

  const toast = useToast();

  const selectedPath = ref(null);
  const isLoading = ref(false);

  const hideBackButton = computed(() => {
    return props.languages.length === 1;
  });

  const isNextButtonDisabled = computed(() => {
    return !selectedPath.value;
  });

  function isSelected(path) {
    return selectedPath.value && selectedPath.value.id === path.id;
  }

  function handlePathButtonClick(path) {
    selectedPath.value = path;
  }

  function handleBackButtonClick() {
    emits('update:selected-language', null);
  }

  async function handleSubmit() {
    isLoading.value = true;

    try {
      await axios.post(route('onboarding.paths'), {
        path: selectedPath.value
      });

      emits('update:selected-path', selectedPath.value);
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }
</script>
