<template>
  <div class="shadow-card space-y-4 rounded-lg bg-white p-6 md:p-8">
    <h3 class="text-xl font-medium text-zinc-800">
      {{
        __(
          'Send an e-mail to invite your team members to add them to the company account.'
        )
      }}
    </h3>

    <form class="space-y-4" @submit.prevent="handleSubmit">
      <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
        <InputText
          id="email"
          v-model:value="values.email"
          v-model:errors="errors.email"
          :label="__('Email')"
          :placeholder="__('Email')"
          required
        />
        <InputSelect
          id="role"
          v-model:value="values.role"
          v-model:errors="errors.role"
          :label="__('Role')"
          :placeholder="__('Role')"
          :options="options.types"
          required
        />
      </div>

      <Button
        :disabled="isLoading"
        :type="isLoading ? 'button' : 'submit'"
        class="ml-auto flex w-full items-center justify-center gap-1.5 md:w-fit"
      >
        <Icon
          v-if="isLoading"
          class="animate-spin text-lg"
          name="progress_activity"
        />
        <span>{{ __(isLoading ? 'Loading...' : 'Invite') }}</span>
      </Button>
    </form>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { isValidEmail } from '@/scripts/validate.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputSelect } from '@/components/form/input-select';
  import { InputText } from '@/components/form/input-text';

  const props = defineProps({
    members: {
      type: Array,
      required: true
    },
    invitations: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['invite']);

  const toast = useToast();

  const values = ref({ email: '', role: null });
  const errors = ref({ email: [], role: [] });
  const isLoading = ref(false);

  async function handleSubmit() {
    const requiredFieldKeys = ['email', 'role'];
    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (!isValidEmail(values.value.email)) {
      errors.value.email = [__('Please enter a valid email address.')];
    } else if (
      props.members
        .map((member) => member.email?.toLowerCase())
        .includes(values.value.email?.toLowerCase())
    ) {
      errors.value.email = [
        __(
          'The email address you have entered already belongs to a registered member'
        )
      ];
    } else if (
      props.invitations
        .map((invitation) => invitation.email.toLowerCase())
        .includes(values.value.email.toLowerCase())
    ) {
      errors.value.email = [
        __('An invitation has already been sent to this email address')
      ];
    }

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.company.members.invite'),
        { email: values.value.email, role_id: values.value.role.id }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('invite', response.data.recruiter);

      values.value.email = '';
      values.value.role = null;
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
