<template>
  <Combobox
    by="email"
    multiple
    :model-value="values"
    @update:model-value="handleUpdateModelValue"
  >
    <div class="relative">
      <ComboboxLabel class="mb-1.5 block leading-normal text-zinc-800">
        {{ __('Search speakers') }}
      </ComboboxLabel>

      <ComboboxButton
        id="combobox-button"
        class="flex w-full cursor-default items-center justify-between gap-2 overflow-hidden rounded-md bg-white px-3.5 py-2.5 shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset ring-gray-200 focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary"
      >
        <Icon class="text-lg text-neutral-400" name="search" is-filled />

        <ComboboxInput
          :placeholder="__('Search speakers')"
          class="w-full truncate border-none bg-transparent p-0 text-zinc-800 placeholder:text-zinc-500 focus:ring-0"
          @input="handleInput"
        />

        <Icon class="text-xl text-zinc-600" name="expand_more" />
      </ComboboxButton>

      <TransitionRoot
        leave-from="opacity-100"
        leave-to="opacity-0"
        @after-leave="handleAfterLeave"
      >
        <ComboboxOptions
          class="shadow-card absolute z-10 mt-1 max-h-60 w-max min-w-full max-w-full overflow-y-auto rounded-md border border-gray-200 bg-white p-1 text-sm leading-normal text-zinc-800 focus:outline-none"
        >
          <template v-if="options.length">
            <ComboboxOption
              v-for="option in options"
              :key="option"
              v-slot="{ selected, active }"
              :value="option"
              as="template"
            >
              <li
                class="flex cursor-default select-none items-center justify-between gap-2 rounded-md px-3 py-1.5 transition-colors"
                :class="{
                  'font-medium': selected,
                  'bg-gray-50': active
                }"
              >
                <div>
                  <span class="block">
                    {{ `${option.first_name} ${option.last_name}` }}
                  </span>
                  <span class="block text-xs text-zinc-600">
                    {{ option.email }}
                  </span>
                </div>

                <Icon v-if="selected" class="text-primary" name="check" />
              </li>
            </ComboboxOption>
          </template>

          <li v-else class="cursor-default select-none px-3 py-1.5">
            {{ __('No results found') }}
          </li>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    TransitionRoot
  } from '@headlessui/vue';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    values: {
      type: Array,
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  });

  const options = computed(() => {
    let filteredOptions = JSON.parse(JSON.stringify(props.options));

    if (query.value) {
      filteredOptions = filteredOptions.filter(
        (option) =>
          option.name.toLowerCase().includes(query.value.toLowerCase()) ||
          option.email.toLowerCase().includes(query.value.toLowerCase())
      );
    }

    return filteredOptions;
  });

  const emits = defineEmits(['update:values', 'update:errors']);

  const query = ref('');

  function handleAfterLeave() {
    query.value = '';
  }

  function handleInput(event) {
    query.value = event.target.value;
  }

  function handleUpdateModelValue(values) {
    emits('update:values', values);
    emits('update:errors', []);
  }
</script>
