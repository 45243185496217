import * as Vue from 'vue';
import { createApp } from 'vue/dist/vue.esm-bundler';
import { LoadingPlugin } from 'vue-loading-overlay';
import Toast from 'vue-toastification';
import dragabble from 'vuedraggable';
import vueClickOutside from 'click-outside-vue3';
import router from './router';
import { __ } from './helpers';
import 'vue-toastification/dist/index.css';
import 'vue-loading-overlay/dist/css/index.css';

import {
  CandidateEvent,
  CandidateEvents,
  CandidateGroup,
  CandidateGroups,
  CreateJob,
  CompanyEvent,
  CompanyEvents,
  CompanyGroup,
  CompanyGroups,
  CompanyProfile,
  EditJob,
  EventForm,
  GroupForm,
  JobsListing,
  JobsListingCompany,
  Members,
  MyJobs,
  Resume,
  Settings,
  Onboarding,
  UserSettings,
  Speakers,
  Courses,
  UserProfile
} from './pages';

import { Button, Multiselect, OldMultiselect } from './components/global';
import {
  CandidatesSidebar,
  Sidebar,
  LayoutSidebar,
  LayoutSidebarOld
} from './components/layout';
import SidebarCompanyOld from './components/layout/companies/SidebarCompanyOld.vue';
import { SearchCandidate } from './components/search-candidate';
import { StepOne, StepTwo } from './components/onboarding-documents';
import Kanban from './components/Kanban.vue';

// Gamification components
import {
  CandidateGamification,
  GamificationHeader,
  GamificationProfile,
  GamificationNotificationProfile,
  GamificationLeaderboard,
  GamificationLeaderboardFriends,
  GamificationLeaderboardWeekly,
  GamificationLeaderboardMonthly,
  GamificationQuests,
  GamificationNewsNotification,
  GamificationNews,
  GamificationAccomplishments,
  GamificationAccomplishmentsAchivements,
  GamificationAccomplishmentsBadges
} from './components/gamification';

import { ManagementDashboard } from './components/companies/candidate-management';
import {
  CandidatesFilter,
  Applications,
  Invitations,
  Contacts,
  Matches,
  PastApplications,
  PastInvitations
} from './components/companies/candidate-management/partials';
import {
  ApplicationActions,
  ApplicationDate,
  CandidateDetailsAnonymous,
  Extras,
  Experience,
  Location,
  NameAnonymousSeen,
  Notes,
  TimeLeft,
  InvitedBy,
  NameContact,
  CandidateDetailsContact,
  Source,
  SourceLink,
  LocationContact,
  Cv,
  KanbanColumn,
  Name,
  CandidateDetails,
  MatchesActions,
  MatchDate,
  Messages,
  NameAnonymous,
  Status,
  Reason,
  PastApplicationsActions,
  PastInvitationsActions,
  InvitationDate
} from './components/companies/candidate-management/partials/columns';

import { JobSingle } from './pages/candidate/jobs/partials/index';

import { getTimezone } from '@/scripts/date.script';

// Set timezone cookie
document.cookie = `timezone=${getTimezone().tz}; path=/`;

import { GamificationModal } from '@/components/other/gamification-modal';

// Create the Vue app
const app = createApp({});

// TODO: Turn off on production
app.config.devtools = true;

// Add custom global methods
app.mixin({
  methods: {
    route: function (name, params = {}) {
      return window.route(name, params);
    },
    __
  }
});

// Toast
const options = {
  // You can set your default options here
  position: 'bottom-right',
  timeout: 3000,
  pauseOnHover: true,
  closeOnClick: true
};

app.use(Toast, options);
app.use(vueClickOutside);

const capitalizeDirective = {
  beforeMount(el, binding) {
    if (binding.value == false) return;
    el.addEventListener('input', function (e) {
      let value = e.target.value;
      // Only update if the first letter is not already uppercase
      if (value && value[0] !== value[0].toUpperCase()) {
        e.target.value = value.charAt(0).toUpperCase() + value.slice(1);
        // Emit the input event to update v-model or any other listeners
        el.dispatchEvent(new Event('input'));
      }
    });
  }
};

app.directive('capitalize', capitalizeDirective);

app.use(LoadingPlugin, {
  color: 'red',
  loader: 'dots',
  'lock-scroll': true
});

app.use(router);

// Register the components
app.component('draggable', dragabble);
app.component('kanban', Kanban);
app.component('multiselect', Multiselect);
app.component('old-multiselect', OldMultiselect);
app.component('Button', Button);

// Register the gamification components
app.component('candidate-gamification', CandidateGamification);
app.component('gamification-header', GamificationHeader);
app.component('gamification-profile', GamificationProfile);
app.component(
  'gamification-notification-profile',
  GamificationNotificationProfile
);
app.component('gamification-leaderboard', GamificationLeaderboard);
app.component(
  'gamification-leaderboard-friends',
  GamificationLeaderboardFriends
);
app.component('gamification-leaderboard-weekly', GamificationLeaderboardWeekly);
app.component(
  'gamification-leaderboard-monthly',
  GamificationLeaderboardMonthly
);
app.component('gamification-quests', GamificationQuests);
app.component('gamification-news', GamificationNews);
app.component('gamification-news-notification', GamificationNewsNotification);
app.component('gamification-accomplishments', GamificationAccomplishments);
app.component(
  'gamification-accomplishments-achivements',
  GamificationAccomplishmentsAchivements
);
app.component(
  'gamification-accomplishments-badges',
  GamificationAccomplishmentsBadges
);

// Search
app.component('search-candidate', SearchCandidate);

// Onboarding documents
app.component('onboarding-documents-stepone', StepOne);
app.component('onboarding-documents-steptwo', StepTwo);

// Company Sidebar
app.component('companies-sidebar', Sidebar);
app.component('companies-sidebar-old', SidebarCompanyOld);

// Candidates Sidebar
app.component('candidates-sidebar', CandidatesSidebar);

app.component('sidebar', LayoutSidebar);
app.component('sidebar-old', LayoutSidebarOld);

// Redesign
app.component('Resume', Resume);
app.component('jobs-listing', JobsListing);
app.component('job-single', JobSingle);
app.component('my-jobs', MyJobs);

//
app.component('company-profile', CompanyProfile);
app.component('create-job', CreateJob);
app.component('edit-job', EditJob);
app.component('jobs-listing-company', JobsListingCompany);

// Candidates management
// Components needs to be in global space in order to work with the dynamic component tag
app.component('candidate-management-dashboard', ManagementDashboard);
app.component('CandidatesFilter', CandidatesFilter);
app.component('Applications', Applications);
app.component('Invitations', Invitations);
app.component('Contacts', Contacts);
app.component('Matches', Matches);
app.component('PastApplications', PastApplications);
app.component('PastInvitations', PastInvitations);
app.component('LoadMore', ManagementDashboard);

app.component('ApplicationActions', ApplicationActions);
app.component('ApplicationDate', ApplicationDate);
app.component('CandidateDetailsAnonymous', CandidateDetailsAnonymous);
app.component('Extras', Extras);
app.component('Experience', Experience);
app.component('Location', Location);
app.component('NameAnonymousSeen', NameAnonymousSeen);
app.component('Notes', Notes);
app.component('TimeLeft', TimeLeft);
app.component('InvitedBy', InvitedBy);
app.component('NameContact', NameContact);
app.component('CandidateDetailsContact', CandidateDetailsContact);
app.component('SourceLink', SourceLink);
app.component('Source', Source);
app.component('LocationContact', LocationContact);
app.component('Cv', Cv);
app.component('Kanban', KanbanColumn);
app.component('Name', Name);
app.component('CandidateDetails', CandidateDetails);
app.component('MatchesActions', MatchesActions);
app.component('MatchDate', MatchDate);
app.component('Messages', Messages);
app.component('NameAnonymous', NameAnonymous);
app.component('Status', Status);
app.component('Reason', Reason);
app.component('PastApplicationsActions', PastApplicationsActions);
app.component('PastInvitationsActions', PastInvitationsActions);
app.component('InvitationDate', InvitationDate);

app.component('Members', Members);
app.component('Settings', Settings);
app.component('Onboarding', Onboarding);
app.component('Courses', Courses);

app.component('GamificationModal', GamificationModal);

// Set the inital language
localStorage.setItem('locale', document.documentElement.lang);

app.component('CandidateGroup', CandidateGroup);
app.component('CandidateGroups', CandidateGroups);
app.component('CompanyGroup', CompanyGroup);
app.component('CompanyGroups', CompanyGroups);
app.component('GroupForm', GroupForm);

app.component('CandidateEvent', CandidateEvent);
app.component('CandidateEvents', CandidateEvents);
app.component('CompanyEvent', CompanyEvent);
app.component('CompanyEvents', CompanyEvents);
app.component('EventForm', EventForm);

app.component('Speakers', Speakers);

app.component('UserProfile', UserProfile);

app.component('user-settings', UserSettings);
// Mount the app
app.mount('main');

window.Vue = Vue;
