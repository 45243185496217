<template>
  <Modal open :title="__('Add event')" @close="handleCancelButtonClick">
    <form @submit.prevent="handleSubmit">
      <div class="grid gap-6 md:grid-cols-2">
        <InputDate
          id="date"
          v-model:value="values.date"
          v-model:errors="errors.date"
          :label="__('Date')"
          :placeholder="__('Pick a date')"
          required
        />

        <InputSelect
          id="type"
          v-model:value="values.type"
          v-model:errors="errors.type"
          :label="__('Type')"
          :placeholder="__('Select event type')"
          :options="options"
          required
        />

        <InputTextarea
          id="details"
          v-model:value="values.details"
          v-model:errors="errors.details"
          class="md:col-span-2"
          :label="__('Details')"
          :placeholder="__('Add event details')"
        />
      </div>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </Modal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { formatInputDate } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Modal } from '@/components/block/modal';
  import { InputDate } from '@/components/form/input-date';
  import { InputSelect } from '@/components/form/input-select';
  import { InputTextarea } from '@/components/form/input-textarea';

  const props = defineProps({
    options: {
      type: Array,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  });

  const emits = defineEmits(['cancel', 'save']);

  const toast = useToast();

  const values = ref({
    date: formatInputDate(new Date()),
    type: null,
    details: ''
  });
  const errors = ref({
    date: [],
    type: [],
    details: []
  });
  const isLoading = ref(false);

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    const requiredFieldKeys = ['date', 'type'];

    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.company.add.history.tracking.event'),
        {
          ...values.value,
          user_id: props.userId
        }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', response.data.event);
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
