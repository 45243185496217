<template>
  <div>
    <button
      class="flex items-center gap-1.5 rounded-lg bg-secondary/10 px-4 py-2 transition-colors hover:bg-secondary/25"
      @click="handlePointsButtonClick"
    >
      <span class="text-base font-medium text-zinc-800">{{ points }}</span>

      <svg
        class="h-5 w-5"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="fill-secondary"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.4867 11.1552C20.48 11.8705 20.4734 12.5858 20.4733 13.3015C20.4738 13.3073 20.474 13.3131 20.474 13.3189C20.474 13.3708 20.4544 13.4206 20.4195 13.4573C20.3846 13.494 20.3373 13.5147 20.2879 13.5147H15.3245V20.8051C15.3241 20.8566 15.3045 20.906 15.2698 20.9425C15.2351 20.9789 15.1882 20.9996 15.1391 21H11.6854C11.6363 20.9996 11.5894 20.9789 11.5547 20.9425C11.52 20.906 11.5004 20.8566 11.5 20.8051V3.24101C11.5011 3.18968 11.521 3.14079 11.5555 3.10449C11.5901 3.06819 11.6366 3.04727 11.6854 3.04608C12.3417 2.99783 12.9882 2.99911 13.6521 3.00043C13.7607 3.00064 13.8698 3.00086 13.9795 3.00086H16.3166C16.4089 3.00147 16.5017 3.00152 16.595 3.00158C17.4492 3.00208 18.3373 3.00261 19.106 3.43282C19.4222 3.62037 19.6958 3.87786 19.9081 4.18749C20.1203 4.49712 20.2661 4.85151 20.3354 5.22619C20.4325 5.75959 20.4787 6.30184 20.4733 6.84489C20.5 7.56066 20.5 8.27649 20.5 8.99225C20.5 9.7135 20.4933 10.4344 20.4867 11.1552ZM15.3703 12.1232V4.36587C15.6848 4.35674 16.0365 4.36919 16.2912 4.55515C16.5567 4.74541 16.6056 5.12279 16.6219 5.43312C16.6469 5.91808 16.6412 6.40686 16.6356 6.8941C16.6332 7.1025 16.6308 7.31069 16.6308 7.51811V12.1232H15.3703Z"
        />
        <path
          class="fill-primary"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.21721 10.4857C2.21277 10.4866 2.20838 10.4877 2.20405 10.489C2.15824 10.4913 2.11475 10.5114 2.08217 10.5457C2.04753 10.5821 2.02807 10.6315 2.02807 10.6831C2.02807 11.4027 2.02106 12.1224 2.01404 12.842C2.00702 13.5617 2 14.2813 2 15.001C2 15.6973 2 16.3921 2.02512 17.0885C2.01879 17.6466 2.06382 18.2041 2.15958 18.7531C2.22645 19.1286 2.36992 19.4844 2.58012 19.796C2.79031 20.1075 3.06222 20.3674 3.37711 20.5577C4.15227 20.9999 5.05236 20.9994 5.91694 20.9988C6.00645 20.9988 6.09596 20.9987 6.18454 20.9991H8.5206C8.62956 20.9991 8.73799 20.9994 8.846 20.9996L8.84744 20.9996C9.51089 21.0009 10.1585 21.0022 10.8138 20.9541C10.8628 20.9529 10.9095 20.9319 10.9442 20.8954C10.9789 20.8589 10.9989 20.8098 11 20.7582V3.19429C10.9992 3.14275 10.9793 3.09356 10.9445 3.05726C10.9097 3.02095 10.8628 3.0004 10.8138 3H7.35922C7.31021 3.0004 7.2633 3.02095 7.22851 3.05726C7.19372 3.09356 7.17381 3.14275 7.17304 3.19429V10.4857H2.21721ZM7.12273 11.8756V19.6309C6.80616 19.64 6.44788 19.6268 6.19481 19.4343C5.93327 19.2353 5.89042 18.853 5.87565 18.5405C5.85676 18.1204 5.86087 17.6979 5.86497 17.276C5.86761 17.0049 5.87025 16.7339 5.86678 16.464V11.8756H7.12273Z"
        />
        <path
          d="M21.9703 19C21.9703 20.0882 21.0882 20.9703 20 20.9703C18.9118 20.9703 18.0297 20.0882 18.0297 19C18.0297 17.9118 18.9118 17.0297 20 17.0297C21.0882 17.0297 21.9703 17.9118 21.9703 19Z"
          fill="#9747FF"
          stroke="black"
          stroke-width="0.0593424"
          stroke-miterlimit="10"
        />
      </svg>
    </button>

    <Modal
      size="large"
      :open="isGamificationModalOpen"
      @close="handleCloseButtonClick"
    >
      <div class="relative -m-6 bg-[#FAFAFA] p-8 lg:p-12">
        <button
          class="absolute -right-0 -top-0 flex p-2"
          @click="handleCloseButtonClick"
        >
          <Icon name="close" class="text-xl text-zinc-800" />
        </button>

        <div class="space-y-10">
          <div v-for="section in sections" :key="section" class="space-y-8">
            <div class="space-y-2">
              <span
                class="block text-center text-2xl font-medium text-zinc-800"
              >
                {{ section.title }}
              </span>
              <span class="block text-center text-zinc-800">
                {{ section.description }}
              </span>
            </div>

            <div class="flex flex-wrap justify-center gap-8">
              <div
                v-for="card in section.cards"
                :key="card"
                class="shadow-card flex w-full shrink-0 flex-col items-center gap-6 rounded-lg bg-white px-6 py-8 md:w-72"
              >
                <div class="space-y-3">
                  <div
                    class="shadow-element mx-auto flex w-fit items-center justify-center rounded-xl p-2.5"
                  >
                    <Icon
                      class="text-3xl text-primary"
                      :name="card.icon"
                      is-filled
                    />
                  </div>

                  <div class="space-y-2">
                    <span
                      class="block text-center text-lg font-medium text-zinc-800"
                    >
                      {{ card.title }}
                    </span>
                    <span
                      class="block text-center text-zinc-800"
                      v-html="card.description"
                    ></span>
                  </div>
                </div>

                <Button class="mt-auto" :href="card.link.path">
                  {{ card.link.label }}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Modal } from '@/components/block/modal';

  const props = defineProps({
    open: {
      type: Boolean,
      default: false
    },
    points: {
      type: Number,
      default: 0
    },
    eventsAccess: {
      type: Boolean,
    }
  });

  const emits = defineEmits(['update:open']);

  let sections = [
    {
      title: __('Find out how to earn points effortlessly'),
      description: __(
        'Earn points by completing actions, then exchange them for rewards in the shop.'
      ),
      cards: [
        {
          icon: 'account_box',
          title: __('Complete your resume'),
          description: __('Finish your resume and score up to {points}.', {
            points: `<span class="font-medium text-secondary">300 ${__('points')}</span>`
          }),
          link: {
            label: __('Go to Profile'),
            path: route('redesign.my-cv.show')
          }
        },
        {
          icon: 'diversity_3',
          title: __('Invite new friends'),
          description: __('Earn {points} for each friend.', {
            points: `<span class="font-medium text-secondary">50 ${__('points')}</span>`
          }),
          link: {
            label: __('Go to Friends'),
            path: route('friends.index')
          }
        },
        {
          icon: 'work',
          title: __('Apply to job'),
          description: __('Earn {points} points for the first 3 applications.', {
            points: `<span class="font-medium text-secondary">50 ${__('points')}</span>`
          }),
          link: {
            label: __('Go to Jobs'),
            path: route('redesign.jobs.index')
          }
        }
      ]
    },
    {
      title: __('Keep up the great work!'),
      description: __('Find more ways to earn points.'),
      cards: [
        {
          icon: 'rewarded_ads',
          title: __('Explore the challenges'),
          description: __(
            "The tougher the challenge, the more points it's worth."
          ),
          link: {
            label: __('Go to Challenges'),
            path: route('challenges.index')
          }
        },
        {
          icon: 'book_4',
          title: __('Discover our courses'),
          description: __('Courses reward points on a rising scale.'),
          link: {
            label: __('Go to Courses'),
            path: route('courses.index')
          }
        }
      ]
    }
  ];

  if (props.eventsAccess) {
    sections[1].cards.push({
      icon: 'local_activity',
      title: __('Attend Events'),
      description: __('Point values vary by event type.'),
      link: {
        label: __('Go to Events'),
        path: route('redesign.events.viewAll')
      }
    });
  }

  const isGamificationModalOpen = ref(false);

  function handlePointsButtonClick() {
    isGamificationModalOpen.value = true;
  }

  function handleCloseButtonClick() {
    isGamificationModalOpen.value = false;
  }
</script>
