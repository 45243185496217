<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell">
    <template v-for="(value, key) in columnData" :key="key">
    <div class="text-center flex justify-center gap-1.5" :class="{'flex-col': key === 'rejected_at'}">

        <a v-if="key === 'invite'" @click.prevent="onInviteToJob(value['action']['jobId'], value['action']['userId'], candidateIndex)" href="#" class="block rounded-md bg-brandColor1 px-2 py-1 text-center text-xs text-white shadow-redesign hover:bg-brandColor1/[.80]">{{ value['label'] }}</a>
        <a v-else-if="key === 'pending'" @click.prevent="" href="#" class="block disabled rounded-md bg-gray-300 pointer-events-none px-2 py-1 text-center text-xs text-white shadow-redesign">{{ value['label'] }}</a>
        <template v-else-if="key === 'rejected_at'">
          <p><strong>{{ value['label'] }}: </strong> {{ value['action'] }}</p>
          <div v-if="value['reject_message'] !== ''">
            <Popover v-slot="{ open }" class="relative text-center">
              <PopoverButton :class="open ? 'text-white' : 'text-white/90'" class="group inline-flex items-center mt-2">
                <span class="material-symbols-rounded text-brandColor1 hover:text-brandColor1/[.80]">info</span>
              </PopoverButton>

              <transition enter-active-class="transition duration-200 ease-out" enter-from-class="translate-y-1 opacity-0" enter-to-class="translate-y-0 opacity-100" leave-active-class="transition duration-150 ease-in" leave-from-class="translate-y-0 opacity-100" leave-to-class="translate-y-1 opacity-0">
                <PopoverPanel class="absolute min-w-[18rem] hasEar shadow-redesign text-center left-1/2 z-30 mt-3 -translate-x-1/2 transform px-4 sm:px-0">
                  <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 px-3 py-3 bg-white">
                    <div class="relative">
                      <div class="relative">
                        <p>{{ value['reject_message'] }}</p>
                      </div>
                    </div>
                  </div>

                </PopoverPanel>
              </transition>
            </Popover>
          </div>
        </template>
        <p v-else>{{ key }}</p>

    </div>
    </template>
  </td>
</template>

<script setup>

import axios from "axios";
import { useToast } from "vue-toastification";
import { inject } from 'vue';
import {Popover, PopoverButton, PopoverPanel} from "@headlessui/vue";

const toast = useToast();
const updateCounters = inject('updateCounters');
const updateComponentTotal = inject('updateComponentTotal');

const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});

const onInviteToJob = (jobId, userId, candidateIndex) => {
  axios.post(route('redesign.matching.inviteToJob'), {
    job_id: jobId,
    user_id: userId
  }).then((response) => {
    props.people.splice(candidateIndex, 1)
    updateCounters();
    updateComponentTotal(props.total - 1)
    toast.success(response.data)
  }).catch((err) => {
    toast.error(err.response.data);
  });
}


</script>
