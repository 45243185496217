<template>
  <div>
    <div
      class="text-zinc-600 [&>ol]:list-decimal [&>ol]:space-y-1 [&>ol]:pl-4 [&>p]:min-h-4 [&>ul]:list-disc [&>ul]:space-y-1 [&>ul]:pl-4 [&_em]:italic [&_p]:leading-normal [&_strong]:font-bold"
      v-html="sanitizedContent"
    />

    <button
      v-if="content.length > limit"
      class="mt-4 text-primary underline underline-offset-2"
      @click="handleViewMoreButtonClick"
    >
      {{ isViewMore ? __('Hide') : __('View more') }}
    </button>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';

  const props = defineProps({
    content: {
      type: String,
      required: true
    },
    limit: {
      type: Number,
      default: 600
    }
  });

  const isViewMore = ref(false);

  const sanitizedContent = computed(() => {
    const content =
      props.content.length > props.limit && !isViewMore.value
        ? props.content.slice(0, props.limit) + '...'
        : props.content;

    return content.replace(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
      ''
    );
  });

  function handleViewMoreButtonClick() {
    isViewMore.value = !isViewMore.value;
  }
</script>
