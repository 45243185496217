<template>
  <div id="values">
    <div class="mt-8 md:grid md:grid-cols-2">
      <SectionHeader icon="star" title="Skills" />
    </div>

    <div class="relative mt-8 rounded-app bg-white p-8 shadow-md">
      <div class="space-y-4">
        <InputSkills
          id="required_skills"
          v-model:value="data.job.required"
          :errors="errors.required_skills"
          :label="__('Required skills')"
          :placeholder="__('Add skills')"
          required
          is-new-options-allowed
          @update:value="createNewSkill"
        />

        <InputSkills
          id="optional_skills"
          v-model:value="data.job.optional"
          :errors="errors.optional"
          :label="__('Optional skills')"
          :placeholder="__('Add skills')"
          required
          is-new-options-allowed
          @update:value="createNewSkill"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useToast } from 'vue-toastification';
  import { useLoading } from 'vue-loading-overlay';
  import axios from 'axios';
  import { __ } from '../../../../helpers';
  import { SectionHeader } from '../../../../components/global';
  import { InputSkills } from '@/components/form/input-skills';

  const toast = useToast();
  const props = defineProps({
    data: {
      type: Object,
      mandatory: true
    },
    errors: {
      type: Object,
      mandatory: true
    }
  });

  const $loading = useLoading();

  const createNewSkill = (values, resolve) => {
    if (!values || !values.length || values[values.length - 1].id !== 'custom-option') {
      return;
    }

    const loader = $loading.show();

    axios.post(route('redesign.company.skills.create'), {
      name: values[values.length - 1].name
    }).then((response) => {
      resolve(response.data);
      toast.success(response.data.message);
    }).catch((err) => {
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(__('An unexpected error has occured. Please try again later.'));
      }
    }).finally(() => {
      loader.hide();
    });
  };
</script>
