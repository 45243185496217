<template>
  <div>
    <Combobox
        nullable
        :by="compareOptions"
        :disabled="disabled"
        :multiple="isMultipleSelection"
        :model-value="value"
        @update:model-value="
        (value) => {
          handleUpdateModelValue(value);
          handleCloseCombobox();
        }
      "
    >
      <div v-click-outside="handleClickOutside" class="relative">
        <ComboboxLabel
            v-if="label"
            :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
        >
          {{ `${label}${required ? '*' : ''}` }}
        </ComboboxLabel>

        <ComboboxButton
            :id="`combobox-button-input-skills-${props.id}`"
            :class="`${errors.length ? 'ring-error' : 'ring-gray-200'} ${disabled ? 'cursor-not-allowed bg-zinc-100' : 'bg-white'} flex w-full items-center gap-2.5 rounded-md px-3.5 py-2.5 shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary`"
        >
          <div
              v-if="slots['icon']"
              class="pointer-events-none flex text-lg text-neutral-400"
          >
            <slot name="icon" />
          </div>

          <ComboboxInput
              :class="`${disabled ? 'cursor-not-allowed text-zinc-400 placeholder:text-zinc-400' : 'text-zinc-800 placeholder:text-zinc-500'} grow truncate border-none bg-transparent p-0 focus:ring-0`"
              :placeholder="placeholder"
              :display-value="(value) => value?.name"
              @input="handleInput"
              @keyup.space.prevent
          />

          <button
              v-if="!isMultipleSelection && value"
              :disabled="disabled"
              class="-m-1 flex p-1"
              @click="handleUpdateModelValue(null)"
          >
            <Icon
                :class="`${disabled ? 'cursor-not-allowed' : ''} text-lg text-zinc-400`"
                name="close"
            />
          </button>

          <Icon
              v-else
              :class="`${disabled ? 'text-zinc-400' : 'text-zinc-500'} pointer-events-none text-lg`"
              name="expand_more"
          />
        </ComboboxButton>

        <ComboboxOptions
            :static="isStatic"
            :class="`${isStatic ? 'static' : 'absolute z-10'} shadow-card mt-1 max-h-60 w-max min-w-full space-y-1 overflow-y-auto rounded-md border border-gray-200 bg-white p-1 text-sm leading-normal text-zinc-800 focus:outline-none`"
            @scroll.passive="handleScroll"
        >
          <div
              v-if="isLoadingQuery"
              class="flex cursor-default select-none items-center gap-2 px-2 py-2.5"
          >
            <Icon class="animate-spin text-lg" name="progress_activity" />
            <span class="block leading-4">
              {{ `${__('Loading')}...` }}
            </span>
          </div>

          <div
              v-else-if="query.length && query.length < 3"
              class="cursor-default select-none px-2 py-2.5"
          >
            <span class="block leading-4">
              {{ __('Please input at least 3 characters') }}
            </span>
          </div>

          <template v-else-if="!options.length">
            <ComboboxOption
                v-if="isNewOptionsAllowed"
                v-slot="{ selected, active }"
                :value="customOption"
                as="template"
            >
              <li
                  class="flex cursor-default select-none items-center justify-between gap-2 rounded-md px-2 py-2.5 leading-4"
                  :class="{
                  'bg-secondary/10 shadow-sm': selected,
                  'bg-gray-50': active && !selected
                }"
              >
                <span class="truncate">
                  {{ __('This is a new option. Click here to add it.') }}
                </span>

                <Icon v-if="selected" class="text-zinc-800" name="check" />
              </li>
            </ComboboxOption>

            <div v-else class="cursor-default select-none px-2 py-2.5">
              <span class="block leading-4">{{ __('No results found') }}</span>
            </div>
          </template>

          <template v-else>
            <ComboboxOption
                v-for="option in options"
                :key="option.id"
                v-slot="{ selected, active }"
                :value="option"
                as="template"
            >
              <li
                  class="flex cursor-default select-none items-center justify-between gap-2 rounded-md px-2 py-2.5 leading-4"
                  :class="{
                  'bg-secondary/10 shadow-sm': selected,
                  'bg-gray-50': active && !selected
                }"
              >
                <span class="truncate">
                  {{ option.name }}
                </span>

                <Icon v-if="selected" class="text-zinc-800" name="check" />
              </li>
            </ComboboxOption>
          </template>

          <div
              v-if="isLoadingFetch"
              class="flex cursor-default select-none items-center gap-2 px-2 py-2.5"
          >
            <Icon class="animate-spin text-lg" name="progress_activity" />
            <span class="block leading-4">
              {{ `${__('Loading more items')}...` }}
            </span>
          </div>
        </ComboboxOptions>
      </div>
    </Combobox>

    <div
        v-if="!isStatic && isMultipleSelection && value.length"
        class="mt-2 flex flex-wrap gap-1"
    >
      <button
          v-for="item in value"
          :key="item.id"
          :aria-label="__('Clear')"
          type="button"
          tabindex="0"
          class="flex items-center gap-1 rounded-lg border border-secondary px-2 py-1 text-sm text-zinc-800 transition-colors hover:bg-secondary/5"
          @click="handleUpdateModelValue(item)"
      >
        <span>{{ item.name }}</span>

        <Icon class="text-zinc-800" name="close" />
      </button>
    </div>

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
          v-for="(error, index) in errors"
          :key="index"
          class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, useSlots } from 'vue';
import { useToast } from 'vue-toastification';
import axios from 'axios';
import { __ } from '@/helpers';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue';
import { Icon } from '@/components/block/icon';

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  value: {
    type: [Array, Object, null],
    required: true
  },
  errors: {
    type: Array,
    default: () => []
  },
  isStatic: {
    type: Boolean,
    default: false
  },
  isNewOptionsAllowed: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(['update:value', 'update:errors']);

const slots = useSlots();
const toast = useToast();

const isMultipleSelection = computed(() => {
  return Array.isArray(props.value);
});

const query = ref('');
const options = ref([]);
const items = ref([]);
const pagination = ref({ page: 1, total: 0 });
const isLoadingQuery = ref(false);
const isLoadingFetch = ref(false);

let debounceTimeout;

const customOption = computed(() => ({
  id: 'custom-option',
  name: query.value,
}));

function compareOptions(a, b) {
  return a?.id === b?.id && a?.name === b?.name;
}

function handleClickOutside() {
  query.value = '';
  options.value = items.value;
}

function handleUpdateModelValue(value) {
  let callbackRezolver;
  if (Array.isArray(value) && value.length > 0 && value[value.length - 1].id === 'custom-option') {

    callbackRezolver = (newOption) => {
      const updatedValue = [
        ...value.slice(0, value.length - 1),
        newOption,
      ];
      emits('update:value', updatedValue);
    };
  }

  if (isMultipleSelection.value && !Array.isArray(value)) {
    if (callbackRezolver) {
      emits('update:value', props.value.filter((item) => item.id !== value.id), callbackRezolver);
    } else {
      emits('update:value', props.value.filter((item) => item.id !== value.id));
    }
  } else {
    if (callbackRezolver) {
      emits('update:value', value, callbackRezolver);
    } else {
      emits('update:value', value);
    }
  }

  emits('update:errors', []);
}

function handleCloseCombobox() {
  const element = document.getElementById(
      `combobox-button-input-skills-${props.id}`
  );

  if (element) {
    element.click();
  }

  handleClickOutside();
}

async function handleInput(event) {
  query.value = event.target.value;

  clearTimeout(debounceTimeout);

  if (query.value.length < 3) {
    options.value = items.value;
    isLoadingQuery.value = false;
    return;
  }

  isLoadingQuery.value = true;

  debounceTimeout = setTimeout(async () => {
    try {
      const response = await axios.get(
          route('skills.find_skills', {
            q: query.value
          })
      );

      options.value = response.data.items;
    } catch {
      toast.error(
          __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingQuery.value = false;
  }, 500);
}

async function handleScroll(event) {
  const { scrollTop, scrollHeight, clientHeight } = event.target;

  if (
      !query.value &&
      !isLoadingFetch.value &&
      pagination.value.page < pagination.value.total &&
      scrollTop + clientHeight >= scrollHeight - 100
  ) {
    pagination.value.page++;
    await fetchItems();
  }
}

async function fetchItems() {
  isLoadingFetch.value = true;

  try {
    const response = await axios.get(
        route('skills.find_skills', {
          page: pagination.value.page
        })
    );

    items.value = [...items.value, ...response.data.items];
    options.value = items.value;
    pagination.value = response.data.pagination;
  } catch {
    toast.error(
        __('An unexpected error has occured. Please try again later.')
    );
  }

  isLoadingFetch.value = false;
}

onMounted(async () => {
  await fetchItems();
});
</script>
