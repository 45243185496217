<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Achievements')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="award_star" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-element space-y-12 rounded-2xl bg-white p-6 md:p-8">
      <div
        v-for="group in isListExpanded ? groups : groups.slice(0, 3)"
        :key="group.type.id"
        class="space-y-6"
      >
        <div class="flex items-center gap-2">
          <Icon class="text-2xl text-primary" :name="group.icon" is-filled />

          <h3 class="text-2xl font-medium text-zinc-800">
            {{ group.type.name }}
          </h3>
        </div>

        <Achievement
          v-for="achievement in group.achievements"
          :key="achievement.id"
          :achievement="achievement"
        />
      </div>

      <Button
        v-if="groups.length > 3"
        class="mx-auto"
        variant="outline"
        @click="handleExpandListButtonClick"
      >
        {{ __(isListExpanded ? 'See less' : 'See more') }}
      </Button>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Achievement } from './components/achievement';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    }
  });

  const groups = computed(() => {
    const icons = {
      1: 'book',
      2: 'redeem',
      3: 'local_activity',
      4: 'military_tech',
      5: 'developer_guide',
      6: 'check_box'
    };

    let groups = {};

    props.data.forEach((achievement) => {
      const typeId = achievement.type.id;

      if (!groups[typeId]) {
        groups[typeId] = {
          type: achievement.type,
          achievements: [],
          icon: icons[typeId]
        };
      }

      groups[typeId].achievements.push({
        id: achievement.id,
        name: achievement.name,
        organization: achievement.organization,
        link: achievement.link,
        description: achievement.description,
        current: achievement.current,
        type: achievement.type,
        start_date: achievement.start_date,
        end_date: achievement.end_date
      });
    });

    let result = [];

    for (let typeId in groups) {
      result.push(groups[typeId]);
    }

    return result;
  });

  const isListExpanded = ref(false);

  function handleExpandListButtonClick() {
    isListExpanded.value = !isListExpanded.value;
  }
</script>
