<template>
  <div class="space-y-8">
    <div
      v-if="!user.is_recruiter"
      class="flex items-start gap-2 rounded-lg bg-secondary/5 p-4"
    >
      <Icon class="mt-0.5 text-xl text-zinc-800" name="info" />

      <div>
        <span class="block font-medium text-zinc-800">
          {{ __('Event Invite Limit') }}
        </span>
        <span class="block text-sm text-zinc-600">
          {{ __('You can invite up to 20 friends per event.') }}
        </span>
      </div>
    </div>

    <InviteForm
      v-if="renderInviteForm"
      :event="event"
      @submit="handleInviteFormSubmit"
    />

    <div class="space-y-4">
      <div class="shadow-element overflow-auto rounded-md">
        <table class="w-max min-w-full">
          <thead>
            <tr>
              <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                {{ __('Name') }}
              </th>
              <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                {{ __('Invitation sent on') }}
              </th>
              <th v-if="canInvite" class="px-8 py-4">
                <div class="flex items-center gap-1">
                  <span class="font-medium leading-normal text-zinc-600">
                    {{ __('Action') }}
                  </span>

                  <Popover position="right">
                    <template #trigger>
                      <Button variant="icon" tabindex="-1">
                        <Icon class="text-xl" name="info" />
                      </Button>
                    </template>
                    <template #content>
                      <div class="p-4">
                        <span class="block text-sm text-zinc-800">
                          {{
                            __(
                              'Invites can be sent to each user once every 7 days.'
                            )
                          }}
                        </span>
                      </div>
                    </template>
                  </Popover>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading" class="border-t">
              <td :colSpan="2" class="px-8 py-4">
                <span class="text-sm text-zinc-600">
                  {{ __('Loading...') }}
                </span>
              </td>
            </tr>

            <tr v-else-if="!attendees.length" class="border-t">
              <td :colSpan="2" class="px-8 py-4">
                <span class="text-sm text-zinc-600">
                  {{ __('No pending invitations') }}
                </span>
              </td>
            </tr>

            <template v-else>
              <Attendee
                v-for="(attendee, index) in attendees"
                :key="attendee"
                v-model:attendee="attendees[index]"
                :event="event"
                :user="user"
              />
            </template>
          </tbody>
        </table>
      </div>

      <div
        v-if="!isPreviousButtonDisabled || !isNextButtonDisabled"
        class="flex justify-center gap-2"
      >
        <button
          class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
          :disabled="isPreviousButtonDisabled"
          :aria-label="__('Previous')"
          @click="handlePreviousButtonClick"
        >
          <Icon class="text-xl text-primary" name="arrow_back" />
        </button>

        <button
          class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
          :disabled="isNextButtonDisabled"
          :aria-label="__('Next')"
          @click="handleNextButtonClick"
        >
          <Icon class="text-xl text-primary" name="arrow_forward" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Popover } from '@/components/block/popover';
  import { Attendee } from './components/attendee';
  import { InviteForm } from './components/invite-form';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const renderInviteForm = computed(() => {
    return (
      props.user.conditions.can_invite &&
      !attendeeLimitReached.value &&
      !registrationDeadlinePassed.value
    );
  });

  const canEdit = computed(() => {
    return props.user.conditions.can_edit;
  });

  const canInvite = computed(() => {
    return (
      canEdit.value &&
      !attendeeLimitReached.value &&
      !registrationDeadlinePassed.value
    );
  });

  const attendeeLimitReached = computed(() => {
    const attendees = props.event.details.attendees;
    const attendeeLimit = props.event.details.attendee_limit;

    if (!attendeeLimit) {
      return false;
    }

    return attendees === attendeeLimit;
  });

  const registrationDeadlinePassed = computed(() => {
    const rsvp = props.event.details.rsvp;

    if (!rsvp) {
      return false;
    }

    return new Date() > new Date(rsvp);
  });

  const isPreviousButtonDisabled = computed(() => {
    return page.value <= 1 || isLoading.value;
  });

  const isNextButtonDisabled = computed(() => {
    return page.value >= totalPages.value || isLoading.value;
  });

  const attendees = ref([]);
  const isLoading = ref(false);
  const page = ref(1);
  const totalPages = ref(0);

  async function handleInviteFormSubmit() {
    if (page.value !== 1) {
      page.value = 1;
    }

    await fetchAttendees(page.value);
  }

  async function handlePreviousButtonClick() {
    page.value--;

    await fetchAttendees(page.value);
  }

  async function handleNextButtonClick() {
    page.value++;

    await fetchAttendees(page.value);
  }

  async function fetchAttendees(page) {
    isLoading.value = true;

    try {
      const response = await axios.get(
        route('redesign.events.guests', {
          slug: props.event.details.slug
        }),
        { params: { page } }
      );

      attendees.value = response.data.members;
      totalPages.value = response.data.pagination.total;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }

  onMounted(async () => {
    await fetchAttendees(page.value);
  });
</script>
