<template>
  <div class="mt-8">
    <!-- invitations -->
    <div>
      <h2 class="text-2xl text-[#212429] font-medium mb-4">
        {{ __("Invitations") }}
        <span class="text-white bg-primary rounded-full text-xs px-2 py-1">{{
          invitations.length
        }}</span>
      </h2>

      <div>
        <div v-if="invitations.length != 0" class="grid md:grid-cols-3 gap-4">
          <div
            v-for="(invitation, index) in invitations"
            :key="invitation.id"
            class="rounded-app shadow-md relative flex flex-col justify-between bg-white"
          >
            <div>
              <div
                class="bg-white rounded-t-app p-4 cursor-pointer relative"
                @click="openModal(invitation.job)"
                v-if="!invitations[index].flipped"
              >
                <span
                  class="absolute bottom-2 right-0 text-xs bg-secondary text-white pl-4 pr-2 py-1 rounded-l-app"
                  v-if="invitation.new"
                  >{{ __("NEW") }}</span
                >
                <div class="flex justify-between">
                  <div
                    class="flex gap-2 items-center"
                    v-if="!config_general_single_company"
                  >
                    <img
                      :src="invitation.job.company.logo"
                      alt="Company logo"
                      class="w-12 h-12 rounded-full"
                    />
                    <div>
                      <h3 class="text-sm text-[#212429]">
                        {{ invitation.job.company.name }}
                      </h3>
                      <p class="text-xs text-[#58585E]">
                        {{ invitation.job.company.domain }}
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <SingleCompanyIcon />
                  </div>
                </div>

                <div>
                  <h3
                    class="text-lg my-2"
                    :class="
                      !invitation.job.active ||
                      invitation.expired ||
                      invitation.rejected
                        ? ' text-zinc-300'
                        : 'text-[#52525B]'
                    "
                  >
                    {{ invitation.job.title }}
                  </h3>
                </div>
              </div>

              <div v-else class="p-4">
                <Textarea
                  v-model="reject_reason[invitation.id]"
                  :value="reject_reason[invitation.id]"
                  placeholder="Could you please tell us why you declined the offer? Your feedback will help in refining our screening process for your profile."
                  label="You rejected the invitation succesfully."
                />
              </div>
            </div>

            <div class="p-4 bg-zinc-50 rounded-b-app">
              <div v-if="!invitations[index].flipped">
                <div
                  v-if="
                    invitation.job.active &&
                    !invitation.expired &&
                    !invitation.rejected
                  "
                >
                  <div class="grid grid-cols-3 gap-2">
                    <div class="flex items-center">
                      <div
                        class="!text-xs flex gap-2 items-center text-[#52525B]"
                        v-if="invitation.time_left"
                      >
                        <Icon name="clock_loader_10" class="!text-sm" />
                        <span class=""> {{ invitation.time_left }}</span>
                      </div>
                    </div>
                    <div>
                      <Button
                        content="Accept"
                        icon="check_circle"
                        @click="() => handleAccept(invitation)"
                      />
                    </div>
                    <div>
                      <Button
                        content="Reject"
                        icon="cancel"
                        class="text-[#52525B]"
                        @click="() => handleReject(invitation)"
                      />
                    </div>
                  </div>
                </div>
                <div class="p-4 bg-gray-200 rounded-b-app" v-else>
                  <div
                    class="text-xs text-[#52525B] flex gap-2 items-center"
                    v-if="invitation.status"
                  >
                    <Icon name="description" class="!text-sm" />
                    <span class=""
                      ><b class="font-bold">{{ __("Status") }}:</b>
                      {{ invitation.status }}</span
                    >
                  </div>
                  <!-- <div
                    class="text-xs text-[#52525B]"
                    v-else-if="invitation.rejected"
                  >
                    {{ __("You rejected this invitation") }}
                  </div>
                  <div class="text-xs text-[#52525B]" v-else>
                    {{ __("This job is inactive at the moment") }}
                  </div> -->
                </div>
              </div>

              <div v-else>
                <div class="text-right">
                  <Button
                    content="Send"
                    icon="send"
                    @click="() => handleRejectReason(invitation)"
                  />
                  <Button
                    content="Skip"
                    icon="cancel"
                    class="text-[#52525B]"
                    @click="() => handleSkipReason(invitation)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <p class="text-[#212429] text-base">
            {{ __("You have no invitation yet.") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import {
  Button,
  Icon,
  SingleCompanyIcon,
  Textarea,
} from "../../../../../components/global";
import { __ } from "../../../../../helpers";
import { useToast } from "vue-toastification";
import axios from "axios";

const props = defineProps({
  matches: {
    type: Array,
    required: true,
  },
  invitations: {
    type: Array,
    required: true,
  },
  config_general_single_company: {
    type: Number,
    required: true,
  },
});

console.log(props.invitations);

const invitations = reactive(props.invitations);

// add "flipped" property to each invitation to store the state of the card
invitations.forEach((invitation) => {
  invitation.flipped = false;
});
const matches = reactive(props.matches);

const toast = useToast();

const handleAccept = (invitation) => {
  console.log("Accept invitation", invitation);

  //   pop from invitations and add to matches
  axios
    .post(
      route("redesign.jobs.accept-invitation", {
        company: invitation.job.company.slug,
        job: invitation.job.slug,
      })
    )
    .then((response) => {
      console.log(response.data);
      const index = invitations.findIndex((i) => i.job.id === invitation);
      if (index !== -1) {
        matches.push(invitations[index]);
        invitations.splice(index, 1);
      }

      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error(error.response.data.message || __('An error occurred.'));
      console.log(error);
      // loadingApply.value = false;
    });
};

const reject_reason = ref({});

// for each invitation, add a reject_reason property
invitations.forEach((invitation) => {
  console.log(invitation);
  reject_reason.value[invitation.id] = "";
});

const handleReject = (invitation) => {
  console.log("Reject invitation", invitation);

  // flip the card
  invitation.flipped = true;

  axios
    .post(
      route("redesign.jobs.reject-invitation", {
        company: invitation.job.company.slug,
        job: invitation.job.slug,
      })
    )
    .then((response) => {
      console.log(response.data);

      // const index = invitations.findIndex((i) => i.job.id === invitation);

      // if (index !== -1) {
      //   invitations.splice(index, 1);
      // }

      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error(error.response.data.message || __('An error occurred.'));
      console.log(error);
      // loadingApply.value = false;
    });
};

// TODO: de facut asta
const handleRejectReason = (invitation) => {
  console.log("Reject invitation", invitation);

  // check if the reject_reason is not empty
  if (reject_reason.value[invitation.id] === "") {
    toast.error(__("Please provide a reason for rejecting this invitation."));
    return;
  }

  axios
    .post(
      route("redesign.invitations.reject-reason", {
        invitation: invitation.id,
      }),
      {
        reject_reason: reject_reason.value[invitation.id],
      }
    )
    .then((response) => {
      console.log(response.data);

      const index = invitations.findIndex((i) => i.id === invitation.id);

      if (index !== -1) {
        invitations.splice(index, 1);
      }

      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error(error.response.data || __("An error occurred."));
      console.log(error);
      // loadingApply.value = false;
    });
};

const handleSkipReason = (invitation) => {
  const index = invitations.findIndex((i) => i.id === invitation.id);

  if (index !== -1) {
    invitations.splice(index, 1);
  }
};

const emit = defineEmits(["openModal"]);

const openModal = (job) => {
  emit("openModal", job);
};
</script>
