<template>
  <div>
    <label
      :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
      for="phone_number"
    >
      {{ `${__('Phone number')}${required ? '*' : ''}` }}
    </label>

    <div
      :class="`${errors.length ? 'ring-error' : 'ring-gray-200'} flex items-center rounded-md bg-white shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary`"
    >
      <Combobox
        id="phone_prefix"
        by="id"
        :model-value="valuePhonePrefix"
        @update:model-value="handlePhonePrefixUpdate"
      >
        <div class="relative">
          <ComboboxButton class="flex items-center pr-2.5">
            <ComboboxInput
              class="w-24 truncate border-none bg-transparent py-2.5 pl-3.5 pr-2.5 text-zinc-800 placeholder:text-zinc-500 focus:ring-0"
              :placeholder="__('Code')"
              :display-value="(value) => value?.name"
              @input="handlePhonePrefixQuery"
            />

            <button
              v-if="valuePhonePrefix"
              class="-m-1 flex p-1"
              @click="handlePhonePrefixUpdate(null)"
            >
              <Icon class="text-lg text-zinc-400" name="close" />
            </button>

            <Icon
              v-else
              class="pointer-events-none text-lg text-zinc-500"
              name="expand_more"
            />
          </ComboboxButton>

          <TransitionRoot
            leave-from="opacity-100"
            leave-to="opacity-0"
            @after-leave="handlePhonePrefixAfterLeave"
          >
            <ComboboxOptions
              class="shadow-card absolute z-10 mt-1 max-h-60 w-max min-w-full overflow-y-auto rounded-md border border-gray-200 bg-white p-1 text-sm leading-normal text-zinc-800 focus:outline-none"
            >
              <template v-if="filteredOptions.length">
                <ComboboxOption
                  v-for="option in filteredOptions"
                  :key="option.id"
                  v-slot="{ selected, active }"
                  :value="option"
                  as="template"
                >
                  <li
                    class="flex cursor-default select-none items-center justify-between gap-2 rounded-md px-3 py-1.5"
                    :class="{
                      'bg-gray-50': active
                    }"
                  >
                    <span
                      class="block truncate"
                      :class="{
                        'font-medium': selected,
                        'font-normal': !selected
                      }"
                    >
                      {{ option.name }}
                    </span>

                    <Icon v-if="selected" class="text-primary" name="check" />
                  </li>
                </ComboboxOption>
              </template>

              <div v-else class="cursor-default select-none px-3 py-1.5">
                {{ __('No results found') }}
              </div>
            </ComboboxOptions>
          </TransitionRoot>
        </div>
      </Combobox>

      <div class="h-6 w-[1px] bg-gray-200" />

      <input
        id="phone_number"
        type="text"
        :placeholder="__('Phone number')"
        :value="valuePhoneNumber"
        class="w-full truncate border-none bg-transparent px-3.5 py-2.5 text-zinc-800 placeholder:text-zinc-500 focus:ring-0"
        @input="handlePhoneNumberInput"
      />
    </div>

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    TransitionRoot
  } from '@headlessui/vue';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    valuePhonePrefix: {
      type: [Object, null],
      required: true
    },
    valuePhoneNumber: {
      type: String,
      default: ''
    },
    errorsPhonePrefix: {
      type: Array,
      default: () => []
    },
    errorsPhoneNumber: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits([
    'update:value-phone-prefix',
    'update:value-phone-number',
    'update:errors-phone-prefix',
    'update:errors-phone-number'
  ]);

  const filteredOptions = computed(() => {
    return query.value
      ? props.options.filter((option) =>
          option.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.value.toLowerCase().replace(/\s+/g, ''))
        )
      : props.options;
  });
  const errors = computed(() => {
    return [
      ...new Set([...props.errorsPhonePrefix, ...props.errorsPhoneNumber])
    ];
  });

  const query = ref('');

  function handlePhonePrefixQuery(event) {
    query.value = event.target.value;
  }

  function handlePhonePrefixAfterLeave() {
    query.value = '';
  }

  function handlePhonePrefixUpdate(value) {
    emits('update:value-phone-prefix', value);
    emits('update:errors-phone-prefix', []);
  }

  function handlePhoneNumberInput(event) {
    emits('update:value-phone-number', event.target.value);
    emits('update:errors-phone-number', []);
  }
</script>
