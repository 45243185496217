<template>
  <div>
    <div
      :class="`${isCompleted ? 'relative items-center justify-center' : 'items-start justify-start'} mx-auto flex min-h-[calc(100dvh-144px)] max-w-4xl md:items-center`"
    >
      <div
        v-if="isLoading"
        class="md:shadow-card h-fit w-full rounded-lg bg-white px-6 py-8 md:rounded-2xl md:p-14"
      >
        <div class="flex items-center gap-1.5 text-zinc-500">
          <Icon class="animate-spin text-xl" name="progress_activity" />
          <span>{{ __('Loading...') }}</span>
        </div>
      </div>

      <template v-else-if="languages.length && paths.length">
        <Congrats v-if="isCompleted" />
        <AgeLimit
          v-else-if="conditions.is_under_age"
          :conditions="conditions"
        />
        <Language
          v-else-if="!selectedLanguage && languages.length > 1"
          v-model:selected-language="selectedLanguage"
          :languages="languages"
        />
        <Path
          v-else-if="!selectedPath && paths.length > 1"
          v-model:selected-language="selectedLanguage"
          v-model:selected-path="selectedPath"
          :paths="paths"
        />
        <ExplorerPath
          v-else-if="selectedPath.slug === 'explorer'"
          v-model:selected-language="selectedLanguage"
          v-model:current-step="currentStep"
          v-model:conditions="conditions"
          :languages="languages"
          @update:is-completed="handleUpdateIsCompleted"
        />
        <JobPath
          v-else-if="selectedPath.slug === 'find-job'"
          v-model:selected-language="selectedLanguage"
          v-model:current-step="currentStep"
          :languages="languages"
          @update:is-completed="handleUpdateIsCompleted"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';
  import { AgeLimit } from './components/age-limit';
  import { Congrats } from './components/congrats';
  import { ExplorerPath } from './components/explorer-path';
  import { JobPath } from './components/job-path';
  import { Language } from './components/language';
  import { Path } from './components/path';

  const toast = useToast();

  const isLoading = computed(() => {
    return (
      isLoadingLanguages.value ||
      isLoadingPaths.value ||
      isLoadingProgress.value ||
      isLoadingLanguage.value ||
      isLoadingPath.value
    );
  });

  const languages = ref([]);
  const paths = ref([]);
  const selectedLanguage = ref(null);
  const selectedPath = ref(null);
  const currentStep = ref(null);
  const conditions = ref({ is_under_age: false });
  const isLoadingLanguages = ref(false);
  const isLoadingPaths = ref(false);
  const isLoadingProgress = ref(false);
  const isLoadingLanguage = ref(false);
  const isLoadingPath = ref(false);
  const isCompleted = ref(false);

  function handleUpdateIsCompleted(value) {
    isCompleted.value = value;

    setTimeout(() => {
      window.location.href = route('redesign.my-cv.show');
    }, 4000);
  }

  async function fetchLanguages() {
    isLoadingLanguages.value = true;

    try {
      const response = await axios.get(route('onboarding.languages'));
      languages.value = response.data;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingLanguages.value = false;
  }

  async function fetchPaths() {
    isLoadingPaths.value = true;

    try {
      const response = await axios.get(route('onboarding.paths'));
      paths.value = response.data.paths;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingPaths.value = false;
  }

  async function fetchProgress() {
    isLoadingProgress.value = true;

    try {
      const response = await axios.get(route('onboarding.progress'));
      selectedLanguage.value = response.data.selected_language;
      selectedPath.value = response.data.selected_path;
      currentStep.value = response.data.current_step;
      conditions.value = response.data.conditions;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingProgress.value = false;
  }

  async function setLanguage() {
    isLoadingLanguage.value = true;

    try {
      await axios.post(route('onboarding.languages'), {
        language: selectedLanguage.value
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingLanguage.value = false;
  }

  async function setPath() {
    isLoadingPath.value = true;

    try {
      await axios.post(route('onboarding.paths'), {
        path: selectedPath.value
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingPath.value = false;
  }

  onMounted(async () => {
    await Promise.all([fetchLanguages(), fetchPaths(), fetchProgress()]);

    if (languages.value.length === 1) {
      selectedLanguage.value = languages.value[0];
      await setLanguage();
    }

    if (paths.value.length === 1) {
      selectedPath.value = paths.value[0];
      await setPath();
    }

    if (selectedLanguage.value) {
      sessionStorage.setItem(
        'onboarding_selected_language',
        JSON.stringify(selectedLanguage.value)
      );
    }
  });
</script>
