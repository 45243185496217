<template>
  <a
    :href="url"
    class="shadow-card flex flex-wrap gap-x-8 gap-y-4 rounded-lg p-6 sm:flex-nowrap"
  >
    <img
      :src="preview"
      :alt="__('Preview photo')"
      class="w-full rounded sm:h-56 sm:w-auto"
    />

    <div class="space-y-6">
      <div class="flex flex-wrap gap-2">
        <span
          class="w-fit rounded-lg border border-secondary px-3 py-1.5 text-sm leading-none text-zinc-800"
        >
          {{ type }}
        </span>
        <span
          v-if="reward"
          class="w-fit rounded-lg border border-secondary px-3 py-1.5 text-sm leading-none text-zinc-800"
        >
          {{ reward }}
        </span>
        <span
          v-if="group"
          class="w-fit rounded-lg border border-secondary px-3 py-1.5 text-sm leading-none text-zinc-800"
        >
          {{ group }}
        </span>
      </div>

      <div class="space-y-4">
        <div class="divide-x-2 divide-primary">
          <span class="font-medium text-zinc-800">
            {{ dateTime }}
          </span>

          <span v-if="isOnSite" class="ml-3 pl-3 font-medium text-zinc-800">
            {{ location }}
          </span>
        </div>

        <span class="block text-2xl font-medium text-zinc-800">{{ name }}</span>
      </div>

      <div v-if="isFutureEvent" class="space-y-4">
        <div v-if="registrationDeadlinePassed" class="flex items-center gap-2">
          <Icon name="event_busy" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('Registration deadline passed') }}
          </span>
        </div>

        <div v-else-if="attendeeLimitReached" class="flex items-center gap-2">
          <Icon name="event_busy" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('The event has reached the maximum limit') }}
          </span>
        </div>

        <div class="flex items-center gap-2">
          <Button
            v-if="canEdit"
            :href="editUrl"
            variant="ghost"
            class="flex items-center gap-1.5"
          >
            <Icon name="edit" class="text-lg" />
            <span>{{ __('Edit event') }}</span>
          </Button>

          <Button
            v-if="canCancel"
            variant="ghost"
            @click.prevent="handleCancelButtonClick"
          >
            {{ __('Cancel event') }}
          </Button>
        </div>
      </div>

      <div v-else-if="isPresentEvent" class="space-y-4">
        <div class="flex items-center gap-2">
          <Icon name="stream" is-filled class="text-2xl text-primary" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('The event is happening now') }}
          </span>
        </div>

        <div v-if="registrationDeadlinePassed" class="flex items-center gap-2">
          <Icon name="event_busy" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('Registration deadline passed') }}
          </span>
        </div>

        <div v-else-if="attendeeLimitReached" class="flex items-center gap-2">
          <Icon name="event_busy" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('The event has reached the maximum limit') }}
          </span>
        </div>

        <Button v-else variant="ghost" class="flex items-center gap-1.5">
          <Icon name="open_in_new" class="text-lg" />
          <span>{{ __('View details') }}</span>
        </Button>
      </div>

      <div v-else-if="isPastEvent" class="space-y-4">
        <div class="flex items-center gap-2">
          <Icon name="local_activity" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('This event has passed') }}
          </span>
        </div>

        <Button variant="ghost" class="flex items-center gap-1.5">
          <Icon name="open_in_new" class="text-lg" />
          <span>{{ __('View details') }}</span>
        </Button>
      </div>

      <div v-else-if="isCanceledEvent" class="space-y-4">
        <div class="flex items-center gap-2">
          <Icon name="local_activity" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('This event has been canceled') }}
          </span>
        </div>

        <div class="flex items-center gap-2">
          <Button variant="ghost" class="flex items-center gap-1.5">
            <Icon name="open_in_new" class="text-lg" />
            <span>{{ __('View details') }}</span>
          </Button>

          <Button
            v-if="canDelete"
            variant="ghost"
            class="flex items-center gap-1.5"
            @click.prevent="handleDeleteButtonClick"
          >
            <Icon name="delete" class="text-lg" />
            <span>{{ __('Delete event') }}</span>
          </Button>
        </div>
      </div>
    </div>

    <EventCancelModal
      v-if="isCancelModalOpen"
      :event="event"
      @cancel="handleCancelModalCancelButtonClick"
      @continue="handleCancelModalContinueButtonClick"
    />

    <EventDeleteModal
      v-if="isDeleteModalOpen"
      :event="event"
      @cancel="handleDeleteModalCancelButtonClick"
      @continue="handleDeleteModalContinueButtonClick"
    />
  </a>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { __ } from '@/helpers';
  import { formatDate, formatTime } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { EventCancelModal } from '../event-cancel-modal';
  import { EventDeleteModal } from '../event-delete-modal';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    },
    events: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:events']);

  const url = computed(() => {
    return route('redesign.company.events.show', { id: event.value.id });
  });

  const editUrl = computed(() => {
    return route('redesign.company.events.edit', { id: event.value.id });
  });

  const preview = computed(() => {
    return `/uploads/${event.value.details.photo}`;
  });

  const type = computed(() => {
    return event.value.details.type.name;
  });

  const group = computed(() => {
    return event.value.group?.id
      ? `${__('Group')}: ${event.value.group.name}`
      : '';
  });
  
  const reward = computed(() => {
    return event.value.gamification?.points.id
      ? `${__('Reward')}: ${event.value.gamification.points.name} ${__('points')}`
      : '';
  });

  const dateTime = computed(() => {
    const startDate = formatDate(event.value.details.start_date_time);
    const startTime = formatTime(event.value.details.start_date_time);
    const endDate = formatDate(event.value.details.end_date_time);
    const endTime = formatTime(event.value.details.end_date_time);

    if (startDate !== endDate) {
      return `${startDate} | ${startTime} - ${endDate} | ${endTime}`;
    }

    return `${startDate} | ${startTime} - ${endTime}`;
  });

  const isOnSite = computed(() => {
    return event.value.details.type.id === 2;
  });

  const location = computed(() => {
    return event.value.details.location.name;
  });

  const name = computed(() => {
    return event.value.details.name;
  });

  const attendeeLimitReached = computed(() => {
    const attendees = event.value.details.attendees;
    const attendeeLimit = event.value.details.attendee_limit;

    if (!attendeeLimit) {
      return false;
    }

    return attendees === attendeeLimit;
  });

  const registrationDeadlinePassed = computed(() => {
    const rsvp = event.value.details.rsvp;

    if (!rsvp) {
      return false;
    }

    return new Date() > new Date(rsvp);
  });

  const isFutureEvent = computed(() => {
    return event.value.status === 'FUTURE';
  });

  const isPresentEvent = computed(() => {
    return event.value.status === 'PRESENT';
  });

  const isPastEvent = computed(() => {
    return event.value.status === 'PAST';
  });

  const isCanceledEvent = computed(() => {
    return event.value.status === 'CANCELED';
  });

  const canEdit = computed(() => {
    return event.value.conditions.can_edit;
  });

  const canCancel = computed(() => {
    return event.value.conditions.can_cancel;
  });

  const canDelete = computed(() => {
    return event.value.conditions.can_delete;
  });

  const event = ref(JSON.parse(JSON.stringify(props.event)));
  const isCancelModalOpen = ref(false);
  const isDeleteModalOpen = ref(false);

  function handleCancelButtonClick() {
    isCancelModalOpen.value = true;
  }

  function handleCancelModalCancelButtonClick() {
    isCancelModalOpen.value = false;
  }

  function handleCancelModalContinueButtonClick() {
    emits(
      'update:events',
      props.events.filter((item) => item.id !== event.value.id)
    );
    isCancelModalOpen.value = false;
  }

  function handleDeleteButtonClick() {
    isDeleteModalOpen.value = true;
  }

  function handleDeleteModalCancelButtonClick() {
    isDeleteModalOpen.value = false;
  }

  function handleDeleteModalContinueButtonClick() {
    emits(
      'update:events',
      props.events.filter((item) => item.id !== event.value.id)
    );
    isDeleteModalOpen.value = false;
  }
</script>
