<template>
  <div class="flex flex-col gap-8">
    <SectionHeader :title="__('Notes')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="chat" />
      </template>
    </SectionHeader>

    <div
      class="shadow-element flex max-h-[25rem] flex-grow flex-col gap-2 rounded-2xl bg-white p-6 md:p-8"
    >
      <ul
        v-if="notes.length"
        ref="noteList"
        class="flex-grow space-y-2 overflow-y-auto"
      >
        <li v-for="note in notes" :key="note.id" class="flex gap-2.5">
          <div class="flex shrink-0 flex-col items-center gap-2">
            <img
              :src="note.avatar_url"
              :alt="__('Recruiter avatar')"
              class="h-8 w-8 shrink-0 rounded-full ring-1 ring-zinc-100"
            />

            <span class="block h-full w-[1.5px] bg-zinc-300" />
          </div>
          <div class="pb-12">
            <span class="block text-xs font-medium text-zinc-600">
              {{ note.recruiter_name }}
            </span>

            <span class="block text-xs text-zinc-600">
              {{ `${__('commented')} ${note.created_at}` }}
            </span>

            <p class="mt-1 text-sm leading-tight text-zinc-600">
              {{ note.text }}
            </p>
          </div>
        </li>
      </ul>

      <span v-else class="mb-6 text-zinc-600">
        {{ __('No notes found') }}
      </span>

      <div class="flex gap-2.5">
        <img
          :src="recruiterAvatarUrl"
          :alt="__('Recruiter avatar')"
          class="h-8 w-8 shrink-0 rounded-full ring-1 ring-zinc-100"
        />

        <form
          class="relative flex-grow overflow-hidden rounded-md pb-14 ring-1 ring-zinc-200 focus-within:ring-2 focus-within:ring-secondary"
          @submit.prevent="handleSubmit"
        >
          <textarea
            v-model="comment"
            class="w-full resize-none border-0 px-3 py-2 text-sm font-normal text-zinc-600 placeholder:text-zinc-400 focus:ring-0"
            :placeholder="`${__('Add your note')}...`"
          />
          <Button
            :disabled="isLoading"
            type="submit"
            variant="outline"
            class="absolute bottom-4 right-5 flex items-center gap-1.5 !text-zinc-600 !ring-zinc-200 hover:!bg-zinc-600/5"
          >
            <Icon
              v-if="isLoading"
              class="animate-spin text-lg"
              name="progress_activity"
            />

            <span>{{ __('Send note') }}</span>
          </Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, nextTick, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    recruiter: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const noteList = ref(null);
  const notes = ref(JSON.parse(JSON.stringify(props.recruiter.notes)));
  const comment = ref('');
  const isLoading = ref(false);

  const recruiterAvatarUrl = computed(() => {
    return props.recruiter.metadata.recruiter_avatar_url;
  });

  const userId = computed(() => {
    return props.data.about_me.user.user_id;
  });

  async function handleSubmit() {
    if (!comment.value) {
      return;
    }

    isLoading.value = true;

    try {
      const response = await axios.post(route('redesign.matching.addNote'), {
        text: comment.value,
        user_id: userId.value
      });

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      notes.value = [
        ...notes.value,
        {
          text: comment.value,
          created_at: __('now'),
          recruiter_name: __('You'),
          avatar_url: recruiterAvatarUrl
        }
      ];

      comment.value = '';

      if (noteList.value) {
        await nextTick();

        noteList.value.scrollTo({
          top: noteList.value.scrollHeight,
          behavior: 'smooth'
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }

  onMounted(() => {
    if (!noteList.value) {
      return;
    }

    noteList.value.scrollTo({
      top: noteList.value.scrollHeight,
      behavior: 'smooth'
    });
  });
</script>
