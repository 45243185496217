<template>
    <div class="space-y-8">
        <div class="flex flex-wrap items-center justify-between gap-4 py-4">
            <h1 class="text-4xl font-medium leading-none text-zinc-800">
                {{ __("Your profile") }}
            </h1>
        </div>

        <div class="candidate-profile-page space-y-6">
                <div class="grid md:grid-cols-2 gap-6 about-and-summary">
                    <About :data="user" />
                    <ActivitySummary v-if="news && news.length" :data="news" />
                    <EmptySection v-else
                        :title="__('Activity summary')"
                        :description="__('All caught up!')"
                        :content="__('You don´t have any new notifications yet.')"
                    />
                </div>
                
                <div class="grid md:grid-cols-2 gap-6 interests-and-events">
                    <Interests v-if="checkInterests" :data="interests" :inspiration="inspiration"/>
                    <EmptySection v-else
                        :title="__('Interests')"
                        :description="__('Don`t miss out on potential job and friend opportunities.')"
                        :content="__('Complete your profile by adding your interests to connect with others who share your passions.')"
                        :link_message="__('Complete Profile')"
                    />
                    
                    <Events v-if="events && events.length" :data="events" />
                    <EmptySection v-else
                        :title="__('Events')"
                        :description="__('You have no upcoming events!')"
                        :content="__('Check out what`s happening and see if anything catches your eye.')"
                        :link_message="__('Go to Events')"
                        :link_url="route('redesign.events.viewAll')"
                    />
                </div>

                
                <div class="grid md:grid-cols-2 gap-6 groups-grid" style="height: 350px;">
                    <Groups v-if="groups && groups.length" :data="groups" />
                    <EmptySection v-else
                        :title="__('Groups')"
                        :description="__('Let´s find some groups for you to join!')"
                        :content="__('You might find many groups that align with your interests.')"
                        :link_message="__('Go to Groups')"
                        :link_url="route('redesign.groups.viewAll')"
                    />
                </div>
            <br>
            <br>
        </div>
    </div>
</template>

<script setup>
import { __ } from "@/helpers";
import About from "./components/about/About.vue";
import ActivitySummary from "./components/activity-summary/ActivitySummary.vue";
import Groups from "./components/groups/Groups.vue";
import Events from "./components/events/Events.vue";
import Interests from "./components/interests/Interests.vue";
import EmptySection from "./components/empty-section/EmptySection.vue";

const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
    news: {
        type: Object,
        required: true,
    },
    interests: {
        type: Object,
        required: true,
    },
    inspiration: {
        type: Object,
        required: true,
    },
    events: {
        type: Object,
        required: true,
    },
    groups: {
        type: Object,
        required: true,
    },
});

function checkInterests() {
    return (props.interests && props.interests.length) || (props.inspiration && props.inspiration.length);
}

</script>
