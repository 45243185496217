<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Recruitment process')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="target" />
      </template>
      <template v-if="canEdit" #button>
        <div class="ml-auto">
          <Button
            class="flex items-center gap-1.5"
            variant="ghost"
            @click="
              isEditing ? handleCancelButtonClick() : handleEditButtonClick()
            "
          >
            <Icon class="text-lg" :name="isEditing ? 'close' : 'edit'" />
            <span>{{ __(isEditing ? 'Cancel' : 'Edit') }}</span>
          </Button>
        </div>
      </template>
    </SectionHeader>

    <RecruitmentProcessEdit
      v-if="isEditing"
      :data="data"
      :error-message="errorMessage"
      @save="handleSaveButtonClick"
      @cancel="handleCancelButtonClick"
    />
    <RecruitmentProcessView v-else :data="data" />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';
  import { RecruitmentProcessEdit } from './components/recruitment-process-edit';
  import { RecruitmentProcessView } from './components/recruitment-process-view';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['update-is-editing']);

  const data = ref(JSON.parse(JSON.stringify(props.data)));

  function handleEditButtonClick() {
    emits('update-is-editing', true);
  }

  function handleCancelButtonClick() {
    emits('update-is-editing', false);
  }

  function handleSaveButtonClick(value) {
    data.value = value;

    emits('update-is-editing', false);
  }
</script>
