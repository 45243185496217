<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell text-center">
    <span v-for="(value, key) in props.columnData" :key="key" >
      <a v-if="value && value !== '-'" :href="value" target="_blank" class="text-brandColor1 font-semibold hover:text-brandColor1/[.80]">{{ __(capitalize(__(key))) }}</a>
      <span v-else>{{ __(capitalize(__(value))) }}</span>
    </span>
  </td>
</template>

<script setup>
import {__, capitalize} from "../../../../../helpers";

const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});
</script>