<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Upload files')">
      <template #icon>
        <Icon
          class="text-3xl text-primary"
          name="drive_folder_upload"
          is-filled
        />
      </template>
      <template v-if="canEdit" #button>
        <div class="ml-auto">
          <Dropdown
            position="right"
            :disabled="isLoading"
            :options="options.document_type"
            @select="handleDropdownSelect"
          >
            <template #trigger>
              <Button
                :disabled="isLoading"
                tabindex="-1"
                variant="ghost"
                class="flex items-center gap-1.5"
              >
                <Icon
                  v-if="isLoading"
                  class="animate-spin text-lg"
                  name="progress_activity"
                />
                <Icon v-else class="text-lg" name="add_box" is-filled />

                <span>{{ __(isLoading ? 'Loading...' : 'Upload file') }}</span>
              </Button>
            </template>
          </Dropdown>

          <input
            id="file-input"
            type="file"
            class="sr-only"
            @change="handleInputChange"
          />
        </div>
      </template>
    </SectionHeader>

    <div
      class="shadow-card relative overflow-hidden rounded-2xl bg-white p-6 md:p-8"
    >
      <div
        v-if="progress"
        class="absolute right-0 top-0 rounded-l-full bg-secondary px-3 py-1"
      >
        <span class="block text-xs leading-none text-white">
          {{ progress }}
        </span>
      </div>

      <EmptySection v-if="!documents.length" :title="__('No files uploaded')">
        <template #icon>
          <Icon
            class="text-xl text-zinc-200"
            name="drive_folder_upload"
            is-filled
          />
        </template>
      </EmptySection>

      <div v-else class="space-y-4">
        <div
          v-for="group in groups"
          :key="group.type.id"
          class="space-y-2 rounded-2xl bg-gradient-to-b from-zinc-100 to-transparent p-4"
        >
          <span class="block text-lg font-medium text-zinc-800">
            {{ group.type.name }}
          </span>

          <div class="sapce-y-2">
            <Document
              v-for="document in group.documents"
              :key="document.id"
              :document="document"
              :username="username"
              :can-edit="canEdit"
              @delete="handleDeleteButtonClick(document)"
            />
          </div>
        </div>
      </div>

      <DocumentDeleteModal
        v-if="isDeleteModalOpen"
        :document="selected"
        @cancel="handleCancelButtonClick"
        @continue="handleContinueButtonClick"
      />
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Dropdown } from '@/components/block/dropdown';
  import { EmptySection } from '@/pages/components/empty-section';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Document } from './components/document';
  import { DocumentDeleteModal } from './components/document-delete-modal';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    username: {
      type: String,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['update-is-editing', 'update-percentages']);

  const toast = useToast();

  const progress = computed(() => {
    return fields.value.total
      ? `${fields.value.completed}/${fields.value.total}`
      : '';
  });

  const groups = computed(() => {
    let groups = {};

    documents.value.forEach((document) => {
      const typeId = document.type_id;

      if (!groups[typeId]) {
        groups[typeId] = {
          type: { id: typeId, name: document.document_type_name },
          documents: []
        };
      }

      groups[typeId].documents.push({
        id: document.id,
        name: document.document_name
      });
    });

    let result = [];

    for (let typeId in groups) {
      result.push(groups[typeId]);
    }

    return result;
  });

  const fields = ref(JSON.parse(JSON.stringify(props.fields)));
  const documents = ref(JSON.parse(JSON.stringify(props.data)));
  const selected = ref(null);
  const isDeleteModalOpen = ref(false);
  const pendingAction = ref('');
  const values = ref({ type: null });
  const isLoading = ref(false);

  function handleCancelButtonClick() {
    emits('update-is-editing', false);
  }

  function handleDropdownSelect(option) {
    values.value.type = option;
    pendingAction.value = 'add';

    emits('update-is-editing', true);
  }

  function handleDeleteButtonClick(document) {
    selected.value = JSON.parse(JSON.stringify(document));
    pendingAction.value = 'delete';

    emits('update-is-editing', true);
  }

  async function handleInputChange(event) {
    const files = event.target.files;

    if (!files || !files.length) {
      return;
    }

    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.cv.save.documents'),
        { document: files[0], document_type: values.value.type },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      documents.value.push(response.data.document);

      // TODO: Wait for BE to send this on response
      // fields.value = response.data.fields;

      emits('update-is-editing', false);
      emits('update-percentages', {
        sectionPercentage: response.data.section_percentage,
        resumePercentage: response.data.percentage
      });

      const element = document.getElementById('file-input');

      if (element) {
        element.value = null;
      }
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }

  function handleContinueButtonClick(data) {
    documents.value = documents.value.filter(
      (document) => document.id !== data.document.id
    );

    // TODO: Wait for BE to send this on response
    // fields.value = data.fields;

    emits('update-is-editing', false);
    emits('update-percentages', {
      sectionPercentage: data.sectionPercentage,
      resumePercentage: data.resumePercentage
    });
  }

  watch(
    () => props.isEditing,
    (value) => {
      if (pendingAction.value === 'add') {
        const element = document.getElementById('file-input');

        if (element) {
          element.click();
        }
      } else if (pendingAction.value === 'delete') {
        isDeleteModalOpen.value = value;
      }
    }
  );

  window.addEventListener('focus', () => {
    if (props.isEditing && pendingAction.value === 'add') {
      pendingAction.value = '';
      emits('update-is-editing', false);
    }
  });
</script>
