<template>
  <div :class="`md:grid md:grid-cols-5 gap-4 mb-4`">
    <div
      v-for="(value, index) in process"
      :key="index"
      class="mb-4 shadow-md p-4 rounded-app bg-white !border-b border-primary relative"
    >
      <!-- Delete button -->
      <div class="absolute right-2 top-2">
        <button
          @click="handleDelete(index)"
          class="text-zinc-600 text-right mb-4"
          type="button"
        >
          <span class="text-xs sr-only"> {{ __("Delete") }}</span> X
        </button>
      </div>

      <h3 class="text-sm text-primary">
        <span class="bg-slate-200 rounded-app py-1 px-2 uppercase">
          {{ __("Step") }} {{ index + 1 }}</span
        >
      </h3>
      <h4 class="text-body flex items-center mt-4 font-medium">
        <Input
          v-model="process[index]"
          :value="process[index]"
          placeholder="Add a new step"
          type="text"
          :errors="errors['recruitment_process.' + index]"
          :maxlength="191"
        />
      </h4>
    </div>
    <div
      class="mb-4 shadow-md p-4 rounded-app bg-white !border-b border-primary cursor-pointer"
      @click="addNewStep"
    >
      <h3 class="text-sm text-primary">
        <span class="bg-slate-200 rounded-app py-1 px-2 uppercase">
          {{ __("Step") }} {{ process.length + 1 }}</span
        >
      </h3>
      <h4 class="text-body flex items-center mt-4 font-medium">
        {{ __("Click to add a new step") }}
      </h4>
    </div>
  </div>
</template>

<script setup>
import { __ } from "../../../../helpers";
import { Input } from "../../../../components/global";
const props = defineProps({
  process: {
    type: Array,
    required: true,
  },
  columns: {
    type: Number,
    default: 2,
  },
  errors: {
    type: Object,
    mandatory: true,
  },
});

const addNewStep = () => {
  props.process.push("");
};

const handleDelete = (index) => {
  props.process.splice(index, 1);
};
</script>
