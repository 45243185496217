<template>
  <div>
    <Combobox
      nullable
      by="id"
      :disabled="disabled"
      :model-value="value"
      @update:model-value="handleUpdateModelValue"
    >
      <div v-click-outside="handleClickOutside" class="relative">
        <ComboboxLabel class="mb-1.5 block leading-normal text-zinc-800">
          {{ __('City') }}
        </ComboboxLabel>

        <ComboboxButton
          :class="`${disabled ? 'cursor-not-allowed bg-zinc-100' : 'bg-white'} flex w-full items-center gap-2.5 rounded-md px-3.5 py-2.5 shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset ring-gray-200 focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary`"
        >
          <div class="pointer-events-none flex text-lg text-neutral-400">
            <Icon class="text-lg" name="distance" is-filled />
          </div>

          <ComboboxInput
            :class="`${disabled ? 'cursor-not-allowed text-zinc-400 placeholder:text-zinc-400' : 'text-zinc-800 placeholder:text-zinc-500'} grow truncate border-none bg-transparent p-0 focus:ring-0`"
            :placeholder="__('Select city')"
            :display-value="(value) => value?.name"
            @input="handleInput"
            @keyup.space.prevent
          />

          <button
            v-if="value"
            :disabled="disabled"
            class="-m-1 flex p-1"
            @click="handleUpdateModelValue(null)"
          >
            <Icon
              :class="`${disabled && 'cursor-not-allowed'} text-lg text-zinc-400`"
              name="close"
            />
          </button>

          <Icon
            v-else
            :class="`${disabled ? 'text-zinc-400' : 'text-zinc-500'} pointer-events-none text-lg`"
            name="expand_more"
          />
        </ComboboxButton>

        <ComboboxOptions
          class="shadow-card absolute z-10 mt-1 max-h-60 w-max min-w-full space-y-1 overflow-y-auto rounded-md border border-gray-200 bg-white p-1 text-sm leading-normal text-zinc-800 focus:outline-none"
          @scroll.passive="handleScroll"
        >
          <div
            v-if="isLoadingQuery"
            class="flex cursor-default select-none items-center gap-2 px-2 py-2.5"
          >
            <Icon class="animate-spin text-lg" name="progress_activity" />
            <span class="block leading-4">
              {{ `${__('Loading')}...` }}
            </span>
          </div>

          <div
            v-else-if="query.length && query.length < 3"
            class="cursor-default select-none px-2 py-2.5"
          >
            <span class="block leading-4">
              {{ __('Please input at least 3 characters') }}
            </span>
          </div>

          <div
            v-else-if="!options.length"
            class="cursor-default select-none px-2 py-2.5"
          >
            <span class="block leading-4">{{ __('No results found') }}</span>
          </div>

          <template v-else>
            <ComboboxOption
              v-for="option in options"
              :key="option.id"
              v-slot="{ selected, active }"
              :value="option"
              as="template"
            >
              <li
                class="flex cursor-default select-none items-center justify-between gap-2 rounded-md px-2 py-2.5 leading-4"
                :class="{
                  'bg-secondary/10 shadow-sm': selected,
                  'bg-gray-50': active && !selected
                }"
              >
                <span class="truncate">
                  {{ option.name }}
                </span>

                <Icon v-if="selected" class="text-zinc-800" name="check" />
              </li>
            </ComboboxOption>
          </template>

          <div
            v-if="isLoadingFetch"
            class="flex cursor-default select-none items-center gap-2 px-2 py-2.5"
          >
            <Icon class="animate-spin text-lg" name="progress_activity" />
            <span class="block leading-4">
              {{ `${__('Loading more items')}...` }}
            </span>
          </div>
        </ComboboxOptions>
      </div>
    </Combobox>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import axios from 'axios';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions
  } from '@headlessui/vue';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    country: {
      type: [Object, null],
      default: null
    },
    value: {
      type: [Object, null],
      default: null
    }
  });

  const emits = defineEmits(['update:value']);

  const toast = useToast();

  const disabled = computed(() => {
    return !props.country;
  });

  const query = ref('');
  const options = ref([]);
  const items = ref([]);
  const pagination = ref({ page: 1, total: 0 });
  const isLoadingQuery = ref(false);
  const isLoadingFetch = ref(false);

  let debounceTimeout;

  function handleClickOutside() {
    query.value = '';
    options.value = items.value;
  }

  function handleUpdateModelValue(value) {
    if (!value) {
      return;
    }

    emits('update:value', value);
  }

  async function handleInput(event) {
    query.value = event.target.value;

    clearTimeout(debounceTimeout);

    if (query.value.length < 3) {
      options.value = items.value;
      isLoadingQuery.value = false;
      return;
    }

    isLoadingQuery.value = true;

    debounceTimeout = setTimeout(async () => {
      try {
        const response = await axios.get(
          route('redesign.cities.find_cities', {
            q: query.value,
            country_code: props.country.code,
            all_cities: true
          })
        );

        options.value = response.data.items;
      } catch {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }

      isLoadingQuery.value = false;
    }, 500);
  }

  async function handleScroll(event) {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (
      !query.value &&
      !isLoadingFetch.value &&
      pagination.value.page < pagination.value.total &&
      scrollTop + clientHeight >= scrollHeight - 100
    ) {
      pagination.value.page++;
      await fetchItems();
    }
  }

  async function fetchItems() {
    if (!props.country || !props.country.code) {
      return;
    }

    isLoadingFetch.value = true;

    try {
      const response = await axios.get(
        route('redesign.cities.find_cities', {
          country_code: props.country.code,
          all_cities: true,
          page: pagination.value.page
        })
      );

      items.value = [...items.value, ...response.data.items];
      options.value = items.value;
      pagination.value = response.data.pagination;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingFetch.value = false;
  }

  watch(
    () => props.country,
    async () => {
      items.value = [];
      pagination.value.page = 1;
      await fetchItems();
    },
    { deep: true, immediate: true }
  );
</script>
