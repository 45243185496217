<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Partners')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="handshake" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
      <div v-if="values.length" class="mb-8">
        <ul
          ref="scrollContainer"
          class="-mx-2 -my-3 max-h-[60rem] space-y-6 overflow-y-auto px-2 py-3 md:-mx-3 md:-my-4 md:px-3 md:py-4"
        >
          <li
            v-for="(item, index) in values"
            :key="item"
            class="shadow-element rounded-lg px-2 py-3"
          >
            <div class="flex justify-end">
              <button
                class="-m-1 flex p-1.5 text-zinc-600"
                type="button"
                :aria-label="__('Delete')"
                @click="handleDeleteButtonClick(index)"
              >
                <Icon class="text-xl" name="close" />
              </button>
            </div>
            <div class="mt-2 grid grid-cols-1 gap-4 md:grid-cols-3">
              <InputText
                :id="`${index}.company_name`"
                v-model:value="item.company_name"
                v-model:errors="errors[`${index}.company_name`]"
                :label="__('Company name')"
                :placeholder="__('Company name')"
                required
              />
              <InputText
                :id="`${index}.website`"
                v-model:value="item.website"
                v-model:errors="errors[`${index}.website`]"
                :label="__('Website')"
                :placeholder="__('Website')"
                required
              />
              <InputImage
                :id="`${index}.logo`"
                v-model:value="item.logo"
                v-model:file="files[index]"
                v-model:errors="errors[`${index}.logo`]"
                class="my-auto"
                :label="__('Upload photo')"
                :description="`JPG, JPEG ${__('or')} PNG (max. 2 MB)`"
                :aspect-ratio="4 / 1"
                required
              />
            </div>
          </li>
        </ul>
      </div>

      <Button
        variant="ghost"
        class="flex items-center gap-1.5"
        @click="handleAddButtonClick"
      >
        <Icon class="text-xl" name="add_box" is-filled />
        <span>{{ __('Add new partner') }}</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
  import { nextTick, ref, watch } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputImage } from '@/components/form/input-image';
  import { InputText } from '@/components/form/input-text';
  import { SectionHeader } from '@/pages/components/section-header';

  const props = defineProps({
    values: {
      type: Object,
      required: true
    },
    files: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['update:values', 'update:files', 'update:errors']);

  const scrollContainer = ref(null);
  const values = ref(JSON.parse(JSON.stringify(props.values)));
  const files = ref(JSON.parse(JSON.stringify(props.files)));
  const errors = ref(JSON.parse(JSON.stringify(props.errors)));

  async function handleAddButtonClick() {
    values.value.push({
      id: null,
      company_name: '',
      website: '',
      logo: ''
    });
    files.value.push(null);

    if (!scrollContainer.value) {
      return;
    }

    await nextTick();

    scrollContainer.value.scrollTo({
      top: scrollContainer.value.scrollHeight,
      behavior: 'smooth'
    });
  }

  function handleDeleteButtonClick(index) {
    values.value = values.value.filter((_, i) => i !== index);
    files.value = files.value.filter((_, i) => i !== index);
    errors.value = {};
  }

  watch(
    values,
    (value) => {
      emits('update:values', value);
    },
    { deep: true }
  );
  watch(
    files,
    (value) => {
      emits('update:files', value);
    },
    { deep: true }
  );
  watch(
    errors,
    (value) => {
      emits('update:errors', value);
    },
    { deep: true }
  );
  watch(props.errors, (value) => {
    errors.value = JSON.parse(JSON.stringify(value));
  });
</script>
