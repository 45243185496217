<template>
  <div id="values">
    <div class="mt-8 md:grid md:grid-cols-2">
      <SectionHeader icon="description" title="Job Description" />
    </div>

    <div class="p-8 shadow-md mt-8 rounded-app bg-white relative">
      <div>
        <VisualEditor
          v-model="data.job.description"
          label="What the job involves"
          required
          :errors="errors.description"
        />

        <VisualEditor
          v-model="data.job.experience_needed"
          label="Experience Needed"
          class="mt-4"
          :errors="errors.experience_needed"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  Button,
  Icon,
  FormMultiselect,
  SectionHeader,
  Textarea,
  VisualEditor,
} from "../../../../components/global";

import { useForm } from "../../../../utils";
import { useToast } from "vue-toastification";
import { __ } from "../../../../helpers";

const props = defineProps({
  data: {
    type: Object,
    mandatory: true,
  },
  errors: {
    type: Object,
    mandatory: true,
  },
});
console.log(props.data);
const toast = useToast();
</script>
