<template>
  <div class="space-y-4">
    <img
      :src="photoUrl"
      :alt="__('Profile picture')"
      class="h-[11.25rem] w-[8.75rem] rounded"
    />

    <div class="space-y-2">
      <div>
        <span class="block font-medium leading-normal text-zinc-600">
          {{ fullName }}
        </span>

        <span class="block text-sm font-medium leading-normal text-secondary">
          {{ role }}
        </span>
      </div>

      <div class="flex items-center gap-2">
        <span class="font-medium leading-normal text-zinc-800">
          {{ `${rating}/5` }}
        </span>

        <div class="flex items-center gap-1">
          <button
            v-for="(_, index) in 5"
            :key="index"
            @click="handleRatingButtonClick(index + 1)"
          >
            <svg
              :class="`${rating >= index + 1 ? 'fill-primary' : 'fill-zinc-300'}`"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0013 14.6033L6.54295 16.6867C6.39017 16.7839 6.23045 16.8256 6.06378 16.8117C5.89712 16.7978 5.75128 16.7423 5.62628 16.645C5.50128 16.5478 5.40406 16.4263 5.33462 16.2804C5.26517 16.1346 5.25128 15.9714 5.29295 15.7909L6.20962 11.8533L3.14712 9.20752C3.00823 9.08252 2.92142 8.94012 2.8867 8.78045C2.85198 8.62072 2.8624 8.46445 2.91795 8.31165C2.9735 8.15892 3.05684 8.03392 3.16795 7.93665C3.27906 7.83945 3.43184 7.77692 3.62628 7.74919L7.66795 7.39499L9.23048 3.68667C9.29988 3.52 9.40755 3.395 9.55335 3.31167C9.69922 3.22833 9.84849 3.18667 10.0013 3.18667C10.1541 3.18667 10.3034 3.22833 10.4492 3.31167C10.595 3.395 10.7027 3.52 10.7721 3.68667L12.3346 7.39499L16.3763 7.74919C16.5708 7.77692 16.7235 7.83945 16.8346 7.93665C16.9458 8.03392 17.0291 8.15892 17.0846 8.31165C17.1402 8.46445 17.1506 8.62072 17.1159 8.78045C17.0812 8.94012 16.9944 9.08252 16.8554 9.20752L13.793 11.8533L14.7096 15.7909C14.7513 15.9714 14.7374 16.1346 14.668 16.2804C14.5985 16.4263 14.5013 16.5478 14.3763 16.645C14.2513 16.7423 14.1054 16.7978 13.9388 16.8117C13.7721 16.8256 13.6124 16.7839 13.4596 16.6867L10.0013 14.6033Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    speaker: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['update:speaker']);

  const placeholderImage = '/images/logo_placeholder.png';

  const photoUrl = computed(() => {
    return props.speaker.photo_url
      ? `/uploads/${props.speaker.photo_url}`
      : placeholderImage;
  });

  const fullName = computed(() => {
    return `${props.speaker.first_name} ${props.speaker.last_name}`;
  });

  const role = computed(() => {
    return props.speaker.role;
  });

  const rating = computed(() => {
    return props.speaker.rating;
  });

  function handleRatingButtonClick(value) {
    emits('update:speaker', { ...props.speaker, rating: value });
  }
</script>
