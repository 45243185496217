<template>
  <div>
    <label
      v-if="label"
      :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
      :for="id"
    >
      {{ `${label}${required ? '*' : ''}` }}
    </label>

    <div class="relative">
      <textarea
        :id="id"
        v-model="value"
        :class="`${errors.length ? 'ring-error' : 'ring-gray-200'} w-full resize-none rounded-md border-0 bg-white px-3.5 py-2.5 font-normal text-zinc-800 shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset placeholder:text-zinc-500 focus:ring-2 focus:ring-inset focus:ring-secondary disabled:cursor-not-allowed disabled:bg-zinc-100 disabled:text-zinc-500 disabled:placeholder:text-zinc-300`"
        :placeholder="placeholder"
        rows="5"
        :minlength="minLength"
        :maxlength="maxLength"
        :disabled="disabled"
        @input="handleInput"
      />

      <span class="absolute bottom-2.5 right-3.5 text-xs text-zinc-600">
        {{ maxLength - value.length }}
      </span>
    </div>

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    minLength: {
      type: Number,
      default: 0
    },
    maxLength: {
      type: Number,
      default: 1000
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  const value = ref(props.value);

  function handleInput() {
    emits('update:value', value.value);
    emits('update:errors', []);
  }
</script>
