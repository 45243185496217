<template>
  <div class="mt-8 rounded-app bg-white p-8 shadow-md">
    <div>
      <form @submit.prevent="() => handleSubmit(1)">
        <div class="my-4 grid gap-4 md:grid-cols-3">
          <Input
            v-model="filters.search"
            class="md:col-span-2"
            :value="filters.search"
            placeholder="Search"
            type="text"
            label="Search job"
          />

          <div>
            <label class="mb-2 block text-sm text-[#58585E]">{{
              __('Ownership')
            }}</label>
            <FormSingleselect
              v-model="filters.owner"
              :values="options.owner"
              :clearable="true"
              type="static"
              class="mt-2"
              @clear="filters.owner = null"
            />
          </div>
        </div>

        <div class="mt-4 items-center justify-between md:flex">
          <div>
            <Button
              :content="isLoading ? __('Loading...') : __('Search')"
              :disabled="isLoading"
              type="submit"
              variation="fill"
            />

            <Button
              content="Reset filters"
              class="ml-2"
              type="button"
              icon="delete"
              @click="deleteFilters"
            />
          </div>
          <!-- <div>
            <Button
              content="Filters"
              class="md:ml-2 mt-2 md:mt-0"
              :icon="isVisibile ? 'expand_less' : 'expand_more'"
              type="button"
              variation="outline"
              @click="isVisibile = !isVisibile"
            />
          </div> -->
        </div>
      </form>
    </div>

    <!-- <div v-if="!isVisibile && checkIfFilters()" class="mt-4">
      <span class="text-sm text-zinc-800"> Active filters: </span>
      <div class="flex flex-wrap gap-2 mt-2">
        <template v-for="(value, index) in filters" :key="index">
          <div
            class="chip rounded-app flex items-center border-0 px-2 py-1 shadow-sm ring-1 ring-inset ring-secondary focus:ring-2 text-sm sm:leading-6 disabled:opacity-50 disabled:cursor-not-allowed"
            v-if="value.length > 0 && index != 'page'"
          >
            {{
              __(
                index.replace(/_/g, " ").charAt(0).toUpperCase() +
                  index.replace(/_/g, " ").slice(1)
              )
            }}:

            {{
              Array.isArray(value) ? value.map((v) => v.name).join(", ") : value
            }}
          </div>
        </template>
      </div>
    </div> -->
  </div>
</template>

<script setup>
  import {
    Button,
    Input,
    FormMultiselect,
    Pagination,
    FormSingleselect
  } from '../../../../../components/global';
  import { ref, watch, onMounted, onUnmounted, reactive } from 'vue';
  import axios from 'axios';
  import { __ } from '../../../../../helpers';

  const props = defineProps({
    filters: {
      type: Object,
      required: true
    },
    selected_filters: {
      type: Object,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    jobs_field: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    isVisibile: {
      type: Boolean,
      required: true
    }
  });

  // console.log(props.selected_filters);

  // should come from the server
  const filters = reactive(props.filters);

  // Function to read the URL and update filters
  function updateFiltersFromURL() {
    const searchParams = new URLSearchParams(window.location.search);
    for (let [key, value] of searchParams.entries()) {
      if (filters.hasOwnProperty(key)) {
        filters[key] = value;
      }
    }
  }

  const handleURLFilters = (newFilters) => {
    const searchParams = new URLSearchParams();
    for (let key in newFilters) {
      if (typeof newFilters[key] === 'string') {
        searchParams.set(key, newFilters[key]);
      } else if (typeof newFilters[key] === 'number') {
        searchParams.set(key, newFilters[key]);
      } else if (Array.isArray(newFilters[key])) {
        newFilters[key].forEach((value) => {
          searchParams.append(`${key}[]`, value.id);
        });
      } else if (
        typeof newFilters[key] === 'object' &&
        newFilters[key] !== null
      ) {
        searchParams.set(key, newFilters[key].id);
      }
    }
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    console.log(newUrl);
    window.history.pushState({}, '', newUrl);
  };

  // Update the URL when filters change
  watch(
    filters,
    (newFilters) => {
      handleURLFilters(newFilters);
    },
    { deep: true }
  );

  // Update filters when URL changes (browser back/forward)
  const handlePopState = () => {
    updateFiltersFromURL();
  };

  onMounted(() => {
    updateFiltersFromURL(); // Initialize filters from URL on mount
    window.addEventListener('popstate', handlePopState);
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', handlePopState);
  });

  console.log(props.jobs);

  const deleteFilters = () => {
    console.log('delete filters');

    for (const key in filters) {
      if (key != 'page') {
        filters[key] = '';
      }
    }

    handleURLFilters(filters);

    handleSubmit();
  };

  const checkIfFilters = () => {
    for (const key in filters.value) {
      if (filters.value[key].length > 0 && key != 'page') {
        console.log(filters.value[key]);
        return true;
      }
    }
    return false;
  };

  const emits = defineEmits(['handleSubmit']);

  const handleSubmit = () => {
    emits('handleSubmit');
  };
</script>
