<template>
  <div class="flex items-end">
    <label for="upload-image" class="group flex w-fit cursor-pointer flex-col items-center gap-2">
      <div
        :class="`${isDragging ? ' bg-primary/5' : 'bg-zinc-50'} relative flex h-[6.25rem] w-[6.25rem] items-center justify-center  overflow-hidden rounded-full border border-dashed border-primary transition-colors`"
        @drop.prevent="handleDrop"
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
      >
        <img
          v-if="value && !isDragging"
          class="h-full w-full object-cover"
          :src="value"
          :alt="__('Profile picture')"
        />
        <Icon v-else class="text-2xl text-primary" name="backup" is-filled />
      </div>

      <span class="px-4 py-2 text-sm font-medium text-primary underline-offset-2 group-hover:underline">
        {{ __(value ? 'Edit photo' : 'Upload photo') }}
      </span>
    </label>

    <div v-if="allowDelete"
        class="cursor-pointer px-4 py-2 text-sm font-medium text-primary underline-offset-2 group-hover:underline"
        role="button"
        aria-label="Delete Photo"
        @click="handleProfileImageDelete"
    >{{ __('Delete photo') }}
    </div>

    <input
      id="upload-image"
      class="sr-only"
      type="file"
      :accept="extensions.join(',')"
      @change="handleInputChange"
    />

    <ImageCropper
      v-model:open="isCropperOpen"
      :value="base64"
      :min-width="minWidth"
      :min-height="minHeight"
      :aspect-ration="aspectRatio"
      @change="handleCropperChange"
      @update:errors="handleUpdateErrors"
    />

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from 'vue';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';
  import { ImageCropper } from '@/components/other/image-cropper';
  import { useToast } from 'vue-toastification';

  const props = defineProps({
    value: {
      type: [String, null],
      required: true
    },
    file: {
      type: [File, null],
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const toast = useToast();

  const emits = defineEmits(['update:value', 'update:file', 'update:errors']);

  const minWidth = 455;
  const minHeight = 455;
  const aspectRatio = 1;
  const maxFileSize = 2;
  const extensions = ['.jpg', '.jpeg', '.png'];
  const defaultImg = window.location.origin+'/logos/logo-150x150.png';

  const base64 = ref('');
  const isCropperOpen = ref(false);
  const isDragging = ref(false);

  function readFiles(files) {
    if (!files || !files.length) {
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      base64.value = reader.result;
      isCropperOpen.value = true;
    };

    reader.readAsDataURL(files[0]);
  }

  function handleInputChange(event) {
    readFiles(event.target.files);
    event.target.value = '';
  }

  function handleDrop(event) {
    const files = Array.from(event.dataTransfer.files);

    const validFiles = files.filter((file) =>
      extensions
        .map((extension) => `image/${extension.split('.')[1]}`)
        .includes(file.type)
    );

    if (validFiles.length) {
      readFiles(validFiles);
    }

    isDragging.value = false;
  }

  function handleDragOver() {
    isDragging.value = true;
  }

  function handleDragLeave() {
    isDragging.value = false;
  }

  function handleCropperChange(base64, file) {
    console.log('thefile', file)
    if (file.size > maxFileSize * 1024 * 1024) {
      emits('update:errors', [
        `${__('File size exceeds limit. Please upload a file smaller than')} ${maxFileSize} MB.`
      ]);
      return;
    }

    emits('update:value', base64);
    emits('update:file', file);
    emits('update:errors', []);
  }

  function handleUpdateErrors(value) {
    emits('update:errors', value);
  }
  const allowDelete = computed(() => {
    return props.value !== defaultImg;
  });
  const handleProfileImageDelete = (event) => {

    if (!isBase64(props.value)) {
      axios.post(route('redesign.profile.deleteProfilePicture'), {
        profile_photo: props.value
      }).then((response) => {
        if (response.data.success){
          // toast.success(response.data.message);
          emits('update:value', defaultImg);
          emits('update:file', null);
        } else {
          toast.error(response.data.message);
        }
      }).catch((error) => {
        toast.error(error.response.data.message);
      });
    } else {
      emits('update:value', defaultImg);
      emits('update:file', null);
    }

  }
  function isBase64(imageStr) {
    const regex = /^data:image\/[a-zA-Z]+;base64,/;
    return regex.test(imageStr);
  }
</script>
