<template>
  <div class="px-5 pb-5">
    <div class="flex flex-col gap-5">
      <div
        class="flex flex-col sm:flex-row gap-5 justify between w-full items-end"
        v-for="(item, index) in filterData.slice()"
        v-bind:key="index"
      >
        <div class="w-full">
          <label
            class="font-primary font-normal text-base text-gray-400 pb-1 flex justify-between w-full"
          >
            {{ __("Skill") }}
            <div v-if="markMandatory == true">
              <Toggle
                v-model="item.mandatory"
                :value="item.mandatory"
                :setid="'mandatory'"
              />
            </div>
          </label>

          <Multiselect
            v-model="item.value"
            :values="skills"
            type="dynamic"
            :clearable="false"
            @query="
              (query) => {
                skills = [];
                getSkillsByQuery(query);
              }
            "
          />
        </div>
        <div
          class="p-2 bg-lightGray rounded-app w-12 text-center font-bold cursor-pointer"
          @click="removeItem(filterData, item)"
          v-if="index < filterData.length - 1"
        >
          <span>-</span>
        </div>
        <div
          class="p-2 bg-lightGray rounded-app w-12 text-center font-bold cursor-pointer"
          @click="pushItem(filterData)"
          v-if="index >= filterData.length - 1"
        >
          +
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Toggle, Multiselect } from "../../global";
import { ref } from "vue";
import { __ } from "../../../helpers";

const props = defineProps({
  markMandatory: Boolean,
  filterData: Array,
});

const skills = ref([]);

// Create a function that receinve an array of objects and return an array with the value of "name"
const getValues = (array, field_name) => {
  // if field_name return an array of {"field_name": "value"}
  if (field_name) {
    return array.map((item) => {
      return { [field_name]: item[field_name] };
    });
  }

  // else return an array of values
  return array.map((item) => {
    return {
      name: item.name,
      id: item.id,
    };
  });
};

const getSkills = async (e) => {
  if (e.target.value.length < 3 || e.target.value === undefined) {
    return;
  }

  axios
    .get(route("skills.find_skills"), {
      params: {
        q: e.target.value,
      },
    })
    .then((response) => {
      skills.value = getValues(response.data);
    });
};

const getSkillsByQuery = async (query) => {
  if (query.length < 3 || query === undefined) {
    return;
  }

  axios
    .get(route("skills.find_skills"), {
      params: {
        q: query,
      },
    })
    .then((response) => {
      skills.value = getValues(response.data);
    });
};

function removeItem(arr, value) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    }
  }
}

function pushItem(value) {
  value.push({
    type: "string",
    value: {},
    mandatory: false,
  });
}
</script>
