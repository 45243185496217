<template>
  <div v-if="!data">
    <span>{{ __('Loading...') }}</span>
  </div>

  <div v-else class="space-y-12">
    <h1 class="text-5xl font-medium text-zinc-800">
      {{ __('Events') }}
    </h1>

    <TabGroup :selected-index="selectedTabIndex" @change="handleTabChange">
      <TabList class="flex gap-2 overflow-auto rounded-lg bg-white p-3">
        <Tab
          v-for="tab in options.tabs"
          :key="tab.value"
          v-slot="{ selected }"
          as="template"
        >
          <button
            :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
          >
            {{ tab.label }}
          </button>
        </Tab>
      </TabList>

      <div class="space-y-4">
        <div class="grid gap-2 md:grid-cols-2 md:gap-4">
          <InputSelect
            :value="values.city"
            :placeholder="__('Location')"
            :options="options.city"
            @update:value="handleUpdateCityValue"
          >
            <template #icon>
              <Icon
                class="text-lg text-neutral-400"
                name="distance"
                is-filled
              />
            </template>
          </InputSelect>

          <InputSelect
            :value="values.search"
            :placeholder="__('Name')"
            :options="options.search"
            @update:value="handleUpdateSearchValue"
          >
            <template #icon>
              <Icon class="text-lg text-neutral-400" name="search" />
            </template>
          </InputSelect>
        </div>

        <TabPanels>
          <TabPanel
            v-for="tab in options.tabs"
            :key="tab.value"
            class="shadow-card rounded-lg bg-white p-6 md:p-8"
          >
            <span v-if="!events.length" class="text-zinc-600">
              {{ __('No events found') }}
            </span>

            <div v-else class="space-y-8">
              <Event v-for="event in events" :key="event.id" :event="event" />

              <div v-if="renderPagination" class="flex justify-center gap-2">
                <button
                  class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
                  :disabled="isPreviousButtonDisabled"
                  :aria-label="__('Previous')"
                  @click="handlePreviousPageButtonClick"
                >
                  <Icon class="text-xl text-primary" name="arrow_back" />
                </button>

                <button
                  class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
                  :disabled="isNextButtonDisabled"
                  :aria-label="__('Next')"
                  @click="handleNextPageButtonClick"
                >
                  <Icon class="text-xl text-primary" name="arrow_forward" />
                </button>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </div>
    </TabGroup>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { getTimezone } from '@/scripts/date.script';
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
  import { Icon } from '@/components/block/icon';
  import { InputSelect } from '@/components/form/input-select';
  import { Event } from './components/shared/event';

  const toast = useToast();

  const values = computed(() => {
    return {
      tab: data.value.filters.selected?.tab || null,
      city: data.value.filters.selected?.city || null,
      search: data.value.filters.selected?.search || null
    };
  });

  const options = computed(() => {
    return data.value.filters.options;
  });

  const selectedTabIndex = computed(() => {
    if (!values.value.tab) {
      return 0;
    }

    return options.value.tabs.findIndex(
      (tab) => tab.value === values.value.tab.value
    );
  });

  const events = computed(() => {
    return data.value.events;
  });

  const page = computed(() => {
    return data.value.pagination.page;
  });

  const total = computed(() => {
    return data.value.pagination.total;
  });

  const renderPagination = computed(() => {
    return total.value > 1;
  });

  const isPreviousButtonDisabled = computed(() => {
    return page.value <= 1;
  });

  const isNextButtonDisabled = computed(() => {
    return page.value >= total.value;
  });

  const data = ref(null);

  function updateQueryParams(newParams) {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);

    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === null) {
        params.delete(key);
      } else {
        params.set(key, newParams[key]);
      }
    });

    url.search = params.toString();
    window.location = url.toString();
  }

  function handleTabChange(index) {
    const url = new URL(window.location);
    const params = new URLSearchParams();
    const tab = options.value.tabs.find((_, i) => i === index);

    if (!tab) {
      return;
    }

    params.set('tab', tab.value.toLowerCase());
    url.search = params.toString();
    window.location = url.toString();
  }

  function handleUpdateCityValue(value) {
    const newParams = { city: value ? value.id : null };
    updateQueryParams(newParams);
  }

  function handleUpdateSearchValue(value) {
    const newParams = { search: value ? value.id : null };
    updateQueryParams(newParams);
  }

  function handlePreviousPageButtonClick() {
    const newParams = { page: page.value - 1 };
    updateQueryParams(newParams);
  }

  function handleNextPageButtonClick() {
    const newParams = { page: page.value + 1 };
    updateQueryParams(newParams);
  }

  onMounted(async () => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    const timezone = getTimezone();

    params.set('timezone', timezone.tz);

    try {
      const response = await axios.get(route('redesign.events.getEvents'), {
        params
      });

      data.value = response.data;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }
  });
</script>
