<template>
  <InputCombobox
    :label="__('Country')"
    :placeholder="__('Select country')"
    :options="options"
    :value="value"
    @update:value="handleUpdateValue"
  >
    <template #icon>
      <Icon class="text-lg" name="public" />
    </template>
  </InputCombobox>
</template>

<script setup>
  import { Icon } from '@/components/block/icon';
  import { InputCombobox } from '@/components/form/input-combobox';

  const props = defineProps({
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Object, null],
      default: null
    }
  });

  const emits = defineEmits(['update:value']);

  function handleUpdateValue(value) {
    emits('update:value', value);
  }
</script>
