<template>
  <Modal open @close="handleCloseButtonClick">
    <div class="flex justify-between gap-4">
      <div class="flex items-center gap-2">
        <Icon name="work" is-filled class="text-2xl text-primary" />

        <span class="text-lg font-medium leading-normal text-zinc-800">
          {{ title }}
        </span>
      </div>

      <Button
        class="-mr-3 -mt-3 h-fit"
        variant="icon"
        @click="handleCloseButtonClick"
      >
        <Icon name="close" class="text-lg" />
      </Button>
    </div>

    <div class="space-y-5">
      <Integration
        v-for="(integration, index) in integrations"
        :key="integration.id"
        v-model:integration="integrations[index]"
        :job="job"
        @cancel="handleCancelButtonClick(integration)"
        @post="handlePostButtonClick(integration)"
        @suspend="handleSuspendButtonClick(integration)"
        @reactivate="handleReactivateButtonClick(integration)"
        @repost="handleRepostButtonClick(integration)"
      />
    </div>
  </Modal>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Modal } from '@/components/block/modal';
  import { Integration } from './components/integration';

  const props = defineProps({
    job: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['close', 'post']);

  const toast = useToast();

  const title = computed(() => {
    return props.job.title;
  });

  const integrations = ref(
    JSON.parse(
      JSON.stringify(
        props.job.integrations.map((integration) => ({
          ...integration,
          isExpanded: false,
          isDisabled: false,
          isLoading: false
        }))
      )
    )
  );

  function handleCloseButtonClick() {
    emits('close');
  }

  function handleCancelButtonClick(item) {
    integrations.value = integrations.value.map((integration) => {
      if (integration.id === item.id) {
        return { ...integration, isExpanded: false };
      }

      return { ...integration, isDisabled: false };
    });
  }

  async function handlePostButtonClick(item) {
    if (!item.isExpanded) {
      integrations.value = integrations.value.map((integration) => {
        if (integration.id === item.id) {
          return { ...integration, isExpanded: true };
        }

        return { ...integration, isDisabled: true };
      });

      return;
    }

    integrations.value = integrations.value.map((integration) => {
      if (integration.id === item.id) {
        return { ...integration, isLoading: true };
      }

      return integration;
    });

    try {

      let routeName;
      // @TODO - refactor this and do it smarter, but keep this like so until implementing more integrations to see how they vary
      if ((item.type === 'api' && item.name !== 'Hipo') || item.type === 'custom1') {
        routeName = 'redesign.integration.post.job';
      } else {
        routeName = 'redesign.integration.share.job';
      }

      const response = await axios.post(route(routeName), {
        job_id: props.job.id,
        integration_id: item.id
      });

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      const jobIntegrationId = response?.data?.job_integration_id;
      const jobExpireCopy = response?.data?.expire_copy;

      integrations.value = integrations.value.map((integration) => {
        if (integration.id === item.id) {
          return {
            ...integration,
            job_integration_id: jobIntegrationId,
            expire_copy: jobExpireCopy,
            state: 'PUBLISHED',
            isExpanded: false,
            isLoading: false
          };
        }

        return { ...integration, isDisabled: false };
      });
    } catch (error) {
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(__('An unexpected error has occured. Please try again later.'));
      }
    }

    integrations.value = integrations.value.map((integration) => {
      if (integration.id === item.id) {
        return { ...integration, isLoading: false };
      }

      return integration;
    });

    emits('post', { ...props.job, integrations });
  }

  async function handleSuspendButtonClick(item) {
    if (!item.isExpanded) {
      integrations.value = integrations.value.map((integration) => {
        if (integration.id === item.id) {
          return { ...integration, isExpanded: true };
        }

        return { ...integration, isDisabled: true };
      });

      return;
    }

    integrations.value = integrations.value.map((integration) => {
      if (integration.id === item.id) {
        return { ...integration, isLoading: true };
      }

      return integration;
    });

    try {

      const response = await axios.post(route('redesign.integration.suspend.job'), {
        job_id: props.job.id,
        job_integration_id: item.job_integration_id,
        integration_id: item.id
      });

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      integrations.value = integrations.value.map((integration) => {
        if (integration.id === item.id) {
          return {
            ...integration,
            state: 'SUSPENDED',
            isExpanded: false,
            isLoading: false
          };
        }

        return { ...integration, isDisabled: false };
      });
    } catch (error) {
      console.log(error)
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(__('An unexpected error has occured. Please try again later.'));
      }
    }

    integrations.value = integrations.value.map((integration) => {
      if (integration.id === item.id) {
        return { ...integration, isLoading: false };
      }

      return integration;
    });

    emits('suspend', { ...props.job, integrations });
  }

  async function handleReactivateButtonClick(item) {
    if (!item.isExpanded) {
      integrations.value = integrations.value.map((integration) => {
        if (integration.id === item.id) {
          return { ...integration, isExpanded: true };
        }

        return { ...integration, isDisabled: true };
      });

      return;
    }

    integrations.value = integrations.value.map((integration) => {
      if (integration.id === item.id) {
        return { ...integration, isLoading: true };
      }

      return integration;
    });

    try {

      const response = await axios.post(route('redesign.integration.update.job'), {
        job_id: props.job.id,
        job_integration_id: item.job_integration_id,
        integration_id: item.id
      });

      if (response?.data?.message) {
        toast.success(response.data.message);
      }
      const jobIntegrationId = response?.data?.job_integration_id;
      const jobExpireCopy = response?.data?.expire_copy;

      integrations.value = integrations.value.map((integration) => {
        if (integration.id === item.id) {
          return {
            ...integration,
            job_integration_id: jobIntegrationId,
            expire_copy: jobExpireCopy,
            state: 'PUBLISHED',
            isExpanded: false,
            isLoading: false
          };
        }

        return { ...integration, isDisabled: false };
      });
    } catch (error) {
      console.log(error)
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(__('An unexpected error has occured. Please try again later.'));
      }
    }

    integrations.value = integrations.value.map((integration) => {
      if (integration.id === item.id) {
        return { ...integration, isLoading: false };
      }

      return integration;
    });

    emits('reactivate', { ...props.job, integrations });
  }
  async function handleRepostButtonClick(item) {
    if (!item.isExpanded) {
      integrations.value = integrations.value.map((integration) => {
        if (integration.id === item.id) {
          return { ...integration, isExpanded: true };
        }

        return { ...integration, isDisabled: true };
      });

      return;
    }

    integrations.value = integrations.value.map((integration) => {
      if (integration.id === item.id) {
        return { ...integration, isLoading: true };
      }

      return integration;
    });

    try {
      const routeName =
          item.name !== 'Hipo'
              ? 'redesign.integration.repost.job'
              : 'redesign.integration.update.job';
      const response = await axios.post(route(routeName), {
        job_id: props.job.id,
        job_integration_id: item.job_integration_id,
        integration_id: item.id
      });

      if (response?.data?.message) {
        toast.success(response.data.message);
      }
    console.log('repost response')
      console.log(response.data)
      const jobIntegrationId = response?.data?.job_integration_id;
      const jobExpireCopy = response?.data?.expire_copy;

      integrations.value = integrations.value.map((integration) => {
        if (integration.id === item.id) {
          return {
            ...integration,
            job_integration_id: jobIntegrationId,
            expire_copy: jobExpireCopy,
            state: 'PUBLISHED',
            isExpanded: false,
            isLoading: false
          };
        }

        return { ...integration, isDisabled: false };
      });
    } catch (error) {
      console.log(error)
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(__('An unexpected error has occured. Please try again later.'));
      }
    }

    integrations.value = integrations.value.map((integration) => {
      if (integration.id === item.id) {
        return { ...integration, isLoading: false };
      }

      return integration;
    });

    emits('repost', { ...props.job, integrations });
  }


</script>
