<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Media')">
      <template #icon>
        <Icon
          class="text-3xl text-primary"
          name="gallery_thumbnail"
          is-filled
        />
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-lg bg-white p-6 md:p-8">
      <div class="space-y-4 sm:space-y-6 md:space-y-8">
        <div ref="slideshow" class="flex gap-8 overflow-hidden">
          <div
            v-for="image in media"
            :key="image"
            class="flex h-96 min-w-full items-center justify-center"
          >
            <img
              class="max-h-full rounded-lg"
              :src="
                image.photo_url.includes('/uploads/')
                  ? image.photo_url
                  : `/uploads/${image.photo_url}`
              "
              :alt="__('Media image')"
            />
          </div>
        </div>
        <div class="flex items-center justify-between gap-4 sm:gap-6 md:gap-8">
          <button
            class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
            :disabled="isPreviousButtonDisabled"
            :aria-label="__('Previous')"
            @click="handlePreviousButtonClick"
          >
            <Icon class="text-xl text-primary" name="arrow_back" />
          </button>

          <div
            ref="indexSlideshow"
            class="flex w-full snap-x snap-mandatory justify-evenly gap-2 overflow-x-auto sm:gap-3 md:gap-4"
          >
            <button
              v-for="image in media"
              :key="image"
              :class="`${selected && selected.id === image.id ? 'border-secondary' : 'border-transparent'} h-24 min-w-[calc((100%-0.5rem)/2)] max-w-[calc((100%-0.5rem)/2)] snap-start rounded-lg border-2 p-0.5 transition-colors sm:h-32 sm:min-w-[calc((100%-1.5rem)/3)] sm:max-w-[calc((100%-1.5rem)/3)] md:h-40 md:min-w-[calc((100%-3rem)/4)] md:max-w-[calc((100%-3rem)/4)]`"
              @click="handleImageButtonClick(image)"
            >
              <img
                class="h-full w-full rounded-md object-cover"
                :src="
                  image.photo_url.includes('/uploads/')
                    ? image.photo_url
                    : `/uploads/${image.photo_url}`
                "
                :alt="__('Media image')"
              />
            </button>
          </div>

          <button
            class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
            :disabled="isNextButtonDisabled"
            :aria-label="__('Next')"
            @click="handleNextButtonClick"
          >
            <Icon class="text-xl text-primary" name="arrow_forward" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onBeforeUnmount, onMounted, ref } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';

  const props = defineProps({
    media: {
      type: Array,
      required: true
    }
  });

  const selected = ref(props.media[0]);
  const slideshow = ref(null);
  const indexSlideshow = ref(null);
  const isPreviousButtonDisabled = ref(false);
  const isNextButtonDisabled = ref(false);

  function handleImageButtonClick(image) {
    if (!slideshow.value) {
      return;
    }

    const index = props.media.map((item) => item.id).indexOf(image.id);

    slideshow.value.scrollTo({
      left: (slideshow.value.clientWidth + 32) * index,
      behavior: 'smooth'
    });

    selected.value = image;
  }

  function handlePreviousButtonClick() {
    if (!indexSlideshow.value) {
      return;
    }

    indexSlideshow.value.scrollBy({
      left: -indexSlideshow.value.clientWidth,
      behavior: 'smooth'
    });
  }

  function handleNextButtonClick() {
    if (!indexSlideshow.value) {
      return;
    }

    indexSlideshow.value.scrollBy({
      left: indexSlideshow.value.offsetWidth,
      behavior: 'smooth'
    });
  }

  function handleIndexSlideshowScrollEvent() {
    if (!indexSlideshow.value) {
      return;
    }

    isPreviousButtonDisabled.value = indexSlideshow.value.scrollLeft <= 0;
    isNextButtonDisabled.value =
      indexSlideshow.value.scrollLeft >=
      indexSlideshow.value.scrollWidth - indexSlideshow.value.clientWidth;
  }

  onMounted(() => {
    if (!indexSlideshow.value) {
      return;
    }

    indexSlideshow.value.addEventListener(
      'scroll',
      handleIndexSlideshowScrollEvent
    );

    isPreviousButtonDisabled.value = indexSlideshow.value.scrollLeft <= 0;
    isNextButtonDisabled.value =
      indexSlideshow.value.scrollLeft >=
      indexSlideshow.value.scrollWidth - indexSlideshow.value.clientWidth;
  });

  onBeforeUnmount(() => {
    if (!indexSlideshow.value) {
      return;
    }

    indexSlideshow.value.removeEventListener(
      'scroll',
      handleIndexSlideshowScrollEvent
    );
  });
</script>
