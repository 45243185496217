<template>
  <div class="space-y-8">
    <SectionHeader :title="__('My Profile')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="account_box" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
      <form @submit.prevent="handleSubmit">
        <div class="space-y-4">
          <UploadImage
            v-model:value="values.profile_photo"
            v-model:file="files.profile_photo"
            v-model:errors="errors.profile_photo"
          />

          <div class="grid gap-4 md:grid-cols-2">
            <InputText
              id="first_name"
              v-model:value="values.first_name"
              v-model:errors="errors.first_name"
              :label="__('First name')"
              :placeholder="__('First name')"
              required
            />

            <InputText
              id="last_name"
              v-model:value="values.last_name"
              v-model:errors="errors.last_name"
              :label="__('Last name')"
              :placeholder="__('Last name')"
              required
            />

            <InputSelect
              id="preferred_language"
              v-model:value="values.preferred_language"
              v-model:errors="errors.preferred_language"
              :label="__('Preferred language')"
              :placeholder="__('Preferred language')"
              :options="options.preffered_language"
            >
              <template #icon>
                <Icon class="text-lg text-neutral-400" name="work" is-filled />
              </template>
            </InputSelect>

            <div class="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5">
              <InputSelect
                id="country_prefix"
                v-model:value="values.country_prefix"
                v-model:errors="errors.country_prefix"
                class="col-span-1 sm:col-span-2"
                :label="__('Country prefix')"
                :placeholder="__('Country prefix')"
                :options="options.country_prefix"
                required
              />

              <InputText
                id="phone_number"
                v-model:value="values.phone_number"
                v-model:errors="errors.phone_number"
                class="col-span-1 sm:col-span-3"
                :label="__('Phone number')"
                :placeholder="__('Phone number')"
                required
              >
                <template #icon>
                  <Icon
                    class="text-lg text-neutral-400"
                    name="phone"
                    is-filled
                  />
                </template>
              </InputText>
            </div>
          </div>
        </div>

        <div
          class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
        >
          <Button
            class="flex items-center justify-center gap-1.5"
            :type="isLoading ? 'button' : 'submit'"
            :disabled="isLoading"
          >
            <Icon
              v-if="isLoading"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span>{{ __('Save') }}</span>
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputSelect } from '@/components/form/input-select';
  import { InputText } from '@/components/form/input-text';
  import { SectionHeader } from '@/pages/components/section-header';
  import { UploadImage } from './components/upload-image';
  import axios from "axios";

  const props = defineProps({
    profile: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const values = ref({
    profile_photo: props.profile.profile_photo || null,
    first_name: props.profile.first_name,
    last_name: props.profile.last_name,
    preferred_language: props.profile.preferred_language || null,
    country_prefix: props.profile.country_prefix || null,
    phone_number: props.profile.phone_number || ''
  });
  const errors = ref({
    profile_photo: [],
    first_name: [],
    last_name: [],
    preferred_language: [],
    country_prefix: [],
    phone_number: []
  });
  const files = ref({ profile_photo: null });
  const isLoading = ref(false);

  async function processUserImage()
  {
    if (files.value.profile_photo) {
      try {
        const formData = new FormData();
        formData.append('profile_photo', files.value.profile_photo);

        const response = await axios.post(route('redesign.company.recruiter.uploadPhoto'), formData);
        values.value.profile_photo = response.data.full_path;
      } catch (error) {
        toast.error(error?.response?.data?.message || __('Something went wrong while uploading the profile image.'));
        throw error;
      }
    }
  }
  async function handleSubmit() {
    const requiredFieldKeys = [
      'first_name',
      'last_name',
      'country_prefix',
      'phone_number'
    ];
    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      await processUserImage();
      // if (files.value.profile_photo) {
      //   const formData = new FormData();
      //
      //   formData.append('file', files.value.profile_photo);
      //
      //   const response = await axios.post(
      //     route('redesign.company.recruiter.uploadPhoto'),
      //     formData
      //   );
      //
      //   values.value.profile_photo = response.data.full_path;
      //   console.log(response.data.full_path)
      // }

      const response = await axios.post(
        route('redesin.company.recruiter.profile.update'),
        values.value
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
