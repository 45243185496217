<template>
  <div class="mt-8">
    <!-- applications -->
    <div>
      <h2 class="text-2xl text-[#212429] font-medium mb-4">
        {{ __("Applications") }}
        <span class="text-white bg-primary rounded-full text-xs px-2 py-1">{{
          applications.length
        }}</span>
      </h2>

      <div>
        <div v-if="applications.length != 0" class="grid md:grid-cols-3 gap-4">
          <div
            v-for="application in applications"
            :key="application.id"
            class="rounded-app shadow-md flex flex-col justify-between bg-white"
          >
            <div
              class="bg-white rounded-t-app p-4 cursor-pointer relative"
              @click="openModal(application.job)"
              v-if="!application.flipped"
            >
              <span
                class="absolute bottom-2 right-0 text-xs bg-secondary text-white pl-4 pr-2 py-1 rounded-l-app"
                v-if="application.new"
                >{{ __("NEW") }}</span
              >
              <div class="flex justify-between">
                <div
                  class="flex gap-2 items-center"
                  v-if="!config_general_single_company"
                >
                  <img
                    :src="application.job.company.logo"
                    alt="Company logo"
                    class="w-12 h-12 rounded-full"
                  />
                  <div>
                    <h3 class="text-sm text-[#212429]">
                      {{ application.job.company.name }}
                    </h3>
                    <p class="text-xs text-[#58585E]">
                      {{ application.job.company.domain }}
                    </p>
                  </div>
                </div>
                <div v-else>
                  <SingleCompanyIcon />
                </div>
              </div>

              <div>
                <h3
                  class="text-lg my-2 break-all"
                  :class="
                    application.job.active ? 'text-[#52525B]' : 'text-zinc-300'
                  "
                >
                  {{ application.job.title }}
                </h3>
              </div>

              <div class="!text-xs flex gap-2 items-center">
                <div class="text-xs text-[#52525B]">
                  <div class="!text-xs flex gap-2 items-center text-[#52525B]">
                    <Icon name="how_to_reg" class="!text-sm" />
                    <span class="">
                      {{ __("Applied") }} {{ application.applied_ago }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="p-4 max-h-40 overflow-y-auto">
              <h4 class="text-zinc-800 text-base">
                {{ __("Thank you for your interest in the ") }}
                {{ application.job.title }} {{ __("role at") }}
                {{ application.job.company.name }}
              </h4>

              <p class="text-zinc-600 text-sm mt-2">
                {{ application.reject_reason }}
              </p>
            </div>
            <div
              class="p-4 bg-zinc-50 rounded-b-app"
              v-if="
                application.job.active &&
                !application.rejected &&
                !application.expired
              "
            >
              <div class="!text-xs flex gap-2 items-center">
                <Icon name="description" class="!text-sm" />
                <span class=""
                  ><b class="font-bold">{{ __("Status") }}:</b>
                  {{ application.status }}</span
                >
              </div>
            </div>

            <div class="p-4 bg-gray-200 rounded-b-app" v-else>
              <div class="text-xs text-[#52525B]" v-if="application.status">
                <div class="flex items-center justify-between">
                  <div class="flex gap-2 items-center">
                    <Icon name="description" class="!text-sm" />
                    <span class=""
                      ><b class="font-bold">{{ __("Status") }}:</b>
                      {{ application.status }}</span
                    >
                  </div>

                  <div
                    v-if="application.reject_reason"
                    class="flex gap-2 items-center cursor-pointer"
                    @click="application.flipped = !application.flipped"
                  >
                    <Button
                      :content="application.flipped ? 'Hide' : 'See why'"
                      icon="flip_to_back"
                      variation="link"
                    />
                    <!-- <Icon name="flip_to_back" class="!text-sm" />
                    <span>{{
                      application.flipped ? __("Hide") : __("See why")
                    }}</span> -->
                  </div>
                </div>
              </div>
              <!-- <div
                class="text-xs text-[#52525B]"
                v-if="application.rejected && application.job.active"
              >
                {{ __("Your application was rejected") }}
              </div>
              <div
                class="text-xs text-[#52525B]"
                v-else-if="application.expired && application.job.active"
              >
                {{ __("This application is expired") }}
              </div>
              <div class="text-xs text-[#52525B]" v-else>
                {{ __("This job is inactive at the moment") }}
              </div> -->
            </div>
          </div>
        </div>

        <div v-else>
          <p class="text-[#212429] text-base">
            {{ __("You have no applications yet.") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import {
  Button,
  Icon,
  SingleCompanyIcon,
} from "../../../../../components/global";
import { __ } from "../../../../../helpers";

const props = defineProps({
  applications: {
    type: Array,
    required: true,
  },
  config_general_single_company: {
    type: Number,
    required: true,
  },
});

console.log(props.applications);
const applications = reactive(props.applications);

// add flipped property to each application
applications.forEach((application) => {
  application.flipped = false;
});

const emit = defineEmits(["openModal"]);

const openModal = (job) => {
  emit("openModal", job);
};
</script>
