<template>
  <div>
    <label
      v-if="label"
      :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
      :for="id"
    >
      {{ `${label}${required ? '*' : ''}` }}
    </label>

    <div class="relative">
      <div
        class="pointer-events-none absolute left-3.5 top-1/2 flex -translate-y-1/2"
      >
        <slot name="icon" />
      </div>

      <input
        :id="id"
        :class="`${slots['icon'] ? 'pl-[2.5rem]' : ''} ${errors.length ? 'ring-error' : 'ring-gray-200'} w-full rounded-md border-0 bg-white px-3.5 py-2.5 text-zinc-800 shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset placeholder:text-zinc-500 focus:ring-2 focus:ring-inset focus:ring-secondary disabled:cursor-not-allowed disabled:bg-zinc-100 disabled:text-zinc-500 disabled:placeholder:text-zinc-300`"
        type="text"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        @input="handleInput"
      />
    </div>

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { ref, useSlots, watch } from 'vue';

  const props = defineProps({
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: null
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  const slots = useSlots();

  const formattedValue = ref(props.value);

  function handleInput(event) {
    const value = event.target.value
      .replace(/[^0-9]/g, '')
      .replace(/^0+(?!$)/, '');

    if (value === '') {
      formattedValue.value = '';
      emits('update:value', '');
      emits('update:errors', []);
      return;
    }

    const numericValue = Number(value);

    if (props.min !== null && numericValue < props.min) {
      return;
    }

    if (props.max !== null && numericValue > props.max) {
      return;
    }

    formattedValue.value = numericValue;

    emits('update:value', numericValue);
    emits('update:errors', []);
  }

  watch(
    () => props.value,
    (newValue) => {
      formattedValue.value = newValue;
    }
  );
</script>
