<template>
  <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
    <form @submit.prevent="handleSubmit">
      <div v-if="values.length" class="mb-8">
        <ul
          ref="scrollContainer"
          class="-mx-2 -my-3 grid max-h-[60rem] grid-cols-1 gap-6 overflow-y-auto px-2 py-3 md:-mx-3 md:-my-4 md:grid-cols-2 md:px-3 md:py-4"
        >
          <li
            v-for="(_, index) in values"
            :key="index"
            class="shadow-element rounded-lg px-2 py-3"
          >
            <div class="flex justify-end">
              <button
                class="-m-1 flex p-1.5 text-zinc-600"
                type="button"
                :aria-label="__('Delete')"
                @click="handleDeleteButtonClick(index)"
              >
                <Icon class="text-xl" name="close" />
              </button>
            </div>

            <InputText
              :id="`${index}.step`"
              v-model:value="values[index]"
              v-model:errors="errors[`${index}.step`]"
              class="mt-2"
              :label="`${__('Step')} ${index + 1}`"
              :placeholder="__('Add step')"
              required
            />
          </li>
        </ul>
      </div>

      <Button
        class="flex items-center gap-1.5"
        variant="ghost"
        @click="handleAddNewButtonClick"
      >
        <Icon class="text-xl" name="add_box" is-filled />
        <span>{{ __('Add another step') }}</span>
      </Button>

      <span
        v-if="errorMessage"
        class="mt-8 block text-center text-sm text-error"
      >
        {{ errorMessage }}
      </span>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { nextTick, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputText } from '@/components/form/input-text';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  });

  const emits = defineEmits(['save', 'cancel']);

  const toast = useToast();

  const scrollContainer = ref(null);
  const values = ref(JSON.parse(JSON.stringify(props.data)));
  const errors = ref({});
  const isLoading = ref(false);

  async function handleAddNewButtonClick() {
    values.value.push('');

    if (!scrollContainer.value) {
      return;
    }

    await nextTick();

    scrollContainer.value.scrollTo(
      { top: scrollContainer.value.scrollHeight, behavior: 'smooth' },
      50
    );
  }

  function handleDeleteButtonClick(index) {
    values.value = values.value.filter((_, i) => i !== index);
    errors.value = {};
  }

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    let firstElement;

    values.value.forEach((value, index) => {
      if (!value) {
        errors.value[`${index}.step`] = [__('This field is required')];

        const element = document.getElementById(`${index}.step`);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.company.update.recruitmentProcess'),
        values.value
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', response.data.data);
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }

  onMounted(() => {
    if (!values.value.length) {
      values.value.push('');
    }
  });
</script>
