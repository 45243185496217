<template>
  <AlertModal
    open
    :title="__('Are you sure you want to delete this volunteering?')"
    :description="volunteering ? volunteering.name : ''"
    :is-loading="isLoading"
    @cancel="handleCancelButtonClick"
    @continue="handleContinueButtonClick"
  />
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { AlertModal } from '@/components/other/alert-modal';

  const props = defineProps({
    volunteering: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['cancel', 'continue']);

  const toast = useToast();

  const isLoading = ref(false);

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleContinueButtonClick() {
    isLoading.value = true;

    try {
      const response = await axios.delete(
        route('redesign.cv.delete.volunteering', { id: props.volunteering.id })
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('continue', {
        volunteering: props.volunteering,
        fields: response.data.fields,
        sectionPercentage: response.data.section_percentage,
        resumePercentage: response.data.percentage
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }
</script>
