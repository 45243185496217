<template>
  <div v-if="isLoadingFetch" class="flex items-center gap-1.5 text-zinc-500">
    <Icon class="animate-spin text-xl" name="progress_activity" />
    <span>{{ __('Loading...') }}</span>
  </div>

  <div v-else class="space-y-6 md:space-y-8">
    <div class="flex gap-2 md:gap-4">
      <Icon
        class="text-[2rem] text-primary md:text-[2.5rem]"
        name="account_box"
        is-filled
      />
      <h1 class="text-2xl font-medium text-zinc-800 md:text-4xl">
        {{ __('First, tell us a little bit about yourself.') }}
      </h1>
    </div>

    <form class="space-y-8" @submit.prevent="handleSubmit">
      <div class="grid gap-4 md:grid-cols-2">
        <InputText
          v-if="fields.first_name.visible"
          id="first_name"
          v-model:value="fields.first_name.value"
          v-model:errors="fields.first_name.errors"
          :label="__('First name')"
          :placeholder="__('First name')"
          :required="fields.first_name.mandatory"
        />

        <InputText
          v-if="fields.last_name.visible"
          id="last_name"
          v-model:value="fields.last_name.value"
          v-model:errors="fields.last_name.errors"
          :label="__('Last name')"
          :placeholder="__('Last name')"
          :required="fields.last_name.mandatory"
        />

        <InputPhone
          v-if="fields.phone_prefix.visible && fields.phone_number.visible"
          v-model:value-phone-prefix="fields.phone_prefix.value"
          v-model:value-phone-number="fields.phone_number.value"
          v-model:errors-phone-prefix="fields.phone_prefix.errors"
          v-model:errors-phone-number="fields.phone_number.errors"
          :options="fields.phone_prefix.options"
          :required="
            fields.phone_prefix.mandatory && fields.phone_number.mandatory
          "
          class="md:col-span-2"
        />
      </div>

      <div class="flex items-center justify-between">
        <Button
          class="flex items-center gap-1.5"
          variant="ghost"
          @click="handleBackButtonClick"
        >
          <Icon class="text-lg" name="arrow_back_ios_new" />
          <span>{{ __('Go Back') }}</span>
        </Button>

        <Button class="ml-auto flex items-center gap-1.5" type="submit">
          <Icon
            v-if="isLoadingUpdate"
            class="animate-spin text-lg"
            name="progress_activity"
          />

          <span>{{ __('Next') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputText } from '@/components/form/input-text';
  import { InputPhone } from '@/components/form/input-phone';

  const emits = defineEmits(['back', 'next']);

  const toast = useToast();

  const fields = ref({
    first_name: {
      visible: false,
      mandatory: false,
      value: '',
      errors: []
    },
    last_name: {
      visible: false,
      mandatory: false,
      value: '',
      errors: []
    },
    phone_prefix: {
      optional: false,
      mandatory: false,
      value: null,
      errors: [],
      options: []
    },
    phone_number: {
      visible: false,
      mandatory: false,
      value: '',
      errors: []
    }
  });
  const isLoadingFetch = ref(false);
  const isLoadingUpdate = ref(false);

  function isValid(value) {
    if (!value) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'object') {
      for (const prop in value) {
        if (value[prop] === null) {
          return false;
        }
      }
      return true;
    }

    return true;
  }

  async function fetchData() {
    isLoadingFetch.value = true;

    try {
      const response = await axios.get(
        route('onboarding.step', { slug: 'introduction' })
      );

      Object.entries(response.data).forEach(([key, value]) => {
        if (fields.value[key]) {
          fields.value[key] = { ...fields.value[key], ...value };
        }
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingFetch.value = false;
  }

  function handleBackButtonClick() {
    emits('back');
  }

  async function handleSubmit() {
    const requiredFieldKeys = Object.keys(fields.value).filter(
      (key) => fields.value[key].visible && fields.value[key].mandatory
    );
    let firstInvalidField;

    requiredFieldKeys.forEach((key) => {
      if (!isValid(fields.value[key].value)) {
        fields.value[key].errors = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstInvalidField) {
          firstInvalidField = element;
        }
      }
    });

    if (
      Object.values(fields.value).some(
        (field) => field.errors && field.errors.length
      )
    ) {
      if (firstInvalidField) {
        firstInvalidField.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
      return;
    }

    const payload = Object.keys(fields.value).reduce((acc, key) => {
      if (fields.value[key].visible) {
        acc[key] = fields.value[key].value;
      }
      return acc;
    }, {});

    isLoadingUpdate.value = true;

    try {
      await axios.post(
        route('onboarding.step', { slug: 'introduction' }),
        payload
      );

      emits('next');
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).forEach((key) => {
          if (fields.value[key]) {
            fields.value[key].errors = error.response.data.errors[key];
          }
        });
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingUpdate.value = false;
  }

  onMounted(async () => {
    await fetchData();
  });
</script>
