<template>
  <tr class="border-y bg-white last:border-b-0">
    <td class="px-8 py-4">
      <div class="flex items-center gap-4">
        <img
          class="h-12 w-12 rounded-full border border-zinc-200"
          :src="profilePhoto"
          :alt="__('Profile picture')"
        />

        <div class="space-y-0.5">
          <span class="block text-sm font-medium text-zinc-800">
            {{ name }}
          </span>
          <span class="block text-sm text-zinc-600">{{ email }}</span>
        </div>
      </div>
    </td>

    <td class="px-8 py-4">
      <template v-if="isPresentEvent || isPastEvent || isRatedEvent">
        <div class="flex items-center gap-2">
          <template v-if="isCheckedIn">
            <Icon name="how_to_reg" is-filled class="text-xl text-success" />
            <span class="text-sm leading-normal text-zinc-800">
              {{ __('Checked In') }}
            </span>
          </template>

          <template v-else-if="isPastEvent || isRatedEvent">
            <Icon name="cancel" is-filled class="text-xl text-error" />
            <span class="text-sm leading-normal text-zinc-800">
              {{ __('Dropped') }}
            </span>
          </template>

          <template v-else>
            <Icon name="help" is-filled class="text-xl text-zinc-400" />
            <span class="text-sm leading-normal text-zinc-800">
              {{ __('Pending') }}
            </span>
          </template>
        </div>
      </template>

      <div v-else class="flex items-center gap-2">
        <Icon name="check_circle" is-filled class="text-xl text-success" />
        <span class="text-sm leading-normal text-zinc-800">
          {{ __('Registered') }}
        </span>
      </div>
    </td>
  </tr>
</template>

<script setup>
  import { computed } from 'vue';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    attendee: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['update:attendee']);

  const placeholderImage = '/images/logo_placeholder.png';

  const profilePhoto = computed(() => {
    return props.attendee.profile_photo
      ? `/uploads/${props.attendee.profile_photo}`
      : placeholderImage;
  });

  const name = computed(() => {
    return props.attendee.name;
  });

  const email = computed(() => {
    return props.attendee.email;
  });

  const isCheckedIn = computed(() => {
    return props.attendee.status === 1;
  });

  const isPresentEvent = computed(() => {
    return props.event.status === 'PRESENT';
  });

  const isPastEvent = computed(() => {
    return props.event.status === 'PAST';
  });

  const isRatedEvent = computed(() => {
    return props.event.status === 'RATED';
  });
</script>
