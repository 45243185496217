<template>
  <div>
    <div
      v-html="
        content.length > limit && !see_more
          ? content.slice(0, limit) + '...'
          : content
      "
      class="visual-editor"
    />

    <button
      @click="see_more = !see_more"
      v-if="content.length > limit"
      class="text-primary underline mt-4"
    >
      {{ see_more ? __("See less") : __("See more") }}
    </button>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { __ } from "../../helpers";
const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  limit: {
    type: Number,
    required: false,
    default: 600,
  },
});

const see_more = ref(false);
</script>

<!-- TODO: Stylize the content -->
