<template>
  <div class="shadow-card space-y-6 rounded-lg bg-white p-6 md:p-8">
    <div class="space-y-2">
      <span class="block text-lg font-medium leading-normal text-zinc-800">
        {{ __('Share the QR code') }}
      </span>
      <span class="block leading-normal text-zinc-600">
        {{ __('Allow the attendees to check in to your event.') }}
      </span>
    </div>

    <img :src="qrCode" :alt="__('QR code')" class="h-40 w-40" />

    <Button variant="outline" @click="handleDownloadButtonClick">
      {{ __('Download QR') }}
    </Button>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Button } from '@/components/block/button';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    }
  });

  const placeholderImage = '/images/logo_placeholder.png';

  const qrCode = computed(() => {
    return props.event.checkin.qr_code
      ? `/uploads/${props.event.checkin.qr_code}`
      : placeholderImage;
  });

  function handleDownloadButtonClick() {
    const baseUrl = window.location.origin;
    const imageUrl = `${baseUrl}${qrCode.value}`;
    const fileName = qrCode.value.split('/').pop();
    const link = document.createElement('a');

    link.href = imageUrl;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
</script>
