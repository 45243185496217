<template>
  <div>
    <SectionHeader :title="__('Active job openings')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="business_center" is-filled />
      </template>
    </SectionHeader>

    <div
      v-if="canEdit"
      class="shadow-card mt-8 space-y-5 rounded-2xl bg-white p-6 md:p-8"
    >
      <InputToggle
        id="show_job_openings"
        v-model:errors="errors.show_job_openings"
        :value="values.show_job_openings"
        :is-loading="isLoading"
        variant="secondary"
        size="large"
        @update:value="handleSubmit"
      >
        <template #label-start>
          <span class="block text-lg font-medium leading-normal text-zinc-800">
            {{ __('Showcase the most recent jobs') }}
          </span>
        </template>
      </InputToggle>
    </div>

    <template v-if="values.show_job_openings && data.jobs && data.jobs.length">
      <div class="mt-4 space-y-4">
        <a
          v-for="job in data.jobs"
          :key="job.id"
          class="block"
          :href="job.url"
          target="_blank"
        >
          <JobCard
            class="mt-0"
            :job="job"
            :user_type="userType"
            :config_general_single_company="configGeneralSingleCompany"
          />
        </a>
      </div>

      <Button
        class="mx-auto mt-8 flex w-fit items-center gap-2.5"
        :href="route('redesign.jobs.index')"
        target="_blank"
      >
        <Icon class="text-lg" name="open_in_new" />
        <span>{{ __('Go to job listing') }}</span>
      </Button>
    </template>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputToggle } from '@/components/form/input-toggle';
  import { SectionHeader } from '@/pages/components/section-header';
  import { JobCard } from '@/pages/candidate/jobs/components';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    userType: {
      type: String,
      required: true
    },
    configGeneralSingleCompany: {
      type: Number,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    }
  });

  const toast = useToast();

  const data = ref(JSON.parse(JSON.stringify(props.data)));
  const values = ref({ show_job_openings: data.value.show_job_openings });
  const errors = ref({ show_job_openings: [] });
  const isLoading = ref(false);

  async function handleSubmit() {
    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.company.jobToggle'),
        values.value.show_job_openings
      );

      // TODO: data.value.show_job_openings = response.data.data.show_job_openings;
      values.value.show_job_openings = !values.value.show_job_openings;

      if (response?.data?.message) {
        toast.success(response.data.message);
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
