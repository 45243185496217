<template>
  <div class="shadow-card rounded-2xl bg-white p-6 md:p-8">
    <EmptySection v-if="!data.length" :title="__('Add testimonials')">
      <template #icon>
        <Icon class="text-xl text-zinc-200" name="rate_review" is-filled />
      </template>
    </EmptySection>

    <div
      v-else
      class="mx-auto flex items-center justify-between gap-6 md:max-w-[50rem] md:gap-8"
    >
      <button
        class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
        :disabled="isPreviousButtonDisabled"
        :aria-label="__('Previous')"
        @click="handlePreviousButtonClick"
      >
        <Icon class="text-xl text-primary" name="arrow_back" />
      </button>

      <ul
        ref="scrollContainer"
        class="flex w-full snap-x snap-mandatory gap-6 overflow-x-auto overflow-y-hidden md:max-w-[30rem] md:gap-8"
      >
        <li
          v-for="item in data"
          :key="item.id"
          class="min-w-full snap-start p-1"
        >
          <figure class="flex h-full flex-col">
            <div class="relative flex-1 py-10">
              <svg
                class="absolute left-0 top-0"
                width="93"
                height="64"
                viewBox="0 0 93 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="fill-secondary/5"
                  d="M52.2449 39.1837C52.2449 32.3918 53.2898 26.5578 55.3796 21.6816C57.4694 16.6313 60.1687 12.5388 63.4776 9.40409C66.7864 6.09524 70.5306 3.74422 74.7102 2.35102C79.064 0.783674 83.3306 0 87.5102 0V9.14286C82.9823 9.14286 78.7157 10.7102 74.7102 13.8449C70.8789 16.8054 68.615 20.898 67.9184 26.1225C68.4408 25.9483 69.0503 25.7742 69.7469 25.6C70.2694 25.4258 70.8789 25.2517 71.5755 25.0776C72.4463 24.9034 73.4041 24.8163 74.449 24.8163C79.6735 24.8163 84.0272 26.8191 87.5102 30.8245C90.9932 34.6558 92.7347 39.1837 92.7347 44.4082C92.7347 49.6327 90.9061 54.2476 87.249 58.2531C83.766 62.0844 79.0639 64 73.1429 64C66.5252 64 61.3878 61.5619 57.7306 56.6857C54.0735 51.6354 52.2449 45.8014 52.2449 39.1837ZM0 39.1837C0 32.3918 1.0449 26.5578 3.13469 21.6816C5.22449 16.6313 7.92381 12.5388 11.2327 9.40409C14.5415 6.09524 18.2857 3.74422 22.4653 2.35102C26.819 0.783674 31.0857 0 35.2653 0V9.14286C30.7374 9.14286 26.4707 10.7102 22.4653 13.8449C18.634 16.8054 16.3701 20.898 15.6735 26.1225C16.1959 25.9483 16.8054 25.7742 17.502 25.6C18.0245 25.4258 18.634 25.2517 19.3306 25.0776C20.2014 24.9034 21.1592 24.8163 22.2041 24.8163C27.4286 24.8163 31.7823 26.8191 35.2653 30.8245C38.7483 34.6558 40.4898 39.1837 40.4898 44.4082C40.4898 49.6327 38.6612 54.2476 35.0041 58.2531C31.5211 62.0844 26.819 64 20.898 64C14.2803 64 9.14286 61.5619 5.48571 56.6857C1.82857 51.6354 0 45.8014 0 39.1837Z"
                />
              </svg>

              <blockquote
                class="text-center font-medium leading-normal text-zinc-600"
              >
                {{ item.testimonial }}
              </blockquote>

              <svg
                class="absolute bottom-0 right-0"
                width="93"
                height="64"
                viewBox="0 0 93 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="fill-secondary/5"
                  d="M40.4895 24.8163C40.4895 31.6082 39.4446 37.4422 37.3548 42.3184C35.265 47.3687 32.5657 51.4612 29.2568 54.5959C25.948 57.9048 22.2038 60.2558 18.0242 61.649C13.6704 63.2163 9.40375 64 5.22416 64L5.22416 54.8571C9.75205 54.8571 14.0187 53.2898 18.0242 50.1551C21.8555 47.1946 24.1194 43.102 24.816 37.8776C24.2935 38.0517 23.684 38.2259 22.9874 38.4C22.465 38.5742 21.8555 38.7483 21.1589 38.9225C20.2881 39.0966 19.3303 39.1837 18.2854 39.1837C13.0609 39.1837 8.70715 37.181 5.22416 33.1755C1.74116 29.3442 -0.00032386 24.8163 -0.000324317 19.5918C-0.000324774 14.3674 1.82824 9.75239 5.48539 5.74694C8.96838 1.91565 13.6704 6.91199e-06 19.5915 6.39435e-06C26.2092 5.81582e-06 31.3466 2.4381 35.0037 7.31429C38.6609 12.3646 40.4895 18.1986 40.4895 24.8163ZM92.7344 24.8163C92.7344 31.6082 91.6895 37.4422 89.5997 42.3184C87.5099 47.3687 84.8106 51.4612 81.5017 54.5959C78.1929 57.9048 74.4487 60.2558 70.2691 61.649C65.9153 63.2163 61.6487 64 57.4691 64L57.4691 54.8571C61.997 54.8571 66.2636 53.2898 70.2691 50.1551C74.1004 47.1946 76.3643 43.102 77.0609 37.8775C76.5385 38.0517 75.9289 38.2258 75.2323 38.4C74.7099 38.5742 74.1004 38.7483 73.4038 38.9224C72.533 39.0966 71.5752 39.1837 70.5303 39.1837C65.3058 39.1837 60.9521 37.1809 57.4691 33.1755C53.9861 29.3442 52.2446 24.8163 52.2446 19.5918C52.2446 14.3674 54.0731 9.75239 57.7303 5.74694C61.2133 1.91565 65.9153 2.3446e-06 71.8364 1.82696e-06C78.4541 1.24842e-06 83.5915 2.4381 87.2487 7.31429C90.9058 12.3646 92.7344 18.1986 92.7344 24.8163Z"
                />
              </svg>
            </div>

            <figcaption>
              <img
                class="mx-auto h-14 w-14 rounded"
                :src="
                  item.company_logo
                    ? item.company_logo.includes('uploads')
                      ? item.company_logo
                      : `/uploads/${item.company_logo}`
                    : placeholderImage
                "
                :alt="__('Profile picture')"
              />
              <div
                class="mt-4 flex flex-wrap items-center justify-center gap-2"
              >
                <span class="font-medium text-zinc-800">{{ item.name }}</span>
                <span class="text-primary">•</span>
                <span class="text-zinc-800">{{ item.role }}</span>
              </div>
            </figcaption>
          </figure>
        </li>
      </ul>

      <button
        class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
        :disabled="isNextButtonDisabled"
        :aria-label="__('Next')"
        @click="handleNextButtonClick"
      >
        <Icon class="text-xl text-primary" name="arrow_forward" />
      </button>
    </div>
  </div>
</template>

<script setup>
  import { onBeforeUnmount, onMounted, ref } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { EmptySection } from '@/pages/components/empty-section';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    }
  });

  const placeholderImage = '/images/logo_placeholder.png';

  const scrollContainer = ref(null);
  const isPreviousButtonDisabled = ref(false);
  const isNextButtonDisabled = ref(false);

  function handlePreviousButtonClick() {
    if (!scrollContainer.value) {
      return;
    }

    scrollContainer.value.scrollBy({
      left: -scrollContainer.value.clientWidth,
      behavior: 'smooth'
    });
  }

  function handleNextButtonClick() {
    if (!scrollContainer.value) {
      return;
    }

    scrollContainer.value.scrollBy({
      left: scrollContainer.value.offsetWidth,
      behavior: 'smooth'
    });
  }

  function handlescrollContainerScrollEvent() {
    if (!scrollContainer.value) {
      return;
    }

    isPreviousButtonDisabled.value = scrollContainer.value.scrollLeft <= 0;
    isNextButtonDisabled.value =
      scrollContainer.value.scrollLeft >=
      scrollContainer.value.scrollWidth - scrollContainer.value.clientWidth;
  }

  onMounted(() => {
    if (!scrollContainer.value) {
      return;
    }

    scrollContainer.value.addEventListener(
      'scroll',
      handlescrollContainerScrollEvent
    );

    isPreviousButtonDisabled.value = scrollContainer.value.scrollLeft <= 0;
    isNextButtonDisabled.value =
      scrollContainer.value.scrollLeft >=
      scrollContainer.value.scrollWidth - scrollContainer.value.clientWidth;
  });

  onBeforeUnmount(() => {
    if (!scrollContainer.value) {
      return;
    }

    scrollContainer.value.removeEventListener(
      'scroll',
      handlescrollContainerScrollEvent
    );
  });
</script>
