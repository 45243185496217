<template>
  <div class="mt-8">
    <!-- saved -->
    <div>
      <h2 class="text-2xl text-[#212429] font-medium mb-4">
        {{ __("Saved Jobs") }}
        <span class="text-white bg-primary rounded-full text-xs px-2 py-1">{{
          saved.length
        }}</span>
      </h2>

      <div>
        <div v-if="saved.length != 0" class="grid md:grid-cols-3 gap-4">
          <div
            v-for="item in saved"
            :key="item.id"
            class="rounded-app shadow-md flex flex-col justify-between bg-white"
          >
            <div
              class="bg-white rounded-t-app p-4 cursor-pointer relative"
              @click="openModal(item.job)"
            >
              <span
                class="absolute bottom-2 right-0 text-xs bg-secondary text-white pl-4 pr-2 py-1 rounded-l-app"
                v-if="item.new"
                >{{ __("NEW") }}</span
              >
              <div class="flex justify-between">
                <div
                  class="flex gap-2 items-center"
                  v-if="!config_general_single_company"
                >
                  <img
                    :src="item.job.company.logo"
                    alt="Company logo"
                    class="w-12 h-12 rounded-full"
                  />
                  <div>
                    <h3 class="text-sm text-[#212429]">
                      {{ item.job.company.name }}
                    </h3>
                    <p class="text-xs text-[#58585E]">
                      {{ item.job.company.domain }}
                    </p>
                  </div>
                </div>
                <div v-else>
                  <SingleCompanyIcon />
                </div>
              </div>

              <div>
                <h3
                  class="text-lg my-2"
                  :class="item.job.active ? 'text-[#52525B]' : 'text-zinc-300'"
                >
                  {{ item.job.title }}
                </h3>
              </div>
            </div>
            <div class="p-4 bg-zinc-50 rounded-b-app" v-if="item.job.active">
              <div class="text-xs text-[#52525B]">
                <div class="!text-xs flex gap-2 items-center text-[#52525B]">
                  <Icon name="clock_loader_10" class="!text-sm" />
                  <span class=""> {{ __("Saved") }} {{ item.saved_ago }}</span>
                </div>
              </div>
            </div>
            <div class="p-4 bg-gray-200 rounded-b-app" v-else>
              <div class="text-xs text-[#52525B]">
                {{ __("This job is inactive at the moment") }}
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <p class="text-[#212429] text-base">
            {{ __("You have no saved jobs yet.") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  Button,
  Icon,
  SingleCompanyIcon,
} from "../../../../../components/global";
import { __ } from "../../../../../helpers";

const props = defineProps({
  saved: {
    type: Array,
    required: true,
  },
  config_general_single_company: {
    type: Number,
    required: true,
  },
});

console.log(props.saved);

const emit = defineEmits(["openModal"]);

const openModal = (job) => {
  emit("openModal", job);
};
</script>
