<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell sm:pl-4">
    <a
      :href="props.columnData.link"
      target="_blank"
      class="text-black hover:text-brandColor1"
      @click.prevent="handleOpenResumeModal"
    >
      <span class="font-bold">{{ props.candidateIndex + 1 }}. </span>
      {{ props.columnData.name }}
    </a>
  </td>
</template>

<script setup>
  const props = defineProps({
    candidateIndex: Number,
    columnData: [Object, Array, String, Number],
    person: Object,
    people: Array,
    total: Number
  });

  const emits = defineEmits(['open-resume-modal']);

  function handleOpenResumeModal() {
    emits('open-resume-modal', {
      username: props.person.NameAnonymousSeen.link.split('/').slice(-2, -1)[0],
      usernameList: props.people.map(
        (item) => item.NameAnonymousSeen.link.split('/').slice(-2, -1)[0]
      ),
      applicationId: props.person.id
    });
  }
</script>
