<template>
  <div class="space-y-12">
    <h1 class="text-5xl font-medium text-zinc-800">
      {{ __('Groups') }}
    </h1>

    <div class="space-y-4">
      <div class="grid gap-2 md:grid-cols-2 md:gap-4">
        <InputSelect
          :value="values.city"
          :placeholder="__('Location')"
          :options="options.city"
          @update:value="handleUpdateCityValue"
        >
          <template #icon>
            <Icon class="text-lg text-neutral-400" name="distance" is-filled />
          </template>
        </InputSelect>

        <InputSelect
          :value="values.search"
          :placeholder="__('Name')"
          :options="options.search"
          @update:value="handleUpdateSearchValue"
        >
          <template #icon>
            <Icon class="text-lg text-neutral-400" name="search" />
          </template>
        </InputSelect>
      </div>

      <div class="shadow-card rounded-lg bg-white p-6 md:p-8">
        <span v-if="!groups.length" class="text-zinc-600">
          {{ __('No groups found') }}
        </span>

        <div v-else class="space-y-8">
          <Group v-for="group in groups" :key="group.id" :group="group" />

          <div v-if="renderPagination" class="flex justify-center gap-2">
            <button
              class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
              :disabled="isPreviousButtonDisabled"
              :aria-label="__('Previous')"
              @click="handlePreviousPageButtonClick"
            >
              <Icon class="text-xl text-primary" name="arrow_back" />
            </button>

            <button
              class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
              :disabled="isNextButtonDisabled"
              :aria-label="__('Next')"
              @click="handleNextPageButtonClick"
            >
              <Icon class="text-xl text-primary" name="arrow_forward" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { InputSelect } from '@/components/form/input-select';
  import { Group } from './components/shared/group';

  const props = defineProps({
    groups: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    }
  });

  const values = computed(() => {
    return {
      city: props.filters.selected?.city || null,
      search: props.filters.selected?.search || null
    };
  });

  const options = computed(() => {
    return props.filters.options;
  });

  const page = computed(() => {
    return props.pagination.page;
  });

  const total = computed(() => {
    return props.pagination.total;
  });

  const renderPagination = computed(() => {
    return total.value > 1;
  });

  const isPreviousButtonDisabled = computed(() => {
    return page.value <= 1;
  });

  const isNextButtonDisabled = computed(() => {
    return page.value >= total.value;
  });

  function updateQueryParams(newParams) {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);

    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === null) {
        params.delete(key);
      } else {
        params.set(key, newParams[key]);
      }
    });

    url.search = params.toString();
    window.location = url.toString();
  }

  function handleUpdateCityValue(value) {
    const newParams = { city: value ? value.id : null };
    updateQueryParams(newParams);
  }

  function handleUpdateSearchValue(value) {
    const newParams = { search: value ? value.id : null };
    updateQueryParams(newParams);
  }

  function handlePreviousPageButtonClick() {
    const newParams = { page: page.value - 1 };
    updateQueryParams(newParams);
  }

  function handleNextPageButtonClick() {
    const newParams = { page: page.value + 1 };
    updateQueryParams(newParams);
  }
</script>
