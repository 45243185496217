<template>
  <a v-if="href" :class="className" :href="href" :target="target">
    <slot />
  </a>

  <button v-else :class="className" :type="type">
    <slot />
  </button>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    variant: {
      type: String,
      default: 'fill',
      validator: (value) => ['fill', 'outline', 'ghost', 'icon'].includes(value)
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) => ['button', 'submit'].includes(value)
    },
    href: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    }
  });

  const className = computed(() => {
    return `${variantClass.value} flex w-fit transition-colors rounded-md text-sm font-medium disabled:pointer-events-none disabled:opacity-50`;
  });

  const variantClass = computed(() => {
    switch (props.variant) {
      case 'fill': {
        return 'px-4 py-2 bg-primary text-white hover:bg-primary/90';
      }
      case 'outline': {
        return 'px-4 py-2 text-zinc-800 ring-1 ring-inset ring-primary hover:bg-primary/5';
      }
      case 'ghost': {
        return 'px-4 py-2 text-primary hover:bg-primary/5';
      }
      case 'icon': {
        return 'flex text-primary hover:bg-primary/5 p-2';
      }
      default: {
        return '';
      }
    }
  });
</script>
