<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Achievements')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="award_star" is-filled />
      </template>
      <template v-if="canEdit" #button>
        <div class="ml-auto">
          <Button
            class="flex items-center gap-1.5"
            variant="ghost"
            @click="handleAddButtonClick"
          >
            <Icon class="text-lg" name="add_box" is-filled />
            <span>{{ __('Add achievement') }}</span>
          </Button>
        </div>
      </template>
    </SectionHeader>

    <div
      class="shadow-card relative overflow-hidden rounded-2xl bg-white p-6 md:p-8"
    >
      <div
        v-if="progress"
        class="absolute right-0 top-0 rounded-l-full bg-secondary px-3 py-1"
      >
        <span class="block text-xs leading-none text-white">
          {{ progress }}
        </span>
      </div>

      <EmptySection
        v-if="!achievements.length"
        :title="__('Add your achievements')"
      >
        <template #icon>
          <Icon class="text-xl text-zinc-200" name="award_star" is-filled />
        </template>
      </EmptySection>

      <div v-else class="space-y-12">
        <div
          v-for="group in isListExpanded ? groups : groups.slice(0, 3)"
          :key="group.type.id"
          class="space-y-6"
        >
          <div class="flex items-center gap-2">
            <Icon class="text-2xl text-primary" :name="group.icon" is-filled />

            <h3 class="text-2xl font-medium text-zinc-800">
              {{ group.type.name }}
            </h3>
          </div>

          <Achievement
            v-for="achievement in group.achievements"
            :key="achievement.id"
            :achievement="achievement"
            :can-edit="canEdit"
            @edit="handleEditButtonClick(achievement)"
            @delete="handleDeleteButtonClick(achievement)"
          />
        </div>

        <Button
          v-if="groups.length > 3"
          class="mx-auto"
          variant="outline"
          @click="handleExpandListButtonClick"
        >
          {{ __(isListExpanded ? 'See less' : 'See more') }}
        </Button>
      </div>

      <AchievementFormModal
        v-if="isFormModalOpen"
        :achievement="selected"
        :options="options"
        @cancel="handleCancelButtonClick"
        @save="handleSaveButtonClick"
      />

      <AchievementDeleteModal
        v-if="isDeleteModalOpen"
        :achievement="selected"
        @cancel="handleCancelButtonClick"
        @continue="handleContinueButtonClick"
      />
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { EmptySection } from '@/pages/components/empty-section';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Achievement } from './components/achievement';
  import { AchievementDeleteModal } from './components/achievement-delete-modal';
  import { AchievementFormModal } from './components/achievement-form-modal';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['update-is-editing', 'update-percentages']);

  const progress = computed(() => {
    return fields.value.total
      ? `${fields.value.completed}/${fields.value.total}`
      : '';
  });

  const groups = computed(() => {
    const icons = {
      1: 'book',
      2: 'redeem',
      3: 'local_activity',
      4: 'military_tech',
      5: 'developer_guide',
      6: 'check_box'
    };

    let groups = {};

    achievements.value.forEach((achievement) => {
      const typeId = achievement.type.id;

      if (!groups[typeId]) {
        groups[typeId] = {
          type: achievement.type,
          achievements: [],
          icon: icons[typeId]
        };
      }

      groups[typeId].achievements.push({
        id: achievement.id,
        name: achievement.name,
        organization: achievement.organization,
        link: achievement.link,
        description: achievement.description,
        current: achievement.current,
        type: achievement.type,
        start_date: achievement.start_date,
        end_date: achievement.end_date
      });
    });

    let result = [];

    for (let typeId in groups) {
      result.push(groups[typeId]);
    }

    return result;
  });

  const fields = ref(JSON.parse(JSON.stringify(props.fields)));
  const achievements = ref(JSON.parse(JSON.stringify(props.data)));
  const selected = ref(null);
  const isFormModalOpen = ref(false);
  const isDeleteModalOpen = ref(false);
  const pendingAction = ref('');
  const isListExpanded = ref(false);

  function handleExpandListButtonClick() {
    isListExpanded.value = !isListExpanded.value;
  }

  function handleAddButtonClick() {
    selected.value = null;
    pendingAction.value = 'add';

    emits('update-is-editing', true);
  }

  function handleEditButtonClick(achievement) {
    selected.value = JSON.parse(JSON.stringify(achievement));
    pendingAction.value = 'edit';
    console.log('xxx');
    console.log(selected.value);
    emits('update-is-editing', true);
  }

  function handleDeleteButtonClick(achievement) {
    selected.value = JSON.parse(JSON.stringify(achievement));
    pendingAction.value = 'delete';

    emits('update-is-editing', true);
  }

  function handleCancelButtonClick() {
    emits('update-is-editing', false);
  }

  function handleSaveButtonClick(data) {
    const existingAchievement = achievements.value.find(
      (achievement) => achievement.id === data.achievement.id
    );

    if (existingAchievement) {
      achievements.value = achievements.value.map((achievement) => {
        if (achievement.id === data.achievement.id) {
          return data.achievement;
        }

        return achievement;
      });
    } else {
      achievements.value = [data.achievement, ...achievements.value];
    }

    fields.value = data.fields;

    emits('update-is-editing', false);
    emits('update-percentages', {
      sectionPercentage: data.sectionPercentage,
      resumePercentage: data.resumePercentage
    });
  }

  function handleContinueButtonClick(data) {
    achievements.value = achievements.value.filter(
      (achievement) => achievement.id !== data.achievement.id
    );

    fields.value = data.fields;

    emits('update-is-editing', false);
    emits('update-percentages', {
      sectionPercentage: data.sectionPercentage,
      resumePercentage: data.resumePercentage
    });
  }

  watch(
    () => props.isEditing,
    (value) => {
      if (pendingAction.value === 'add' || pendingAction.value === 'edit') {
        isFormModalOpen.value = value;
      } else if (pendingAction.value === 'delete') {
        isDeleteModalOpen.value = value;
      }
    }
  );
</script>
