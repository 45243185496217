<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Address')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="location_on" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
      <form @submit.prevent="handleSubmit">
        <div class="space-y-4">
          <div class="grid gap-4 md:grid-cols-2">
            <InputText
              id="address"
              v-model:value="values.address"
              v-model:errors="errors.address"
              :label="__('Address')"
              :placeholder="__('Add address')"
              required
            />
            <InputText
              id="address"
              v-model:value="values.detailed_address"
              v-model:errors="errors.detailed_address"
              :label="__('Detailed Address')"
              :placeholder="__('Detailed Address')"
            />
            <InputLocation
              id="location"
              v-model:value="values.location"
              v-model:errors="errors.location"
              :label-country="__('Country of Residence')"
              :label-city="__('City of Residence')"
              :placeholder-country="__('Add country')"
              :placeholder-city="__('Add city')"
              :options="options.country"
              class="col-span-1 sm:col-span-2"
              required
            />
            <div class="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5">
              <InputSelect
                id="country_prefix"
                v-model:value="values.country_prefix"
                v-model:errors="errors.country_prefix"
                :label="__('Country prefix')"
                :placeholder="__('Country prefix')"
                :options="options.country_prefix"
                class="col-span-1 sm:col-span-2"
                required
              />

              <InputText
                id="phone_number"
                v-model:value="values.phone_number"
                v-model:errors="errors.phone_number"
                class="col-span-1 sm:col-span-3"
                :label="__('Phone Number')"
                :placeholder="__('754 782 082')"
                required
              >
                <template #icon>
                  <Icon
                    class="text-lg text-neutral-400"
                    name="phone"
                    is-filled
                  />
                </template>
              </InputText>
            </div>
            <InputText
              id="address"
              v-model:value="values.postal_code"
              v-model:errors="errors.postal_code"
              :label="__('Postal Code')"
              :placeholder="__('Postal Code')"
              required
            />
          </div>
        </div>

        <div
          class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
        >
          <Button
            class="flex items-center justify-center gap-1.5"
            :type="isLoading ? 'button' : 'submit'"
            :disabled="isLoading"
          >
            <Icon
              v-if="isLoading"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span>{{ __('Save') }}</span>
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '../../../../helpers';
  import { Icon } from '../../../../components/block/icon';
  import { InputSelect } from '../../../../components/form/input-select';
  import { InputText } from '../../../../components/form/input-text';
  import { SectionHeader } from '../../../components/section-header';
  import { Button } from '../../../../components/block/button';
  import { InputLocation } from '../../../../components/form/input-location';
  import axios from 'axios';

  const props = defineProps({
    profile: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  });

  const isLoading = ref(false);

  const emits = defineEmits(['cancel']);

  const toast = useToast();

  const values = ref({
    country_prefix: props.profile.country_prefix || null,
    phone_number: props.profile.phone || '',
    location: props.profile.location || null,
    address: props.profile.address || '',
    detailed_address: props.profile.detailed_address,
    postal_code: props.profile.postal_code
  });
  const errors = ref({
    country_prefix: [],
    phone_number: [],
    location: [],
    address: [],
    postal_code: [],
    detailed_address: []
  });

  async function handleSubmit() {
    const requiredFieldKeys = [
      'country_prefix',
      'phone_number',
      'location',
      'address',
      'postal_code'
    ];
    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (
      values.value.location &&
      values.value.location.country &&
      !values.value.location.city
    ) {
      errors.value.location = [__('This field is required')];

      const element = document.getElementById('location');

      if (element && !firstElement) {
        firstElement = element;
      }
    }

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    try {
      const response = await axios.post(
        route('redesign.candidate.settings.updateAddress'),
        values.value
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }
    isLoading.value = false;
  }
</script>
