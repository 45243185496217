<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell">
    <div class="flex justify-center gap-1.5">
      <template v-for="(value, key) in columnData" :key="key">
        <a v-if="key === 'invite'" @click.prevent="onInviteToJob(value['action']['jobId'], value['action']['userId'], candidateIndex)" href="#" class="block rounded-md bg-brandColor1 px-2 py-1 text-center text-xs text-white shadow-redesign hover:bg-brandColor1/[.80]">{{ value['label'] }}</a>
        <p v-else-if="key === 'rejected_at'"><strong>{{ value['label'] }}: </strong> {{ value['action'] }}</p>
        <p v-else>{{ value['label'] }}</p>
      </template>
    </div>
  </td>
</template>

<script setup>

import axios from "axios";
import { useToast } from "vue-toastification";
import { inject } from 'vue';

const toast = useToast();
const updateCounters = inject('updateCounters');
const updateComponentTotal = inject('updateComponentTotal');

const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});

const onInviteToJob = (jobId, userId, candidateIndex) => {
  axios.post(route('redesign.matching.inviteToJob'), {
    job_id: jobId,
    user_id: userId
  }).then((response) => {
    props.people.splice(candidateIndex, 1)
    updateCounters();
    updateComponentTotal(props.total - 1)
    toast.success(response.data)
  }).catch((err) => {
    toast.error(err.response.data);
  });
}


</script>
