<template>
  <div>
    <h1 class="text-5xl font-medium leading-none text-zinc-800">
      {{ __('Company profile') }}
    </h1>

    <div class="mt-16 space-y-12">
      <Branding
        :data="companyProfile.branding.data"
        :options="companyProfile.branding.options"
        :error-message="companyProfile.branding.errorMessage"
        :can-edit="companyProfile.branding.canEdit"
        :is-editing="companyProfile.branding.isEditing"
        @update-is-editing="(value) => handleUpdateIsEditing('branding', value)"
      />
      <About
        :data="companyProfile.about.data"
        :options="companyProfile.about.options"
        :error-message="companyProfile.about.errorMessage"
        :can-edit="companyProfile.about.canEdit"
        :is-editing="companyProfile.about.isEditing"
        @update-is-editing="(value) => handleUpdateIsEditing('about', value)"
      />
      <Team
        v-if="companyProfile.team.canEdit || companyProfile.team.data.length"
        :data="companyProfile.team.data"
        :error-message="companyProfile.team.errorMessage"
        :can-edit="companyProfile.team.canEdit"
        :is-editing="companyProfile.team.isEditing"
        @update-is-editing="(value) => handleUpdateIsEditing('team', value)"
      />
      <Testimonials
        v-if="
          companyProfile.testimonials.canEdit ||
          companyProfile.testimonials.data.length
        "
        :data="companyProfile.testimonials.data"
        :error-message="companyProfile.testimonials.errorMessage"
        :can-edit="companyProfile.testimonials.canEdit"
        :is-editing="companyProfile.testimonials.isEditing"
        @update-is-editing="
          (value) => handleUpdateIsEditing('testimonials', value)
        "
      />
      <RecruitmentProcess
        v-if="
          companyProfile.recruitment_process.canEdit ||
          companyProfile.recruitment_process.data.length
        "
        :data="companyProfile.recruitment_process.data"
        :error-message="companyProfile.recruitment_process.errorMessage"
        :can-edit="companyProfile.recruitment_process.canEdit"
        :is-editing="companyProfile.recruitment_process.isEditing"
        @update-is-editing="
          (value) => handleUpdateIsEditing('recruitment_process', value)
        "
      />
      <Faq
        v-if="companyProfile.faq.canEdit || companyProfile.faq.data.length"
        :data="companyProfile.faq.data"
        :error-message="companyProfile.faq.errorMessage"
        :can-edit="companyProfile.faq.canEdit"
        :is-editing="companyProfile.faq.isEditing"
        @update-is-editing="(value) => handleUpdateIsEditing('faq', value)"
      />
      <ActiveJobs
        v-if="
          companyProfile.active_jobs.canEdit ||
          companyProfile.active_jobs.data.length
        "
        :data="companyProfile.active_jobs.data"
        :user-type="companyProfile.active_jobs.userType"
        :config-general-single-company="
          companyProfile.active_jobs.configGeneralSingleCompany
        "
        :can-edit="companyProfile.active_jobs.canEdit"
      />
      <Media
        v-if="companyProfile.media.canEdit || companyProfile.media.data.length"
        :data="companyProfile.media.data"
        :error-message="companyProfile.media.errorMessage"
        :can-edit="companyProfile.media.canEdit"
        :is-editing="companyProfile.media.isEditing"
        @update-is-editing="(value) => handleUpdateIsEditing('media', value)"
      />
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { __ } from '@/helpers';
  import { About } from './components/about';
  import { ActiveJobs } from './components/active-jobs';
  import { Branding } from './components/branding';
  import { Faq } from './components/faq';
  import { Media } from './components/media';
  import { RecruitmentProcess } from './components/recruitment-process';
  import { Team } from './components/team';
  import { Testimonials } from './components/testimonials';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    userType: {
      type: String,
      required: true
    },
    configGeneralSingleCompany: {
      type: Number,
      required: true
    }
  });

  const companyProfile = ref({
    branding: {
      data: props.data.branding.company,
      options: props.data.branding.options,
      errorMessage: '',
      canEdit: props.canEdit,
      isEditing: false
    },
    about: {
      data: props.data.about.company,
      options: props.data.about.options,
      errorMessage: '',
      canEdit: props.canEdit,
      isEditing: false
    },
    team: {
      data: props.data.team.company,
      errorMessage: '',
      canEdit: props.canEdit,
      isEditing: false
    },
    testimonials: {
      data: props.data.testimonials.company,
      errorMessage: '',
      canEdit: props.canEdit,
      isEditing: false
    },
    recruitment_process: {
      data: props.data.recruitment_process.company,
      errorMessage: '',
      canEdit: props.canEdit,
      isEditing: false
    },
    faq: {
      data: props.data.faq.company,
      errorMessage: '',
      canEdit: props.canEdit,
      isEditing: false
    },
    active_jobs: {
      data: props.data.active_jobs.company,
      userType: props.userType,
      configGeneralSingleCompany: props.configGeneralSingleCompany,
      canEdit: props.canEdit
    },
    media: {
      data: props.data.media.company,
      errorMessage: '',
      canEdit: props.canEdit,
      isEditing: false
    }
  });

  async function handleUpdateIsEditing(key, value) {
    const editingSectionKey = Object.keys(companyProfile.value).find(
      (key) => companyProfile.value[key].isEditing
    );

    if (value && editingSectionKey) {
      companyProfile.value[editingSectionKey].errorMessage = __(
        'Please save or cancel your changes before editing another section.'
      );

      const element = document.querySelector('button[type="submit"]');

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }

      return;
    }

    if (!value) {
      Object.keys(companyProfile.value).forEach((key) => {
        companyProfile.value[key].errorMessage = '';
      });
    }

    companyProfile.value[key].isEditing = value;
  }
</script>
