<template>
  <div
    class="shadow-card relative overflow-hidden rounded-2xl bg-white p-6 md:p-8"
  >
    <div
      v-if="progress"
      class="absolute right-0 top-0 rounded-l-full bg-secondary px-3 py-1"
    >
      <span class="block text-xs leading-none text-white">{{ progress }}</span>
    </div>

    <div class="grid gap-4 md:grid-cols-2 md:gap-x-16">
      <div class="flex flex-wrap justify-between gap-1 md:col-span-2">
        <span class="leading-normal text-zinc-600">
          {{ __('I want to work in') }}
        </span>

        <span
          v-if="!locations.length"
          class="font-medium leading-normal text-zinc-800"
        >
          -
        </span>

        <div
          v-else
          class="flex flex-wrap gap-x-2 gap-y-1 divide-x-2 divide-primary"
        >
          <span
            v-for="location in locations"
            :key="location"
            class="pl-2 font-medium leading-normal text-zinc-800 first:!pl-0"
          >
            {{ location }}
          </span>
        </div>
      </div>

      <div class="space-y-4">
        <div class="flex flex-wrap justify-between gap-1">
          <span class="leading-normal text-zinc-600">
            {{ __('I require a visa to work in') }}
          </span>
          <span class="font-medium leading-normal text-zinc-800">
            {{ visa }}
          </span>
        </div>

        <div class="flex flex-wrap justify-between gap-1">
          <span class="leading-normal text-zinc-600">
            {{ __('Employment type') }}
          </span>
          <span class="font-medium leading-normal text-zinc-800">
            {{ employmentType }}
          </span>
        </div>

        <div class="flex flex-wrap justify-between gap-1">
          <span class="leading-normal text-zinc-600">
            {{ __('Work type') }}
          </span>
          <span class="font-medium leading-normal text-zinc-800">
            {{ workType }}
          </span>
        </div>

        <div class="flex flex-wrap justify-between gap-1">
          <span class="leading-normal text-zinc-600">
            {{ __('Desired job') }}
          </span>
          <span class="font-medium leading-normal text-zinc-800">
            {{ desiredJob }}
          </span>
        </div>
      </div>

      <div class="space-y-4">
        <div class="flex flex-wrap justify-between gap-1">
          <span class="leading-normal text-zinc-600">
            {{ __('Desired wage') }}
          </span>
          <span class="font-medium leading-normal text-zinc-800">
            {{ wage }}
          </span>
        </div>

        <div class="flex flex-wrap justify-between gap-1">
          <span class="leading-normal text-zinc-600">{{ __('Domain') }}</span>
          <span class="font-medium leading-normal text-zinc-800">
            {{ domain }}
          </span>
        </div>

        <div class="flex flex-wrap justify-between gap-1">
          <span class="leading-normal text-zinc-600">
            {{ __('Department') }}
          </span>
          <span class="font-medium leading-normal text-zinc-800">
            {{ department }}
          </span>
        </div>

        <div class="flex flex-wrap justify-between gap-1">
          <span class="leading-normal text-zinc-600">
            {{ __('Urgency') }}
          </span>
          <span class="font-medium leading-normal text-zinc-800">
            {{ urgency }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true
    }
  });

  const progress = computed(() => {
    return props.fields.total
      ? `${props.fields.completed}/${props.fields.total}`
      : '';
  });

  const locations = computed(() => {
    return props.data.location.map(
      (item) => `${item.city.name}, ${item.country.name}`
    );
  });

  const visa = computed(() => {
    return props.data.visa && props.data.visa.length
      ? props.data.visa.map((item) => item.name).join(', ')
      : '-';
  });

  const employmentType = computed(() => {
    return props.data.employment_type && props.data.employment_type.length
      ? props.data.employment_type.map((item) => item.name).join(', ')
      : '-';
  });

  const workType = computed(() => {
    return props.data.presence && props.data.presence.length
      ? props.data.presence.map((item) => item.name).join(', ')
      : '-';
  });

  const wage = computed(() => {
    return props.data.desired_wage &&
      props.data.currency &&
      props.data.currency.name &&
      props.data.salary_format &&
      props.data.salary_format.name &&
      props.data.salary_type &&
      props.data.salary_type.name
      ? `${props.data.desired_wage} ${props.data.currency.name} ${props.data.salary_format.name} / ${props.data.salary_type.name}`
      : '-';
  });

  const domain = computed(() => {
    return props.data.domain && props.data.domain.length
      ? props.data.domain.map((item) => item.name).join(', ')
      : '-';
  });

  const department = computed(() => {
    return props.data.department && props.data.department.length
      ? props.data.department.map((item) => item.name).join(', ')
      : '-';
  });

  const desiredJob = computed(() => {
    return props.data.desired_job && props.data.desired_job.length
      ? props.data.desired_job.map((item) => item.name).join(', ')
      : '-';
  });

  const urgency = computed(() => {
    return props.data.urgency && props.data.urgency.name
      ? props.data.urgency.name
      : '-';
  });
</script>
