<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Link to a Group')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="group_work" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
      <InputSelect
        id="group"
        :value="value"
        :errors="errors"
        :label="__('Select Group')"
        :placeholder="
          __(
            'Link event to a group. If you don’t have one, create it from the Group page.'
          )
        "
        :options="options"
        @update:value="handleUpdateValue"
        @update:errors="handleUpdateErrors"
      >
        <template #icon>
          <Icon class="text-lg text-neutral-400" name="search" />
        </template>
      </InputSelect>
    </div>
  </div>
</template>

<script setup>
  import { Icon } from '@/components/block/icon';
  import { InputSelect } from '@/components/form/input-select';
  import { SectionHeader } from '@/pages/components/section-header';

  const props = defineProps({
    value: {
      type: [Object, null],
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  function handleUpdateValue(value) {
    emits('update:value', value);
  }

  function handleUpdateErrors(value) {
    emits('update:errors', value);
  }
</script>
