<template>
  <Combobox as="div">
    <div class="relative md:w-full">
      <ComboboxInput
        class="w-full rounded-app border-0 bg-white py-1.5 pl-3 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 disabled:opacity-50 disabled:cursor-not-allowed"
        @change="
          [(query = $event.target.value), $emit('query', $event.target.value)]
        "
        :display-value="(current) => (current ? current.name : '')"
        :placeholder="__(placeholder)"
      />
      <ComboboxButton
        style="width: 100%; justify-content: end"
        class="absolute inset-y-0 right-0 flex items-center rounded-r-app px-2 focus:outline-none"
      >
        <span class="material-symbols-rounded"> expand_more </span>
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredValues?.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-app bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-for="item in filteredValues"
          :key="item.id"
          :value="item"
          as="template"
          @click="selectedValue = item.id"
          v-slot="{ active, selected }"
        >
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-700',
              active ? 'bg-gray-50' : '',
            ]"
          >
            <span :class="['block', selected && 'font-semibold']">
              {{ item.name }}
            </span>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4 text-gray-700',
                active ? '' : '',
              ]"
            >
              <span class="material-symbols-rounded text-primary"> done </span>
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>

      <ComboboxOptions
        v-if="filteredValues?.length == 0 && query.length >= 3"
        @click="(event) => event.preventDefault()"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-app bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption>
          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9']">
            {{ __("No results found") }}
          </li>
        </ComboboxOption>
      </ComboboxOptions>

      <ComboboxOptions
        v-if="filteredValues?.length == 0 && query.length < 3"
        @click="(event) => event.preventDefault()"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-app bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption>
          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9']">
            {{ __("Please enter 3 or more characters") }}
          </li>
        </ComboboxOption>
      </ComboboxOptions>

      <div
        class="text-gray-400 font-primary font-normal flex justify-center items-center inset-y-0 right-6 absolute cursor-pointer px-2"
        @click="[$emit('clear'), (selectedValue = '')]"
        v-if="selectedValue != '' && clearable"
      >
        <span class="material-symbols-rounded"> close </span>
      </div>
    </div>
  </Combobox>
</template>

<script setup>
import { computed, ref } from "vue";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { __ } from "../../helpers";

const props = defineProps({
  values: Array,
  clearable: {
    type: Boolean,
  },
  type: {
    validator(value) {
      // The value must match one of these strings
      return ["static", "dynamic"].includes(value);
    },
  },
  placeholder: {
    type: String,
    default: __("Search here..."),
  },
});

const query = ref("");
const selectedValue = ref("");

const filteredValues = computed(() => {
  if (props.type == "static") {
    return props.values.filter((item) => {
      return item.name.toLowerCase().includes(query.value.toLowerCase());
    });
  } else {
    return props.values;
  }
});
</script>
