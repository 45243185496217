<template>
  <AlertModal
    open
    :title="title"
    :description="description"
    :is-loading="isLoading"
    @cancel="handleCancelButtonClick"
    @continue="handleContinueButtonClick"
  />
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { AlertModal } from '@/components/other/alert-modal';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['cancel', 'continue']);

  const toast = useToast();

  const title = computed(() => {
    return __('Are you sure you want to delete this event?');
  });

  const description = computed(() => {
    return props.event.details.name;
  });

  const isLoading = ref(false);

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleContinueButtonClick() {
    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.company.events.deleteEvent', {
          id: props.event.id
        })
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('continue');
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }
</script>
