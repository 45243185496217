<template>
  <Modal
    open
    :title="__(isEditMode ? 'Edit speaker' : 'Add speaker')"
    @close="handleCancelButtonClick"
  >
    <form @submit.prevent="handleSubmit">
      <div class="grid gap-6 md:grid-cols-2">
        <InputText
          id="first_name"
          v-model:value="values.first_name"
          v-model:errors="errors.first_name"
          :label="__('First name')"
          :placeholder="__('First name')"
          :disabled="isEditMode"
          required
        />

        <InputText
          id="last_name"
          v-model:value="values.last_name"
          v-model:errors="errors.last_name"
          :label="__('Last name')"
          :placeholder="__('Last name')"
          :disabled="isEditMode"
          required
        />

        <InputText
          id="email"
          v-model:value="values.email"
          v-model:errors="errors.email"
          :label="__('Email')"
          :placeholder="__('Email')"
          :disabled="isEditMode"
          required
        />

        <InputText
          id="role"
          v-model:value="values.role"
          v-model:errors="errors.role"
          :label="__('Role')"
          :placeholder="__('Role')"
          required
        />

        <InputTextarea
          id="description"
          v-model:value="values.description"
          v-model:errors="errors.description"
          :label="__('Description')"
          :placeholder="__('Description')"
          required
          class="md:col-span-2"
        />

        <InputText
          id="linkedin_link"
          v-model:value="values.linkedin_link"
          v-model:errors="errors.linkedin_link"
          :label="__('LinkedIn Profile')"
          :placeholder="__('LinkedIn Profile')"
          :disabled="isEditMode"
        />

        <InputImage
          :id="`photo_url`"
          v-model:value="values.photo_url"
          v-model:file="files.photo_url"
          v-model:errors="errors.photo_url"
          class="my-auto"
          :label="__('Upload photo')"
          :description="`JPG, JPEG ${__('or')} PNG (max. 2 MB)`"
          :aspect-ratio="7 / 9"
          :disabled="isEditMode"
        />
      </div>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </Modal>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { isValidEmail, isValidHttpUrl } from '@/scripts/validate.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Modal } from '@/components/block/modal';
  import { InputImage } from '@/components/form/input-image';
  import { InputText } from '@/components/form/input-text';
  import { InputTextarea } from '@/components/form/input-textarea';

  const props = defineProps({
    speaker: {
      type: [Object, null],
      required: true
    }
  });

  const emits = defineEmits(['cancel', 'save']);

  const toast = useToast();

  const isEditMode = computed(() => {
    return !!props.speaker;
  });

  const values = ref({
    first_name: props.speaker?.first_name || '',
    last_name: props.speaker?.last_name || '',
    email: props.speaker?.email || '',
    role: props.speaker?.role || '',
    description: props.speaker?.description || '',
    linkedin_link: props.speaker?.linkedin_link || '',
    photo_url: props.speaker?.photo_url || ''
  });
  const files = ref({
    photo_url: null
  });
  const errors = ref({
    first_name: [],
    last_name: [],
    email: [],
    role: [],
    description: [],
    linkedin_link: [],
    photo_url: []
  });
  const isLoading = ref(false);

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    const requiredFieldKeys = [
      'first_name',
      'last_name',
      'email',
      'role',
      'description'
    ];
    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (values.value.email && !isValidEmail(values.value.email)) {
      errors.value.email = [__('Please enter a valid email address.')];

      const element = document.getElementById('email');

      if (element && !firstElement) {
        firstElement = element;
      }
    }

    if (
      values.value.linkedin_link &&
      !isValidHttpUrl(values.value.linkedin_link)
    ) {
      errors.value.linkedin_link = [
        __('Invalid URL. Please ensure the URL is correctly formatted.')
      ];

      const element = document.getElementById('linkedin_link');

      if (element && !firstElement) {
        firstElement = element;
      }
    }

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      if (files.value.photo_url) {
        const formData = new FormData();

        formData.append('photo', files.value.photo_url);

        const response = await axios.post(
          route('redesign.media.uploadPhoto'),
          formData
        );

        values.value.photo_url = response.data.photo_url;
      }

      let speaker;

      if (isEditMode.value) {
        speaker = { ...props.speaker, ...values.value };
      } else {
        const response = await axios.post(
          route('redesign.company.speakers.store'),
          values.value
        );

        if (response?.data?.message) {
          toast.success(response.data.message);
        }

        speaker = response.data.data;
      }

      emits('save', speaker);
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
