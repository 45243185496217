<!-- create a template with children inside -->
<template>
  <div class="candidate-gamification__tabs">
    <gamification-profile
      :user="user"
      :switchToNews="switchToNews"
      :error="error"
      :roles_opportunities_route="this.roles_opportunities_route"
      :messages_route="this.messages_route"
      :friends_index_route="this.friends_index_route"
      :page_shop_active="this.page_shop_active"
      :page_shop_url="this.page_shop_url"
      :dismissNotification="dismissNotification"
      :shortenedDescription="shortenedDescription"
    />
    <gamification-leaderboard :user="user" :error="error" />
    <gamification-quests :user="user" :error="error" />
    <gamification-news
      :user="user"
      :error="error"
      :dismissAllNotifications="dismissAllNotifications"
      :dismissNotification="dismissNotification"
      :messages_route="this.messages_route"
      :friends_index_route="this.friends_index_route"
      :roles_opportunities_route="this.roles_opportunities_route"
      :page_shop_active="this.page_shop_active"
      :page_shop_url="this.page_shop_url"
    />

    <gamification-accomplishments :user="user" :error="error" />
  </div>
</template>

<script>
import axios from "axios";
// export the component
export default {
  // props
  props: [
    "gamification_route",
    "newsfeed_mark_route",
    "newsfeed_mark_all_route",
    "csrf",
    "roles_opportunities_route",
    "messages_route",
    "friends_index_route",
    "page_shop_active",
    "page_shop_url",
  ],
  data() {
    return {
      user: null,
      jQueryLoaded: false,
      error: false,
    };
  },
  updated() {
    if (this.user && !this.jQueryLoaded) {
      // profile progress
      jQuery(".levelProgress input.progress").knob({
        min: 0,
        max: 100,
        readOnly: true,
        thickness: 0.2,
        width: 150,
        height: 150,
        fgColor: "#F05C63",
        bgColor: "#F5F5F5",
        fontWeight: "700",
      });

      // subtabs
      jQuery(".candidate-gamification__subtab-link").on("click", function () {
        const subTabId = jQuery(this).data("subtab");

        jQuery(this).siblings().removeClass("selected");
        jQuery(this)
          .closest(".candidate-gamification__tab ")
          .find(".candidate-gamification__subtab.selected")
          .removeClass("selected");

        jQuery("#" + subTabId).addClass("selected");
        jQuery(this).addClass("selected");
      });

      // leaderboard
      let leaderboardItems = jQuery(
        ".candidate-gamification__leaderboard--item"
      );
      for (var position in this.user.leaderboard.monthly) {
        // If next position is not consecutive and it's not the last entry in leaderboard, add "...".
        if (!this.user.leaderboard.monthly[parseInt(position) + 1]) {
          var itemPosition = Object.keys(this.user.leaderboard.monthly).indexOf(
            position
          );
          if (
            position !=
            Object.keys(this.user.leaderboard.monthly)[
              Object.keys(this.user.leaderboard.monthly).length - 1
            ]
          ) {
            $(
              '<div class="candidate-gamification__leaderboard--item" style="display: block"> ' +
                '<p style="text-align: center"> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </p>' +
                "<p></p>" +
                "</div>"
            ).insertAfter(leaderboardItems[itemPosition]);
          }
        }
      }
      for (var position in this.user.leaderboard.weekly) {
        if (!this.user.leaderboard.weekly[parseInt(position) + 1]) {
          var itemPosition =
            Object.keys(this.user.leaderboard.weekly).indexOf(position) +
            Object.keys(this.user.leaderboard.monthly).length;
          if (
            position !=
            Object.keys(this.user.leaderboard.weekly)[
              Object.keys(this.user.leaderboard.weekly).length - 1
            ]
          ) {
            $(
              '<div class="candidate-gamification__leaderboard--item" style="display: block"> ' +
                '<p style="text-align: center"> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </p>' +
                "<p></p>" +
                "</div>"
            ).insertAfter(leaderboardItems[itemPosition]);
          }
        }
      }

      this.jQueryLoaded = true;
    }
  },
  mounted() {
    var parent = this;
    axios
      .get(this.gamification_route)
      .then(function (response) {
        parent.user = response.data.user;
      })
      .catch((e) => {
        parent.error = true;
      });
  },
  methods: {
    shortenedDescription: function (sentence) {
      var trimmedString = sentence.substr(0, 30);
      trimmedString =
        trimmedString.substr(
          0,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
        ) + "...";
      return trimmedString;
    },
    switchToNews: function () {
      $("#profile").removeClass("current");
      $("[data-tab=profile]").removeClass("current");
      $("#news").addClass("current");
      $("[data-tab=news]").addClass("current");
    },
    dismissNotification: function (index) {
      var parent = this;
      let notificationId = this.user.notifications[index]["id"];
      axios
        .post(
          this.newsfeed_mark_route,
          {
            _token: this.csrf,
            mark: 1,
            id: notificationId,
          },
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        )
        .then(function (response) {
          if (response.data.status == true) {
            parent.user.notifications.splice(index, 1);
            showSuccessMessage(response.data.message);
          } else {
            showErrorMessage(response.data.error);
            console.log(response.data.error);
          }
        })
        .catch(function (error) {
          showErrorMessage(error);
          console.log(response.data.error);
        });
    },
    dismissAllNotifications: function () {
      var parent = this;
      axios
        .post(
          this.newsfeed_mark_all_route,
          {
            _token: this.csrf,
            mark: 1,
            type: "profile",
          },
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        )
        .then(function (response) {
          if (response.data.status == true) {
            parent.user.notifications = [];
            showSuccessMessage(response.data.message);
          } else {
            showErrorMessage(response.data.error);
          }
        })
        .catch(function (error) {
          showErrorMessage(error);
        });
    },
  },
};
</script>
