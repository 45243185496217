<template>
  <div class="candidate-gamification__news-highlights--item">
    <div
      class="candidate-gamification__news-highlights--ico"
      style="height: auto"
    >
      <span
        v-if="
          [
            'App\\Notifications\\NewJob',
            'App\\Notifications\\InvitedToJob',
            'App\\Notifications\\AppliedToJob',
            'App\\Notifications\\MatchMade',
            'App\\Notifications\\ChallengeAnswered',
            'App\\Notifications\\ChallengeInvite',
          ].includes(notification.type)
        "
        class="iconH"
        style="fill: #e95b63"
      >
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" height="30">
          <use xlink:href="/images/front/r_SVG-sprite.svg#briefcase"></use>
        </svg>
      </span>

      <span
        v-if="
          [
            'App\\Notifications\\AcademyCompleted',
            'App\\Notifications\\AcademyInvite',
          ].includes(notification.type)
        "
        class="iconH"
        style="fill: #e95b63"
      >
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" height="30">
          <use xlink:href="/images/front/r_SVG-sprite.svg#house_small"></use>
        </svg>
      </span>

      <span
        v-if="notification.type == 'App\\Notifications\\NewShopItem'"
        class="iconH"
        style="fill: #e95b63"
      >
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" height="30">
          <use xlink:href="/images/front/r_SVG-sprite.svg#star"></use>
        </svg>
      </span>

      <span
        v-if="notification.type == 'App\\Notifications\\NewMessage'"
        class="iconH"
        style="fill: #e95b63"
      >
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" height="30">
          <use xlink:href="/images/front/r_SVG-sprite.svg#message"></use>
        </svg>
      </span>

      <div
        v-if="notification.type == 'App\\Notifications\\BadgeEarned'"
        class="candidate-gamification__news-highlights--ico"
      >
        <img src="/images/front/badges/b-explorer.png" alt="" />
      </div>

      <div
        v-if="notification.type == 'App\\Notifications\\LeveledUp'"
        class="lvlup"
      >
        <small>+1! <br />{{ __("Level") }}</small>
        {{ __("Up") }}!
      </div>

      <img
        v-if="
          [
            'App\\Notifications\\FriendRequestReceived',
            'App\\Notifications\\NewJobReferral',
          ].includes(notification.type)
        "
        src="/images/front/svg-sprite-source/r_avatar_student.svg"
        alt=""
      />
    </div>

    <div class="candidate-gamification__news-highlights--desc">
      <div
        class="candidate-gamification__news-highlights--title"
        v-html="notification.data.title"
      ></div>
      <div
        class="candidate-gamification__news-highlights--body"
        v-html="this.shortenedDescription(notification.data.content)"
      ></div>
    </div>

    <div class="candidate-gamification__news-highlights--options more-options">
      <div
        class="candidate-gamification__news-highlights--trigger more-options__trigger"
      >
        •••
      </div>
      <div
        class="candidate-gamification__news-highlights--drop more-options__drop"
      >
        <ul>
          <li
            v-if="
              [
                'App\\Notifications\\NewJob',
                'App\\Notifications\\NewJobReferral',
              ].includes(notification.type) && notification.url
            "
          >
            <a :href="notification.url">{{ __("See job") }}</a>
          </li>
          <li v-if="notification.type == 'App\\Notifications\\InvitedToJob'">
            <a :href="this.roles_opportunities_route">{{ __("Details") }}</a>
          </li>
          <li
            v-if="
              notification.type == 'App\\Notifications\\ChallengeInvite' &&
              notification.url
            "
          >
            <a :href="notification.url">{{ __("See challenge") }}</a>
          </li>
          <li
            v-if="
              notification.type == 'App\\Notifications\\AcademyInvite' &&
              notification.url
            "
          >
            <a :href="notification.url">{{ __("See course") }}</a>
          </li>
          <li v-if="notification.type == 'App\\Notifications\\MatchMade'">
            <a :href="this.messages_route">{{ __("Write a message") }}</a>
          </li>
          <li
            v-if="
              notification.type == 'App\\Notifications\\FriendRequestReceived'
            "
          >
            <a :href="this.friends_index_route"
              >{{ __("Accept") }} / {{ __("Ignore") }}</a
            >
          </li>
          <li v-if="notification.type == 'App\\Notifications\\NewMessage'">
            <a :href="this.messages_route">{{ __("View message") }}</a>
          </li>
          <li v-if="notification.type == 'App\\Notifications\\NewShopItem'">
            <a v-if="this.page_shop_active" :href="this.page_shop_url">{{
              __("Browse shop")
            }}</a>
          </li>
          <li>
            <a @click="this.dismissNotification(index)">{{ __("Dismiss") }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "notification",
    "index",
    "roles_opportunities_route",
    "messages_route",
    "friends_index_route",
    "page_shop_active",
    "page_shop_url",
    "dismissNotification",
    "shortenedDescription",
  ],
};
</script>
