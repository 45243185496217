<template>
  <form
    @submit.prevent="handleApply"
    class="mt-4 rounded-app bg-white p-8 shadow-md"
  >
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <Input
        id="first_name"
        label="First name"
        placeholder="First name"
        type="text"
        v-model="formData.first_name"
        :value="formData.first_name"
        :errors="errors.first_name"
        :status="errors.first_name ? 'error' : 'default'"
        required
      />

      <Input
        id="last_name"
        label="Last name"
        placeholder="Last name"
        type="text"
        v-model="formData.last_name"
        :value="formData.last_name"
        :errors="errors.last_name"
        :status="errors.last_name ? 'error' : 'default'"
        required
      />
      <Input
        id="email"
        label="Email"
        placeholder="Email"
        type="email"
        v-model="formData.email"
        :value="formData.email"
        :errors="errors.email"
        :status="errors.email ? 'error' : 'default'"
        required
      />
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div>
          <label class="mb-2 block text-sm text-[#58585E]"
            >{{ __('Country') }} *
          </label>
          <FormSingleselect
            id="country_prefix"
            v-model="formData.country_prefix"
            :values="props.options.country_prefix"
            @clear="formData.country = null"
            :clearable="true"
            :errors="errors.country_prefix"
            type="static"
            required
          />
        </div>
        <Input
          id="phone"
          label="Phone"
          placeholder="Phone"
          type="tel"
          v-model="formData.phone"
          :value="formData.phone"
          :errors="errors.phone"
          :status="errors.phone ? 'error' : 'default'"
          required
        />
      </div>

      <InputLocation
        v-model:value="formData.current_location"
        v-model:errors="errors.current_location"
        :label-country="__('Country of residence')"
        :label-city="__(`City of residence`)"
        :placeholder-country="__('Add country')"
        :placeholder-city="__('Add city')"
        :options="options.country"
        required
        :default-country="options.default_country"
        class="md:col-span-2"
      />

      <Input
        id="file"
        label="CV"
        placeholder="CV"
        type="file"
        accept=".pdf,.doc,.docx"
        @change="handleCVChange"
        :errors="errors.file"
        :status="errors.file ? 'error' : 'default'"
        required
      />

      <div>
        <Input
          id="agree"
          type="checkbox"
          v-model="formData.agree"
          :value="formData.agree"
          required
          :errors="errors.agree"
          label="I agree with the <a href='/terms-and-conditions-candidates'>terms and conditions</a> and <a href='/privacy-policy'>privacy statement</a>."
        />
      </div>
    </div>
    <div class="mt-4 text-right">
      <Button
        :content="__('Easy Apply')"
        type="submit"
        variation="outline"
        :disabled="isLoading"
      />
      <Button
        :content="__('Cancel')"
        @click="() => emit('close')"
        class="ml-2"
        :disabled="isLoading"
      />
    </div>
  </form>
</template>

<script setup>
  import { ref } from 'vue';

  import {
    Button,
    FormSingleselect,
    Input
  } from '../../../../components/global';
  import { __ } from '../../../../helpers';
  import { useToast } from 'vue-toastification';
  import { useForm } from '../../../../utils';
  import { InputLocation } from '@/components/form/input-location';

  const props = defineProps({
    job: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const emit = defineEmits(['close']);

  const pageFields = ref({
    first_name: '',
    last_name: '',
    email: '',
    country_prefix: null,
    phone: '',
    current_location: null,
    file: null,
    agree: false
  });

  const handleCVChange = (event) => {
    console.log(event.target.files[0]);
    pageFields.value.file = event.target.files[0];
  };

  const { formData, isLoading, updateFormData, resetForm, submitForm, errors } =
    useForm(pageFields.value, {
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: route('redesign.jobs.apply_no_account', {
        company: props.job.company.slug,
        job: props.job.slug
      })
    });

  const handleApply = async () => {
    try {
      const submitResponse = await submitForm(); // Call the submitForm method from useForm

      // check if erro objecet is empty
      if (Object.keys(errors.value).length === 0) {
        // show success toast
        toast.success(submitResponse.message);

        // reset the pageFields
        resetForm();

        emit('close');
      } else {
        console.log(errors.value);
        // show error toast
        toast.error(__('You have some errors in your form! Please check it.'));
      }
    } catch (error) {
      // Handle submission errors here
      console.log(error);
      toast.error(error.response.data.message || __('An error occurred'));
    }
  };

  const locations = ref([]);
  const getLocationsByQuery = async (query) => {
    if (query.length < 3 || query === undefined) {
      return;
    }

    console.log(query);
    axios
      .get(route('cities.find_cities'), {
        params: {
          q: query
        }
      })
      .then((response) => {
        locations.value = response.data;
        console.log(response.data);
      });
  };
</script>
