<template>
  <div class="space-y-6">
    <Button
      v-if="canEdit"
      :href="editUrl"
      variant="outline"
      class="ml-auto flex w-fit items-center gap-1.5"
    >
      <Icon name="edit" class="text-lg" />
      <span>{{ __('Edit group') }}</span>
    </Button>

    <div class="space-y-12">
      <Header v-model:group="group" :can-deactivate="canDeactivate" />

      <TabGroup>
        <TabList class="flex gap-2 overflow-auto rounded-lg bg-white p-3">
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
            >
              {{ __('About') }}
            </button>
          </Tab>
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
            >
              {{ __('Events') }}
            </button>
          </Tab>
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
            >
              {{ __('Members') }}
            </button>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <About :group="group" />
          </TabPanel>
          <TabPanel>
            <Events :group="group" :can-edit="canEdit" />
          </TabPanel>
          <TabPanel>
            <Members :group="group" :user="user" :can-edit="canEdit" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { About } from './components/group/about';
  import { Events } from './components/group/events';
  import { Header } from './components/group/header';
  import { Members } from './components/group/members';

  const props = defineProps({
    group: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDeactivate: {
      type: Boolean,
      default: false
    }
  });

  const editUrl = computed(() => {
    return route('redesign.company.groups.edit', { id: props.group.id });
  });

  const group = ref(JSON.parse(JSON.stringify(props.group)));
</script>
