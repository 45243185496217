<template>
  <div class="shadow-card space-y-8 rounded-lg bg-white p-6 md:px-6 md:py-8">
    <img :src="coverUrl" :alt="__('Cover')" class="w-full rounded" />

    <div class="flex flex-wrap items-start justify-between gap-4">
      <div class="space-y-6">
        <h1 class="text-4xl font-medium text-primary">{{ name }}</h1>

        <div class="space-y-6">
          <div class="flex items-center gap-1.5">
            <Icon name="group" is-filled class="text-xl text-primary" />
            <span class="text-sm text-zinc-800">
              {{ `${totalMembers} ${__('members')}` }}
            </span>
          </div>

          <div v-if="hasJoinedGroup" class="space-y-4">
            <div class="flex items-center gap-1.5">
              <Icon name="beenhere" is-filled class="text-xl text-success" />
              <span class="text-sm font-medium text-zinc-600">
                {{ __('You are part of this group') }}
              </span>
            </div>

            <Button
              v-if="canLeaveGroup"
              class="flex items-center justify-center gap-1.5"
              :disabled="isLoadingLeave"
              @click="handleLeaveButtonClick"
            >
              <Icon
                v-if="isLoadingLeave"
                class="animate-spin text-lg"
                name="progress_activity"
              />
              <span>{{ __('Leave group') }}</span>
            </Button>
          </div>

          <Button
            v-else-if="canJoinGroup"
            class="flex items-center justify-center gap-1.5"
            :disabled="isLoadingJoin"
            @click="handleJoinButtonClick"
          >
            <Icon
              v-if="isLoadingJoin"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span>{{ __('Join group') }}</span>
          </Button>
        </div>
      </div>

      <Dropdown
        position="right"
        :options="shareDropdownOptions"
        @select="handleShareDropdownSelect"
      >
        <template #trigger>
          <Button
            tabindex="-1"
            variant="ghost"
            class="flex items-center gap-1.5"
          >
            <Icon name="share" class="text-xl text-primary" />
            <span class="text-zinc-600">{{ __('Share this group') }}</span>
          </Button>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Dropdown } from '@/components/block/dropdown';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    group: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    canJoinGroup: {
      type: Boolean,
      default: false
    },
    canLeaveGroup: {
      type: Boolean,
      default: false
    },
    hasJoinedGroup: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['update:user']);

  const toast = useToast();

  const shareDropdownOptions = [
    {
      id: 1,
      name: __('Share to LinkedIn')
    },
    {
      id: 2,
      name: __('Share to Facebook')
    },
    {
      id: 3,
      name: __('Copy link to group')
    }
  ];

  const coverUrl = computed(() => {
    return group.value.details.cover.includes('/uploads/')
      ? group.value.details.cover
      : `/uploads/${group.value.details.cover}`;
  });

  const name = computed(() => {
    return group.value.details.name;
  });

  const group = ref(JSON.parse(JSON.stringify(props.group)));
  const hasJoinedGroup = ref(props.hasJoinedGroup);
  const canJoinGroup = ref(props.canJoinGroup);
  const canLeaveGroup = ref(props.canLeaveGroup);
  const isLoadingJoin = ref(false);
  const isLoadingLeave = ref(false);
  const totalMembers = ref(0);

  async function handleJoinButtonClick() {
    isLoadingJoin.value = true;

    try {
      const response = await axios.post(
        route('redesign.groups.joinGroup', { slug: group.value.details.slug })
      );

      if (response?.data?.redirect_url) {
        window.location.href = response.data.redirect_url;
        return;
      }

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      hasJoinedGroup.value = true;
      canLeaveGroup.value = true;
      totalMembers.value++;

      emits('update:user', {
        ...props.user,
        can_invite: true
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingJoin.value = false;
  }

  async function handleLeaveButtonClick() {
    isLoadingLeave.value = true;

    try {
      const response = await axios.post(
        route('redesign.groups.exitGroup', { slug: group.value.details.slug })
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      hasJoinedGroup.value = false;
      canJoinGroup.value = true;
      totalMembers.value--;

      emits('update:user', {
        ...props.user,
        can_invite: false
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingLeave.value = false;
  }

  async function handleShareDropdownSelect(option) {
    const groupUrl = route('redesign.groups.view', {
      slug: group.value.details.slug
    });
    switch (option.id) {
      case 1: {
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${groupUrl}`,
          '_blank'
        );
        break;
      }
      case 2: {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${groupUrl}`,
          '_blank'
        );
        break;
      }
      case 3: {
        try {
          await navigator.clipboard.writeText(groupUrl);

          toast.success(__('Group link has been copied to your clipboard!'));
        } catch {
          toast.error(
            __('An unexpected error has occured. Please try again later.')
          );
        }
        break;
      }
    }
  }

  onMounted(async () => {
    try {
      const response = await axios.get(
        route('redesign.groups.paginateMembers', {
          slug: group.value.details.slug
        }),
        { params: { offset: 0, limit: 10 } }
      );

      totalMembers.value = response.data.total;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }
  });
</script>
