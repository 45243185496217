<template>
  <div class="shadow-element relative rounded-lg bg-white p-6">
    <div class="flex flex-wrap items-center gap-2">
      <span class="text-lg text-zinc-800">{{ event.title }}</span>
      <span class="border-l-2 border-primary pl-2 text-zinc-600">
        {{ event.date }}
      </span>
    </div>

    <div v-if="event.recruiter" class="mt-2.5 flex items-center gap-1.5">
      <Icon class="text-2xl text-primary" name="settings_account_box" />
      <span class="text-sm font-medium text-zinc-600">
        {{ `${__('Recruiter')}: ${event.recruiter}` }}
      </span>
    </div>
    <div v-if="event.kanban" class="mt-2.5 flex items-center gap-1.5">
      <Icon class="text-2xl text-primary" name="view_kanban" />
      <span class="text-sm font-medium text-zinc-600">
        {{ `${__('Kanban step')}: ${event.kanban}` }}
      </span>
    </div>
    <div v-if="event.source" class="mt-2.5 flex items-center gap-1.5">
      <Icon class="text-2xl text-primary" name="join_inner" />
      <span class="text-sm font-medium text-zinc-600">
        {{ `${__('Source')}: ${event.source}` }}
      </span>
    </div>


    <p
      v-if="event.details && isDetailsExpanded"
      class="mt-4 leading-normal text-zinc-800"
    >
      {{ event.details }}
    </p>

    <Button
      v-if="event.details"
      class="absolute bottom-[1.1rem] right-[1rem] ml-auto mt-1.5 flex items-center gap-1"
      variant="ghost"
      @click="handleExpandDetailsButtonClick"
    >
      <span>{{ __(isDetailsExpanded ? 'Hide' : 'View more') }}</span>
      <Icon
        class="text-base"
        :name="isDetailsExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
      />
    </Button>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  defineProps({
    event: {
      type: Object,
      required: true
    }
  });

  const isDetailsExpanded = ref(false);

  function handleExpandDetailsButtonClick() {
    isDetailsExpanded.value = !isDetailsExpanded.value;
  }
</script>
