<template>
  <div class="space-y-12">
    <div v-if="isUserTypeRecruiter" class="space-y-8">
      <CandidateInformation
        :data="data"
        :recruiter="recruiter"
        :username="user.encoded_username"
      />

      <div class="space-y-6">
        <button
          class="flex w-full flex-wrap items-center justify-between gap-4 border-b-[1.5px] border-primary py-4"
          @click="handleDashboardButtonClick"
        >
          <h1 class="text-4xl font-medium leading-none text-zinc-800">
            {{ __('Dashboard') }}
          </h1>

          <Icon
            :class="`${isDashboardOpen ? 'rotate-180' : 'rotate-0'} text-3xl text-zinc-800`"
            name="keyboard_arrow_down"
          />
        </button>

        <div v-if="isDashboardOpen" class="space-y-10">
          <Applications :data="data" :recruiter="recruiter" />

          <div class="grid gap-10 md:grid-cols-2">
            <Pipelines :data="data" :recruiter="recruiter" />
            <Notes :data="data" :recruiter="recruiter" />
          </div>

          <HistoryTracking
            :data="recruiter.history_tracking"
            :options="recruiter.options.history_tracking"
            :user-id="user.id"
          />
        </div>
      </div>
    </div>

    <div v-else class="space-y-6">
      <div class="flex flex-wrap items-center justify-between gap-4 py-4">
        <h1 class="text-4xl font-medium leading-none text-zinc-800">
          {{ __('Resume') }}
        </h1>

        <Button variant="outline" @click="handleDownloadResumeButtonClick">
          <span>{{ __('Download Resume') }}</span>
        </Button>
      </div>

      <div class="space-y-10">
        <CompleteProfile
          :percentages="data.percentages"
          :resume-percentage="data.cv_percentage"
        />
        <UploadResume
          v-if="conditions.show_upload_banner"
          :can-upload="conditions.can_upload"
        />
      </div>
    </div>

    <div class="space-y-8">
      <span
        v-if="isUserTypeRecruiter"
        class="block border-b-[1.5px] border-primary py-4 text-4xl font-medium leading-none text-zinc-800"
      >
        {{ __('Resume') }}
      </span>

      <div class="space-y-10">
        <AboutMe
          id="about_me"
          :data="resume.about_me.data"
          :availability-data="resume.availability.data"
          :options="resume.about_me.options"
          :fields="resume.about_me.fields"
          :resume="resume.about_me.resume"
          :error-message="resume.about_me.errorMessage"
          :can-edit="resume.about_me.canEdit"
          :is-editing="resume.about_me.isEditing"
          @update-is-editing="
            (value) => handleUpdateIsEditing('about_me', value)
          "
          @update-percentages="
            (value) => handleUpdatePercentages('about_me', value)
          "
        />
        <Availability
          id="availability"
          :data="resume.availability.data"
          :options="resume.availability.options"
          :fields="resume.availability.fields"
          :error-message="resume.availability.errorMessage"
          :can-edit="resume.availability.canEdit"
          :is-editing="resume.availability.isEditing"
          @update-is-editing="
            (value) => handleUpdateIsEditing('availability', value)
          "
          @update-percentages="
            (value) => handleUpdatePercentages('availability', value)
          "
        />
        <Experiences
          v-if="resume.experiences.canEdit || resume.experiences.data.length"
          id="experiences"
          :data="resume.experiences.data"
          :options="resume.experiences.options"
          :fields="resume.experiences.fields"
          :has-no-experience="resume.experiences.hasNoExperience"
          :can-edit="resume.experiences.canEdit"
          :is-editing="resume.experiences.isEditing"
          @update-is-editing="
            (value) => handleUpdateIsEditing('experiences', value)
          "
          @update-percentages="
            (value) => handleUpdatePercentages('experiences', value)
          "
        />
        <Educations
          v-if="resume.educations.canEdit || resume.educations.data.length"
          id="educations"
          :data="resume.educations.data"
          :options="resume.educations.options"
          :fields="resume.educations.fields"
          :can-edit="resume.educations.canEdit"
          :is-editing="resume.educations.isEditing"
          @update-is-editing="
            (value) => handleUpdateIsEditing('educations', value)
          "
          @update-percentages="
            (value) => handleUpdatePercentages('educations', value)
          "
        />
        <Volunteerings
          v-if="
            resume.volunteerings.canEdit || resume.volunteerings.data.length
          "
          :data="resume.volunteerings.data"
          :fields="resume.volunteerings.fields"
          :can-edit="resume.volunteerings.canEdit"
          :is-editing="resume.volunteerings.isEditing"
          @update-is-editing="
            (value) => handleUpdateIsEditing('volunteerings', value)
          "
          @update-percentages="
            (value) => handleUpdatePercentages('volunteerings', value)
          "
        />
        <Projects
          v-if="resume.projects.canEdit || resume.projects.data.length"
          :data="resume.projects.data"
          :fields="resume.projects.fields"
          :can-edit="resume.projects.canEdit"
          :is-editing="resume.projects.isEditing"
          @update-is-editing="
            (value) => handleUpdateIsEditing('projects', value)
          "
          @update-percentages="
            (value) => handleUpdatePercentages('projects', value)
          "
        />
        <Achievements
          v-if="resume.achievements.canEdit || resume.achievements.data.length"
          :data="resume.achievements.data"
          :options="resume.achievements.options"
          :fields="resume.achievements.fields"
          :can-edit="resume.achievements.canEdit"
          :is-editing="resume.achievements.isEditing"
          @update-is-editing="
            (value) => handleUpdateIsEditing('achievements', value)
          "
          @update-percentages="
            (value) => handleUpdatePercentages('achievements', value)
          "
        />
        <Skills
          v-if="resume.skills.canEdit || resume.skills.data.length"
          id="skills"
          :data="resume.skills.data"
          :fields="resume.skills.fields"
          :error-message="resume.skills.errorMessage"
          :can-edit="resume.skills.canEdit"
          :is-editing="resume.skills.isEditing"
          @update-is-editing="(value) => handleUpdateIsEditing('skills', value)"
          @update-percentages="
            (value) => handleUpdatePercentages('skills', value)
          "
        />
        <Documents
          v-if="
            conditions.show_documents &&
            (resume.documents.canEdit || resume.documents.data.length)
          "
          :data="resume.documents.data"
          :options="resume.documents.options"
          :fields="resume.documents.fields"
          :username="resume.documents.username"
          :can-edit="resume.documents.canEdit"
          :is-editing="resume.documents.isEditing"
          @update-is-editing="
            (value) => handleUpdateIsEditing('documents', value)
          "
          @update-percentages="
            (value) => handleUpdatePercentages('documents', value)
          "
        />
        <Languages
          v-if="resume.languages.canEdit || resume.languages.data.length"
          :data="resume.languages.data"
          :options="resume.languages.options"
          :fields="resume.languages.fields"
          :error-message="resume.languages.errorMessage"
          :can-edit="resume.languages.canEdit"
          :is-editing="resume.languages.isEditing"
          @update-is-editing="
            (value) => handleUpdateIsEditing('languages', value)
          "
          @update-percentages="
            (value) => handleUpdatePercentages('languages', value)
          "
        />

        <div
          v-if="
            resume.values.canEdit ||
            resume.values.data.length ||
            resume.perks.canEdit ||
            resume.perks.data.length
          "
          class="grid gap-12 md:grid-cols-2"
        >
          <Values
            v-if="resume.values.canEdit || resume.values.data.length"
            :data="resume.values.data"
            :options="resume.values.options"
            :fields="resume.values.fields"
            :error-message="resume.values.errorMessage"
            :can-edit="resume.values.canEdit"
            :is-editing="resume.values.isEditing"
            @update-is-editing="
              (value) => handleUpdateIsEditing('values', value)
            "
            @update-percentages="
              (value) => handleUpdatePercentages('values', value)
            "
          />
          <Perks
            v-if="resume.perks.canEdit || resume.perks.data.length"
            :data="resume.perks.data"
            :options="resume.perks.options"
            :fields="resume.perks.fields"
            :error-message="resume.perks.errorMessage"
            :can-edit="resume.perks.canEdit"
            :is-editing="resume.perks.isEditing"
            @update-is-editing="
              (value) => handleUpdateIsEditing('perks', value)
            "
            @update-percentages="
              (value) => handleUpdatePercentages('perks', value)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { __ } from '@/helpers';
  import { computeResume } from '@/scripts/resume.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { AboutMe } from './components/about-me';
  import { Achievements } from './components/achievements';
  import { Applications } from './components/applications';
  import { Availability } from './components/availability';
  import { CandidateInformation } from './components/candidate-information';
  import { CompleteProfile } from './components/complete-profile';
  import { Documents } from './components/documents';
  import { Educations } from './components/educations';
  import { Experiences } from './components/experiences';
  import { HistoryTracking } from './components/history-tracking';
  import { Languages } from './components/languages';
  import { Notes } from './components/notes';
  import { Perks } from './components/perks';
  import { Pipelines } from './components/pipelines';
  import { Projects } from './components/projects';
  import { Skills } from './components/skills';
  import { UploadResume } from './components/upload-resume';
  import { Values } from './components/values';
  import { Volunteerings } from './components/volunteerings';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    recruiter: {
      type: [Object, null],
      required: true
    },
    conditions: {
      type: Object,
      required: true
    },
    userType: {
      type: String,
      required: true
    }
  });

  const isUserTypeRecruiter = computed(() => {
    return props.userType === 'recruiter';
  });

  const isDashboardOpen = ref(false);
  const data = ref(JSON.parse(JSON.stringify(props.data)));
  const resume = ref({
    about_me: {
      data: props.data.about_me.user,
      options: props.data.about_me.options,
      fields: props.data.about_me.fields,
      resume: {
        file: props.data.cv_file,
        canUpload: props.conditions.can_upload
      },
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    },
    availability: {
      data: props.data.availability.user,
      options: props.data.availability.options,
      fields: props.data.availability.fields,
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    },
    experiences: {
      data: props.data.experiences.user.experiences,
      options: props.data.experiences.options,
      fields: props.data.experiences.fields,
      hasNoExperience:
        props.data.experiences.user.no_experience === 0 ? false : true,
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    },
    educations: {
      data: props.data.educations.user,
      options: props.data.educations.options,
      fields: props.data.educations.fields,
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    },
    volunteerings: {
      data: props.data.volunteering.user,
      fields: props.data.volunteering.fields,
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    },
    projects: {
      data: props.data.projects.user,
      fields: props.data.projects.fields,
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    },
    achievements: {
      data: props.data.achievements.user,
      options: props.data.achievements.options,
      fields: props.data.achievements.fields,
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    },
    skills: {
      data: props.data.skills.user.map((item) => item.skill),
      fields: props.data.skills.fields,
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    },
    documents: {
      data: props.data.documents.user,
      options: props.data.documents.options,
      fields: props.data.documents.fields,
      username: props.user.encoded_username,
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    },
    languages: {
      data: props.data.languages.user,
      options: props.data.languages.options,
      fields: props.data.languages.fields,
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    },
    values: {
      data: props.data.values.user,
      options: props.data.values.options,
      fields: props.data.values.fields,
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    },
    perks: {
      data: props.data.perks.user,
      options: props.data.perks.options,
      fields: props.data.perks.fields,
      errorMessage: '',
      canEdit: !isUserTypeRecruiter.value,
      isEditing: false
    }
  });

  function handleDashboardButtonClick() {
    isDashboardOpen.value = !isDashboardOpen.value;
  }

  function handleDownloadResumeButtonClick() {
    computeResume(props.data);
  }

  async function handleUpdateIsEditing(key, value) {
    const editingSectionKey = Object.keys(resume.value).find(
      (key) => resume.value[key].isEditing
    );

    if (value && editingSectionKey) {
      resume.value[editingSectionKey].errorMessage = __(
        'Please save or cancel your changes before editing another section.'
      );

      const element = document.querySelector('button[type="submit"]');

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }

      return;
    }

    if (!value) {
      Object.keys(resume.value).forEach((key) => {
        resume.value[key].errorMessage = '';
      });
    }

    resume.value[key].isEditing = value;
  }

  function handleUpdatePercentages(key, value) {
    const section = Object.keys(data.value.percentages).includes(key)
      ? key
      : 'others';

    if (value.sectionPercentage) {
      data.value.percentages = {
        ...data.value.percentages,
        [section]: value.sectionPercentage
      };
    }

    if (value.resumePercentage) {
      data.value.cv_percentage = value.resumePercentage;
    }
  }
</script>
