<template>
  <Combobox
    nullable
    :by="by"
    :disabled="disabled"
    :model-value="value"
    @update:model-value="handleUpdateModelValue"
  >
    <div class="relative">
      <ComboboxLabel
        v-if="label"
        :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
      >
        {{ `${label}${required ? '*' : ''}` }}
      </ComboboxLabel>

      <ComboboxButton
        :class="`${errors.length ? 'ring-error' : 'ring-gray-200'} ${disabled ? 'cursor-not-allowed bg-zinc-100' : ''} flex w-full cursor-default items-center justify-between gap-2 overflow-hidden rounded-md bg-white px-3.5 py-2.5 shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary`"
      >
        <div class="pointer-events-none flex text-neutral-400">
          <slot name="icon" />
        </div>

        <ComboboxInput
          :class="`${disabled ? 'text-zinc-500' : 'text-zinc-800'} w-full truncate border-none bg-transparent p-0 placeholder:text-zinc-500 focus:ring-0`"
          :placeholder="placeholder"
          :display-value="(value) => value?.name"
          @focus="handleFocus"
          @input="handleInput"
        />

        <ComboboxButton
          v-if="value"
          class="-m-1 flex p-1"
          @click="handleClearButtonClick"
        >
          <Icon
            :class="`${disabled ? 'text-zinc-300' : 'text-error'} text-lg`"
            name="close"
          />
        </ComboboxButton>

        <ComboboxButton v-else class="-m-1 flex p-1">
          <Icon
            :class="`${disabled ? 'text-zinc-300' : 'text-zinc-500'} text-xl`"
            name="expand_more"
          />
        </ComboboxButton>
      </ComboboxButton>

      <TransitionRoot
        leave-from="opacity-100"
        leave-to="opacity-0"
        @after-leave="handleAfterLeave"
      >
        <ComboboxOptions
          class="shadow-card absolute z-10 mt-1 max-h-60 w-max min-w-full overflow-y-auto rounded-md border border-gray-200 bg-white p-1 text-sm leading-normal text-zinc-800 focus:outline-none"
        >
          <template v-if="options.length">
            <ComboboxOption
              v-for="option in options"
              :key="option.id"
              v-slot="{ selected, active }"
              :value="option"
              as="template"
            >
              <li
                class="flex cursor-default select-none items-center justify-between gap-2 rounded-md px-3 py-1.5"
                :class="{
                  'bg-gray-50': active
                }"
              >
                <span
                  class="block truncate"
                  :class="{ 'font-medium': selected, 'font-normal': !selected }"
                >
                  {{ option.name }}
                </span>

                <Icon v-if="selected" class="text-primary" name="check" />
              </li>
            </ComboboxOption>
          </template>

          <div
            v-else-if="isLoadingOptions"
            class="cursor-default select-none px-3 py-1.5"
          >
            {{ __('Loading...') }}
          </div>

          <div
            v-else-if="isReactiveOptions && query.length < 3"
            class="cursor-default select-none px-3 py-1.5"
          >
            {{ __('Please input at least 3 characters') }}
          </div>

          <div v-else class="cursor-default select-none px-3 py-1.5">
            {{ __('No results found') }}
          </div>
        </ComboboxOptions>
      </TransitionRoot>

      <div v-if="errors.length" class="mt-2 space-y-1">
        <span
          v-for="(error, index) in errors"
          :key="index"
          class="block text-xs text-error"
        >
          {{ error }}
        </span>
      </div>
    </div>
  </Combobox>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    TransitionRoot
  } from '@headlessui/vue';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    by: {
      type: String,
      default: 'name'
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, String, null],
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      required: true
    },
    isLoadingOptions: {
      type: Boolean,
      default: false
    },
    isReactiveOptions: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['update:value', 'update:errors', 'query']);

  const options = computed(() => {
    if (props.isReactiveOptions) {
      return props.options;
    }

    const normalizedQuery =
      query.value?.toLowerCase().replace(/\s+/g, '') || '';

    if (!normalizedQuery) {
      return props.options;
    }

    return props.options.filter((option) =>
      option.name.toLowerCase().replace(/\s+/g, '').includes(normalizedQuery)
    );
  });

  const query = ref('');

  function handleAfterLeave() {
    query.value = '';
    emits('query', query.value);
  }

  function handleFocus() {
    emits('query', props.value?.name || '');
  }

  function handleInput(event) {
    query.value = event.target.value;
    emits('query', query.value);
  }

  function handleClearButtonClick() {
    emits('update:value', null);
    emits('update:errors', []);
  }

  function handleUpdateModelValue(option) {
    emits('update:value', option);
    emits('update:errors', []);
  }
</script>
