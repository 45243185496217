<template>
  <InputCombobox
    :value="value"
    :label="label"
    :placeholder="placeholder"
    :required="required"
    :options="options"
    :errors="errors"
    @update:value="handleUpdateValue"
  >
    <template #icon>
      <Icon class="text-lg" name="public" />
    </template>
  </InputCombobox>
</template>

<script setup>
  import { Icon } from '@/components/block/icon';
  import { InputCombobox } from '@/components/form/input-combobox';

  const props = defineProps({
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Object, null],
      default: null
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  function handleUpdateValue(value) {
    emits('update:value', value);
    emits('update:errors', []);
  }
</script>
