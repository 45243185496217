<template>
  <div class="candidate-gamification__subtab" id="friends">
    <div class="candidate-gamification__leaderboard">
      <p
          style="color: rgba(15, 35, 63, 0.5); padding: 15px"
          v-if="user.leaderboard.friends.length == 0"
      >
        {{ __("You have no friends yet!") }}
      </p>

      <div
          v-for="(friend, position) in user.leaderboard.friends"
          class="candidate-gamification__leaderboard--item"
      >
        <div style="width: 50px; text-align: center">
          {{ parseInt(position) + 1 }}
        </div>
        <div class="candidate-gamification__leaderboard--avatar">
          <img
              :src="friend.profile_photo_src"
              alt=""
              style="border-radius: 50%"
          />
        </div>
        <div
            class="candidate-gamification__leaderboard--name"
            style="width: 160px; word-break: break-word"
        >
          {{ friend.name }}
        </div>
        <div class="candidate-gamification__leaderboard--points">
          {{ friend.points }}p
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
};
</script>
