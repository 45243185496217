<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell">
    <div class="flex justify-center gap-1.5">
      <template v-for="(value, type) in props.columnData" :key="type">

        <template v-if="type === 'reject'"><!-- reject -->
          <Popover v-slot="{ open }" class="relative text-center">
            <PopoverButton :class="open ? 'text-white' : 'text-white/90'" class="group inline-flex items-center">
              <a href="#" class="block cursor-pointer rounded-md bg-brandColor1 px-2 py-1 text-center text-xs text-white shadow-redesign hover:bg-brandColor1/[.80]">{{ capitalize(value['label']) }}</a>
            </PopoverButton>

            <transition enter-active-class="transition duration-200 ease-out" enter-from-class="translate-y-1 opacity-0" enter-to-class="translate-y-0 opacity-100" leave-active-class="transition duration-150 ease-in" leave-from-class="translate-y-0 opacity-100" leave-to-class="translate-y-1 opacity-0">
              <PopoverPanel v-slot="{ close }" class="absolute min-w-[22rem] hasEar shadow-redesign text-left left-1/2 z-30 mt-3 -translate-x-1/2 transform px-4 sm:px-0 overflow-y-scroll">
                <div class="rounded-lg shadow-lg ring-1 ring-black/5 px-3 pt-3 bg-white max-h-50">
                  <div class="relative">
                    <div class="relative">
                      <template v-if="value['hasAccount']">
                        <Textarea
                            v-model="rejectTextarea.reject_reason"
                            :placeholder="__('Decline application and send a note to the candidate')"
                            :id="'reject-'+candidateIndex"
                            :name="'reject-'+candidateIndex"
                        ></Textarea>
                      </template>
                      <template v-if="!value['hasAccount']">
                        <p class="text-gray-400">{{ __('You can send a note to a candidate when rejecting them only if they have an account.')}}</p>
                      </template>

                      <div class="flex py-3">
                        <div class="flex-auto text-left">
                          <button @click="handleReject(candidateIndex, close)" class="rounded-md bg-brandColor1 px-2 py-1 text-center text-xs text-white shadow-redesign hover:bg-brandColor1/[.80]">{{ __('Save') }}</button>
                        </div>
                        <div class="flex-auto text-right">
                          <PopoverButton class="text-xs text-brandColor1 font-semibold hover:text-brandColor1/[.80">{{ __('Discard') }}</PopoverButton>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </PopoverPanel>
            </transition>
          </Popover>
        </template><!-- end reject -->

        <template v-else><!-- accept -->
          <a @click="handleAccept(candidateIndex)" class="block cursor-pointer rounded-md bg-brandColor1 px-2 py-1 text-center text-xs text-white shadow-redesign hover:bg-brandColor1/[.80]">{{ capitalize(value['label']) }}</a>
        </template><!-- end accept -->


      </template>
    </div>
  </td>
</template>

<script setup>

import {__, capitalize} from "../../../../../helpers";
import {Popover, PopoverButton, PopoverPanel} from "@headlessui/vue";
import {Textarea} from "../../../../global";
import axios from "axios";
import { useToast } from "vue-toastification";
import {inject} from 'vue';

const toast = useToast();
const updateCounters = inject('updateCounters');
const updateComponentTotal = inject('updateComponentTotal');
const currentJob = inject('selectedJob');

const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});

const rejectTextarea = {
  reject_reason: '',
}

const handleReject = (candidateIndex, close) => {

  let rejectFormData = new FormData();
      rejectFormData.append('user_id', props.person.user_id);
      rejectFormData.append('job_id', currentJob.value.id);
      rejectFormData.append('reject_reason', (rejectTextarea.reject_reason != null) ? rejectTextarea.reject_reason : '');

  axios.post(route("redesign.matching.rejectApplication"), rejectFormData)
    .then((response) => {
      rejectTextarea.reject_reason = '';
      doUpdates(candidateIndex);
      close();
      toast.success(response.data.message);
    }).catch((err) => {
      toast.error( err.response.data.message || __("Something went wrong.") );
    })
}

const handleAccept = (candidateIndex) => {
  let acceptFormData = new FormData();
      acceptFormData.append('user_id', props.person.user_id);
      acceptFormData.append('job_id', currentJob.value.id);

  axios.post(route("redesign.matching.acceptApplication"), acceptFormData)
      .then((response) => {
        toast.success(response.data.message);
        doUpdates(candidateIndex);
      }).catch((err) => {
        toast.error( err.response.data.message || __("Something went wrong.") );
      })
}

const doUpdates = (candidateIndex) => {
  props.people.splice(candidateIndex, 1)
  updateCounters();
  updateComponentTotal(props.total - 1)
}


</script>