<template>
  <div
    class="shadow-card flex flex-wrap items-start gap-4 rounded-lg bg-white p-6 md:p-8"
  >
    <img
      :src="photoUrl"
      :alt="__('Profile picture')"
      class="h-[11.25rem] w-[8.75rem] rounded"
    />

    <div class="grow">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2">
          <span class="block font-medium leading-normal text-zinc-800">
            {{ fullName }}
          </span>

          <a
            v-if="linkedinLink"
            :href="linkedinLink"
            target="_blank"
            class="-m-1 flex items-center justify-center rounded p-1 transition-colors hover:bg-zinc-800/5"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
            >
              <path
                class="fill-zinc-800"
                d="M1.66667 2.86042C1.66667 2.20104 2.21458 1.66667 2.89062 1.66667H17.1094C17.7854 1.66667 18.3333 2.20104 18.3333 2.86042V17.1396C18.3333 17.799 17.7854 18.3333 17.1094 18.3333H2.89062C2.21458 18.3333 1.66667 17.799 1.66667 17.1396V2.86042ZM6.81562 15.6187V8.09271H4.31458V15.6187H6.81562ZM5.56563 7.06458C6.4375 7.06458 6.98021 6.4875 6.98021 5.76458C6.96458 5.02604 6.43854 4.46458 5.58229 4.46458C4.72604 4.46458 4.16667 5.02708 4.16667 5.76458C4.16667 6.4875 4.70937 7.06458 5.54896 7.06458H5.56563ZM10.6781 15.6187V11.4156C10.6781 11.1906 10.6948 10.9656 10.7615 10.8052C10.9417 10.3562 11.3531 9.89062 12.0448 9.89062C12.95 9.89062 13.3115 10.5802 13.3115 11.5927V15.6187H15.8125V11.3021C15.8125 8.98958 14.5792 7.91458 12.9333 7.91458C11.6063 7.91458 11.0115 8.64375 10.6781 9.15729V9.18333H10.6615C10.667 9.17464 10.6725 9.16596 10.6781 9.15729V8.09271H8.17812C8.20937 8.79896 8.17812 15.6187 8.17812 15.6187H10.6781Z"
              />
            </svg>
          </a>
        </div>

        <div class="flex gap-2">
          <Button
            :aria-label="__('Edit')"
            variant="icon"
            @click="handleEditButtonClick"
          >
            <Icon class="text-xl" name="edit" />
          </Button>

          <Button
            :aria-label="__('Delete')"
            variant="icon"
            @click="handleDeleteButtonClick"
          >
            <Icon class="text-xl" name="delete" />
          </Button>
        </div>
      </div>

      <span class="block text-sm font-medium text-secondary">
        {{ role }}
      </span>

      <p class="mt-2 text-sm text-zinc-600">{{ description }}</p>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    speaker: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['edit', 'delete']);

  const placeholderImage = '/images/logo_placeholder.png';

  const photoUrl = computed(() => {
    return props.speaker.photo_url
      ? `/uploads/${props.speaker.photo_url}`
      : placeholderImage;
  });

  const fullName = computed(() => {
    return `${props.speaker.first_name} ${props.speaker.last_name}`;
  });

  const linkedinLink = computed(() => {
    return props.speaker.linkedin_link;
  });

  const role = computed(() => {
    return props.speaker.role;
  });

  const description = computed(() => {
    return props.speaker.description;
  });

  function handleEditButtonClick() {
    emits('edit');
  }

  function handleDeleteButtonClick() {
    emits('delete');
  }
</script>
