<template>
  <tr class="border-b bg-white last:border-b-0">
    <td class="px-8 py-4">
      <div class="flex items-center gap-4">
        <img
          class="h-12 w-12 rounded-full border border-zinc-200"
          :src="placeholderImage"
          :alt="__('Profile picture')"
        />

        <span class="text-sm text-zinc-600">{{ email }}</span>
      </div>
    </td>

    <td v-if="canEdit"></td>

    <td class="px-8 py-4">
      <span class="text-sm text-zinc-600">{{ role }}</span>
    </td>

    <td class="px-8 py-4">
      <span class="block text-sm text-zinc-600">
        {{ __('Invitation sent on') }}
        <br />
        {{ date }}
      </span>
    </td>

    <td v-if="canEdit" class="px-8 py-4">
      <Button
        class="flex w-full items-center justify-center gap-1.5 md:w-fit"
        :disabled="!canInviteAgain || isLoading"
        @click="handleInviteAgainButtonClick"
      >
        <Icon
          v-if="isLoading"
          class="animate-spin text-lg"
          name="progress_activity"
        />
        <span>
          {{ __(isLoading ? 'Loading...' : 'Invite again') }}
        </span>
      </Button>
    </td>
  </tr>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { formatDateTime } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    invitation: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits('invite-again');

  const toast = useToast();

  const placeholderImage = '/images/logo_placeholder.png';

  const email = computed(() => {
    return props.invitation.email;
  });

  const role = computed(() => {
    return props.invitation.type;
  });

  const date = computed(() => {
    return formatDateTime(props.invitation.updated_at);
  });

  const canInviteAgain = computed(() => {
    return props.invitation.can_invite_again;
  });

  const isLoading = ref(false);

  async function handleInviteAgainButtonClick() {
    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.company.members.resend'),
        { invitation_id: props.invitation.id }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('invite-again', {
        ...props.invitation,
        can_invite_again: response.data.recruiter.can_invite_again,
        updated_at: response.data.recruiter.updated_at
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }
</script>
