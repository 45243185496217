<template>
  <div class="relative h-40 overflow-hidden rounded-md">
    <img
      class="h-full w-full object-cover"
      :src="
        image.url.includes('data') || image.url.includes('/uploads/')
          ? image.url
          : `/uploads/${image.url}`
      "
      :alt="__('Media image')"
    />

    <button
      class="absolute inset-0 flex items-center justify-center bg-zinc-800/75 opacity-0 transition-opacity hover:opacity-100 focus:opacity-100"
      type="button"
      :aria-label="__('Delete')"
      @click="handleDeleteButtonClick"
    >
      <Icon class="text-xl text-white" name="delete" />
    </button>
  </div>
</template>

<script setup>
  import { Icon } from '@/components/block/icon';

  defineProps({
    image: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['delete']);

  function handleDeleteButtonClick() {
    emits('delete');
  }
</script>
