<template>
  <div>
    <div v-if="error" id="badges" class="candidate-gamification__tab">
      <div class="matching-lists__message">
        <p style="text-align: center">
          {{ __("Something went wrong. Please check again later.") }}
        </p>
      </div>
    </div>

    <div v-if="user" id="badges" class="candidate-gamification__tab">
      <div class="candidate-gamification__module-header flex sa aic">
        <h3
          class="candidate-gamification__subtab-link selected"
          data-subtab="badges-tab"
        >
          {{ __("Badges") }}
        </h3>
        <h3
          class="candidate-gamification__subtab-link"
          data-subtab="achievements-tab"
        >
          {{ __("Achievements") }}
        </h3>
      </div>

      <div class="candidate-gamification__module-cnt">
        <div
          class="candidate-gamification__subtabs candidate-gamification__badges-achievements"
        >
          <gamification-accomplishments-badges
            :user="user"
          ></gamification-accomplishments-badges>
          <gamification-accomplishments-achivements
            :user="user"
          ></gamification-accomplishments-achivements>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["error", "user"],
};
</script>
