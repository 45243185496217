<template>
  <a :href="url" class="flex flex-wrap gap-x-8 gap-y-4 sm:flex-nowrap">
    <img
      :src="preview"
      :alt="__('Preview photo')"
      class="w-full rounded sm:h-56 sm:w-auto"
    />

    <div class="space-y-6">
      <div class="flex flex-wrap gap-2">
        <span
          class="w-fit rounded-lg border border-secondary px-3 py-1.5 text-sm leading-none text-zinc-800"
        >
          {{ type }}
        </span>
        <span
          v-if="reward"
          class="w-fit rounded-lg border border-secondary px-3 py-1.5 text-sm leading-none text-zinc-800"
        >
          {{ reward }}
        </span>
        <span
          v-if="group"
          class="w-fit rounded-lg border border-secondary px-3 py-1.5 text-sm leading-none text-zinc-800"
        >
          {{ group }}
        </span>
      </div>

      <div class="space-y-4">
        <span class="block font-medium text-zinc-800">{{ dateTime }}</span>
        <span class="block text-2xl font-medium text-zinc-800">{{ name }}</span>
      </div>

      <template v-if="isFutureEvent">
        <div v-if="registrationDeadlinePassed" class="space-y-2">
          <div class="flex items-center gap-2">
            <Icon name="event_busy" is-filled class="text-2xl text-error" />
            <span class="text-lg font-medium text-zinc-800">
              {{ __('Registration deadline passed') }}
            </span>
          </div>

          <p v-if="isUserRegistered" class="leading-normal text-zinc-600">
            {{ __('Once you unregister, you cannot register back.') }}
          </p>

          <p v-else class="leading-normal text-zinc-600">
            {{
              __(
                'Explore other exciting events happening around the same time!'
              )
            }}

            <a
              :href="allEventsUrl"
              class="text-primary underline underline-offset-2"
            >
              {{ __('See more') }}
            </a>
          </p>
        </div>

        <div v-else-if="attendeeLimitReached" class="space-y-2">
          <div class="flex items-center gap-2">
            <Icon name="event_busy" is-filled class="text-2xl text-error" />
            <span class="text-lg font-medium text-zinc-800">
              {{ __('The event has reached the maximum limit') }}
            </span>
          </div>

          <p v-if="isUserRegistered" class="leading-normal text-zinc-600">
            {{
              __(
                "Space is limited! Unregistering now means you'll need to re-register if you want to attend."
              )
            }}
          </p>

          <p v-else class="leading-normal text-zinc-600">
            {{
              __(
                'Explore other exciting events happening around the same time!'
              )
            }}

            <a
              :href="allEventsUrl"
              class="text-primary underline underline-offset-2"
            >
              {{ __('See more') }}
            </a>
          </p>
        </div>

        <div v-if="isUserRegistered" class="flex flex-wrap items-center gap-2">
          <span
            class="rounded-md bg-success px-4 py-2 text-sm font-medium text-white"
          >
            {{ __('Registered') }}
          </span>

          <Button
            :disabled="isLoadingUnregister"
            variant="outline"
            class="flex items-center justify-center gap-1.5"
            @click.prevent="handleUnregisterButtonClick"
          >
            <Icon
              v-if="isLoadingUnregister"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span> {{ __('Unregister') }}</span>
          </Button>
        </div>

        <template v-else>
          <Button
            v-if="
              !attendeeLimitReached &&
              !registrationDeadlinePassed &&
              canRegister
            "
            :disabled="isLoadingRegister"
            class="flex items-center justify-center gap-1.5"
            @click.prevent="handleRegisterButtonClick"
          >
            <Icon
              v-if="isLoadingRegister"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span>{{ __('Register Now') }}</span>
          </Button>

          <Button v-else>
            {{ __('View more') }}
          </Button>
        </template>
      </template>

      <div v-else-if="isPresentEvent" class="space-y-4">
        <div class="flex items-center gap-2">
          <Icon name="stream" is-filled class="text-2xl text-primary" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('The event is happening now') }}
          </span>
        </div>

        <div
          v-if="isUserCheckedIn"
          class="flex w-fit items-center gap-1.5 rounded-md bg-success px-4 py-2 text-white"
        >
          <Icon name="event_available" is-filled class="text-xl" />

          <span class="text-sm font-medium">
            {{ __('You checked in') }}
          </span>
        </div>

        <template v-else>
          <div v-if="registrationDeadlinePassed" class="space-y-2">
            <div class="flex items-center gap-2">
              <Icon name="event_busy" is-filled class="text-2xl text-error" />
              <span class="text-lg font-medium text-zinc-800">
                {{ __('Registration deadline passed') }}
              </span>
            </div>

            <p v-if="isUserRegistered" class="leading-normal text-zinc-600">
              {{ __('Once you unregister, you cannot register back.') }}
            </p>

            <p v-else class="leading-normal text-zinc-600">
              {{
                __(
                  'Explore other exciting events happening around the same time!'
                )
              }}

              <a
                :href="allEventsUrl"
                class="text-primary underline underline-offset-2"
              >
                {{ __('See more') }}
              </a>
            </p>
          </div>

          <div v-else-if="attendeeLimitReached" class="space-y-2">
            <div class="flex items-center gap-2">
              <Icon name="event_busy" is-filled class="text-2xl text-error" />
              <span class="text-lg font-medium text-zinc-800">
                {{ __('The event has reached the maximum limit') }}
              </span>
            </div>

            <p v-if="isUserRegistered" class="leading-normal text-zinc-600">
              {{
                __(
                  "Space is limited! Unregistering now means you'll need to re-register if you want to attend."
                )
              }}
            </p>

            <p v-else class="leading-normal text-zinc-600">
              {{
                __(
                  'Explore other exciting events happening around the same time!'
                )
              }}

              <a
                :href="allEventsUrl"
                class="text-primary underline underline-offset-2"
              >
                {{ __('See more') }}
              </a>
            </p>
          </div>

          <span
            v-if="isUserRegistered"
            class="block w-fit rounded-md bg-success px-4 py-2 text-sm font-medium text-white"
          >
            {{ __('Registered') }}
          </span>

          <Button
            v-else-if="
              !attendeeLimitReached &&
              !registrationDeadlinePassed &&
              canRegister
            "
            :disabled="isLoadingRegister"
            class="flex items-center justify-center gap-1.5"
            @click.prevent="handleRegisterButtonClick"
          >
            <Icon
              v-if="isLoadingRegister"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span> {{ __('Register Now') }}</span>
          </Button>
        </template>
      </div>

      <div v-else-if="isPastEvent" class="space-y-4">
        <div class="flex items-center gap-2">
          <Icon name="local_activity" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('This event has passed') }}
          </span>
        </div>

        <p v-if="isUserUnregistered" class="!mt-2 leading-normal text-zinc-600">
          {{
            __('Explore other exciting events happening around the same time!')
          }}

          <a
            :href="allEventsUrl"
            class="text-primary underline underline-offset-2"
          >
            {{ __('See more') }}
          </a>
        </p>

        <div
          v-else-if="isUserRegistered"
          class="flex w-fit items-center gap-1.5 rounded-md bg-error px-4 py-2 text-white"
        >
          <Icon name="cancel" is-filled class="text-xl" />

          <span class="text-sm font-medium">
            {{ __("You didn't check in") }}
          </span>
        </div>

        <div
          v-else-if="isUserCheckedIn"
          class="flex flex-wrap items-center gap-2"
        >
          <div
            class="flex items-center gap-1.5 rounded-md bg-success px-4 py-2 text-white"
          >
            <Icon name="event_available" is-filled class="text-xl" />

            <span class="text-sm font-medium">
              {{ __('You checked in') }}
            </span>
          </div>

          <Button
            class="flex items-center gap-1.5"
            @click.prevent="handleRateButtonClick"
          >
            <Icon name="reviews" is-filled class="text-xl text-white" />
            <span class="text-white">{{ __('Rate this event') }}</span>
          </Button>
        </div>
      </div>

      <div v-else-if="isRatedEvent" class="space-y-4">
        <div class="flex items-center gap-2">
          <Icon name="local_activity" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('This event has passed') }}
          </span>
        </div>

        <div
          class="flex w-fit items-center gap-1.5 rounded-md bg-success px-4 py-2 text-white"
        >
          <Icon name="reviews" is-filled class="text-xl" />

          <span class="text-sm font-medium">
            {{ __('You rated this event') }}
          </span>
        </div>
      </div>

      <div v-else-if="isCanceledEvent" class="space-y-2">
        <div class="flex items-center gap-2">
          <Icon name="local_activity" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('This event has been canceled') }}
          </span>
        </div>

        <p v-if="isUserRegistered" class="leading-normal text-zinc-600">
          {{
            __(
              "The event you signed up for isn't happening anymore. Explore other exciting events happening around the same time!"
            )
          }}

          <a
            :href="allEventsUrl"
            class="text-primary underline underline-offset-2"
          >
            {{ __('See more') }}
          </a>
        </p>

        <p v-else class="leading-normal text-zinc-600">
          {{
            __('Explore other exciting events happening around the same time!')
          }}

          <a
            :href="allEventsUrl"
            class="text-primary underline underline-offset-2"
          >
            {{ __('See more') }}
          </a>
        </p>
      </div>
    </div>

    <EventRateModal
      v-if="isEventRateModalOpen"
      :event="event"
      @cancel="handleCancelEventRateModalButtonClick"
      @save="handleSaveEventRateModalButtonClick"
    />
  </a>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { formatDate, formatTime } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { EventRateModal } from '../event-rate-modal';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const allEventsUrl = route('redesign.events.viewAll');

  const url = computed(() => {
    return route('redesign.events.view', { slug: event.value.details.slug });
  });

  const preview = computed(() => {
    return `/uploads/${event.value.details.photo}`;
  });

  const type = computed(() => {
    return event.value.details.type.name;
  });

  const group = computed(() => {
    return event.value.group?.id
      ? `${__('Group')}: ${event.value.group.name}`
      : '';
  });

  const reward = computed(() => {
    return event.value.gamification?.points.id
      ? `${__('Reward')}: ${event.value.gamification.points.name} ${__('points')}`
      : '';
  });

  const dateTime = computed(() => {
    const startDate = formatDate(event.value.details.start_date_time);
    const startTime = formatTime(event.value.details.start_date_time);
    const endDate = formatDate(event.value.details.end_date_time);
    const endTime = formatTime(event.value.details.end_date_time);

    if (startDate !== endDate) {
      return `${startDate} | ${startTime} - ${endDate} | ${endTime}`;
    }

    return `${startDate} | ${startTime} - ${endTime}`;
  });

  const name = computed(() => {
    return event.value.details.name;
  });

  const attendeeLimitReached = computed(() => {
    const attendees = event.value.details.attendees;
    const attendeeLimit = event.value.details.attendee_limit;

    if (!attendeeLimit) {
      return false;
    }

    return attendees === attendeeLimit;
  });

  const registrationDeadlinePassed = computed(() => {
    const rsvp = event.value.details.rsvp;

    if (!rsvp) {
      return false;
    }

    return new Date() > new Date(rsvp);
  });

  const isFutureEvent = computed(() => {
    return event.value.status === 'FUTURE' || event.value.status === 'UPCOMING';
  });

  const isPresentEvent = computed(() => {
    return event.value.status === 'PRESENT';
  });

  const isPastEvent = computed(() => {
    return event.value.status === 'PAST';
  });

  const isRatedEvent = computed(() => {
    return event.value.status === 'RATED';
  });

  const isCanceledEvent = computed(() => {
    return event.value.status === 'CANCELED';
  });

  const isUserUnregistered = computed(() => {
    return event.value.user?.status === 'UNREGISTERED';
  });

  const isUserRegistered = computed(() => {
    return event.value.user?.status === 'REGISTERED';
  });

  const isUserCheckedIn = computed(() => {
    return event.value.user?.status === 'CHECKED_IN';
  });

  const canRegister = computed(() => {
    return event.value.user?.can_register;
  });

  const event = ref(JSON.parse(JSON.stringify(props.event)));
  const isLoadingRegister = ref(false);
  const isLoadingUnregister = ref(false);
  const isEventRateModalOpen = ref(false);

  async function handleRegisterButtonClick() {
    isLoadingRegister.value = true;

    try {
      const response = await axios.post(
        route('redesign.events.register', {
          slug: event.value.details.slug
        })
      );

      if (response?.data?.redirect_url) {
        window.location.href = response.data.redirect_url;
        return;
      }

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      event.value = {
        ...event.value,
        details: {
          ...event.value.details,
          attendees: event.value.details.attendees + 1
        },
        user: { ...event.value.user, status: 'REGISTERED' }
      };
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingRegister.value = false;
  }

  async function handleUnregisterButtonClick() {
    isLoadingRegister.value = true;

    try {
      const response = await axios.post(
        route('redesign.events.unregister', {
          slug: event.value.details.slug
        })
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      event.value = {
        ...event.value,
        details: {
          ...event.value.details,
          attendees: event.value.details.attendees - 1
        },
        user: {
          ...event.value.user,
          status: 'UNREGISTERED',
          can_register: true
        }
      };
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingRegister.value = false;
  }

  function handleRateButtonClick() {
    isEventRateModalOpen.value = true;
  }

  function handleCancelEventRateModalButtonClick() {
    isEventRateModalOpen.value = false;
  }

  async function handleSaveEventRateModalButtonClick() {
    event.value = { ...event.value, status: 'RATED' };
    isEventRateModalOpen.value = false;
  }
</script>
