<template>
  <div v-if="isLoadingFetch" class="flex items-center gap-1.5 text-zinc-500">
    <Icon class="animate-spin text-xl" name="progress_activity" />
    <span>{{ __('Loading...') }}</span>
  </div>

  <div v-else class="space-y-6 md:space-y-8">
    <div class="space-y-2.5">
      <div class="flex gap-2 md:gap-4">
        <Icon
          class="text-[2rem] text-primary md:text-[2.5rem]"
          name="diversity_1"
          is-filled
        />
        <h1 class="text-2xl font-medium text-zinc-800 md:text-4xl">
          {{ __('Building an inclusive experience') }}
        </h1>
      </div>

      <p class="text-sm text-zinc-800 lg:text-base">
        {{
          __(
            'We believe in fostering an inclusive environment. Help us create a more personalized experience by sharing your identity and preferences. Your participation is voluntary, and your information will remain private, visible only if you choose to share it.'
          )
        }}
      </p>
    </div>

    <form class="space-y-8" @submit.prevent="handleSubmit">
      <div class="grid gap-4 md:grid-cols-2">
        <div class="relative md:col-span-2">
          <InputCombobox
            v-if="fields.ethnicity.visible"
            id="ethnicity"
            v-model:value="fields.ethnicity.value"
            v-model:errors="fields.ethnicity.errors"
            :label="__('Ethnicity')"
            :placeholder="__('What’s your ethnicity?')"
            :required="fields.ethnicity.mandatory"
            :options="fields.ethnicity.options"
          >
            <template #icon>
              <Icon name="emoji_flags" is-filled />
            </template>
          </InputCombobox>

          <button
            class="absolute right-0 top-[3px] -m-1 flex items-center gap-1 p-1 text-zinc-600"
            type="button"
            @click="handleIsVisibleToRecruitersButtonClick('ethnicity')"
          >
            <span class="text-xs">
              {{
                __(
                  `${fields.ethnicity.isVisibleToRecruiters ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                )
              }}
            </span>
            <Icon
              class="text-lg"
              :name="
                fields.ethnicity.isVisibleToRecruiters
                  ? 'visibility'
                  : 'visibility_off'
              "
            />
          </button>
        </div>

        <div class="relative">
          <InputCombobox
            v-if="fields.gender.visible"
            id="gender"
            v-model:value="fields.gender.value"
            v-model:errors="fields.gender.errors"
            :label="__('Gender identity')"
            :placeholder="__('Which gender identity do you identify with?')"
            :required="fields.gender.mandatory"
            :options="fields.gender.options"
          >
            <template #icon>
              <Icon name="female" is-filled />
            </template>
          </InputCombobox>

          <button
            class="absolute right-0 top-[3px] -m-1 flex items-center gap-1 p-1 text-zinc-600"
            type="button"
            @click="handleIsVisibleToRecruitersButtonClick('gender')"
          >
            <span class="text-xs">
              {{
                __(
                  `${fields.gender.isVisibleToRecruiters ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                )
              }}
            </span>
            <Icon
              class="text-lg"
              :name="
                fields.gender.isVisibleToRecruiters
                  ? 'visibility'
                  : 'visibility_off'
              "
            />
          </button>
        </div>

        <div class="relative">
          <InputCombobox
            v-if="fields.pronouns.visible"
            id="pronouns"
            v-model:value="fields.pronouns.value"
            v-model:errors="fields.pronouns.errors"
            :label="__('Pronouns')"
            :placeholder="__('How would you like to be referred to?')"
            :required="fields.pronouns.mandatory"
            :options="fields.pronouns.options"
          >
            <template #icon>
              <Icon name="man_3" is-filled />
            </template>
          </InputCombobox>

          <button
            class="absolute right-0 top-[3px] -m-1 flex items-center gap-1 p-1 text-zinc-600"
            type="button"
            @click="handleIsVisibleToRecruitersButtonClick('pronouns')"
          >
            <span class="text-xs">
              {{
                __(
                  `${fields.pronouns.isVisibleToRecruiters ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                )
              }}
            </span>
            <Icon
              class="text-lg"
              :name="
                fields.pronouns.isVisibleToRecruiters
                  ? 'visibility'
                  : 'visibility_off'
              "
            />
          </button>
        </div>
      </div>

      <div
        v-if="fields.vulnerabilities.visible || fields.disabilities.visible"
        class="space-y-4"
      >
        <p class="text-zinc-800 lg:text-lg">
          {{
            __(
              'Your well-being is important to us. Share any accessibility needs or vulnerabilities so we can offer the right support.'
            )
          }}
        </p>

        <div class="grid gap-4 md:grid-cols-2">
          <div v-if="fields.vulnerabilities.visible" class="relative">
            <InputCombobox
              id="vulnerabilities"
              v-model:value="fields.vulnerabilities.value"
              v-model:errors="fields.vulnerabilities.errors"
              :label="__('Vulnerabilities')"
              :placeholder="__('What challenges do you experience?')"
              :required="fields.vulnerabilities.mandatory"
              :options="fields.vulnerabilities.options"
            >
              <template #icon>
                <Icon name="psychiatry" is-filled />
              </template>
            </InputCombobox>

            <button
              class="absolute right-0 top-[3px] -m-1 flex items-center gap-1 p-1 text-zinc-600"
              type="button"
              @click="handleIsVisibleToRecruitersButtonClick('vulnerabilities')"
            >
              <span class="text-xs">
                {{
                  __(
                    `${fields.vulnerabilities.isVisibleToRecruiters ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                  )
                }}
              </span>
              <Icon
                class="text-lg"
                :name="
                  fields.vulnerabilities.isVisibleToRecruiters
                    ? 'visibility'
                    : 'visibility_off'
                "
              />
            </button>
          </div>

          <div v-if="fields.disabilities.visible" class="relative">
            <InputCombobox
              id="disabilities"
              v-model:value="fields.disabilities.value"
              v-model:errors="fields.disabilities.errors"
              :label="__('Disabilities')"
              :placeholder="__('What’s your disability status?')"
              :required="fields.disabilities.mandatory"
              :options="fields.disabilities.options"
            >
              <template #icon>
                <Icon name="accessibility" is-filled />
              </template>
            </InputCombobox>

            <button
              class="absolute right-0 top-[3px] -m-1 flex items-center gap-1 p-1 text-zinc-600"
              type="button"
              @click="handleIsVisibleToRecruitersButtonClick('disabilities')"
            >
              <span class="text-xs">
                {{
                  __(
                    `${fields.disabilities.isVisibleToRecruiters ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                  )
                }}
              </span>
              <Icon
                class="text-lg"
                :name="
                  fields.disabilities.isVisibleToRecruiters
                    ? 'visibility'
                    : 'visibility_off'
                "
              />
            </button>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <Button
          class="flex items-center gap-1.5"
          variant="ghost"
          @click="handleBackButtonClick"
        >
          <Icon class="text-lg" name="arrow_back_ios_new" />
          <span>{{ __('Go Back') }}</span>
        </Button>

        <Button class="ml-auto flex items-center gap-1.5" type="submit">
          <Icon
            v-if="isLoadingUpdate"
            class="animate-spin text-lg"
            name="progress_activity"
          />

          <span>{{ __('Next') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputCombobox } from '@/components/form/input-combobox';

  const emits = defineEmits(['back', 'next']);

  const toast = useToast();

  const fields = ref({
    ethnicity: {
      visible: false,
      mandatory: false,
      value: null,
      errors: [],
      options: [],
      isVisibleToRecruiters: false
    },
    gender: {
      visible: false,
      mandatory: false,
      value: null,
      errors: [],
      options: [],
      isVisibleToRecruiters: false
    },
    pronouns: {
      visible: false,
      mandatory: false,
      value: null,
      errors: [],
      options: [],
      isVisibleToRecruiters: false
    },
    vulnerabilities: {
      visible: false,
      mandatory: false,
      value: [],
      errors: [],
      options: [],
      isVisibleToRecruiters: false
    },
    disabilities: {
      visible: false,
      mandatory: false,
      value: [],
      errors: [],
      options: [],
      isVisibleToRecruiters: false
    }
  });
  const isLoadingFetch = ref(false);
  const isLoadingUpdate = ref(false);

  function isValid(value) {
    if (!value) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'object') {
      for (const prop in value) {
        if (value[prop] === null) {
          return false;
        }
      }
      return true;
    }

    return true;
  }

  function handleIsVisibleToRecruitersButtonClick(key) {
    fields.value[key].isVisibleToRecruiters =
      !fields.value[key].isVisibleToRecruiters;
  }

  async function fetchData() {
    isLoadingFetch.value = true;

    try {
      const response = await axios.get(
        route('onboarding.step', { slug: 'inclusivity' })
      );

      Object.entries(response.data).forEach(([key, value]) => {
        if (fields.value[key]) {
          fields.value[key] = { ...fields.value[key], ...value };
        }
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingFetch.value = false;
  }

  function handleBackButtonClick() {
    emits('back');
  }

  async function handleSubmit() {
    const requiredFieldKeys = Object.keys(fields.value).filter(
      (key) => fields.value[key].visible && fields.value[key].mandatory
    );
    let firstInvalidField;

    requiredFieldKeys.forEach((key) => {
      if (!isValid(fields.value[key].value)) {
        fields.value[key].errors = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstInvalidField) {
          firstInvalidField = element;
        }
      }
    });

    if (
      Object.values(fields.value).some(
        (field) => field.errors && field.errors.length
      )
    ) {
      if (firstInvalidField) {
        firstInvalidField.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
      return;
    }

    const payload = Object.keys(fields.value).reduce((acc, key) => {
      if (fields.value[key].visible) {
        acc[key] = fields.value[key].value;
      }
      return acc;
    }, {});

    isLoadingUpdate.value = true;

    try {
      await axios.post(
        route('onboarding.step', { slug: 'inclusivity' }),
        payload
      );

      emits('next');
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).forEach((key) => {
          if (fields.value[key]) {
            fields.value[key].errors = error.response.data.errors[key];
          }
        });
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingUpdate.value = false;
  }

  onMounted(async () => {
    await fetchData();
  });
</script>
