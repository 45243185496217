<template>
  <div>
    <label
      v-if="label"
      :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
    >
      {{ `${label}${required ? '*' : ''}` }}
    </label>

    <VueSlider
      :rail-style="{
        backgroundColor: `rgba(var(--tw-secondary-color), 0.1)`
      }"
      :process-style="{
        backgroundColor: `rgba(var(--tw-secondary-color), 1)`
      }"
      :tooltip-style="{
        backgroundColor: `rgba(var(--tw-secondary-color), 1)`,
        borderColor: `rgba(var(--tw-secondary-color), 1)`
      }"
      :height="8"
      :dot-size="24"
      :tooltip-formatter="tooltipFormatter"
      :min="min"
      :max="max"
      :interval="interval"
      :model-value="value"
      @update:model-value="handleUpdateModelValue"
    >
      <template #dot>
        <div class="h-full w-full rounded-full bg-white">
          <div class="h-full w-full rounded-full bg-secondary/10 p-0.5">
            <div class="h-full w-full rounded-full bg-secondary p-1">
              <div class="h-full w-full rounded-full bg-white"></div>
            </div>
          </div>
        </div>
      </template>
    </VueSlider>

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import VueSlider from 'vue-3-slider-component';

  const props = defineProps({
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 0
    },
    errors: {
      type: Array,
      default: () => []
    },
    currency: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    interval: {
      type: Number,
      default: 1
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  function handleUpdateModelValue(value) {
    emits('update:value', value);
    emits('update:errors', []);
  }

  function tooltipFormatter(value) {
    if (!props.currency) {
      return value;
    }

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: props.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  }
</script>
