<template>
  <div v-if="!data">
    <span>{{ __('Loading...') }}</span>
  </div>

  <div v-else class="shadow-card space-y-8 rounded-lg bg-white p-6 md:p-8">
    <TabGroup @change="handleTabChange">
      <div class="flex flex-wrap items-center justify-between gap-4">
        <TabList class="flex gap-3 overflow-auto bg-white">
          <Tab
            v-for="tab in tabs"
            :key="tab.value"
            v-slot="{ selected }"
            as="template"
          >
            <button
              :class="`${selected ? 'border-primary' : 'border-transparent'} whitespace-nowrap border-b-2 py-3 pr-8 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:border-primary`"
            >
              {{ tab.label }}
            </button>
          </Tab>
        </TabList>

        <Button
          v-if="canEdit"
          :href="createEventUrl"
          variant="outline"
          class="flex w-fit items-center gap-1.5"
        >
          <Icon name="add_box" class="text-lg" is-filled />
          <span>{{ __('Create new event') }}</span>
        </Button>
      </div>
      <TabPanels>
        <TabPanel v-for="tab in tabs" :key="tab.value" class="space-y-8">
          <span v-if="!data.events.length" class="block text-zinc-600">
            {{ __('No events found') }}
          </span>

          <div v-else class="space-y-8">
            <Event
              v-for="event in data.events"
              :key="event.id"
              v-model:events="data.events"
              :event="event"
            />

            <div v-if="renderPagination" class="flex justify-center gap-2">
              <button
                class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
                :disabled="isPreviousButtonDisabled"
                :aria-label="__('Previous')"
                @click="handlePreviousPageButtonClick"
              >
                <Icon class="text-xl text-primary" name="arrow_back" />
              </button>

              <button
                class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
                :disabled="isNextButtonDisabled"
                :aria-label="__('Next')"
                @click="handleNextPageButtonClick"
              >
                <Icon class="text-xl text-primary" name="arrow_forward" />
              </button>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { getTimezone } from '@/scripts/date.script';
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Event } from '../../shared/event';

  const props = defineProps({
    group: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  });

  const toast = useToast();

  const createEventUrl = computed(() => {
    return route('redesign.company.events.create', { groupId: props.group.id });
  });

  const tabs = computed(() => {
    return data.value.filters.options.tabs;
  });

  const page = computed(() => {
    return data.value.pagination.page;
  });

  const total = computed(() => {
    return data.value.pagination.total;
  });

  const renderPagination = computed(() => {
    return total.value > 1;
  });

  const isPreviousButtonDisabled = computed(() => {
    return page.value <= 1;
  });

  const isNextButtonDisabled = computed(() => {
    return page.value >= total.value;
  });

  const data = ref(null);

  async function fetchEvents(params) {
    params.set('group', props.group.id);
    params.set('timezone', getTimezone().tz);

    try {
      const response = await axios.get(
        route('redesign.company.events.getEvents'),
        {
          params
        }
      );

      data.value = response.data;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }
  }

  async function handleTabChange(index) {
    const tab = tabs.value.find((_, i) => i === index);

    if (!tab) {
      return;
    }

    const params = new URLSearchParams();
    params.set('tab', tab.value.toLowerCase());

    await fetchEvents(params);
  }

  async function handlePreviousPageButtonClick() {
    const params = new URLSearchParams();
    params.set('page', page.value - 1);

    await fetchEvents(params);
  }

  async function handleNextPageButtonClick() {
    const params = new URLSearchParams();
    params.set('page', page.value + 1);

    await fetchEvents(params);
  }

  onMounted(async () => {
    const params = new URLSearchParams();

    await fetchEvents(params);
  });
</script>
