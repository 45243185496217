<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Education')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="school" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-element space-y-8 rounded-2xl bg-white p-6 md:p-8">
      <Education
        v-for="education in isListExpanded ? data : data.slice(0, 3)"
        :key="education.id"
        :education="education"
      />

      <Button
        v-if="data.length > 3"
        class="mx-auto"
        variant="outline"
        @click="handleExpandListButtonClick"
      >
        {{ __(isListExpanded ? 'See less' : 'See more') }}
      </Button>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Education } from './components/education';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    }
  });

  const isListExpanded = ref(false);

  function handleExpandListButtonClick() {
    isListExpanded.value = !isListExpanded.value;
  }
</script>
