<template>
  <Combobox as="div" v-model="addSelectedValue">
    <div class="relative md:w-full">
      <!-- Combobox Input -->
      <div class="relative">
        <ComboboxInput
          class="w-full rounded-app border-0 bg-white py-1.5 pl-3 pr-14 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm sm:leading-6"
          :class="classes"
          @change="
            [(query = $event.target.value), $emit('query', $event.target.value)]
          "
          :placeholder="__(placeholder)"
          :aria-label="placeholder"
          autocomplete="off"
          ref="inputRef"
          @keyup.enter="create_new(query)"
        />
        <Icon
          v-if="icon"
          :name="icon"
          aria-hidden="true"
          class="absolute left-3 top-1/2 -translate-y-1/2 transform text-[#A3A3A3]"
        />
      </div>
      <ComboboxButton
        style="width: 100%; justify-content: end"
        class="absolute inset-y-0 right-0 flex items-center rounded-r-app px-2 focus:outline-none"
      >
        <span class="material-symbols-rounded" aria-hidden="true">
          expand_more
        </span>
        <span class="sr-only">{{ __('Expand') }}</span>
      </ComboboxButton>

      <!-- Combobox Options -->
      <ComboboxOptions
        v-if="filteredValues?.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-app bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-for="item in filteredValues"
          :key="item.id"
          :value="item"
          as="template"
          v-slot="{ active, selected, disabled }"
          :disabled="item.is_disabled"
        >
          <li
            :class="[
              disabled ? 'cursor-not-allowed opacity-50' : '',
              'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-700',
              active ? 'bg-gray-50' : ''
            ]"
          >
            <span :class="['block', selected && 'font-semibold']">
              <span v-if="item.is_child" class="pr-4"></span>
              {{ item.name }}
            </span>

            <!-- Selected Indicator -->
            <span
              v-if="selectedValue?.some((value) => value.id === item.id)"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4 text-gray-700',
                active ? '' : ''
              ]"
            >
              <span
                class="material-symbols-rounded text-secondary"
                aria-hidden="true"
              >
                done
              </span>
              <span class="sr-only">{{ __('Selected') }}</span>
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>

      <ComboboxOptions
        v-if="filteredValues?.length == 0 && query.length >= 3"
        @click="(event) => event.preventDefault()"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-app bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption v-if="!can_create">
          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9']">
            {{ __('No results found') }}
          </li>
        </ComboboxOption>
        <ComboboxOption v-else>
          <li :class="['relative select-none py-2 pl-3 pr-9']">
            <span
              class="cursor-pointer' text-primary"
              @click="create_new(query)"
              >{{ __('This is a new option, press to add it') }}</span
            >
          </li>
        </ComboboxOption>
      </ComboboxOptions>

      <ComboboxOptions
        v-if="filteredValues?.length == 0 && query.length < 3"
        @click="(event) => event.preventDefault()"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-app bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption>
          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9']">
            {{ __('Please enter 3 or more characters') }}
          </li>
        </ComboboxOption>
      </ComboboxOptions>

      <!-- No Results Found -->
      <!-- ... existing no results logic ... -->
    </div>
    <!-- Display Chips for Selected Values -->
    <div
      class="mt-4 flex flex-wrap gap-2"
      v-if="props.multiple && selectedValue.length > 0"
    >
      <div
        v-for="(value, index) in selectedValue"
        :key="index"
        class="chip flex items-center rounded-app border-0 px-2 py-1 text-sm shadow-sm ring-1 ring-inset ring-secondary focus:ring-2 disabled:cursor-not-allowed disabled:opacity-50 sm:leading-6"
      >
        {{ findValueName(value) }}
        <button
          class="ml-2 cursor-pointer"
          type="button"
          @click="removeSelectedValue(index)"
        >
          <span class="material-symbols-rounded !text-sm" aria-hidden="true">
            close
          </span>
          <span class="sr-only">{{ __('Delete') }}</span>
        </button>
      </div>
    </div>

    <div v-if="errors">
      <p
        class="mt-1 text-xs text-error"
        v-for="(error, index) in errors"
        :key="index"
      >
        {{ error }}
      </p>
    </div>
  </Combobox>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions
  } from '@headlessui/vue';
  import { __ } from '../../../helpers';
  import { Icon } from '..';
  import { useToast } from 'vue-toastification';

  const props = defineProps({
    values: Array,
    modelValue: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean
    },
    type: {
      validator(value) {
        return ['static', 'dynamic'].includes(value);
      },
      default: 'static'
    },
    placeholder: {
      type: String,
      default: __('Search here...')
    },
    icon: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    max: {
      type: Number
    },
    can_create: {
      type: Boolean,
      default: false
    }
  });

  const classes = computed(() => {
    return props.icon ? '!pl-12' : '';
  });

  const toast = useToast();
  const inputRef = ref(null);

  const query = ref('');
  const selectedValue = ref(props.modelValue);

  const emit = defineEmits(['update:modelValue', 'createValue', 'clear']);

  // Emit an event whenever selectedValue changes
  watch(selectedValue, (newValue) => {
    console.log(newValue);

    emit('update:modelValue', newValue);

    // clear combo box input
    query.value = '';
  });

  // Update selectedValue if the prop changes from the outside
  watch(
    () => props.modelValue,
    (newValue) => {
      if (newValue !== selectedValue.value) {
        selectedValue.value = newValue;
      }
    }
  );

  const filteredValues = computed(() => {
    if (props.type == 'static') {
      return props.values.filter((item) =>
        item.name.toLowerCase().includes(query.value.toLowerCase())
      );
    } else {
      return props.values;
    }
  });

  const addSelectedValue = ref(null);
  watch(addSelectedValue, (newValue, oldValue) => {
    if (!newValue) return;
    if (props.max && selectedValue.value.length >= props.max) {
      toast.error(
        __('You can select maximum') + ' ' + props.max + ' ' + __('values')
      );
      return;
    }

    if (props.multiple) {
      if (!selectedValue.value.some((value) => value.id === newValue.id)) {
        selectedValue.value.push(newValue);
      }
    } else {
      selectedValue.value = [newValue];
    }
    // clear combo box input
    query.value = '';
    emit('clear');
  });

  // Function to remove a value from selectedValue
  const removeSelectedValue = (index) => {
    selectedValue.value.splice(index, 1);
  };

  // Function to find the name of a value by its id
  const findValueName = (value) => {
    const item = props.values.find((item) => item.id === value.id);
    return item ? item.name : value.name;
  };

  const create_new = () => {
    console.log('create new', query.value);

    if (!props.can_create) {
      return;
    }

    if (!query.value) {
      return;
    }

    // if values is not empty
    if (props.values.length > 0) {
      return;
    }

    // check if is not max
    if (props.max && selectedValue.value.length >= props.max) {
      toast.error(
        __('You can select maximum') + ' ' + props.max + ' ' + __('values')
      );
      return;
    }

    var result = new Promise((resolve) =>
      emit('createValue', query.value, resolve)
    );

    result.then((value) => {
      console.log('value', value);
      props.values.push(value);
      selectedValue.value.push(value);

      query.value = '';
      emit('clear');
    });
  };

  const blurAfterSelect = () => {
    // other stuff
    setTimeout(() => {
      // blur the input
      inputRef.value.$el.blur();
    }, 0);
  };
</script>
