<template>
  <form class="space-y-4" @submit.prevent="handleSubmit">
    <Combobox
      by="email"
      multiple
      :model-value="values"
      @update:model-value="handleUpdateModelValue"
    >
      <div class="relative">
        <ComboboxLabel
          :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
        >
          {{ __('Invite people') }}
        </ComboboxLabel>

        <ComboboxButton
          id="combobox-button"
          :class="`${errors.length ? 'ring-error' : 'ring-gray-200'}  flex w-full cursor-default items-center justify-between gap-2 overflow-hidden rounded-md bg-white px-3.5 py-2.5 shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary`"
        >
          <Icon class="text-lg text-neutral-400" name="search" is-filled />

          <ComboboxInput
            :placeholder="
              __(
                'Invite specific group members or other users from the platform that are not part of the group.'
              )
            "
            class="w-full truncate border-none bg-transparent p-0 text-zinc-800 placeholder:text-zinc-500 focus:ring-0"
            @input="handleInput"
          />

          <Icon class="text-xl text-zinc-600" name="expand_more" />
        </ComboboxButton>

        <TransitionRoot
          leave-from="opacity-100"
          leave-to="opacity-0"
          @after-leave="handleAfterLeave"
        >
          <ComboboxOptions
            class="shadow-card absolute z-10 mt-1 max-h-60 w-max min-w-full max-w-full overflow-y-auto rounded-md border border-gray-200 bg-white p-1 text-sm leading-normal text-zinc-800 focus:outline-none"
          >
            <template v-if="options.length">
              <ComboboxOption
                v-for="option in options"
                :key="option"
                v-slot="{ selected, active }"
                :value="option"
                as="template"
              >
                <li
                  class="flex cursor-default select-none items-center justify-between gap-2 rounded-md px-3 py-1.5 transition-colors"
                  :class="{
                    'font-medium': selected,
                    'bg-gray-50': active
                  }"
                >
                  <div>
                    <span class="block">
                      {{ option.name }}
                    </span>
                    <span class="block text-xs text-zinc-600">
                      {{ option.email }}
                    </span>
                  </div>

                  <Icon v-if="selected" class="text-primary" name="check" />
                </li>
              </ComboboxOption>
            </template>

            <li
              v-else-if="isLoadingOptions"
              class="cursor-default select-none px-3 py-1.5"
            >
              {{ __('Loading...') }}
            </li>

            <li
              v-else-if="query.length < 3"
              class="cursor-default select-none px-3 py-1.5"
            >
              {{ __('Please input at least 3 characters') }}
            </li>

            <li
              v-else
              class="cursor-default select-none px-3 py-1.5 transition-colors hover:bg-zinc-50"
              @click="handleCustomOptionClick"
            >
              <span class="block">
                {{ __('Click here to add the following users') }}
              </span>
              <span class="block text-xs text-zinc-600">
                {{ query }}
              </span>
            </li>
          </ComboboxOptions>
        </TransitionRoot>

        <div v-if="values.length" class="mt-2 flex flex-wrap gap-1">
          <button
            v-for="value in values"
            :key="value"
            :aria-label="__('Clear')"
            type="button"
            class="flex items-center gap-1 rounded-lg border border-secondary px-2 py-1 text-sm text-zinc-800 transition-colors hover:bg-secondary/5"
            @click="handleClearButtonClick(value)"
          >
            <span>{{ value.email }}</span>

            <Icon class="text-error" name="close" />
          </button>
        </div>

        <div v-if="errors.length" class="mt-2 space-y-1">
          <span
            v-for="(error, index) in errors"
            :key="index"
            class="block text-xs text-error"
          >
            {{ error }}
          </span>
        </div>
      </div>
    </Combobox>

    <Button
      class="mx-auto flex items-center justify-center gap-1.5"
      :type="isLoadingSubmit ? 'button' : 'submit'"
      :disabled="isLoadingSubmit"
    >
      <Icon
        v-if="isLoadingSubmit"
        class="animate-spin text-lg"
        name="progress_activity"
      />
      <span>{{ __(isLoadingSubmit ? 'Loading...' : 'Invite') }}</span>
    </Button>
  </form>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { isValidEmail } from '@/scripts/validate.script';
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    TransitionRoot
  } from '@headlessui/vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['submit']);

  const toast = useToast();

  const values = ref([]);
  const errors = ref([]);
  const options = ref([]);
  const isLoadingOptions = ref(false);
  const isLoadingSubmit = ref(false);
  const query = ref('');

  function handleAfterLeave() {
    query.value = '';
    options.value = [];
  }

  async function handleInput(event) {
    query.value = event.target.value;

    if (query.value.length < 3 || query.value.split(',').length >= 2) {
      options.value = [];
      return;
    }

    isLoadingOptions.value = true;

    try {
      const response = await axios.get(route('redesign.events.searchMember'), {
        params: { name: query.value }
      });

      options.value = response.data.members;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingOptions.value = false;
  }

  async function handleCustomOptionClick() {
    const element = document.getElementById('combobox-button');

    if (element) {
      element.click();
    }

    errors.value = [];

    const emails = query.value
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email)
      .filter((email, index, self) => self.indexOf(email) === index);

    emails
      .filter((email) => !isValidEmail(email))
      .forEach((email) => {
        errors.value = [
          ...errors.value,
          `${email} ${__('is not a valid email address')}`
        ];
      });

    emails
      .filter((email) =>
        values.value.map((value) => value.email).includes(email)
      )
      .forEach((email) => {
        errors.value = [
          ...errors.value,
          `${email} ${__('has already been added to this list')}`
        ];
      });

    const validEmails = emails.filter(
      (email) =>
        isValidEmail(email) &&
        !values.value.map((value) => value.email).includes(email)
    );

    if (validEmails.length > 0) {
      try {
        const response = await axios.get(
          route('redesign.events.searchMember'),
          {
            params: { name: validEmails.join(',') }
          }
        );

        validEmails.forEach((email) => {
          const member = response.data.members.find(
            (member) => member.email === email
          );

          if (member) {
            values.value = [
              ...values.value,
              { id: member.id, email: member.email }
            ];
          } else {
            values.value = [...values.value, { id: null, email }];
          }
        });
      } catch {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }
  }

  function handleClearButtonClick(option) {
    values.value = values.value.filter((value) => value.email !== option.email);
    errors.value = [];
  }

  function handleUpdateModelValue(options) {
    values.value = options.map((option) => ({
      id: option.id,
      email: option.email
    }));
    errors.value = [];
  }

  async function handleSubmit() {
    if (!values.value.length) {
      return;
    }

    errors.value = [];

    isLoadingSubmit.value = true;

    try {
      const response = await axios.post(
        route('redesign.events.inviteMembers', {
          slug: props.event.details.slug
        }),
        { data: values.value }
      );

      if (response?.data?.errors?.length) {
        errors.value = response?.data?.errors;
      } else if (response?.data?.message) {
        toast.success(response.data.message);
      }

      values.value = [];

      emits('submit');
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingSubmit.value = false;
  }
</script>
