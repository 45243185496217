<template>
  <div class="flex flex-col gap-5 font-primary">
    <div class="flex flex-col gap-5">
      <div
        class="flex xl:flex-row flex-col xl:items-center items-start justify-between gap-5"
        v-if="isRefineClicked == false"
      >
        <h1 class="text-2xl sm:text-4xl font-bold">
          <span class="text-primary">{{ __("Search") }}</span>
          {{ __("for the perfect candidate") }}!
        </h1>
        <div class="flex flex-col gap-4">
          <Button @click="searchAgain" content="Search" variation="fill" />
        </div>
      </div>
      <div v-else>
        <h1 class="text-2xl sm:text-4xl font-primary font-bold">
          {{ __("Refine results") }}
        </h1>
      </div>
      <div
        class="flex items-start xl:gap-0 gap-5 xl:w-56 w-full xl:self-end self-start"
      >
        <div class="flex relative group">
          <p>
            {{ __("Mark mandatory fields")
            }}<svg
              class="fill-primary inline-flex ml-1"
              width="15"
              height="15"
              xmlns="http://www.w3.org/2000/svg"
            >
              <use xlink:href="/images/front/file_svg.svg#info-circle"></use>
            </svg>
          </p>
          <div
            class="absolute invisible group-hover:visible top-14 w-40 p-3 z-50 rounded-app text-xs bg-white shadow-md"
          >
            {{
              __(
                "Mark which of the fields should be mandatory for a more accurate search."
              )
            }}
          </div>
        </div>
        <Toggle
          @change="switchMandatory"
          :value="markMandatory"
          :setid="'markMandatory'"
        />
      </div>
    </div>
    <div v-for="(filter, index) in arrayOfFilters" v-bind:key="index">
      <div class="bg-white rounded-app" v-if="filter.show">
        <div
          class="flex p-5 pb-3 py-3 justify-between"
          @click="filter.isOpen = !filter.isOpen"
        >
          <div class="flex justify-between py-2 items-center">
            <div class="flex items-center gap-2">
              <!-- <SvgLoader :icon="filter.icon" class="w-4 h-4 " /> -->
              <SvgList :icon="filter.icon" class="text-primary" />

              <h3 class="font-primary font-bold uppercase">
                {{ filter.title }}
              </h3>
            </div>
          </div>
          <span class="cursor-pointer flex justify-between py-2 items-center">
            <span class="material-symbols-rounded"> expand_more </span>
          </span>
        </div>
        <div name="fade">
          <div v-if="filter.isOpen">
            <component
              :is="filter.component"
              class="pt-5 border-t-4 border-dotted"
              :markMandatory="markMandatory"
              :filterData="filter.props"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, shallowRef, resolveComponent, onMounted } from "vue";
import { Button, SvgList, Toggle } from "../../global";
import {
  SearchCandidateAvailability,
  SearchCandidateEducation,
  SearchCandidateExperience,
  SearchCandidateSkills,
  SearchCandidateTested,
  SearchCandidateLanguages,
  SearchCandidateContactDetails,
  SearchCandidateAbout,
} from ".";
import { __ } from "../../../helpers";

const props = defineProps({
  filterAvailability: Array,
  filterContactDetails: Array,
  filterAbout: Array,
  isRefineClicked: Boolean,
  degrees: Array,
  filterExperience: Object,
  skills: Array,
  testedSkills: Array,
  languages: Array,
  markMandatory: Boolean,
  config_display_users_anonymously: Number,
});

// const markMandatory = ref(false);
const isSearchClicked = ref(false);

// define the emits that helps us to communicate with the parent component
const emit = defineEmits([
  "onsearch-click",
  "searchAgain",
  "updateCandidates",
  "switchMandatory",
]);

// define the array of filters to help us render the filters dynamically
const arrayOfFilters = ref([
  {
    title: __("Contact details"),
    show: props.config_display_users_anonymously == 1 ? false : true,
    component: shallowRef(SearchCandidateContactDetails),
    props: props.filterContactDetails,
    markMandatory: props.markMandatory,
    isOpen: false,
    icon: "name",
  },
  {
    title: __("About"),
    show: true,
    component: shallowRef(SearchCandidateAbout),
    props: props.filterAbout,
    markMandatory: props.markMandatory,
    isOpen: false,
    icon: "user",
  },
  {
    title: __("Availability"),
    show: true,
    component: shallowRef(SearchCandidateAvailability),
    props: props.filterAvailability,
    markMandatory: props.markMandatory,
    isOpen: false,
    icon: "check",
  },
  {
    title: __("Education"),
    show: true,
    component: shallowRef(SearchCandidateEducation),
    props: props.degrees,
    markMandatory: props.markMandatory,
    isOpen: false,
    icon: "house_small",
  },
  {
    title: __("Experience"),
    show: true,
    component: shallowRef(SearchCandidateExperience),
    props: props.filterExperience,
    markMandatory: props.markMandatory,
    isOpen: false,
    icon: "briefcase",
  },
  {
    title: __("Skills"),
    show: true,
    component: shallowRef(SearchCandidateSkills),
    props: props.skills,
    markMandatory: props.markMandatory,
    isOpen: false,
    icon: "star",
  },
  // {
  //   title: __("Tested skills"),
  //   show: true,
  //   component: shallowRef(SearchCandidateTested),
  //   props: props.testedSkills,
  //   markMandatory: props.markMandatory,
  //   isOpen: false,
  //   icon: "ribbon2",
  // },
  {
    title: __("Languages"),
    show: true,
    component: shallowRef(SearchCandidateLanguages),
    props: props.languages,
    markMandatory: props.markMandatory,
    isOpen: false,
    icon: "language",
  },
]);

// Helper functions
function onSearchClick(event) {
  emit("onsearch-click", !event);
}

const searchAgain = () => {
  onSearchClick(isSearchClicked);
  emit("searchAgain");
};

const updateCandidates = () => {
  emit("updateCandidates");
};

const switchMandatory = () => {
  emit("switchMandatory");
};

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
