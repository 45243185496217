<template>
  <div class="shadow-card rounded-2xl bg-white p-6 md:p-8">
    <h2 class="text-3xl font-medium text-zinc-800 md:text-4xl">
      {{ __('Complete your profile') }}
    </h2>

    <span class="mt-2 block leading-normal text-zinc-600">
      {{ __('To apply to a job you need to have your resume completed.') }}
    </span>

    <span
      class="ml-auto mr-10 block w-fit text-lg font-medium text-secondary md:mr-3 md:text-2xl"
    >
      {{ `${resumePercentage}%` }}
    </span>

    <div class="flex items-center gap-2">
      <button
        class="flex rounded-full p-1.5 transition-colors hover:bg-zinc-50 disabled:pointer-events-none disabled:opacity-50 md:!hidden"
        :disabled="isPreviousButtonDisabled"
        :aria-label="__('Previous')"
        @click="handlePreviousButtonClick"
      >
        <Icon class="text-xl text-zinc-600" name="keyboard_arrow_left" />
      </button>

      <div
        ref="scrollContainer"
        class="-my-[22px] flex w-full snap-x snap-mandatory gap-2 overflow-x-auto overflow-y-clip md:my-0 md:gap-0.5"
      >
        <ProgressBar
          v-for="section in sections"
          :key="section.id"
          :class="`min-w-full snap-start pt-[22px] md:min-w-fit md:pt-4`"
          :section="section"
        />
      </div>

      <button
        class="flex rounded-full p-1.5 transition-colors hover:bg-zinc-50 disabled:pointer-events-none disabled:opacity-50 md:!hidden"
        :disabled="isNextButtonDisabled"
        :aria-label="__('Next')"
        @click="handleNextButtonClick"
      >
        <Icon class="text-xl text-zinc-600" name="keyboard_arrow_right" />
      </button>
    </div>
  </div>
</template>

<script setup>
  import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';
  import { ProgressBar } from './components/progress-bar';

  const props = defineProps({
    percentages: {
      type: Object,
      required: true
    },
    resumePercentage: {
      type: Number,
      required: true
    }
  });

  const keyToNameMap = {
    about_me: __('About me'),
    availability: __('Availability'),
    experiences: __('Experience'),
    educations: __('Education'),
    skills: __('Skills'),
    others: __('Others')
  };

  const sections = computed(() => {
    return Object.keys(props.percentages).map((key) => {
      return {
        id: key,
        name: keyToNameMap[key] || key,
        completed: props.percentages[key].completed,
        total: props.percentages[key].total
      };
    });
  });

  const scrollContainer = ref(null);
  const isPreviousButtonDisabled = ref(false);
  const isNextButtonDisabled = ref(false);

  function handlePreviousButtonClick() {
    if (!scrollContainer.value) {
      return;
    }

    scrollContainer.value.scrollBy({
      left: -scrollContainer.value.clientWidth,
      behavior: 'smooth'
    });
  }

  function handleNextButtonClick() {
    if (!scrollContainer.value) {
      return;
    }

    scrollContainer.value.scrollBy({
      left: scrollContainer.value.offsetWidth,
      behavior: 'smooth'
    });
  }

  function handleScrollableContentScrollEvent() {
    if (!scrollContainer.value) {
      return;
    }

    isPreviousButtonDisabled.value = scrollContainer.value.scrollLeft <= 0;
    isNextButtonDisabled.value =
      scrollContainer.value.scrollLeft >=
      scrollContainer.value.scrollWidth - scrollContainer.value.clientWidth;
  }

  onMounted(() => {
    if (!scrollContainer.value) {
      return;
    }

    scrollContainer.value.addEventListener(
      'scroll',
      handleScrollableContentScrollEvent
    );

    isPreviousButtonDisabled.value = scrollContainer.value.scrollLeft <= 0;
    isNextButtonDisabled.value =
      scrollContainer.value.scrollLeft >=
      scrollContainer.value.scrollWidth - scrollContainer.value.clientWidth;
  });

  onBeforeUnmount(() => {
    if (!scrollContainer.value) {
      return;
    }

    scrollContainer.value.removeEventListener(
      'scroll',
      handleScrollableContentScrollEvent
    );
  });
</script>
