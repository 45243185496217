<template>
  <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
    <form @submit.prevent="handleSubmit">
      <RichTextEditor
        id="about_us"
        v-model:value="values.about_us"
        v-model:errors="errors.about_us"
        :label="__('About company')"
        :placeholder="
          __(
            'Here you can add more details about the company in general, company culture, ways of working.'
          )
        "
      />

      <div class="mt-8 grid gap-4 md:grid-cols-2 md:gap-y-8">
        <div class="relative">
          <InputText
            id="email"
            v-model:value="values.email.value"
            v-model:errors="errors.email"
            :label="__('Email')"
            :placeholder="__('Email')"
          >
            <template #icon>
              <Icon class="text-lg text-neutral-400" name="mail" is-filled />
            </template>
          </InputText>

          <button
            class="absolute right-0 top-[3px] flex items-center gap-1 text-zinc-600"
            type="button"
            @click="handleVisibilityButtonClick('email')"
          >
            <span class="text-xs">
              {{
                __(
                  `${values.email.is_visible ? 'Visible to candidates' : 'Not visible to candidates'}`
                )
              }}
            </span>
            <Icon
              class="text-lg"
              :name="values.email.is_visible ? 'visibility' : 'visibility_off'"
            />
          </button>
        </div>

        <div class="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5">
          <InputSelect
            id="country_prefix"
            v-model:value="values.country_prefix"
            v-model:errors="errors.country_prefix"
            class="col-span-1 sm:col-span-2"
            :label="__('Country prefix')"
            :placeholder="__('Country prefix')"
            :options="options.country_prefix"
          />

          <div class="relative col-span-1 sm:col-span-3">
            <InputText
              id="phone_number"
              v-model:value="values.phone_number.value"
              v-model:errors="errors.phone_number"
              :label="__('Phone')"
              :placeholder="__('Phone')"
            >
              <template #icon>
                <Icon class="text-lg text-neutral-400" name="phone" is-filled />
              </template>
            </InputText>

            <button
              class="absolute right-0 top-[3px] flex items-center gap-1 text-zinc-600"
              type="button"
              @click="handleVisibilityButtonClick('phone_number')"
            >
              <span class="text-xs">
                {{
                  __(
                    `${values.phone_number.is_visible ? 'Visible to candidates' : 'Not visible to candidates'}`
                  )
                }}
              </span>
              <Icon
                class="text-lg"
                :name="
                  values.phone_number.is_visible
                    ? 'visibility'
                    : 'visibility_off'
                "
              />
            </button>
          </div>
        </div>

        <InputLocation
          id="location"
          v-model:value="values.location"
          v-model:errors="errors.location"
          :label-country="__('Country')"
          :label-city="__('City')"
          :placeholder-country="__('Select country')"
          :placeholder-city="__('Select city')"
          :options="options.country"
          class="md:col-span-2"
        />

        <InputText
          id="address"
          v-model:value="values.address"
          v-model:errors="errors.address"
          :label="__('Address')"
          :placeholder="__('Add address')"
          required
        >
          <template #icon>
            <Icon class="text-lg text-neutral-400" name="near_me" is-filled />
          </template>
        </InputText>

        <InputMultiSelect
          id="benefits"
          v-model:values="values.benefits"
          v-model:errors="errors.benefits"
          :label="__('Benefits')"
          :placeholder="__('Company benefits')"
          :options="options.benefits"
        >
          <template #icon>
            <Icon class="text-lg text-neutral-400" name="loyalty" is-filled />
          </template>
        </InputMultiSelect>

        <InputMultiSelect
          id="values"
          v-model:values="values.values"
          v-model:errors="errors.values"
          :label="__('Values')"
          :placeholder="__('Company values')"
          :options="options.values"
        >
          <template #icon>
            <Icon class="text-lg text-neutral-400" name="favorite" is-filled />
          </template>
        </InputMultiSelect>
      </div>

      <span
        v-if="errorMessage"
        class="mt-8 block text-center text-sm text-error"
      >
        {{ errorMessage }}
      </span>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputLocation } from '@/components/form/input-location';
  import { InputMultiSelect } from '@/components/form/input-multi-select';
  import { InputSelect } from '@/components/form/input-select';
  import { InputText } from '@/components/form/input-text';
  import { RichTextEditor } from '@/components/form/rich-text-editor';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  });

  const emits = defineEmits(['save', 'cancel']);

  const toast = useToast();

  const data = ref(JSON.parse(JSON.stringify(props.data)));
  const values = ref({
    about_us: data.value.about_us || '',
    email: { ...data.value.email, value: data.value.email.value || '' },
    country_prefix: data.value.country_prefix || null,
    phone_number: {
      ...data.value.phone_number,
      value: data.value.phone_number.value || ''
    },
    location: data.value.location || null,
    address: data.value.address || '',
    benefits: data.value.benefits || [],
    values: data.value.values || []
  });
  const errors = ref({
    about_us: [],
    email: [],
    country_prefix: [],
    phone_number: [],
    location: [],
    address: [],
    benefits: [],
    values: []
  });
  const isLoading = ref(false);

  function handleVisibilityButtonClick(key) {
    values.value[key].is_visible = !values.value[key].is_visible;
  }

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    const requiredFieldKeys = ['location', 'address'];
    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (
      values.value.location &&
      values.value.location.country &&
      !values.value.location.city
    ) {
      errors.value.location = [__('This field is required')];

      const element = document.getElementById('location');

      if (element && !firstElement) {
        firstElement = element;
      }
    }

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.company.update.about'),
        values.value
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', response.data.data);
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
