<template>
  <div class="mt-1 flex flex-col gap-5" v-if="candidates.length > 0">
    <div
      class="sticky top-16 flex h-8 items-center justify-end bg-gray-100 py-7 pl-5 font-primary font-bold sm:top-20 lg:top-36"
      v-if="
        isRefineClicked == false &&
        (FEATURES['bulk_invite_to_job'] || FEATURES['bulk_add_to_pipeline'])
      "
    >
      <!-- Bulk Candidates -->
      <div class="flex gap-5" v-if="FEATURES['bulk_candidates']">
        <input
          type="checkbox"
          @change="all_check($event)"
          :checked="checkedNames.length < candidates?.length ? false : true"
        />
        {{ __('Bulk select') }} ({{ checkedNames.length }}
        {{ __('Candidates') }})
      </div>
      <!-- Bulk Candidates -->

      <!-- Actions -->
      <div
        class="flex flex-row items-center justify-between"
        v-if="isRefineClicked == false"
      >
        <div name="fade">
          <div
            class="hidden gap-5 md:flex"
            :class="
              checkedNames.length > 0
                ? 'opacity-100 duration-300'
                : 'opacity-0 duration-300'
            "
            v-if="checkedNames.length > 0"
          >
            <div
              class="relative w-auto"
              v-if="
                // check if available_jobs is not undefined and if it has at least one job
                available_jobs &&
                Object.keys(available_jobs).length > 0 &&
                FEATURES['bulk_invite_to_job']
              "
            >
              <div
                class="w-full bg-secondary px-5 py-2 text-center font-primary text-white hover:cursor-pointer hover:bg-secondary-click"
                @click="openJobInvite"
                :class="isJobInvite ? 'rounded-t-app' : 'rounded-app'"
              >
                {{
                  config_general_single_company
                    ? __('Invite to job / Add to Kanban')
                    : __('Invite to job')
                }}
              </div>
              <div
                class="absolute -right-2/4 z-40 flex h-max w-80 flex-col items-center gap-3 bg-white p-5 shadow-lg"
                v-if="isJobInvite"
              >
                <div class="w-full">
                  <Multiselect
                    v-model="jobs"
                    :values="Object.values(available_jobs)"
                    :clearable="false"
                    type="static"
                  />
                </div>
                <div
                  class="flex w-full justify-between font-primary text-white"
                >
                  <button
                    @click="[(isJobInvite = false), bulkInviteToJob()]"
                    class="flex w-6 justify-center rounded-app bg-secondary px-12 py-2 hover:cursor-pointer hover:bg-secondary-click"
                  >
                    {{ __('Invite') }}
                  </button>
                  <button class="text-primary" @click="isJobInvite = false">
                    {{ __('Discard') }}
                  </button>
                </div>
              </div>
            </div>

            <div
              class="relative w-auto"
              v-if="
                // check if available_jobs is not undefined and if it has at least one job
                available_pipelines &&
                Object.keys(available_pipelines).length > 0 &&
                FEATURES['bulk_add_to_pipeline']
              "
            >
              <div
                class="w-full bg-secondary px-5 py-2 text-center font-primary text-white hover:cursor-pointer hover:bg-secondary-click"
                @click="openPipelineModal"
                :class="isPipeline ? 'rounded-t-app' : 'rounded-app'"
              >
                {{ __('Add to pipeline') }}
              </div>
              <div
                class="absolute -right-2/4 flex h-max w-80 flex-col items-center gap-3 bg-white p-5 shadow-lg"
                v-if="isPipeline"
              >
                <div class="w-full">
                  <Multiselect
                    v-model="pipelines"
                    :values="Object.values(available_pipelines)"
                    :clearable="false"
                    type="static"
                  />
                </div>
                <div
                  class="flex w-full justify-between font-primary text-white"
                >
                  <button
                    @click="[(isPipeline = false), bulkAddToPipeline()]"
                    class="flex w-6 justify-center rounded-app bg-secondary px-12 py-2 hover:cursor-pointer hover:bg-secondary-click"
                  >
                    {{ __('Add') }}
                  </button>
                  <button class="text-primary" @click="isPipeline = false">
                    {{ __('Discard') }}
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="
                Object.keys(available_pipelines).length == 0 &&
                FEATURES['bulk_add_to_pipeline']
              "
            >
              {{ __('No pipelines available') }}
            </div>
          </div>
        </div>
        <div name="fade">
          <div
            class="relative md:hidden"
            v-if="isRefineClicked == false && checkedNames.length > 0"
          >
            <button
              @click="isActionsMobileModal"
              class="bg-secondary px-5 py-1 text-white hover:bg-secondary-click"
              :class="isActionsMobile == true ? 'rounded-t-app' : 'rounded-app'"
            >
              {{ __('Actions') }}
            </button>
            <div
              class="absolute right-0 flex w-60 flex-col gap-5 bg-white p-5 shadow-md sm:w-80"
              v-if="isActionsMobile"
            >
              <div
                class="relative"
                v-if="
                  // check if available_jobs is not undefined and if it has at least one job
                  available_jobs &&
                  Object.keys(available_jobs).length > 0 &&
                  FEATURES['bulk_invite_to_job']
                "
              >
                <div
                  class="w-full bg-secondary py-1 text-center font-primary text-white hover:cursor-pointer hover:bg-secondary-click"
                  @click="openJobInviteMobile"
                  :class="isJobInviteMobile ? 'rounded-t-app' : 'rounded-app'"
                >
                  {{
                    config_general_single_company
                      ? __('Invite to job / Add to Kanban')
                      : __('Invite to job')
                  }}
                </div>
                <div
                  class="absolute right-0 z-20 flex h-max w-full flex-col items-center gap-3 bg-white p-5 shadow-lg lg:w-80"
                  v-if="isJobInviteMobile"
                >
                  <div class="w-full">
                    <Multiselect
                      v-model="jobs"
                      :values="Object.values(available_jobs)"
                      :clearable="false"
                      type="static"
                    />
                  </div>
                  <div
                    class="flex w-full justify-between font-primary text-white"
                  >
                    <button
                      @click="[(isJobInviteMobile = false), bulkInviteToJob()]"
                      class="flex w-6 justify-center rounded-app bg-secondary px-12 py-2 hover:cursor-pointer hover:bg-secondary-click"
                    >
                      {{ __('Invite') }}
                    </button>
                    <button
                      class="text-primary"
                      @click="isJobInviteMobile = false"
                    >
                      {{ __('Discard') }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="relative"
                v-if="
                  // check if available_jobs is not undefined and if it has at least one job
                  available_pipelines &&
                  Object.keys(available_pipelines).length > 0 &&
                  FEATURES['bulk_add_to_pipeline']
                "
              >
                <div
                  class="w-full bg-secondary py-1 text-center font-primary text-white hover:cursor-pointer hover:bg-secondary-click"
                  @click="openPipelineModalMobile"
                  :class="isPipelineMobile ? 'rounded-t-app' : 'rounded-app'"
                >
                  {{ __('Add to pipeline') }}
                </div>
                <div
                  class="absolute flex h-max w-full flex-col items-center gap-3 bg-white p-5 shadow-lg"
                  v-if="isPipelineMobile"
                >
                  <div class="w-full">
                    <Multiselect
                      v-model="pipelines"
                      :values="Object.values(available_pipelines)"
                      :clearable="false"
                      type="static"
                    />
                  </div>
                  <div
                    class="flex w-full justify-between font-primary text-white"
                  >
                    <button
                      @click="[(isPipelineMobile = false), bulkAddToPipeline()]"
                      class="flex w-6 justify-center rounded-app bg-secondary px-12 py-2 hover:cursor-pointer hover:bg-secondary-click"
                    >
                      {{ __('Add') }}
                    </button>
                    <button
                      class="text-primary"
                      @click="isPipelineMobile = false"
                    >
                      {{ __('Discard') }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  Object.keys(available_pipelines).length == 0 &&
                  FEATURES['bulk_add_to_pipeline']
                "
              >
                {{ __('No pipelines available') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Actions -->
    </div>

    <div v-for="(item, index) in candidates" v-bind:key="index">
      <div
        class="gap-5 rounded-app bg-white p-5 lg:grid"
        :class="isRefineClicked == false ? 'lg:grid-cols-10' : 'lg:grid-cols-6'"
      >
        <div class="col-span-3 lg:flex lg:items-center">
          <div class="grid grid-cols-10 gap-5">
            <div class="col-span-1" v-if="isRefineClicked == false">
              <input
                type="checkbox"
                class="h-4 w-4 rounded-md border-2 accent-primary focus:ring-0"
                :value="item.id"
                v-model="checkedNames"
              />
            </div>
            <!-- 
            <div class="col-span-2">
              <a :href="item.cv_url" target="_blank"
                ><img
                  :src="item.photo_url"
                  class="w-16 border-gray-300 border-2 rounded-full"
              /></a>
            </div> -->
            <div class="col-span-9 flex flex-col gap-3">
              <a
                class="flex cursor-pointer items-center gap-2"
                :href="item.cv_url"
                target="_blank"
                @click.prevent="handleOpenResumeModal(item.cv_url)"
              >
                <h3 class="font-primary font-semibold hover:text-secondary">
                  <span>{{ index + 1 }}. </span>{{ item.name }}
                  <Icon name="open_in_new" class="!text-sm text-primary" />
                </h3>
              </a>
              <div class="flex gap-4 rounded-lg font-primary font-medium">
                <span
                  class="w-8 text-xs sm:text-base"
                  :style="colorProcent(item.percentages['Fit'])"
                  >{{ item.percentages['Fit'] }}</span
                >
                <span class="text-xs sm:text-base">{{ __('Fit') }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-3 lg:flex lg:items-center">
          <div
            class="flex flex-col break-words py-2 font-primary font-medium lg:py-0"
          >
            <span class="flex items-center gap-2" v-if="item.location">
              <span>
                <Icon name="location_on" class="text-primary" />
              </span>
              {{ item.location }}
            </span>
            <span class="flex items-center gap-2" v-if="item.experience">
              <span>
                <Icon name="auto_graph" class="text-primary" />
              </span>
              {{ item.experience }} {{ __('years') }}
            </span>
            <span class="flex items-center gap-2" v-if="item.job">
              <span>
                <Icon name="work" class="text-primary" />
              </span>
              {{ item.job }}
            </span>
          </div>
        </div>

        <div
          class="col-span-4 lg:flex lg:items-center"
          v-if="isRefineClicked == false"
        >
          <div
            class="grid grid-cols-2 gap-y-2 sm:grid-cols-3 sm:gap-x-5 lg:grid-cols-2"
          >
            <div
              v-for="(value, name, index) in config_display_users_anonymously ==
              1
                ? Object.keys(item.percentages)
                    .filter((key) => key != 'Name')
                    .reduce((obj, key) => {
                      obj[key] = item.percentages[key];
                      return obj;
                    }, {})
                : item.percentages"
              v-bind:key="index"
            >
              <div
                class="flex gap-4 rounded-lg px-2 py-0.5 font-primary font-medium"
                v-if="name != 'Fit'"
              >
                <span
                  class="w-8 text-xs sm:text-base"
                  :style="colorProcent(value)"
                  >{{ value }}</span
                >
                <span class="text-xs sm:text-base">{{ __(name) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <Button
        @click="fetchCandidates"
        v-if="page != -1 && (page != 2 || candidates.length >= 10)"
        content="Load more"
        variation="fill"
      />
    </div>

    <ResumeModal
      v-if="isResumeModalOpen"
      v-model:username="candidateUsername"
      :username-list="candidateUsernameList"
      @close="handleCloseResumeModal"
      @update:currentIndex="handleCurrentIndexUpdate"
    />
  </div>
</template>
<script setup>
  import { computed, ref, watch, onMounted } from 'vue';
  import { __ } from '../../../helpers';
  import { useToast } from 'vue-toastification';
  import { MAX_ADD_TO_PIPELINE, MAX_INVITE_TO_JOB } from '../../../constants';
  import { SvgList, Icon, Multiselect, Button } from '../../global';
  import { ResumeModal } from '@/components/other/resume-modal';

  const toast = useToast();
  const props = defineProps({
    allChecked: Boolean,
    candidates: Array,
    isRefineClicked: Boolean,
    page: Number,
    available_pipelines: Object,
    available_jobs: Object,
    config_general_single_company: Number,
    config_display_users_anonymously: Number
  });

  const FEATURES = ref({
    bulk_invite_to_job: false,
    bulk_candidates: false,
    bulk_add_to_pipeline: false
  });

  const emit = defineEmits(['updateCandidates', 'searchAgain']);

  // Function to append new candidates from the API to the existing candidates
  const fetchCandidates = () => {
    emit('updateCandidates');
  };

  const isPipeline = ref(false);

  const pipelines = ref('');

  const jobs = ref('');
  const isJobInvite = ref(false);
  const isJobInviteMobile = ref(false);
  const isPipelineMobile = ref(false);
  const allCheckedInput = ref(false);
  const isActions = ref(false);
  const isActionsMobile = ref(false);
  const checkedNames = ref([]);
  const procent = ref(70);

  function onClickOutside() {
    // if (isActions) {
    //   isActions = false;
    // }
    if (isPipeline.value) {
      isPipeline.value = false;
    }
    if (isPipelineMobile.value) {
      isPipelineMobile.value = false;
    }
    if (isJobInvite.value) {
      isJobInvite.value = false;
    }
    if (isJobInviteMobile.value) {
      isJobInviteMobile.value = false;
    }
    if (isActionsMobile.value) {
      isActionsMobile.value = false;
    }
  }
  function openPipelineModal() {
    isPipeline.value = !isPipeline.value;

    // Check if job invite modal is open and close it
    if (isJobInvite.value) {
      isJobInvite.value = false;
    }
  }
  function openPipelineModalMobile() {
    isPipelineMobile.value = !isPipelineMobile.value;

    // Check if job invite modal is open and close it
    if (isJobInviteMobile.value) {
      isJobInviteMobile.value = false;
    }
  }
  function openJobInvite() {
    isJobInvite.value = !isJobInvite.value;

    // Check if pipeline modal is open and close it
    if (isPipeline.value) {
      isPipeline.value = false;
    }
  }
  function openJobInviteMobile() {
    isJobInviteMobile.value = !isJobInviteMobile.value;

    // Check if pipeline modal is open and close it
    if (isPipelineMobile.value) {
      isPipelineMobile.value = false;
    }
  }
  function isActionsModal() {
    isActions.value = !isActions.value;
  }
  function isActionsMobileModal() {
    isActionsMobile.value = !isActionsMobile.value;
  }
  function all_check(event) {
    if (event.target.checked == true) {
      for (let i = 0; i < props.candidates.length; i++) {
        checkedNames.value.push(props.candidates[i].id);
      }
    } else {
      checkedNames.value = [];
    }
  }

  function openResume(item) {
    item.isResume = !item.isResume;
  }
  function onClickOutsideResume() {
    for (let i = 0; i < props.candidates.length; i++) {
      if (props.candidates[i].isResume) {
        props.candidates[i].isResume = false;
      }
    }
  }
  function colorProcent(procent) {
    if (procent == 'N/A') {
      return 'color: #9ca3af';
    }

    let parsed_procent = procent;

    // check if the procent is a string
    if (typeof parsed_procent === 'string') {
      // extract the number from the string and convert to number, the string is like "70%"
      parsed_procent = parseInt(
        procent.toString().substring(0, procent.length - 1)
      );
    }

    if (parsed_procent >= 0 && parsed_procent <= 45) {
      return 'color: #FF5449';
    } else if (parsed_procent >= 46 && parsed_procent <= 54) {
      return 'color: #FEC84B ';
    }
    return 'color: #12B76A';
  }

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const bulkInviteToJob = () => {
    if (jobs.value == '') {
      toast.error(__('Please select a job'));
      return;
    }

    if (checkedNames.value.length > MAX_INVITE_TO_JOB) {
      toast.error(
        __('You can invite maximum') +
          ' ' +
          MAX_INVITE_TO_JOB +
          ' ' +
          __('candidates at once')
      );
      return;
    }

    axios
      .post(
        route('company.invite.bulk.job'),
        {
          user_ids: JSON.stringify(checkedNames.value),
          job_id: getKeyByValue(props.available_jobs, jobs.value)
        },
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest'
          }
        }
      )
      .then((response) => {
        if (response.data.status == 'success' || response.data.status == true) {
          toast.success(response.data.message);
          return;
        }
        toast.error(response.data.message);
      });
  };

  const bulkAddToPipeline = () => {
    if (pipelines.value == '') {
      toast.error(__('Please select a pipeline'));
      return;
    }

    if (checkedNames.value.length > MAX_ADD_TO_PIPELINE) {
      toast.error(
        __('You can add maximum') +
          ' ' +
          MAX_ADD_TO_PIPELINE +
          ' ' +
          __('candidates at once')
      );
      return;
    }

    axios
      .post(
        route('company.pipelines-candidates.store.bulk'),
        {
          user_ids: checkedNames.value,
          pipeline_id: getKeyByValue(props.available_pipelines, pipelines.value)
        },
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest'
          }
        }
      )
      .then((response) => {
        if (response.data.status == 'success' || response.data.status == true) {
          toast.success(response.data.message);
          return;
        }
        toast.error(response.data.message);
      });
  };

  // Create a reactive ref that can be exposed by the component
  // To remove the checked names when search again
  const removeCheckedNames = () => {
    checkedNames.value = [];
  };

  defineExpose({
    removeCheckedNames
  });

  const candidateUsernameList = computed(() => {
    return props.candidates.map(
      (candidate) => candidate.cv_url.split('/').slice(-2, -1)[0]
    );
  });
  const candidateUsername = ref(null);
  const candidateIndex = ref(null);
  const isResumeModalOpen = ref(false);

  function handleOpenResumeModal(url) {
    candidateUsername.value = url.split('/').slice(-2, -1)[0];
    candidateIndex.value = candidateUsernameList.value.indexOf(
      candidateUsername.value
    );
    isResumeModalOpen.value = true;
  }

  function handleCloseResumeModal() {
    candidateUsername.value = null;
    isResumeModalOpen.value = false;
  }

  function handleCurrentIndexUpdate(newIndex) {
    candidateIndex.value = newIndex;
  }

  watch(
    () => candidateIndex.value,
    (value) => {
      if (
        value >= candidateUsernameList.value.length - 1 &&
        props.page !== -1 &&
        (props.page !== 2 || props.candidates.length >= 10)
      ) {
        fetchCandidates();
      }
    }
  );
</script>
