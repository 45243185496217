<template>
  <Combobox as="div" v-model="modelValue" by="id" nullable>
    <div class="relative md:w-full">
      <div class="relative">
        <ComboboxInput
          class="w-full rounded-app border-0 bg-white py-1.5 pl-3 pr-14 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm sm:leading-6"
          :class="classes"
          @change="
            [(query = $event.target.value), $emit('query', $event.target.value)]
          "
          :display-value="(current) => (current ? current.name : '')"
          :placeholder="__(placeholder)"
          autocomplete="off"
          :required="required"
          ref="inputRef"
          @keyup.enter="create_new(query)"
        />
        <Icon
          v-if="icon"
          :name="icon"
          aria-hidden="true"
          class="absolute left-3 top-1/2 -translate-y-1/2 transform text-[#A3A3A3]"
        />
      </div>
      <ComboboxButton
        style="width: 100%; justify-content: end"
        class="absolute inset-y-0 right-0 flex items-center rounded-r-app px-2 focus:outline-none"
      >
        <span class="material-symbols-rounded" aria-hidden="true">
          expand_more
        </span>
        <span class="sr-only">{{ __('Expand') }}</span>
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredValues?.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-app bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-for="item in filteredValues"
          :key="item.id"
          :value="item"
          as="template"
          v-slot="{ active, selected, disabled }"
          :disabled="item.is_disabled"
        >
          <li
            :class="[
              disabled ? 'cursor-not-allowed opacity-50' : '',
              'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-700',
              active ? 'bg-gray-50' : ''
            ]"
          >
            <span :class="['block', selected && 'font-semibold']">
              <span v-if="item.is_child" class="pr-4"></span>
              {{ item.name }}
            </span>

            <span
              v-if="modelValue?.id == item.id"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4 text-gray-700',
                active ? '' : ''
              ]"
            >
              <span class="material-symbols-rounded text-primary"> done </span>
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>

      <ComboboxOptions
        v-if="filteredValues?.length == 0 && query.length >= 3"
        @click="(event) => event.preventDefault()"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-app bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption v-if="!can_create">
          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9']">
            {{ __('No results found') }}
          </li>
        </ComboboxOption>

        <ComboboxOption v-else @click="create_new(query)">
          <li :class="['relative select-none py-2 pl-3 pr-9']">
            <span class="cursor-pointer' text-primary">{{
              __('This is a new option, press to add it')
            }}</span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>

      <ComboboxOptions
        v-if="filteredValues?.length == 0 && query.length < 3"
        @click="(event) => event.preventDefault()"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-app bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption>
          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9']">
            {{ __('Please enter 3 or more characters') }}
          </li>
        </ComboboxOption>
      </ComboboxOptions>

      <div
        class="absolute inset-y-0 right-6 flex cursor-pointer items-center justify-center px-2 font-primary font-normal text-gray-400"
        @click="[$emit('clear'), (query = '')]"
        v-if="modelValue != null && clearable"
      >
        <span class="material-symbols-rounded" aria-hidden="true"> close </span>
        <span class="sr-only">{{ __('Delete') }}</span>
      </div>
    </div>

    <div v-if="errors">
      <p
        class="mt-1 text-xs text-error"
        v-for="(error, index) in errors"
        :key="index"
      >
        {{ error }}
      </p>
    </div>
  </Combobox>
</template>

<script setup>
  import {
    computed,
    ref,
    watch,
    onMounted
  } from 'vue';
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions
  } from '@headlessui/vue';
  import { __ } from '../../../helpers';
  import { Icon } from '..';
  import { useLoading } from 'vue-loading-overlay';

  const props = defineProps({
    values: Array,
    clearable: {
      type: Boolean
    },
    modelValue: {
      type: Object,
      default: {
        id: null,
        name: null
      }
    },
    type: {
      validator(value) {
        // The value must match one of these strings
        return ['static', 'dynamic'].includes(value);
      },
      default: 'static'
    },
    placeholder: {
      type: String,
      default: __('Search here...')
    },
    icon: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    can_create: {
      type: Boolean,
      default: false
    }
  });

  const $loading = useLoading();

  const query = ref('');
  const modelValue = ref(props.modelValue); // Use a ref to track the model value locally

  const emit = defineEmits(['update:modelValue', 'createValue', 'query']);

  const inputRef = ref(null);

  // Watch for changes to the local modelValue and emit an event for the parent to update the value
  watch(modelValue, (newValue, oldValue) => {
    if (!newValue) {
      return;
    }
    emit('update:modelValue', newValue);

    console.log('modelValue changed', newValue, oldValue);

    blurAfterSelect();
  });

  // Ensure to update local modelValue when props.modelValue changes, to keep local state in sync with parent
  watch(
    () => props.modelValue,
    (newVal) => {
      modelValue.value = newVal;
    }
  );

  const filteredValues = computed(() => {
    if (props.type == 'static') {
      // keep values where is_disabled is true and filter the rest
      return props.values.filter((item) => {
        if (item.is_disabled) {
          return item;
        } else if (
          item.name.toLowerCase().includes(query.value.toLowerCase())
        ) {
          return item;
        }
      });
    } else {
      return props.values;
    }
  });

  const classes = computed(() => {
    return props.icon ? '!pl-12' : '';
  });

  const create_new = () => {
    console.log('create new', query.value);

    if (!props.can_create) {
      return;
    }

    if (!query.value) {
      return;
    }

    if (props.values.length > 0) {
      return;
    }

    const loader = $loading.show();

    var result = new Promise((resolve) =>
      emit('createValue', query.value, resolve)
    );

    result.then((value) => {
      console.log('value', value);
      emit('update:modelValue', value);
      props.values.push(value);
    });

    query.value = '';

    loader.hide();
  };

  const blurAfterSelect = () => {
    // other stuff
    setTimeout(() => {
      // blur the input
      inputRef.value.$el.blur();
    }, 0);
  };
</script>
