<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Applications')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="work_history" />
      </template>
    </SectionHeader>

    <div
      v-if="applications.length"
      class="flex snap-x snap-mandatory gap-8 overflow-x-auto"
    >
      <Application
        v-for="application in applications"
        :key="application.id"
        :application="application"
        :applications="applications"
        :user-id="userId"
        class="min-w-full max-w-full snap-start sm:min-w-[calc((100%-2rem)/2)] sm:max-w-[calc((100%-2rem)/2)] md:min-w-[calc((100%-4rem)/3)] md:max-w-[calc((100%-4rem)/3)]"
        @update:applications="handleUpdateApplications"
      />
    </div>

    <div v-else class="shadow-card rounded-2xl bg-white p-6 md:p-8">
      <span class="text-zinc-600">
        {{ __('No applications found') }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Application } from './components/application';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    recruiter: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits('update:applications');

  const applications = ref(
    JSON.parse(JSON.stringify(props.recruiter.applications))
  );

  const userId = computed(() => {
    return props.data.about_me.user.user_id;
  });

  function handleUpdateApplications(newApplications) {
    applications.value = newApplications;
    emits('update:applications', newApplications);
  }
</script>
