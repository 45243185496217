<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute left-full top-0 flex w-16 justify-center pt-5"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <!-- <XMarkIcon
                        class="h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      /> -->
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4"
              >
                <div class="flex h-16 shrink-0 items-center py-4">
                  <slot name="logo"></slot>
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul
                    role="list"
                    class="flex flex-1 flex-col justify-between gap-y-7"
                  >
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in sidebartopmenu" :key="item.name">
                          <a
                            v-if="!item.submenu"
                            :href="item.url"
                            :class="[
                              item.current
                                ? 'bg-gray-200'
                                : 'hover:bg-gray-200',
                              'group mb-2 flex gap-x-3 rounded-app p-2 text-sm leading-6 text-[#212429]'
                            ]"
                          >
                            <Icon
                              :name="item.icon"
                              class="h-6 w-6 shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            {{ __(item.title) }}
                          </a>
                          <Disclosure as="div" v-else v-slot="{ open }">
                            <DisclosureButton
                              :class="[
                                item.current
                                  ? 'bg-gray-200'
                                  : 'hover:bg-gray-200',
                                'mb-2 flex w-full items-center gap-x-3 rounded-app p-2 text-left text-sm leading-6 text-[#212429]'
                              ]"
                            >
                              <Icon
                                :name="item.icon"
                                class="h-6 w-6 shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {{ __(item.title) }}
                              <Icon
                                name="expand_more"
                                :class="[
                                  open
                                    ? 'rotate-90 text-gray-500'
                                    : 'text-gray-400',
                                  'ml-auto h-5 w-5 shrink-0'
                                ]"
                              />
                            </DisclosureButton>
                            <DisclosurePanel as="ul" class="mt-1 px-2">
                              <li
                                v-for="subItem in item.submenu"
                                :key="subItem.title"
                              >
                                <!-- 44px -->
                                <DisclosureButton
                                  as="a"
                                  :href="subItem.url"
                                  :class="[
                                    subItem.current
                                      ? 'bg-gray-200'
                                      : 'hover:bg-gray-200',
                                    'block rounded-app px-4 py-2 pl-9 pr-2 text-sm leading-6 text-gray-700'
                                  ]"
                                  >{{ __(subItem.title) }}</DisclosureButton
                                >
                              </li>
                            </DisclosurePanel>
                          </Disclosure>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <div
                        class="mt-auto"
                        v-for="item in sidebarbottommenu"
                        :key="item.title"
                      >
                        <a
                          :href="item.url"
                          class="group -mx-2 flex gap-x-3 rounded-app p-2 text-sm leading-6 text-gray-400 hover:bg-gray-200 hover:text-gray-700"
                        >
                          <Icon
                            :name="item.icon"
                            aria-hidden="true"
                            class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-gray-700"
                          />
                          {{ __(item.title) }}
                        </a>
                      </div>
                      <!-- <div class="mt-auto">
                          <a
                            href="#"
                            class="group -mx-2 flex gap-x-3 rounded-app p-2 text-sm leading-6 text-gray-400 hover:bg-gray-200 hover:text-gray-700"
                          >
                            <Icon
                              name="settings_suggest"
                              class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-gray-700"
                            />
                            Settings
                          </a>
                        </div> -->
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden shadow-md lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
      :class="collapsable ? 'lg:hidden' : 'lg:fixed'"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4"
      >
        <div class="flex h-16 shrink-0 items-center py-4">
          <div><slot name="logo"></slot></div>
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col justify-between gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in sidebartopmenu" :key="item.name">
                  <a
                    v-if="!item.submenu"
                    :href="item.url"
                    :class="[
                      item.current ? 'bg-gray-200' : 'hover:bg-gray-200',
                      'group mb-2 flex gap-x-3 rounded-app p-2 text-sm leading-6 text-[#212429]'
                    ]"
                  >
                    <Icon
                      :name="item.icon"
                      class="h-6 w-6 shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    {{ __(item.title) }}
                  </a>
                  <Disclosure as="div" v-else v-slot="{ open }">
                    <DisclosureButton
                      :class="[
                        item.current ? 'bg-gray-200' : 'hover:bg-gray-200',
                        'mb-2 flex w-full items-center gap-x-3 rounded-app p-2 text-left text-sm leading-6 text-[#212429]'
                      ]"
                    >
                      <Icon
                        :name="item.icon"
                        class="h-6 w-6 shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      {{ __(item.title) }}
                      <Icon
                        name="expand_more"
                        :class="[
                          open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                          'ml-auto h-5 w-5 shrink-0'
                        ]"
                      />
                    </DisclosureButton>
                    <DisclosurePanel as="ul" class="mt-1 px-2">
                      <li v-for="subItem in item.submenu" :key="subItem.name">
                        <!-- 44px -->
                        <DisclosureButton
                          as="a"
                          :href="subItem.url"
                          :class="[
                            subItem.current
                              ? 'bg-gray-200'
                              : 'hover:bg-gray-200',
                            'block rounded-app px-4 py-2 pl-9 pr-2 text-sm leading-6 text-gray-700'
                          ]"
                          >{{ __(subItem.title) }}</DisclosureButton
                        >
                      </li>
                    </DisclosurePanel>
                  </Disclosure>
                </li>
              </ul>
            </li>

            <li>
              <div
                class="mt-auto"
                v-for="item in sidebarbottommenu"
                :key="item.title"
              >
                <a
                  :href="item.url"
                  class="group -mx-2 flex gap-x-3 rounded-app p-2 text-sm leading-6 text-gray-400 hover:bg-gray-200 hover:text-gray-700"
                >
                  <Icon
                    aria-hidden="true"
                    :name="item.icon"
                    class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-gray-700"
                  />
                  {{ __(item.title) }}
                </a>
              </div>

              <div class="mt-auto" aria-hidden="true">
                <span
                  @click="collapsable = !collapsable"
                  class="group -mx-2 flex cursor-pointer gap-x-3 rounded-app p-2 text-sm leading-6 text-gray-400 hover:bg-gray-200 hover:text-gray-700"
                >
                  <Icon
                    name="keyboard_tab"
                    class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-gray-700"
                  />
                  {{ __('Collapse') }}
                </span>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div :class="collapsable ? 'lg:pl-0' : 'lg:pl-72'">
      <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
      >
        <button
          type="button"
          class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Icon name="menu" />
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <div class="relative flex flex-1"></div>
          <!--          <form class="relative flex flex-1" action="#" method="GET">-->
          <!--            <label for="search-field" class="sr-only">Search</label>-->
          <!--            <input-->
          <!--              id="search-field"-->
          <!--              class="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"-->
          <!--              placeholder="Search..."-->
          <!--              type="search"-->
          <!--              name="search"-->
          <!--            />-->
          <!--          </form>-->
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <!-- Separator -->
            <div
              class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
              aria-hidden="true"
            />

            <slot name="user"></slot>
          </div>
        </div>
      </div>
      <!-- <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
          <div>
            <h1 className="text-4xl text-[#212429] font-medium mb-2">Resume</h1>
            <p>Manage the jobs you are most interested in</p>
          </div>
        </div>
      </header> -->

      <!-- if you do not pass custom classes, it fallbacks to default width -->
      <main :class="customClasses || 'mx-auto max-w-7xl py-10'">
        <div class="px-4 sm:px-6 lg:px-8">
          <!-- Your content -->
          <slot></slot>
        </div>
      </main>
    </div>

    <div
      :class="[collapsable ? 'fixed' : 'hidden']"
      class="left-0 top-1/4 z-50 flex-col space-y-3 rounded-app bg-transparent bg-white px-5 py-3 shadow-md shadow-sm"
    >
      <ul role="list" class="-mx-2 space-y-1 bg-white">
        <li v-for="item in sidebartopmenu" :key="item.name">
          <a
            :href="item.url"
            :class="[
              'group flex gap-x-3 rounded-app p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-200'
            ]"
          >
            <Icon
              :name="item.icon"
              class="h-6 w-6 shrink-0 text-gray-400"
              aria-hidden="true"
            />
          </a>
        </li>
        <li>
          <span
            @click="collapsable = !collapsable"
            class="group flex cursor-pointer gap-x-3 rounded-app p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-200"
          >
            <Icon name="keyboard_tab" class="h-6 w-6 shrink-0 text-gray-400" />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot
  } from '@headlessui/vue';
  import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel
  } from '@headlessui/vue';
  import { Icon } from '../../global';

  const pages = [
    { name: 'Projects', href: '#', current: false },
    { name: 'Project Nero', href: '#', current: true }
  ];
  // define props
  const props = defineProps({
    sidebartopmenu: {
      type: Object,
      required: true
    },
    sidebarbottommenu: {
      type: Object,
      required: true
    },
    customClasses: String
  });

  const sidebarOpen = ref(false);
  const collapsable = ref(false);
</script>
