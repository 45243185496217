<template>
  <div>
    <!-- {{ data }} -->
    <JobDetails
      :data="data.details"
      :domains="data.domains"
      :contract_types="data.contract_types"
      :presence="data.presence"
      :visas="data.visas"
      :recruiters="data.recruiters"
      :salary="data.salary"
      :metadata="data.metadata"
      :errors="errors"
      @jobDataEmitUpdate="handleJobDataEmitUpdate"
    />
    <Location
      v-model:data="data.locations"
      :job="data.details"
      :errors="errors"
    />

    <JobDescription :data="data.details" :errors="errors" />
    <JobInclusivity :data="data.inclusivity" :job="data.details" />

    <Skills :data="data.skills" :errors="errors" />

    <div class="md:grid md:grid-cols-2 md:gap-x-10">
      <Values :data="data.values" />
      <Perks :data="data.perks" />
    </div>

    <Languages :data="data.languages" :errors="errors" />

    <RecruitmentProcess :data="data.recruitment_process" :errors="errors" />

    <AtsProcess :data="data.ats_recruitment_process" :errors="errors" />
  </div>
</template>

<script setup>
  import { reactive } from 'vue';

  import {
    Values,
    Perks,
    JobDescription,
    Skills,
    Languages,
    JobInclusivity,
    AtsProcess,
    RecruitmentProcess,
    JobDetails,
    Location
  } from '.';

  const props = defineProps({
    name: String,

    data: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: true
    }
  });

  const data = reactive(props.data);

  function handleJobDataEmitUpdate(newData)
  {
    console.log('xxx')
    console.log(data)
    console.log(newData.data)
    Object.assign(data, newData.data);
  }
</script>
