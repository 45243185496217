<template>
  <AlertModal
    open
    :title="__('Are you sure you want to delete this speaker?')"
    :description="fullName"
    :is-loading="isLoading"
    @cancel="handleCancelButtonClick"
    @continue="handleContinueButtonClick"
  />
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { AlertModal } from '@/components/other/alert-modal';

  const props = defineProps({
    speaker: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['cancel', 'continue']);

  const toast = useToast();

  const fullName = computed(() => {
    return `${props.speaker.first_name} ${props.speaker.last_name}`;
  });

  const isLoading = ref(false);

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleContinueButtonClick() {
    isLoading.value = true;

    try {
      const response = await axios.delete(
        route('redesign.company.speakers.delete', { id: props.speaker.id })
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('continue', props.speaker);
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }
</script>
