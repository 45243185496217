<template>
  <div
    class="space-y-6 rounded-2xl bg-gradient-to-b from-zinc-100 to-transparent p-4"
  >
    <div class="space-y-2">
      <span class="block text-zinc-800">
        <span class="text-lg font-medium">{{ name }}</span>
        <span v-if="organization">{{ ` @ ${organization}` }}</span>
      </span>

      <span v-if="date" class="block text-sm text-zinc-600">{{ date }}</span>
    </div>

    <a
      v-if="link"
      :href="link"
      target="_blank"
      class="block w-fit rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
    >
      {{ link }}
    </a>

    <p v-if="description" class="leading-normal text-zinc-600">
      <span>{{ description }}</span>

      <button
        v-if="isDescriptionExpandButtonVisible"
        class="text-primary underline-offset-2 hover:underline"
        @click="handleDescriptionExpandButtonClick"
      >
        {{ __('see more') }}
      </button>
    </p>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { __ } from '@/helpers';
  import { formatDateToMonthYear } from '@/scripts/date.script';

  const props = defineProps({
    project: {
      type: Object,
      required: true
    }
  });

  const name = computed(() => {
    return props.project.name;
  });

  const organization = computed(() => {
    return props.project.organization;
  });

  const date = computed(() => {
    if (!props.project.start_date) {
      return '';
    }

    const isCurrentlyStudyingHere = props.project.current;
    const startDate = props.project.start_date
      ? new Date(props.project.start_date)
      : '-';
    const endDate = isCurrentlyStudyingHere
      ? new Date()
      : props.project.end_date
        ? new Date(props.project.end_date)
        : '-';

    let years =
      startDate === '-' || endDate === '-'
        ? '0'
        : endDate.getFullYear() - startDate.getFullYear();
    let months =
      startDate === '-' || endDate === '-'
        ? '0'
        : endDate.getMonth() - startDate.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    if (months > 0) {
      years++;
    }

    return `${startDate === '-' ? __('Date not set') : formatDateToMonthYear(startDate)} - ${
      isCurrentlyStudyingHere
        ? __('Present')
        : endDate === '-'
          ? __('Date not set')
          : formatDateToMonthYear(endDate)
    } (${years} ${__('years')})`;
  });

  const link = computed(() => {
    return props.project.link;
  });

  const description = computed(() => {
    if (!props.project.description) {
      return '';
    }

    return props.project.description.length <= 250 ||
      isDescriptionExpanded.value
      ? props.project.description
      : `${props.project.description.substring(0, 250)}...`;
  });

  const isDescriptionExpandButtonVisible = computed(() => {
    if (!props.project.description) {
      return false;
    }

    return (
      props.project.description.length > 250 && !isDescriptionExpanded.value
    );
  });

  const isDescriptionExpanded = ref(false);

  function handleDescriptionExpandButtonClick() {
    isDescriptionExpanded.value = true;
  }
</script>
