<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell text-center">
    <a v-for="(value, key) in props.columnData" :key="key" :href="value" target="_blank">
      <span class="font-bold">{{ capitalize(__(key)) }}</span>
    </a>
  </td>
</template>

<script setup>
import {__, capitalize} from "../../../../../helpers";

const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});
</script>