<template>
  <div>
    <label
      v-if="label"
      :for="id"
      :class="`${disabled && 'cursor-not-allowed'} flex items-center gap-2`"
    >
      <span
        :class="`${disabled && '!bg-zinc-100 !ring-gray-200'} ${value ? 'bg-primary ring-primary' : 'bg-white'} ${errors.length ? 'ring-error' : 'ring-gray-200'} flex h-5 w-5 items-center justify-center rounded-md shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset`"
      >
        <Icon
          v-if="value"
          name="check"
          :class="`${disabled ? 'text-zinc-500' : 'text-white'} pointer-events-none`"
        />
      </span>

      <span
        :class="`${errors.length ? 'text-error' : 'text-zinc-800'} leading-normal`"
      >
        {{ `${label}${required ? '*' : ''}` }}
      </span>
    </label>
    <label v-else-if="html"
      :for="id"
      :class="`${disabled && 'cursor-not-allowed'} flex items-center gap-2`"
    >
      <span
        :class="`${disabled && '!bg-zinc-100 !ring-gray-200'} ${value ? 'bg-primary ring-primary' : 'bg-white'} ${errors.length ? 'ring-error' : 'ring-gray-200'} flex h-5 w-5 items-center justify-center rounded-md shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset`"
      >
        <Icon
          v-if="value"
          name="check"
          :class="`${disabled ? 'text-zinc-500' : 'text-white'} pointer-events-none`"
        />
      </span>
      <span
        :class="`${errors.length ? 'text-error' : 'text-zinc-800'} leading-normal`"
      >
        <span v-html="html"></span>
        <span v-if="required" class="text-red-500">*</span>
      </span>
    </label>

    <input
      :id="id"
      :checked="value"
      :disabled="disabled"
      type="checkbox"
      class="sr-only"
      @change="handleChange"
    />

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    html: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  function handleChange(event) {
    emits('update:value', event.target.checked);
    emits('update:errors', []);
  }
</script>
