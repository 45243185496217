<template>
  <div class="shadow-card space-y-8 rounded-lg bg-white p-6 md:px-6 md:py-8">
    <img :src="coverUrl" :alt="__('Cover')" class="w-full rounded" />

    <div class="space-y-6">
      <div class="flex flex-wrap items-start justify-between gap-4">
        <div class="space-y-2.5">
          <h1 class="text-4xl font-medium text-primary">{{ name }}</h1>

          <span class="block text-lg font-medium leading-normal text-zinc-800">
            {{ `${__('Date and time')}: ${dateTime}` }}
          </span>

          <div
            v-if="isPastEvent || isRatedEvent"
            class="flex items-center gap-1.5"
          >
            <Icon name="group" is-filled class="text-xl text-primary" />
            <span class="text-sm text-zinc-800">
              {{ `${checkedInAttendees} ${__('people attended')}` }}
            </span>
          </div>

          <div v-else class="flex flex-wrap items-center gap-x-4 gap-y-2">
            <div class="flex items-center gap-1.5">
              <Icon name="group" is-filled class="text-xl text-primary" />
              <span class="text-sm text-zinc-800">
                {{ `${attendees} ${__('attendees')}` }}
              </span>
            </div>

            <div v-if="attendeeLimit" class="flex items-center gap-1.5">
              <Icon name="group_add" is-filled class="text-xl text-primary" />
              <span class="text-sm text-zinc-800">
                {{ `${attendeeLimit - attendees} ${__('spots left')}` }}
              </span>
            </div>

            <div v-if="registrationDeadline" class="flex items-center gap-1.5">
              <Icon
                name="calendar_clock"
                is-filled
                class="text-xl text-primary"
              />
              <span class="text-sm text-zinc-800">
                {{ `${__('Register until')} ${registrationDeadline}` }}
              </span>
            </div>
          </div>
        </div>

        <Dropdown
          position="right"
          :options="shareDropdownOptions"
          @select="handleShareDropdownSelect"
        >
          <template #trigger>
            <Button
              tabindex="-1"
              variant="ghost"
              class="flex items-center gap-1.5"
            >
              <Icon name="share" class="text-xl text-primary" />
              <span class="text-zinc-600">{{ __('Share this event') }}</span>
            </Button>
          </template>
        </Dropdown>
      </div>

      <template v-if="isFutureEvent">
        <Button
          v-if="canCancel"
          variant="outline"
          @click="handleCancelButtonClick"
        >
          {{ __('Cancel event') }}
        </Button>
      </template>

      <div v-else-if="isPresentEvent" class="flex items-center gap-2">
        <Icon name="stream" is-filled class="text-2xl text-primary" />
        <span class="text-lg font-medium text-zinc-800">
          {{ __('The event is happening now') }}
        </span>
      </div>

      <div v-else-if="isPastEvent" class="flex items-center gap-2">
        <Icon name="local_activity" is-filled class="text-2xl text-error" />
        <span class="text-lg font-medium text-zinc-800">
          {{ __('This event has passed') }}
        </span>
      </div>

      <div v-else-if="isRatedEvent" class="space-y-4">
        <div class="space-y-2.5">
          <span class="block text-lg font-medium leading-normal text-zinc-800">
            {{ `${ratings} ${__('people rated your event')}` }}
          </span>

          <div class="flex items-center gap-2.5">
            <span class="text-2xl font-medium leading-normal text-zinc-800">
              {{ `${eventRating}/5` }}
            </span>

            <div class="flex items-center gap-1.5">
              <div v-for="(_, index) in 5" :key="index">
                <svg
                  :class="`${eventRating >= index + 1 ? 'fill-primary' : 'fill-zinc-300'}`"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0019 21.905L9.81443 25.03C9.58526 25.1759 9.34568 25.2384 9.09568 25.2175C8.84568 25.1967 8.62693 25.1134 8.43943 24.9675C8.25193 24.8217 8.1061 24.6394 8.00193 24.4206C7.89776 24.2019 7.87693 23.9571 7.93943 23.6863L9.31443 17.78L4.72068 13.8113C4.51235 13.6238 4.38214 13.4102 4.33005 13.1707C4.27797 12.9311 4.2936 12.6967 4.37693 12.4675C4.46026 12.2384 4.58526 12.0509 4.75193 11.905C4.9186 11.7592 5.14776 11.6654 5.43943 11.6238L11.5019 11.0925L13.8457 5.53C13.9498 5.28 14.1113 5.0925 14.33 4.9675C14.5488 4.8425 14.7727 4.78 15.0019 4.78C15.2311 4.78 15.455 4.8425 15.6738 4.9675C15.8925 5.0925 16.054 5.28 16.1581 5.53L18.5019 11.0925L24.5644 11.6238C24.8561 11.6654 25.0852 11.7592 25.2519 11.905C25.4186 12.0509 25.5436 12.2384 25.6269 12.4675C25.7102 12.6967 25.7259 12.9311 25.6738 13.1707C25.6217 13.4102 25.4915 13.6238 25.2831 13.8113L20.6894 17.78L22.0644 23.6863C22.1269 23.9571 22.1061 24.2019 22.0019 24.4206C21.8977 24.6394 21.7519 24.8217 21.5644 24.9675C21.3769 25.1134 21.1581 25.1967 20.9081 25.2175C20.6581 25.2384 20.4186 25.1759 20.1894 25.03L15.0019 21.905Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div v-if="event.speakers.length" class="space-y-4">
          <span class="block text-2xl font-medium leading-normal text-zinc-800">
            {{ __('Rated speakers') }}
          </span>

          <div class="flex flex-wrap gap-6">
            <SpeakerRating
              v-for="speaker in event.speakers"
              :key="speaker.id"
              :speaker="speaker"
            />
          </div>
        </div>
      </div>

      <template v-else-if="isCanceledEvent">
        <div class="flex items-center gap-2">
          <Icon name="chat_error" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('This event has been canceled') }}
          </span>
        </div>

        <Button
          v-if="canDelete"
          variant="outline"
          @click="handleDeleteButtonClick"
        >
          {{ __('Delete event') }}
        </Button>
      </template>
    </div>

    <EventCancelModal
      v-if="isCancelModalOpen"
      :event="event"
      @cancel="handleCancelModalCancelButtonClick"
      @continue="handleCancelModalContinueButtonClick"
    />

    <EventDeleteModal
      v-if="isDeleteModalOpen"
      :event="event"
      @cancel="handleDeleteModalCancelButtonClick"
      @continue="handleDeleteModalContinueButtonClick"
    />
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import {
    formatDateWithoutUTC,
    formatTimeWithoutUTC
  } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Dropdown } from '@/components/block/dropdown';
  import { Icon } from '@/components/block/icon';
  import { SpeakerRating } from './components/speaker-rating';
  import { EventCancelModal } from '../../shared/event-cancel-modal';
  import { EventDeleteModal } from '../../shared/event-delete-modal';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['update:event']);

  const toast = useToast();

  const shareDropdownOptions = [
    {
      id: 1,
      name: __('Share to LinkedIn')
    },
    {
      id: 2,
      name: __('Share to Facebook')
    },
    {
      id: 3,
      name: __('Copy link to event')
    }
  ];

  const coverUrl = computed(() => {
    return props.event.details.cover.includes('/uploads/')
      ? props.event.details.cover
      : `/uploads/${props.event.details.cover}`;
  });

  console.log(props);

  const name = computed(() => {
    return props.event.details.name;
  });

  const dateTime = computed(() => {
    const startDate = formatDateWithoutUTC(
      `${props.event.details.start_date_time}${props.event.details.timezone.offset}`
    );
    const startTime = formatTimeWithoutUTC(
      `${props.event.details.start_date_time}${props.event.details.timezone.offset}`
    );
    const endDate = formatDateWithoutUTC(
      `${props.event.details.end_date_time}${props.event.details.timezone.offset}`
    );
    const endTime = formatTimeWithoutUTC(
      `${props.event.details.end_date_time}${props.event.details.timezone.offset}`
    );

    if (startDate !== endDate) {
      return `${startDate} | ${startTime} - ${endDate} | ${endTime}`;
    }

    return `${startDate} | ${startTime} - ${endTime}`;
  });

  const attendees = computed(() => {
    return props.event.details.attendees;
  });

  const attendeeLimit = computed(() => {
    return props.event.details.attendee_limit;
  });

  const checkedInAttendees = computed(() => {
    return props.event.details.checked_in_attendees;
  });

  const registrationDeadline = computed(() => {
    if (!props.event.details.rsvp) {
      return null;
    }

    const rsvpDate = formatDateWithoutUTC(
      `${props.event.details.rsvp}${props.event.details.timezone.offset}`
    );
    const rsvpTime = formatTimeWithoutUTC(
      `${props.event.details.rsvp}${props.event.details.timezone.offset}`
    );

    return `${rsvpDate} | ${rsvpTime}`;
  });

  const ratings = computed(() => {
    return props.event.details.total_ratings;
  });

  const eventRating = computed(() => {
    return props.event.details.rating || 0;
  });

  const isFutureEvent = computed(() => {
    return props.event.status === 'FUTURE';
  });

  const isPresentEvent = computed(() => {
    return props.event.status === 'PRESENT';
  });

  const isPastEvent = computed(() => {
    return props.event.status === 'PAST';
  });

  const isRatedEvent = computed(() => {
    return props.event.status === 'RATED';
  });

  const isCanceledEvent = computed(() => {
    return props.event.status === 'CANCELED';
  });

  const canCancel = computed(() => {
    return props.event.conditions.can_cancel;
  });

  const canDelete = computed(() => {
    return props.event.conditions.can_delete;
  });

  const isCancelModalOpen = ref(false);
  const isDeleteModalOpen = ref(false);

  function handleCancelButtonClick() {
    isCancelModalOpen.value = true;
  }

  function handleCancelModalCancelButtonClick() {
    isCancelModalOpen.value = false;
  }

  function handleCancelModalContinueButtonClick() {
    emits('update:event', {
      ...JSON.parse(JSON.stringify(props.event)),
      status: 'CANCELED',
      conditions: {
        ...JSON.parse(JSON.stringify(props.event.conditions)),
        can_delete: true
      }
    });
    isCancelModalOpen.value = false;
  }

  function handleDeleteButtonClick() {
    isDeleteModalOpen.value = true;
  }

  function handleDeleteModalCancelButtonClick() {
    isDeleteModalOpen.value = false;
  }

  function handleDeleteModalContinueButtonClick() {
    window.location.href = route('redesign.company.events.viewAll');
    isDeleteModalOpen.value = false;
  }

  async function handleShareDropdownSelect(option) {
    const eventUrl = route('redesign.events.view', {
      slug: props.event.details.slug
    });

    switch (option.id) {
      case 1: {
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${eventUrl}`,
          '_blank'
        );
        break;
      }
      case 2: {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${eventUrl}`,
          '_blank'
        );
        break;
      }
      case 3: {
        try {
          await navigator.clipboard.writeText(eventUrl);

          toast.success(__('Event link has been copied to your clipboard!'));
        } catch {
          toast.error(
            __('An unexpected error has occured. Please try again later.')
          );
        }
        break;
      }
    }
  }
</script>
