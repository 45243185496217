<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Experience')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="business_center" is-filled />
      </template>
      <template v-if="canEdit && !hasNoExperience" #button>
        <div class="ml-auto">
          <Button
            class="flex items-center gap-1.5"
            variant="ghost"
            @click="handleAddButtonClick"
          >
            <Icon class="text-lg" name="add_box" is-filled />
            <span>{{ __('Add experience') }}</span>
          </Button>
        </div>
      </template>
    </SectionHeader>

    <div class="space-y-4">
      <div
        v-if="!experiences.length"
        class="shadow-card rounded-2xl bg-white p-6 md:p-8"
      >
        <InputCheckbox
          id="hasNoExperience"
          :value="hasNoExperience"
          :label="__('I have not had any previous work')"
          @update:value="handleHasNoExperienceUpdate"
        />
      </div>

      <div
        v-if="!hasNoExperience"
        class="shadow-card relative overflow-hidden rounded-2xl bg-white p-6 md:p-8"
      >
        <div
          v-if="progress"
          class="absolute right-0 top-0 rounded-l-full bg-secondary px-3 py-1"
        >
          <span class="block text-xs leading-none text-white">
            {{ progress }}
          </span>
        </div>

        <EmptySection
          v-if="!experiences.length"
          :title="__('Add your experience')"
        >
          <template #icon>
            <Icon
              class="text-xl text-zinc-200"
              name="business_center"
              is-filled
            />
          </template>
        </EmptySection>

        <div v-else class="space-y-8">
          <Experience
            v-for="experience in isListExpanded
              ? experiences
              : experiences.slice(0, 3)"
            :key="experience.id"
            :experience="experience"
            :can-edit="canEdit"
            @edit="handleEditButtonClick(experience)"
            @delete="handleDeleteButtonClick(experience)"
          />

          <Button
            v-if="experiences.length > 3"
            class="mx-auto"
            variant="outline"
            @click="handleExpandListButtonClick"
          >
            {{ __(isListExpanded ? 'See less' : 'See more') }}
          </Button>
        </div>

        <ExperienceFormModal
          v-if="isFormModalOpen"
          :experience="selected"
          :options="options"
          @cancel="handleCancelButtonClick"
          @save="handleSaveButtonClick"
        />

        <ExperienceDeleteModal
          v-if="isDeleteModalOpen"
          :experience="selected"
          @cancel="handleCancelButtonClick"
          @continue="handleContinueButtonClick"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputCheckbox } from '@/components/form/input-checkbox';
  import { EmptySection } from '@/pages/components/empty-section';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Experience } from './components/experience';
  import { ExperienceDeleteModal } from './components/experience-delete-modal';
  import { ExperienceFormModal } from './components/experience-form-modal';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    hasNoExperience: {
      type: Boolean,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['update-is-editing', 'update-percentages']);

  const toast = useToast();

  const progress = computed(() => {
    return fields.value.total
      ? `${fields.value.completed}/${fields.value.total}`
      : '';
  });

  const fields = ref(JSON.parse(JSON.stringify(props.fields)));
  const experiences = ref(JSON.parse(JSON.stringify(props.data)));
  const hasNoExperience = ref(props.hasNoExperience);
  const selected = ref(null);
  const isFormModalOpen = ref(false);
  const isDeleteModalOpen = ref(false);
  const pendingAction = ref('');
  const isListExpanded = ref(false);

  function handleExpandListButtonClick() {
    isListExpanded.value = !isListExpanded.value;
  }

  async function handleHasNoExperienceUpdate(value) {
    try {
      const response = await axios.put(
        route('redesign.cv.update.no_experience'),
        { no_experience: value }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      fields.value = response.data.fields;
      hasNoExperience.value = value;

      emits('update-percentages', {
        sectionPercentage: response.data.section_percentage,
        resumePercentage: response.data.percentage
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }
  }

  function handleAddButtonClick() {
    selected.value = null;
    pendingAction.value = 'add';

    emits('update-is-editing', true);
  }

  function handleEditButtonClick(experience) {
    selected.value = JSON.parse(JSON.stringify(experience));
    pendingAction.value = 'edit';

    emits('update-is-editing', true);
  }

  function handleDeleteButtonClick(experience) {
    selected.value = JSON.parse(JSON.stringify(experience));
    pendingAction.value = 'delete';

    emits('update-is-editing', true);
  }

  function handleCancelButtonClick() {
    emits('update-is-editing', false);
  }

  function handleSaveButtonClick(data) {
    const existingExperience = experiences.value.find(
      (experience) => experience.id === data.experience.id
    );

    if (existingExperience) {
      experiences.value = experiences.value.map((experience) => {
        if (experience.id === data.experience.id) {
          return data.experience;
        }

        return experience;
      });
    } else {
      experiences.value = [data.experience, ...experiences.value];
    }

    fields.value = data.fields;

    emits('update-is-editing', false);
    emits('update-percentages', {
      sectionPercentage: data.sectionPercentage,
      resumePercentage: data.resumePercentage
    });
  }

  function handleContinueButtonClick(data) {
    experiences.value = experiences.value.filter(
      (experience) => experience.id !== data.experience.id
    );

    fields.value = data.fields;

    emits('update-is-editing', false);
    emits('update-percentages', {
      sectionPercentage: data.sectionPercentage,
      resumePercentage: data.resumePercentage
    });
  }

  watch(
    () => props.isEditing,
    (value) => {
      if (pendingAction.value === 'add' || pendingAction.value === 'edit') {
        isFormModalOpen.value = value;
      } else if (pendingAction.value === 'delete') {
        isDeleteModalOpen.value = value;
      }
    }
  );
</script>
