<template>
  <div>
    <div
      v-if="error"
      id="profile"
      class="candidate-gamification__tab candidate-gamification__profile"
    >
      <div class="matching-lists__message">
        <p style="text-align: center">
          {{ __("Something went wrong. Please check again later.") }}
        </p>
      </div>
    </div>

    <div
      v-if="user"
      id="profile"
      class="candidate-gamification__tab candidate-gamification__profile"
    >
      <div class="candidate-gamification__profile--header flex aic">
        <div class="avatar">
          <img class="avatar__pic" :src="user.profile_photo_src" alt="" />
        </div>

        <div class="user">
          <span class="name">{{ user.full_name }}</span>
          <div v-if="user.crowning" class="tag">{{ user.crowning.name }}</div>
        </div>
        <div v-if="user.crowning" class="badge">
          <img :src="user.crowning.img" alt="" />
        </div>
      </div>

      <div class="userLevel">
        <div class="levelIndicators">
          <div class="theLevel">
            <span>{{ __("Level") }}</span>
            <span class="no">{{ user.current_level.level_number }}</span>
          </div>
        </div>
        <div class="levelProgress">
          <input type="text" v-model="user.progress" class="progress" />
        </div>
        <div class="centred">
          <span class="bc"
            ><strong>{{ user.points }}p</strong></span
          >
          <span v-if="user.next_level"
            >| {{ user.next_level.minimum_points }}p</span
          >
          <br />
          <span class="rank">
            <span class="theRank">{{ user.current_level.name }}</span>
          </span>
        </div>
      </div>

      <div class="candidate-gamification__news-highlights">
        <div class="candidate-gamification__module-header flex sb aic">
          <h3>{{ __("News") }}</h3>
          <a
            v-if="user.notifications.length > 0"
            v-on:click="this.switchToNews()"
            >{{ __("See all") }}</a
          >
        </div>

        <div class="candidate-gamification__news-highlights--list">
          <p v-if="user.notifications.length == 0">
            {{ __("No recent news!") }}
          </p>
          <div
            v-for="(notification, index) in user.notifications.slice(0, 2)"
            :key="index"
          >
            <gamification-notification-profile
              :notification="notification"
              :index="index"
              :roles_opportunities_route="this.roles_opportunities_route"
              :messages_route="this.messages_route"
              :friends_index_route="this.friends_index_route"
              :page_shop_active="this.page_shop_active"
              :page_shop_url="this.page_shop_url"
              :dismissNotification="this.dismissNotification"
              :shortenedDescription="this.shortenedDescription"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// define props

export default {
  props: [
    "user",
    "error",
    "switchToNews",
    "roles_opportunities_route",
    "messages_route",
    "friends_index_route",
    "page_shop_active",
    "page_shop_url",
    "dismissNotification",
    "shortenedDescription",
  ],
};
</script>
