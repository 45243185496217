<template>
  <div>
    <Button
      class="ml-auto flex items-center gap-2"
      variant="ghost"
      :href="resumePageLink"
      target="_blank"
    >
      <Icon name="open_in_new" class="text-xl text-primary" />
      <span>{{ __('Go to resume page') }}</span>
    </Button>

    <div class="space-y-6">
      <div class="space-y-2">
        <div class="space-y-1">
          <span class="block text-4xl font-medium text-primary">
            {{ candidateName }}
          </span>

          <span
            v-if="candidatePosition"
            class="block text-lg font-medium text-zinc-800"
          >
            {{ candidatePosition }}
          </span>
        </div>

        <div class="flex items-center gap-6">
          <div class="py-2">
            <span class="block text-sm leading-6 text-zinc-600">
              {{ `${__('Account source')}: ${accountSource}` }}
            </span>
          </div>
          <div v-if="!hasAccount" class="flex items-center gap-1 py-2">
            <Icon class="text-2xl text-primary" name="person_off" />

            <span class="text-sm leading-6 text-zinc-600">
              {{ __('Without account') }}
            </span>
          </div>
        </div>

        <div class="flex flex-wrap items-center gap-x-4 gap-y-2">
          <a
            v-if="candidatePhoneNumber"
            class="flex items-center gap-2"
            :href="`tel:${candidatePhoneNumber}`"
          >
            <Icon class="text-lg text-primary" name="phone" is-filled />
            <span class="text-sm leading-6 text-zinc-600">
              {{ `${__('Phone number')}: ${candidatePhoneNumber}` }}
            </span>
          </a>

          <a
            v-if="candidateEmail"
            class="flex items-center gap-2"
            :href="`mailto:${candidateEmail}`"
          >
            <Icon class="text-lg text-primary" name="email" is-filled />
            <span class="text-sm leading-6 text-zinc-600">
              {{ `${__('Email')}: ${candidateEmail}` }}
            </span>
          </a>
        </div>
      </div>

      <div class="flex flex-wrap items-center gap-x-4 gap-y-2">
        <Popover v-if="availableJobs.length" @close="handleInvitePopoverClose">
          <template #trigger>
            <Button
              tabindex="-1"
              variant="outline"
              class="flex items-center gap-1.5"
            >
              <span>
                {{ __(hasAccount ? 'Invite to job' : 'Add to Kanban') }}
              </span>
              <Icon name="expand_more" />
            </Button>
          </template>
          <template #content="{ close }">
            <form
              class="flex flex-col items-end gap-4 p-4"
              @submit.prevent="handleInviteSubmit(close)"
            >
              <InputSelect
                id="job_select"
                v-model:value="values.job"
                class="min-w-80"
                :label="__('Job')"
                :placeholder="__('Select job')"
                :options="availableJobs"
              />

              <Button
                class="flex items-center gap-1.5"
                type="submit"
                :disabled="isLoadingInvite"
              >
                <Icon
                  v-if="isLoadingInvite"
                  class="animate-spin text-lg"
                  name="progress_activity"
                />
                <span>
                  {{
                    __(
                      isLoadingInvite
                        ? 'Loading...'
                        : hasAccount
                          ? 'Send invitation'
                          : 'Add to Kanban'
                    )
                  }}
                </span>
              </Button>
            </form>
          </template>
        </Popover>

        <Dropdown
          :options="downloadResumeOptions"
          @select="handleDownloadResume"
        >
          <template #trigger>
            <Button class="flex items-center gap-1.5" variant="outline">
              <span>{{ __('Download resume') }}</span>
              <Icon name="expand_more" />
            </Button>
          </template>
        </Dropdown>

        <Button
          v-if="!isAnonymizedProfile && hasAccount"
          @click="handleSendMessageButtonClick"
        >
          <span>{{ __('Send message') }}</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { computeResume } from '@/scripts/resume.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Dropdown } from '@/components/block/dropdown';
  import { Popover } from '@/components/block/popover';
  import { InputSelect } from '@/components/form/input-select';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    recruiter: {
      type: Object,
      required: true
    },
    username: {
      type: String,
      required: true
    }
  });

  const toast = useToast();

  const candidateName = computed(() => {
    return `${props.data.about_me.user.first_name} ${props.data.about_me.user.last_name}`;
  });

  const candidatePosition = computed(() => {
    return props.data.about_me.user.current_position;
  });

  const candidatePhoneNumber = computed(() => {
    return props.data.about_me.user.phone;
  });

  const candidateEmail = computed(() => {
    return props.data.about_me.user.email;
  });

  const accountSource = computed(() => {
    return props.recruiter.metadata.account_source;
  });

  const resumeFile = computed(() => {
    return props.data.cv_file;
  });

  const isAnonymizedProfile = computed(() => {
    return props.recruiter.metadata.anonymized_profile;
  });

  const hasAccount = computed(() => {
    return props.recruiter.metadata.has_account;
  });

  const userId = computed(() => {
    return props.data.about_me.user.user_id;
  });

  const hasCvFromBestJobs = computed(() => {
    return props.data.cv_file?.bestjobs_cv ?? null !== null;
  });

  const hasCvFromEJobs = computed(() => {
    return props.data.cv_file?.ejobs_cv ?? null !== null;
  });

  const hasCvFromHipo = computed(() => {
    return props.data.cv_file?.hipo_cv ?? null !== null;
  });

  const downloadResumeOptions = computed(() => {
    const options = [
      {
        id: 1,
        name: __('Computed resume'),
        icon: 'download'
      },
      {
        id: 2,
        name: __('Original resume'),
        icon: 'download'
      }
    ];

    if (hasCvFromBestJobs.value) {
      options.push({
        id: 3,
        name: __('BestJobs resume'),
        icon: 'download'
      });
    }
    if (hasCvFromEJobs.value) {
      options.push({
        id: 4,
        name: __('EJobs resume'),
        icon: 'download'
      });
    }
    if (hasCvFromHipo.value) {
      options.push({
        id: 5,
        name: __('Hipo resume'),
        icon: 'download'
      });
    }

    return !resumeFile.value || isAnonymizedProfile.value
      ? options.slice(0, 1)
      : options;
  });

  const resumePageLink = computed(() => {
    return route('redesign.cv.show', { username: props.username });
  });

  const availableJobs = ref(
    JSON.parse(
      JSON.stringify(
        hasAccount.value
          ? props.recruiter.available_jobs
          : props.recruiter.available_boards
      )
    )
  );
  const values = ref({ job: null });
  const isLoadingInvite = ref(false);

  function handleInvitePopoverClose() {
    values.value.job = null;
  }

  async function handleInviteSubmit(close) {
    if (!values.value.job) {
      return;
    }

    isLoadingInvite.value = true;

    try {
      const response = await axios.post(
        route(
          hasAccount.value
            ? 'redesign.matching.inviteToJob'
            : 'redesign.cv.addToKanban'
        ),
        {
          job_id: values.value.job.id,
          user_id: userId.value
        }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      availableJobs.value = availableJobs.value.filter(
        (availableJob) => availableJob.id !== values.value.job.id
      );

      close();
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingInvite.value = false;
  }

  function downloadFile(path, name) {
    const a = document.createElement('a');
    a.href = path;
    a.download = resumeFile.value.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  async function handleDownloadResume(option) {
    if (option.id === 1) {
      await computeResume(props.data, isAnonymizedProfile.value);
    } else if (option.id === 2) {
      downloadFile(resumeFile.value.path);
    } else if (option.id === 3) {
      downloadFile(resumeFile.value.bestjobs_cv);
    } else if (option.id === 4) {
      downloadFile(resumeFile.value.ejobs_cv);
    } else if (option.id === 5) {
      downloadFile(resumeFile.value.hipo_cv);
    }
  }

  function handleSendMessageButtonClick() {
    window.open(props.recruiter.metadata.message_url, '_blank');
  }
</script>
