<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell text-center">
    {{ props.columnData }}
  </td>
</template>

<script setup>
const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});
</script>