<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell">
    <div class="flex items-center">
      <div class="font-medium text-black break-all">
        <p v-for="(value, key) in props.columnData" :key="key"><span class="font-bold">{{ capitalize(__(key)) }}: </span>{{ value }}</p>
      </div>
    </div>
  </td>
</template>

<script setup>

import {__, capitalize} from "../../../../../helpers";
const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});

</script>