<template>
    <div 
        class="space-y-12"
        v-if="courses.length" 
    >
        <div class="my-4 md:my-0 bg-white px-6 py-6 rounded-lg">
            <label class="mb-2 block text-sm text-[#58585E]">
                {{ __('Course') }}
            </label>
            <div class="mb-2 md:grid md:grid-cols-2 md:gap-x-2">
                <FormSingleselect 
                    @update:modelValue="handleCourseChange"
                    v-model="course" 
                    :values="props.courses"
                    placeholder=""
                    type="static"
                    
                    required 
                />
                <div class="relative justify-self-end">
                    <Button @click="handleDownloadButtonClick">
                        <Icon class="text-xl" name="analytics" />
                        {{ __('Download Report') }}
                    </Button> 
                </div>
            </div>

            <div class="mt-5 md:grid md:grid-cols-2">
                <div v-if="!data">
                    <span>{{ __('Loading...') }}</span>
                </div>
                <div v-else :class="[data.coursesClassName]">
                    <div v-for="(courseValue, key, index) in data.course" 
                        :key="key"
                        :class="[
                            index != Object.keys(data.course).length - 1 ?
                            'my-4 md:my-0 border-r border-zinc-700' : 'my-4 md:my-0'
                        ]">
                        <label class="mb-2 block text-sm text-[#58585E]">
                            {{ courseValue.name }}
                        </label>
                        <div class="text-3xl text-[#212429] font-medium">
                            {{ courseValue.total }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!data">
            <span>{{ __('Loading...') }}</span>
        </div>
        <div v-else class="space-y-8">  
            <div class="shadow-redesign overflow-x-auto rounded-lg bg-white">
                <!-- Courses table -->
                <table class="w-max min-w-full overflow-x-hidden">
                    <thead class="border-b">
                        <tr>
                            <th v-for="(name, index) in headers" :key="index"
                                class="px-8 py-4 font-medium leading-normal text-zinc-600">
                                {{ name }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, index) in data.users" 
                            :key="index"
                            class="border-b"
                        >
                            <td v-for="(name, index) in headers" 
                                :key="index" 
                                class="px-8 py-4 text-zinc-600"
                            >
                                <span :class="[
                                    index == 'graduated' ? 
                                        user[index] !== 'Pending' ? 
                                            'px-6 py-2 rounded-lg text-center items-center text-xs text-green-500 bg-green-100 border-green-500' : 
                                            'px-6 py-2 rounded-lg text-center items-center text-xs text-yellow-500 bg-yellow-100 border-yellow-500'
                                    : 'text-zinc-600']"
                                > 
                                    {{ user[index] }} 
                                </span>
                                
                                <div v-if="index == 'points' && !user[index]" 
                                    class="block items-center gap-2">
                                    <Icon class="text-xl text-[#52525B]" name="clock_loader_10" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- End Courses table -->

                <!-- Pagination -->
                <div v-if="renderPagination" class="flex justify-center gap-2">
                    <button class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
                        :disabled="isPreviousButtonDisabled" :aria-label="__('Previous')"
                        @click="handlePreviousPageButtonClick">
                        <Icon class="text-xl text-primary" name="arrow_back" />
                    </button>

                    <button class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
                        :disabled="isNextButtonDisabled" :aria-label="__('Next')" @click="handleNextPageButtonClick">
                        <Icon class="text-xl text-primary" name="arrow_forward" />
                    </button>
                </div>
                <!-- End Pagination -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { Icon } from '@/components/block/icon';
import { Button } from '@/components/block/button';
import { useToast } from 'vue-toastification';
import axios from 'axios';
import FormSingleselect from "../../../components/global/Form/FormSingleselect.vue";

const toast = useToast();

const props = defineProps({
    headers: {
        type: Array,
        required: true
    },
    courses: {
        type: Array,
        required: true
    }
});

let course = ref(props.courses[0]);

const data = ref(null);

const total = computed(() => {
    return data.value.pagination.total;
});

const renderPagination = computed(() => {
    return total.value > 1;
});

const page = computed(() => {
    return data.value.pagination.page;
});

const isPreviousButtonDisabled = computed(() => {
    return page.value <= 1;
});

const isNextButtonDisabled = computed(() => {
    return page.value >= total.value;
});

async function handlePreviousPageButtonClick() {
    await fetchUsers(parseInt(page.value) - 1);
}

async function handleNextPageButtonClick() {
    await fetchUsers(parseInt(page.value) + 1);
}

async function fetchUsers(pageNo = 1) {
    try {
        const response = await axios.get(
            route('redesign.company.courses.users'),
            { 
                params: {
                    course: course.value.id,
                    page: pageNo
                }
            }
        );

        data.value = response.data;
        data.value.coursesClassName = `md:grid md:grid-cols-${data.value.course_columns} md:gap-x-${data.value.course_columns}`;
    } catch {
        toast.error(
            __('An unexpected error has occured. Please try again later.')
        );
    }
}

async function handleCourseChange(value) {
    course.value = value;
    await fetchUsers();
}

onMounted(async () => {
    await fetchUsers();
});

async function handleDownloadButtonClick() {
    try {
       await axios.get(route('redesign.company.courses.reports'))
            .then(response => {
                const blob = new Blob([response.data], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);

                const date = new Date();
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');

                link.download = 'courses_' + `${year}-${month}-${day}` + '.csv'; 
                link.click();
                URL.revokeObjectURL(link.href);
            })
            .catch(error => {
                toast.error(
                __('An unexpected error has occured. Please try again later.')
            );
        });

    } catch (error) {
        toast.error(
            __('An unexpected error has occured. Please try again later.')
        );
    }
}
        

</script>