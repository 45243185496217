<template>
  <div>
    <div v-if="jobs.length > 0">
      <!-- TODO: Modify the with modal opening -->
      <a
        v-for="job in jobs"
        v-bind:key="job.id"
        tabindex="0"
        class="block transition duration-150 ease-in-out hover:bg-gray-50"
      >
        <CompanyJobCard
          :job="job"
          :has-integrations="hasIntegrations"
          @openModal="openModal(job)"
          @openJobBoardsModal="openJobBoardsModal(job)"
          @handleJobToggle="handleJobToggle"
        />
      </a>
    </div>

    <div v-else>{{ __('No results') }}</div>
  </div>
</template>

<script setup>
  import {
    Button,
    Icon,
    Input,
    Textarea,
    FormMultiselect
  } from '../../../../../components/global';
  import { __ } from '../../../../../helpers';
  import { CompanyJobCard } from '../../components';
  const props = defineProps({
    jobs: {
      type: Array,
      required: true
    },
    hasIntegrations: {
      type: Boolean,
      required: true
    }
  });

  const emit = defineEmits(['openModal', 'openJobBoardsModal']);

  const openModal = (job) => {
    emit('openModal', job);
  };

  const openJobBoardsModal = (job) => {
    emit('openJobBoardsModal', job);
  };

  const handleJobToggle = (job) => {
    console.log(job);

    // TODO: Add here afte adding also on modal
    // // pop the job from the list
    // const index = props.jobs.findIndex((j) => j.id === job.id);

    // if (index > -1) {
    //   props.jobs.splice(index, 1);
    // }
  };
</script>
