<template>
  <div class="candidate-gamification__subtab selected" id="monthly">
    <div class="candidate-gamification__leaderboard">
      <div
        v-for="(leaderboardUser, position) in user.leaderboard.monthly"
        :class="
          leaderboardUser.user_id == user.id
            ? 'candidate-gamification__leaderboard--item highlight'
            : 'candidate-gamification__leaderboard--item'
        "
      >
        <div style="width: 50px; text-align: center">
          {{ parseInt(position) + 1 }}
        </div>
        <div class="candidate-gamification__leaderboard--avatar">
          <img
            :src="leaderboardUser.profile_photo_src"
            alt=""
            style="border-radius: 50%"
          />
        </div>
        <div
          class="candidate-gamification__leaderboard--name"
          style="width: 160px; word-break: break-word"
        >
          {{ leaderboardUser.name }}
        </div>
        <div class="candidate-gamification__leaderboard--points">
          {{ leaderboardUser.points }}p
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
};
</script>
