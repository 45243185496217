<template>
  <div class="space-y-6">
    <div class="flex flex-wrap items-center gap-2">
      <div
        v-if="group"
        class="flex flex-wrap items-center gap-x-4 gap-y-2 rounded-lg bg-white px-4 py-2"
      >
        <div class="flex items-center gap-2">
          <Icon name="group_work" is-filled class="text-2xl text-primary" />
          <span class="font-medium leading-normal text-zinc-800">
            {{ group.name }}
          </span>
        </div>

        <Button :href="group.url" variant="ghost">
          {{ __('Go to group') }}
        </Button>
      </div>

      <Button
        v-if="canEdit"
        :href="editUrl"
        variant="outline"
        class="ml-auto flex w-fit items-center gap-1.5"
      >
        <Icon name="edit" class="text-lg" />
        <span>{{ __('Edit event') }}</span>
      </Button>
    </div>

    <div class="space-y-12">
      <Header v-model:event="event" />

      <TabGroup>
        <TabList class="flex gap-2 overflow-auto rounded-lg bg-white p-3">
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
            >
              {{ __('About') }}
            </button>
          </Tab>
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
            >
              {{ __('Attendees') }}
            </button>
          </Tab>
          <Tab
            v-if="isFutureEvent || isPresentEvent"
            v-slot="{ selected }"
            as="template"
          >
            <button
              :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
            >
              {{ __('Check In') }}
            </button>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <About :event="event" />
          </TabPanel>
          <TabPanel>
            <Attendees :event="event" />
          </TabPanel>
          <TabPanel v-if="isFutureEvent || isPresentEvent">
            <CheckIn :event="event" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { About } from './components/event/about';
  import { Attendees } from './components/event/attendees';
  import { CheckIn } from './components/event/check-in';
  import { Header } from './components/event/header';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    }
  });

  const group = computed(() => {
    if (!event.value.group || !event.value.group.id) {
      return;
    }

    const name = event.value.group.name;
    const url = route('redesign.company.groups.show', {
      id: event.value.group.id
    });

    return { name, url };
  });

  const editUrl = computed(() => {
    return route('redesign.company.events.edit', { id: event.value.id });
  });

  const isFutureEvent = computed(() => {
    return props.event.status === 'FUTURE';
  });

  const isPresentEvent = computed(() => {
    return props.event.status === 'PRESENT';
  });

  const canEdit = computed(() => {
    return props.event.conditions.can_edit;
  });

  const event = ref(JSON.parse(JSON.stringify(props.event)));
</script>
