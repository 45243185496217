<template>
	<button
		v-if="variation != 'link'"
		class="w-auto rounded-app py-2 !text-sm disabled:cursor-not-allowed disabled:opacity-50"
		:class="classes"
	>
		<div class="flex items-center justify-center gap-2 px-8">
			<Icon
				v-if="icon && icon_position == 'left'"
				:name="icon"
				class="!text-sm"
				aria-hidden="true"
			/>
			{{ __(content) }}
			<Icon
				v-if="icon && icon_position == 'right'"
				:name="icon"
				class="!text-sm"
				aria-hidden="true"
			/>
			<span v-if="srText" class="sr-only">{{ __(srText) }}</span>
		</div>
	</button>
	<button
		v-else
		class="w-auto !text-sm disabled:cursor-not-allowed disabled:opacity-50"
		:class="classes"
	>
		<div class="flex items-center justify-center gap-2">
			<Icon
				v-if="icon && icon_position == 'left'"
				:name="icon"
				class="!text-sm"
				aria-hidden="true"
			/>
			{{ __(content) }}
			<Icon
				v-if="icon && icon_position == 'right'"
				:name="icon"
				class="!text-sm"
				aria-hidden="true"
			/>
			<span v-if="srText" class="sr-only">{{ __(srText) }}</span>
		</div>
	</button>
</template>

<script setup>
import { computed } from 'vue';
import { Icon } from '.';
import { __ } from '../../helpers';

// compute classes based on type
const classes = computed(() => {
	return {
		'text-primary hover:bg-primary hover:text-button-color': props.variation === 'normal',
		'text-primary ': props.variation === 'link',
		'text-primary border-primary border hover:bg-primary hover:text-button-color':
			props.variation === 'outline',
		'text-button-color border-primary bg-primary border hover:bg-primary-600':
			props.variation === 'fill'
	};
});

const props = defineProps({
	content: {
		type: String,
		required: true
	},
	variation: {
		type: String,
		default: 'normal',
		validator: (value) => {
			return ['normal', 'outline', 'fill', 'link'].includes(value);
		}
	},
	icon: {
		type: String,
		default: ''
	},
	srText: {
		type: String,
		default: ''
	},
	icon_position: {
		type: String,
		default: 'left'
	},
	href: {
		type: String,
		default: ''
	}
});
</script>
