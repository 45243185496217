<template>
  <div class="font-primary mt-12">
    <h2 class="font-medium text-xl">{{ __("Mandatory documents") }}</h2>
    <div
      v-for="(document, index) in documents.filter(
        (document) => document.is_required
      )"
      :key="index"
      class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 pt-4"
    >
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">
          {{ document.name }}{{ document.is_required ? "*" : "" }}
        </h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">
          {{ document.description }}
        </p>
        <span
          v-if="document.is_uploaded || submitedDocuments[document.name]"
          class="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700"
        >
          <svg
            class="h-1.5 w-1.5 fill-green-500"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx="3" cy="3" r="3" />
          </svg>
          <span>{{ __("Uploaded") }}</span>
        </span>
      </div>

      <div
        class="grid md:col-span-2 bg-white p-4 rounded-app shadow-md"
        v-if="!document.is_uploaded && !submitedDocuments[document.name]"
      >
        <div class="mt-4">
          <div v-if="document.need_prefill" class="mb-4">
            <label class="block text-sm font-medium leading-6 text-gray-900">{{
              __(document.prefill_placeholder)
            }}</label>
            <OldMultiselect
              v-model="prefilledOptions[document.name]"
              :values="document.prefill_options"
              type="static"
              :disabled="isDisabled(index)"
            />
          </div>
          <label
            for="first-name"
            class="block text-sm font-medium leading-6 text-gray-900"
            >{{ __("Attach") }} {{ __(document.name) }}</label
          >
          <!-- TODO OCR: Add rule to disable -->
          <!-- If the select input isn't filled, you shouldn't upload a file -->
          <Dropfile
            v-model="uploadedDocuments[document.name]"
            :name="index"
            :extensions="document.extensions"
            :maxFileSize="document.max_file_size"
            :disabled="isDisabled(index)"
            @clear-fields="clearFields(document.name)"
            @fill-fields="fillFields(document.name)"
          />
        </div>

        <div
          v-if="
            fields[document.name]?.length != 0 &&
            uploadedDocuments[document.name]
          "
          class="grid grid-cols-2 gap-5 mt-4"
        >
          <div
            class="xl:col-span-1 col-span-2"
            v-for="(key, index) in getObjectKeys(fields[document.name])"
            :key="index"
          >
            <label
              class="font-primary font-normal text-gray-400 pb-1 flex items-center justify-between"
              >{{ __(fields[document.name][index].name) }}
              <div>
                <Toggle
                  v-model="fields[document.name][index].validated"
                  :value="fields[document.name][index].validated"
                  :setid="'mandatory'"
                  type="validator"
                />
              </div>
            </label>
            <OldMultiselect
              v-if="fields[document.name][index].type == 'select'"
              v-model="fields[document.name][index].value"
              :values="fields[document.name][index].values"
              type="static"
            />
            <InputText
              v-else
              :type="fields[document.name][index].type"
              :status="
                fields[document.name][index].validated ? 'success' : 'warning'
              "
              v-model="fields[document.name][index].value"
              :value="fields[document.name][index].value"
              :placeholder="
                __('Fill') + ' ' + __(fields[document.name][index].name)
              "
            />
          </div>
        </div>
        <div
          className="mt-6 flex items-center justify-end gap-x-6 border-t border-gray-200 pt-4"
          v-if="!isDisabled(index)"
        >
          <button
            type="button"
            @click="clearDocument(document.name)"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {{ __("Cancel") }}
          </button>
          <button
            type="submit"
            @click="submitDocument(document.name)"
            className="rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
          >
            {{ __("Save") }}
          </button>
        </div>
      </div>
    </div>
    <h2 class="font-medium text-xl mt-8">{{ __("Optional documents") }}</h2>
    <div
      v-for="(document, index) in documents.filter(
        (document) => !document.is_required
      )"
      :key="index"
      class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 pt-4"
    >
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">
          {{ document.name }}{{ document.is_required ? "*" : "" }}
        </h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">
          {{ document.description }}
        </p>
        <span
          v-if="document.is_uploaded || submitedDocuments[document.name]"
          class="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700"
        >
          <svg
            class="h-1.5 w-1.5 fill-green-500"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx="3" cy="3" r="3" />
          </svg>
          <span>{{ __("Uploaded") }}</span>
        </span>
      </div>

      <div
        class="grid md:col-span-2 bg-white p-4 rounded-app shadow-md"
        v-if="!document.is_uploaded && !submitedDocuments[document.name]"
      >
        <div class="mt-4">
          <div v-if="document.need_prefill" class="mb-4">
            <label class="block text-sm font-medium leading-6 text-gray-900">{{
              __(document.prefill_placeholder)
            }}</label>
            <!-- TODO OCR: Gray background if it's disabled -->
            <OldMultiselect
              v-model="prefilledOptions[document.name]"
              :values="document.prefill_options"
              type="static"
              :disabled="isDisabledMandatory(index)"
            />
          </div>
          <label
            for="first-name"
            class="block text-sm font-medium leading-6 text-gray-900"
            >{{ __("Attach") }} {{ __(document.name) }}</label
          >
          <!-- TODO OCR: Add rule to disable -->
          <!-- If the select input isn't filled, you shouldn't upload a file -->
          <Dropfile
            v-model="uploadedDocuments[document.name]"
            :name="index"
            :extensions="document.extensions"
            :maxFileSize="document.max_file_size"
            :disabled="isDisabledMandatory(index)"
            @clear-fields="clearFields(document.name)"
            @fill-fields="fillFields(document.name)"
          />
        </div>

        <div
          v-if="
            fields[document.name]?.length != 0 &&
            uploadedDocuments[document.name]
          "
          class="grid grid-cols-2 gap-5 mt-4"
        >
          <div
            class="xl:col-span-1 col-span-2"
            v-for="(key, index) in getObjectKeys(fields[document.name])"
            :key="index"
          >
            <label
              class="font-primary font-normal text-gray-400 pb-1 flex items-center justify-between"
              >{{ __(fields[document.name][index].name) }}
              <div>
                <Toggle
                  v-model="fields[document.name][index].validated"
                  :value="fields[document.name][index].validated"
                  :setid="'mandatory'"
                  type="validator"
                />
              </div>
            </label>
            <OldMultiselect
              v-if="fields[document.name][index].type == 'select'"
              v-model="fields[document.name][index].value"
              :values="fields[document.name][index].values"
              type="static"
            />
            <InputText
              v-else
              :type="fields[document.name][index].type"
              :status="
                fields[document.name][index].validated ? 'success' : 'warning'
              "
              v-model="fields[document.name][index].value"
              :value="fields[document.name][index].value"
              :placeholder="
                __('Fill') + ' ' + __(fields[document.name][index].name)
              "
            />
          </div>
        </div>
        <div
          className="mt-6 flex items-center justify-end gap-x-6 border-t border-gray-200 pt-4"
          v-if="!isDisabledMandatory(index)"
        >
          <button
            type="button"
            @click="clearDocument(document.name)"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {{ __("Cancel") }}
          </button>
          <button
            type="submit"
            @click="submitDocument(document.name)"
            className="rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
          >
            {{ __("Save") }}
          </button>
        </div>
      </div>
    </div>
    <LoadingOverlay
      :open="loading"
      title="Loading..."
      description="Please wait while we are processing your request."
    />
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, ref, toRef, toRefs } from "vue";
import { __ } from "../../../helpers";
import {
  InputText,
  Modal,
  Toggle,
  LoadingOverlay,
  OldMultiselect,
} from "../../global";
import Dropfile from "./DropFile.vue";

const props = defineProps({
  documents: {
    type: Array,
    required: true,
  },
});

// Create a copy of documents props where we store the uploaded documents
const uploadedDocuments = ref({});

// A variable to store the submited documents like a local copy of the server
const submitedDocuments = ref({});

// An object with the fields for each document
const fields = ref({});

// A variable to store the loading state
const loading = ref(false);

// Prefilled options for each document who need it
const prefilledOptions = ref({});

// Before mount we create the fields object with the fields for each document
onBeforeMount(() => {
  props.documents.forEach((document) => {
    if (document.fields) {
      fields.value[document.name] = document.fields.map((field) => ({
        ...field,
        validated: false,
        value: "",
      }));
    }

    console.log(fields.value);
  });
});

// Auxiliar function
const getObjectKeys = (obj) => {
  return Object.keys(obj);
};

const clearFields = (document) => {
  //  iterate through fields and set value to '' and validated to false
  fields.value[document].forEach((field) => {
    field.value = "";
    field.validated = false;
  });
};

const fillFields = (document) => {
  // Set loading to true
  loading.value = true;

  //   iterate through fields and set value to '' and validated to false

  //   TODO: Call the OCR API
  console.log("---Call the OCR API---");
  console.log("Document: ", document);
  console.log("Fields: ", fields.value[document]);
  console.log("Uploaded document: ", uploadedDocuments.value[document]);
  console.log(prefilledOptions.value[document]);
  console.log("---/Call the OCR API---");

  // Check if document.need_prefill is true
  // if (props.documents.find((doc) => doc.name == document).need_prefill) {
  //   // Check if document is in prefilledOptions
  //   if (prefilledOptions.value[document]) {
  //     // Use this when call the OCR API
  //     console.log(prefilledOptions.value[document]);
  //   }
  // }

  // Simulate request
  setTimeout(() => {
    fields.value[document].forEach((field) => {
      field.value = field.name;
    });
    loading.value = false;
  }, 1000);
};

const submitDocument = (documentName) => {
  // Check if document is uploaded
  if (!uploadedDocuments.value[documentName]) {
    console.log("Document not uploaded");
    return;
  }

  let errors = [];

  // Check if fields are validated
  if (fields.value[documentName]) {
    fields.value[documentName].forEach((field) => {
      if (!field.validated) {
        console.log("Fields not validated");
        errors.push(field.name);
      }
    });
  }

  // TODO OCR: Uncomment this
  // if (errors.length > 0) {
  //   console.error(errors);
  //   return;
  // }

  // Set loading to true
  loading.value = true;

  // Make request to API
  console.log("---SEND THE DOCUMENT---");
  console.log("Document: ", documentName);
  console.log("Fields: ", fields.value[documentName]);
  console.log("Uploaded document: ", uploadedDocuments.value[documentName]);
  console.log(prefilledOptions.value[documentName]);
  console.log("---/SEND THE DOCUMENT---");

  // simulate request
  setTimeout(() => {
    // Fill submitedDocuments
    submitedDocuments.value[documentName] =
      uploadedDocuments.value[documentName];

    loading.value = false;
  }, 1000);
};

const clearDocument = (documentName) => {
  // Clear fields
  clearFields(documentName);

  console.log(documentName);
  console.log(uploadedDocuments.value[documentName]);
  console.log(fields.value[documentName]);
};

const isDisabled = (index) => {
  if (
    index > 0 &&
    (submitedDocuments.value[props.documents[index - 1]?.name] == undefined ||
      submitedDocuments.value[props.documents[index - 1]?.name] == null)
  ) {
    return true;
  }

  return false;
};

const isDisabledMandatory = () => {
  // Check if the last mandatory document is submited
  if (
    props.documents.filter((doc) => doc.mandatory).length ==
    getObjectKeys(submitedDocuments.value).length
  ) {
    return true;
  }
};
</script>
