<template>
  <div>
    <button
      v-if="!formVisible"
      @click="toggleForm"
      type="button"
      class="block w-full rounded-md bg-brandColor1 px-3 py-2 text-center text-sm text-white shadow-sm sm:w-auto"
    >
      {{ __('Add new contact') }}
    </button>

    <form
      @submit.prevent="submit"
      v-if="formVisible"
      class="grid grid-cols-4 gap-5 rounded-md bg-zinc-100 p-6"
      contact_sources=""
      country_codes=""
      job_id=""
    >
      <div class="col-span-1">
        <div class="w-full pb-5">
          <label
            class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >{{ __('First name') }}</label
          >
          <InputText
            type="text"
            v-model="form.first_name"
            :value="form.first_name"
            :placeholder="__('First name')"
            id="first_name"
            name="first_name"
            required
          />
        </div>
      </div>

      <div class="col-span-1">
        <div class="w-full pb-5">
          <label
            class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >{{ __('Last name') }}</label
          >
          <InputText
            type="text"
            v-model="form.last_name"
            :value="form.last_name"
            :placeholder="__('Last name')"
            id="last_name"
            name="last_name"
            required
          />
        </div>
      </div>

      <div class="col-span-1">
        <div class="w-full pb-5">
          <label
            class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >{{ __('Email') }}</label
          >
          <InputText
            type="text"
            v-model="form.email"
            :value="form.email"
            :placeholder="__('Email')"
            id="email"
            name="email"
            required
          />
        </div>
      </div>

      <div class="col-span-1">
        <div class="w-full pb-5">
          <label
            class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >{{ __('Source') }}</label
          >
          <div class="relative">
            <FormSingleselect
              v-model="form.contact_source"
              :values="props.contact_sources"
              type="static"
            />
          </div>
        </div>
      </div>

      <div class="col-span-1">
        <div class="w-full pb-5">
          <label
            class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >{{ __('Link') }}</label
          >
          <InputText
            type="text"
            v-model="form.link"
            :value="form.link"
            :placeholder="__('Link')"
            id="link"
            name="link"
          />
        </div>
      </div>

      <div class="col-span-3">
        <div class="w-full pb-5">
          <label
            class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >{{ __('Phone') }}</label
          >
          <div class="relative">
            <div class="absolute w-[42%] lg:w-[30%]">
              <FormSingleselect
                v-model="form.country_code"
                :values="props.country_codes"
                type="static"
              />
            </div>
            <div class="[&>input]:pl-[45%] lg:[&>input]:pl-[32%]">
              <InputText
                v-model="form.phone"
                :value="form.phone"
                type="text"
                :placeholder="__('Phone')"
                id="phone"
                name="phone"
              />
            </div>
          </div>
        </div>
      </div>

      <InputLocation
        v-model:value="form.location"
        :label-country="__('Country')"
        :label-city="__('City')"
        :placeholder-country="__('Add country')"
        :placeholder-city="__('Add city')"
        :options="countries"
        :default-country="defaultCountry"
        class="col-span-4"
      />

      <div class="col-span-4 flex justify-center gap-2">
        <button
          type="submit"
          class="block rounded-md bg-brandColor1 px-3 py-2 text-center text-sm text-white shadow-sm"
        >
          {{ __('Add') }}
        </button>
        <button
          @click="toggleForm"
          type="button"
          class="text-center text-sm font-semibold text-brandColor1"
        >
          {{ __('Cancel') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { InputText } from '../../../global';
  import FormSingleselect from '../../../global/Form/FormSingleselect.vue';
  import { inject, ref } from 'vue';
  import axios from 'axios';
  import { useToast } from 'vue-toastification';
  import { __ } from '../../../../helpers';
  import { InputLocation } from '@/components/form/input-location';

  const toast = useToast();
  const selectedJob = inject('selectedJob');
  const loadUsers = inject('loadUsers');

  const props = defineProps({
    country_codes: {
      type: Array,
      required: true
    },
    contact_sources: {
      type: Array,
      required: true
    },
    job_id: {
      type: Number,
      required: true
    },
    countries: {
      type: Array,
      default: () => []
    },
    defaultCountry: {
      type: Object,
      default: null
    }
  });

  const formVisible = ref(0);
  const form = ref({
    email: null,
    first_name: null,
    last_name: null,
    link: null,
    contact_source: null,
    country_code: null,
    phone: null,
    location: null,
    job_id: props.job_id,
    source: null
  });

  const resetForm = () => {
    form.value.email = null;
    form.value.first_name = null;
    form.value.last_name = null;
    form.value.link = null;
    form.value.contact_source = null;
    form.value.country_code = null;
    form.value.phone = null;
    form.value.location = null;
    form.value.source = null;
  };

  function toggleForm() {
    formVisible.value = !formVisible.value;
  }

  function submit() {
    if (form.value.country_code) {
      form.value.country_prefix = form.value.country_code['id'];
    }
    if (form.value.contact_source != null) {
      form.value.source = form.value.contact_source['id'];
    }
    form.value.job_id = selectedJob.value.id;

    axios
      .post(route('redesign.company.contacts.store'), form.value)
      .then(function (response) {
        toast.success(response.data.message);
        resetForm();
        loadUsers();
      })
      .catch(function (err) {
        toast.error(err.response.data.message || __('Something went wrong.'));
      });
  }
</script>
