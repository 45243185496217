<template>
  <div class="mt-4 flow-root">
    <div>
      <div class="inline-block min-w-full py-2 align-middle min-h-[19rem]">
        <table class="min-w-full border-separate border-spacing-0">
          <thead>
          <tr class="table-row">
            <th v-for="(column, index) in columns" :key="index"
                scope="col"
                :colspan="index < 2 ? '1' : undefined"
                class="sticky top-0 z-10 shadow-redesign border-b border-gray-300 py-3.5 pr-3 text-sm font-semibold text-black bg-white"
                :class="{
                'pl-4 sm:pl-6 lg:pl-8': index === 0,
                'px-3': index !== 0,
                'text-left': index < 2,
                'text-center': index >= 2
              }">
              {{ __(capitalize(column.label)) }}
            </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(person, candidateIndex) in people" :key="candidateIndex" class="table-row">
              <template v-for="(columnData, columnComponent) in person" :key="columnComponent">
                <component :is="renderColumnComponent(columnComponent)"
                           :columnData="columnData"
                           :candidateIndex="candidateIndex"
                           :person="person"
                           :people="people"
                           :total="props.total"
                ></component>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-if="props.total === 0" class="text-center text-gray-400 py-12">
    <p>{{ __("Looks like you didn't add any contacted users on this job yet.") }}</p>
    <p>{{ __("Use the 'Add new contact' option and start adding contacts.") }}</p>
  </div>
  <div v-if="displayLoadMore" class="mt-2 mb-2 pl-4 sm:flex-none">
    <button @click="onLoadMoreUsers()" type="button" class="block rounded-md bg-brandColor2 px-3 py-2 text-center text-sm text-white shadow-sm hover:bg-brandColor2">{{ __('Load more') }}</button>
  </div>
</template>

<script setup>
import {onMounted, computed, watch, ref} from "vue";
import { capitalize } from "../../../../helpers";
import { useToast } from "vue-toastification";

const emit = defineEmits(['loadMoreUsers'])

const toast = useToast();
const props = defineProps({
  data: Array,
  columns: Array,
  per_page: Number,
  total: Number,
});

const displayLoadMore = ref(false);
const people = computed(() => props.data);

watch(people, async (newPeople) => {
  displayLoadMore.value = (newPeople.length < props.total);
});

//render as component only columns that are defined on backend, columns like user_id, seen, etc, will not be treated as components
const renderColumnComponent = (columnComponent) => {
  return props.columns.some( column => column.subcomponent_name === columnComponent ) ? columnComponent : null;
}

function onLoadMoreUsers() {
  emit("loadMoreUsers");
}

</script>