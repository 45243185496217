<template>
  <div
    class="space-y-6 rounded-2xl bg-gradient-to-b from-zinc-100 to-transparent p-4"
  >
    <div class="flex items-start justify-between gap-4">
      <div class="space-y-2">
        <span class="block text-zinc-800">
          <span class="text-lg font-medium">
            {{ position }}
          </span>
          @
          <span>{{ company }}</span>
        </span>

        <span class="block text-sm text-zinc-600">{{ date }}</span>
      </div>

      <div v-if="canEdit" class="flex flex-wrap justify-end gap-2">
        <Button
          :aria-label="__('Edit')"
          variant="icon"
          @click="handleEditButtonClick"
        >
          <Icon class="text-xl" name="edit" />
        </Button>

        <Button
          :aria-label="__('Delete')"
          variant="icon"
          @click="handleDeleteButtonClick"
        >
          <Icon class="text-xl" name="delete" />
        </Button>
      </div>
    </div>

    <div v-if="employment_type || location" class="flex flex-wrap gap-2">
      <span
        v-if="employment_type"
        class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
      >
        {{ employment_type }}
      </span>

      <span
        v-if="location"
        class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
      >
        {{ location }}
      </span>
    </div>

    <p v-if="description" class="leading-normal text-zinc-600">
      <span>{{ description }}</span>

      <button
        v-if="isDescriptionExpandButtonVisible"
        class="text-primary underline-offset-2 hover:underline"
        @click="handleDescriptionExpandButtonClick"
      >
        {{ __('see more') }}
      </button>
    </p>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { __ } from '@/helpers';
  import { formatDateToMonthYear } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    experience: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    }
  });

  const emits = defineEmits(['edit', 'delete']);

  const position = computed(() => {
    return props.experience.position;
  });

  const company = computed(() => {
    return props.experience.company;
  });

  const date = computed(() => {
    const isCurrentlyWorkingHere = props.experience.current;
    const startDate = props.experience.start_date
      ? new Date(props.experience.start_date)
      : '-';
    const endDate = isCurrentlyWorkingHere
      ? new Date()
      : props.experience.end_date
        ? new Date(props.experience.end_date)
        : '-';

    let years =
      startDate === '-' || endDate === '-'
        ? '0'
        : endDate.getFullYear() - startDate.getFullYear();
    let months =
      startDate === '-' || endDate === '-'
        ? '0'
        : endDate.getMonth() - startDate.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    let time = '';

    if (years > 0) {
      time += `${years} ${__('years')}`;
    }
    if (months > 0) {
      if (years > 0) {
        time += ` ${__('and')} `;
      }

      time += `${months} ${__('months')}`;
    }

    return `${startDate === '-' ? __('Date not set') : formatDateToMonthYear(startDate)} - ${
      isCurrentlyWorkingHere
        ? __('Present')
        : endDate === '-'
          ? __('Date not set')
          : formatDateToMonthYear(endDate)
    } (${years} ${__('years')})`;
  });

  const employment_type = computed(() => {
    return props.experience.employment_type
      ? props.experience.employment_type.name
      : '';
  });

  const location = computed(() => {
    return props.experience.location
      ? `${props.experience.location.city.name}, ${props.experience.location.country.name}`
      : '';
  });

  const description = computed(() => {
    if (!props.experience.description) {
      return '';
    }

    return props.experience.description.length <= 250 ||
      isDescriptionExpanded.value
      ? props.experience.description
      : `${props.experience.description.substring(0, 250)}...`;
  });

  const isDescriptionExpandButtonVisible = computed(() => {
    if (!props.experience.description) {
      return false;
    }

    return (
      props.experience.description.length > 250 && !isDescriptionExpanded.value
    );
  });

  const isDescriptionExpanded = ref(false);

  function handleDescriptionExpandButtonClick() {
    isDescriptionExpanded.value = true;
  }

  function handleEditButtonClick() {
    emits('edit');
  }

  function handleDeleteButtonClick() {
    emits('delete');
  }
</script>
