<template>
  <div
    class="shadow-card relative overflow-hidden rounded-2xl bg-white p-6 md:p-8"
  >
    <div
      v-if="progress"
      class="absolute right-0 top-0 rounded-l-full bg-secondary px-3 py-1"
    >
      <span class="block text-xs leading-none text-white">{{ progress }}</span>
    </div>

    <div class="space-y-10">
      <div v-if="canEdit" class="flex items-center gap-6">
        <img
          class="h-[6.25rem] w-[6.25rem] rounded"
          :src="profilePicture"
          :alt="__('Profile picture')"
        />

        <div class="space-y-1">
          <span class="block text-2xl font-medium text-primary">
            {{ name }}
          </span>
          <span v-if="position" class="block text-lg font-medium text-zinc-800">
            {{ position }}
          </span>
        </div>
      </div>

      <div v-else class="space-y-4 border-b pb-6">
        <h3 class="text-lg font-medium text-zinc-800">
          {{ __('Candidate Overview') }}
        </h3>

        <div class="flex flex-wrap gap-2">
          <span
            class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
          >
            {{ `${__('Location')}: ${residence}` }}
          </span>

          <span
            class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
          >
            {{ `${__('Career status')}: ${careerStatus}` }}
          </span>

          <span
            class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
          >
            {{ `${__('Work type')}: ${workType}` }}
          </span>

          <span
            class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
          >
            {{ `${__('Desired wage')}: ${wage}` }}
          </span>

          <span
            class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
          >
            {{ `${__('Domain')}: ${domain}` }}
          </span>

          <span
            class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
          >
            {{ `${__('Employment type')}: ${employmentType}` }}
          </span>
        </div>
      </div>

      <div v-if="personalQuote" class="space-y-2">
        <h3 class="text-lg font-medium text-zinc-800">{{ __('About') }}</h3>
        <p class="leading-normal text-zinc-600">
          {{ personalQuote }}
        </p>
      </div>

      <div class="space-y-2">
        <h3 class="text-lg font-medium text-zinc-800">
          {{ __('General info') }}
        </h3>

        <div class="grid gap-4 md:grid-cols-2 md:gap-16">
          <div class="space-y-4">
            <div class="flex flex-wrap justify-between gap-2">
              <span class="leading-normal text-zinc-600">
                {{ __('Email') }}
              </span>
              <span class="font-medium leading-normal text-zinc-800">
                {{ email }}
              </span>
            </div>

            <div class="flex flex-wrap justify-between gap-2">
              <span class="leading-normal text-zinc-600">
                {{ __('Phone number') }}
              </span>
              <span class="font-medium leading-normal text-zinc-800">
                {{ phone }}
              </span>
            </div>

            <div class="flex flex-wrap justify-between gap-2">
              <span class="leading-normal text-zinc-600">
                {{ __('Residence') }}
              </span>
              <span class="font-medium leading-normal text-zinc-800">
                {{ residence }}
              </span>
            </div>

            <div class="flex flex-wrap justify-between gap-2">
              <span class="leading-normal text-zinc-600">{{ __('Age') }}</span>
              <span class="font-medium leading-normal text-zinc-800">
                {{ age }}
              </span>
            </div>
          </div>
          <div class="space-y-4">
            <div class="flex flex-wrap justify-between gap-2">
              <span class="leading-normal text-zinc-600">
                {{ __('Career status') }}
              </span>
              <span class="font-medium leading-normal text-zinc-800">
                {{ careerStatus }}
              </span>
            </div>

            <div
              v-if="genderIdentity.isVisible"
              class="flex flex-wrap justify-between gap-2"
            >
              <span class="leading-normal text-zinc-600">
                {{ __('Gender identity') }}
              </span>
              <span class="font-medium leading-normal text-zinc-800">
                {{ genderIdentity.value }}
              </span>
            </div>

            <div
              v-if="pronouns.isVisible"
              class="flex flex-wrap justify-between gap-2"
            >
              <span class="leading-normal text-zinc-600">
                {{ __('Pronouns') }}
              </span>
              <span class="font-medium leading-normal text-zinc-800">
                {{ pronouns.value }}
              </span>
            </div>

            <div
              v-if="ethnicity.isVisible"
              class="flex flex-wrap justify-between gap-2"
            >
              <span class="leading-normal text-zinc-600">
                {{ __('Ethnicity') }}
              </span>
              <span class="font-medium leading-normal text-zinc-800">
                {{ ethnicity.value }}
              </span>
            </div>

            <div
              v-if="nationality.isVisible"
              class="flex flex-wrap justify-between gap-2"
            >
              <span class="leading-normal text-zinc-600">
                {{ __('Nationality') }}
              </span>
              <span class="font-medium leading-normal text-zinc-800">
                {{ nationality.value }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="vulnerabilities.isVisible && vulnerabilities.values.length"
        class="space-y-2"
      >
        <h3 class="text-lg font-medium text-zinc-800">
          {{ __('Vulnerabilities') }}
        </h3>

        <div class="flex flex-wrap gap-x-4 gap-y-2 divide-x-2 divide-primary">
          <span
            v-for="vulnerability in vulnerabilities.values"
            :key="vulnerability.name"
            class="pl-4 leading-tight text-zinc-600 first:!pl-0"
          >
            {{ vulnerability.name }}
          </span>
        </div>
      </div>

      <div
        v-if="
          disabilities.values.length &&
          (disabilities.isVisible ||
            (mobilityDegree.isVisible && mobilityDegree.value) ||
            (assistiveDevice.isVisible && assistiveDevice.value))
        "
        class="space-y-2"
      >
        <h3 class="text-lg font-medium text-zinc-800">
          {{ __('Disabilities') }}
        </h3>

        <div class="space-y-6">
          <div
            v-if="disabilities.isVisible"
            class="flex flex-wrap gap-x-4 gap-y-2 divide-x-2 divide-primary"
          >
            <span
              v-for="disability in disabilities.values"
              :key="disability.name"
              class="pl-4 leading-tight text-zinc-600 first:!pl-0"
            >
              {{ disability.name }}
            </span>
          </div>

          <div
            v-if="
              (mobilityDegree.isVisible && mobilityDegree.value) ||
              (assistiveDevice.isVisible && assistiveDevice.value)
            "
            class="grid gap-4 md:grid-cols-2 md:gap-16"
          >
            <div
              v-if="mobilityDegree.isVisible && mobilityDegree.value"
              class="flex flex-wrap justify-between gap-2"
            >
              <span class="leading-normal text-zinc-600">
                {{ __('Mobility degree') }}
              </span>
              <span class="font-medium leading-normal text-zinc-800">
                {{ mobilityDegree.value }}
              </span>
            </div>

            <div
              v-if="assistiveDevice.isVisible && assistiveDevice.value"
              class="flex flex-wrap justify-between gap-2"
            >
              <span class="leading-normal text-zinc-600">
                {{ __('Assistive device') }}
              </span>
              <span class="font-medium leading-normal text-zinc-800">
                {{ __(assistiveDevice.value ? 'Yes' : 'No') }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="canEdit" class="space-y-2">
        <h3 class="text-lg font-medium text-zinc-800">
          {{ __('CV Upload') }}
        </h3>

        <a
          v-if="resume.file"
          class="group -mx-4 flex w-fit items-center gap-1.5 px-4 py-2 text-primary"
          :href="resume.file.path"
          target="_blank"
        >
          <Icon class="text-xl" name="picture_as_pdf" is-filled />
          <span
            class="text-sm font-medium group-hover:underline group-hover:underline-offset-2"
          >
            {{ resume.file.name }}
          </span>
        </a>

        <span v-else class="block leading-tight text-zinc-600">
          {{ __('No CV uploaded') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    availabilityData: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    resume: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    }
  });

  const placeholderImage = '/images/logo_placeholder.png';

  const progress = computed(() => {
    return props.fields.total
      ? `${props.fields.completed}/${props.fields.total}`
      : '';
  });

  const profilePicture = computed(() => {
    return props.data.profile_photo || placeholderImage;
  });

  const name = computed(() => {
    return `${props.data.first_name} ${props.data.last_name}`;
  });

  const position = computed(() => {
    return props.data.current_position;
  });

  const personalQuote = computed(() => {
    return props.data.personal_quote;
  });

  const email = computed(() => {
    return props.data.email || '-';
  });

  const phone = computed(() => {
    if (props.data.country_prefix?.prefix && props.data.phone) {
      return `${props.data.country_prefix.prefix} ${props.data.phone}`;
    }

    return props.data.phone || '-';
  });

  const residence = computed(() => {
    if (props.data.current_location) {
      return `${props.data.current_location.city.name}, ${props.data.current_location.country.name}`;
    }

    return '-';
  });

  const age = computed(() => {
    return props.data.age || '-';
  });

  const careerStatus = computed(() => {
    return props.data.career_status?.name || '-';
  });

  const genderIdentity = computed(() => {
    const isVisible = props.data.gender?.visible;
    const value = props.data.gender?.value?.name || '-';

    return { isVisible, value };
  });

  const pronouns = computed(() => {
    const isVisible = props.data.pronoun?.visible;
    const value = props.data.pronoun?.value?.name || '-';

    return { isVisible, value };
  });

  const ethnicity = computed(() => {
    const isVisible = props.data.ethnicity?.visible;
    const value = props.data.ethnicity?.value?.name || '-';

    return { isVisible, value };
  });

  const nationality = computed(() => {
    const isVisible = props.data.nationality?.visible;
    const value = props.data.nationality?.value?.name || '-';

    return { isVisible, value };
  });

  const vulnerabilities = computed(() => {
    const isVisible = props.data.vulnerability.visible;
    const values = props.data.vulnerability?.value || [];

    return { isVisible, values };
  });

  const disabilities = computed(() => {
    const isVisible = props.data.disability.visible;
    const values = props.data.disability?.value || [];

    return { isVisible, values };
  });

  const mobilityDegree = computed(() => {
    const isVisible = props.data.mobility_degree.visible;
    const value = props.data.mobility_degree?.value?.name;

    return { isVisible, value };
  });

  const assistiveDevice = computed(() => {
    const isVisible = props.data.assistive_device.visible;
    const value = props.data.assistive_device?.value;

    return { isVisible, value };
  });

  const workType = computed(() => {
    return props.availabilityData.presence &&
      props.availabilityData.presence.length
      ? props.availabilityData.presence.map((item) => item.name).join(', ')
      : '-';
  });

  const wage = computed(() => {
    return props.availabilityData.desired_wage &&
      props.availabilityData.currency &&
      props.availabilityData.currency.name &&
      props.availabilityData.salary_format &&
      props.availabilityData.salary_format.name &&
      props.availabilityData.salary_type &&
      props.availabilityData.salary_type.name
      ? `${props.availabilityData.desired_wage} ${props.availabilityData.currency.name} ${props.availabilityData.salary_format.name} / ${props.availabilityData.salary_type.name}`
      : '-';
  });

  const domain = computed(() => {
    return props.availabilityData.domain && props.availabilityData.domain.length
      ? props.availabilityData.domain.map((item) => item.name).join(', ')
      : '-';
  });

  const employmentType = computed(() => {
    return props.availabilityData.employment_type &&
      props.availabilityData.employment_type.length
      ? props.availabilityData.employment_type
          .map((item) => item.name)
          .join(', ')
      : '-';
  });
</script>
