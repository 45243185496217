<template>
  <div>
    <label
      v-if="label"
      :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
      :for="id"
    >
      {{ `${label}${required ? '*' : ''}` }}
    </label>

    <label
      :class="`${disabled || isLoading ? 'cursor-not-allowed' : 'cursor-pointer'} ${label ? 'py-3' : 'py-0'} flex w-fit items-center gap-3`"
      :for="id"
    >
      <slot name="label-start" />

      <div :class="mainContainerClassName">
        <div :class="containerClassName">
          <Icon :class="iconClassName" name="progress_activity" />
          <span :class="circleClassName" />
        </div>
      </div>

      <slot name="label-end" />

      <input
        :id="id"
        class="sr-only"
        type="checkbox"
        :checked="value"
        :disabled="disabled || isLoading"
        @change="handleChange"
      />
    </label>

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary'].includes(value)
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['medium', 'large'].includes(value)
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  const mainContainerClassName = computed(() => {
    let className = 'relative flex justify-center';

    switch (props.size) {
      case 'medium': {
        className = `${className} h-[20px] w-[36px]`;
        break;
      }
      case 'large': {
        className = `${className} h-[22px] w-[40px]`;
        break;
      }
    }

    return className;
  });

  const containerClassName = computed(() => {
    let className = `${props.value ? (props.isLoading ? 'bg-gray-200' : `bg-${props.variant}`) : 'bg-gray-200'} ${props.disabled ? 'opacity-50' : 'opacity-100'} absolute h-full rounded-full transition !duration-150`;

    switch (props.size) {
      case 'medium': {
        className = `${className} ${props.isLoading ? `w-[20px]` : 'w-full'}`;
        break;
      }
      case 'large': {
        className = `${className} ${props.isLoading ? `w-[22px]` : 'w-full'}`;
        break;
      }
    }

    return className;
  });

  const iconClassName = computed(() => {
    let className = `${props.isLoading ? 'opacity-100' : 'opacity-0'} absolute -left-[2px] -top-[2px] animate-spin text-${props.variant} transition-colors`;

    switch (props.size) {
      case 'medium': {
        className = `${className} text-[24px]`;
        break;
      }
      case 'large': {
        className = `${className} text-[26px]`;
        break;
      }
    }

    return className;
  });

  const circleClassName = computed(() => {
    let className = `${props.value ? 'left-[unset] right-[2px]' : 'left-[2px] right-[unset]'} absolute top-[2px] rounded-full bg-white transition !duration-150`;

    switch (props.size) {
      case 'medium': {
        className = `${className} h-[16px] w-[16px]`;
        break;
      }
      case 'large': {
        className = `${className} h-[18px] w-[18px]`;
        break;
      }
    }

    return className;
  });

  function handleChange() {
    emits('update:value', !props.value);
    emits('update:errors', []);
  }
</script>
