<template>
  <div
    class="candidate-gamification__subtabs candidate-gamification__badges-achievements"
  >
    <div class="candidate-gamification__subtab selected" id="badges-tab">
      <div
        v-for="badge in user.badges"
        :class="
          badge.owned
            ? 'candidate-gamification__badges-achievements--item owned'
            : 'candidate-gamification__badges-achievements--item'
        "
      >
        <div class="candidate-gamification__badges-achievements--icon">
          <img :src="badge.img" alt="" width="50" height="50" />
        </div>
        <div class="candidate-gamification__badges-achievements--desc">
          <strong>{{ badge.name }}</strong>
          <p>{{ badge.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
};
</script>
