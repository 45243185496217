<template>
  <div>
    <label
      v-if="label"
      for="dp-input-date-picker"
      :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
    >
      {{ `${label}${required ? '*' : ''}` }}
    </label>

    <VueDatePicker
      :style="{
        '--dp-border-color': errors.length ? 'rgb(239, 68, 68)' : '#d5dae1',
        '--dp-border-color-hover': errors.length
          ? 'rgb(239, 68, 68)'
          : '#d5dae1',
        '--dp-border-color-focus': errors.length
          ? 'rgb(239, 68, 68)'
          : 'rgba(var(--tw-secondary-color))'
      }"
      uid="date-picker"
      auto-apply
      :enable-time-picker="enableTimePicker"
      :format="format"
      :min-date="min"
      :max-date="max"
      :min-time="minTime"
      :max-time="maxTime"
      :placeholder="placeholder"
      :disabled="disabled"
      :model-value="props.value"
      @update:model-value="handleUpdateModelValue"
    />

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  import { computed } from 'vue';

  const props = defineProps({
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    enableTimePicker: {
      type: Boolean,
      default: false
    },
    min: {
      type: [String, Date, null],
      default: null
    },
    max: {
      type: [String, Date, null],
      default: null
    },
    value: {
      type: [String, null],
      default: null
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  const format = computed(() => {
    return props.enableTimePicker ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';
  });

  const minTime = computed(() => {
    if (!props.enableTimePicker || !props.min || !props.value) {
      return null;
    }

    const min = new Date(props.min);
    const value = new Date(props.value);

    const minDate = new Date(min.getFullYear(), min.getMonth(), min.getDate());
    const valueDate = new Date(
      value.getFullYear(),
      value.getMonth(),
      value.getDate()
    );

    if (valueDate > minDate) {
      return null;
    }

    return {
      hours: min.getHours(),
      minutes: min.getMinutes()
    };
  });

  const maxTime = computed(() => {
    if (!props.enableTimePicker || !props.max || !props.value) {
      return null;
    }

    const max = new Date(props.max);
    const value = new Date(props.value);

    const maxDate = new Date(max.getFullYear(), max.getMonth(), max.getDate());
    const valueDate = new Date(
      value.getFullYear(),
      value.getMonth(),
      value.getDate()
    );

    if (valueDate < maxDate) {
      return null;
    }

    return {
      hours: max.getHours(),
      minutes: max.getMinutes()
    };
  });

  function handleUpdateModelValue(value) {
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, '0');
    const day = value.getDate().toString().padStart(2, '0');
    const hours = value.getHours().toString().padStart(2, '0');
    const minutes = value.getMinutes().toString().padStart(2, '0');
    const seconds = value.getSeconds().toString().padStart(2, '0');

    emits(
      'update:value',
      `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    );
    emits('update:errors', []);
  }
</script>

<style>
  .dp__input:focus,
  .dp__input_focus {
    border-color: rgba(var(--tw-secondary-color));
    box-shadow: 0 0 0 1px rgba(var(--tw-secondary-color));
  }

  .dp__theme_light {
    --dp-border-radius: 6px;
    --dp-cell-border-radius: 6px;
    --dp-input-padding: 9px 30px 9px 12px;
    --dp-background-color: #fff;
    --dp-text-color: #27272a;
    --dp-hover-color: rgba(var(--tw-primary-color), 0.05);
    --dp-hover-text-color: #27272a;
    --dp-hover-icon-color: #a3a3a3;
    --dp-primary-color: rgba(var(--tw-primary-color));
    --dp-primary-disabled-color: rgba(var(--tw-primary-color), 0.5);
    --dp-primary-text-color: #fff;
    --dp-secondary-color: #71717a;
    --dp-menu-border-color: #d5dae1;
    --dp-disabled-color: #f4f4f5;
    --dp-icon-color: #a3a3a3;
    --dp-disabled-color-text: #71717a;
    --dp-highlight-color: rgba(var(--tw-primary-color), 0.05);
  }
</style>
