<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="emit('close')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6 md:w-[80rem]"
            >
              <Icon
                name="close"
                class="absolute right-4 top-4 cursor-pointer"
                @click="emit('close')"
              />

              <JobSingle
                :job="job"
                :user_type="user_type"
                :user_profile="user_profile"
                :options="options"
                @save="emit('save', job)"
                @accept_invitation="emit('accept_invitation', job)"
                @decline_invitation="emit('decline_invitation', job)"
                :config_general_single_company="config_general_single_company"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import { onMounted, watch } from 'vue';
  import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot
  } from '@headlessui/vue';
  import { Icon } from '../../../../components/global';
  import { __ } from '../../../../helpers';
  import { useToast } from 'vue-toastification';
  import { JobSingle } from '.';
  import axios from 'axios';

  const props = defineProps({
    open: Boolean,
    job: Object,
    user_type: {
      type: String,
      required: false,
      default: 'guest'
    },
    config_general_single_company: {
      type: Number,
      required: false,
      default: 0
    },
    user_profile: {
      type: String,
      required: false
    },
    options: {
      type: Object
    },
    mark_viewed: {
      type: Boolean,
      required: false,
      default: true
    }
  });

  const toast = useToast();

  const emit = defineEmits([
    'close',
    'save',
    'accept_invitation',
    'decline_invitation'
  ]);

  // on each open, mark the job as viewed
  onMounted(() => {
    watch(
      () => props.open,
      (value) => {
        if (value && props.mark_viewed && props.job.active) {
          axios
            .post(
              route('redesign.jobs.markView', {
                company: props.job.company.slug,
                job: props.job.slug
              })
            )
            .then((response) => {})
            .catch((error) => {
              toast.error(
                error.response.data.message || __('Something went wrong.')
              );
            });
        }
      }
    );
  });
</script>
