<template>
  <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
    <form @submit.prevent="handleSubmit">
      <div v-if="values.length" class="mb-8">
        <ul
          ref="scrollContainer"
          class="-mx-2 -my-3 max-h-[60rem] space-y-6 overflow-y-auto px-2 py-3 md:-mx-3 md:-my-4 md:px-3 md:py-4"
        >
          <li
            v-for="(item, index) in values"
            :key="item"
            class="shadow-element rounded-lg px-2 py-3"
          >
            <div class="flex justify-end">
              <button
                class="-m-1 flex p-1.5 text-zinc-600"
                type="button"
                :aria-label="__('Delete')"
                @click="handleDeleteButtonClick(index)"
              >
                <Icon class="text-xl" name="close" />
              </button>
            </div>
            <div class="mt-2 grid grid-cols-1 gap-2">
              <InputText
                :id="`${index}.question`"
                v-model:value="item.question"
                v-model:errors="errors[`${index}.question`]"
                :label="__('Question')"
                :placeholder="__('Add question')"
                required
              />
              <InputTextarea
                :id="`${index}.answer`"
                v-model:value="item.answer"
                v-model:errors="errors[`${index}.answer`]"
                :placeholder="__('Add answer')"
                required
              />
            </div>
          </li>
        </ul>
      </div>

      <Button
        class="flex items-center gap-1.5"
        variant="ghost"
        @click="handleAddNewButtonClick"
      >
        <Icon class="text-xl" name="add_box" is-filled />
        <span>{{ __('Add another question') }}</span>
      </Button>

      <span
        v-if="errorMessage"
        class="mt-8 block text-center text-sm text-error"
      >
        {{ errorMessage }}
      </span>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { nextTick, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputText } from '@/components/form/input-text';
  import { InputTextarea } from '@/components/form/input-textarea';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  });

  const emits = defineEmits(['save', 'cancel']);

  const toast = useToast();

  const scrollContainer = ref(null);
  const values = ref(JSON.parse(JSON.stringify(props.data)));
  const errors = ref({});
  const isLoading = ref(false);

  async function handleAddNewButtonClick() {
    values.value.push({ id: null, question: '', answer: '' });

    if (!scrollContainer.value) {
      return;
    }

    await nextTick();

    scrollContainer.value.scrollTo({
      top: scrollContainer.value.scrollHeight,
      behavior: 'smooth'
    });
  }

  function handleDeleteButtonClick(index) {
    values.value = values.value.filter((_, i) => i !== index);
    errors.value = {};
  }

  async function deleteItem(id) {
    await axios.post(route('redesign.company.delete.faq'), { id });
  }

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    const requiredFieldKeys = ['question', 'answer'];
    let firstElement;

    values.value.forEach((item, index) => {
      requiredFieldKeys.forEach((key) => {
        if (!item[key]) {
          errors.value[`${index}.${key}`] = [__('This field is required')];

          const element = document.getElementById(`${index}.${key}`);

          if (element && !firstElement) {
            firstElement = element;
          }
        }
      });
    });

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      const deleteItemPromiseList = props.data
        .filter((item) => !values.value.some((value) => value.id === item.id))
        .map((item) => deleteItem(item.id));

      await Promise.all(deleteItemPromiseList);

      const response = await axios.post(
        route('redesign.company.update.faq'),
        values.value
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', response.data.data);
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }

  onMounted(() => {
    if (!values.value.length) {
      values.value.push({ id: null, question: '', answer: '' });
    }
  });
</script>
