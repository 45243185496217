<template>
  <div class="shadow-card space-y-8 rounded-lg bg-white p-6 md:px-6 md:py-8">
    <img :src="coverUrl" :alt="__('Cover')" class="w-full rounded" />

    <div class="space-y-6">
      <div class="flex flex-wrap items-start justify-between gap-4">
        <div class="space-y-2.5">
          <h1 class="text-4xl font-medium text-primary">{{ name }}</h1>

          <span class="block text-lg font-medium leading-normal text-zinc-800">
            {{ `${__('Date and time')}: ${dateTime}` }}
          </span>

          <div
            v-if="isPastEvent || isRatedEvent"
            class="flex items-center gap-1.5"
          >
            <Icon name="group" is-filled class="text-xl text-primary" />
            <span class="text-sm text-zinc-800">
              {{ `${checkedInAttendees} ${__('people attended')}` }}
            </span>
          </div>

          <div v-else class="flex flex-wrap items-center gap-x-4 gap-y-2">
            <div class="flex items-center gap-1.5">
              <Icon name="group" is-filled class="text-xl text-primary" />
              <span class="text-sm text-zinc-800">
                {{ `${attendees} ${__('attendees')}` }}
              </span>
            </div>

            <div v-if="attendeeLimit" class="flex items-center gap-1.5">
              <Icon name="group_add" is-filled class="text-xl text-primary" />
              <span class="text-sm text-zinc-800">
                {{ `${attendeeLimit - attendees} ${__('spots left')}` }}
              </span>
            </div>

            <div v-if="registrationDeadline" class="flex items-center gap-1.5">
              <Icon
                name="calendar_clock"
                is-filled
                class="text-xl text-primary"
              />
              <span class="text-sm text-zinc-800">
                {{ `${__('Register until')} ${registrationDeadline}` }}
              </span>
            </div>
          </div>
        </div>

        <Dropdown
          position="right"
          :options="shareDropdownOptions"
          @select="handleShareDropdownSelect"
        >
          <template #trigger>
            <Button
              tabindex="-1"
              variant="ghost"
              class="flex items-center gap-1.5"
            >
              <Icon name="share" class="text-xl text-primary" />
              <span class="text-zinc-600">{{ __('Share this event') }}</span>
            </Button>
          </template>
        </Dropdown>
      </div>

      <template v-if="isFutureEvent">
        <div v-if="registrationDeadlinePassed" class="space-y-2">
          <div class="flex items-center gap-2">
            <Icon name="event_busy" is-filled class="text-2xl text-error" />
            <span class="text-lg font-medium text-zinc-800">
              {{ __('Registration deadline passed') }}
            </span>
          </div>

          <p v-if="isUserRegistered" class="leading-normal text-zinc-600">
            {{ __('Once you unregister, you cannot register back.') }}
          </p>

          <p v-else class="leading-normal text-zinc-600">
            {{
              __(
                'Explore other exciting events happening around the same time!'
              )
            }}

            <a
              :href="allEventsUrl"
              class="text-primary underline underline-offset-2"
            >
              {{ __('See more') }}
            </a>
          </p>
        </div>

        <div v-else-if="attendeeLimitReached" class="space-y-2">
          <div class="flex items-center gap-2">
            <Icon name="event_busy" is-filled class="text-2xl text-error" />
            <span class="text-lg font-medium text-zinc-800">
              {{ __('The event has reached the maximum limit') }}
            </span>
          </div>

          <p v-if="isUserRegistered" class="leading-normal text-zinc-600">
            {{
              __(
                "Space is limited! Unregistering now means you'll need to re-register if you want to attend."
              )
            }}
          </p>

          <p v-else class="leading-normal text-zinc-600">
            {{
              __(
                'Explore other exciting events happening around the same time!'
              )
            }}

            <a
              :href="allEventsUrl"
              class="text-primary underline underline-offset-2"
            >
              {{ __('See more') }}
            </a>
          </p>
        </div>

        <div v-if="isUserRegistered" class="flex flex-wrap items-center gap-2">
          <span
            class="rounded-md bg-success px-4 py-2 text-sm font-medium text-white"
          >
            {{ __('Registered') }}
          </span>

          <Button
            :disabled="isLoadingUnregister"
            variant="outline"
            class="flex items-center justify-center gap-1.5"
            @click="handleUnregisterButtonClick"
          >
            <Icon
              v-if="isLoadingUnregister"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span> {{ __('Unregister') }}</span>
          </Button>
        </div>

        <Button
          v-else-if="
            !attendeeLimitReached && !registrationDeadlinePassed && canRegister
          "
          :disabled="isLoadingRegister"
          class="flex items-center justify-center gap-1.5"
          @click="handleRegisterButtonClick"
        >
          <Icon
            v-if="isLoadingRegister"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span> {{ __('Register Now') }}</span>
        </Button>
      </template>

      <div v-else-if="isPresentEvent" class="space-y-4">
        <div class="flex items-center gap-2">
          <Icon name="stream" is-filled class="text-2xl text-primary" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('The event is happening now') }}
          </span>
        </div>

        <div
          v-if="isUserCheckedIn"
          class="flex w-fit items-center gap-1.5 rounded-md bg-success px-4 py-2 text-white"
        >
          <Icon name="event_available" is-filled class="text-xl" />

          <span class="text-sm font-medium">
            {{ __('You checked in') }}
          </span>
        </div>

        <template v-else>
          <div v-if="registrationDeadlinePassed" class="space-y-2">
            <div class="flex items-center gap-2">
              <Icon name="event_busy" is-filled class="text-2xl text-error" />
              <span class="text-lg font-medium text-zinc-800">
                {{ __('Registration deadline passed') }}
              </span>
            </div>

            <p v-if="isUserRegistered" class="leading-normal text-zinc-600">
              {{ __('Once you unregister, you cannot register back.') }}
            </p>

            <p v-else class="leading-normal text-zinc-600">
              {{
                __(
                  'Explore other exciting events happening around the same time!'
                )
              }}

              <a
                :href="allEventsUrl"
                class="text-primary underline underline-offset-2"
              >
                {{ __('See more') }}
              </a>
            </p>
          </div>

          <div v-else-if="attendeeLimitReached" class="space-y-2">
            <div class="flex items-center gap-2">
              <Icon name="event_busy" is-filled class="text-2xl text-error" />
              <span class="text-lg font-medium text-zinc-800">
                {{ __('The event has reached the maximum limit') }}
              </span>
            </div>

            <p v-if="isUserRegistered" class="leading-normal text-zinc-600">
              {{
                __(
                  "Space is limited! Unregistering now means you'll need to re-register if you want to attend."
                )
              }}
            </p>

            <p v-else class="leading-normal text-zinc-600">
              {{
                __(
                  'Explore other exciting events happening around the same time!'
                )
              }}

              <a
                :href="allEventsUrl"
                class="text-primary underline underline-offset-2"
              >
                {{ __('See more') }}
              </a>
            </p>
          </div>

          <div
            v-if="isUserRegistered || isTokenActive"
            class="flex flex-wrap items-center gap-2"
          >
            <span
              v-if="isUserRegistered"
              class="rounded-md bg-success px-4 py-2 text-sm font-medium text-white"
            >
              {{ __('Registered') }}
            </span>

            <Button v-if="canCheckIn" @click="handleCheckInButtonClick">
              {{ __('Check In Now') }}
            </Button>
          </div>

          <Button
            v-else-if="
              !attendeeLimitReached &&
              !registrationDeadlinePassed &&
              canRegister
            "
            :disabled="isLoadingRegister"
            class="flex items-center justify-center gap-1.5"
            @click="handleRegisterButtonClick"
          >
            <Icon
              v-if="isLoadingRegister"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span> {{ __('Register Now') }}</span>
          </Button>
        </template>
      </div>

      <div v-else-if="isPastEvent" class="space-y-4">
        <div class="flex items-center gap-2">
          <Icon name="local_activity" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('This event has passed') }}
          </span>
        </div>

        <p v-if="isUserUnregistered" class="!mt-2 leading-normal text-zinc-600">
          {{
            __('Explore other exciting events happening around the same time!')
          }}

          <a
            :href="allEventsUrl"
            class="text-primary underline underline-offset-2"
          >
            {{ __('See more') }}
          </a>
        </p>

        <div
          v-else-if="isUserRegistered"
          class="flex w-fit items-center gap-1.5 rounded-md bg-error px-4 py-2 text-white"
        >
          <Icon name="cancel" is-filled class="text-xl" />

          <span class="text-sm font-medium">
            {{ __("You didn't check in") }}
          </span>
        </div>

        <div
          v-else-if="isUserCheckedIn"
          class="flex flex-wrap items-center gap-2"
        >
          <div
            class="flex items-center gap-1.5 rounded-md bg-success px-4 py-2 text-white"
          >
            <Icon name="event_available" is-filled class="text-xl" />

            <span class="text-sm font-medium">
              {{ __('You checked in') }}
            </span>
          </div>

          <Button
            class="flex items-center gap-1.5"
            @click="handleRateButtonClick"
          >
            <Icon name="reviews" is-filled class="text-xl text-white" />
            <span class="text-white">{{ __('Rate this event') }}</span>
          </Button>
        </div>
      </div>

      <div v-else-if="isRatedEvent" class="space-y-4">
        <div class="flex items-center gap-2">
          <Icon name="local_activity" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('This event has passed') }}
          </span>
        </div>

        <div
          class="flex w-fit items-center gap-1.5 rounded-md bg-success px-4 py-2 text-white"
        >
          <Icon name="reviews" is-filled class="text-xl" />

          <span class="text-sm font-medium">
            {{ __('You rated this event') }}
          </span>
        </div>
      </div>

      <div v-else-if="isCanceledEvent" class="space-y-2">
        <div class="flex items-center gap-2">
          <Icon name="local_activity" is-filled class="text-2xl text-error" />
          <span class="text-lg font-medium text-zinc-800">
            {{ __('This event has been canceled') }}
          </span>
        </div>

        <p v-if="isUserRegistered" class="leading-normal text-zinc-600">
          {{
            __(
              "The event you signed up for isn't happening anymore. Explore other exciting events happening around the same time!"
            )
          }}

          <a
            :href="allEventsUrl"
            class="text-primary underline underline-offset-2"
          >
            {{ __('See more') }}
          </a>
        </p>

        <p v-else class="leading-normal text-zinc-600">
          {{
            __('Explore other exciting events happening around the same time!')
          }}

          <a
            :href="allEventsUrl"
            class="text-primary underline underline-offset-2"
          >
            {{ __('See more') }}
          </a>
        </p>
      </div>
    </div>

    <EventRateModal
      v-if="isEventRateModalOpen"
      :event="event"
      @cancel="handleCancelEventRateModalButtonClick"
      @save="handleSaveEventRateModalButtonClick"
    />
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import {
    formatDateWithoutUTC,
    formatTimeWithoutUTC
  } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Dropdown } from '@/components/block/dropdown';
  import { Icon } from '@/components/block/icon';
  import { EventRateModal } from '../../shared/event-rate-modal';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['update:event', 'update:user']);

  const toast = useToast();

  const allEventsUrl = route('redesign.events.viewAll');
  const shareDropdownOptions = [
    {
      id: 1,
      name: __('Share to LinkedIn')
    },
    {
      id: 2,
      name: __('Share to Facebook')
    },
    {
      id: 3,
      name: __('Copy link to event')
    }
  ];

  const coverUrl = computed(() => {
    return props.event.details.cover.includes('/uploads/')
      ? props.event.details.cover
      : `/uploads/${props.event.details.cover}`;
  });

  const name = computed(() => {
    return props.event.details.name;
  });

  const dateTime = computed(() => {
    const startDate = formatDateWithoutUTC(
      `${props.event.details.start_date_time}${props.event.details.timezone.offset}`
    );
    const startTime = formatTimeWithoutUTC(
      `${props.event.details.start_date_time}${props.event.details.timezone.offset}`
    );
    const endDate = formatDateWithoutUTC(
      `${props.event.details.end_date_time}${props.event.details.timezone.offset}`
    );
    const endTime = formatTimeWithoutUTC(
      `${props.event.details.end_date_time}${props.event.details.timezone.offset}`
    );

    if (startDate !== endDate) {
      return `${startDate} | ${startTime} - ${endDate} | ${endTime}`;
    }

    return `${startDate} | ${startTime} - ${endTime}`;
  });

  const attendees = computed(() => {
    return props.event.details.attendees;
  });

  const attendeeLimit = computed(() => {
    return props.event.details.attendee_limit;
  });

  const checkedInAttendees = computed(() => {
    return props.event.details.checked_in_attendees;
  });

  const registrationDeadline = computed(() => {
    if (!props.event.details.rsvp) {
      return null;
    }

    const rsvpDate = formatDateWithoutUTC(
      `${props.event.details.rsvp}${props.event.details.timezone.offset}`
    );
    const rsvpTime = formatTimeWithoutUTC(
      `${props.event.details.rsvp}${props.event.details.timezone.offset}`
    );

    return `${rsvpDate} | ${rsvpTime}`;
  });

  const attendeeLimitReached = computed(() => {
    if (!attendeeLimit.value) {
      return false;
    }

    return attendees.value === attendeeLimit.value;
  });

  const registrationDeadlinePassed = computed(() => {
    if (!props.event.details.rsvp) {
      return false;
    }

    const rsvp = new Date(
      `${props.event.details.rsvp}${props.event.details.timezone.offset}`
    );

    return new Date() > rsvp;
  });

  const isFutureEvent = computed(() => {
    return props.event.status === 'FUTURE' || props.event.status === 'UPCOMING';
  });

  const isPresentEvent = computed(() => {
    return props.event.status === 'PRESENT';
  });

  const isPastEvent = computed(() => {
    return props.event.status === 'PAST';
  });

  const isRatedEvent = computed(() => {
    return props.event.status === 'RATED';
  });

  const isCanceledEvent = computed(() => {
    return props.event.status === 'CANCELED';
  });

  const isUserUnregistered = computed(() => {
    return props.user.conditions.status === 'UNREGISTERED';
  });

  const isUserRegistered = computed(() => {
    return props.user.conditions.status === 'REGISTERED';
  });

  const isTokenActive = computed(() => {
    return props.user.conditions.active_token;
  });

  const isUserCheckedIn = computed(() => {
    return props.user.conditions.status === 'CHECKED_IN';
  });

  const canRegister = computed(() => {
    return props.user.conditions.can_register;
  });

  const canCheckIn = computed(() => {
    return props.user.conditions.can_check_in;
  });

  const isLoadingRegister = ref(false);
  const isLoadingUnregister = ref(false);
  const isLoadingCheckIn = ref(false);
  const isEventRateModalOpen = ref(false);

  async function handleRegisterButtonClick() {
    isLoadingRegister.value = true;

    try {
      const response = await axios.post(
        route('redesign.events.register', {
          slug: props.event.details.slug
        })
      );

      if (response?.data?.redirect_url) {
        window.location.href = response.data.redirect_url;
        return;
      }

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('update:event', {
        ...props.event,
        details: {
          ...props.event.details,
          attendees: props.event.details.attendees + 1
        }
      });
      emits('update:user', {
        ...props.user,
        conditions: {
          ...props.user.conditions,
          can_invite: true,
          status: 'REGISTERED'
        }
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingRegister.value = false;
  }

  async function handleUnregisterButtonClick() {
    isLoadingRegister.value = true;

    try {
      const response = await axios.post(
        route('redesign.events.unregister', {
          slug: props.event.details.slug
        })
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('update:event', {
        ...props.event,
        details: {
          ...props.event.details,
          attendees: props.event.details.attendees - 1
        }
      });
      emits('update:user', {
        ...props.user,
        conditions: { ...props.user.conditions, status: 'UNREGISTERED' }
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingRegister.value = false;
  }

  async function handleCheckInButtonClick() {
    isLoadingCheckIn.value = true;

    try {
      const response = await axios.post(
        route('redesign.events.checkIn', {
          slug: props.event.details.slug,
          active_token: props.user.conditions.active_token
        })
      );

      if (response?.data?.redirect_url) {
        window.location.href = response.data.redirect_url;
        return;
      }

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('update:user', {
        ...props.user,
        conditions: { ...props.user.conditions, status: 'CHECKED_IN' }
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingCheckIn.value = false;
  }

  function handleRateButtonClick() {
    isEventRateModalOpen.value = true;
  }

  function handleCancelEventRateModalButtonClick() {
    isEventRateModalOpen.value = false;
  }

  async function handleSaveEventRateModalButtonClick() {
    emits('update:event', { ...props.event, status: 'RATED' });
    isEventRateModalOpen.value = false;
  }

  async function handleShareDropdownSelect(option) {
    const eventUrl = route('redesign.events.view', {
      slug: props.event.details.slug
    });

    switch (option.id) {
      case 1: {
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${eventUrl}`,
          '_blank'
        );
        break;
      }
      case 2: {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${eventUrl}`,
          '_blank'
        );
        break;
      }
      case 3: {
        try {
          await navigator.clipboard.writeText(eventUrl);

          toast.success(__('Event link has been copied to your clipboard!'));
        } catch {
          toast.error(
            __('An unexpected error has occured. Please try again later.')
          );
        }
        break;
      }
    }
  }
</script>
