<template>
  <div class="shadow-element overflow-hidden rounded-lg">
    <div class="space-y-4 p-4 pb-6">
      <div class="flex flex-wrap items-start gap-4">
        <div class="flex items-center gap-4">
          <img class="h-14 w-14" :src="logo" :alt="name" />

          <div>
            <span
              class="block text-lg font-medium leading-normal text-zinc-800"
            >
              {{ name }}
            </span>
            <span class="block leading-normal text-zinc-600">
              {{ description }}
            </span>
          </div>
        </div>

        <Button
          v-if="isConfigured && !isGlobal && !isExpanded"
          class="ml-auto flex items-center gap-2"
          variant="ghost"
          @click="handleConfigureButtonClick"
        >
          <Icon name="edit" class="text-xl" />
          <span>{{ __('Edit') }}</span>
        </Button>
      </div>

      <form v-if="isExpanded" class="grid gap-4 md:grid-cols-2">
        <InputText
          v-for="field in fields"
          :id="field.id"
          :key="field.id"
          v-model:value="values[field.id]"
          v-model:errors="errors[field.id]"
          :label="field.label"
          :placeholder="field.description"
          :required="field.required"
        />
      </form>
    </div>

    <div class="flex flex-wrap items-center gap-4 bg-zinc-50 p-4">
      <div v-if="!isExpanded" class="flex items-center gap-2">
        <template v-if="isNotConfigured">
          <Icon class="text-lg text-zinc-600" name="settings" />
          <span class="text-sm text-zinc-600">
            {{
              __('The integration must be configured before it can be used.')
            }}
          </span>
        </template>

        <template v-else-if="isPending">
          <Icon class="text-lg text-error" name="hourglass_top" />
          <span class="text-sm text-zinc-600">
            {{ __('Processing integration. It will be available shortly.') }}
          </span>
        </template>

        <template v-else-if="isConfigured">
          <Icon class="text-lg text-success" name="integration_instructions" />

          <span class="text-sm text-zinc-600">
            {{
              __(
                isGlobal
                  ? 'The integration is pre-configured and ready to use. Activate it when needed.'
                  : 'The integration process is finished. You can now use it.'
              )
            }}
          </span>
        </template>
      </div>

      <div class="ml-auto">
        <div v-if="isExpanded" class="flex gap-2">
          <Button variant="ghost" @click="handleCancelButtonClick">
            {{ __('Cancel') }}
          </Button>

          <Button
            class="flex items-center justify-center gap-1.5"
            :disabled="isLoading"
            @click="handleSaveButtonClick"
          >
            <Icon
              v-if="isLoading"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span>{{ __('Save') }}</span>
          </Button>
        </div>

        <Button v-else-if="isNotConfigured" @click="handleConfigureButtonClick">
          {{ __('Configure') }}
        </Button>

        <InputToggle
          v-else-if="isConfigured"
          :id="id"
          :value="isActive"
          :is-loading="isLoadingState"
          @update:value="handleStateToggle"
        >
          <template #label-start>
            <span class="text-sm text-zinc-800">
              {{ __(isActive ? 'Active' : 'Inactive') }}
            </span>
          </template>
        </InputToggle>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputText } from '@/components/form/input-text';
  import { InputToggle } from '@/components/form/input-toggle';

  const props = defineProps({
    integration: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['update:integration']);

  const toast = useToast();

  const id = computed(() => {
    return `${props.integration.id}`;
  });

  const logo = computed(() => {
    return `/uploads/${props.integration.logo}`;
  });

  const name = computed(() => {
    return props.integration.name;
  });

  const description = computed(() => {
    return props.integration.description;
  });

  const isNotConfigured = computed(() => {
    return props.integration.state === 'NOT_SET';
  });

  const isPending = computed(() => {
    return props.integration.state === 'PENDING';
  });

  const isConfigured = computed(() => {
    return props.integration.state === 'CONFIGURED';
  });

  const isActive = computed(() => {
    return props.integration.is_active;
  });

  const isGlobal = computed(() => {
    return props.integration.is_global;
  });

  const fields = computed(() => {
    return props.integration.company_integration_fields;
  });

  const values = ref({});
  const errors = ref({});
  const isExpanded = ref(false);
  const isLoading = ref(false);
  const isLoadingState = ref(false);

  function handleConfigureButtonClick() {
    isExpanded.value = true;
  }

  function handleCancelButtonClick() {
    isExpanded.value = false;
  }

  async function handleSaveButtonClick() {
    const requiredFieldKeys = fields.value
      .filter((field) => field.required)
      .map((field) => field.id);
    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      const companyIntegrationFields = fields.value.map((field) => ({
        ...field,
        value: values.value[field.id]
      }));

      const response = await axios.put(
        route('redesign.upsert.company.integration'),
        {
          id: id.value,
          company_integration_fields: companyIntegrationFields
        }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      isExpanded.value = false;

      emits('update:integration', {
        ...props.integration,
        company_integration_fields: companyIntegrationFields,
        state: 'CONFIGURED',
        is_active: true
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }

  async function handleStateToggle(value) {
    isLoadingState.value = true;

    try {
      const response = await axios.put(
        route('redesign.toggle.company.integration'),
        { id: id.value }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('update:integration', { ...props.integration, is_active: value });
    } catch (err) {
      toast.error(err.response.data.message || __('An unexpected error has occured. Please try again later.'));
    }

    isLoadingState.value = false;
  }

  onMounted(() => {
    fields.value.forEach((field) => {
      values.value[field.id] = field.value || '';
      errors.value[field.id] = [];
    });
  });
</script>
