<template>
  <div
    class="shadow-card relative overflow-hidden rounded-2xl bg-white p-6 md:p-8"
  >
    <div
      v-if="progress"
      class="absolute right-0 top-0 rounded-l-full bg-secondary px-3 py-1"
    >
      <span class="block text-xs leading-none text-white">{{ progress }}</span>
    </div>

    <EmptySection v-if="!data.length" :title="__('Add your values')">
      <template #icon>
        <Icon class="text-xl text-zinc-200" name="favorite" is-filled />
      </template>
    </EmptySection>

    <div v-else class="flex flex-wrap gap-x-3 gap-y-2">
      <span
        v-for="value in data"
        :key="value.id"
        class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
      >
        {{ value.name }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { EmptySection } from '@/pages/components/empty-section';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    }
  });

  const progress = computed(() => {
    return props.fields.total
      ? `${props.fields.completed}/${props.fields.total}`
      : '';
  });
</script>
