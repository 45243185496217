<template>
  <div>
    <div class="grid grid-cols-2">
      <div>
        <h1 class="text-5xl font-medium leading-none text-zinc-800">
          {{ name }}
        </h1>
      </div>
      <div class="text-right">
        <a :href="route('redesign.company.jobs.create')" target="_BLANK">
          <Button
            content="Create a new job"
            variation="outline"
            icon="add_box"
          />
        </a>
      </div>
    </div>

    <hr class="my-8" />

    <CompanyJobsFilters
      :filters="filters"
      :selected_filters="selectedFilters"
      :options="options"
      :jobs_field="jobs_field"
      :pagination="pagination"
      @handleSubmit="handleSubmit"
      :isVisibile="isVisibile"
      :isLoading="isLoading"
    />

    <div class="my-8 grid grid-cols-2">
      <!-- <h2 class="text-3xl text-[#212429] font-medium">
        {{ __("Job search results") }}
      </h2> -->

      <!-- <div class="text-right text-xs">{{ __("Sort") }}</div> -->
    </div>
    <CompanyJobsSearchResults
      :jobs="jobs_field"
      :has-integrations="hasIntegrations"
      @openModal="handleModal"
      @openJobBoardsModal="handleOpenIntegrationsModalButtonClick"
    />

    <Pagination
      v-if="pagination.last > 1"
      :currentPage="Number(filters.page)"
      :lastPage="pagination.last"
      @update:currentPage="handlePageChange"
    />

    <JobModal
      :open="modalOpen"
      @close="modalOpen = false"
      :job="modalOpenJob"
      :options="options"
      :mark_viewed="false"
      :user_profile="user_profile"
    />

    <IntegrationsModal
      v-if="isIntegrationsModalOpen"
      :job="selectedJob"
      @close="handleCloseIntegrationsModalButtonClick"
      @post="handlePostIntegrationsModalButtonClick"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button, Pagination } from '../../../components/global';
  import { JobModal } from '../../candidate/jobs/partials';
  import { IntegrationsModal } from './components/integrations-mobal';
  import { CompanyJobsSearchResults, CompanyJobsFilters } from './partials';

  const props = defineProps({
    jobs: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    selectedFilters: {
      type: Object,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    hasIntegrations: {
      type: Boolean,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    user_profile: {
      type: String,
      required: false
    }
  });

  const isIntegrationsModalOpen = ref(false);
  const selectedJob = ref(null);

  function handleOpenIntegrationsModalButtonClick(job) {
    selectedJob.value = job;
    isIntegrationsModalOpen.value = true;
  }

  function handleCloseIntegrationsModalButtonClick() {
    isIntegrationsModalOpen.value = false;
  }

  function handlePostIntegrationsModalButtonClick(item) {
    jobs_field.value = jobs_field.value.map((job) => {
      if (job.id === item.id) {
        return item;
      }

      return job;
    });
  }

  const modalOpen = ref(false);
  const modalOpenJob = ref(null);

  const handleModal = (job) => {
    modalOpen.value = true;
    modalOpenJob.value = job;
  };

  // should come from the server
  const filters = ref(props.selectedFilters);
  const jobs_field = ref(props.jobs);
  const pagination = ref(props.pagination);

  function handlePageChange(newPage) {
    // filters.value.page = newPage;

    handleSubmit(newPage);
  }

  const isLoading = ref(false);
  const isVisibile = ref(false);
  const handleSubmit = (page) => {
    // get search params from the URL
    const searchParams = new URLSearchParams(window.location.search);
    isLoading.value = true;

    if (page) {
      filters.value.page = page;
      searchParams.set('page', page);
    }

    // Close filters
    isVisibile.value = false;

    axios
      .get(
        route('redesign.company.jobs.load', {
          status: props.status
        }),
        { params: searchParams }
      )
      .then((response) => {
        console.log(response.data);
        jobs_field.value = response.data.data.jobs;
        pagination.value = response.data.data.pagination;
        isLoading.value = false;
      });
  };
</script>
