<template>
  <div
    class="flex items-center justify-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4 shadow-md rounded-app"
  >
    <div>
      <div>
        <nav aria-label="Pagination">
          <ul class="isolate inline-flex -space-x-px rounded-app shadow-sm">
            <button
              @click="goToPage(currentPage - 1)"
              class="relative inline-flex items-center rounded-l-app px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
              :disabled="currentPage <= 1"
            >
              <span class="sr-only">{{ __("Previous") }}</span>
              <Icon name="arrow_back_ios" class="!text-sm" />
            </button>
            <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" -->
            <li v-for="page in pages" :key="page" class="cursor-pointer">
              <span
                v-if="page === '...'"
                class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >...</span
              >
              <span
                v-else-if="page === currentPage"
                class="relative z-10 inline-flex items-center bg-violet-50 px-4 py-2 text-sm font-semibold text-black focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                aria-current="page"
                @click="goToPage(page)"
                >{{ page }}</span
              >

              <span
                v-else
                class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                @click="goToPage(page)"
                >{{ page }}</span
              >
            </li>

            <button
              @click="goToPage(currentPage + 1)"
              class="relative inline-flex items-center rounded-r-app px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
              :disabled="currentPage >= totalPages"
            >
              <span class="sr-only">{{ __("Next") }}</span>
              <Icon name="arrow_forward_ios" class="!text-sm" />
            </button>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { Icon } from ".";
import { __ } from "../../helpers";

const props = defineProps({
  currentPage: Number,
  lastPage: Number,
});

const emit = defineEmits(["update:currentPage"]);

// Reactive state
const currentPage = ref(props.currentPage || 1);
const totalPages = ref(props.lastPage);

// Constants for pagination display
const numPagesToShowAtEdges = 1;
const numPagesToShowAroundCurrent = 1;

// Computed properties
const pages = computed(() => {
  let pages = [];

  // Always include the first few pages
  for (let i = 1; i <= numPagesToShowAtEdges && i <= totalPages.value; i++) {
    pages.push(i);
  }

  // Include the current page and a few pages around it
  let currentPageMin = Math.max(
    currentPage.value - numPagesToShowAroundCurrent,
    numPagesToShowAtEdges + 1
  );
  let currentPageMax = Math.min(
    currentPage.value + numPagesToShowAroundCurrent,
    totalPages.value - numPagesToShowAtEdges
  );

  if (currentPageMin > numPagesToShowAtEdges + 1) {
    pages.push("...");
  }

  for (let i = currentPageMin; i <= currentPageMax; i++) {
    pages.push(i);
  }

  if (currentPageMax < totalPages.value - numPagesToShowAtEdges) {
    pages.push("...");
  }

  // Always include the last few pages
  for (
    let i = totalPages.value - numPagesToShowAtEdges + 1;
    i <= totalPages.value;
    i++
  ) {
    pages.push(i);
  }

  return pages;
});

// Methods
function goToPage(page) {
  if (page < 1 || page > totalPages.value || page === "...") {
    return;
  }
  currentPage.value = page;
  emit("update:currentPage", page);
}

// Watchers
watch(currentPage, (newValue) => {
  // Handle current page change, maybe fetch new data based on the page.
});
</script>
