<template>
  <label>
    <input
      type="checkbox"
      :required="props.required"
      :id="setid"
      class="sr-only"
      @input="$emit('update:modelValue', $event.target.checked)"
      :checked="value ? true : false"
    />

    <div
      :for="setid"
      v-if="type == 'default'"
      :class="[
        value ? 'bg-secondary' : 'bg-gray-200',
        'relative inline-flex h-6 w-14 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2'
      ]"
    >
      <span
        aria-hidden="true"
        :class="[
          value ? 'translate-x-8' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        ]"
      />
      <span
        class="absolute"
        :class="[
          value ? 'translate-x-1 text-white' : 'translate-x-6 text-primary',
          'pointer-events-none flex h-5 w-5 transform items-center font-primary text-xs font-bold shadow ring-0 transition duration-200 ease-in-out'
        ]"
        aria-hidden="true"
        ><span v-if="value">{{ true_value }}</span>
        <span v-else>{{ false_value }}</span></span
      >
      <span
        class="sr-only absolute"
        :class="[
          value ? 'translate-x-1 text-white' : 'translate-x-6 text-primary',
          'pointer-events-none flex h-5 w-5 transform items-center font-primary text-xs font-bold shadow ring-0 transition duration-200 ease-in-out'
        ]"
        ><span v-if="value">{{ __(true_value_sr) }}</span>
        <span v-else>{{ __(false_value_sr) }}</span></span
      >
    </div>

    <div
      :for="setid"
      v-if="type == 'validator'"
      :class="[
        value ? 'bg-success' : 'bg-gray-200',
        'relative inline-flex h-6 w-14 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2'
      ]"
    >
      <span
        aria-hidden="true"
        :class="[
          value ? 'translate-x-8' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        ]"
      />
      <!-- <span
        class="absolute"
        :class="[
          value ? 'translate-x-1 text-white' : 'translate-x-6 text-primary',
          'pointer-events-none h-5 w-5 flex items-center font-primary font-bold shadow text-xs transform ring-0 transition ease-in-out duration-200',
        ]"
        ><span v-if="value">ON</span> <span v-else>OFF</span></span
      > -->
    </div>
  </label>
</template>

<script setup>
  import { ref } from 'vue';

  const modelValue = ref([]);

  const props = defineProps({
    value: {
      type: Boolean,
      default: false
    },
    setid: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['default', 'validator'].includes(value);
      }
    },
    true_value: {
      type: String,
      default: 'ON'
    },
    false_value: {
      type: String,
      default: 'OFF'
    },
    true_value_sr: {
      type: String,
      default: 'ON'
    },
    false_value_sr: {
      type: String,
      default: 'OFF'
    }
  });
</script>
