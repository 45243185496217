import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
// console.log(pdfMake);
// console.log(pdfFonts);
// pdfMake.vfs = pdfFonts.default.pdfMake.vfs;
pdfMake.fonts = {
  Inter: {
    normal: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
    bold: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf'
  }
};


function formatDateToString(date) {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${year}`;
}

export async function computeResume(data, isAnonymizedProfile = false) {
  const profilePhoto = await convertImageToBase64(
    data.about_me.user.profile_photo
  );

  const colors = {
    // TODO: Replace primary for each client,
    primary: '#D71F70',
    zinc_50: '#FAFAFA',
    zinc_500: '#71717A',
    zinc_600: '#52525B',
    zinc_700: '#3F3F46',
    zinc_800: '#27272A',
    zinc_950: '#09090B'
  };

  const definition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [40, 40, 40, 40],
    defaultStyle: { font: 'Inter' },
    background: function (currentPage, pageSize) {
      return {
        canvas: [
          {
            type: 'rect',
            x: pageSize.width - 232,
            y: currentPage === 1 ? 103 : 0,
            w: 232,
            h: currentPage === 1 ? pageSize.height - 103 : pageSize.height,
            color: colors.zinc_50
          }
        ]
      };
    },
    content: [
      {
        image: profilePhoto,
        absolutePosition: { x: 40, y: 40 },
        fit: [50, 50]
      },
      {
        style: 'h1',
        text: isAnonymizedProfile
          ? 'ANONYMIZED USER'
          : `${data.about_me.user.first_name} ${data.about_me.user.last_name}`?.toUpperCase()
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 24,
            y1: 0,
            x2: 491,
            y2: 0,
            lineWidth: 0.75
          }
        ]
      },
      {
        style: 'container',
        columns: [
          {
            width: '*',
            stack: [
              {
                style: 'section',
                stack: [
                  {
                    style: 'h2',
                    text: 'ABOUT ME'
                  },
                  {
                    style: 'section_content',
                    stack: [
                      {
                        style: 'p',
                        text: data.about_me.user.personal_quote
                          ? data.about_me.user.personal_quote
                          : '-'
                      }
                    ]
                  }
                ],
                margin: [0, 0, 0, 16]
              },

              data.experiences.user.experiences.length
                ? [
                    {
                      canvas: [
                        {
                          type: 'line',
                          x1: 0,
                          y1: 0,
                          x2: 303,
                          y2: 0,
                          color: colors.zinc_500
                        }
                      ]
                    },
                    {
                      style: 'section',
                      stack: [
                        {
                          style: 'h2',
                          text: 'WORK EXPERIENCE'
                        },
                        {
                          style: 'section_content',
                          stack: [
                            data.experiences.user.experiences.map(
                              (experience, index) => ({
                                stack: [
                                  {
                                    style: 'experience_position',
                                    text: experience.position?.toUpperCase()
                                  },
                                  {
                                    style: 'experience_company',
                                    text: `${experience.company} | ${formatDateToString(new Date(experience.start_date))} - ${experience.end_date ? formatDateToString(new Date(experience.end_date)) : 'Present'}`
                                  },
                                  {
                                    style: 'experience_description',
                                    text: experience.description
                                  }
                                ],
                                margin: index ? [0, 14, 0, 0] : [0, 0, 0, 0]
                              })
                            )
                          ]
                        }
                      ]
                    }
                  ]
                : null,

              data.educations.user.length
                ? [
                    {
                      canvas: [
                        {
                          type: 'line',
                          x1: 0,
                          y1: 0,
                          x2: 303,
                          y2: 0,
                          color: colors.zinc_500
                        }
                      ]
                    },
                    {
                      style: 'section',
                      stack: [
                        {
                          style: 'h2',
                          text: 'EDUCATION'
                        },
                        {
                          style: 'section_content',
                          stack: [
                            data.educations.user.map((education, index) => ({
                              stack: [
                                {
                                  style: 'education_field',
                                  text: education.field?.toUpperCase()
                                },
                                {
                                  style: 'education_date',
                                  text: `${formatDateToString(new Date(education.start_date))} - ${education.end_date ? formatDateToString(new Date(education.end_date)) : 'Present'}`
                                },
                                {
                                  style: 'education_name',
                                  text: education.name
                                }
                              ],
                              margin: index ? [0, 14, 0, 0] : [0, 0, 0, 0]
                            }))
                          ]
                        }
                      ]
                    }
                  ]
                : null,

              data.achievements.user.length
                ? [
                    {
                      canvas: [
                        {
                          type: 'line',
                          x1: 0,
                          y1: 0,
                          x2: 303,
                          y2: 0,
                          color: colors.zinc_500
                        }
                      ]
                    },
                    {
                      style: 'section',
                      stack: [
                        {
                          style: 'h2',
                          text: 'ACHIEVEMENTS'
                        },
                        {
                          style: 'section_content',
                          stack: [
                            Object.values(
                              data.achievements.user.reduce(
                                (acc, achievement) => (
                                  (acc[achievement.type.name] = acc[
                                    achievement.type.name
                                  ] || {
                                    name: achievement.type.name,
                                    achievements: []
                                  }).achievements.push(achievement),
                                  acc
                                ),
                                {}
                              )
                            ).map((type, index) => ({
                              stack: [
                                {
                                  style: 'achievement_type_name',
                                  text: type.name?.toUpperCase()
                                },
                                {
                                  stack: type.achievements.map(
                                    (achievement) => ({
                                      style: 'achievement_name',
                                      text: achievement.name
                                    })
                                  )
                                }
                              ],
                              margin: index ? [0, 12, 0, 0] : [0, 0, 0, 0]
                            }))
                          ]
                        }
                      ]
                    }
                  ]
                : null
            ]
          },
          {
            width: 192,
            stack: [
              {
                style: 'section',
                stack: [
                  {
                    style: 'h2',
                    text: 'CONTACT'
                  },
                  {
                    style: 'section_content',
                    stack: [
                      {
                        style: 'contact_item',
                        columns: [
                          {
                            svg: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="${colors.primary}"><path d="M763-145q-121-9-229.5-59.5T339-341q-86-86-136-194.5T144-765q-2-21 12.5-36.5T192-817h136q17 0 29.5 10.5T374-780l24 107q2 13-1.5 25T385-628l-97 98q20 38 46 73t58 66q30 30 64 55.5t72 45.5l99-96q8-8 20-11.5t25-1.5l107 23q17 5 27 17.5t10 29.5v136q0 21-16 35.5T763-145Z"/></svg>`,
                            width: 18
                          },
                          {
                            style: 'contact_item_text',
                            text:
                              !isAnonymizedProfile &&
                              data.about_me.user.country_prefix &&
                              data.about_me.user.phone
                                ? `${data.about_me.user.country_prefix.prefix} ${data.about_me.user.phone}`
                                : '-'
                          }
                        ],
                        columnGap: 10,
                        margin: [0, 0, 0, 0]
                      },
                      {
                        style: 'contact_item',
                        columns: [
                          {
                            svg: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="${colors.primary}"><path d="M168-192q-29 0-50.5-21.5T96-264v-432q0-29 21.5-50.5T168-768h624q30 0 51 21.5t21 50.5v432q0 29-21 50.5T792-192H168Zm312-240 312-179v-85L480-517 168-696v85l312 179Z"/></svg>`,
                            width: 18
                          },
                          {
                            style: 'contact_item_text',
                            text:
                              !isAnonymizedProfile && data.about_me.user.email
                                ? data.about_me.user.email
                                : '-'
                          }
                        ],
                        columnGap: 10
                      },
                      {
                        style: 'contact_item',
                        columns: [
                          {
                            svg: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="${colors.primary}"><path d="M480.21-480Q510-480 531-501.21t21-51Q552-582 530.79-603t-51-21Q450-624 429-602.79t-21 51Q408-522 429.21-501t51 21ZM480-96Q323.02-227.11 245.51-339.55 168-452 168-549q0-134 89-224.5T479.5-864q133.5 0 223 90.5T792-549q0 97-77 209T480-96Z"/></svg>`,
                            width: 18
                          },
                          {
                            style: 'contact_item_text',
                            text: data.about_me.user.current_location
                              ? data.about_me.user.current_location.name
                              : '-'
                          }
                        ],
                        columnGap: 10
                      }
                    ]
                  }
                ],
                margin: [0, 0, 0, 16]
              },

              data.skills.user.length
                ? [
                    {
                      canvas: [
                        {
                          type: 'line',
                          x1: 8,
                          y1: 0,
                          x2: 184,
                          y2: 0,
                          color: colors.zinc_500
                        }
                      ]
                    },
                    {
                      style: 'section',
                      stack: [
                        {
                          style: 'h2',
                          text: 'SKILLS'
                        },
                        {
                          style: 'section_content',
                          stack: [
                            Object.values(
                              data.skills.user
                                .map((item) => item.skill)
                                .reduce(
                                  (acc, skill) => (
                                    (acc[skill.category.name] = acc[
                                      skill.category.name
                                    ] || {
                                      name: skill.category.name,
                                      skills: []
                                    }).skills.push(skill),
                                    acc
                                  ),
                                  {}
                                )
                            ).map((category, index) => ({
                              stack: [
                                {
                                  style: 'skill_category_name',
                                  text: category.name?.toUpperCase()
                                },
                                {
                                  stack: category.skills.map((skill) => ({
                                    style: 'skill_name',
                                    text: skill.name
                                  }))
                                }
                              ],
                              margin: index ? [0, 12, 0, 0] : [0, 0, 0, 0]
                            }))
                          ]
                        }
                      ]
                    }
                  ]
                : null,

              data.languages.user.length
                ? [
                    {
                      canvas: [
                        {
                          type: 'line',
                          x1: 8,
                          y1: 0,
                          x2: 184,
                          y2: 0,
                          color: colors.zinc_500
                        }
                      ]
                    },
                    {
                      style: 'section',
                      stack: [
                        {
                          style: 'h2',
                          text: 'LANGUAGES'
                        },
                        {
                          style: 'section_content',
                          stack: [
                            data.languages.user.map((item, index) => ({
                              columns: [
                                {
                                  style: 'language_name',
                                  text: item.language.name
                                },
                                {
                                  style: 'language_proficiency',
                                  text: item.proficiency.name
                                }
                              ],
                              margin: index ? [0, 8, 0, 0] : [0, 0, 0, 0]
                            }))
                          ]
                        }
                      ]
                    }
                  ]
                : null
            ]
          }
        ],
        columnGap: 40
      }
    ],
    styles: {
      h1: {
        fontSize: 35,
        bold: true,
        alignment: 'center',
        characterSpacing: 2.5,
        color: colors.zinc_700,
        margin: [0, 0, 0, 20]
      },
      h2: {
        fontSize: 15,
        bold: true,
        characterSpacing: 1,
        color: colors.zinc_950
      },
      p: {
        fontSize: 11,
        lineHeight: 1.4,
        color: colors.zinc_950
      },
      container: {
        margin: [0, 20, 0, 0]
      },
      section: {
        margin: [0, 16, 0, 16]
      },
      section_content: {
        margin: [0, 12, 0, 0]
      },
      experience_position: {
        fontSize: 12,
        bold: true,
        color: colors.zinc_950
      },
      experience_company: {
        fontSize: 12,
        color: colors.zinc_950,
        margin: [0, 4, 0, 0]
      },
      experience_description: {
        fontSize: 10,
        lineHeight: 1.2,
        color: colors.zinc_600,
        margin: [0, 4, 0, 0]
      },
      education_field: {
        fontSize: 10,
        bold: true,
        characterSpacing: 1,
        color: colors.zinc_600
      },
      education_date: {
        fontSize: 9,
        bold: true,
        color: colors.zinc_600,
        margin: [0, 4, 0, 0]
      },
      education_name: {
        fontSize: 10,
        color: colors.zinc_600,
        margin: [0, 4, 0, 0]
      },
      achievement_type_name: {
        fontSize: 10,
        bold: true,
        color: colors.zinc_600
      },
      achievement_name: {
        fontSize: 10,
        color: colors.zinc_600,
        margin: [0, 4, 0, 0]
      },
      contact_item: {
        margin: [0, 12, 0, 0]
      },
      contact_item_text: {
        fontSize: 10,
        color: colors.zinc_500,
        margin: [0, 2, 0, 0]
      },
      skill_category_name: {
        fontSize: 10,
        bold: true,
        color: colors.zinc_600
      },
      skill_name: {
        fontSize: 10,
        color: colors.zinc_600,
        margin: [0, 4, 0, 0]
      },
      language_name: {
        fontSize: 11,
        color: colors.zinc_600
      },
      language_proficiency: {
        fontSize: 11,
        color: colors.zinc_600
      }
    }
  };

  pdfMake.createPdf(definition).open();
}

async function convertImageToBase64(url) {
  const response = await fetch(url);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
