<template>
  <div>
    <label
      v-if="label"
      :class="`${errors.length ? 'text-error' : 'text-zinc-800'} mb-1.5 block leading-normal`"
      :for="id"
    >
      {{ `${label}${required ? '*' : ''}` }}
    </label>

    <div class="group relative">
      <input
        :id="id"
        :class="`${errors.length ? 'ring-error' : 'ring-gray-200'} w-full rounded-md border-0 bg-white px-3.5 py-2.5 pr-[2.5rem] text-zinc-800 shadow-[0px_1px_2px_0px_#2A33420D] ring-1 ring-inset placeholder:text-zinc-600 focus:ring-2 focus:ring-inset focus:ring-secondary disabled:cursor-not-allowed disabled:bg-zinc-100 disabled:text-zinc-500 disabled:placeholder:text-zinc-300`"
        :type="isVisible ? 'text' : 'password'"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        @input="handleInput"
      />

      <Button
        class="absolute right-1 top-1/2 !hidden -translate-y-1/2 group-focus-within:!flex"
        variant="icon"
        @click="handleVisibilityButtonClick"
      >
        <Icon
          class="text-xl text-primary"
          :name="isVisible ? 'visibility_off' : 'visibility'"
          is-filled
        />
      </Button>
    </div>

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  const isVisible = ref(false);

  function handleInput(event) {
    emits('update:value', event.target.value);
    emits('update:errors', []);
  }

  function handleVisibilityButtonClick() {
    isVisible.value = !isVisible.value;
  }
</script>
