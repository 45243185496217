<template>
  <a
    :href="url"
    class="shadow-card flex flex-wrap gap-x-8 gap-y-4 rounded-lg p-6 sm:flex-nowrap"
  >
    <img
      :src="preview"
      :alt="__('Preview photo')"
      class="w-full rounded sm:h-56 sm:w-auto"
    />

    <div class="space-y-4">
      <div class="space-y-2">
        <span class="block text-2xl font-medium text-zinc-800">{{ name }}</span>

        <div class="flex items-center gap-1.5">
          <Icon name="group" is-filled class="text-lg text-zinc-600" />
          <span class="text-sm text-zinc-600">
            {{ `${totalMembers} ${__('members')}` }}
          </span>
        </div>
      </div>

      <div v-if="canDeactivate || canEdit" class="flex items-center gap-4">
        <InputToggle
          v-if="canDeactivate"
          :id="`status-${group.id}`"
          :value="status"
          @change="handleStatusChange"
        >
          <template #label-start>
            <span class="block text-sm font-medium text-zinc-600">
              {{ __(status ? 'Active' : 'Inactive') }}
            </span>
          </template>
        </InputToggle>

        <Button
          v-if="canEdit"
          :href="editGroupUrl"
          variant="ghost"
          class="flex items-center gap-1.5"
        >
          <Icon name="edit" class="text-lg text-primary" />
          <span>{{ __('Edit') }}</span>
        </Button>
      </div>
    </div>

    <GroupStatusModal
      v-if="isStatusModalOpen"
      :group="group"
      @cancel="handleStatusModalCancelButtonClick"
      @continue="handleStatusModalContinueButtonClick"
    />
  </a>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputToggle } from '@/components/form/input-toggle';
  import { GroupStatusModal } from '../../shared/group-status-modal';

  const props = defineProps({
    group: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const url = computed(() => {
    return route('redesign.company.groups.show', { id: group.value.id });
  });

  const preview = computed(() => {
    return group.value.photo.includes('/uploads/')
      ? group.value.photo
      : `/uploads/${group.value.photo}`;
  });

  const name = computed(() => {
    return group.value.name;
  });

  const canDeactivate = computed(() => {
    return group.value.canDeactivate;
  });

  const canEdit = computed(() => {
    return group.value.canEdit;
  });

  const status = computed(() => {
    return group.value.active === 1 ? true : false;
  });

  const editGroupUrl = computed(() => {
    return route('redesign.company.groups.edit', { id: group.value.id });
  });

  const group = ref(JSON.parse(JSON.stringify(props.group)));
  const isStatusModalOpen = ref(false);
  const totalMembers = ref(0);

  function handleStatusChange() {
    isStatusModalOpen.value = true;
  }

  function handleStatusModalCancelButtonClick() {
    isStatusModalOpen.value = false;
  }

  function handleStatusModalContinueButtonClick(value) {
    group.value.active = value;
    isStatusModalOpen.value = false;
  }

  onMounted(async () => {
    try {
      const response = await axios.get(
        route('redesign.company.groups.paginateMembers', {
          id: group.value.id
        }),
        { params: { offset: 0, limit: 10 } }
      );

      totalMembers.value = response.data.total;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }
  });
</script>
