<template>
  <div class="mt-8">
    <!-- Matches -->
    <div>
      <h2 class="text-2xl text-[#212429] font-medium mb-4">
        {{ __("Matches") }}
        <span class="text-white bg-primary rounded-full text-xs px-2 py-1">{{
          matches.length
        }}</span>
      </h2>

      <div>
        <div v-if="matches.length != 0" class="grid md:grid-cols-3 gap-4">
          <div
            v-for="match in matches"
            :key="match.id"
            class="rounded-app shadow-md relative flex flex-col justify-between bg-white"
          >
            <div
              class="bg-white rounded-t-app p-4 cursor-pointer relative"
              @click="openModal(match.job)"
            >
              <span
                class="absolute bottom-2 right-0 text-xs bg-secondary text-white pl-4 pr-2 py-1 rounded-l-app"
                v-if="match.new"
                >{{ __("NEW") }}</span
              >
              <div class="flex justify-between">
                <div
                  class="flex gap-2 items-center"
                  v-if="!config_general_single_company"
                >
                  <img
                    :src="match.job.company.logo"
                    alt="Company logo"
                    class="w-12 h-12 rounded-full"
                  />
                  <div>
                    <h3 class="text-sm text-[#212429]">
                      {{ match.job.company.name }}
                    </h3>
                    <p class="text-xs text-[#58585E]">
                      {{ match.job.company.domain }}
                    </p>
                  </div>
                </div>
                <div v-else>
                  <SingleCompanyIcon />
                </div>
              </div>

              <div>
                <h3
                  class="text-lg my-2"
                  :class="match.job.active ? 'text-[#52525B]' : 'text-zinc-300'"
                >
                  {{ match.job.title }}
                </h3>
              </div>

              <!-- <div
                class="!text-xs flex gap-2 items-center"
                :class="match.job.active ? 'text-[#52525B]' : 'text-zinc-300'"
                v-if="match.status"
              >
                <Icon name="description" class="!text-sm" />
                <span class=""
                  ><b class="font-bold">Status:</b> {{ match.status }}</span
                >
              </div> -->
            </div>
            <div class="p-4 bg-zinc-50 rounded-b-app" v-if="match.job.active">
              <div class="text-right">
                <a :href="match.message_url">
                  <Button content="Message" variation="outline" icon="send" />
                </a>
              </div>
            </div>
            <div class="p-4 bg-gray-200 rounded-b-app" v-else>
              <div class="text-xs text-[#52525B]">
                {{ __("This job is inactive at the moment") }}
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <p class="text-[#212429] text-base">
            {{ __("You have no matches yet.") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  Button,
  Icon,
  SingleCompanyIcon,
} from "../../../../../components/global";
import { __ } from "../../../../../helpers";

const props = defineProps({
  matches: {
    type: Array,
    required: true,
  },
  config_general_single_company: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["openModal"]);

const openModal = (job) => {
  emit("openModal", job);
};
</script>
