<template>
  <div class="space-y-8">
    <Button
      v-if="canInvite && hasParentGroup"
      class="ml-auto"
      @click="handleInviteAllGroupMembersButtonClick"
    >
      {{ __('Invite all group members') }}
    </Button>

    <InviteAllGroupMembersModal
      v-if="isInviteAllGroupMembersModalOpen"
      :event="event"
      @cancel="handleCancelInviteAllGroupMemmbersModalButtonClick"
      @continue="handleContinueInviteAllGroupMemmbersModalButtonClick"
    />

    <div class="space-y-4">
      <div class="shadow-element overflow-auto rounded-md">
        <table class="w-max min-w-full">
          <thead>
            <tr>
              <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                {{ __('Name') }}
              </th>
              <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                {{ __('Invitation sent on') }}
              </th>
              <th v-if="canInvite" class="px-8 py-4">
                <div class="flex items-center gap-1">
                  <span class="font-medium leading-normal text-zinc-600">
                    {{ __('Action') }}
                  </span>

                  <Popover position="right">
                    <template #trigger>
                      <Button variant="icon" tabindex="-1">
                        <Icon class="text-xl" name="info" />
                      </Button>
                    </template>
                    <template #content>
                      <div class="p-4">
                        <span class="block text-sm text-zinc-800">
                          {{
                            __(
                              'Invites can be sent to each user once every 7 days.'
                            )
                          }}
                        </span>
                      </div>
                    </template>
                  </Popover>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading" class="border-t">
              <td :colSpan="2" class="px-8 py-4">
                <span class="text-sm text-zinc-600">
                  {{ __('Loading...') }}
                </span>
              </td>
            </tr>

            <tr v-else-if="!attendees.length" class="border-t">
              <td :colSpan="2" class="px-8 py-4">
                <span class="text-sm text-zinc-600">
                  {{ __('No pending invitations') }}
                </span>
              </td>
            </tr>

            <template v-else>
              <Attendee
                v-for="(attendee, index) in attendees"
                :key="attendee"
                v-model:attendee="attendees[index]"
                :event="event"
              />
            </template>
          </tbody>
        </table>
      </div>

      <div
        v-if="!isPreviousButtonDisabled || !isNextButtonDisabled"
        class="flex justify-center gap-2"
      >
        <button
          class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
          :disabled="isPreviousButtonDisabled"
          :aria-label="__('Previous')"
          @click="handlePreviousButtonClick"
        >
          <Icon class="text-xl text-primary" name="arrow_back" />
        </button>

        <button
          class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
          :disabled="isNextButtonDisabled"
          :aria-label="__('Next')"
          @click="handleNextButtonClick"
        >
          <Icon class="text-xl text-primary" name="arrow_forward" />
        </button>
      </div>
    </div>

    <InviteForm
      v-if="canInvite"
      :event="event"
      @submit="handleInviteFormSubmit"
    />
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Popover } from '@/components/block/popover';
  import { Attendee } from './components/attendee';
  import { InviteForm } from './components/invite-form';
  import { InviteAllGroupMembersModal } from './components/invite-all-group-members-modal';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const canEdit = computed(() => {
    return props.event.conditions.can_edit;
  });

  const attendeeLimitReached = computed(() => {
    const attendees = props.event.details.attendees;
    const attendeeLimit = props.event.details.attendee_limit;

    if (!attendeeLimit) {
      return false;
    }

    return attendees === attendeeLimit;
  });

  const registrationDeadlinePassed = computed(() => {
    const rsvp = props.event.details.rsvp;

    if (!rsvp) {
      return false;
    }

    return new Date() > new Date(rsvp);
  });

  const canInvite = computed(() => {
    return (
      canEdit.value &&
      !attendeeLimitReached.value &&
      !registrationDeadlinePassed.value
    );
  });

  const hasParentGroup = computed(() => {
    return !!props.event.group?.id;
  });

  const isPreviousButtonDisabled = computed(() => {
    return page.value <= 1 || isLoading.value;
  });

  const isNextButtonDisabled = computed(() => {
    return page.value >= totalPages.value || isLoading.value;
  });

  const isInviteAllGroupMembersModalOpen = ref(false);
  const attendees = ref([]);
  const isLoading = ref(false);
  const page = ref(1);
  const totalPages = ref(0);

  function handleInviteAllGroupMembersButtonClick() {
    isInviteAllGroupMembersModalOpen.value = true;
  }

  function handleCancelInviteAllGroupMemmbersModalButtonClick() {
    isInviteAllGroupMembersModalOpen.value = false;
  }

  async function handleContinueInviteAllGroupMemmbersModalButtonClick() {
    isInviteAllGroupMembersModalOpen.value = false;

    if (page.value !== 1) {
      page.value = 1;
    }

    await fetchAttendees(page.value);
  }

  async function handleInviteFormSubmit() {
    if (page.value !== 1) {
      page.value = 1;
    }

    await fetchAttendees(page.value);
  }

  async function handlePreviousButtonClick() {
    page.value--;

    await fetchAttendees(page.value);
  }

  async function handleNextButtonClick() {
    page.value++;

    await fetchAttendees(page.value);
  }

  async function fetchAttendees(page) {
    isLoading.value = true;

    try {
      const response = await axios.get(
        route('redesign.company.events.invitedAttendees', {
          id: props.event.id
        }),
        { params: { page } }
      );

      attendees.value = response.data.members;
      totalPages.value = response.data.pagination.total;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }

  onMounted(async () => {
    await fetchAttendees(page.value);
  });
</script>
