<template>
  <div class="space-y-12">
    <h1 class="text-5xl font-medium text-zinc-800">
      {{ __(isEditMode ? 'Edit group' : 'Create group') }}
    </h1>

    <form class="space-y-8" @submit.prevent="handleSubmit">
      <Details
        v-model:values="values.details"
        v-model:files="files.details"
        v-model:errors="errors.details"
        :options="options.country"
      />

      <TeamMembers
        v-if="canAssignManagers"
        v-model:values="values.team_members"
        v-model:errors="errors.team_members"
        :options="options.team_members"
      />

      <Media
        v-model:values="values.media"
        v-model:files="files.media"
        v-model:errors="errors.media"
      />

      <div class="flex flex-col gap-2 md:flex-row-reverse md:justify-center">
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { isValidHttpUrl } from '@/scripts/validate.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Details } from './components/group-form/details';
  import { Media } from './components/group-form/media';
  import { TeamMembers } from './components/group-form/team-members';

  const props = defineProps({
    group: {
      type: [Object, null],
      default: null
    },
    options: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    canAssignManagers: {
      type: Boolean,
      default: false
    }
  });

  const toast = useToast();

  const isEditMode = computed(() => {
    return !!group.value;
  });

  const group = ref(JSON.parse(JSON.stringify(props.group)));
  const values = ref({
    details: {
      cover: group.value?.details.cover || null,
      photo: group.value?.details.photo || null,
      name: group.value?.details.name || '',
      location: group.value?.details.location || null,
      description: group.value?.details.description || '',
      facebook: group.value?.details.facebook || '',
      instagram: group.value?.details.instagram || '',
      linkedin: group.value?.details.linkedin || '',
      twitter: group.value?.details.twitter || '',
      youtube: group.value?.details.youtube || '',
      tiktok: group.value?.details.tiktok || ''
    },
    team_members: group.value?.team_members || [],
    media: group.value?.media || []
  });
  const files = ref({
    details: {
      cover: null,
      photo: null
    },
    media: []
  });
  const errors = ref({
    details: {
      cover: [],
      photo: [],
      name: [],
      location: [],
      description: [],
      facebook: [],
      instagram: [],
      linkedin: [],
      twitter: [],
      youtube: [],
      tiktok: []
    },
    team_members: [],
    media: []
  });
  const isLoading = ref(false);

  function isValid(value) {
    if (!value) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'object') {
      for (const prop in value) {
        if (value[prop] === null) {
          return false;
        }
      }
      return true;
    }

    return true;
  }

  async function uploadPhoto(file) {
    const formData = new FormData();

    formData.append('photo', file);

    const response = await axios.post(
      route('redesign.media.uploadPhoto'),
      formData
    );

    return response.data.photo_url;
  }

  function handleCancelButtonClick() {
    history.back();
  }

  async function handleSubmit() {
    const requiredFieldKeys = [
      'cover',
      'photo',
      'name',
      'location',
      'description'
    ];
    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!isValid(values.value.details[key])) {
        errors.value.details[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (
      values.value.details.location &&
      values.value.details.location.country &&
      !values.value.details.location.city
    ) {
      errors.value.details.location = [__('This field is required')];

      const element = document.getElementById('location');

      if (element && !firstElement) {
        firstElement = element;
      }
    }

    const social_links = [
      'facebook',
      'instagram',
      'linkedin',
      'twitter',
      'youtube',
      'tiktok'
    ];

    social_links.forEach((key) => {
      if (
        values.value.details[key] &&
        !isValidHttpUrl(values.value.details[key])
      ) {
        errors.value.details[key] = [
          __('Invalid URL. Please ensure the URL is correctly formatted.')
        ];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (Object.values(errors.value.details).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      if (files.value.details.cover) {
        values.value.details.cover = await uploadPhoto(
          files.value.details.cover
        );
      }

      if (files.value.details.photo) {
        values.value.details.photo = await uploadPhoto(
          files.value.details.photo
        );
      }

      if (files.value.media.length) {
        const imagePromiseList = files.value.media.map(async (file) => {
          return await uploadPhoto(file);
        });

        const newImages = (await Promise.all(imagePromiseList)).map(
          (photo_url) => ({ photo_url })
        );

        const uploadedImages = values.value.media.filter(
          (media) => !media.photo_url.startsWith('data:')
        );

        values.value.media = [...uploadedImages, ...newImages];
      }

      let response;

      if (isEditMode.value) {
        response = await axios.post(
          route('redesign.company.groups.update', { id: group.value.id }),
          values.value
        );
      } else {
        response = await axios.post(
          route('redesign.company.groups.store'),
          values.value
        );
      }

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      window.location = route('redesign.company.groups.show', {
        id: isEditMode.value ? group.value.id : response.data.id
      });
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
