<template>
  <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
    <form @submit.prevent="handleSubmit">
      <InputSkills
        id="skills"
        v-model:value="values.skills"
        v-model:errors="errors.skills"
        :label="__('Select your skills from the list below.')"
        :placeholder="__('Add skills')"
        is-new-options-allowed
        @update:value="createNewSkill"
      >
        <template #icon>
          <Icon name="stylus_note" />
        </template>
      </InputSkills>

      <span
        v-if="errorMessage"
        class="mt-8 block text-center text-sm text-error"
      >
        {{ errorMessage }}
      </span>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputSkills } from '@/components/form/input-skills';
  import axios from "axios";
  import {useLoading} from "vue-loading-overlay";

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  });

  const emits = defineEmits(['save', 'cancel']);

  const toast = useToast();

  const values = ref({ skills: JSON.parse(JSON.stringify(props.data)) });
  const errors = ref({ skills: [] });
  const isLoading = ref(false);

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    let firstElement;

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      const customSkillPromiseList = values.value.skills
        .filter((skill) => !skill.id)
        .map((skill) => {
          return axios.post(route('redesign.cv.skill.create'), {
            name: skill.name
          });
        });

      const customSkillList = (await Promise.all(customSkillPromiseList)).map(
        (item) => item.data
      );

      if (customSkillList) {
        values.value.skills = values.value.skills.map((skill) => {
          const customSkill = customSkillList.find(
            (customSkill) => customSkill.name === skill.name
          );

          if (customSkill) {
            return customSkill;
          }

          return skill;
        });
      }

      const response = await axios.put(
        route('redesign.cv.update.skills'),
        values.value.skills
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', {
        data: values.value.skills,
        fields: response.data.fields,
        sectionPercentage: response.data.section_percentage,
        resumePercentage: response.data.percentage
      });
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }

  const $loading = useLoading();
  const createNewSkill = (values, resolve) => {
    if (!values || !values.length || values[values.length - 1].id !== 'custom-option') {
      return;
    }

    const loader = $loading.show();

    axios.post(route('redesign.company.skills.create'), {
          name: values[values.length - 1].name
        }).then((response) => {
          resolve(response.data);
          toast.success(response.data.message);
        }).catch((err) => {
            if (err?.response?.data?.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error(__('An unexpected error has occured. Please try again later.'));
            }
        }).finally(() => {
          loader.hide();
        });
  };
</script>
