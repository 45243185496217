<template>
  <div v-if="isLoadingFetch" class="flex items-center gap-1.5 text-zinc-500">
    <Icon class="animate-spin text-xl" name="progress_activity" />
    <span>{{ __('Loading...') }}</span>
  </div>

  <div v-else class="space-y-6 md:space-y-8">
    <div class="flex gap-2 md:gap-4">
      <Icon
        class="text-[2rem] text-primary md:text-[2.5rem]"
        name="palette"
        is-filled
      />
      <h1 class="text-2xl font-medium text-zinc-800 md:text-4xl">
        {{ __('What do you enjoy doing?') }}
      </h1>
    </div>

    <div class="space-y-1">
      <span class="block font-medium text-zinc-800 md:text-lg">
        {{
          __(
            'Tell us about the activities that make your free time fun and fulfilling.'
          )
        }}
      </span>
      <span class="block text-sm leading-4 text-zinc-800">
        {{ __('Pick the options from the list below.') }}
      </span>
    </div>

    <form class="space-y-8" @submit.prevent="handleSubmit">
      <InputCombobox
        v-if="fields.hobbies.visible"
        id="hobbies"
        v-model:value="fields.hobbies.value"
        v-model:errors="fields.hobbies.errors"
        :placeholder="__('Do you have any hobbies?')"
        :options="fields.hobbies.options"
        :required="fields.hobbies.mandatory"
        is-static
      >
        <template #icon>
          <Icon name="search" />
        </template>
      </InputCombobox>

      <div class="flex items-center justify-between">
        <Button
          class="flex items-center gap-1.5"
          variant="ghost"
          @click="handleBackButtonClick"
        >
          <Icon class="text-lg" name="arrow_back_ios_new" />
          <span>{{ __('Go Back') }}</span>
        </Button>

        <Button
          class="ml-auto flex items-center gap-1.5"
          type="submit"
          :disabled="isLoadingUpdate"
        >
          <Icon
            v-if="isLoadingUpdate"
            class="animate-spin text-lg"
            name="progress_activity"
          />

          <span>{{ __('Next') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputCombobox } from '@/components/form/input-combobox';

  const emits = defineEmits(['back', 'next']);

  const toast = useToast();

  const fields = ref({
    hobbies: {
      visible: false,
      mandatory: false,
      value: [],
      errors: [],
      options: []
    }
  });
  const isLoadingFetch = ref(false);
  const isLoadingUpdate = ref(false);

  function isValid(value) {
    if (!value) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'object') {
      for (const prop in value) {
        if (value[prop] === null) {
          return false;
        }
      }
      return true;
    }

    return true;
  }

  async function fetchData() {
    isLoadingFetch.value = true;

    try {
      const response = await axios.get(
        route('onboarding.step', { slug: 'hobbies' })
      );

      Object.entries(response.data).forEach(([key, value]) => {
        if (fields.value[key]) {
          fields.value[key] = { ...fields.value[key], ...value };
        }
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingFetch.value = false;
  }

  function handleBackButtonClick() {
    emits('back');
  }

  async function handleSubmit() {
    const requiredFieldKeys = Object.keys(fields.value).filter(
      (key) => fields.value[key].visible && fields.value[key].mandatory
    );
    let firstInvalidField;

    requiredFieldKeys.forEach((key) => {
      if (!isValid(fields.value[key].value)) {
        fields.value[key].errors = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstInvalidField) {
          firstInvalidField = element;
        }
      }
    });

    if (
      Object.values(fields.value).some(
        (field) => field.errors && field.errors.length
      )
    ) {
      if (firstInvalidField) {
        firstInvalidField.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
      return;
    }

    const payload = Object.keys(fields.value).reduce((acc, key) => {
      if (fields.value[key].visible) {
        acc[key] = fields.value[key].value;
      }
      return acc;
    }, {});

    isLoadingUpdate.value = true;

    try {
      await axios.post(route('onboarding.step', { slug: 'hobbies' }), payload);

      emits('next');
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).forEach((key) => {
          if (fields.value[key]) {
            fields.value[key].errors = error.response.data.errors[key];
          }
        });
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingUpdate.value = false;
  }

  onMounted(async () => {
    await fetchData();
  });
</script>
