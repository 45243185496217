<template>
    <div class="h-full space-y-6 md:space-y-8 overflow-hidden rounded-app bg-white p-8 relative grid grid-rows-[auto,1fr] shadow-[0px_12px_16px_-4px_rgb(85_105_135_/_20%)]">
            <div class="absolute top-3 right-2.5">
              <Button
                  class="profile-edit-btn flex items-center gap-1.5"
                  variant="ghost"
                  @click="handleEditButtonClick"
              >
                <Icon class="text-2xl" name="edit" />
              </Button>
            </div>
            <div class="grid xl:place-items-center h-full xl:h-auto justify-self-center text-center pb-2">
                <div class="xl:grid">
                    <div class="flex -space-x-1 overflow-hidden justify-center justify-self-center pb-6">
                        <img class="inline-block size-16 rounded-full ring-2 ring-white" :src="user.avatar">
                    </div>
                    <span class="text-primary text-xl xl:text-2xl font-medium pb-6 xl:pb-2"> {{ user.name }} </span>

                    <p class="my-1 px-6 xl:my-0 text-base xl:text-lg "> {{ user.tagline }} </p>

                    <div class="flex justify-center justify-self-center items-center space-x-1 py-2">
                        <span class="text-2xl font-medium"> {{ user.points }} </span>
                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.8277 10.2546C19.8277 7.85592 19.9045 5.531 19.8046 3.21529C19.7156 1.17906 18.6929 0.316046 16.6228 0.297619C14.9337 0.279191 13.2445 0.297619 11.5553 0.297619C11.3342 0.297619 11.1131 0.323724 10.918 0.336009V17.627H14.4945V10.2561L19.8277 10.2546Z" fill="white"/>
                            <path d="M20.0197 10.2546C20.0197 8.83415 20.0473 7.41524 20.0473 5.99479C20.0473 5.28995 20.0473 4.5851 20.0197 3.88025C20.0252 3.3455 19.9774 2.81153 19.8769 2.28628C19.8051 1.91733 19.6541 1.56835 19.4343 1.26346C19.2145 0.95856 18.9312 0.70501 18.6038 0.520323C17.7209 0.0504246 16.6858 0.101102 15.7153 0.0949592C14.9091 0.0949592 14.1029 0.0949592 13.2952 0.0949592C12.4875 0.0949592 11.7105 0.0842088 10.9196 0.139491C10.8691 0.140662 10.8209 0.161261 10.7852 0.197009C10.7494 0.232756 10.7288 0.280902 10.7277 0.331443V17.6271C10.7281 17.6779 10.7484 17.7265 10.7843 17.7624C10.8202 17.7983 10.8688 17.8186 10.9196 17.819H14.4961C14.5468 17.8186 14.5954 17.7983 14.6313 17.7624C14.6673 17.7265 14.6876 17.6779 14.688 17.6271V10.2561L14.4961 10.4481H19.8277C19.8786 10.4481 19.9274 10.4278 19.9634 10.3919C19.9994 10.3559 20.0197 10.307 20.0197 10.2561C20.0197 10.2052 19.9994 10.1564 19.9634 10.1204C19.9274 10.0844 19.8786 10.0642 19.8277 10.0642H14.4961C14.4453 10.0646 14.3967 10.0849 14.3608 10.1208C14.3249 10.1567 14.3045 10.2053 14.3041 10.2561V17.6271L14.4961 17.4351H10.9196L11.1116 17.6271V0.331443L10.9196 0.523395C12.2587 0.429722 13.6162 0.47579 14.9598 0.477326C15.6109 0.477326 16.2651 0.461971 16.9162 0.495755C17.3293 0.51004 17.7379 0.587193 18.1278 0.72456C18.4504 0.845726 18.7393 1.04236 18.9704 1.29803C19.2015 1.55369 19.368 1.86096 19.4561 2.19414C19.5731 2.66108 19.6309 3.14089 19.6281 3.62227C19.6511 4.30101 19.6603 4.98129 19.6634 5.66003C19.6634 7.19564 19.6358 8.72358 19.6358 10.2546C19.6358 10.3055 19.656 10.3543 19.692 10.3903C19.728 10.4263 19.7768 10.4465 19.8277 10.4465C19.8786 10.4465 19.9274 10.4263 19.9634 10.3903C19.9994 10.3543 20.0197 10.3055 20.0197 10.2546Z" fill="black"/>
                            <path d="M19.8275 10.2546H14.4958V17.6255H10.9194V0.33142C11.1144 0.319135 11.3355 0.29303 11.5567 0.29303C13.2458 0.29303 14.935 0.276138 16.6242 0.29303C18.6942 0.314529 19.7169 1.17754 19.806 3.2107C19.8997 5.53101 19.8275 7.85594 19.8275 10.2546ZM14.5465 1.25432V9.27024H16.2357C16.2357 7.04667 16.2357 4.87838 16.2357 2.71009C16.2234 1.53841 15.8379 1.20211 14.5465 1.25432Z" fill="#4A148C"/>
                            <path d="M19.8277 10.0626H14.4961C14.4453 10.063 14.3967 10.0834 14.3608 10.1193C14.3249 10.1552 14.3045 10.2038 14.3041 10.2546V17.6255L14.4961 17.4336H10.9196L11.1116 17.6255V0.331443L10.9196 0.523395C12.2587 0.429722 13.6162 0.47579 14.9598 0.477326C15.6109 0.477326 16.2651 0.461971 16.9162 0.495755C17.3293 0.51004 17.7379 0.587193 18.1278 0.72456C18.4504 0.845726 18.7393 1.04236 18.9704 1.29803C19.2015 1.55369 19.368 1.86096 19.4561 2.19414C19.5731 2.66108 19.6309 3.14089 19.6281 3.62227C19.6511 4.30101 19.6603 4.98129 19.6634 5.66003C19.6634 7.19564 19.6358 8.72358 19.6358 10.2546C19.6358 10.3055 19.656 10.3543 19.692 10.3903C19.728 10.4263 19.7768 10.4465 19.8277 10.4465C19.8786 10.4465 19.9274 10.4263 19.9634 10.3903C19.9994 10.3543 20.0197 10.3055 20.0197 10.2546C20.0197 8.83415 20.0473 7.41524 20.0473 5.99479C20.0473 5.28995 20.0473 4.5851 20.0197 3.88025C20.0252 3.3455 19.9774 2.81153 19.8769 2.28628C19.8051 1.91733 19.6541 1.56835 19.4343 1.26346C19.2145 0.95856 18.9312 0.70501 18.6038 0.520323C17.7209 0.0504246 16.6858 0.101102 15.7153 0.0949592C14.9091 0.0949592 14.1029 0.0949592 13.2952 0.0949592C12.4875 0.0949592 11.7105 0.0842088 10.9196 0.139491C10.8691 0.140662 10.8209 0.161261 10.7852 0.197009C10.7494 0.232756 10.7288 0.280902 10.7277 0.331443V17.6271C10.7281 17.6779 10.7484 17.7265 10.7843 17.7624C10.8202 17.7983 10.8688 17.8186 10.9196 17.819H14.4961C14.5468 17.8186 14.5954 17.7983 14.6313 17.7624C14.6673 17.7265 14.6876 17.6779 14.688 17.6271V10.2561L14.4961 10.4481H19.8277C19.8788 10.4481 19.9278 10.4278 19.964 10.3916C20.0001 10.3555 20.0204 10.3065 20.0204 10.2554C20.0204 10.2042 20.0001 10.1552 19.964 10.1191C19.9278 10.0829 19.8788 10.0626 19.8277 10.0626Z" fill="#4A148C"/>
                            <path d="M14.3546 1.25435V9.27026C14.355 9.32104 14.3753 9.36963 14.4112 9.40555C14.4471 9.44146 14.4957 9.46181 14.5465 9.46221H16.2357C16.2865 9.46181 16.3351 9.44146 16.371 9.40555C16.4069 9.36963 16.4272 9.32104 16.4276 9.27026C16.4276 7.68243 16.4276 6.09563 16.4276 4.50985C16.4276 4.11059 16.4276 3.71133 16.4276 3.31207C16.4276 2.99574 16.4399 2.67633 16.4092 2.36153C16.3739 1.99144 16.2771 1.60754 15.9762 1.36338C15.5861 1.04858 15.0256 1.05625 14.5511 1.067C14.3054 1.07622 14.3039 1.46012 14.5511 1.45091C14.9166 1.43709 15.3773 1.41098 15.6936 1.63057C15.9685 1.81792 16.0192 2.18954 16.036 2.49512C16.0729 3.17694 16.0453 3.86643 16.0453 4.54824C16.0453 5.28739 16.0453 6.02653 16.0453 6.76567C16.0453 7.60309 16.0453 8.43949 16.0453 9.27487L16.2372 9.08291H14.548L14.74 9.27487V1.25896C14.7406 1.20784 14.7209 1.15858 14.6852 1.12201C14.6495 1.08543 14.6007 1.06454 14.5496 1.06393C14.4985 1.06332 14.4492 1.08304 14.4126 1.11875C14.3761 1.15446 14.3552 1.20323 14.3546 1.25435Z" fill="black"/>
                            <path d="M14.5466 1.25428C15.838 1.20207 16.2234 1.53837 16.2281 2.71004C16.2373 4.87833 16.2281 7.04662 16.2281 9.27019H14.5389L14.5466 1.25428Z" fill="white"/>
                            <path d="M14.5466 1.44628C14.9121 1.43246 15.3727 1.40636 15.6891 1.62595C15.964 1.8133 16.0146 2.18491 16.0315 2.4905C16.0684 3.17231 16.0407 3.86181 16.0407 4.54362C16.0407 5.28276 16.0407 6.0219 16.0407 6.76105C16.0407 7.59847 16.0407 8.43487 16.0407 9.27024L16.2327 9.07829H14.5435L14.7355 9.27024V1.25433C14.7355 1.20342 14.7152 1.1546 14.6792 1.1186C14.6432 1.0826 14.5944 1.06238 14.5435 1.06238C14.4926 1.06238 14.4438 1.0826 14.4078 1.1186C14.3718 1.1546 14.3516 1.20342 14.3516 1.25433V9.27024C14.352 9.32103 14.3723 9.36962 14.4082 9.40553C14.4441 9.44144 14.4927 9.46179 14.5435 9.46219H16.2327C16.2835 9.46179 16.3321 9.44144 16.368 9.40553C16.4039 9.36962 16.4242 9.32103 16.4246 9.27024C16.4246 7.68242 16.4246 6.09561 16.4246 4.50984C16.4246 4.11058 16.4246 3.71132 16.4246 3.31206C16.4246 2.99572 16.4369 2.67631 16.4062 2.36151C16.3709 1.99143 16.2742 1.60752 15.9732 1.36336C15.5831 1.04856 15.0226 1.05624 14.5481 1.06699C14.3009 1.07159 14.2994 1.4555 14.5466 1.44628Z" fill="#4A148C"/>
                            <path d="M1.01185 7.68094C1.01185 10.0873 0.935064 12.4198 1.03488 14.7448C1.12241 16.7887 2.14974 17.6532 4.22589 17.6747C5.91506 17.6916 7.61499 17.6747 9.30877 17.6747C9.5299 17.6747 9.75103 17.6486 9.94759 17.6348V0.282349H6.35579V7.67787L1.01185 7.68094Z" fill="white"/>
                            <path d="M0.815066 7.68088C0.815066 9.10286 0.785889 10.5248 0.785889 11.9468C0.785889 12.6348 0.785888 13.3212 0.811994 14.0091C0.805417 14.5606 0.852216 15.1114 0.951735 15.6538C1.02123 16.0248 1.17034 16.3763 1.38879 16.6841C1.60723 16.9918 1.88983 17.2486 2.21708 17.4366C3.1062 17.9188 4.15349 17.8681 5.13475 17.8728C5.94402 17.8728 6.75329 17.8728 7.56256 17.8728C8.37183 17.8728 9.15345 17.8835 9.94583 17.8282C9.99678 17.827 10.0453 17.8063 10.0814 17.7703C10.1174 17.7342 10.1381 17.6857 10.1393 17.6347V0.282284C10.1385 0.231359 10.1178 0.182768 10.0817 0.146898C10.0455 0.111029 9.99676 0.0907268 9.94583 0.090332H6.35557C6.30464 0.0907268 6.25588 0.111029 6.21973 0.146898C6.18357 0.182768 6.16288 0.231359 6.16208 0.282284V7.67781L6.35557 7.48585H1.01162C0.963481 7.49016 0.918692 7.51232 0.886071 7.54799C0.853449 7.58365 0.835358 7.63024 0.835358 7.67857C0.835358 7.72691 0.853449 7.77349 0.886071 7.80916C0.918692 7.84483 0.963481 7.86699 1.01162 7.87129H6.35557C6.40637 7.87011 6.45473 7.8493 6.49052 7.81322C6.52631 7.77715 6.54674 7.72861 6.54752 7.67781V0.285353L6.35557 0.477305H9.94276L9.75081 0.285353V17.6378L9.94276 17.4459C8.58681 17.5426 7.21244 17.495 5.85188 17.4919C5.21153 17.4919 4.56657 17.5073 3.92776 17.475C3.50589 17.4599 3.08891 17.3796 2.69159 17.237C2.36896 17.1122 2.08088 16.912 1.85141 16.6531C1.62193 16.3943 1.45772 16.0843 1.37249 15.749C1.25616 15.2643 1.19994 14.7671 1.20511 14.2687C1.18208 13.6068 1.17286 12.945 1.17133 12.2816C1.17133 10.746 1.19897 9.21035 1.19897 7.68395C1.19917 7.65874 1.19441 7.63374 1.18495 7.61037C1.17549 7.58701 1.16152 7.56574 1.14383 7.54777C1.12615 7.5298 1.1051 7.51549 1.08189 7.50566C1.05868 7.49583 1.03376 7.49066 1.00855 7.49046C0.957644 7.49005 0.908658 7.50989 0.872372 7.5456C0.836087 7.58131 0.815473 7.62997 0.815066 7.68088Z" fill="black"/>
                            <path d="M1.01144 7.68092H6.35538V0.2854H9.94258V17.6379C9.74602 17.6517 9.52489 17.6778 9.30376 17.6778C7.61458 17.6778 5.91466 17.6931 4.22087 17.6778C2.14472 17.6563 1.11739 16.7917 1.02986 14.7478C0.930049 12.4198 1.01144 10.0872 1.01144 7.68092ZM6.30778 16.7103V8.66833H4.6186C4.6186 10.898 4.6186 13.074 4.6186 15.25C4.62167 16.4247 5.00404 16.7626 6.30317 16.7103H6.30778Z" fill="#D71F70"/>
                            <path d="M1.01162 7.86821H6.35557C6.40637 7.86702 6.45473 7.84621 6.49052 7.81013C6.52631 7.77406 6.54674 7.72553 6.54752 7.67472V0.285336L6.35557 0.477288H9.94276L9.75081 0.285336V17.6378L9.94276 17.4458C8.58681 17.5426 7.21244 17.495 5.85188 17.4919C5.21153 17.4919 4.56657 17.5073 3.92776 17.475C3.50589 17.4599 3.08891 17.3796 2.69159 17.237C2.36896 17.1122 2.08088 16.912 1.85141 16.6531C1.62193 16.3943 1.45772 16.0843 1.37249 15.749C1.25616 15.2643 1.19994 14.7671 1.20511 14.2686C1.18208 13.6068 1.17286 12.9449 1.17133 12.2816C1.17133 10.7459 1.19897 9.21033 1.19897 7.68393C1.19897 7.63302 1.17875 7.5842 1.14275 7.5482C1.10675 7.5122 1.05793 7.49198 1.00702 7.49198C0.956109 7.49198 0.907286 7.5122 0.871288 7.5482C0.83529 7.5842 0.815066 7.63302 0.815066 7.68393C0.815066 9.10591 0.785889 10.5279 0.785889 11.9499C0.785889 12.6378 0.785888 13.3242 0.811994 14.0122C0.805417 14.5636 0.852216 15.1144 0.951735 15.6568C1.02123 16.0278 1.17034 16.3793 1.38879 16.6871C1.60723 16.9949 1.88983 17.2517 2.21708 17.4397C3.1062 17.9219 4.15349 17.8712 5.13475 17.8758C5.94402 17.8758 6.75329 17.8758 7.56256 17.8758C8.37183 17.8758 9.15345 17.8866 9.94583 17.8313C9.99678 17.8301 10.0453 17.8093 10.0814 17.7733C10.1174 17.7373 10.1381 17.6887 10.1393 17.6378V0.285336C10.1385 0.234411 10.1178 0.185822 10.0817 0.149952C10.0455 0.114083 9.99676 0.0937785 9.94583 0.0933838H6.35557C6.30464 0.0937785 6.25588 0.114083 6.21973 0.149952C6.18357 0.185822 6.16288 0.234411 6.16208 0.285336V7.68086L6.35557 7.48891H1.01162C0.967852 7.49776 0.92849 7.52148 0.900214 7.55605C0.871938 7.59062 0.856488 7.6339 0.856488 7.67856C0.856488 7.72322 0.871938 7.7665 0.900214 7.80107C0.92849 7.83563 0.967852 7.85935 1.01162 7.86821Z" fill="#D71F70"/>
                            <path d="M6.49684 16.7103V8.66827C6.49604 8.61735 6.47535 8.56875 6.43919 8.53288C6.40304 8.49701 6.35428 8.47671 6.30335 8.47632H4.61418C4.56339 8.47672 4.5148 8.49707 4.47889 8.53298C4.44298 8.56889 4.42263 8.61749 4.42222 8.66827C4.42222 10.2561 4.42222 11.8429 4.42222 13.4287C4.42222 14.1397 4.37923 14.8706 4.44065 15.5785C4.47443 15.9548 4.56964 16.3463 4.8783 16.5997C5.26835 16.9161 5.83192 16.9161 6.30642 16.8976C6.55366 16.8884 6.55519 16.503 6.30642 16.5122C5.93941 16.526 5.46491 16.5537 5.14857 16.3248C4.87677 16.1283 4.83223 15.7505 4.81688 15.4419C4.78463 14.7601 4.81688 14.0721 4.80766 13.3903V11.1944C4.80766 10.3528 4.80766 9.51081 4.80766 8.66827L4.61571 8.86176H6.30489L6.11294 8.66827V16.7103C6.11886 16.757 6.14162 16.8 6.17696 16.8311C6.2123 16.8623 6.25779 16.8794 6.30489 16.8794C6.35199 16.8794 6.39747 16.8623 6.43281 16.8311C6.46815 16.8 6.49092 16.757 6.49684 16.7103Z" fill="black"/>
                            <path d="M6.30338 16.7103C5.00886 16.7626 4.6142 16.4247 4.6142 15.25C4.60499 13.074 4.6142 10.898 4.6142 8.66833H6.30338V16.7103Z" fill="white"/>
                            <path d="M6.30312 16.5168C5.93611 16.5306 5.4616 16.5583 5.14526 16.3295C4.87346 16.1329 4.82893 15.7551 4.81357 15.4465C4.78132 14.7647 4.81357 14.0767 4.80436 13.3949V11.1944C4.80436 10.3528 4.80436 9.51081 4.80436 8.66827L4.6124 8.86176H6.30158L6.10963 8.66827V16.7103C6.11393 16.7584 6.1361 16.8032 6.17176 16.8358C6.20743 16.8685 6.25401 16.8866 6.30235 16.8866C6.35069 16.8866 6.39727 16.8685 6.43294 16.8358C6.4686 16.8032 6.49077 16.7584 6.49507 16.7103V8.66827C6.49427 8.61735 6.47358 8.56875 6.43742 8.53288C6.40127 8.49701 6.35251 8.47671 6.30158 8.47632H4.6124C4.56162 8.47672 4.51303 8.49707 4.47712 8.53298C4.44121 8.56889 4.42085 8.61749 4.42045 8.66827C4.42045 10.2561 4.42045 11.8429 4.42045 13.4287C4.42045 14.1397 4.37746 14.8706 4.43888 15.5785C4.47267 15.9548 4.56787 16.3463 4.87653 16.5997C5.26658 16.9161 5.83015 16.9161 6.30465 16.8976C6.55035 16.893 6.55189 16.5076 6.30312 16.5168Z" fill="#D71F70"/>
                            <path d="M19.2992 17.9095C20.3568 17.9095 21.2141 17.0522 21.2141 15.9946C21.2141 14.937 20.3568 14.0797 19.2992 14.0797C18.2416 14.0797 17.3843 14.937 17.3843 15.9946C17.3843 17.0522 18.2416 17.9095 19.2992 17.9095Z" fill="#9747FF" stroke="black" stroke-width="0.0568178" stroke-miterlimit="10"/>
                        </svg>

                    </div>

                    <div class="flex flex-wrap gap-x-6 lg:gap-x-4 gap-y-2 justify-center justify-self-center py-4 xl:py-2">
                        <a id="github" :href="user.links.github" target="_blank"
                            class="-m-1 flex items-center justify-center rounded p-1 transition-colors hover:bg-primary-800/5">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6">
                                <path class="fill-primary" 
                                    d="M12 2C6.477 2 2 6.477 2 12c0 4.418 2.865 8.166 6.839 9.491.5.091.682-.217.682-.482 0-.237-.008-.868-.013-1.704-2.782.604-3.369-1.343-3.369-1.343-.454-1.152-1.11-1.459-1.11-1.459-.908-.621.069-.608.069-.608 1.004.07 1.532 1.031 1.532 1.031.892 1.529 2.341 1.088 2.911.832.091-.647.35-1.088.636-1.338-2.22-.253-4.555-1.11-4.555-4.944 0-1.091.39-1.983 1.029-2.683-.103-.253-.447-1.27.098-2.646 0 0 .84-.27 2.75 1.025A9.564 9.564 0 0 1 12 6.845a9.56 9.56 0 0 1 2.502.338c1.91-1.295 2.75-1.025 2.75-1.025.546 1.376.202 2.393.099 2.646.64.7 1.028 1.592 1.028 2.683 0 3.842-2.337 4.687-4.566 4.935.36.31.678.922.678 1.857 0 1.34-.012 2.422-.012 2.75 0 .267.18.576.688.478C19.138 20.163 22 16.417 22 12c0-5.523-4.477-10-10-10z"/>
                            </svg>
                        </a>
                        <a id="linkedin" :href="user.links.linkedin" target="_blank"
                            class="-m-1 flex items-center justify-center rounded p-1 transition-colors hover:bg-primary-800/5">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6">
                                <path class="fill-primary"
                                    d="M1.66667 2.86042C1.66667 2.20104 2.21458 1.66667 2.89062 1.66667H17.1094C17.7854 1.66667 18.3333 2.20104 18.3333 2.86042V17.1396C18.3333 17.799 17.7854 18.3333 17.1094 18.3333H2.89062C2.21458 18.3333 1.66667 17.799 1.66667 17.1396V2.86042ZM6.81562 15.6187V8.09271H4.31458V15.6187H6.81562ZM5.56563 7.06458C6.4375 7.06458 6.98021 6.4875 6.98021 5.76458C6.96458 5.02604 6.43854 4.46458 5.58229 4.46458C4.72604 4.46458 4.16667 5.02708 4.16667 5.76458C4.16667 6.4875 4.70937 7.06458 5.54896 7.06458H5.56563ZM10.6781 15.6187V11.4156C10.6781 11.1906 10.6948 10.9656 10.7615 10.8052C10.9417 10.3562 11.3531 9.89062 12.0448 9.89062C12.95 9.89062 13.3115 10.5802 13.3115 11.5927V15.6187H15.8125V11.3021C15.8125 8.98958 14.5792 7.91458 12.9333 7.91458C11.6063 7.91458 11.0115 8.64375 10.6781 9.15729V9.18333H10.6615C10.667 9.17464 10.6725 9.16596 10.6781 9.15729V8.09271H8.17812C8.20937 8.79896 8.17812 15.6187 8.17812 15.6187H10.6781Z" />
                            </svg>
                        </a>
                        <a id="behance" :href="user.links.behance" target="_blank"
                            class="-m-1 flex items-center justify-center rounded p-1 transition-colors hover:bg-primary-800/5">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6">
                                <circle cx="12" cy="12" r="12" class="fill-primary" />
                                <g transform="translate(2, 2) scale(0.8)">
                                    <path class="fill-white"
                                    d="M22 7h-7v-2h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14h-8.027c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988h-6.466v-14.967h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zm-3.466-8.988h3.584c2.508 0 2.906-3-.312-3h-3.272v3zm3.391 3h-3.391v3.016h3.341c3.055 0 2.868-3.016.05-3.016z" />
                                </g>
                            </svg>
                        </a>
                        <a id="other" :href="user.links.other" target="_blank"
                            class="-m-1 flex items-center justify-center rounded p-1 transition-colors hover:bg-primary-800/5">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6">
                                <path class="fill-primary" d="M10.5 12.5763C10.9583 12.5763 11.3507 12.4131 11.6771 12.0867C12.0035 11.7603 12.1666 11.368 12.1666 10.9096C12.1666 10.4513 12.0035 10.0589 11.6771 9.73254C11.3507 9.40616 10.9583 9.24296 10.5 9.24296C10.0416 9.24296 9.64929 9.40616 9.3229 9.73254C8.99651 10.0589 8.83331 10.4513 8.83331 10.9096C8.83331 11.368 8.99651 11.7603 9.3229 12.0867C9.64929 12.4131 10.0416 12.5763 10.5 12.5763ZM7.16665 15.9096H13.8333V15.4305C13.8333 15.0971 13.743 14.7916 13.5625 14.5138C13.3819 14.236 13.1319 14.0277 12.8125 13.8888C12.4514 13.736 12.0798 13.618 11.6979 13.5346C11.316 13.4513 10.9166 13.4096 10.5 13.4096C10.0833 13.4096 9.68401 13.4513 9.30206 13.5346C8.92012 13.618 8.54859 13.736 8.18748 13.8888C7.86803 14.0277 7.61804 14.236 7.43748 14.5138C7.25692 14.7916 7.16665 15.0971 7.16665 15.4305V15.9096ZM15.5 19.243H5.49998C5.04165 19.243 4.64929 19.0798 4.3229 18.7534C3.99651 18.427 3.83331 18.0346 3.83331 17.5763V4.24296C3.83331 3.78463 3.99651 3.39227 4.3229 3.06588C4.64929 2.73949 5.04165 2.57629 5.49998 2.57629H12.1666L17.1666 7.57629V17.5763C17.1666 18.0346 17.0035 18.427 16.6771 18.7534C16.3507 19.0798 15.9583 19.243 15.5 19.243Z"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

        <AboutFormModal
          v-if="isFormModalOpen"
          :data="user"
          @cancel="handleCancelButtonClick"
          @save="handleSaveButtonClick"
        />
    </div>
</template>

<script setup>
import { __ } from '@/helpers';
import { Button } from '@/components/block/button';
import { Icon } from '@/components/block/icon';
import { reactive, ref } from 'vue';
import AboutFormModal from '../about-form-modal/about-form-modal.vue';

const props = defineProps({
    data: {
        type: Object,
        required: true
    }
});

const user = reactive(props.data);

const isFormModalOpen = ref(false);

function handleEditButtonClick() {
    isFormModalOpen.value = true;
}

function handleCancelButtonClick() {
    isFormModalOpen.value = false;
}

function handleSaveButtonClick(updatedData) {
    Object.assign(user, updatedData.data); 
    isFormModalOpen.value = false;
}

</script>
