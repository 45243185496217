<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Details')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="local_activity" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
      <div class="space-y-6">
        <UploadCover
          v-model:value="values.cover"
          v-model:file="files.cover"
          v-model:errors="errors.cover"
        />

        <InputImage
          id="photo"
          v-model:value="values.photo"
          v-model:file="files.photo"
          v-model:errors="errors.photo"
          :label="__('Featured Photo')"
          :description="`JPG, JPEG ${__('or')} PNG (max. 2 MB)`"
          :extra-description="
            __(
              'Upload a photo for the event listing page to give users a better feel.'
            )
          "
          required
          :aspect-ratio="4 / 3"
        />

        <InputText
          id="name"
          v-model:value="values.name"
          v-model:errors="errors.name"
          :label="__('Name')"
          :placeholder="__('Add a suggestive event name')"
          required
        />

        <InputTextarea
          id="description"
          v-model:value="values.description"
          v-model:errors="errors.description"
          :label="__('Description')"
          :placeholder="
            __(
              `This is the event description. It's what people will see when you promote your event, but don't worry, you can always update it later. Feel free to provide additional details about the event purpose, speakers or specific sessions.`
            )
          "
          required
        />

        <InputSelect
          id="timezone"
          v-model:value="values.timezone"
          v-model:errors="errors.timezone"
          :options="options.timezones"
          :label="__('Timezone')"
          :placeholder="__('Timezone')"
          required
        />

        <div class="grid grid-cols-2 gap-6">
          <InputDateTime
            id="start_date_time"
            v-model:value="values.start_date_time"
            v-model:errors="errors.start_date_time"
            :label="__('Start date and time')"
            :placeholder="__('Pick date and time')"
            required
          />

          <InputDateTime
            id="end_date_time"
            v-model:value="values.end_date_time"
            v-model:errors="errors.end_date_time"
            :label="__('End date and time')"
            :placeholder="__('Pick date and time')"
            required
          />
        </div>

        <InputRadio
          id="type"
          v-model:value="values.type"
          v-model:errors="errors.type"
          :label="__('Event type')"
          :options="options.type"
          required
          @update:value="handleEventTypeChange"
        />

        <template v-if="!isEventTypeRemote">
          <InputLocation
            id="location"
            v-model:value="values.location"
            v-model:errors="errors.location"
            :label-country="__('Country')"
            :label-city="__('City')"
            :placeholder-country="__('Select country')"
            :placeholder-city="__('Select city')"
            :options="options.country"
            required
          />

          <InputText
            id="address"
            v-model:value="values.address"
            v-model:errors="errors.address"
            :label="__('Address')"
            :placeholder="__('Address')"
            required
          />
        </template>

        <InputText
          id="streaming_platform"
          v-model:value="values.streaming_platform"
          v-model:errors="errors.streaming_platform"
          :label="__('Streaming platform')"
          :placeholder="
            __(
              isEventTypeRemote
                ? 'Streaming platform'
                : 'Only in case the event has online streaming support'
            )
          "
          :required="isEventTypeRemote"
        />

        <div class="grid grid-cols-2 gap-6">
          <InputNumber
            id="attendee_limit"
            v-model:value="values.attendee_limit"
            v-model:errors="errors.attendee_limit"
            :label="__('Attendee limit')"
            :placeholder="__('Members will see how many spots are available')"
          />

          <InputDateTime
            id="rsvp"
            v-model:value="values.rsvp"
            v-model:errors="errors.rsvp"
            :label="__('Registration deadline')"
            :placeholder="__('Pick a date')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { InputDateTime } from '@/components/form/input-date-time';
  import { InputImage } from '@/components/form/input-image';
  import { InputLocation } from '@/components/form/input-location';
  import { InputNumber } from '@/components/form/input-number';
  import { InputRadio } from '@/components/form/input-radio';
  import { InputSelect } from '@/components/form/input-select';
  import { InputText } from '@/components/form/input-text';
  import { InputTextarea } from '@/components/form/input-textarea';
  import { SectionHeader } from '@/pages/components/section-header';
  import { UploadCover } from './components/upload-cover';

  const props = defineProps({
    values: {
      type: Object,
      required: true
    },
    files: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['update:values', 'update:files', 'update:errors']);

  const isEventTypeRemote = computed(() => {
    return values.value.type.id === 1;
  });

  const values = ref(JSON.parse(JSON.stringify(props.values)));
  const files = ref(JSON.parse(JSON.stringify(props.files)));
  const errors = ref(JSON.parse(JSON.stringify(props.errors)));

  function handleEventTypeChange() {
    if (isEventTypeRemote.value) {
      values.value.location = null;
      values.value.address = '';
      errors.value.location = [];
      errors.value.address = [];
    } else {
      errors.value.streaming_platform = [];
    }
  }

  watch(
    values,
    (value) => {
      emits('update:values', value);
    },
    { deep: true }
  );
  watch(
    files,
    (value) => {
      emits('update:files', value);
    },
    { deep: true }
  );
  watch(
    errors,
    (value) => {
      emits('update:errors', value);
    },
    { deep: true }
  );
  watch(
    props.errors,
    (value) => {
      errors.value = JSON.parse(JSON.stringify(value));
    },
    { deep: true, immediate: true }
  );
</script>
