<template>
  <div class="px-5 pb-5">
    <div class="flex flex-col gap-5">
      <div
        class="flex flex-col lg:flex-row gap-5 justify-between w-full items-end"
        v-for="(item, index) in filterData"
        v-bind:key="index"
      >
        <div class="w-full">
          <label
            class="font-primary font-normal text-base text-gray-400 pb-1 flex items-center justify-between"
            >{{ __("Language") }}
            <div v-if="markMandatory == true">
              <Toggle
                v-model="item.mandatory"
                :value="item.mandatory"
                :setid="'mandatory'"
                @change="item.proficiency.mandatory = false"
              />
            </div>
          </label>
          <Multiselect
            v-model="item.value"
            :values="item.values"
            :clearable="false"
            type="static"
          />
        </div>
        <div class="w-full">
          <label
            class="font-primary font-normal text-base text-gray-400 pb-1 flex items-center justify-between"
            >{{ __("Proficiency") }}
            <div v-if="markMandatory == true && item.mandatory">
              <Toggle
                v-model="item.proficiency.mandatory"
                :value="item.value ? item.proficiency.mandatory : null"
                :setid="'mandatory'"
              />
            </div>
          </label>
          <Multiselect
            v-model="item.proficiency.value"
            :values="item.proficiency.values"
            :disabled="item.value ? false : true"
            :clearable="false"
            type="static"
          />
        </div>
        <div
          class="p-2 bg-lightGray rounded-app lg:w-20 w-12 text-center font-bold cursor-pointer"
          @click="removeItem(filterData, item)"
          v-if="index < filterData.length - 1"
        >
          <span>-</span>
        </div>
        <div
          class="p-2 bg-lightGray rounded-app lg:w-20 w-12 text-center font-bold cursor-pointer"
          @click="pushItem(filterData)"
          v-if="index >= filterData.length - 1"
        >
          +
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Toggle, Multiselect } from "../../global";
import { ref } from "vue";
import { __ } from "../../../helpers";

const props = defineProps({
  markMandatory: Boolean,
  filterData: Array,
});

function pushItem(value) {
  value.push({
    type: "language",
    value: "",
    mandatory: false,
    values: props.filterData[0].values,
    proficiency: {
      value: "",
      mandatory: false,
      values: props.filterData[0].proficiency.values,
    },
  });
}

function removeItem(arr, value) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    }
  }
}
</script>
