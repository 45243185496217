<template>
  <div>
    <div class="mt-8 md:grid md:grid-cols-2">
      <SectionHeader icon="description" title="Job Details" />
    </div>
    <div class="relative mt-8 rounded-app bg-white p-8 shadow-md">
      <div>
        <div class="gap-4 md:mb-8 md:grid md:grid-cols-2">
          <div class="my-4 md:my-0">
            <Input
              :value="data.job.title"
              placeholder="Software Engineer"
              label="Job title"
              type="text"
              required
              :errors="errors.title"
              :maxlength="100"
              @input="handleJobTitleInput"
            />
          </div>

          <div class="relative my-4 md:my-0">
            <InputCareers
              id="std_title"
              v-model:value="data.job.std_title"
              :errors="errors.std_title"
              :label="__('Standardized job title')"
              :placeholder="__('Search here...')"
              required
              is-new-options-allowed
              @update:value="updateStdJobTitle"
            />
            <Button
              v-if="hasAttributes"
              class="absolute right-0 top-[calc(100%_+_0.4em)] flex items-center gap-1.5"
              variant="ghost"
              @click="autoFillFields"
            >
              <Icon class="text-xl" name="add_box" is-filled />
              <span>{{ __('Autofill fields from template') }}</span>
            </Button>
          </div>
        </div>

        <div class="gap-4 md:mb-8 md:grid md:grid-cols-2">
          <div class="my-4 md:my-0">
            <label class="mb-2 block text-sm text-[#58585E]"
              >{{ __('Industry experience') }} *</label
            >
            <FormSingleselect
              v-model="domains.job"
              :values="domains.options"
              :clearable="true"
              @clear="domains.job = null"
              type="static"
              :errors="errors.industry_experience"
              required
            />
          </div>

          <div class="my-4 md:my-0">
            <label class="mb-2 block text-sm text-[#58585E]"
              >{{ __('Department') }} *</label
            >
            <FormSingleselect
              v-model="data.job.sub_department"
              :values="data.options.merged_departments"
              :clearable="true"
              @clear="data.job.merged_departments = null"
              type="static"
              :errors="errors.department"
              required
            />
          </div>
        </div>

        <div class="gap-4 md:mb-8 md:grid md:grid-cols-2">
          <div class="my-4 md:my-0">
            <label class="mb-2 block text-sm text-[#58585E]"
              >{{ __('Availability') }} *</label
            >
            <FormSingleselect
              v-model="data.job.type"
              :values="data.options.type"
              :clearable="true"
              @clear="data.job.type = null"
              type="static"
              :errors="errors.availability"
              required
            />
          </div>

          <div class="my-4 md:my-0">
            <label class="mb-2 block text-sm text-[#58585E]"
              >{{ __('Level of experience') }} *</label
            >
            <FormSingleselect
              v-model="data.job.career_level"
              :values="data.options.career_level"
              :clearable="true"
              @clear="data.job.career_level = null"
              type="static"
              :errors="errors.level_of_experience"
              required
            />
          </div>
        </div>

        <div class="gap-4 md:mb-8 md:grid md:grid-cols-2">
          <div class="my-4 md:my-0">
            <label class="mb-2 block text-sm text-[#58585E]"
              >{{ __('Contract type') }} *</label
            >
            <FormMultiselect
              v-model="contract_types.job"
              :values="contract_types.options"
              :clearable="true"
              :multiple="true"
              type="static"
              class="mt-2"
              :errors="errors.contract_types"
              required
            />
          </div>
          <div class="my-4 md:my-0">
            <label class="mb-2 block text-sm text-[#58585E]"
              >{{ __('Presence') }} *</label
            >
            <FormMultiselect
              v-model="presence.job"
              :values="presence.options"
              :clearable="true"
              :multiple="true"
              type="static"
              class="mt-2"
              :errors="errors.presence"
              required
            />
          </div>
        </div>

        <div class="gap-4 md:mb-8 md:grid md:grid-cols-2">
          <div class="my-4 md:my-0">
            <label class="mb-2 block text-sm text-[#58585E]">{{
              __('Required visa')
            }}</label>
            <FormMultiselect
              v-model="visas.job"
              :values="visas.options"
              :clearable="true"
              :multiple="true"
              type="static"
              class="mt-2"
            />
          </div>
          <div class="my-4 md:my-0">
            <label class="mb-2 block text-sm text-[#58585E]"
              >{{ __('Recruiters') }} *</label
            >
            <FormMultiselect
              v-model="recruiters.job"
              :values="recruiters.options"
              :clearable="true"
              :multiple="true"
              type="static"
              class="mt-2"
              :errors="errors.recruiters"
              required
            />
          </div>
        </div>

        <div>
          <div class="mb-4">
            <label class="mb-2 block text-sm text-[#58585E]">{{
              __('Salary')
            }}</label>
            <FormSingleselect
              v-model="data.job.salary.type"
              :values="data.options.salary.type"
              :clearable="true"
              type="static"
              required
              :errors="errors.salary"
            />
          </div>

          <!-- <div v-if="data.job?.salary?.id == 1">1</div> -->
          <div v-if="data.job?.salary?.type.id == 2">
            <div>
              <div class="gap-4 md:mb-8 md:grid md:grid-cols-4">
                <div class="my-4 md:my-0">
                  <label class="mb-2 block text-sm text-[#58585E]">{{
                    __('Salary type')
                  }}</label>
                  <FormSingleselect
                    v-model="data.job.salary.value.period"
                    :values="data.options.salary.period"
                    :clearable="true"
                    type="static"
                    :errors="errors.period"
                    required
                  />
                </div>

                <div class="my-4 md:my-0">
                  <Input
                    v-model="data.job.salary.value.salary.from"
                    :value="data.job.salary.value.salary.from"
                    placeholder="Salary (from)"
                    type="number"
                    label="Salary (from)"
                    :errors="errors.from"
                    required
                  />
                </div>

                <div class="my-4 md:my-0">
                  <Input
                    v-model="data.job.salary.value.salary.to"
                    :value="data.job.salary.value.salary.to"
                    placeholder="Salary (to)"
                    type="number"
                    label="Salary (to)"
                    :errors="errors.to"
                    required
                  />
                </div>

                <div class="my-4 md:my-0">
                  <label class="mb-2 block text-sm text-[#58585E]">{{
                    __('Currency')
                  }}</label>
                  <FormSingleselect
                    v-model="data.job.salary.value.currency"
                    :values="data.options.salary.currency"
                    :clearable="true"
                    type="static"
                    icon="attach_money"
                    :errors="errors.currency"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="data.job?.salary?.type.id == 3">
            <div>
              <div class="gap-4 md:mb-8 md:grid md:grid-cols-3">
                <div class="my-4 md:my-0">
                  <label class="mb-2 block text-sm text-[#58585E]">{{
                    __('Salary type')
                  }}</label>
                  <FormSingleselect
                    v-model="data.job.salary.value.period"
                    :values="data.options.salary.period"
                    :clearable="true"
                    type="static"
                    :errors="errors.period"
                    required
                  />
                </div>

                <div class="my-4 md:my-0">
                  <Input
                    v-model="data.job.salary.value.salary.fixed"
                    :value="data.job.salary.value.salary.fixed"
                    placeholder="Salary (gross)"
                    type="number"
                    label="Salary (gross)"
                    :errors="errors.fixed"
                    required
                  />
                </div>

                <div class="my-4 md:my-0">
                  <label class="mb-2 block text-sm text-[#58585E]"
                    >{{ __('Currency') }}
                  </label>
                  <FormSingleselect
                    v-model="data.job.salary.value.currency"
                    :values="data.options.salary.currency"
                    :clearable="true"
                    type="static"
                    icon="attach_money"
                    :errors="errors.currency"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="metadata.single_company">
          <div class="">
            <label class="mb-2 block text-sm text-[#58585E]">{{
              __('Internal job')
            }}</label>
            <!-- TODO -->
            <Toggle v-model="data.job.internal" :value="data.job.internal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import {
    FormMultiselect,
    FormSingleselect,
    Input,
    SectionHeader,
    Toggle
  } from '../../../../components/global';

  import { __ } from '../../../../helpers';
  import axios from 'axios';
  import { useLoading } from 'vue-loading-overlay';
  import { useToast } from 'vue-toastification';
  import { Icon } from '@/components/block/icon';
  import { Button } from '@/components/block/button';
  import { InputCareers } from '@/components/form/input-careers';

  const props = defineProps({
    data: {
      type: Object,
      mandatory: true
    },
    domains: {
      type: Object,
      mandatory: true
    },
    contract_types: {
      type: Object,
      mandatory: true
    },
    presence: {
      type: Object,
      mandatory: true
    },
    visas: {
      type: Object,
      mandatory: true
    },
    recruiters: {
      type: Object,
      mandatory: true
    },
    errors: {
      type: Object,
      mandatory: true
    },
    salary: {
      type: Object,
      mandatory: true
    },
    metadata: {
      type: Object,
      mandatory: true
    }
  });

  const hasAttributes = ref(false);
  const selectedAttribute = ref();
  const $loading = useLoading();
  const toast = useToast();

  const createStandardisedTitle = async (value) => {
    const loader = $loading.show();

    try {
      const response = await axios.post(
        route('redesign.company.stdtitle.create'),
        {
          name: value
        }
      );

      toast.success(response.data.message);

      return { id: response.data.id, name: value }; // Return the result directly
    } catch (err) {
      console.error(err);
      throw err; // Rethrow the error if you need to handle it higher up
    } finally {
      loader.hide();
    }
  };

  const emit = defineEmits(['jobDataEmitUpdate']);
  async function stdHasAttributes(stdId) {
    try {
      const result = await axios.post(route('redesign.std.has.attributes'), {
        std_id: stdId
      });
      hasAttributes.value = result.data;
      selectedAttribute.value = stdId;
    } catch (e) {
      toast.error(__('An error occurred, please try again!'));
    }
  }

  async function autoFillFields() {
    try {
      const result = await axios.post(route('redesign.std.get.attributes'), {
        std_id: selectedAttribute.value
      });
      result.data.data.details.job.title = props.data.job.title;
      emit('jobDataEmitUpdate', result.data);
      toast.success(__('Fields autofilled successfully.'));
    } catch (e) {
      toast.error(e.response.data.message);
    }
  }

  async function updateStdJobTitle(value) {
    if (!value) {
      return;
    }

    if (value.id === 'custom-option') {
      const newOption = await createStandardisedTitle(value.name);
      props.data.job.std_title = newOption;
    } else {
      await stdHasAttributes(value);
    }
  }

  function handleJobTitleInput(event) {
    const value = event.target.value.replace(/[^a-zA-Z0-9 .,\-;&]/g, '');
    event.target.value = value;
    props.data.job.title = value;
  }
</script>
