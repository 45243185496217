<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Your Companies')">
      <template #icon>
        <Icon
          class="text-3xl text-primary"
          name="apartment"
        />
      </template>
      <template #button>
        <Button
          v-if="canCreateCompany"
          class="ml-auto flex items-center gap-1.5 px-5"
          type="button"
          @click="openModal"
        >
          <span>{{ __('Create a New Company') }}</span>
        </Button>
      </template>
    </SectionHeader>

    <div class="shadow-redesign overflow-x-auto rounded-lg bg-white">
      <table class="w-max min-w-full overflow-x-hidden">
        <thead class="border-b-8">
            <tr>
                <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                    {{ __('Name') }}
                </th>
                <!-- <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                    {{ __('Country') }}
                </th> -->
                <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                    {{ __('Your role') }}
                </th>
                <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                    {{ __('Action') }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(company, index) in companies" 
                :key="index"
                class="border-b"
            >
                <td class="px-8 py-4 text-zinc-600">
                  <div class="flex items-center gap-4">
                    <img
                      class="h-12 w-12 rounded-full border border-zinc-200"
                      :src="company.logo"
                      :alt="__('Profile picture')"
                    />

                    <div class="space-y-0.5">
                      <span class="block text-sm font-medium text-zinc-800">
                        {{ company.name }}
                      </span>
                    </div>
                  </div>
                </td>
                <!-- <td class="px-8 py-4 text-zinc-600 text-xs">
                      <span>
                        {{ company.country }}
                      </span>
                </td> -->
                <td class="px-8 py-4 text-zinc-600 text-xs">
                      <span>
                        {{ company.role }}
                      </span>
                </td>
                <td class="px-8 py-4 text-zinc-600">
                  <span v-if="!company.suspended">
                    <Button
                      class="flex items-center justify-center gap-1.5"
                      :disabled="company.active"
                      type="button"
                      @click="handleSignInMultiCompany(company.id)"
                    >
                      <span>{{ __('Sign In') }}</span>
                    </Button>
                  </span>
                  <span v-else>
                    <Button
                      disabled
                      type="button"
                      class="flex items-center justify-center gap-1.5 bg-secondary/10"
                      style="color: black;"
                    >
                      <span>{{ __('Suspended') }}</span>
                    </Button>
                  </span>
                </td>
            </tr>
        </tbody>
      </table>
    </div>
    
    <RegisterCompanyModal 
      ref="registerCompanyModal" 
      @update-companies="updateCompanies"
    />
  </div>
</template>

<script setup>
  import { reactive, ref } from 'vue'; 
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';
  import { useToast } from 'vue-toastification';
  import RegisterCompanyModal from './components/modal/register-company-modal';

  const toast = useToast();

  const props = defineProps({
    companies: {
      required: true,
      type: Object
    },
    canCreateCompany: {
      required: true,
      type: Boolean
    }
  });

  const companies = reactive({ ...props.companies });

  const registerCompanyModal = ref(null);
  const openModal = () => registerCompanyModal.value?.openModal();

  async function handleSignInMultiCompany(id)
  {
    try {
      const response = await axios.post(
        route('company.multiCompanySignIn', {
          'id': id
        })
      );

      if (response?.data?.redirect_url) {
        window.location.href = response.data.redirect_url;
        return;
      }
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }
  }

  const updateCompanies = (newCompany) => {
    const newKey = Object.keys(companies).length + 1;
    companies[newKey] = newCompany;
  };

</script>