<template>
  <div v-if="isLoadingFetch" class="flex items-center gap-1.5 text-zinc-500">
    <Icon class="animate-spin text-xl" name="progress_activity" />
    <span>{{ __('Loading...') }}</span>
  </div>

  <form v-else class="space-y-8" @submit.prevent="handleSubmit">
    <div class="space-y-8">
      <InputButtonGroup
        v-if="fields.availability.visible"
        id="availability"
        v-model:value="fields.availability.value"
        v-model:errors="fields.availability.errors"
        :label="__('When would you like to start your new job?')"
        :description="__('Pick one option from the list below.')"
        :required="fields.availability.mandatory"
        :options="fields.availability.options"
      />

      <div
        v-if="
          fields.currency.visible ||
          fields.format.visible ||
          fields.wage.visible
        "
        class="space-y-4"
      >
        <span class="block font-medium text-zinc-800 lg:text-lg">
          {{ __('How much would you like to earn?') }}
        </span>

        <div
          v-if="fields.currency.visible || fields.format.visible"
          class="flex flex-wrap gap-8"
        >
          <InputRadio
            v-if="fields.currency.visible"
            id="currency"
            v-model:value="fields.currency.value"
            v-model:errors="fields.currency.errors"
            :label="__('Currency')"
            :required="fields.currency.mandatory"
            :options="fields.currency.options"
          />

          <InputRadio
            v-if="fields.format.visible"
            id="format"
            v-model:value="fields.format.value"
            v-model:errors="fields.format.errors"
            :label="__('Salary Format')"
            :required="fields.format.mandatory"
            :options="fields.format.options"
          />

          <InputRadio
            v-if="fields.salary_type.visible"
            id="salary_type"
            v-model:value="fields.salary_type.value"
            v-model:errors="fields.salary_type.errors"
            :label="__('Per')"
            :required="fields.salary_type.mandatory"
            :options="fields.salary_type.options"
          />
        </div>

        <InputSlider
          v-if="fields.wage.visible"
          id="wage"
          v-model:value="fields.wage.value"
          v-model:errors="fields.wage.errors"
          :label="__('Desired Wage')"
          :required="fields.wage.mandatory"
          :currency="fields.currency.value?.name"
          :max="10000"
          :interval="100"
          class="w-1/2"
        />
      </div>
    </div>

    <div class="flex items-center justify-between">
      <Button
        class="flex items-center gap-1.5"
        variant="ghost"
        @click="handleBackButtonClick"
      >
        <Icon class="text-lg" name="arrow_back_ios_new" />
        <span>{{ __('Go Back') }}</span>
      </Button>

      <Button class="ml-auto flex items-center gap-1.5" type="submit">
        <Icon
          v-if="isLoadingUpdate"
          class="animate-spin text-lg"
          name="progress_activity"
        />

        <span>{{ __('Next') }}</span>
      </Button>
    </div>
  </form>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputButtonGroup } from '@/components/form/input-button-group';
  import { InputRadio } from '@/components/form/input-radio';
  import { InputSlider } from '@/components/form/input-slider';

  const emits = defineEmits(['back', 'next']);

  const toast = useToast();

  const fields = ref({
    availability: {
      visible: false,
      mandatory: false,
      value: null,
      errors: [],
      options: []
    },
    currency: {
      visible: false,
      mandatory: false,
      value: null,
      errors: [],
      options: []
    },
    format: {
      visible: false,
      mandatory: false,
      value: null,
      errors: [],
      options: []
    },
    salary_type: {
      visible: false,
      mandatory: false,
      value: null,
      errors: [],
      options: []
    },
    wage: {
      visible: false,
      mandatory: false,
      value: 0,
      errors: []
    }
  });
  const isLoadingFetch = ref(false);
  const isLoadingUpdate = ref(false);

  function isValid(value) {
    if (!value) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'object') {
      for (const prop in value) {
        if (value[prop] === null) {
          return false;
        }
      }
      return true;
    }

    return true;
  }

  async function fetchData() {
    isLoadingFetch.value = true;

    try {
      const response = await axios.get(
        route('onboarding.step', { slug: 'job' })
      );

      Object.entries(response.data).forEach(([key, value]) => {
        if (fields.value[key]) {
          fields.value[key] = { ...fields.value[key], ...value };
        }
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingFetch.value = false;
  }

  function handleBackButtonClick() {
    emits('back');
  }

  async function handleSubmit() {
    const requiredFieldKeys = Object.keys(fields.value).filter(
      (key) => fields.value[key].visible && fields.value[key].mandatory
    );
    let firstInvalidField;

    requiredFieldKeys.forEach((key) => {
      if (!isValid(fields.value[key].value)) {
        fields.value[key].errors = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstInvalidField) {
          firstInvalidField = element;
        }
      }
    });

    if (
      Object.values(fields.value).some(
        (field) => field.errors && field.errors.length
      )
    ) {
      if (firstInvalidField) {
        firstInvalidField.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
      return;
    }

    const payload = Object.keys(fields.value).reduce((acc, key) => {
      if (fields.value[key].visible) {
        acc[key] = fields.value[key].value;
      }
      return acc;
    }, {});

    isLoadingUpdate.value = true;

    try {
      await axios.post(route('onboarding.step', { slug: 'job' }), payload);

      emits('next');
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).forEach((key) => {
          if (fields.value[key]) {
            fields.value[key].errors = error.response.data.errors[key];
          }
        });
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingUpdate.value = false;
  }

  onMounted(async () => {
    await fetchData();
  });
</script>
