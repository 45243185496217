<template>
  <div v-if="isLoadingFetch" class="flex items-center gap-1.5 text-zinc-500">
    <Icon class="animate-spin text-xl" name="progress_activity" />
    <span>{{ __('Loading...') }}</span>
  </div>

  <div v-else class="space-y-6 md:space-y-8">
    <div class="space-y-2.5">
      <div class="flex gap-2 md:gap-4">
        <Icon
          class="text-[2rem] text-primary md:text-[2.5rem]"
          name="business_center"
          is-filled
        />
        <h1 class="text-2xl font-medium text-zinc-800 md:text-4xl">
          {{ __('What type of job are you looking for?') }}
        </h1>
      </div>

      <p class="text-sm font-medium text-zinc-800 lg:text-lg">
        {{ __('Tell us more about your career preferences.') }}
      </p>
    </div>

    <form class="space-y-8" @submit.prevent="handleSubmit">
      <div class="grid gap-4 md:grid-cols-2">
        <InputCareers
          v-if="fields.role.visible"
          id="role"
          v-model:value="fields.role.value"
          v-model:errors="fields.role.errors"
          :label="__('What role are you seeking?')"
          :placeholder="__('Add desired role')"
          :required="fields.role.mandatory"
          is-new-options-allowed
        />
        <InputCombobox
          v-if="fields.type.visible"
          id="type"
          v-model:value="fields.type.value"
          v-model:errors="fields.type.errors"
          :label="__('Select type of job:')"
          :placeholder="__('Add job type')"
          :options="fields.type.options"
          :required="fields.type.mandatory"
        />
        <InputLocation
          v-if="fields.location.visible"
          v-model:value="fields.location.value"
          v-model:errors="fields.location.errors"
          :label-country="__(`Select the country where you'd like to work`)"
          :label-city="__(`Select the city where you'd like to work`)"
          :placeholder-country="__('Add country')"
          :placeholder-city="__('Add city')"
          :options="fields.location.options"
          :required="fields.location.mandatory"
          :default-country="fields.location.default_country"
          class="md:col-span-2"
        />
      </div>

      <div class="flex items-center justify-between">
        <Button
          class="flex items-center gap-1.5"
          variant="ghost"
          @click="handleBackButtonClick"
        >
          <Icon class="text-lg" name="arrow_back_ios_new" />
          <span>{{ __('Go Back') }}</span>
        </Button>

        <Button class="ml-auto flex items-center gap-1.5" type="submit">
          <Icon
            v-if="isLoadingUpdate"
            class="animate-spin text-lg"
            name="progress_activity"
          />

          <span>{{ __('Next') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputCareers } from '@/components/form/input-careers';
  import { InputCombobox } from '@/components/form/input-combobox';
  import { InputLocation } from '@/components/form/input-location';

  const emits = defineEmits(['back', 'next']);

  const toast = useToast();

  const fields = ref({
    role: {
      visible: false,
      mandatory: false,
      value: [],
      errors: [],
      options: []
    },
    type: {
      visible: false,
      mandatory: false,
      value: null,
      errors: [],
      options: []
    },
    location: {
      visible: false,
      mandatory: false,
      default_country: null,
      value: [],
      errors: [],
      options: []
    }
  });
  const isLoadingFetch = ref(false);
  const isLoadingUpdate = ref(false);

  function isValid(value) {
    if (!value) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'object') {
      for (const prop in value) {
        if (value[prop] === null) {
          return false;
        }
      }
      return true;
    }

    return true;
  }

  async function fetchData() {
    isLoadingFetch.value = true;

    try {
      const response = await axios.get(
        route('onboarding.step', { slug: 'career' })
      );

      Object.entries(response.data).forEach(([key, value]) => {
        if (fields.value[key]) {
          fields.value[key] = { ...fields.value[key], ...value };
        }
      });
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoadingFetch.value = false;
  }

  function handleBackButtonClick() {
    emits('back');
  }

  async function handleSubmit() {
    const requiredFieldKeys = Object.keys(fields.value).filter(
      (key) => fields.value[key].visible && fields.value[key].mandatory
    );
    let firstInvalidField;

    requiredFieldKeys.forEach((key) => {
      if (!isValid(fields.value[key].value)) {
        fields.value[key].errors = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstInvalidField) {
          firstInvalidField = element;
        }
      }
    });

    if (
      Object.values(fields.value).some(
        (field) => field.errors && field.errors.length
      )
    ) {
      if (firstInvalidField) {
        firstInvalidField.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
      return;
    }

    isLoadingUpdate.value = true;

    const customCareerOptions = fields.value.role.value.filter(
      (career) => career.id === 'custom-option'
    );

    if (customCareerOptions.length) {
      try {
        const response = await axios.post(route('redesign.cv.career.create'), {
          careers: customCareerOptions
        });

        fields.value.role.value = fields.value.role.value.filter(
          (career) => career.id !== 'custom-option'
        );

        fields.value.role.value = [
          ...fields.value.role.value,
          ...response.data.careers
        ];
      } catch {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    const payload = Object.keys(fields.value).reduce((acc, key) => {
      if (fields.value[key].visible) {
        acc[key] = fields.value[key].value;
      }
      return acc;
    }, {});

    try {
      await axios.post(route('onboarding.step', { slug: 'career' }), payload);

      emits('next');
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).forEach((key) => {
          if (fields.value[key]) {
            fields.value[key].errors = error.response.data.errors[key];
          }
        });
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingUpdate.value = false;
  }

  onMounted(async () => {
    await fetchData();
  });
</script>
