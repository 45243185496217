<template>
  <div>
    <div v-if="jobs.length > 0">
      <!-- TODO: Modify the with modal opening -->
      <a
        v-for="job in jobs"
        @click="openModal(job)"
        v-bind:key="job.id"
        tabindex="0"
        class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
      >
        <JobCard
          :job="job"
          :user_type="user_type"
          :config_general_single_company="config_general_single_company"
        />
      </a>
    </div>

    <div v-else>{{ __("No results") }}</div>
  </div>
</template>

<script setup>
import {
  Button,
  Icon,
  Input,
  Textarea,
  FormMultiselect,
} from "../../../../components/global";
import { __ } from "../../../../helpers";
import { JobOverview } from "../components";
import { JobCard } from "../components";
const props = defineProps({
  jobs: {
    type: Array,
    required: true,
  },
  user_type: {
    type: String,
    required: true,
  },
  config_general_single_company: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["openModal"]);

const openModal = (job) => {
  emit("openModal", job);
};
</script>
