<template>
  <div class="space-y-12">
    <h1 class="text-5xl font-medium text-zinc-800">{{ __('Settings') }}</h1>

    <TabGroup>
      <TabList class="flex gap-2 overflow-auto rounded-lg bg-white p-3">
        <Tab v-slot="{ selected }" as="template">
          <button
            :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
          >
            {{ __('Profile Settings') }}
          </button>
        </Tab>
        <Tab v-if="displayIntegrations" v-slot="{ selected }" as="template">
          <button
            :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
          >
            {{ __('Company Settings') }}
          </button>
        </Tab>
        <Tab v-if="displayCompanyAccounts" v-slot="{ selected }" as="template">
          <button
            :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
          >
            {{ __('Company Accounts') }}
          </button>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel class="space-y-12">
          <Profile :profile="profile" :options="options" />
          <Password />
          <InternalEmails v-if="isCompanyOwner" :domains="domains" />
        </TabPanel>
        <TabPanel v-if="displayIntegrations" class="space-y-12">
          <Integrations  />
        </TabPanel>
        <TabPanel v-if="displayCompanyAccounts" class="space-y-12">
          <CompanyAccounts :companies="companies" :canCreateCompany="canCreateCompany"/>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
  import { Integrations } from './components/integrations';
  import { Password } from './components/password';
  import { Profile } from './components/profile';
  import { InternalEmails } from './components/internal-emails';
  import { CompanyAccounts } from './components/company-accounts';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    }
  });

  const profile = computed(() => {
    return props.data.recruiter;
  });

  const domains = computed( () => {
    return props.data.domains
  });

  const options = computed(() => {
    return {
      preffered_language: props.data.languages,
      country_prefix: props.data.phoneCodes
    };
  });

  const companies = computed( () => {
    return props.data.companies
  });

  const canCreateCompany = computed(() => {
    return props.data.canCreateCompany;
  });

  const displayIntegrations = computed(() => {
    return props.data.display_integrations;
  });

  const isCompanyOwner = computed( () => {
    return props.data.isCompanyOwner;
  });

  const displayCompanyAccounts = computed(() => {
    return props.data.display_company_accounts;
  });
</script>
