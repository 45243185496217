<template>
  <Modal
    :open="open"
    :title="title"
    :description="description"
    @close="handleCancelButtonClick"
  >
    <div class="flex flex-col gap-2 md:flex-row-reverse">
      <Button
        :disabled="isLoading"
        class="flex items-center justify-center gap-1.5"
        @click="handleContinueButtonClick"
      >
        <Icon
          v-if="isLoading"
          class="animate-spin text-lg"
          name="progress_activity"
        />
        <span>{{ __(isLoading ? 'Loading...' : 'Continue') }}</span>
      </Button>

      <Button
        :disabled="isLoading"
        variant="ghost"
        @click="handleCancelButtonClick"
      >
        <span>{{ __('Cancel') }}</span>
      </Button>
    </div>
  </Modal>
</template>

<script setup>
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Modal } from '@/components/block/modal';

  defineProps({
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['cancel', 'continue']);

  function handleCancelButtonClick() {
    emits('cancel');
  }

  function handleContinueButtonClick() {
    emits('continue');
  }
</script>
