<template>
  <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
    <form @submit.prevent="handleSubmit">
      <div class="space-y-10">
        <div class="space-y-4">
          <UploadImage
            v-model:value="values.profile_photo"
            v-model:file="files.profile"
            v-model:errors="errors.profile_photo"
          />

          <div class="grid gap-4 md:grid-cols-2">
            <InputText
              id="first_name"
              v-model:value="values.first_name"
              v-model:errors="errors.first_name"
              :label="__('First name')"
              :placeholder="__('First name')"
              required
            />

            <InputText
              id="last_name"
              v-model:value="values.last_name"
              v-model:errors="errors.last_name"
              :label="__('Last name')"
              :placeholder="__('Last name')"
              required
            />

            <InputText
              id="email"
              :value="values.email"
              :errors="errors.email"
              :label="__('Email')"
              :placeholder="__('Email')"
              disabled
            >
              <template #icon>
                <Icon class="text-lg text-neutral-400" name="mail" is-filled />
              </template>
            </InputText>

            <div class="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5">
              <InputSelect
                id="country_prefix"
                v-model:value="values.country_prefix"
                v-model:errors="errors.country_prefix"
                class="col-span-1 sm:col-span-2"
                :label="__('Country prefix')"
                :placeholder="__('Country prefix')"
                :options="options.country_prefix"
              />

              <InputText
                id="phone"
                v-model:value="values.phone"
                v-model:errors="errors.phone"
                class="col-span-1 sm:col-span-3"
                :label="__('Phone number')"
                :placeholder="__('Phone number')"
              >
                <template #icon>
                  <Icon
                    class="text-lg text-neutral-400"
                    name="phone"
                    is-filled
                  />
                </template>
              </InputText>
            </div>

            <InputSelect
              id="career_status"
              v-model:value="values.career_status"
              v-model:errors="errors.career_status"
              :label="__('Career status')"
              :placeholder="__('Career status')"
              :options="options.career_status"
            >
              <template #icon>
                <Icon class="text-lg text-neutral-400" name="work" is-filled />
              </template>
            </InputSelect>

            <InputDate
              id="birthday"
              v-model:value="values.birthday"
              v-model:errors="errors.birthday"
              :label="__('Birth date')"
              :placeholder="__('Pick a date')"
            />

            <InputTextarea
              id="personal_quote"
              v-model:value="values.personal_quote"
              v-model:errors="errors.personal_quote"
              class="md:col-span-2"
              :label="__('Description')"
              :placeholder="
                __(
                  'You can write about your years of experience, industry, achievements or skills.'
                )
              "
            />

            <InputLocation
              id="current_location"
              v-model:value="values.current_location"
              v-model:errors="errors.current_location"
              :label-country="__('Country of residence')"
              :label-city="__('City of residence')"
              :placeholder-country="__('Select country')"
              :placeholder-city="__('Select city')"
              :options="options.country"
              class="md:col-span-2"
            />

            <div class="relative">
              <InputSelect
                id="nationality"
                v-model:value="values.nationality.value"
                v-model:errors="errors.nationality"
                :label="__('Nationality')"
                :placeholder="__('Nationality')"
                :options="options.nationality"
              >
                <template #icon>
                  <Icon
                    class="text-lg text-neutral-400"
                    name="home_pin"
                    is-filled
                  />
                </template>
              </InputSelect>

              <button
                class="absolute right-0 top-[3px] flex items-center gap-1 text-zinc-600"
                type="button"
                @click="handleVisibilityButtonClick('nationality')"
              >
                <span class="text-xs">
                  {{
                    __(
                      `${values.nationality.visible ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                    )
                  }}
                </span>
                <Icon
                  class="text-lg"
                  :name="
                    values.nationality.visible ? 'visibility' : 'visibility_off'
                  "
                />
              </button>
            </div>

            <div class="relative">
              <InputSelect
                id="ethnicity"
                v-model:value="values.ethnicity.value"
                v-model:errors="errors.ethnicity"
                :label="__('Ethnicity')"
                :placeholder="__('Ethnicity')"
                :options="options.ethnicity"
              >
                <template #icon>
                  <Icon
                    class="text-lg text-neutral-400"
                    name="emoji_flags"
                    is-filled
                  />
                </template>
              </InputSelect>

              <button
                class="absolute right-0 top-[3px] flex items-center gap-1 text-zinc-600"
                type="button"
                @click="handleVisibilityButtonClick('ethnicity')"
              >
                <span class="text-xs">
                  {{
                    __(
                      `${values.ethnicity.visible ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                    )
                  }}
                </span>
                <Icon
                  class="text-lg"
                  :name="
                    values.ethnicity.visible ? 'visibility' : 'visibility_off'
                  "
                />
              </button>
            </div>

            <div class="relative">
              <InputSelect
                id="gender"
                v-model:value="values.gender.value"
                v-model:errors="errors.gender"
                :label="__('Gender identity')"
                :placeholder="__('Gender identity')"
                :options="options.gender"
              >
                <template #icon>
                  <Icon
                    class="text-lg text-neutral-400"
                    name="female"
                    is-filled
                  />
                </template>
              </InputSelect>

              <button
                class="absolute right-0 top-[3px] flex items-center gap-1 text-zinc-600"
                type="button"
                @click="handleVisibilityButtonClick('gender')"
              >
                <span class="text-xs">
                  {{
                    __(
                      `${values.gender.visible ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                    )
                  }}
                </span>
                <Icon
                  class="text-lg"
                  :name="
                    values.gender.visible ? 'visibility' : 'visibility_off'
                  "
                />
              </button>
            </div>

            <div class="relative">
              <InputSelect
                id="pronoun"
                v-model:value="values.pronoun.value"
                v-model:errors="errors.pronoun"
                :label="__('Pronouns')"
                :placeholder="__('Pronouns')"
                :options="options.pronoun"
              >
                <template #icon>
                  <Icon
                    class="text-lg text-neutral-400"
                    name="man_3"
                    is-filled
                  />
                </template>
              </InputSelect>

              <button
                class="absolute right-0 top-[3px] flex items-center gap-1 text-zinc-600"
                type="button"
                @click="handleVisibilityButtonClick('pronoun')"
              >
                <span class="text-xs">
                  {{
                    __(
                      `${values.pronoun.visible ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                    )
                  }}
                </span>
                <Icon
                  class="text-lg"
                  :name="
                    values.pronoun.visible ? 'visibility' : 'visibility_off'
                  "
                />
              </button>
            </div>

            <div class="relative">
              <InputMultiSelect
                id="vulnerability"
                v-model:values="values.vulnerability.value"
                v-model:errors="errors.vulnerability"
                :label="__('Vulnerabilities')"
                :placeholder="__('Vulnerabilities')"
                :options="options.vulnerability"
              >
                <template #icon>
                  <Icon class="text-lg text-neutral-400" name="psychiatry" />
                </template>
              </InputMultiSelect>

              <button
                class="absolute right-0 top-[3px] flex items-center gap-1 text-zinc-600"
                type="button"
                @click="handleVisibilityButtonClick('vulnerability')"
              >
                <span class="text-xs">
                  {{
                    __(
                      `${values.vulnerability.visible ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                    )
                  }}
                </span>
                <Icon
                  class="text-lg"
                  :name="
                    values.vulnerability.visible
                      ? 'visibility'
                      : 'visibility_off'
                  "
                />
              </button>
            </div>

            <div class="relative">
              <InputMultiSelect
                id="disability"
                v-model:values="values.disability.value"
                v-model:errors="errors.disability"
                :label="__('Disabilities')"
                :placeholder="__('Disabilities')"
                :options="options.disability"
              >
                <template #icon>
                  <Icon
                    class="text-lg text-neutral-400"
                    name="accessibility"
                    is-filled
                  />
                </template>
              </InputMultiSelect>

              <button
                class="absolute right-0 top-[3px] flex items-center gap-1 text-zinc-600"
                type="button"
                @click="handleVisibilityButtonClick('disability')"
              >
                <span class="text-xs">
                  {{
                    __(
                      `${values.disability.visible ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                    )
                  }}
                </span>
                <Icon
                  class="text-lg"
                  :name="
                    values.disability.visible ? 'visibility' : 'visibility_off'
                  "
                />
              </button>
            </div>

            <div class="relative">
              <InputSelect
                id="mobility_degree"
                v-model:value="values.mobility_degree.value"
                v-model:errors="errors.mobility_degree"
                :label="__('Mobility degree')"
                :placeholder="__('Mobility degree')"
                :options="options.mobility_degree"
              >
                <template #icon>
                  <Icon
                    class="text-lg text-neutral-400"
                    name="assist_walker"
                    is-filled
                  />
                </template>
              </InputSelect>

              <button
                class="absolute right-0 top-[3px] flex items-center gap-1 text-zinc-600"
                type="button"
                @click="handleVisibilityButtonClick('mobility_degree')"
              >
                <span class="text-xs">
                  {{
                    __(
                      `${values.mobility_degree.visible ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                    )
                  }}
                </span>
                <Icon
                  class="text-lg"
                  :name="
                    values.mobility_degree.visible
                      ? 'visibility'
                      : 'visibility_off'
                  "
                />
              </button>
            </div>

            <div class="relative">
              <InputToggle
                id="assistive_device"
                v-model:value="values.assistive_device.value"
                v-model:errors="errors.assistive_device"
                :label="__('Assistive device')"
              >
                <template #label-end>
                  <span class="block text-zinc-800">
                    {{ __(values.assistive_device.value ? 'Yes' : 'No') }}
                  </span>
                </template>
              </InputToggle>

              <button
                class="absolute right-0 top-[3px] flex items-center gap-1 text-zinc-600"
                type="button"
                @click="handleVisibilityButtonClick('assistive_device')"
              >
                <span class="text-xs">
                  {{
                    __(
                      `${values.assistive_device.visible ? 'Visible for recruiters' : 'Not visible for recruiters'}`
                    )
                  }}
                </span>
                <Icon
                  class="text-lg"
                  :name="
                    values.assistive_device.visible
                      ? 'visibility'
                      : 'visibility_off'
                  "
                />
              </button>
            </div>
          </div>
        </div>

        <div class="space-y-2">
          <h3 class="text-lg font-medium text-zinc-800">
            {{ __('CV Upload') }}
          </h3>

          <div class="space-y-4">
            <template v-if="files.resume">
              <a
                v-if="files.resume.path"
                class="group -mx-4 flex w-fit items-center gap-1.5 px-4 py-2 text-primary"
                :href="files.resume.path"
                target="_blank"
              >
                <Icon class="text-xl" name="picture_as_pdf" is-filled />
                <span
                  class="text-sm font-medium group-hover:underline group-hover:underline-offset-2"
                >
                  {{ files.resume.name }}
                </span>
              </a>

              <div
                v-else
                class="-mx-4 flex w-fit items-center gap-1.5 px-4 py-2 text-primary"
              >
                <Icon class="text-xl" name="picture_as_pdf" is-filled />
                <span class="text-sm font-medium">
                  {{ files.resume.name }}
                </span>
              </div>
            </template>

            <span v-else class="block leading-tight text-zinc-600">
              {{ __('No CV uploaded') }}
            </span>

            <span class="block text-sm text-zinc-600">
              {{
                resume.canUpload
                  ? `${__('Max 1 upload/day')} - pdf, doc, docx, txt, rtf (5MB)`
                  : __(
                      "Looks like you've reached your maximum upload limit for resumes today. Remember, you can upload one resume per day."
                    )
              }}
            </span>

            <div class="flex items-center gap-2">
              <label
                :class="`${!resume.canUpload ? 'pointer-events-none opacity-50' : ''} block w-fit cursor-pointer rounded-md px-4 py-2 text-sm font-medium text-primary ring-1 ring-primary transition-colors hover:bg-primary hover:text-white`"
              >
                <span>
                  {{ __(files.resume ? 'Change Resume' : 'Upload Resume') }}
                </span>
                <input
                  class="sr-only"
                  type="file"
                  accept=".pdf,.doc,.docx"
                  :disabled="!resume.canUpload"
                  @change="handleUploadResumeChange"
                />
              </label>

              <Button
                class="flex items-center gap-1.5"
                variant="ghost"
                :disabled="!files.resume"
                @click="handleDeleteResumeButtonClick"
              >
                <Icon class="text-xl" name="delete" />
                <span>{{ __('Delete') }}</span>
              </Button>
            </div>
            <div v-if="errors.file" class="mt-2 space-y-1">
              <span v-for="(error, index) in errors.file" :key="index" class="block text-xs text-error">
                {{ error }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <span
        v-if="errorMessage"
        class="mt-8 block text-center text-sm text-error"
      >
        {{ errorMessage }}
      </span>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputDate } from '@/components/form/input-date';
  import { InputLocation } from '@/components/form/input-location';
  import { InputMultiSelect } from '@/components/form/input-multi-select';
  import { InputSelect } from '@/components/form/input-select';
  import { InputText } from '@/components/form/input-text';
  import { InputTextarea } from '@/components/form/input-textarea';
  import { InputToggle } from '@/components/form/input-toggle';
  import { UploadImage } from './components/upload-image';
  import axios from "axios";

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    resume: {
      type: Object,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  });

  const emits = defineEmits(['save', 'cancel']);

  const toast = useToast();

  const data = ref(JSON.parse(JSON.stringify(props.data)));
  const values = ref({
    profile_photo: data.value.profile_photo,
    first_name: data.value.first_name || '',
    last_name: data.value.last_name || '',
    email: data.value.email || '',
    country_prefix: data.value.country_prefix?.id
      ? data.value.country_prefix
      : null,
    phone: data.value.phone || '',
    career_status: data.value.career_status,
    birthday: data.value.birthday || '',
    personal_quote: data.value.personal_quote || '',
    current_location: data.value.current_location,
    nationality: data.value.nationality,
    ethnicity: data.value.ethnicity,
    gender: data.value.gender,
    pronoun: data.value.pronoun,
    vulnerability: data.value.vulnerability,
    disability: data.value.disability,
    mobility_degree: data.value.mobility_degree,
    assistive_device: data.value.assistive_device
  });
  const errors = ref({
    profile_photo: [],
    first_name: [],
    last_name: [],
    email: [],
    country_prefix: [],
    phone: [],
    career_status: [],
    birthday: [],
    personal_quote: [],
    current_location: [],
    nationality: [],
    ethnicity: [],
    gender: [],
    pronoun: [],
    vulnerability: [],
    disability: [],
    mobility_degree: [],
    assistive_device: [],
    file: []
  });
  const files = ref({ profile: null, resume: props.resume.file });
  const isLoading = ref(false);
  const reloadPage = ref(false);
  const doDeleteFile = ref(false);
  function handleUploadResumeChange(event) {
    const eventFiles = event.target.files;

    if (!eventFiles || !eventFiles.length) {
      return;
    }

    const file = eventFiles[0];

    files.value.resume = file;
  }

  function handleDeleteResumeButtonClick() {
    files.value.resume = null;
    doDeleteFile.value = true;
  }

  function handleVisibilityButtonClick(key) {
    values.value[key].visible = !values.value[key].visible;
  }

  function handleCancelButtonClick() {
    emits('cancel');
  }

  //if you uploaded/deleted the CV and uploaded the about-me section successfully
  watch(
      () => reloadPage.value && isLoading.value === false,
      (newValue) => {
        if (newValue) {
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        }
      },{ immediate: false }
  );


function validateFields()
  {
    const requiredFieldKeys = ['first_name', 'last_name'];
    let firstInvalidElement = null;

    errors.value = {};

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];
        if (!firstInvalidElement) {
          firstInvalidElement = document.getElementById(key);
        }
      }
    });

    if (
        values.value.current_location &&
        values.value.current_location.country &&
        !values.value.current_location.city
    ) {
      errors.value.current_location = [__('This field is required')];
      if (!firstInvalidElement) {
        firstInvalidElement = document.getElementById('current_location');
      }
    }

    if (Object.keys(errors.value).length > 0) {
      if (firstInvalidElement) {
        firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return false;
    }

    return true;

  }

  async function processCv() {
    try {
      if (files.value.resume && !files.value.resume.path) {
        const formData = new FormData();
              formData.append('file', files.value.resume);

        await axios.post(route('redesign.cv.upload-cv'), formData);
        reloadPage.value = true;
      } else if (doDeleteFile.value) {
        reloadPage.value = true;
        await axios.post(route('redesign.cv.delete-cv'));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || __('Something went wrong while uploading CV.'));
      throw error;
    }
  }

  async function processUserImage()
  {
    if (files.value.profile) {
      try {
        const formData = new FormData();
              formData.append('profile_photo', files.value.profile);

        const response = await axios.post(route('redesign.candidate.settings.uploadPhoto'), formData);
        values.value.profile_photo = response.data.full_path;
      } catch (error) {
        toast.error(error?.response?.data?.message || __('Something went wrong while uploading the profile image.'));
        throw error;
      }
    }
  }
  async function updateProfileData()
  {
    try {
      const response = await axios.put(route('redesign.cv.update.about-me'), values.value);
      toast.success(response?.data?.message || __('Profile updated successfully.'));
      return response.data;
    } catch (err) {
      toast.error(__('Something went wrong while updating profile data.'));
      throw err;
    }
  }
  async function handleSubmit() {
    isLoading.value = true;

    try {
      const fieldsAreValid = await validateFields();
      if (!fieldsAreValid) {
        return;
      }
      await processCv();
      await processUserImage();
      const profileData = await updateProfileData();

      emits('save', {
        data: { ...data.value, ...values.value },
        fields: profileData.fields,
        sectionPercentage: profileData.section_percentage,
        resumePercentage: profileData.percentage
      });

    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      }
    } finally {
      isLoading.value = false;
    }
  }
</script>
