<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Speakers and Hosts')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="mic" is-filled />
      </template>

      <template #button>
        <Button
          variant="ghost"
          class="flex items-center justify-center gap-1.5"
          @click="handleAddButtonClick"
        >
          <Icon class="text-lg" name="add_box" is-filled />
          <span>{{ __('Add new speaker') }}</span>
        </Button>
      </template>
    </SectionHeader>

    <div class="space-y-4">
      <Search
        :values="values"
        :errors="errors"
        :options="options"
        @update:values="handleUpdateValues"
        @update:errors="handleUpdateErrors"
      />

      <div class="space-y-6">
        <Speaker
          v-for="speaker in values"
          :key="speaker.id"
          :speaker="speaker"
          @edit="handleEditButtonClick(speaker)"
          @delete="handleDeleteButtonClick(speaker)"
        />
      </div>

      <SpeakerFormModal
        v-if="isFormModalOpen"
        :speaker="selected"
        @cancel="handleCancelButtonClick"
        @save="handleSaveButtonClick"
      />
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Search } from './components/search';
  import { Speaker } from './components/speaker';
  import { SpeakerFormModal } from './components/speaker-form-modal';

  const props = defineProps({
    values: {
      type: Array,
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  });

  const emits = defineEmits(['update:values', 'update:errors']);

  const selected = ref(null);
  const isFormModalOpen = ref(false);

  function handleAddButtonClick() {
    selected.value = null;
    isFormModalOpen.value = true;
  }

  function handleEditButtonClick(speaker) {
    selected.value = JSON.parse(JSON.stringify(speaker));
    isFormModalOpen.value = true;
  }

  function handleCancelButtonClick() {
    selected.value = null;
    isFormModalOpen.value = false;
  }

  function handleSaveButtonClick(speaker) {
    let values = JSON.parse(JSON.stringify(props.values));
    const existingSpeaker = values.find((item) => item.id === speaker.id);

    if (existingSpeaker) {
      values = values.map((item) => {
        if (item.id === speaker.id) {
          return speaker;
        }

        return item;
      });
    } else {
      values = [...values, speaker];
    }

    isFormModalOpen.value = false;

    emits('update:values', values);
  }

  function handleDeleteButtonClick(speaker) {
    emits(
      'update:values',
      props.values.filter((item) => item.id !== speaker.id)
    );
  }

  function handleUpdateValues(values) {
    emits('update:values', values);
  }

  function handleUpdateErrors(errors) {
    emits('update:errors', errors);
  }
</script>
