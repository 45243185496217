<template>
  <div class="mt-4 flex gap-4 items-center">
    <a :href="route('redesign.company.jobs.edit', job.id)" target="_BLANK">
      <Button content="Edit job" variation="fill" />
    </a>
    <Toggle @change="handleToggle" :value="job.active" />
  </div>
</template>

<script setup>
import { Button, Toggle } from "../../../../components/global";
import axios from "axios";
import { useLoading } from "vue-loading-overlay";
import { useToast } from "vue-toastification";
import { __ } from "../../../../helpers";

const toast = useToast();
const props = defineProps({
  job: {
    type: Object,
    required: true,
  },
});

const $loading = useLoading();

const handleToggle = () => {
  if (props.job.active) {
    const confirm = window.confirm(
      __("Are you sure you want to inactivate this job?") +
        "\n\n" +
        __(
          "Please note that if you proceed, all unanswered applications will be automatically rejected, and an email will be sent to inform candidates that the job is no longer active. "
        )
    );

    if (!confirm) {
      return;
    }
  }
  const loader = $loading.show();

  axios
    .post(route("redesign.company.job.toggle", { job: props.job.id }), {
      active: !props.job.active,
    })
    .then((response) => {
      toast.success(response.data.message);
      props.job.active = !props.job.active;

      loader.hide();
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      loader.hide();
    });
};
</script>
