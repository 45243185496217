<template>
  <div
    class="space-y-6 rounded-2xl bg-gradient-to-b from-zinc-100 to-transparent p-4"
  >
    <div class="space-y-2">
      <span class="block text-zinc-800">
        <span class="text-lg font-medium">
          {{ field }}
        </span>
        @
        <span>{{ name }}</span>
      </span>

      <span class="block text-sm text-zinc-600">{{ date }}</span>
    </div>

    <div v-if="level || location" class="flex flex-wrap gap-2">
      <span
        v-if="level"
        class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
      >
        {{ level }}
      </span>

      <span
        v-if="location"
        class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
      >
        {{ location }}
      </span>
    </div>

    <p v-if="description" class="leading-normal text-zinc-600">
      <span>{{ description }}</span>

      <button
        v-if="isDescriptionExpandButtonVisible"
        class="text-primary underline-offset-2 hover:underline"
        @click="handleDescriptionExpandButtonClick"
      >
        {{ __('see more') }}
      </button>
    </p>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { __ } from '@/helpers';
  import { formatDateToMonthYear } from '@/scripts/date.script';

  const props = defineProps({
    education: {
      type: Object,
      required: true
    }
  });

  const name = computed(() => {
    return props.education.name;
  });

  const field = computed(() => {
    return props.education.field;
  });

  const date = computed(() => {
    const isCurrentlyStudyingHere = props.education.current;
    const startDate = props.education.start_date
      ? new Date(props.education.start_date)
      : '-';
    const endDate = isCurrentlyStudyingHere
      ? new Date()
      : props.education.end_date
        ? new Date(props.education.end_date)
        : '-';

    let years =
      startDate === '-' || endDate === '-'
        ? '0'
        : endDate.getFullYear() - startDate.getFullYear();
    let months =
      startDate === '-' || endDate === '-'
        ? '0'
        : endDate.getMonth() - startDate.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    if (months > 0) {
      years++;
    }

    return `${startDate === '-' ? __('Date not set') : formatDateToMonthYear(startDate)} - ${
      isCurrentlyStudyingHere
        ? __('Present')
        : endDate === '-'
          ? __('Date not set')
          : formatDateToMonthYear(endDate)
    } (${years} ${__('years')})`;
  });

  const level = computed(() => {
    return props.education.level ? props.education.level.name : '';
  });

  const location = computed(() => {
    return props.education.location ? props.education.location.name : '';
  });

  const description = computed(() => {
    if (!props.education.description) {
      return '';
    }

    return props.education.description.length <= 250 ||
      isDescriptionExpanded.value
      ? props.education.description
      : `${props.education.description.substring(0, 250)}...`;
  });

  const isDescriptionExpandButtonVisible = computed(() => {
    if (!props.education.description) {
      return false;
    }

    return (
      props.education.description.length > 250 && !isDescriptionExpanded.value
    );
  });

  const isDescriptionExpanded = ref(false);

  function handleDescriptionExpandButtonClick() {
    isDescriptionExpanded.value = true;
  }
</script>
