<template>
  <div>
    <label v-if="notification.type == 'App\\Notifications\\NewMessage'">
      <div class="candidate-gamification__news--visual">
        <img
          v-if="notification.img"
          :src="notification.img"
          width="50"
          height="50"
          style="border-radius: 50%"
        />
        <strong>{{ notification.data.sender }}</strong>
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a :href="this.messages_route" class="btn gray small">{{
            __("View message")
          }}</a>
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label
      v-if="notification.type == 'App\\Notifications\\FriendRequestReceived'"
    >
      <div class="candidate-gamification__news--visual">
        <img
          :src="notification.img"
          alt=""
          width="50"
          height="50"
          style="border-radius: 50%"
        />
        <strong>{{ notification.user }}</strong>
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a :href="this.friends_index_route" class="btn gray small"
            >{{ __("Accept") }} / {{ __("Ignore") }}</a
          >
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\InvitedToJob'">
      <div class="candidate-gamification__news--visual">
        <img
          :src="notification.img"
          alt=""
          width="50"
          height="50"
          style="border-radius: 50%"
        />
        <strong>{{ notification.company }}</strong>
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a :href="this.roles_opportunities_route" class="btn gray small">{{
            __("Accept")
          }}</a>
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\AcademyInvite'">
      <div class="candidate-gamification__news--visual">
        <img
          :src="notification.img"
          alt=""
          width="50"
          height="50"
          style="border-radius: 50%"
        />
        <strong>{{ notification.company }}</strong>
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a
            v-if="notification.url"
            :href="notification.url"
            class="btn gray small"
            >{{ __("See course") }}</a
          >
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\ChallengeInvite'">
      <div class="candidate-gamification__news--visual">
        <img
          :src="notification.img"
          alt=""
          width="50"
          height="50"
          style="border-radius: 50%"
        />
        <strong>{{ notification.company }}</strong>
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a
            v-if="notification.url"
            :href="notification.url"
            class="btn gray small"
            >{{ __("See challenge") }}</a
          >
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\MatchMade'">
      <div class="candidate-gamification__news--visual match">
        <img
          :src="user.profile_photo_src"
          alt=""
          width="50"
          height="50"
          style="border-radius: 50%"
        />
        <div class="match-icon">
          <img src="/images/front/circle-done-red.svg" alt="" />
        </div>
        <img
          :src="notification.img"
          alt=""
          width="50"
          height="50"
          style="border-radius: 50%"
        />
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a :href="this.messages_route" class="btn gray small">{{
            __("Write a message")
          }}</a>
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\AcademyCompleted'">
      <div class="candidate-gamification__news--visual">
        <span class="iconH" style="fill: #e95b63">
          <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.2 20.2"
            height="40"
          >
            <use xlink:href="/images/front/r_SVG-sprite.svg#house_small"></use>
          </svg>
        </span>
        <strong>{{ __("Course") }}</strong>
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\ChallengeAnswered'">
      <div class="candidate-gamification__news--visual">
        <span class="iconH" style="fill: #e95b63">
          <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.2 20.2"
            height="40"
          >
            <use xlink:href="/images/front/r_SVG-sprite.svg#briefcase"></use>
          </svg>
        </span>
        <strong>{{ __("Challenge") }}</strong>
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\AppliedToJob'">
      <div class="candidate-gamification__news--visual">
        <span class="iconH" style="fill: #e95b63">
          <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.2 20.2"
            height="40"
          >
            <use xlink:href="/images/front/r_SVG-sprite.svg#briefcase"></use>
          </svg>
        </span>
        <strong>{{ __("Job application") }}</strong>
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\BadgeEarned'">
      <div class="candidate-gamification__news--visual">
        <img :src="notification.img" alt="" height="50" width="50" />
        <strong>{{ __("Badge") }}</strong>
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\LeveledUp'">
      <div class="candidate-gamification__news--visual">
        <div class="lvlup">
          <small>+1! <br />{{ __("Level") }}</small>
          {{ __("Up") }}!
        </div>
        <strong>{{ __("Leveled Up") }}</strong>
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\NewShopItem'">
      <div class="candidate-gamification__news--visual">
        <img
          src="/images/front/news-tab-placeholder.jpg"
          alt=""
          class="banner-image"
        />
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a
            v-if="this.page_shop_active"
            :href="this.page_shop_url"
            class="btn gray small"
            >{{ __("Browse shop") }}</a
          >
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\NewJob'">
      <div class="candidate-gamification__news--visual">
        <img
          src="/images/front/news-tab-placeholder.jpg"
          alt=""
          class="banner-image"
        />
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a
            v-if="notification.url"
            :href="notification.url"
            class="btn gray small"
            >{{ __("See job") }}</a
          >
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>

    <label v-if="notification.type == 'App\\Notifications\\NewJobReferral'">
      <div class="candidate-gamification__news--visual">
        <img
          :src="notification.img"
          alt=""
          width="50"
          height="50"
          style="border-radius: 50%"
        />
        <strong>{{ notification.user }}</strong>
      </div>
      <div class="candidate-gamification__news--desc">
        <div class="candidate-gamification__news--head">
          <div
            class="candidate-gamification__news--title"
            v-html="notification.data.title"
          ></div>
          <div class="candidate-gamification__news--date">
            {{ notification.ago }}
          </div>
        </div>
        <div class="candidate-gamification__news--body">
          <p v-html="notification.data.content"></p>
          <a
            v-if="notification.url"
            :href="notification.url"
            class="btn gray small"
            >{{ __("See job") }}</a
          >
          <a
            @click="this.dismissNotification(index)"
            class="btn gray small prev-ml-1"
            >{{ __("Dismiss") }}</a
          >
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: [
    "notification",
    "user",
    "index",
    "messages_route",
    "dismissNotification",
    "friends_index_route",
    "roles_opportunities_route",
    "page_shop_active",
    "page_shop_url",
  ],
};
</script>
