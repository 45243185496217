<template>
    <div class="events-card h-full space-y-6 md:space-y-8 overflow-hidden rounded-app bg-white p-8 relative grid grid-rows-[auto,1fr] shadow-[0px_12px_16px_-4px_rgb(85_105_135_/_20%)]">
        <!-- <div class="rounded bg-white p-8 overflow-hidden grid grid-rows-[auto,1fr] h-full"> -->
            <div class="pb-5 text-2xl font-medium">
                {{ __('Events') }}
            </div>
            <div class="mt-0 bg-white shadow-none rounded h-full overflow-hidden">
                <Carousel 
                    ref="carouselRef"
                    v-bind="carouselConfig">
                    <Slide v-for="(event, index) in events" :key="index">
                        <div class="p-4 bg-zinc-100 grid rounded">
                        <!-- <div class="carousel__item md:h-auto lg:h-[19rem] xl:h-[17rem] overflow-hidden"> -->
                        <div class="carousel__item h-auto">
                            <div class="flex flex-wrap gap-2 pb-6">
                                <span
                                    class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800 text-sm">
                                    {{ event.type }}
                                </span>
                                <span
                                    v-if="event.group.name"
                                    class="hidden xl:block  rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800 text-sm">
                                    {{ __('Group') }} : {{ event.group.name }}
                                </span>
                                <span
                                    v-if="event.group.name"
                                    class="xl:hidden block rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800 text-sm">
                                    {{ __('Group') }} : {{ event.group.short_name }}
                                </span>
                            </div>
                            <div class="space-y-2">
                                <span class="text-left block font-small text-sm xl:text-base text-zinc-800 pb-4">
                                    {{ event.start_date_time }} <span class="text-primary">|</span> {{ event.timezone }}
                                </span>
                                <span class="hidden xl:block text-left text-lg xl:text-2xl font-medium text-zinc-800 pb-6">
                                    {{ event.name }}
                                </span>
                                <span class="xl:hidden block text-left text-lg xl:text-2xl font-medium text-zinc-800 pb-6">
                                    {{ event.short_name }}
                                </span>
                            </div>
                            <div class="bottom-0">
                                <div class="flex flex-wrap lg:gap-0 lg:py-1 xl:gap-2 xl:py-2 events-buttons">
                                    <a
                                        v-if="event.button.url"
                                        :href="event.button.url"
                                        class="p-2 lg:p-2 xl:px-6 lg:py-2 bg-primary text-white hover:bg-primary/90 flex w-fit transition-colors rounded-md text-sm font-medium disabled:pointer-events-none disabled:opacity-50 flex items-center justify-center gap-1.5"
                                        type="button"
                                        >
                                        <span>{{ event.button.title }}</span>
                                    </a>
                                    <button
                                        v-else
                                        class="p-2 lg:p-2 xl:px-6 lg:py-2 bg-green-500 text-white hover:bg-green-500/90 flex w-fit transition-colors rounded-md text-sm font-medium disabled:pointer-events-none disabled:opacity-50 flex items-center justify-center gap-1.5"
                                        type="button"
                                        >
                                        <span>{{ event.button.title }}</span>
                                    </button>
                                    
                                    <Button
                                        class="flex items-center gap-2 lg:p-0 md:px-0"
                                        variant="ghost"
                                        :href="event.link.url"
                                        target="_blank"
                                        >
                                        <Icon name="open_in_new" class="text-xl text-primary" />
                                        <span>{{ event.link.title }}</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </Slide>
                </Carousel>
                <div class="text-center bottom">
                    <Icon
                        @click="prev" 
                        class="carousel_btn_prev text-4xl text-zinc-500 pt-4" 
                        name="chevron_left" />
                    <Icon 
                        @click="next"
                        class="carousel_btn_next text-4xl text-zinc-500 pt-4" 
                        name="chevron_right" />
                </div>
            </div>
        <!-- </div> -->
    </div>
</template>

<script setup>
import { __ } from "@/helpers";
import { Button } from "@/components/block/button";
import { Icon } from "@/components/block/icon";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { ref } from "vue";

const carouselConfig = {
    itemsToShow: 1,
    snapAlign: 'center',
    wrapAround: true,
}

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const carouselRef = ref();

const next = () => carouselRef.value.next();
const prev = () => carouselRef.value.prev();

const events = ref(JSON.parse(JSON.stringify(props.data)));

</script>