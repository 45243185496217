<template>
  <div>
    <div v-if="value" class="flex items-center gap-4">
      <div class="shadow-card relative h-14 w-14 overflow-hidden rounded">
        <img
          class="h-full w-full object-cover"
          :src="
            value.includes('data') || value.includes('uploads')
              ? value
              : `/uploads/${value}`
          "
          :alt="__('Image')"
        />

        <button
          v-if="!disabled"
          class="absolute inset-0 flex items-center justify-center bg-zinc-800/75 opacity-0 transition-opacity hover:opacity-100 focus:opacity-100"
          type="button"
          :aria-label="__('Delete')"
          @click="handleDeleteButtonClick"
        >
          <Icon class="text-xl text-white" name="delete" />
        </button>
      </div>

      <div class="space-y-1">
        <span class="block text-sm font-medium text-primary">
          {{ file?.name || label }}
        </span>

        <span v-if="description" class="block text-xs text-zinc-600">
          {{ description }}
        </span>

        <span v-if="extraDescription" class="block text-xs text-zinc-600">
          {{ extraDescription }}
        </span>
      </div>
    </div>
    <div v-else>
      <label
        :class="`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} flex items-center gap-4 text-primary`"
        :for="id"
        @drop.prevent="handleDrop"
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
      >
        <div
          :class="`${isDragging ? 'border border-dashed border-primary bg-primary/5' : 'bg-white'} shadow-card flex h-14 w-14 shrink-0 items-center justify-center rounded transition-colors`"
        >
          <Icon class="text-2xl text-primary" name="backup" is-filled />
        </div>

        <div class="space-y-1">
          <span class="block text-sm font-medium">
            {{ `${label}${required ? '*' : ''}` }}
          </span>
          <span v-if="description" class="block text-xs text-zinc-600">
            {{ description }}
          </span>
        </div>
      </label>

      <input
        :id="id"
        class="sr-only"
        type="file"
        :accept="extensions.join(',')"
        :disabled="disabled"
        @change="handleInputChange"
      />

      <ImageCropper
        v-model:open="isCropperOpen"
        :value="base64"
        :width="width"
        :height="height"
        :aspect-ratio="aspectRatio"
        :file-name="fileName"
        @change="handleCropperChange"
        @update:errors="handleUpdateErrors"
      />
    </div>

    <div v-if="errors.length" class="mt-2 space-y-1">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="block text-xs text-error"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';
  import { ImageCropper } from '@/components/other/image-cropper';

  const props = defineProps({
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    extraDescription: {
      type: String,
      default: ''
    },
    extensions: {
      type: Array,
      default: () => ['.jpg', '.jpeg', '.png']
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: NaN
    },
    height: {
      type: Number,
      default: NaN
    },
    aspectRatio: {
      type: Number,
      default: NaN
    },
    maxFileSize: {
      type: Number,
      default: 2
    },
    value: {
      type: [String, null],
      required: true
    },
    file: {
      type: [File, null],
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:value', 'update:file', 'update:errors']);

  const base64 = ref('');
  const fileName = ref('');
  const isCropperOpen = ref(false);
  const isDragging = ref(false);

  function readFiles(files) {
    if (!files || !files.length) {
      return;
    }

    const file = files[0];
    const reader = new FileReader();

    reader.onload = () => {
      base64.value = reader.result;
      fileName.value = file.name.slice(0, file.name.lastIndexOf('.'));
      isCropperOpen.value = true;
    };

    reader.readAsDataURL(file);
  }

  function handleInputChange(event) {
    const files = Array.from(event.target.files);

    const validFiles = files.filter((file) =>
      props.extensions
        .map((extension) => `image/${extension.split('.')[1]}`)
        .includes(file.type)
    );

    if (validFiles.length) {
      readFiles(validFiles);
    }

    event.target.value = '';
  }

  function handleDrop(event) {
    if (props.disabled) {
      return;
    }

    const files = Array.from(event.dataTransfer.files);

    const validFiles = files.filter((file) =>
      props.extensions
        .map((extension) => `image/${extension.split('.')[1]}`)
        .includes(file.type)
    );

    if (validFiles.length) {
      readFiles(validFiles);
    }

    isDragging.value = false;
  }

  function handleDragOver() {
    isDragging.value = true;
  }

  function handleDragLeave() {
    isDragging.value = false;
  }

  function handleDeleteButtonClick() {
    emits('update:value', null);
    emits('update:file', null);
    emits('update:errors', []);
  }

  function handleCropperChange(base64, file) {
    if (file.size > props.maxFileSize * 1024 * 1024) {
      emits('update:errors', [
        `${__('File size exceeds limit. Please upload a file smaller than')} ${props.maxFileSize} MB.`
      ]);
      return;
    }

    emits('update:value', base64);
    emits('update:file', file);
    emits('update:errors', []);
  }

  function handleUpdateErrors(value) {
    emits('update:errors', value);
  }
</script>
