<template>
  <div class="space-y-4">
    <div class="shadow-element overflow-auto rounded-md">
      <table class="w-max min-w-full">
        <thead>
          <tr class="bg-white">
            <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
              {{ __('Name') }}
            </th>
            <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
              {{ __('Role') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading" class="border-t">
            <td :colSpan="2" class="px-8 py-4">
              <span class="text-sm text-zinc-600">
                {{ __('Loading...') }}
              </span>
            </td>
          </tr>

          <template v-else>
            <tr
              v-for="member in members"
              :key="member.id"
              :class="`${user && member.id === user.id ? 'bg-violet-50' : 'bg-white'} border-y last:border-b-0`"
            >
              <td class="px-8 py-4">
                <div class="flex items-center gap-4">
                  <img
                    class="h-12 w-12 rounded-full border border-zinc-200"
                    :src="
                      member.profile_photo
                        ? `/uploads/${member.profile_photo}`
                        : placeholderImage
                    "
                    :alt="__('Profile picture')"
                  />

                  <div class="space-y-0.5">
                    <span class="block text-sm font-medium text-zinc-800">
                      {{ member.name }}
                    </span>
                    <span class="block text-sm text-zinc-600">
                      {{ member.email }}
                    </span>
                  </div>
                </div>
              </td>

              <td class="px-8 py-4">
                <span class="text-sm text-zinc-600">
                  {{ member.role }}
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div
      v-if="!isPreviousButtonDisabled || !isNextButtonDisabled"
      class="flex justify-center gap-2"
    >
      <button
        class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
        :disabled="isPreviousButtonDisabled"
        :aria-label="__('Previous')"
        @click="handlePreviousButtonClick"
      >
        <Icon class="text-xl text-primary" name="arrow_back" />
      </button>

      <button
        class="flex rounded-full bg-zinc-50 p-1.5 disabled:pointer-events-none disabled:opacity-50"
        :disabled="isNextButtonDisabled"
        :aria-label="__('Next')"
        @click="handleNextButtonClick"
      >
        <Icon class="text-xl text-primary" name="arrow_forward" />
      </button>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    group: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const placeholderImage = '/images/logo_placeholder.png';

  const isPreviousButtonDisabled = computed(() => {
    return page.value <= 1 || isLoading.value;
  });

  const isNextButtonDisabled = computed(() => {
    return page.value >= totalPages.value || isLoading.value;
  });

  const members = ref([]);
  const isLoading = ref(false);
  const page = ref(1);
  const totalPages = ref(0);

  async function handlePreviousButtonClick() {
    page.value--;

    await fetchMembers(page.value);
  }

  async function handleNextButtonClick() {
    page.value++;

    await fetchMembers(page.value);
  }

  async function fetchMembers(page) {
    const limit = 10;
    const offset = (page - 1) * limit;

    isLoading.value = true;

    try {
      const response = await axios.get(
        route('redesign.groups.paginateMembers', {
          slug: props.group.details.slug
        }),
        { params: { offset, limit } }
      );

      members.value = response.data.members;
      totalPages.value = Math.ceil(response.data.total / limit);
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }

  onMounted(async () => {
    await Promise.all([fetchMembers(page.value)]);
  });
</script>
