<template>
  <div>
    <div class="grid gap-4 md:grid-cols-2">
      <InputCountry
        id="country"
        v-model:value="country"
        :label="labelCountry"
        :placeholder="placeholderCountry"
        :required="required"
        :options="options.country"
        :errors="!country ? errors : []"
        @update:value="handleCountryUpdate"
      />

      <InputCity
        id="city"
        v-model:value="city"
        :label="labelCity"
        :placeholder="placeholderCity"
        :required="required"
        :disabled="!country"
        :country="country"
        :errors="errors"
        @update:value="handleCityUpdate"
      />
    </div>

    <div
      v-if="isMultipleSelection && value.length"
      class="mt-2 flex flex-wrap gap-1"
    >
      <button
        v-for="item in value"
        :key="item.id"
        :aria-label="__('Clear')"
        type="button"
        tabindex="0"
        class="flex items-center gap-1 rounded-lg border border-secondary px-2 py-1 text-sm text-zinc-800 transition-colors hover:bg-secondary/5"
        @click="handleDeleteButtonClick(item)"
      >
        <span>{{ ` ${item.city.name}, ${item.country.name}` }}</span>

        <Icon class="text-zinc-800" name="close" />
      </button>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';
  import { InputCity } from './components/input-city';
  import { InputCountry } from './components/input-country';

  const props = defineProps({
    labelCountry: {
      type: String,
      default: ''
    },
    labelCity: {
      type: String,
      default: ''
    },
    placeholderCountry: {
      type: String,
      default: ''
    },
    placeholderCity: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Array, Object, null],
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    },
    defaultCountry: {
      type: [Object, null],
      default: null
    }
  });

  const emits = defineEmits(['update:value', 'update:errors']);

  const toast = useToast();

  const isMultipleSelection = computed(() => {
    return Array.isArray(props.value);
  });

  const options = ref({ country: props.options, city: [] });
  const country = ref(
    props.value && !isMultipleSelection.value
      ? props.value.country
      : props.defaultCountry
  );
  const city = ref(
    props.value && !isMultipleSelection.value ? props.value.city : null
  );

  function handleCountryUpdate() {
    city.value = null;

    if (country.value && country.value.code) {
      if (!isMultipleSelection.value) {
        emits('update:value', { country: country.value, city: city.value });
        emits('update:errors', []);
      }
    } else {
      emits('update:value', isMultipleSelection.value ? [] : null);
    }

    emits('update:errors', []);
  }

  function handleCityUpdate() {
    if (!isMultipleSelection.value) {
      emits('update:value', { ...props.value, city: city.value });
      emits('update:errors', []);
    } else {
      if (city.value != null && !props.value.find((item) => item.city.id === city.value.id)) {
        emits('update:value', [
          ...props.value,
          { country: country.value, city: city.value }
        ]);
        emits('update:errors', []);
      }

      country.value = null;
      city.value = null;
    }

    emits('update:errors', []);
  }

  function handleDeleteButtonClick(value) {
    emits(
      'update:value',
      props.value.filter((item) => item.city.id !== value.city.id)
    );
    emits('update:errors', []);
  }
</script>
