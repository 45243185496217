<template>
  <Modal size="large" full-height open @close="handleCloseButtonClick">
    <div class="relative px-4 py-8">
      <button
        class="absolute -right-2 -top-2 flex p-2"
        @click="handleCloseButtonClick"
      >
        <Icon name="close" class="text-xl text-zinc-800" />
      </button>

      <div v-if="isLoading || !user" class="flex items-center gap-2">
        <Icon
          name="progress_activity"
          class="animate-spin text-xl text-zinc-800"
        />
        <span class="text-zinc-800">{{ __('Loading...') }}</span>
      </div>

      <template v-else>
        <div class="space-y-12">
          <div class="space-y-8">
            <CandidateInformation
              :data="data"
              :recruiter="recruiter"
              :username="user.encoded_username"
            />

            <div class="space-y-6">
              <button
                class="flex w-full flex-wrap items-center justify-between gap-4 border-b-[1.5px] border-primary py-4"
                @click="handleDashboardButtonClick"
              >
                <span class="text-4xl font-medium leading-none text-zinc-800">
                  {{ __('Dashboard') }}
                </span>

                <Icon
                  :class="`${isDashboardOpen ? 'rotate-180' : 'rotate-0'} text-3xl text-zinc-800`"
                  name="keyboard_arrow_down"
                />
              </button>

              <div v-if="isDashboardOpen" class="space-y-10">
                <Applications
                  :data="data"
                  :recruiter="recruiter"
                  @update:applications="handleUpdateApplications"
                />

                <div class="grid gap-10 md:grid-cols-2">
                  <Pipelines :data="data" :recruiter="recruiter" />
                  <Notes :data="data" :recruiter="recruiter" />
                </div>

                <HistoryTracking
                  :data="recruiter.history_tracking"
                  :options="recruiter.options.history_tracking"
                  :user-id="user.id"
                />
              </div>
            </div>
          </div>

          <div class="space-y-8">
            <span
              class="block border-b-[1.5px] border-primary py-4 text-4xl font-medium leading-none text-zinc-800"
            >
              {{ __('Resume') }}
            </span>

            <div class="space-y-10">
              <AboutMe
                :data="data.about_me.user"
                :availability-data="data.availability.user"
              />
              <Availability :data="data.availability.user" />
              <Experiences
                v-if="data.experiences.user.experiences.length"
                :data="data.experiences.user.experiences"
              />
              <Educations
                v-if="data.educations.user.length"
                :data="data.educations.user"
              />
              <Volunteerings
                v-if="data.volunteering.user.length"
                :data="data.volunteering.user"
              />
              <Projects
                v-if="data.projects.user.length"
                :data="data.projects.user"
              />
              <Achievements
                v-if="data.achievements.user.length"
                :data="data.achievements.user"
              />
              <Skills
                v-if="data.skills.user.map((item) => item.skill).length"
                :data="data.skills.user.map((item) => item.skill)"
              />
              <Documents
                v-if="conditions.show_documents && data.documents.user.length"
                :data="data.documents.user"
                :username="user.encoded_username"
              />
              <Languages
                v-if="data.languages.user.length"
                :data="data.languages.user"
              />

              <div
                v-if="data.values.user.length || data.perks.user.length"
                class="grid gap-12 md:grid-cols-2"
              >
                <Values
                  v-if="data.values.user.length"
                  :data="data.values.user"
                />
                <Perks v-if="data.perks.user.length" :data="data.perks.user" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="sticky -bottom-6 -mb-8 flex grow items-center justify-between gap-2 bg-white py-6 md:grow-0"
        >
          <Button
            class="flex items-center gap-2"
            variant="ghost"
            :disabled="isPreviousCandidateButtonDisabled"
            @click="handlePreviousCandidateButtonClick"
          >
            <Icon name="arrow_back" class="text-xl" />
            <span>{{ __('Previous candidate') }}</span>
          </Button>
          <Button
            class="flex items-center gap-2"
            variant="ghost"
            :disabled="isNextCandidateButtonDisabled"
            @click="handleNextCandidateButtonClick"
          >
            <span>{{ __('Next candidate') }}</span>
            <Icon name="arrow_forward" class="text-xl" />
          </Button>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script setup>
  import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Modal } from '@/components/block/modal';
  import { AboutMe } from './components/about-me';
  import { Achievements } from './components/achievements';
  import { Applications } from './components/applications';
  import { Availability } from './components/availability';
  import { Documents } from './components/documents';
  import { Educations } from './components/educations';
  import { Experiences } from './components/experiences';
  import { CandidateInformation } from './components/candidate-information';
  import { HistoryTracking } from './components/history-tracking';
  import { Languages } from './components/languages';
  import { Notes } from './components/notes';
  import { Perks } from './components/perks';
  import { Pipelines } from './components/pipelines';
  import { Projects } from './components/projects';
  import { Skills } from './components/skills';
  import { Values } from './components/values';
  import { Volunteerings } from './components/volunteerings';

  const props = defineProps({
    username: {
      type: String,
      required: true
    },
    usernameList: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits([
    'close',
    'update:applications',
    'update:username',
    'update:currentIndex'
  ]);

  const toast = useToast();

  const currentCandidateIndex = computed(() => {
    return props.usernameList.indexOf(props.username);
  });

  watch(currentCandidateIndex, (newIndex) => {
    emits('update:currentIndex', newIndex);
  });

  const isPreviousCandidateButtonDisabled = computed(() => {
    return currentCandidateIndex.value <= 0;
  });

  const isNextCandidateButtonDisabled = computed(() => {
    return currentCandidateIndex.value >= props.usernameList.length - 1;
  });

  const conditions = ref(null);
  const data = ref(null);
  const recruiter = ref(null);
  const user = ref(null);
  const isLoading = ref(false);
  const isDashboardOpen = ref(false);

  function handleDashboardButtonClick() {
    isDashboardOpen.value = !isDashboardOpen.value;
  }

  function handleUpdateApplications(applications) {
    emits('update:applications', applications);
  }

  function handleCloseButtonClick() {
    emits('close');
  }

  function handlePreviousCandidateButtonClick() {
    if (currentCandidateIndex.value > 0) {
      emits(
        'update:username',
        props.usernameList[currentCandidateIndex.value - 1]
      );
    }
  }

  function handleNextCandidateButtonClick() {
    if (currentCandidateIndex.value < props.usernameList.length - 1) {
      emits(
        'update:username',
        props.usernameList[currentCandidateIndex.value + 1]
      );
    }
  }

  function handleKeyDown(event) {
    if (event.key === 'ArrowLeft' && !isPreviousCandidateButtonDisabled.value) {
      handlePreviousCandidateButtonClick();
    } else if (
      event.key === 'ArrowRight' &&
      !isNextCandidateButtonDisabled.value
    ) {
      handleNextCandidateButtonClick();
    }
  }

  async function fetchResume() {
    isLoading.value = true;

    try {
      const response = await axios.get(
        route('redesign.cv.load', { username: props.username })
      );

      conditions.value = response.data.conditions;
      data.value = response.data.data;
      recruiter.value = response.data.recruiter;
      user.value = response.data.user;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }

  watch(
    () => props.username,
    async () => {
      await fetchResume();
    },
    { immediate: true }
  );

  onMounted(async () => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });
</script>
