<template>
  <div class="shadow-card space-y-8 rounded-lg bg-white p-6 md:p-8">
    <Confirmed v-if="isPastEvent || isRatedEvent" :event="event" :user="user" />

    <TabGroup v-else>
      <TabList class="flex gap-3 overflow-auto bg-white">
        <Tab v-slot="{ selected }" as="template">
          <button
            :class="`${selected ? 'border-primary' : 'border-transparent'} whitespace-nowrap border-b-2 py-3 pr-8 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:border-primary`"
          >
            {{ __('Confirmed') }}
          </button>
        </Tab>
        <Tab v-slot="{ selected }" as="template">
          <button
            :class="`${selected ? 'border-primary' : 'border-transparent'} whitespace-nowrap border-b-2 px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:border-primary`"
          >
            {{ __('Invited') }}
          </button>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Confirmed :event="event" :user="user" />
        </TabPanel>
        <TabPanel>
          <Invited :event="event" :user="user" />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
  import { Confirmed } from './components/confirmed';
  import { Invited } from './components/invited';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  });

  const isPastEvent = computed(() => {
    return props.event.status === 'PAST';
  });

  const isRatedEvent = computed(() => {
    return props.event.status === 'RATED';
  });
</script>
