<template>
    <div class="interests-card h-full space-y-6 md:space-y-8 overflow-hidden rounded-app bg-white p-8 relative grid grid-rows-[auto,1fr] shadow-[0px_12px_16px_-4px_rgb(85_105_135_/_20%)]">
        <div class="absolute top-3 right-2.5">
            <Button
                class="profile-edit-btn flex items-center gap-1.5"
                variant="ghost"
                    @click="handleEditButtonClick"
            >
                <Icon class="text-2xl" name="edit" style="width: 32px;" />
            </Button>
        </div>

        <div class="overflow-hidden h-full mt-0">
            <div class="interests-title text-2xl font-medium"> 
                {{ __('Interests') }} 
            </div>
            <div class="h-full overflow-y-scroll customscrollbar--simple">
                <div class="overflow-hidden"> 

                <div v-for="(interest, index) in interests" :key="index">
                    <div  v-if="interest.values.length" class="py-4">
                        <span 
                            class="block font-medium text-zinc-800 text-base xl:text-lg">
                            {{ interest.title }}
                        </span>

                        <div class="flex flex-wrap gap-x-2 gap-y-1 py-1">
                            <span v-for="(value, index) in interest.values" :key="index"
                                class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800 text-sm">
                                {{ value.name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="py-4">
                    <span class="block font-medium text-zinc-800 text-base xl:text-lg">
                        {{ inspirationData.title }}
                    </span>

                    <div class="flex flex-wrap gap-x-2 py-1">
                        <span class="py-1 leading-tight text-sm text-zinc-800">
                            {{ inspirationData.name }}
                        </span>
                    </div>
                    <br />
                </div>
                </div>
            </div>
        </div>

        <InterestsFormModal
          v-if="isFormModalOpen"
          :interests="interests"
          :inspiration="inspirationData"
          @cancel="handleCancelButtonClick"
          @save="handleSaveButtonClick"
        />
    </div>
</template>

<script setup>
    import { __ } from '@/helpers';
    import { Button } from '@/components/block/button';
    import { Icon } from '@/components/block/icon';
    import { reactive, ref } from 'vue';
    import InterestsFormModal from '../interests-form-modal/interests-form-modal.vue';

    const props = defineProps({
        data: {
            type: Object,
            required: true
        },
        inspiration: {
            type: Object,
            required: true
        }
    });

    // Create a reactive copy of props
    const interests = reactive(JSON.parse(JSON.stringify(props.data))); 
    const inspirationData = reactive(JSON.parse(JSON.stringify(props.inspiration))); 

    const isFormModalOpen = ref(false);

    function handleEditButtonClick() {
        isFormModalOpen.value = true;
    }

    function handleCancelButtonClick() {
        isFormModalOpen.value = false;
    }

    function handleSaveButtonClick(updatedData) {
        try {
            interests.careers.values = [...updatedData.data.careers]; 
            interests.hobbies.values = [...updatedData.data.hobbies]; 
            interests.skills.values = [...updatedData.data.skills]; 
        
            inspirationData.name = updatedData.data.celebrities;

            isFormModalOpen.value = false;
        } catch (error) {
            console.log('error: ' + error);
        }
    }

</script>