<template>
  <div class="flex gap-2 md:gap-4">
    <Modal
      open
      :title="__('Interests')"
      @close="handleCancelButtonClick"
    >
      <form @submit.prevent="handleSubmit">
        <div class="grid gap-6">
          <InputCareers
            id="careers"
            v-model:value="values.careers"
            v-model:errors="errors.careers"
            :label="__('What are your dream career path?')"
            :placeholder="__('Search possible careers')"
            is-new-options-allowed
          >
            <template #icon>
              <Icon name="stars" />
            </template>
          </InputCareers>

          <InputCombobox
            id="hobbies"
            v-model:value="values.hobbies"
            v-model:errors="errors.hobbies"
            :label="__('Hobbies and Extracurriculars')"
            :placeholder="__('Search your favorite hobbies and activities')"
            :options="options.hobbies"
          >
            <template #icon>
              <Icon name="search" />
            </template>
          </InputCombobox>

          <InputSkills
            id="skills"
            v-model:value="values.skills"
            v-model:errors="errors.skills"
            :label="__('Skill interests')"
            :placeholder="__('What skills are you most interested in developing?')"
            is-new-options-allowed
          >
            <template #icon>
              <Icon name="stylus_note" />
            </template>
          </InputSkills>

          <InputText
              id="celebrities"
              v-model:value="values.celebrities"
              v-model:errors="errors.celebrities"
              :label="
                __(
                  'Who inspires you? List celebrities or influencers you admire, separated by commas:'
                )
              "
              :placeholder="__('Add celebrity’s name')"
          />
        </div>

        <div
          class="mt-8 flex flex-col gap-2 md:flex-row md:justify-center"
        >
          <Button
            class="flex items-center justify-center gap-1.5"
            :type="isLoading ? 'button' : 'submit'"
            :disabled="isLoading"
          >
            <Icon
              v-if="isLoading"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
          </Button>

          <Button variant="ghost" @click="handleCancelButtonClick">
            <span>{{ __('Cancel') }}</span>
          </Button>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Modal } from '@/components/block/modal';
  import { InputText } from '@/components/form/input-text';
  import { InputCombobox } from '@/components/form/input-combobox';
  import { InputCareers } from '@/components/form/input-careers';
  import { InputSkills } from '@/components/form/input-skills';


  const props = defineProps({
    interests: {
      type: [Object, null],
      required: true
    },
    inspiration: {
      type: [Object, null],
      required: true
    }
  });

  const emits = defineEmits(['cancel', 'save']);

  const toast = useToast();

  const interestsData = ref(JSON.parse(JSON.stringify(props.interests)));
  const inspirationData = ref(JSON.parse(JSON.stringify(props.inspiration)));

  const values = ref({
    careers: interestsData.value?.careers?.values ?? '',
    hobbies: interestsData.value?.hobbies?.values ?? '',
    skills: interestsData.value?.skills?.values ?? '',
    celebrities: inspirationData.value?.name ?? ''
  });

  const errors = ref({
    careers: [],
    hobbies: [],
    skills: [],
    celebrities: []
  });
  
  const options = ref({
    hobbies: interestsData.value?.hobbies?.options ?? []
  });

  const isLoading = ref(false);

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    let firstElement;

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      let response;
      response = await axios.post(
        route('redesign.profile.updateInterests'),
        {
          ...values.value
        }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', {
        data: values.value
      });
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
