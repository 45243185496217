<template>
  <div id="values">
    <div class="mt-8 md:grid md:grid-cols-2">
      <SectionHeader icon="account_tree" title="ATS process" />
      <div
        class="text-xs text-zinc-600 flex items-center text-right justify-end"
      >
        {{
          __(
            "Internal only! Once the job is posted, the internal recruitment process cannot be changed."
          )
        }}
      </div>
    </div>
    <div class="p-8 shadow-md mt-8 rounded-app bg-white relative">
      <div>
        <div>
          <label class="text-sm text-[#58585E] mb-2 block"
            >{{ __("Add ATS process") }} *</label
          >
          <FormSingleselect
            v-model="data.job.process"
            :values="data.options"
            placeholder="Select values"
            :errors="errors.ats_recruitment_process"
            required
          />
        </div>

        <RecruitmentProcess
          class="mt-8"
          :process="data.job?.steps"
          v-if="data.job?.steps.length > 0"
        />
      </div>
    </div>
  </div>
</template>

<script setup>

import {
  SectionHeader,
  FormSingleselect,
} from "../../../../components/global";

import { useToast } from "vue-toastification";
import { __ } from "../../../../helpers";
import { RecruitmentProcess } from "../../../candidate/jobs/components";

const props = defineProps({
  data: {
    type: Object,
    mandatory: true,
  },
  errors: {
    type: Object,
    mandatory: true,
  },
});

const toast = useToast();
</script>
