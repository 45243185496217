<template>
  <div class="space-y-3 rounded-lg bg-zinc-50 p-6">
    <template v-if="!isRejected">
      <span
        class="block w-fit rounded-lg border border-secondary px-3 py-1 text-sm text-zinc-800"
      >
        {{ application.job_department }}
      </span>

      <span class="block leading-normal text-zinc-800">
        {{ application.job_title }}
      </span>

      <div class="flex flex-wrap items-center justify-between gap-y-8">
        <div class="flex items-center gap-2 text-zinc-600">
          <Icon name="clock_loader_10" />
          <span class="text-sm leading-none">
            {{ application.applied_ago }}
          </span>
        </div>

        <div class="ml-auto flex flex-wrap justify-end">
          <Button
            class="flex items-center gap-1.5"
            variant="ghost"
            :disabled="isLoadingAccept"
            @click="handleAcceptButtonClick"
          >
            <Icon
              v-if="isLoadingAccept"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <Icon v-else class="text-lg" name="check_circle" />

            <span>{{ __('Accept') }}</span>
          </Button>
          <Button
            class="flex items-center gap-1.5 !text-gray-600 hover:!bg-gray-600/5"
            variant="ghost"
            :disabled="isLoadingAccept"
            @click="handleRejectButtonClick"
          >
            <Icon class="text-lg" name="cancel" />

            <span>{{ __('Reject') }}</span>
          </Button>
        </div>
      </div>
    </template>
    <template v-else>
      <form class="space-y-4" @submit.prevent="handleSendRejectReason">
        <InputTextarea
          :id="`reject_reason-${application.id}`"
          v-model:value="rejectReason"
          :label="__('Could you please tell us why you declined the offer?')"
          :placeholder="
            __(
              ' Your feedback will help in refining our screening process for your profile.'
            )
          "
        />

        <div class="flex flex-wrap justify-end gap-2">
          <Button
            class="flex items-center gap-1.5"
            variant="outline"
            :disabled="isLoadingSendRejectReason || isLoadingSkipRejectReason"
            @click="handleSkipRejectReason"
          >
            <Icon
              v-if="isLoadingSkipRejectReason"
              class="animate-spin text-lg"
              name="progress_activity"
            />

            <span>{{ __('Skip') }}</span>
          </Button>
          <Button
            class="flex items-center gap-1.5"
            type="submit"
            :disabled="isLoadingSendRejectReason || isLoadingSkipRejectReason"
          >
            <Icon
              v-if="isLoadingSendRejectReason"
              class="animate-spin text-lg"
              name="progress_activity"
            />
            <Icon v-else class="text-lg" name="send" />

            <span>{{ __('Send') }}</span>
          </Button>
        </div>
      </form>
    </template>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputTextarea } from '@/components/form/input-textarea';

  const props = defineProps({
    applications: {
      type: Array,
      required: true
    },
    application: {
      type: Object,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  });

  const emits = defineEmits(['update:applications']);

  const toast = useToast();

  const isLoadingAccept = ref(false);
  const isRejected = ref(false);
  const rejectReason = ref('');
  const isLoadingSendRejectReason = ref(false);
  const isLoadingSkipRejectReason = ref(false);

  async function handleAcceptButtonClick() {
    isLoadingAccept.value = true;

    try {
      const response = await axios.post(
        route('redesign.matching.acceptApplication', {
          user_id: props.userId,
          job_id: props.application.id
        })
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits(
        'update:applications',
        props.applications.filter(
          (application) => application.id !== props.application.id
        )
      );
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingAccept.value = false;
  }

  function handleRejectButtonClick() {
    isRejected.value = true;
  }

  async function handleSendRejectReason() {
    isLoadingSendRejectReason.value = true;

    try {
      const rejectResponse = await axios.post(
        route('redesign.matching.rejectApplication', {
          user_id: props.userId,
          job_id: props.application.id
        })
      );

      if (rejectResponse?.data?.message) {
        toast.success(rejectResponse.data.message);
      }

      const rejectReasonResponse = await axios.post(
        route('redesign.applications.reject-reason', {
          application: props.application.application_id
        }),
        { reject_reason: rejectReason.value }
      );

      if (rejectReasonResponse?.data?.message) {
        toast.success(rejectReasonResponse.data.message);
      }

      emits(
        'update:applications',
        props.applications.filter(
          (application) => application.id !== props.application.id
        )
      );
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingSendRejectReason.value = false;
  }

  async function handleSkipRejectReason() {
    isLoadingSkipRejectReason.value = true;

    try {
      const response = await axios.post(
        route('redesign.matching.rejectApplication', {
          user_id: props.userId,
          job_id: props.application.id
        })
      );
      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits(
        'update:applications',
        props.applications.filter(
          (application) => application.id !== props.application.id
        )
      );
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoadingSkipRejectReason.value = false;
  }
</script>
