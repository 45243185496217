<template>
  <div class="candidate-gamification__subtab" id="achievements-tab">
    <div
      v-for="achievement in user.achievements"
      class="candidate-gamification__badges-achievements--item owned"
    >
      <div class="candidate-gamification__badges-achievements--icon">
        <img :src="achievement.img" alt="" style="max-width: 70px" />
      </div>
      <div class="candidate-gamification__badges-achievements--desc">
        <strong>{{ achievement.name }}</strong>
        <p v-if="achievement.completed">{{ achievement.description }}</p>

        <small v-if="achievement.completed">
          <span class="icon-done">
            <img src="/images/front/circle-done.svg" alt="" />
          </span>
          {{ __("Achievement unlocked") }}
        </small>

        <small v-if="!achievement.completed">
          <p>
            <a :href="achievement.url">{{ __("Unlock achievement") }}</a>
          </p>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
};
</script>
