<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Skills')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="star" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-element space-y-4 rounded-2xl bg-white p-6 md:p-8">
      <div v-for="group in groups" :key="group.category.id" class="space-y-2">
        <span class="block font-medium text-zinc-800">
          {{ group.category.name }}
        </span>

        <div class="flex flex-wrap gap-x-3 gap-y-2">
          <span
            v-for="skill in group.skills"
            :key="skill.id"
            class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
          >
            {{ skill.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    }
  });

  const groups = computed(() => {
    let groups = {};

    props.data.forEach((skill) => {
      const categoryId = skill.category.id;

      if (!groups[categoryId]) {
        groups[categoryId] = {
          category: skill.category,
          skills: []
        };
      }

      groups[categoryId].skills.push({ id: skill.id, name: skill.name });
    });

    let result = [];

    for (let categoryId in groups) {
      result.push(groups[categoryId]);
    }

    return result;
  });
</script>
