<template>
  <div>
    <div
      class="mt-2 grid grid-cols-8 rounded-lg border border-green-600 break-word p-6 w-full font-primary font-medium"
      v-if="file"
    >
      <div class="col-span-1 flex items-center">
        <Icon name="add_notes" class="text-green-600" />
      </div>
      <div class="col-span-5 flex items-center">{{ file.name }}</div>
      <div class="col-span-2 flex justify-end items-center">
        <span @click="removeFile" class="cursor-pointer">
          <Icon name="delete" class="text-gray-300" />
        </span>
      </div>
    </div>
    <div
      class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 w-full"
      :class="disabled ? 'bg-gray-100 !text-gray-300' : 'cursor-pointer'"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
      v-else
    >
      <input
        type="file"
        multiple
        :name="'file' + name"
        :id="'fileInput' + name"
        class="sr-only"
        @change="onChange"
        ref="file"
        :accept="extensions.join(', ')"
        :disabled="disabled"
      />

      <label
        :for="'fileInput' + name"
        class="text-sm font-primary text-gray-400 text-center"
      >
        <Icon name="backup" />
        <div v-if="isDragging">Release to drop files here.</div>
        <div v-else>
          <div>
            <u
              class="font-bold no-underline"
              :class="disabled ? 'text-gray-300' : 'text-blue-600'"
              >Click to upload</u
            >
            or drag and drop
          </div>
          <div>
            {{ extensions.join(", ") }} (MAX {{ maxFileSize / 1000000 }} MB)
          </div>
        </div>
        {{ file?.name }}
      </label>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Icon } from "../../global";
</script>

<script>
export default {
  props: {
    name: {
      type: Number,
      required: true,
    },
    extensions: {
      type: Array,
      required: false,
      default: () => [".pdf", ".jpg", ".jpeg", ".png"],
    },
    maxFileSize: {
      type: Number,
      required: false,
      default: 5000000,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isDragging: false,
      file: null,
    };
  },
  methods: {
    onChange() {
      // TODO OCR: Check if current file is under 5 mb

      // TODO OCR: Check if current file is valid

      this.file = this.$refs.file.files[0];
      this.$emit("update:modelValue", this.$refs.file.files[0]);
      this.$emit("fill-fields");
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();

      // TODO OCR: Check if the file is valid
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    removeFile() {
      this.file = null;
      this.$emit("update:modelValue", null);
      this.$emit("clear-fields");
    },
  },
};
</script>
