<template>
  <div class="space-y-8">
    <SectionHeader :title="__('GDPR Settings')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="encrypted" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
      <form @submit.prevent="handleSubmit">
        <div class="space-y-4">
          <div class="grid gap-4 md:grid-cols-3 items-center space-y-4">
            <span
              class="col-span-2 block text-lg font-medium leading-normal text-zinc-800"
            >
              {{
                __(
                  'I would like to receive updates regarding career opportunities that match my skills.'
                )
              }}
            </span>
            <div class="col-span-1 custom-toggle justify-self-end space-y-4">
              <Toggle id="gdpr_toggle" :value="values.marketing" @change="handleStatusChange" />
            </div>
          </div>
          <div
            class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
          >
            <Button
              class="flex items-center justify-center gap-1.5"
              :type="isLoading ? 'button' : 'submit'"
              :disabled="isLoading"
            >
              <Icon
                v-if="isLoading"
                class="animate-spin text-lg"
                name="progress_activity"
              />
              <span>{{ __('Save') }}</span>
            </Button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Toggle } from '../../../../components/global';
  import { SectionHeader } from '@/pages/components/section-header';
  import axios from 'axios';

  const props = defineProps({
    profile: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();
  const values = ref({ marketing: props.profile.marketing });
  const errors = ref({ marketing: [] });
  const isLoading = ref(false);

  function handleStatusChange() {
    values.value.marketing = !values.value.marketing;
    console.log(values.value.marketing);
  }

  async function handleSubmit() {
    isLoading.value = true;
    try {
      const payload = {
        marketing: !!values.value.marketing,
      };
      const response = await axios.post(
        route('redesign.candidate.settings.updateGdpr'),
        payload
      );

      if (response?.data?.message && payload.marketing === true) {
        toast.success(response.data.message);
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      } else {
        toast.error(
          __('An unexpected error has occurred. Please try again later.')
        );
      }
    }
    isLoading.value = false;
  }
</script>
