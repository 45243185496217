<template>
  <div class="flex items-center gap-4">
    <div class="flex items-center gap-1.5">
      <Icon class="text-xl text-primary" name="assignment_turned_in" />
      <span class="text-sm font-medium text-zinc-600">{{ document.name }}</span>
    </div>

    <div class="flex">
      <Button
        :disabled="isLoading"
        :aria-label="__('Download')"
        variant="icon"
        class="!text-zinc-600 hover:!bg-zinc-600/5"
        @click="handleDownloadButtonClick"
      >
        <Icon
          v-if="isLoading"
          class="animate-spin text-xl"
          name="progress_activity"
        />
        <Icon v-else class="text-xl" name="download" />
      </Button>

      <Button
        v-if="canEdit"
        :aria-label="__('Delete')"
        variant="icon"
        @click="handleDeleteButtonClick"
      >
        <Icon class="text-xl" name="delete" />
      </Button>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    document: {
      type: Object,
      required: true
    },
    username: {
      type: String,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    }
  });

  const emits = defineEmits(['delete']);

  const toast = useToast();

  const isLoading = ref(false);

  async function handleDownloadButtonClick() {
    isLoading.value = true;

    try {
      const response = await axios.get(
        route('redesign.cv.download-document', {
          username: props.username,
          id: props.document.id
        })
      );

      const decodedFromBase64 = atob(response.data.content);
      const decodedNumbers = new Array(decodedFromBase64.length);
      for (let i = 0; i < decodedFromBase64.length; i++) {
        decodedNumbers[i] = decodedFromBase64.charCodeAt(i);
      }
      const decodedArray = new Uint8Array(decodedNumbers);
      const blob = new Blob([decodedArray], {
        type: response.data.type
      });
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = blobUrl;
      a.download = response.data.filename;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);

      if (response?.data?.message) {
        toast.success(response.data.message);
      }
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }

  function handleDeleteButtonClick() {
    emits('delete');
  }
</script>
