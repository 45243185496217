<template>
  <div>
    <div v-if="error" id="news" class="candidate-gamification__tab">
      <div class="matching-lists__message">
        <p style="text-align: center">
          {{ __("Something went wrong. Please check again later.") }}
        </p>
      </div>
    </div>

    <div v-if="user" id="news" class="candidate-gamification__tab">
      <div class="candidate-gamification__module-header">
        <h3 class="candidate-gamification__subtab-link selected">
          {{ __("News") }}
        </h3>
      </div>
      <div class="candidate-gamification__module-cnt">
        <div class="candidate-gamification__news--list">
          <p v-if="user.notifications.length == 0">
            {{ __("No recent news!") }}
          </p>
          <div
            v-for="(notification, index) in user.notifications"
            class="candidate-gamification__news--item"
          >
            <gamification-news-notification
              :notification="notification"
              :user="this.user"
              :index="index"
              :messages_route="this.messages_route"
              :dismissNotification="this.dismissNotification"
              :friends_index_route="this.friends_index_route"
              :roles_opportunities_route="this.roles_opportunities_route"
              :page_shop_active="this.page_shop_active"
              :page_shop_url="this.page_shop_url"
            />
          </div>
          <div v-if="user.notifications.length != 0" class="centred">
            <a @click="this.dismissAllNotifications()" class="btn">{{
              __("Clear")
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "error",
    "user",
    "dismissAllNotifications",
    "dismissNotification",
    "messages_route",
    "friends_index_route",
    "roles_opportunities_route",
    "page_shop_active",
    "page_shop_url",
  ],
};
</script>
