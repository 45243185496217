<template>
  <div class="relative mt-5 sm:mt-10 lg:grid lg:grid-cols-10 lg:gap-5">
    <div
      class="col-span-8 lg:col-span-3"
      :class="isRefineClicked == true ? 'lg:col-span-4' : ''"
    >
      <div class="sm:sticky sm:top-40">
        <div class="">
          <h1
            class="relative pb-5 font-primary text-2xl font-bold sm:mt-5 sm:text-4xl"
            v-if="isRefineClicked == false"
          >
            {{ __('Search results') }}
          </h1>
        </div>
        <SearchCandidateFilterViewVue
          :filterAvailability="filterAvailability"
          :filterContactDetails="filterContactDetails"
          :filterAbout="filterAbout"
          :degrees="degrees"
          :filterExperience="filterExperience"
          :skills="skills"
          :testedSkills="testedSkills"
          :languages="languages"
          :config_display_users_anonymously="config_display_users_anonymously"
          v-if="isRefineClicked == false"
          class=""
        />
        <div class="flex justify-center py-5" v-if="isRefineClicked == false">
          <Button
            @click="isRefineClicked = !isRefineClicked"
            content="Refine results"
            variation="fill"
          />
        </div>
      </div>
      <SearchCandidateFilter
        :filterAvailability="filterAvailability"
        :filterContactDetails="filterContactDetails"
        :filterAbout="filterAbout"
        :degrees="degrees"
        :filterExperience="filterExperience"
        :skills="skills"
        :testedSkills="testedSkills"
        :languages="languages"
        :isRefineClicked="isRefineClicked"
        :markMandatory="markMandatory"
        :config_display_users_anonymously="config_display_users_anonymously"
        @switchMandatory="switchMandatory"
        @searchAgain="searchAgain"
        @updateCandidates="updateCandidates"
        v-if="isRefineClicked == true"
      />
      <div
        class="flex items-center justify-center gap-2 py-5 text-xs sm:text-base"
        v-if="isRefineClicked == true"
      >
        <Button
          @click="[(isRefineClicked = !isRefineClicked), searchAgain()]"
          content="Search again"
          variation="fill"
        />
      </div>
    </div>
    <div
      class="col-span-1 hidden h-full justify-center lg:flex"
      v-if="isRefineClicked == true"
    >
      <div class="h-full w-1 bg-gray-200"></div>
    </div>
    <div
      class=""
      :class="
        isRefineClicked == true
          ? 'col-span-8 lg:col-span-5 lg:-mt-2 lg:pt-0 xl:mt-0 xl:pt-5 2xl:mt-1 2xl:pt-16'
          : 'col-span-8 lg:col-span-7 '
      "
    >
      <SearchCandidateShow
        :allChecked="allChecked"
        :candidates="candidates"
        :isRefineClicked="isRefineClicked"
        :page="page"
        :available_pipelines="available_pipelines"
        :available_jobs="available_jobs"
        :config_general_single_company="config_general_single_company"
        :config_display_users_anonymously="config_display_users_anonymously"
        @updateCandidates="updateCandidates"
        @searchAgain="searchAgain"
        ref="searchCandidateShow"
      />

      <div
        v-if="isLoading"
        class="h-8 w-full py-7 text-center font-primary font-bold"
      >
        <h2>
          {{ __('Loading...') }}
        </h2>
      </div>
      <div
        v-else-if="candidates.length == 0"
        class="h-8 w-full py-7 text-center font-primary font-bold"
      >
        <h2>
          {{ __('No candidates!') }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import {
    SearchCandidateFilter,
    SearchCandidateFilterViewVue,
    SearchCandidateShow
  } from '.';
  import { __ } from '../../../helpers';
  import { Button } from '../../global';

  const props = defineProps({
    filterAvailability: Array,
    filterContactDetails: Array,
    filterAbout: Array,
    isRefineClicked: Boolean,
    degrees: Array,
    filterExperience: Object,
    skills: Array,
    testedSkills: Array,
    languages: Array,
    candidates: Array,
    page: Number,
    markMandatory: Boolean,
    available_pipelines: Object,
    available_jobs: Object,
    config_general_single_company: Number,
    config_display_users_anonymously: Number,
    isLoading: {
      type: Boolean,
      default: false
    }
  });

  const allChecked = ref(false);
  const isRefineClicked = ref(false);

  const emit = defineEmits([
    'searchAgain',
    'updateCandidates',
    'switchMandatory',
    'removeCheckedNames'
  ]);

  const searchAgain = () => {
    emit('searchAgain');
    callRemoveCheckedNames();
  };

  const updateCandidates = () => {
    emit('updateCandidates');
  };

  const switchMandatory = () => {
    emit('switchMandatory');
  };

  // Create a ref to clean the checked names when search again is clicked
  const searchCandidateShow = ref(null);
  function callRemoveCheckedNames() {
    searchCandidateShow.value.removeCheckedNames();
  }
</script>
