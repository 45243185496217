// import the translations from the JSON files
import ro from '../../../lang/ro.json';
import es from '../../../lang/es.json';
import fr from '../../../lang/fr.json';
import de from '../../../lang/de.json';
import hu from '../../../lang/hu.json';

export const __ = (value, replacements = {}) => {
  const lang = localStorage.getItem('locale');
  const languageMappings = {
    ro: ro,
    es: es,
    de: de,
    fr: fr,
    hu: hu
  };

  const selectedLanguage = languageMappings[lang];
  let translatedValue =
    selectedLanguage && selectedLanguage[value]
      ? selectedLanguage[value]
      : value;

  Object.keys(replacements).forEach((key) => {
    translatedValue = translatedValue.replace(`{${key}}`, replacements[key]);
  });

  return translatedValue;
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
