<template>
  <div class="shadow-card space-y-6 rounded-lg bg-white p-6 md:p-8">
    <div class="space-y-3">
      <span class="block text-lg font-medium text-zinc-800">
        {{ __('Location') }}
      </span>

      <div class="flex gap-1.5">
        <Icon name="distance" is-filled class="text-xl text-primary" />
        <span class="text-sm text-zinc-800">{{ location }}</span>
      </div>
    </div>

    <div class="space-y-3">
      <span class="block text-lg font-medium text-zinc-800">
        {{ __('About the group') }}
      </span>

      <p class="block leading-normal text-zinc-600">
        <span>{{ description }}</span>

        <button
          v-if="isDescriptionExpandButtonVisible"
          class="text-primary underline-offset-2 hover:underline"
          @click="handleDescriptionExpandButtonClick"
        >
          {{ __('see more') }}
        </button>
      </p>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    details: {
      type: Object,
      required: true
    }
  });

  const location = computed(() => {
    return props.details.location.name;
  });

  const description = computed(() => {
    return props.details.description.length <= 250 ||
      isDescriptionExpanded.value
      ? props.details.description
      : `${props.details.description.substring(0, 250)}...`;
  });

  const isDescriptionExpandButtonVisible = computed(() => {
    return (
      props.details.description.length > 250 && !isDescriptionExpanded.value
    );
  });

  const isDescriptionExpanded = ref(false);

  function handleDescriptionExpandButtonClick() {
    isDescriptionExpanded.value = true;
  }
</script>
