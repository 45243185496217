<template>
  <div class="space-y-6">
    <div
      v-if="group"
      class="ml-auto flex w-fit flex-wrap items-center gap-x-4 gap-y-2 rounded-lg bg-white px-4 py-2"
    >
      <div class="flex items-center gap-2">
        <Icon name="group_work" is-filled class="text-2xl text-primary" />
        <span class="font-medium leading-normal text-zinc-800">
          {{ group.name }}
        </span>
      </div>

      <Button :href="group.url" variant="ghost">
        {{ __('Go to group') }}
      </Button>
    </div>

    <div class="space-y-12">
      <Header v-model:event="event" v-model:user="user" />

      <TabGroup>
        <TabList class="flex gap-2 overflow-auto rounded-lg bg-white p-3">
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
            >
              {{ __('About') }}
            </button>
          </Tab>
          <Tab v-if="renderAttendeesTab" v-slot="{ selected }" as="template">
            <button
              :class="`${selected ? 'bg-secondary/5' : 'bg-white'} rounded-md px-8 py-3 font-medium leading-normal text-zinc-800 outline-none transition-colors hover:bg-secondary/5`"
            >
              {{ __('Attendees') }}
            </button>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <About :event="event" />
          </TabPanel>
          <TabPanel v-if="renderAttendeesTab">
            <Attendees :event="event" :user="user" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { About } from './components/event/about';
  import { Attendees } from './components/event/attendees';
  import { Header } from './components/event/header';

  const props = defineProps({
    event: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  });

  const renderAttendeesTab = computed(() => {
    return !!props.user.id;
  });

  const group = computed(() => {
    if (!event.value.group?.id) {
      return;
    }

    const name = event.value.group.name;
    const url = route('redesign.groups.view', {
      slug: event.value.group.slug
    });

    return { name, url };
  });

  const event = ref(JSON.parse(JSON.stringify(props.event)));
  const user = ref(JSON.parse(JSON.stringify(props.user)));
</script>
