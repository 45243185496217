<template>
  <Modal
    open
    :title="__(experience ? 'Edit experience' : 'Add experience')"
    @close="handleCancelButtonClick"
  >
    <form @submit.prevent="handleSubmit">
      <div class="grid gap-6 md:grid-cols-2">
        <InputText
          id="position"
          v-model:value="values.position"
          v-model:errors="errors.position"
          :label="__('Position')"
          :placeholder="__('Position')"
          required
        />

        <InputText
          id="company"
          v-model:value="values.company"
          v-model:errors="errors.company"
          :label="__('Company')"
          :placeholder="__('Company')"
          required
        />

        <InputSelect
          id="employment_type"
          v-model:value="values.employment_type"
          v-model:errors="errors.employment_type"
          :label="__('Employment type')"
          :placeholder="__('Employment type')"
          :options="options.employment_type"
          class="md:col-span-2"
        />

        <InputLocation
          id="location"
          v-model:value="values.location"
          v-model:errors="errors.location"
          :label-country="__('Country')"
          :label-city="__('City')"
          :placeholder-country="__('Select country')"
          :placeholder-city="__('Select city')"
          :options="options.country"
          class="md:col-span-2"
        />

        <InputDate
          id="start_date"
          v-model:value="values.start_date"
          v-model:errors="errors.start_date"
          :label="__('Start date')"
          :placeholder="__('Pick a date')"
          required
          :max-value="
            values.end_date ? values.end_date : formatInputDate(new Date())
          "
        />

        <InputDate
          id="end_date"
          v-model:value="values.end_date"
          v-model:errors="errors.end_date"
          :label="__('End date')"
          :placeholder="__('Pick a date')"
          :disabled="values.current"
          required
          :min-value="values.start_date ? values.start_date : ''"
          :max-value="formatInputDate(new Date())"
        />

        <InputCheckbox
          id="current"
          v-model:value="values.current"
          v-model:errors="errors.current"
          :label="__('I currently work here')"
          class="md:col-span-2"
          @update:value="handleCurrentUpdate"
        />

        <InputTextarea
          id="description"
          v-model:value="values.description"
          v-model:errors="errors.description"
          :label="__('Description')"
          :placeholder="
            __(
              'Add responsibilities, requirements, achievements or anything else that might be relevant for this role.'
            )
          "
          class="md:col-span-2"
        />
      </div>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </Modal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { formatInputDate } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Modal } from '@/components/block/modal';
  import { InputCheckbox } from '@/components/form/input-checkbox';
  import { InputDate } from '@/components/form/input-date';
  import { InputLocation } from '@/components/form/input-location';
  import { InputSelect } from '@/components/form/input-select';
  import { InputText } from '@/components/form/input-text';
  import { InputTextarea } from '@/components/form/input-textarea';

  const props = defineProps({
    experience: {
      type: [Object, null],
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['cancel', 'save']);

  const toast = useToast();

  const experience = ref(JSON.parse(JSON.stringify(props.experience)));
  const values = ref({
    position: experience.value?.position || '',
    company: experience.value?.company || '',
    employment_type: experience.value?.employment_type || null,
    location: experience.value?.location || null,
    start_date: experience.value?.start_date || '',
    end_date: experience.value?.end_date || '',
    current: experience.value?.current ? true : false,
    description: experience.value?.description || ''
  });
  const errors = ref({
    position: [],
    company: [],
    employment_type: [],
    location: [],
    start_date: [],
    end_date: [],
    current: [],
    description: []
  });
  const isLoading = ref(false);

  function handleCurrentUpdate() {
    values.value.end_date = '';
    errors.value.end_date = [];
  }

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    const requiredFieldKeys = ['position', 'company', 'start_date'];

    if (!values.value.current) {
      requiredFieldKeys.push('end_date');
    }

    let firstElement;

    requiredFieldKeys.forEach((key) => {
      if (!values.value[key]) {
        errors.value[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (
      values.value.location &&
      values.value.location.country &&
      !values.value.location.city
    ) {
      errors.value.location = [__('This field is required')];

      const element = document.getElementById('location');

      if (element && !firstElement) {
        firstElement = element;
      }
    }

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      let response;

      if (experience.value) {
        response = await axios.put(
          route('redesign.cv.update.experience', {
            id: experience.value.id,
            ...values.value
          })
        );
      } else {
        response = await axios.post(
          route('redesign.cv.save.experience', values.value)
        );
      }

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', {
        experience: {
          id: experience.value?.id || response.data.data,
          ...values.value
        },
        fields: response.data.fields,
        sectionPercentage: response.data.section_percentage,
        resumePercentage: response.data.percentage
      });
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = error.response.data.errors;
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
