<template>
  <div>
    <div class="mt-4 rounded-app bg-white p-8 shadow-md">
      <!-- TOP -->
      <div class="flex gap-4">
        <div class="flex items-center text-sm">
          <Icon name="calendar_month" class="mr-2 text-primary" />
          <span class="text-body">{{ __('Posted') }} {{ job.posted_at }}</span>
        </div>

        <div class="flex items-center text-sm">
          <Icon name="visibility" class="mr-2 text-primary" />
          <span class="text-body"
            >{{ job.job_views_count }} {{ __('views') }}</span
          >
        </div>

        <div class="hidden items-center text-sm md:flex">
          <Icon name="share" class="mr-2 text-primary" />
          <span class="text-body"> {{ __('Share this job') }}</span>
          <div class="ml-4 flex gap-2">
            <a
              :href="`https://www.linkedin.com/sharing/share-offsite/?url=${job.url}`"
              target="_blank"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.4325C0 0.64125 0.6575 0 1.46875 0H18.5312C19.3425 0 20 0.64125 20 1.4325V18.5675C20 19.3587 19.3425 20 18.5312 20H1.46875C0.6575 20 0 19.3587 0 18.5675V1.4325ZM6.17875 16.7425V7.71125H3.1775V16.7425H6.17875ZM4.67875 6.4775C5.725 6.4775 6.37625 5.785 6.37625 4.9175C6.3575 4.03125 5.72625 3.3575 4.69875 3.3575C3.67125 3.3575 3 4.0325 3 4.9175C3 5.785 3.65125 6.4775 4.65875 6.4775H4.67875ZM10.8137 16.7425V11.6988C10.8137 11.4288 10.8337 11.1587 10.9137 10.9662C11.13 10.4275 11.6238 9.86875 12.4538 9.86875C13.54 9.86875 13.9738 10.6963 13.9738 11.9113V16.7425H16.975V11.5625C16.975 8.7875 15.495 7.4975 13.52 7.4975C11.9275 7.4975 11.2137 8.3725 10.8137 8.98875V9.02H10.7937C10.8004 9.00957 10.8071 8.99915 10.8137 8.98875V7.71125H7.81375C7.85125 8.55875 7.81375 16.7425 7.81375 16.7425H10.8137Z"
                  fill="#52525B"
                />
              </svg>
            </a>
            <a
              :href="`https://www.facebook.com/sharer/sharer.php?u=${job.url}`"
              target="_blank"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.9 0H1.1C0.808262 0 0.528473 0.115893 0.322183 0.322183C0.115893 0.528473 0 0.808262 0 1.1V18.9C0 19.1917 0.115893 19.4715 0.322183 19.6778C0.528473 19.8841 0.808262 20 1.1 20H10.68V12.25H8.08V9.25H10.68V7C10.6261 6.47176 10.6885 5.93813 10.8627 5.43654C11.0369 4.93495 11.3188 4.47755 11.6885 4.09641C12.0582 3.71528 12.5068 3.41964 13.0028 3.23024C13.4989 3.04083 14.0304 2.96225 14.56 3C15.3383 2.99521 16.1163 3.03528 16.89 3.12V5.82H15.3C14.04 5.82 13.8 6.42 13.8 7.29V9.22H16.8L16.41 12.22H13.8V20H18.9C19.0445 20 19.1875 19.9715 19.321 19.9163C19.4544 19.861 19.5757 19.78 19.6778 19.6778C19.78 19.5757 19.861 19.4544 19.9163 19.321C19.9715 19.1875 20 19.0445 20 18.9V1.1C20 0.955546 19.9715 0.812506 19.9163 0.679048C19.861 0.54559 19.78 0.424327 19.6778 0.322183C19.5757 0.220038 19.4544 0.139013 19.321 0.0837326C19.1875 0.0284524 19.0445 0 18.9 0Z"
                  fill="#52525B"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <!-- /TOP -->

      <!-- HERO -->
      <div class="mt-8">
        <div class="md:flex md:justify-between">
          <div class="flex justify-between">
            <a
              :href="
                route('redesign.company.view', { company: job.company.slug })
              "
              target="_BLANK"
            >
              <div
                class="flex items-center gap-2"
                v-if="!config_general_single_company"
              >
                <img
                  :src="job.company.logo"
                  alt="Company logo"
                  class="h-12 w-12 rounded-full"
                />
                <div>
                  <h3 class="my-2 text-lg text-[#212429]">
                    {{ job.company.name }}
                  </h3>
                  <p class="text-sm text-body">
                    {{ job.company.domain }}
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div v-if="job.internal" class="mt-4 md:mt-0">
            <span class="flex items-center text-sm text-body">
              <Icon name="apartment" class="mr-2 !text-sm text-primary" />
              {{ __('Internal job') }}
            </span>
          </div>
        </div>

        <div class="mt-4">
          <h1 class="my-4 text-3xl font-medium text-[#212429]">
            <Icon
              name="work"
              :is_filled="true"
              class="text-primary"
              v-if="config_general_single_company"
            />
            {{ job.title }}
          </h1>
        </div>

        <div class="flex items-center gap-2" v-if="job.city">
          <Icon name="distance" class="text-primary" />
          <span v-for="(city, index) in job.city" :key="index" class="text-body"
            >{{ city.name }}
            <span v-if="index !== job.city.length - 1">, </span>
          </span>
        </div>

        <div v-if="job.conditions.can_edit">
          <JobQuickEdit :job="job" />
        </div>

        <div class="mt-8 items-center justify-between md:flex">
          <div>
            <div v-if="user_type === 'user'">
              <div v-if="job.conditions.is_matched">
                <Button
                  content="Matched"
                  class="!border-success !bg-success"
                  type="button"
                  variation="fill"
                />
              </div>
              <div v-if="job.conditions.is_applied">
                <Button
                  content="Applied"
                  class="!border-success !bg-success"
                  type="button"
                  variation="fill"
                />
              </div>
              <div v-else-if="job.conditions.is_invited">
                <Button
                  content="Accept invitation"
                  type="button"
                  variation="fill"
                  @click="handleAcceptInvitation"
                  :disabled="loading_handle_invitation || !job.active"
                />
                <Button
                  content="Decline"
                  class="ml-2"
                  type="button"
                  variation="outline"
                  @click="handleDeclineInvitation"
                  :disabled="loading_handle_invitation || !job.active"
                />
                <p v-if="!job.active" class="mt-4 text-body">
                  {{ __("The job has expired and you can no longer apply for it.") }}
                </p>
              </div>
              <div v-else-if="job.conditions.can_apply">
                <div v-if="job.conditions.is_cv_completed">
                  <Button
                    :content="loadingApply ? 'Applying...' : 'Apply now'"
                    :class="
                      job.conditions.is_applied
                        ? 'border-success bg-success'
                        : ''
                    "
                    :disabled="loadingApply"
                    type="button"
                    variation="fill"
                    @click="handleApply"
                  />
                </div>
                <div v-else>
                  <Button
                    content="Apply now"
                    disabled
                    type="button"
                    variation="fill"
                  />
                  <p class="mt-4 text-body">
                    {{
                      __(
                        "You cannot apply because you don't have the profile completed."
                      )
                    }}
                    <a
                      :href="user_profile"
                      class="font-bold text-primary underline"
                      >{{ __('Go to profile') }}</a
                    >
                  </p>
                </div>
              </div>
            </div>

            <div v-if="user_type === 'none'">
              <a href="/login" target="_BLANK">
                <Button
                  content="Sign in & Apply"
                  type="button"
                  variation="fill"
                />
              </a>
              <Button
                v-if="!showApplyForm"
                content="Easy Apply"
                class="ml-2"
                type="button"
                @click="showApplyForm = !showApplyForm"
              />
            </div>
          </div>

          <div class="mt-4 items-center gap-4 md:mt-0 md:flex">
            <a
              :href="job.url"
              target="_BLANK"
              class="flex items-center text-sm text-primary"
              v-if="job.active"
            >
              <Icon name="open_in_new" class="mr-2" />
              <span class="font-medium">
                {{ __('Go to job page') }}
              </span>
            </a>

            <span
              @click="handleSave"
              class="flex cursor-pointer items-center text-sm"
              role="button"
              v-if="user_type === 'user' && job.active"
            >
              <Icon
                name="bookmark"
                class="mr-2 text-primary"
                :is_filled="job.conditions.is_saved"
                fill_color="#10b981"
              />
              <span class="font-medium text-primary">
                {{ job.conditions.is_saved ? __('Saved') : __('Save') }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <!-- HERO -->
    </div>

    <div
      v-if="show_reject_reason"
      class="mt-4 rounded-app bg-white p-8 shadow-md"
    >
      <h3 class="mb-2 text-lg font-semibold text-zinc-800">
        {{ __('Reason for rejecting this invitation') }}
      </h3>
      <p class="text-sm text-zinc-800">
        {{
          __(
            'Could you please tell us why you declined the offer? Your feedback will help in refining our screening process for your profile.'
          )
        }}
      </p>
      <form @submit.prevent="handleRejectReason()">
        <Textarea
          v-model="reject_reason"
          :value="reject_reason"
          placeholder="Reason for rejecting this invitation"
          class="mt-4"
        />
        <div class="mt-4">
          <Button content="Send" icon="send" variation="fill" class="mr-2" />
          <Button
            content="Skip"
            icon="cancel"
            class="text-[#52525B]"
            variation="outline"
            type="button"
            @click="() => handleSkipReason()"
          />
        </div>
      </form>
    </div>

    <div v-if="showApplyForm" class="my-8">
      <h5 class="mb-4 text-lg font-medium">
        {{ __('Unlock your job opportunities. Apply with your CV.') }}
      </h5>

      <ApplyWithoutAccountForm
        @close="showApplyForm = !showApplyForm"
        :job="job"
        :options="options"
      />
    </div>

    <!-- <hr class="my-8" /> -->

    <!-- Job Overview -->
    <div class="my-8">
      <h2 class="mb-4 text-xl font-semibold">
        {{ __('Job overview') }}
      </h2>
      <JobOverview :job="job" class="mt-4 rounded-app bg-white p-8 shadow-md" />
    </div>
    <!-- /Job Overview -->

    <!-- <hr class="my-8" /> -->

    <!-- Job Description -->
    <div v-if="job.description" class="my-8">
      <h2 class="mb-4 text-xl font-semibold">
        {{ __('Job description') }}
      </h2>
      <div class="mt-4 rounded-app bg-white p-8 text-body shadow-md">
        <FormatedHTML :content="job.description" />
      </div>
    </div>
    <!-- /Job Description -->

    <!-- Experience needed -->
    <div v-if="renderExperienceNeededSection" class="my-8">
      <h2 class="my-4 text-xl font-semibold">
        {{ __('Experience needed') }}
      </h2>
      <div class="mt-4 rounded-app bg-white p-8 text-body shadow-md">
        <FormatedHTML :content="job.experience_needed" />
      </div>
    </div>
    <!-- /Experience needed -->

    <!-- Inclusion details -->
    <div v-if="renderInclusionDetailsSection" class="my-8">
      <h2 class="my-4 text-xl font-semibold">
        {{ __('Job Inclusivity') }}
      </h2>
      <div class="mt-4 rounded-app bg-white p-8 text-body shadow-md">
        <div v-if="job?.vulnerability?.length > 0" class="mb-4">
          <h3 class="text-lg font-semibold">
            {{ __('Vulnerabilities') }}
          </h3>
          <div>
            <span
              v-for="(vulnerability, index) in job.vulnerability"
              :key="index"
            >
              <span>
                {{ vulnerability.name }}
              </span>
              <span
                v-if="index !== job.vulnerability.length - 1"
                class="px-2 text-primary"
              >
                |
              </span>
            </span>
          </div>
        </div>
        <div v-if="job?.disability?.length > 0" class="mb-4">
          <h3 class="text-lg font-semibold">
            {{ __('Disabilities') }}
          </h3>
          <div>
            <span v-for="(disability, index) in job.disability" :key="index">
              <span>
                {{ disability.name }}
              </span>
              <span
                v-if="index !== job.disability.length - 1"
                class="px-2 text-primary"
              >
                |
              </span>
            </span>
          </div>
        </div>
        <FormatedHTML
          :content="job.inclusion_details"
          v-if="job.inclusion_details"
        />
      </div>
    </div>
    <!-- /Inclusion details -->

    <!-- <hr class="my-8" /> -->

    <!-- Skills -->
    <div class="my-8">
      <h2 class="mb-4 text-xl font-semibold">
        {{ __('Skills') }}
      </h2>
      <div class="mt-4 rounded-app bg-white p-8 shadow-md">
        <div v-if="job.skills.required.length > 0">
          <h3 class="mb-2 text-lg font-semibold">
            {{ __('Required skills') }}
          </h3>
          <SkillsView :skills="convertArray(job.skills.required)" />
        </div>

        <div v-if="job.skills.optional.length > 0">
          <hr class="my-2" />

          <h3 class="mb-2 text-lg font-semibold">
            {{ __('Optional skills') }}
          </h3>
          <SkillsView :skills="convertArray(job.skills.optional)" />
        </div>
      </div>
    </div>

    <!-- <hr class="my-8" /> -->

    <!-- Values & Perks -->
    <div class="my-8">
      <div class="md:grid md:grid-cols-2 md:gap-x-20">
        <div v-if="job.values.length > 0">
          <h2 class="mb-4 text-xl font-semibold">
            {{ __('Values') }}
          </h2>
          <ViewChips
            :data="job.values"
            class="mt-4 rounded-app bg-white p-8 shadow-md"
          />
        </div>
        <div v-if="job.perks.length > 0" class="mt-8 md:mt-0">
          <h2 class="mb-4 text-xl font-semibold">
            {{ __('Perks') }}
          </h2>
          <ViewChips
            :data="job.perks"
            class="mt-4 rounded-app bg-white p-8 shadow-md"
          />
        </div>
      </div>
    </div>

    <!-- <hr class="my-8" /> -->

    <!-- Languages & Recruitment Process -->
    <div>
      <div>
        <div v-if="job.languages.length > 0" class="my-8">
          <h2 class="mb-4 text-xl font-semibold">
            {{ __('Languages') }}
          </h2>
          <ViewLanguages
            :languages="job.languages"
            :columns="2"
            class="mt-4 rounded-app bg-white p-8 shadow-md"
          />
        </div>

        <div v-if="job.recruitment_process.length > 0" class="my-8">
          <h2 class="mb-4 text-xl font-semibold">
            {{ __('Recruitment process') }}
          </h2>
          <RecruitmentProcess :columns="5" :process="job.recruitment_process" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, reactive, ref } from 'vue';

  import {
    Button,
    FormatedHTML,
    Icon,
    Textarea
  } from '../../../../components/global';
  import { __ } from '../../../../helpers';
  import { SkillsView, ViewChips, ViewLanguages } from '../components';
  import {
    ApplyWithoutAccountForm,
    JobOverview,
    JobQuickEdit,
    RecruitmentProcess
  } from '../components';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';

  const props = defineProps({
    job: Object,
    user_type: {
      type: String,
      required: true
    },
    user_profile: {
      type: String,
      required: false
    },
    config_general_single_company: {
      type: Number,
      required: true
    },
    options: {
      type: Object
    }
  });

  const job = reactive(props.job);

  const toast = useToast();

  // Fix for the future field added on a skill
  const convertArray = (array) => {
    let newArray = [];
    array.forEach((element) => {
      newArray.push(element.skill);
    });
    return newArray;
  };

  const loadingApply = ref(false);

  const renderExperienceNeededSection = computed(() => {
    return (
      props.job.experience_needed &&
      props.job.experience_needed.trim() !== '<p></p>'
    );
  });

  const renderInclusionDetailsSection = computed(() => {
    return (
      (props.job.inclusion_details &&
        props.job.inclusion_details.trim() !== '<p></p>') ||
      props.job.vulnerability?.length ||
      props.job.disability?.length
    );
  });

  const handleApply = () => {
    if (job.conditions.is_applied) {
      toast.error(__('You have already applied to this job.'));
      return;
    }

    loadingApply.value = true;

    axios
      .post(
        route('redesign.jobs.apply', {
          company: job.company.slug,
          job: job.slug
        })
      )
      .then((response) => {
        console.log(response.data);

        job.conditions.is_applied = !job.conditions.is_applied;
        toast.success(response.data.message);
        loadingApply.value = false;
      })
      .catch((error) => {
        toast.error(error.response.data.message || __('An error occurred.'));
        console.log(error);
        loadingApply.value = false;
      });
  };

  const handleSave = () => {
    console.log('Save');
    console.log(job.conditions.is_saved);

    axios
      .post(
        route('redesign.jobs.save', {
          company: job.company.slug,
          job: job.slug
        }),
        { saved: !job.conditions.is_saved }
      )
      .then((response) => {
        console.log(response.data);

        job.conditions.is_saved = !job.conditions.is_saved;
        toast.success(response.data.message);

        emit('save', job);
      })
      .catch((error) => {
        toast.error(error.response.data.message || __('An error occurred.'));
        console.log(error);
      });
  };

  const showApplyForm = ref(false);

  const emit = defineEmits(['save', 'accept_invitation', 'decline_invitation']);

  // Reject Reason
  const reject_reason = ref('');
  const show_reject_reason = ref(false);
  const loading_handle_invitation = ref(false);

  const handleAcceptInvitation = () => {
    loading_handle_invitation.value = true;

    axios
      .post(
        route('redesign.jobs.accept-invitation', {
          company: job.company.slug,
          job: job.slug
        })
      )
      .then((response) => {
        console.log(response.data);

        // is_apllied true
        job.conditions.is_applied = true;

        // is_invited false
        emit('accept_invitation', job);
        toast.success(response.data.message);

        loading_handle_invitation.value = false;
      })
      .catch((error) => {
        toast.error(error.response.data.message || __('An error occurred.'));
        console.log(error);

        loading_handle_invitation.value = false;
      });
  };

  const handleDeclineInvitation = () => {
    loading_handle_invitation.value = true;

    axios
      .post(
        route('redesign.jobs.reject-invitation', {
          company: job.company.slug,
          job: job.slug
        })
      )
      .then((response) => {
        console.log(response.data);

        job.conditions.is_invited = false;
        job.conditions.can_apply = false;

        emit('decline_invitation', job);

        show_reject_reason.value = true;
        toast.success(response.data.message);

        loading_handle_invitation.value = false;
      })
      .catch((error) => {
        toast.error(error.response.data.message || __('An error occurred.'));
        console.log(error);
        // loadingApply.value = false;

        loading_handle_invitation.value = false;
      });
  };

  const handleRejectReason = () => {
    // check if the reject_reason is not empty
    if (reject_reason.value === '') {
      toast.error(__('Please provide a reason for rejecting this invitation'));
      return;
    }

    axios
      .post(
        route('redesign.invitations.reject-reason', {
          invitation: job.conditions.invitation_id
        }),
        {
          reject_reason: reject_reason.value
        }
      )
      .then((response) => {
        console.log(response.data);

        reject_reason.value = '';
        show_reject_reason.value = false;
        // TODO: change state

        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message || __('An error occurred.'));
        console.log(error);
        // loadingApply.value = false;
      });
  };

  const handleSkipReason = () => {
    reject_reason.value = '';
    show_reject_reason.value = false;
  };
</script>
