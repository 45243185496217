<template>
  <div class="candidate-gamification__header">
    <ul class="candidate-gamification__header--list">
      <li class="candidate-gamification__header--item" data-tab="profile">
        <svg
          class="plus_svg"
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="18"
        >
          <use
            xlink:href="/images/front/r_SVG-sprite.svg#gamification-user"
          ></use>
        </svg>
      </li>
      <li class="candidate-gamification__header--item" data-tab="quests">
        <svg
          class="plus_svg"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
        >
          <use
            xlink:href="/images/front/r_SVG-sprite.svg#gamification-flag"
          ></use>
        </svg>
      </li>
      <li class="candidate-gamification__header--item" data-tab="news">
        <svg
          class="plus_svg"
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="21"
        >
          <use
            xlink:href="/images/front/r_SVG-sprite.svg#gamification-notification"
          ></use>
        </svg>
      </li>
      <li class="candidate-gamification__header--item" data-tab="badges">
        <svg
          class="plus_svg"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="19"
        >
          <use
            xlink:href="/images/front/r_SVG-sprite.svg#gamification-star"
          ></use>
        </svg>
      </li>
      <li class="candidate-gamification__header--item" data-tab="leaderboard">
        <svg
          class="plus_svg"
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="16"
        >
          <use
            xlink:href="/images/front/r_SVG-sprite.svg#gamification-graph"
          ></use>
        </svg>
      </li>
    </ul>
  </div>
</template>

<script></script>
