<template>
  <div class="shadow-card space-y-5 rounded-2xl bg-white p-6 md:p-8">
    <div v-if="renderContactDetails">
      <h3 class="text-lg font-medium text-zinc-800">{{ __('Contact') }}</h3>

      <div class="mt-4 flex flex-wrap gap-x-4 gap-y-2">
        <a
          v-if="renderEmail"
          class="flex items-center gap-1.5"
          :href="`mailto:${data.email.value}`"
        >
          <Icon
            class="text-lg text-primary"
            name="mail"
            is-filled
            :aria-label="__('Email')"
          />
          <span class="text-sm text-zinc-800">{{ data.email.value }}</span>
        </a>
        <a
          v-if="renderPhone"
          class="flex items-center gap-1.5"
          :href="`tel:${data.country_prefix.prefix}${data.phone_number.value}`"
        >
          <Icon
            class="text-lg text-primary"
            name="phone"
            is-filled
            :aria-label="__('Phone number')"
          />
          <span class="text-sm text-zinc-800">
            {{ data.country_prefix.prefix }}
            {{ formatPhoneNumber(data.phone_number.value) }}
          </span>
        </a>

        <div v-if="renderLocation" class="flex items-center gap-1.5">
          <Icon
            class="text-lg text-primary"
            name="distance"
            is-filled
            :aria-label="__('Location')"
          />
          <span class="text-sm text-zinc-800">
            {{
              `${data.address}, ${data.location.city.name}, ${data.location.country.name}`
            }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="data.benefits && data.benefits.length">
      <h3 class="text-lg font-medium text-zinc-800">{{ __('Benefits') }}</h3>

      <div
        class="mt-4 flex flex-wrap gap-x-4 gap-y-2 divide-x-2 divide-primary"
      >
        <span
          v-for="benefit in data.benefits"
          :key="benefit.id"
          class="block pl-4 leading-tight text-zinc-600 first:!pl-0"
        >
          {{ benefit.name }}
        </span>
      </div>
    </div>

    <div v-if="data.values && data.values.length">
      <h3 class="text-lg font-medium text-zinc-800">{{ __('Values') }}</h3>

      <div
        class="mt-4 flex flex-wrap gap-x-4 gap-y-2 divide-x-2 divide-primary"
      >
        <span
          v-for="value in data.values"
          :key="value.id"
          class="block pl-4 leading-tight text-zinc-600 first:!pl-0"
        >
          {{ value.name }}
        </span>
      </div>
    </div>

    <div v-if="data.about_us">
      <h3 class="text-lg font-medium text-zinc-800">{{ __('About us') }}</h3>

      <HtmlRenderer class="mt-4" :content="data.about_us" />
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { HtmlRenderer } from '@/components/other/html-renderer';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    }
  });

  const renderContactDetails = computed(() => {
    return renderEmail.value || renderPhone.value || renderLocation.value;
  });

  const renderEmail = computed(() => {
    return (
      props.data.email && props.data.email.value && props.data.email.is_visible
    );
  });

  const renderPhone = computed(() => {
    return (
      props.data.country_prefix &&
      props.data.country_prefix.prefix &&
      props.data.phone_number &&
      props.data.phone_number.value &&
      props.data.phone_number.is_visible
    );
  });

  const renderLocation = computed(() => {
    return (
      props.data.location &&
      props.data.location.country &&
      props.data.location.city &&
      props.data.address
    );
  });

  function formatPhoneNumber(value) {
    return value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6);
  }
</script>
