<template>
  <div>
    <div v-if="error" id="quests" class="candidate-gamification__tab">
      <div class="matching-lists__message">
        <p style="text-align: center">
          {{ __("Something went wrong. Please check again later.") }}
        </p>
      </div>
    </div>

    <div v-show="user" id="quests" class="candidate-gamification__tab">
      <div class="candidate-gamification__module-header">
        <h3>{{ __("Quest list") }}</h3>
      </div>
      <div class="candidate-gamification__module-cnt">
        <div
          class="candidate-gamification__slider cycle-slideshow"
          data-cycle-fx="fade"
          data-cycle-timeout="0"
          data-cycle-slides=".candidate-gamification__slide"
          data-cycle-pager=".candidate-gamification__slider--pager"
          data-cycle-swipe="true"
          data-cycle-next=".candidate-gamification__slider--next"
          data-cycle-prev=".candidate-gamification__slider--prev"
          data-cycle-allow-wrap="false"
        >
          <div class="candidate-gamification__slider--footer">
            <div class="candidate-gamification__slider--prev">
              <svg class="arrow_left" xmlns="http://www.w3.org/2000/svg">
                <use
                  xlink:href="/images/front/r_SVG-sprite.svg#arrow_left"
                ></use>
              </svg>
            </div>
            <div class="candidate-gamification__slider--pager show"></div>
            <div class="candidate-gamification__slider--next">
              <svg class="arrow_right" xmlns="http://www.w3.org/2000/svg">
                <use
                  xlink:href="/images/front/r_SVG-sprite.svg#arrow_right"
                ></use>
              </svg>
            </div>
          </div>

          <div v-for="index in 7" class="candidate-gamification__slide">
            <div v-if="user" class="candidate-gamification__slide--cnt">
              <img
                :src="user.quests[index - 1]['img']"
                alt=""
                style="max-width: 80%"
              />
              <h4>{{ user.quests[index - 1]["name"] }}</h4>
              <p>{{ user.quests[index - 1]["description"] }}</p>

              <a
                v-if="
                  !user.quests[index - 1].completed &&
                  user.quests[index - 1].expired
                "
                class="btn"
                disabled
                >{{ __("Expired") }}</a
              >
              <a
                v-if="
                  !user.quests[index - 1].completed &&
                  !user.quests[index - 1].expired
                "
                :href="user.quests[index - 1].url"
                class="btn"
                >{{ __("Complete") }}</a
              >
              <div v-if="!user.quests[index - 1].completed">
                <small>{{ __("Reward") }}:</small>
                <span class="big bc"
                  >{{ user.quests[index - 1]["points"] }}p</span
                >
              </div>

              <a v-if="user.quests[index - 1].completed" class="btn disabled">{{
                __("Completed")
              }}</a>
              <div v-if="user.quests[index - 1].completed">
                <small>
                  <span class="icon-done">
                    <span class="icon-done">
                      <img src="/images/front/circle-done.svg" alt="" />
                    </span>
                  </span>
                  {{ __("Mission accomplished") }}</small
                >
                <span class="big green"
                  >{{ user.quests[index - 1]["points"] }}p</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["error", "user"],
};
</script>
