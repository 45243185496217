<template>
  <div>
    <div>
      <h1 class="text-5xl font-medium leading-none text-zinc-800">
        {{ __('Jobs') }}
      </h1>
      <p class="mt-2 text-base text-zinc-600">
        {{ __('Apply to the most wanted jobs in your industry') }}
      </p>
    </div>

    <hr class="my-8" />

    <div class="mt-8 rounded-app bg-white p-8 shadow-md">
      <div>
        <form @submit.prevent="() => handleSubmit(1)">
          <div class="my-4">
            <Input
              v-model="filters.search"
              :value="filters.search"
              placeholder="Search"
              type="text"
              label="Search job"
            />
          </div>

          <div v-if="isVisibile">
            <Location
              v-model:country="country"
              v-model:value="filters.location"
              :options="options.country"
              :default-country="options.default_country"
              class="md:col-span-2 md:mb-8"
            />

            <div class="gap-4 md:mb-8 md:grid md:grid-cols-3">
              <div class="my-4 md:my-0">
                <label class="mb-2 block text-sm text-[#58585E]">{{
                  __('Career level')
                }}</label>
                <FormMultiselect
                  v-model="filters.career_level"
                  :values="props.options.career_level"
                  :clearable="true"
                  :multiple="true"
                  type="static"
                  class="mt-2"
                />
              </div>

              <div class="my-4 md:my-0">
                <label class="mb-2 block text-sm text-[#58585E]">{{
                  __('On-site/ Remote')
                }}</label>
                <FormMultiselect
                  v-model="filters.presence"
                  :values="props.options.presence"
                  :clearable="true"
                  :multiple="true"
                  type="static"
                  class="mt-2"
                />
              </div>

              <div class="my-4 md:my-0">
                <label class="mb-2 block text-sm text-[#58585E]">{{
                  __('Posted time')
                }}</label>
                <FormMultiselect
                  v-model="filters.posted_at"
                  :values="props.options.posted_at"
                  :clearable="true"
                  :multiple="true"
                  type="static"
                  class="mt-2"
                />
              </div>
            </div>

            <div class="gap-4 md:mb-8 md:grid md:grid-cols-3">
              <div class="my-4 md:my-0">
                <label class="mb-2 block text-sm text-[#58585E]">{{
                  __('Company')
                }}</label>
                <FormMultiselect
                  v-model="filters.company"
                  :values="companies"
                  @query="
                    (query) => {
                      companies = [];
                      getCompaniesByQuery(query);
                    }
                  "
                  @clear="companies = []"
                  :clearable="true"
                  :multiple="true"
                  type="static"
                />
              </div>

              <div class="my-4 md:my-0">
                <label class="mb-2 block text-sm text-[#58585E]">{{
                  __('Language')
                }}</label>
                <FormMultiselect
                  v-model="filters.language"
                  :values="props.options.language"
                  :clearable="true"
                  :multiple="true"
                  type="static"
                  class="mt-2"
                />
              </div>

              <div class="my-4 md:my-0">
                <label class="mb-2 block text-sm text-[#58585E]">{{
                  __('Job type')
                }}</label>
                <FormMultiselect
                  v-model="filters.type"
                  :values="props.options.type"
                  :clearable="true"
                  :multiple="true"
                  type="static"
                  class="mt-2"
                />
              </div>
            </div>
          </div>

          <div class="mt-4 items-center justify-between md:flex">
            <div>
              <Button
                :content="isLoading ? __('Loading...') : __('Search')"
                :disabled="isLoading"
                type="submit"
                variation="fill"
              />

              <Button
                content="Reset filters"
                class="ml-2"
                type="button"
                icon="delete"
                @click="deleteFilters"
              />
            </div>
            <div>
              <Button
                content="Filters"
                class="mt-2 md:ml-2 md:mt-0"
                :icon="isVisibile ? 'expand_less' : 'expand_more'"
                icon_position="right"
                type="button"
                variation="outline"
                @click="isVisibile = !isVisibile"
              />
            </div>
          </div>
        </form>
      </div>

      <div v-if="!isVisibile && checkIfFilters()" class="mt-4">
        <span class="text-sm text-zinc-800"> {{ __('Active filters') }}: </span>
        <div class="mt-2 flex flex-wrap gap-2">
          <template v-for="(value, index) in filters">
            <div
              v-if="value.length && index !== 'page'"
              :key="index"
              class="chip flex items-center gap-1 rounded-app border-0 px-2 py-1 text-sm shadow-sm ring-1 ring-inset ring-secondary focus:ring-2 disabled:cursor-not-allowed disabled:opacity-50 sm:leading-6"
            >
              <span>
                {{
                  __(
                    index.replace(/_/g, ' ').charAt(0).toUpperCase() +
                      index.replace(/_/g, ' ').slice(1)
                  )
                }}:
              </span>

              <span>
                <template v-if="index === 'location'">
                  {{
                    value
                      .map((v) => `${v.city.name}, ${v.country.name}`)
                      .join(' | ')
                  }}
                </template>
                <template v-else>
                  {{
                    Array.isArray(value)
                      ? value.map((v) => v.name || v).join(', ')
                      : value
                  }}
                </template>
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="my-8 grid grid-cols-2">
      <h2 class="text-3xl font-medium text-[#212429]">
        {{ __('Job search results') }}
      </h2>

      <!-- <div class="text-right text-xs">{{ __("Sort") }}</div> -->
    </div>
    <JobsSearchResults
      :jobs="jobs_field"
      @openModal="handleModal"
      :user_type="user_type"
      :config_general_single_company="config_general_single_company"
    />
    <Pagination
      v-if="pagination.last > 1"
      :currentPage="Number(filters.page)"
      :lastPage="pagination.last"
      @update:currentPage="handlePageChange"
    />

    <JobModal
      :open="modalOpen"
      @close="modalOpen = false"
      :job="modalOpenJob"
      :user_type="user_type"
      :user_profile="user_profile"
      :config_general_single_company="config_general_single_company"
      :options="options"
    />
  </div>
</template>

<script setup>
  import { ref, watch, onMounted, onUnmounted, nextTick } from 'vue';
  import axios from 'axios';
  import { __ } from '../../../helpers';
  import { JobsSearchResults, JobModal } from './partials';
  import {
    Button,
    Input,
    FormMultiselect,
    Pagination
  } from '../../../components/global';
  import { Location } from './components/location';

  const props = defineProps({
    name: String,
    jobs: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    user_type: {
      type: String,
      required: true
    },
    user_profile: {
      type: String,
      required: false
    },
    config_general_single_company: {
      type: Number,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  });

  const isLoading = ref(false);
  const modalOpen = ref(false);
  const modalOpenJob = ref(null);

  const country = ref(null);

  const handleModal = (job) => {
    modalOpen.value = true;
    modalOpenJob.value = job;
  };

  // should come from the server
  const filters = ref(props.filters);
  const jobs_field = ref(props.jobs);
  const pagination = ref(props.pagination);

  // Function to read the URL and update filters
  function updateFiltersFromURL() {
    const searchParams = new URLSearchParams(window.location.search);
    for (let [key, value] of searchParams.entries()) {
      if (filters.value.hasOwnProperty(key)) {
        filters.value[key] = value;
      }
    }
  }

  const handleURLFilters = (newFilters) => {
    const searchParams = new URLSearchParams();
    for (let key in newFilters) {
      if (key === 'location') {
        newFilters.location.forEach((value) => {
          searchParams.append('location[]', value.city.id);
        });
      } else if (typeof newFilters[key] === 'string') {
        searchParams.set(key, newFilters[key]);
      } else if (typeof newFilters[key] === 'number') {
        searchParams.set(key, newFilters[key]);
      } else if (Array.isArray(newFilters[key])) {
        newFilters[key].forEach((value) => {
          searchParams.append(`${key}[]`, value.id);
        });
      } else if (
        typeof newFilters[key] === 'object' &&
        newFilters[key] !== null
      ) {
        searchParams.set(key, newFilters[key].id);
      }
    }
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  // Update the URL when filters change
  watch(
    filters,
    (newFilters) => {
      handleURLFilters(newFilters);
    },
    { deep: true }
  );

  // Update filters when URL changes (browser back/forward)
  const handlePopState = () => {
    updateFiltersFromURL();
  };

  onMounted(() => {
    updateFiltersFromURL(); // Initialize filters from URL on mount
    window.addEventListener('popstate', handlePopState);
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', handlePopState);
  });

  const handleSubmit = async (page) => {
    if (country.value) {
      const countryExists = filters.value.location.some(
        (item) =>
          item.country.code === country.value.code &&
          item.city.id === country.value.code
      );

      if (!countryExists) {
        filters.value.location = [
          ...filters.value.location,
          {
            country: country.value,
            city: { id: country.value.code, name: __('All cities') }
          }
        ];
      }
    }

    await nextTick();

    // get search params from the URL
    const searchParams = new URLSearchParams(window.location.search);
    isLoading.value = true;

    if (page) {
      filters.value.page = page;
      searchParams.set('page', page);
    }

    // Close filters
    isVisibile.value = false;

    axios
      .get(route('redesign.jobs.load'), { params: searchParams })
      .then((response) => {
        jobs_field.value = response.data.data.jobs;
        pagination.value = response.data.data.pagination;
        isLoading.value = false;
      });
  };

  function handlePageChange(newPage) {
    // filters.value.page = newPage;

    handleSubmit(newPage);
  }

  const companies = ref([]);
  const getCompaniesByQuery = async (query) => {
    if (query.length < 3 || query === undefined) {
      return;
    }

    axios
      .get(route('companies.find_companies'), {
        params: {
          q: query
        }
      })
      .then((response) => {
        companies.value = response.data;
      });
  };

  const deleteFilters = () => {
    country.value = null;

    filters.value = {
      search: '',
      location: [],
      career_level: [],
      presence: [],
      posted_at: [],
      language: [],
      type: [],
      page: 1
    };

    handleURLFilters(filters.value);

    handleSubmit();
  };

  const isVisibile = ref(false);

  const checkIfFilters = () => {
    for (let key in filters.value) {
      if (filters.value[key].length > 0 && key != 'page') {
        return true;
      }
    }
    return false;
  };
</script>
