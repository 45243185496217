<template>
  <div class="md:flex grid gap-2 my-4">
    <!-- {{ props.job.overview }} -->
    <span v-for="(value, index) in props.job.overview" :key="index">
      <span
        v-if="value"
        class="chip rounded-app items-center border-0 px-2 py-1 shadow-sm ring-1 ring-inset ring-secondary focus:ring-2 text-sm sm:leading-6 disabled:opacity-50 disabled:cursor-not-allowed"
        :class="{ 'ring-zinc-400 ': props.disabled }"
      >
        <!-- check if value.name is string, object or array -->
        {{ index }}:
        <span v-if="typeof value === 'string'">{{ value }}</span>
        <span v-else-if="Array.isArray(value)">
          <span v-for="(item, index) in value" :key="index">
            <span v-if="index !== value.length - 1">{{ item.name }}, </span>
            <span v-else>{{ item.name }}</span>
          </span>
        </span>
        <span v-else-if="typeof value === 'object'">
          {{ value.name }}
        </span>
      </span>
    </span>
  </div>
</template>

<script setup>
import {
  Button,
  Icon,
  Input,
  Textarea,
  FormMultiselect,
} from "../../../../components/global";
const props = defineProps({
  job: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
</script>
