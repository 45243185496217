<template>
  <div class="space-y-12">
    <h1 class="text-5xl font-medium text-zinc-800">{{ __('Settings') }}</h1>
    <UserDetails :profile="profile" :options="options" @cancel="handleCancelButtonClick" />
    <Address :options="options" :profile="profile" />
    <UserPassword />
    <Gdpr :profile="profile" />
    <div class="justify-self-end">
      <Button
        class="mx-auto mt-8 flex w-fit items-center gap-2.5"
        target="_blank"
        @click="deleteUser"
      >
        <Icon class="text-lg" name="delete" />
        <span>{{ __('Delete Account') }}</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
  import UserDetails from './components/UserDetails.vue';
  import { Button} from '../../../components/block/button';
  import { Icon } from '../../../components/block/icon';
  import { computed, ref } from 'vue';
  import Address from './components/Address.vue';
  import Gdpr from './components/Gdpr.vue';
  import UserPassword from './components/UserPassword.vue';
  import {useToast} from 'vue-toastification';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const emits = defineEmits(['cancel']);

  const profile = computed(() => {
    return props.data.user;
  });

  const options = computed(() => {
    return {
      languages: props.data.languages,
      country_prefix: props.data.country_prefix,
      country: props.data.country
    };
  });

  const isLoading = ref(false);

  const deleteUser = async () => {
    try {
      const response = await axios.post(
        route('redesign.candidate.settings.deleteAccount')
      );
      if(response.data.success) {
        toast.success(response.data.message);
        window.location = '/';
      }
    } catch (error) {
      toast.error("Couldn't delete user.");
    }
  };
</script>
