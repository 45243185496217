<template>
    <div class="h-full space-y-6 md:space-y-8 rounded-app bg-white overflow-hidden grid grid-rows-[auto,1fr] p-8 shadow-[0px_12px_16px_-4px_rgb(85_105_135_/_20%)]">
        <div class="text-2xl font-medium"> 
            {{ __('Groups') }}
        </div>
        <div class="pt-5 mt-0 overflow-y-scroll customscrollbar--simple">
            <div class="px-6 py-4 rounded-lg shadow-card overflow-hidden" 
                v-for="(group, index) in groups" :key="index">
                <div class="flex lg:gap-0xl:gap-2">
                    <span class="self-center flex-none">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_973_13782" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                            <rect width="24" height="24" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_973_13782)">
                            <path d="M8 16C8.55 16 9.02083 15.8042 9.4125 15.4125C9.80417 15.0208 10 14.55 10 14C10 13.45 9.80417 12.9792 9.4125 12.5875C9.02083 12.1958 8.55 12 8 12C7.45 12 6.97917 12.1958 6.5875 12.5875C6.19583 12.9792 6 13.45 6 14C6 14.55 6.19583 15.0208 6.5875 15.4125C6.97917 15.8042 7.45 16 8 16ZM16 16C16.55 16 17.0208 15.8042 17.4125 15.4125C17.8042 15.0208 18 14.55 18 14C18 13.45 17.8042 12.9792 17.4125 12.5875C17.0208 12.1958 16.55 12 16 12C15.45 12 14.9792 12.1958 14.5875 12.5875C14.1958 12.9792 14 13.45 14 14C14 14.55 14.1958 15.0208 14.5875 15.4125C14.9792 15.8042 15.45 16 16 16ZM12 10C12.55 10 13.0208 9.80417 13.4125 9.4125C13.8042 9.02083 14 8.55 14 8C14 7.45 13.8042 6.97917 13.4125 6.5875C13.0208 6.19583 12.55 6 12 6C11.45 6 10.9792 6.19583 10.5875 6.5875C10.1958 6.97917 10 7.45 10 8C10 8.55 10.1958 9.02083 10.5875 9.4125C10.9792 9.80417 11.45 10 12 10ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z" fill="#D71F70"/>
                            </g>
                        </svg>
                    </span>
                    <span class="hidden xl:block block font-medium text-zinc-800 self-center px-2 sm:text-base lg:text-lg">
                        {{ group.name }}
                    </span>
                    <span class="xl:hidden block block font-medium text-zinc-800 self-center px-2 sm:text-base lg:text-lg">
                        {{ group.short_name }}
                    </span>
                    <Button
                        class="flex items-center gap-2 ml-auto flex-none group-btn text-sm"
                        variant="ghost"
                        :href="group.url"
                        target="_blank"
                        >
                        <span>{{ __('Go to group') }}</span>
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { __ } from '@/helpers';
import { Button } from '@/components/block/button';
import { ref } from 'vue';

const props = defineProps({
    data: {
        type: Object,
        required: true
    }
});

const groups = ref(JSON.parse(JSON.stringify(props.data)));
</script>