<template>
  <div class="space-y-8">
    <SectionHeader :title="__('History Tracking')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="account_tree" />
      </template>
      <template #button>
        <Button
          v-if="options"
          class="ml-auto flex items-center gap-1.5"
          variant="ghost"
          @click="handleAddButtonClick"
        >
          <Icon class="text-lg" name="add_box" is-filled />
          <span>{{ __('Add a new event') }}</span>
        </Button>
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-2xl bg-white p-6 md:p-8">
      <EmptySection v-if="!events.length" :title="__('No events found')">
        <template #icon>
          <Icon class="text-xl text-zinc-200" name="account_tree" is-filled />
        </template>
      </EmptySection>

      <div
        v-else
        class="-m-3 max-h-[30rem] space-y-4 overflow-y-auto p-3 md:-m-4 md:p-4"
      >
        <Event v-for="event in events" :key="event.id" :event="event" />
      </div>
    </div>

    <EventFormModal
      v-if="isFormModalOpen"
      :options="options"
      :user-id="userId"
      @cancel="handleCancelButtonClick"
      @save="handleSaveButtonClick"
    />
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { EmptySection } from '@/pages/components/empty-section';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Event } from './components/event';
  import { EventFormModal } from './components/event-form-modal';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  });

  const events = computed(() => {
    return [...data.value].sort((a, b) => {
      const dateA = new Date(a.date.split('-').reverse().join('-'));
      const dateB = new Date(b.date.split('-').reverse().join('-'));

      return dateB - dateA;
    });
  });

  const data = ref(JSON.parse(JSON.stringify(props.data)));
  const isFormModalOpen = ref(false);

  function handleAddButtonClick() {
    isFormModalOpen.value = true;
  }

  function handleCancelButtonClick() {
    isFormModalOpen.value = false;
  }

  function handleSaveButtonClick(event) {
    data.value = [event, ...data.value];
    isFormModalOpen.value = false;
  }
</script>
