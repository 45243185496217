<template>
    <div class="space-y-8">
        <SectionHeader :title="__('Gamification')">
        <template #icon>
            <Icon class="text-3xl text-primary" name="sports_esports" is-filled />
        </template>
        </SectionHeader>

        <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
            <div class="space-y-6">
                <div class="grid grid-cols-2 gap-6">
                    <InputSelect
                        id="points"
                        v-model:value="values.points"
                        v-model:errors="errors"
                        :label="__('Select points')"
                        :placeholder="__('Select the number of points the users will receive')"
                        :options="options"
                    />
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import { Icon } from '@/components/block/icon';
    import { InputSelect } from '@/components/form/input-select';
    import { SectionHeader } from '@/pages/components/section-header';
    import { ref, watch } from 'vue';

    const props = defineProps({
        values: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: true
        }
  });

  const values = ref(JSON.parse(JSON.stringify(props.values)));
  const errors = ref(JSON.parse(JSON.stringify(props.errors)));

  const emits = defineEmits(['update:values', 'update:errors']);

  watch(
    values,
    (value) => {
      emits('update:values', value);
    },
    { deep: true }
  );

  watch(
    errors,
    (value) => {
      emits('update:errors', value);
    },
    { deep: true }
  );

  watch(
    props.errors,
    (value) => {
      errors.value = JSON.parse(JSON.stringify(value));
    },
    { deep: true, immediate: true }
  );

</script>