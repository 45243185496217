<template>
  <div class="space-y-12">
    <h1 class="text-5xl font-medium text-zinc-800">{{ __('Members') }}</h1>

    <div class="space-y-8">
      <SectionHeader :title="__('Company Team Members')">
        <template #icon>
          <Icon class="text-3xl text-primary" name="assignment_ind" is-filled />
        </template>
      </SectionHeader>

      <Invite
        v-if="isRoleCompanyOwner || isRoleCommunityLead"
        :members="members"
        :invitations="invitations"
        :options="options"
        @invite="handleInviteButtonClick"
      />

      <div class="shadow-redesign overflow-x-auto rounded-lg bg-white">
        <table class="w-max min-w-full overflow-x-hidden">
          <thead class="border-b">
            <tr>
              <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                {{ __('Name') }}
              </th>
              <th
                v-if="isRoleCompanyOwner"
                class="px-8 py-4 font-medium leading-normal text-zinc-600"
              >
                {{ __('Status') }}
              </th>
              <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                {{ __('Role') }}
              </th>
              <th class="px-8 py-4 font-medium leading-normal text-zinc-600">
                {{ __('Date') }}
              </th>
              <th
                v-if="isRoleCompanyOwner"
                class="px-8 py-4 font-medium leading-normal text-zinc-600"
              >
                {{ __('Action') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <Member
              v-for="member in members"
              :key="member.id"
              :member="member"
              :options="options"
              :can-edit="isRoleCompanyOwner"
              :is-current-user="member.id === currentUser.id"
              @update="handleUpdateButtonClick"
              @remove="handleRemoveButtonClick(member)"
            />
            <Invitation
              v-for="invitation in invitations"
              :key="invitation.id"
              :invitation="invitation"
              :can-edit="isRoleCompanyOwner"
              @invite-again="handleInviteAgainButtonClick"
            />
          </tbody>
        </table>

        <MemberDeleteModal
          v-if="isDeleteModalOpen"
          :member="selected"
          @cancel="handleCancelButtonClick"
          @continue="handleContinueButtonClick"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Invitation } from './components/invitation';
  import { Member } from './components/member';
  import { MemberDeleteModal } from './components/member-delete-modal';
  import { Invite } from './components/invite';

  const props = defineProps({
    members: {
      type: Array,
      required: true
    },
    invitations: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  });

  const currentUser = computed(() => {
    return props.members.find((member) => member.id === props.user.id);
  });

  const isRoleCompanyOwner = computed(() => {
    return props.role === 'company_owner';
  });

  const isRoleCommunityLead = computed(() => {
    return props.role === 'community_lead';
  });

  const members = ref(JSON.parse(JSON.stringify(props.members)));
  const invitations = ref(JSON.parse(JSON.stringify(props.invitations)));
  const selected = ref(null);
  const isDeleteModalOpen = ref(false);

  function handleInviteButtonClick(invitation) {
    invitations.value.push(invitation);
  }

  function handleUpdateButtonClick(member) {
    members.value = members.value.map((item) => {
      if (item.id === member.id) {
        return member;
      }

      return item;
    });
  }

  function handleRemoveButtonClick(member) {
    selected.value = JSON.parse(JSON.stringify(member));
    isDeleteModalOpen.value = true;
  }

  function handleCancelButtonClick() {
    selected.value = null;
    isDeleteModalOpen.value = false;
  }

  function handleContinueButtonClick(member) {
    members.value = members.value.filter((item) => item.id !== member.id);
    isDeleteModalOpen.value = false;
  }

  function handleInviteAgainButtonClick(invitation) {
    invitations.value = invitations.value.map((item) => {
      if (item.id === invitation.id) {
        return invitation;
      }

      return item;
    });
  }
</script>
