<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Upload files')">
      <template #icon>
        <Icon
          class="text-3xl text-primary"
          name="drive_folder_upload"
          is-filled
        />
      </template>
    </SectionHeader>

    <div class="shadow-element space-y-4 rounded-2xl bg-white p-6 md:p-8">
      <div
        v-for="group in groups"
        :key="group.type.id"
        class="space-y-2 rounded-2xl bg-gradient-to-b from-zinc-100 to-transparent p-4"
      >
        <span class="block text-lg font-medium text-zinc-800">
          {{ group.type.name }}
        </span>

        <div class="sapce-y-2">
          <Document
            v-for="document in group.documents"
            :key="document.id"
            :document="document"
            :username="username"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Document } from './components/document';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    username: {
      type: String,
      required: true
    }
  });

  const groups = computed(() => {
    let groups = {};

    props.data.forEach((document) => {
      const typeId = document.type_id;

      if (!groups[typeId]) {
        groups[typeId] = {
          type: { id: typeId, name: document.document_type_name },
          documents: []
        };
      }

      groups[typeId].documents.push({
        id: document.id,
        name: document.document_name
      });
    });

    let result = [];

    for (let typeId in groups) {
      result.push(groups[typeId]);
    }

    return result;
  });
</script>
