<template>
  <div
    class="md:shadow-card h-fit w-full space-y-6 rounded-lg bg-white px-6 py-8 md:p-14"
  >
    <div class="flex gap-2 md:gap-4">
      <Icon
        class="text-[2rem] text-primary md:text-[2.5rem]"
        :name="isUnderAge ? 'family_star' : 'no_accounts'"
        is-filled
      />
      <h1 class="text-2xl font-medium text-zinc-800 md:text-4xl">
        {{
          __(
            `${isUnderAge ? 'Come Back When You’re Ready!' : 'Platform Not Available for Your Age Group'}`
          )
        }}
      </h1>
    </div>

    <p
      class="text-sm text-zinc-800 md:text-lg"
      v-html="
        __('Thank you for your interest! This platform is dedicated to individuals over the age of 14 years old who are exploring their passions and building their futures. Since you’re under the required age, we invite you to return once you are at least 14 years old.')
      "
    />

    <Button
      v-if="!isUnderAge"
      class="ml-auto flex w-fit"
      href="https://playground.jobful.io"
    >
      <span>{{ __('Join Jobful Talent') }}</span>
    </Button>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    conditions: {
      type: Object,
      required: true
    }
  });

  const isUnderAge = computed(() => {
    return props.conditions.is_under_age;
  });
</script>
