<template>
  <div id="perks" class="mt-8 flex flex-col gap-8">
    <SectionHeader icon="loyalty" title="Perks" />

    <div
      class="relative flex grow flex-col items-end justify-between gap-4 rounded-app bg-white p-8 shadow-md"
    >
      <FormMultiselect
        v-model="data.job"
        class="w-full"
        :values="data.options"
        multiple
        :placeholder="'Select perks'"
        :max="7"
      />

      <Button
        v-if="data.company.length"
        class="flex items-center gap-1.5"
        variant="ghost"
        @click="autoFill"
      >
        <Icon class="text-xl" name="add_box" is-filled />
        <span>{{ __('Autofill from company profile') }}</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import {
    FormMultiselect,
    SectionHeader
  } from '../../../../components/global';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    }
  });

  const autoFill = () => {
    props.data.job = JSON.parse(JSON.stringify(props.data.company));
  };
</script>
