<template>
  <div class="bg-white">
    <div
      v-for="(itemfilter, index) in filterItems"
      v-bind:key="index"
      :class="index != 0 ? '' : ''"
    >
      <div
        class="flex justify-between border-b-2 p-5 py-3 pb-3"
        v-if="itemfilter.show"
        @click="onClickDropdown(index, itemfilter.isOpen)"
      >
        <h3 class="title font-primary font-bold uppercase text-gray-400">
          {{ itemfilter.title }}
        </h3>
        <span class="flex cursor-pointer items-center justify-between">
          <span class="material-symbols-rounded"> expand_more </span>
        </span>
      </div>
      <div>
        <div v-if="itemfilter.isOpen">
          <ul
            v-if="itemfilter.items.length != 0 && !itemfilter.isEmpty"
            class="list-inside list-disc p-5 marker:text-secondary"
          >
            <div v-for="(item, index) in itemfilter.items" v-bind:key="index">
              <div
                v-if="item.value != null && item.value.length != 0"
                class="is-exist"
              >
                <p class="font-bold">
                  {{ !item.title ? null : item.title + ':' }}
                </p>

                <div v-if="item.type == 'string'">
                  <li v-if="item.value != 'Please Select' || item.value > 0">
                    {{ item.value.name ? item.value.name : item.value }}
                  </li>
                  <p class="font-primary text-gray-400" v-else>
                    {{ __('Nothing yet!') }}
                  </p>
                </div>

                <div v-else-if="item.type == 'location'">
                  <li
                    v-if="item.value && item.value.country && item.value.city"
                  >
                    {{ `${item.value.city.name} | ${item.value.country.name}` }}
                  </li>
                  <p class="font-primary text-gray-400" v-else>
                    {{ __('Nothing yet!') }}
                  </p>
                </div>

                <div v-else-if="item.type == 'country'">
                  <li v-if="item.value && item.value.length">
                    {{ item.value.map((value) => value.name).join(', ') }}
                  </li>
                  <p class="font-primary text-gray-400" v-else>
                    {{ __('Nothing yet!') }}
                  </p>
                </div>

                <div v-else-if="item.type == 'language'">
                  <li v-if="item.value">
                    {{ item.value.name
                    }}{{
                      item.proficiency.value && item.proficiency.value.name
                        ? ' - ' + item.proficiency.value.name
                        : ''
                    }}
                  </li>
                  <p class="font-primary text-gray-400" v-else>
                    {{ __('Nothing yet!') }}
                  </p>
                </div>
                <div v-else-if="item.type == 'object'">
                  <li v-for="(x, index) in item.value" v-bind:key="index">
                    {{ x.name }}
                  </li>
                </div>
              </div>
            </div>
          </ul>
          <div
            v-if="itemfilter.items.length == 0 || itemfilter.isEmpty"
            class="p-5 text-center text-gray-400"
          >
            {{ __('Nothing yet!') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { __ } from '../../../helpers';

  const props = defineProps({
    filterAvailability: Array,
    filterContactDetails: Array,
    filterAbout: Array,
    degrees: Array,
    filterExperience: Object,
    skills: Array,
    testedSkills: Array,
    languages: Array,
    config_display_users_anonymously: Number
  });

  const filterItems = ref([
    {
      title: __('Contact details'),
      show: props.config_display_users_anonymously == 1 ? false : true,
      items: [
        ...props.filterContactDetails[0].name,
        ...props.filterContactDetails[0].email,
        ...props.filterContactDetails[0].country_code,
        ...props.filterContactDetails[0].phone
      ],
      isOpen: false,
      isEmpty: true
    },
    {
      title: __('About candidate'),
      show: true,
      items: [
        ...props.filterAbout[0].location,
        ...props.filterAbout[0].career_status
      ],
      isOpen: false,
      isEmpty: true
    },
    {
      title: __('Availability'),
      show: true,
      items: props.filterAvailability[0].availability,
      isOpen: false,
      isEmpty: true
    },
    {
      title: __('Education'),
      show: true,
      items: props.degrees[0].degree,
      isOpen: false,
      isEmpty: true
    },
    {
      title: __('Experience'),
      show: true,
      items: props.filterExperience,
      isOpen: false,
      isEmpty: true
    },
    {
      title: __('Skills'),
      show: true,
      items: props.skills,
      isOpen: false,
      isEmpty: true
    },
    // {
    //   title: __("Tested skills"),
    //   show: true,
    //   items: props.testedSkills,
    //   isOpen: false,
    //   isEmpty: true,
    // },
    {
      title: __('Languages'),
      show: true,
      items: props.languages,
      isOpen: false,
      isEmpty: true
    }
  ]);

  function onClickDropdown(index, isOpen) {
    filterItems.value[index].isOpen = !filterItems.value[index].isOpen;
    for (let i = 0; i < filterItems.value.length; i++) {
      if (filterItems.value[index] != filterItems.value[i]) {
        filterItems.value[i].isOpen = false;
      }
    }
  }

  onMounted(() => {
    for (let i = 0; i < filterItems.value.length; i++) {
      for (let y = 0; y < filterItems.value[i].items.length; y++) {
        console.log(filterItems.value[i].items[y].value);

        if (
          filterItems.value[i].items[y].value != null &&
          filterItems.value[i].items[y].value.length != 0
        ) {
          filterItems.value[i].isEmpty = false;
        }
      }
    }
  });
</script>
