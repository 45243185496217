<template>
  <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
    <form @submit.prevent="handleSubmit">
      <div class="space-y-4">
        <InputLocation
          id="location"
          v-model:value="values.location"
          v-model:errors="errors.location"
          :label-country="__('Select the country you want to work in')"
          :label-city="__('Select the city you want to work in')"
          :placeholder-country="__('Select country')"
          :placeholder-city="__('Select city')"
          :options="options.country"
        />

        <InputMultiSelect
          id="visa"
          v-model:values="values.visa"
          v-model:errors="errors.visa"
          :label="__('I require a visa to work in')"
          :placeholder="__('Visa')"
          :options="options.visa"
        >
          <template #icon>
            <Icon
              class="text-lg text-neutral-400"
              name="account_balance"
              is-filled
            />
          </template>
        </InputMultiSelect>

        <InputMultiSelect
          id="employment_type"
          v-model:values="values.employment_type"
          v-model:errors="errors.employment_type"
          :label="__('Employment type')"
          :placeholder="__('Employment type')"
          :options="options.employment_type"
        >
          <template #icon>
            <Icon class="text-lg text-neutral-400" name="badge" is-filled />
          </template>
        </InputMultiSelect>

        <InputMultiSelect
          id="presence"
          v-model:values="values.presence"
          v-model:errors="errors.presence"
          :label="__('Work type')"
          :placeholder="__('Work type')"
          :options="options.presence"
        >
          <template #icon>
            <Icon class="text-lg text-neutral-400" name="apartment" is-filled />
          </template>
        </InputMultiSelect>

        <div class="grid gap-x-2 gap-y-4 md:grid-cols-2">
          <InputCareers
            id="desired_job"
            v-model:value="values.desired_job"
            v-model:errors="errors.desired_job"
            :label="__('Desired Job')"
            :placeholder="__('Desired Job')"
            is-new-options-allowed
          >
            <template #icon>
              <Icon name="stars" />
            </template>
          </InputCareers>

          <InputSelect
            id="urgency"
            v-model:value="values.urgency"
            v-model:errors="errors.urgency"
            :label="__('Urgency')"
            :placeholder="__('Urgency')"
            :options="options.urgency"
          />
        </div>

        <div class="grid gap-x-2 gap-y-4 md:grid-cols-4">
          <InputSelect
            id="salary_format"
            v-model:value="values.salary_format"
            v-model:errors="errors.salary_format"
            :label="__('Salary Format')"
            :placeholder="__('Salary Format')"
            :options="options.salary_format"
          />
          <InputSelect
            id="salary_type"
            v-model:value="values.salary_type"
            v-model:errors="errors.salary_type"
            :label="__('Wage type')"
            :placeholder="__('Wage type')"
            :options="options.salary_type"
          />

          <InputText
            id="desired_wage"
            v-model:value="values.desired_wage"
            v-model:errors="errors.desired_wage"
            :label="__('Desired wage')"
            :placeholder="__('Gross')"
          >
            <template #icon>
              <Icon
                class="text-lg text-neutral-400"
                name="attach_money"
                is-filled
              />
            </template>
          </InputText>

          <InputSelect
            id="currency"
            v-model:value="values.currency"
            v-model:errors="errors.currency"
            :label="__('Currency')"
            :placeholder="__('Currency')"
            :options="options.currency"
          />
        </div>

        <InputMultiSelect
          id="domain"
          v-model:values="values.domain"
          v-model:errors="errors.domain"
          :label="__('Domain')"
          :placeholder="__('Domain')"
          :options="options.domain"
        >
          <template #icon>
            <Icon class="text-lg text-neutral-400" name="domain" is-filled />
          </template>
        </InputMultiSelect>

        <InputMultiSelect
          id="department"
          v-model:values="values.department"
          v-model:errors="errors.department"
          :label="__('Department')"
          :placeholder="__('Department')"
          :options="options.department"
        >
          <template #icon>
            <Icon class="text-lg text-neutral-400" name="work" is-filled />
          </template>
        </InputMultiSelect>
      </div>

      <span
        v-if="errorMessage"
        class="mt-8 block text-center text-sm text-error"
      >
        {{ errorMessage }}
      </span>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputLocation } from '@/components/form/input-location';
  import { InputMultiSelect } from '@/components/form/input-multi-select';
  import { InputSelect } from '@/components/form/input-select';
  import { InputText } from '@/components/form/input-text';
  import { InputCareers } from '@/components/form/input-careers';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  });

  const emits = defineEmits(['save', 'cancel']);

  const toast = useToast();

  const data = ref(JSON.parse(JSON.stringify(props.data)));
  const values = ref({
    location: data.value.location || [],
    visa: data.value.visa,
    employment_type: data.value.employment_type,
    presence: data.value.presence,
    salary_type: data.value.salary_type,
    desired_wage: data.value.desired_wage
      ? data.value.desired_wage.toString()
      : '',
    currency: data.value.currency,
    domain: data.value.domain,
    department: data.value.department,
    desired_job: data.value.desired_job,
    salary_format: data.value.salary_format,
    urgency: data.value.urgency
  });
  const errors = ref({
    country: [],
    location: [],
    visa: [],
    employment_type: [],
    presence: [],
    salary_type: [],
    desired_wage: [],
    currency: [],
    domain: [],
    department: [],
    desired_job: [],
    salary_format: [],
    urgency: []
  });
  const isLoading = ref(false);

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    if (
      !values.value.location.length ||
      ((values.value.desired_wage || values.value.currency) &&
        (!values.value.desired_wage || !values.value.currency))
    ) {
      let firstElement;

      if (!values.value.location.length) {
        errors.value.location = [__('This field is required')];

        const element = document.getElementById('location');

        if (element && !firstElement) {
          firstElement = element;
        }
      }

      if (
        (values.value.desired_wage || values.value.currency) &&
        (!values.value.desired_wage || !values.value.currency)
      ) {
        if (!values.value.desired_wage) {
          errors.value.desired_wage = [__('This field is required')];

          const element = document.getElementById('desired_wage');

          if (element && !firstElement) {
            firstElement = element;
          }
        }

        if (!values.value.currency) {
          errors.value.currency = [__('This field is required')];

          const element = document.getElementById('currency');

          if (element && !firstElement) {
            firstElement = element;
          }
        }
      }

      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      const response = await axios.put(
        route('redesign.cv.update.availability'),
        values.value
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', {
        data: values.value,
        fields: response.data.fields,
        sectionPercentage: response.data.section_percentage,
        resumePercentage: response.data.percentage
      });
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
