import { createRouter, createWebHistory } from 'vue-router';
import ManagementDashboard from './components/companies/candidate-management/ManagementDashboard.vue';

const routes = [
	{
		path: '/company/dashboard/:jobId?',
		name: 'managementDashboard',
		component: ManagementDashboard
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
