<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Projects')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="tactic" is-filled />
      </template>
      <template v-if="canEdit" #button>
        <div class="ml-auto">
          <Button
            class="flex items-center gap-1.5"
            variant="ghost"
            @click="handleAddButtonClick"
          >
            <Icon class="text-lg" name="add_box" is-filled />
            <span>{{ __('Add project') }}</span>
          </Button>
        </div>
      </template>
    </SectionHeader>

    <div
      class="shadow-card relative overflow-hidden rounded-2xl bg-white p-6 md:p-8"
    >
      <div
        v-if="progress"
        class="absolute right-0 top-0 rounded-l-full bg-secondary px-3 py-1"
      >
        <span class="block text-xs leading-none text-white">
          {{ progress }}
        </span>
      </div>

      <EmptySection v-if="!projects.length" :title="__('Add your projects')">
        <template #icon>
          <Icon class="text-xl text-zinc-200" name="tactic" is-filled />
        </template>
      </EmptySection>

      <div v-else class="space-y-8">
        <Project
          v-for="project in isListExpanded ? projects : projects.slice(0, 3)"
          :key="project.id"
          :project="project"
          :can-edit="canEdit"
          @edit="handleEditButtonClick(project)"
          @delete="handleDeleteButtonClick(project)"
        />

        <Button
          v-if="projects.length > 3"
          class="mx-auto"
          variant="outline"
          @click="handleExpandListButtonClick"
        >
          {{ __(isListExpanded ? 'See less' : 'See more') }}
        </Button>
      </div>

      <ProjectFormModal
        v-if="isFormModalOpen"
        :project="selected"
        @cancel="handleCancelButtonClick"
        @save="handleSaveButtonClick"
      />

      <ProjectDeleteModal
        v-if="isDeleteModalOpen"
        :project="selected"
        @cancel="handleCancelButtonClick"
        @continue="handleContinueButtonClick"
      />
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { EmptySection } from '@/pages/components/empty-section';
  import { SectionHeader } from '@/pages/components/section-header';
  import { Project } from './components/project';
  import { ProjectDeleteModal } from './components/project-delete-modal';
  import { ProjectFormModal } from './components/project-form-modal';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['update-is-editing', 'update-percentages']);

  const progress = computed(() => {
    return fields.value.total
      ? `${fields.value.completed}/${fields.value.total}`
      : '';
  });

  const fields = ref(JSON.parse(JSON.stringify(props.fields)));
  const projects = ref(JSON.parse(JSON.stringify(props.data)));
  const selected = ref(null);
  const isFormModalOpen = ref(false);
  const isDeleteModalOpen = ref(false);
  const pendingAction = ref('');
  const isListExpanded = ref(false);

  function handleExpandListButtonClick() {
    isListExpanded.value = !isListExpanded.value;
  }

  function handleAddButtonClick() {
    selected.value = null;
    pendingAction.value = 'add';

    emits('update-is-editing', true);
  }

  function handleEditButtonClick(project) {
    selected.value = JSON.parse(JSON.stringify(project));
    pendingAction.value = 'edit';

    emits('update-is-editing', true);
  }

  function handleDeleteButtonClick(project) {
    selected.value = JSON.parse(JSON.stringify(project));
    pendingAction.value = 'delete';

    emits('update-is-editing', true);
  }

  function handleCancelButtonClick() {
    emits('update-is-editing', false);
  }

  function handleSaveButtonClick(data) {
    const existingProject = projects.value.find(
      (project) => project.id === data.project.id
    );

    if (existingProject) {
      projects.value = projects.value.map((project) => {
        if (project.id === data.project.id) {
          return data.project;
        }

        return project;
      });
    } else {
      projects.value = [data.project, ...projects.value];
    }

    fields.value = data.fields;

    emits('update-is-editing', false);
    emits('update-percentages', {
      sectionPercentage: data.sectionPercentage,
      resumePercentage: data.resumePercentage
    });
  }

  function handleContinueButtonClick(data) {
    projects.value = projects.value.filter(
      (project) => project.id !== data.project.id
    );

    fields.value = data.fields;

    emits('update-is-editing', false);
    emits('update-percentages', {
      sectionPercentage: data.sectionPercentage,
      resumePercentage: data.resumePercentage
    });
  }

  watch(
    () => props.isEditing,
    (value) => {
      if (pendingAction.value === 'add' || pendingAction.value === 'edit') {
        isFormModalOpen.value = value;
      } else if (pendingAction.value === 'delete') {
        isDeleteModalOpen.value = value;
      }
    }
  );
</script>
