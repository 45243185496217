<template>
  <Dialog class="relative z-50" :open="open" @close="handleClose">
    <div class="fixed inset-0 bg-black/80" aria-hidden="true" />

    <div
      class="fixed inset-0 flex min-h-screen w-screen items-center justify-center md:p-4"
    >
      <DialogPanel
        :class="`${fullHeight && 'h-full'} ${size === 'large' ? 'max-w-[80rem]' : 'max-w-[45rem]'} max-h-full w-full space-y-4 overflow-y-auto bg-white p-6 md:rounded-lg`"
      >
        <div v-if="title || description" class="space-y-2">
          <DialogTitle
            v-if="title"
            class="text-center text-lg font-medium text-zinc-800 md:text-left"
          >
            {{ title }}
          </DialogTitle>

          <DialogDescription
            v-if="description"
            class="text-center text-sm text-zinc-600 md:text-left"
          >
            {{ description }}
          </DialogDescription>
        </div>

        <slot></slot>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
  import {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription
  } from '@headlessui/vue';

  const props = defineProps({
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium'
    },
    fullHeight: {
      type: Boolean,
      default: false
    }
  });

  const emits = defineEmits(['close']);

  function handleClose() {
    emits('close');
  }
</script>
