<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell sm:pl-4">
    <template v-if="props.columnData.link !== ''">
      <a :href="props.columnData.link" target="_blank" class="text-black hover:text-brandColor1">
        <span class="font-bold">{{ props.candidateIndex + 1 }}. </span>{{ props.columnData.name }}
      </a>
    </template>

    <template v-else>
      <span class="font-bold">{{ props.candidateIndex + 1 }}. </span>{{ props.columnData.name }}
    </template>

  </td>
</template>

<script setup>

const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});

</script>
