<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <JobForm :data="data" :errors="errors"/>

      <JobSubmitSection
          :isLoading="isLoading"
          :metadata="data.metadata"
          @update:status="data.metadata.status = $event"
          @updateAction="action = $event"
      />
    </form>
  </div>
</template>

<script setup>
import {reactive, ref} from "vue";

import {useToast} from "vue-toastification";
import {__} from "../../../helpers";
import {JobForm} from "./partials";
import {useForm} from "../../../utils";
import {JobSubmitSection} from "./components";

const props = defineProps({
  name: String,

  data: {
    type: Object,
    required: true,
  },
});

const toast = useToast();

const data = reactive(props.data);

const action = ref("");

// Initialize the form using the useForm function and provide Axios method and URL
const {formData, isLoading, updateFormData, resetForm, submitForm, errors} =
    useForm(data, {
      method: "put",
      url: route("redesign.company.jobs.update", {job: props.data.job}),
    });

const handleSubmit = async () => {
  data.metadata.action = action.value;

  try {
    const submitResponse = await submitForm(); // Call the submitForm method from useForm

    if (Object.keys(errors.value).length === 0) {
      toast.success(submitResponse.message);

      if (submitResponse.integration_result.failed_integrations.length > 0) {
        toast.error(submitResponse.integration_result.failed_integrations);
      } else if (submitResponse.integration_result.successful_integrations.length > 0) {
        toast.success(submitResponse.integration_result.successful_integrations);
      }

      data.metadata.status = data.metadata.action === "post";

      setTimeout(function () {
        window.location.replace(
            route("redesign.jobs.show", {company: submitResponse.company, job: submitResponse.job})
        );
      }, 2200);
    } else {
      // show error toast
      toast.error(__("You have some errors in your form!"));
    }
  } catch (error) {
    // Handle submission errors here
    toast.error(error.response.data.message || __("Something went wrong."));
  }
};
</script>
