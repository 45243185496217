<template>
  <div class="space-y-8">
    <SectionHeader :title="__('Details')">
      <template #icon>
        <Icon class="text-3xl text-primary" name="local_activity" is-filled />
      </template>
    </SectionHeader>

    <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
      <div class="space-y-6">
        <UploadCover
          v-model:value="values.cover"
          v-model:file="files.cover"
          v-model:errors="errors.cover"
        />

        <InputImage
          id="photo"
          v-model:value="values.photo"
          v-model:file="files.photo"
          v-model:errors="errors.photo"
          :label="__('Featured Photo')"
          :description="`JPG, JPEG ${__('or')} PNG (max. 2 MB)`"
          :extra-description="
            __(
              'Upload a photo for the group listing page to give users a better feel.'
            )
          "
          required
          :aspect-ratio="4 / 3"
        />

        <InputText
          id="name"
          v-model:value="values.name"
          v-model:errors="errors.name"
          :label="__('Name')"
          :placeholder="
            __(
              'Add a name that clearly communicates the purpose and focus of the group'
            )
          "
          required
        />

        <InputLocation
          id="location"
          v-model:value="values.location"
          v-model:errors="errors.location"
          :label-country="__('Country')"
          :label-city="__('City')"
          :placeholder-country="__('Select country')"
          :placeholder-city="__('Select city')"
          :options="options"
          required
        />

        <InputTextarea
          id="description"
          v-model:value="values.description"
          v-model:errors="errors.description"
          :label="__('Description')"
          :placeholder="
            __(
              `This is the group description. It's what people will see when you promote your group, but don't worry, you can always update it later. Feel free to provide additional details about the group's purpose, activities, and upcoming events.`
            )
          "
          required
        />

        <div class="space-y-6">
          <div class="flex items-center gap-x-2">
            <Icon class="text-2xl text-primary" name="add_link" />

            <span class="text-xl font-medium text-zinc-800">
              {{ __('Social Media Links') }}
            </span>
          </div>

          <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
            <InputText
              id="facebook"
              v-model:value="values.facebook"
              v-model:errors="errors.facebook"
              class="col-span-1"
              :label="__('Facebook')"
              :placeholder="__('Add Facebook')"
            />
            <InputText
              id="instagram"
              v-model:value="values.instagram"
              v-model:errors="errors.instagram"
              class="col-span-1"
              :label="__('Instagram')"
              :placeholder="__('Add Instagram')"
            />
            <InputText
              id="linkedin"
              v-model:value="values.linkedin"
              v-model:errors="errors.linkedin"
              class="col-span-1"
              :label="__('LinkedIn')"
              :placeholder="__('Add LinkedIn')"
            />
            <InputText
              id="twitter"
              v-model:value="values.twitter"
              v-model:errors="errors.twitter"
              class="col-span-1"
              :label="__('X (Twitter)')"
              :placeholder="__('Add X (Twitter)')"
            />
            <InputText
              id="youtube"
              v-model:value="values.youtube"
              v-model:errors="errors.youtube"
              class="col-span-1"
              :label="__('YouTube')"
              :placeholder="__('Add YouTube')"
            />
            <InputText
              id="tiktok"
              v-model:value="values.tiktok"
              v-model:errors="errors.tiktok"
              class="col-span-1"
              :label="__('TikTok')"
              :placeholder="__('Add TikTok')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { InputImage } from '@/components/form/input-image';
  import { InputLocation } from '@/components/form/input-location';
  import { InputText } from '@/components/form/input-text';
  import { InputTextarea } from '@/components/form/input-textarea';
  import { SectionHeader } from '@/pages/components/section-header';
  import { UploadCover } from './components/upload-cover';

  const props = defineProps({
    values: {
      type: Object,
      required: true
    },
    files: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:values', 'update:files', 'update:errors']);

  const values = ref(JSON.parse(JSON.stringify(props.values)));
  const files = ref(JSON.parse(JSON.stringify(props.files)));
  const errors = ref(JSON.parse(JSON.stringify(props.errors)));

  watch(
    values,
    (value) => {
      emits('update:values', value);
    },
    { deep: true }
  );
  watch(
    files,
    (value) => {
      emits('update:files', value);
    },
    { deep: true }
  );
  watch(
    errors,
    (value) => {
      emits('update:errors', value);
    },
    { deep: true }
  );
  watch(props.errors, (value) => {
    errors.value = JSON.parse(JSON.stringify(value));
  });
</script>
