<template>
  <Popover v-slot="{ close, open }" class="relative w-fit">
    <PopoverButton class="rounded-md">
      <slot name="trigger" />
    </PopoverButton>

    <PopoverPanel
      :change="handleChange(open)"
      :class="`shadow-card absolute ${position}-0 z-10 mt-1 w-max min-w-full origin-top-${position} rounded-md border bg-white focus:outline-none`"
    >
      <slot name="content" :close="close" />
    </PopoverPanel>
  </Popover>
</template>

<script setup>
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

  defineProps({
    position: {
      type: String,
      default: 'left'
    }
  });

  const emits = defineEmits(['close', 'open']);

  function handleChange(open) {
    emits(open ? 'open' : 'close');
  }
</script>
