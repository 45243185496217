<template>
  <Modal :open="open" @close="handleCancelButtonClick">
    <VuePictureCropper
      v-if="width && height"
      :box-style="{
        width: '100%',
        height: '100%',
        backgroundColor: '#f8f8f8',
        margin: 'auto'
      }"
      :img="value"
      :options="options"
      :preset-mode="{
        mode: 'fixedSize',
        width,
        height
      }"
    />
    <VuePictureCropper
      v-else
      :box-style="{
        width: '100%',
        height: '100%',
        backgroundColor: '#f8f8f8',
        margin: 'auto'
      }"
      :img="value"
      :options="options"
    />

    <div class="mt-4 flex flex-col justify-center gap-2 md:flex-row">
      <Button variant="outline" @click="handleCancelButtonClick">
        <span>{{ __('Cancel') }}</span>
      </Button>
      <Button @click="handleCropButtonClick">
        <span>{{ __('Crop') }}</span>
      </Button>
    </div>
  </Modal>
</template>

<script setup>
  import { computed, watch } from 'vue';
  import VuePictureCropper, { cropper } from 'vue-picture-cropper';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Modal } from '@/components/block/modal';

  const props = defineProps({
    open: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      required: true
    },
    minWidth: {
      type: Number,
      default: 300
    },
    minHeight: {
      type: Number,
      default: 300
    },
    width: {
      type: Number,
      default: NaN
    },
    height: {
      type: Number,
      default: NaN
    },
    aspectRatio: {
      type: Number,
      default: NaN
    },
    fileName: {
      type: String,
      default: ''
    }
  });

  const emits = defineEmits(['update:open', 'update:errors', 'change']);

  const options = computed(() => {
    let options = { viewMode: 1, dragMode: 'move' };

    if (props.minWidth) {
      options = { ...options, minCropBoxWidth: props.minWidth };
    }

    if (props.minHeight) {
      options = { ...options, minCropBoxHeight: props.minHeight };
    }

    if (props.width && props.height) {
      options = { ...options, cropBoxResizable: false };
    }

    if (props.aspectRatio) {
      options = { ...options, aspectRatio: props.aspectRatio };
    }

    return options;
  });

  function handleCancelButtonClick() {
    emits('update:open', false);
  }

  async function handleCropButtonClick() {
    if (!cropper) {
      return;
    }

    const base64 = cropper.getDataURL();
    const file = await cropper.getFile({ fileName: props.fileName });

    emits('change', base64, file);
    emits('update:open', false);
  }

  watch(
    () => props.value,
    (value) => {
      emits('update:open', false);

      if (!value) {
        return;
      }

      const img = new Image();
      img.src = value;

      img.onload = () => {
        const errors = [];

        if (img.width < props.minWidth) {
          errors.push(
            __(
              `Image width must be at least {minWidth}px, but received {width}px.`,
              { minWidth: props.minWidth, width: img.width }
            )
          );
        }
        if (img.height < props.minHeight) {
          errors.push(
            __(
              `Image height must be at least {minHeight}px, but received {height}px.`,
              { minHeight: props.minHeight, height: img.height }
            )
          );
        }

        if (errors.length) {
          emits('update:errors', errors);
        } else {
          emits('update:open', true);
        }
      };
    }
  );
</script>
