<template>
  <tr class="border-y bg-white last:border-b-0">
    <td class="px-8 py-4">
      <div class="flex items-center gap-4">
        <img
          class="h-12 w-12 rounded-full border border-zinc-200"
          :src="profilePhoto"
          :alt="__('Profile picture')"
        />

        <div class="space-y-0.5">
          <span class="block text-sm font-medium text-zinc-800">
            {{ name }}
          </span>
          <span class="block text-sm text-zinc-600">{{ email }}</span>
        </div>
      </div>
    </td>

    <td class="px-8 py-4">
      <span class="text-sm text-zinc-600">{{ date }}</span>
    </td>

    <td v-if="canInvite" class="px-8 py-4">
      <Button
        :disabled="!canInviteAgain || isLoading"
        class="flex items-center justify-center gap-1.5"
        @click="handleInviteAgainButtonClick"
      >
        <Icon
          v-if="isLoading"
          class="animate-spin text-lg"
          name="progress_activity"
        />

        <span>{{ __('Invite again') }}</span>
      </Button>
    </td>
  </tr>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { formatDate, formatTime } from '@/scripts/date.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    attendee: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['update:attendee']);

  const toast = useToast();

  const placeholderImage = '/images/logo_placeholder.png';

  const profilePhoto = computed(() => {
    return props.attendee.profile_photo
      ? `/uploads/${props.attendee.profile_photo}`
      : placeholderImage;
  });

  const name = computed(() => {
    return props.attendee.name;
  });

  const email = computed(() => {
    return props.attendee.email;
  });

  const date = computed(() => {
    return `${formatDate(props.attendee.invitation_sent)} | ${formatTime(props.attendee.invitation_sent)}`;
  });

  const canEdit = computed(() => {
    return props.user.conditions.can_edit;
  });

  const attendeeLimitReached = computed(() => {
    const attendees = props.event.details.attendees;
    const attendeeLimit = props.event.details.attendee_limit;

    if (!attendeeLimit) {
      return false;
    }

    return attendees === attendeeLimit;
  });

  const registrationDeadlinePassed = computed(() => {
    const rsvp = props.event.details.rsvp;

    if (!rsvp) {
      return false;
    }

    return new Date() > new Date(rsvp);
  });

  const canInvite = computed(() => {
    return (
      canEdit.value &&
      !attendeeLimitReached.value &&
      !registrationDeadlinePassed.value
    );
  });

  const canInviteAgain = computed(() => {
    return props.attendee.canInviteAgain;
  });

  const isLoading = ref(false);

  async function handleInviteAgainButtonClick() {
    isLoading.value = true;

    try {
      const response = await axios.post(
        route('redesign.company.groups.inviteMemberAgain', {
          id: props.event.id
        }),
        { member: props.attendee }
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('update:attendee', response.data.member);
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }
</script>
