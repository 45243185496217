<template>
  <td class="border-b border-gray-200 px-3 py-4 text-sm sm:table-cell">
    <div class="flex justify-center gap-1.5">
      <div v-for="(value, key) in columnData" :key="key" >
        <p v-if="key === 'label'">{{ capitalize(value) }}</p>
        <div v-if="key === 'uploadcv'" class="block relative">
          <label :for="'fileInput' + candidateIndex" class="block cursor-pointer rounded-md bg-brandColor1 px-2 py-1 text-center text-xs text-white shadow-redesign hover:bg-brandColor1/[.80]" v-html="value['label']"></label>
          <input @change="onUploadCv($event, value['action']['contactId'], candidateIndex)" type="file" :name="'file' + candidateIndex" :id="'fileInput' + candidateIndex" class="absolute top-0 left-0 z-0 pointer-events-none opacity-0 invisible" ref="file" />
        </div>


      </div>
    </div>
  </td>
</template>

<script setup>

import {__, capitalize} from "../../../../../helpers";
import axios from "axios";
import {useToast} from "vue-toastification";
import {inject} from "vue";
const loadUsers = inject('loadUsers');

const toast = useToast();
const props = defineProps({
  candidateIndex: Number,
  columnData: [Object, Array, String, Number],
  person: Object,
  people: Array,
  total: Number,
});

const onUploadCv = (event, cId, candidateIndex) => {
  let file = event.target.files[0];
  let formData = new FormData();
  formData.append('file', file);
  formData.append('contact_id', cId);

  axios.post(route('redesign.company.contacts.convert'),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
  ).then(response => {
    toast.success(response.data.message)
    loadUsers();
  }).catch( err => {
    toast.error( err.response.data.message || __("Something went wrong.") );
  });
}

</script>

