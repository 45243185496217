<template>
  <div
    class="md:shadow-card h-fit w-full space-y-6 rounded-lg bg-white px-6 py-8 md:space-y-8 md:p-14"
  >
    <div class="flex gap-2 md:gap-4">
      <Icon class="text-[2rem] text-primary md:text-[2.5rem]" name="language" />
      <h1 class="text-2xl font-medium text-zinc-800 md:text-4xl">
        {{ __('Choose your preferred language') }}
      </h1>
    </div>

    <form class="space-y-8" @submit.prevent="handleSubmit">
      <InputCombobox
        v-model:value="selectedLanguage"
        :placeholder="__('Language')"
        :options="languages"
        @update:value="handleValueUpdate"
      />

      <Button
        :key="selectedLanguage?.id"
        class="ml-auto flex items-center gap-1.5"
        type="submit"
        :disabled="isNextButtonDisabled"
      >
        <Icon
          v-if="isLoading"
          class="animate-spin text-lg"
          name="progress_activity"
        />

        <span>{{ __("Let's Start") }}</span>
      </Button>
    </form>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputCombobox } from '@/components/form/input-combobox';

  const props = defineProps({
    languages: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits(['update:selected-language']);

  const toast = useToast();

  const isNextButtonDisabled = computed(() => {
    return !selectedLanguage.value || isLoading.value;
  });

  const selectedLanguage = ref(null);
  const isLoading = ref(false);

  async function handleSubmit() {
    isLoading.value = true;

    try {
      await axios.post(route('onboarding.languages'), {
        language: selectedLanguage.value
      });

      sessionStorage.setItem(
        'onboarding_selected_language',
        JSON.stringify(selectedLanguage.value)
      );

      emits('update:selected-language', selectedLanguage.value);
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }

  onMounted(async () => {
    const stringifiedOnboardingSelectedLanguage = sessionStorage.getItem(
      'onboarding_selected_language'
    );

    if (!stringifiedOnboardingSelectedLanguage) {
      return;
    }

    const onboardingSelectedLanguage = JSON.parse(
      stringifiedOnboardingSelectedLanguage
    );

    if (!onboardingSelectedLanguage) {
      return;
    }

    selectedLanguage.value = onboardingSelectedLanguage;
  });

  function handleValueUpdate() {
    localStorage.setItem('locale', selectedLanguage.value.code);
  }
</script>
