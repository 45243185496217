<template>
  <div class="h-full px-5 pb-5">
    <div>
      <div v-for="(item, index) in filterData" v-bind:key="index">
        <div class="grid grid-cols-2 gap-5">
          <div class="col-span-2 w-full xl:col-span-1">
            <label
              class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >
              {{ __('Country') }}
              <div v-if="markMandatory == true">
                <Toggle
                  v-model="item.availability[7].mandatory"
                  :value="item.availability[7].mandatory"
                  :setid="'mandatory'"
                />
              </div>
            </label>

            <div class="relative">
              <InputCountry
                id="country"
                v-model:value="item.availability[7].value"
                :placeholder="__('Select country')"
                :options="item.availability[7].values"
                @update:value="item.availability[1].value = null"
              />
            </div>
          </div>

          <div class="col-span-2 w-full xl:col-span-1">
            <label
              class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >
              {{ __('City') }}
              <div v-if="markMandatory == true">
                <Toggle
                  v-model="item.availability[1].mandatory"
                  :value="item.availability[1].mandatory"
                  :setid="'mandatory'"
                />
              </div>
            </label>

            <div class="relative">
              <InputCity
                id="city"
                v-model:value="item.availability[1].value"
                :placeholder="__('Select city')"
                :disabled="!item.availability[7].value"
                :country="item.availability[7].value"
              />
            </div>
          </div>
          <div class="col-span-2 w-full xl:col-span-1">
            <label
              class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >
              {{ __('Employment type') }}
              <div v-if="markMandatory == true">
                <Toggle
                  v-model="item.availability[0].mandatory"
                  :value="item.availability[0].mandatory"
                  :setid="'mandatory'"
                />
              </div>
            </label>
            <div class="relative">
              <Multiselect
                v-model="item.availability[0].value"
                :values="item.availability[0].values"
                @clear="item.availability[0].value = null"
                :clearable="true"
                type="static"
              />
            </div>
          </div>

          <div class="col-span-2 w-full xl:col-span-1">
            <label
              class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
              >{{ __('Domain') }}
              <div v-if="markMandatory == true">
                <Toggle
                  v-model="item.availability[3].mandatory"
                  :value="item.availability[3].mandatory"
                  :setid="'mandatory'"
                />
              </div>
            </label>

            <div class="relative">
              <Multiselect
                v-model="item.availability[3].value"
                :values="item.availability[3].values"
                @clear="item.availability[3].value = null"
                :clearable="true"
                type="static"
              />
            </div>
          </div>
        </div>

        <div class="mt-[1.25rem] grid grid-cols-1 gap-5">
          <div class="col-span-2 w-full xl:col-span-1">
            <label
              class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
              >{{ __('Department') }}
              <div v-if="markMandatory == true">
                <Toggle
                  v-model="item.availability[6].mandatory"
                  :value="item.availability[6].mandatory"
                  :setid="'mandatory'"
                />
              </div>
            </label>

            <div class="relative">
              <Multiselect
                v-model="item.availability[6].value"
                :values="item.availability[6].values"
                @clear="item.availability[6].value = null"
                :clearable="true"
                type="static"
              />
            </div>
          </div>
        </div>

        <div class="mt-[1.25rem] grid grid-cols-3 gap-5">
          <div class="col-span-2 w-full py-1 xl:col-span-1">
            <label
              class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >
              {{ __('Currency') }}
              <div v-if="markMandatory == true">
                <Toggle
                  v-model="item.availability[4].mandatory"
                  :value="item.availability[4].mandatory"
                  :setid="'mandatory'"
                />
              </div>
            </label>
            <Multiselect
              v-model="item.availability[4].value"
              :values="item.availability[4].values"
              @clear="item.availability[4].value = null"
              :clearable="true"
              type="static"
            />
          </div>

          <div class="col-span-2 w-full py-1 xl:col-span-1">
            <label
              class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >
              {{ __('Salary type') }}
              <div v-if="markMandatory == true">
                <Toggle
                  v-model="item.availability[5].mandatory"
                  :value="item.availability[5].mandatory"
                  :setid="'mandatory'"
                />
              </div>
            </label>
            <Multiselect
              v-model="item.availability[5].value"
              :values="item.availability[5].values"
              @clear="item.availability[5].value = null"
              :clearable="true"
              type="static"
            />
          </div>
          <div class="col-span-2 w-full py-1 xl:col-span-1">
            <label
              class="flex items-center justify-between pb-1 font-primary font-normal text-gray-400"
            >
              {{ __('Salary (gross)') }}
              <div v-if="markMandatory == true">
                <Toggle
                  v-model="item.availability[2].mandatory"
                  :value="item.availability[2].mandatory"
                  :setid="'mandatory'"
                />
              </div>
            </label>
            <InputText
              type="number"
              v-model="item.availability[2].value"
              :value="item.availability[2].value"
              placeholder="0"
              min="0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { Toggle, Multiselect, InputText } from '../../global';
  import { InputCity } from '@/components/form/input-location/components/input-city';
  import { InputCountry } from '@/components/form/input-location/components/input-country';
  import { onMounted } from 'vue';

  const props = defineProps({
    markMandatory: Boolean,
    filterData: Array
  });

  onMounted(() => {
    props.filterData.forEach((item) => {
      item.availability[7].value = item.availability[7].defaultCountry;
    });
  });
</script>
