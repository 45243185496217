<template>
  <div id="location">
    <div class="mt-8 md:grid md:grid-cols-2">
      <SectionHeader icon="location_on" title="Location" />
    </div>

    <div class="relative mt-8 rounded-app bg-white p-8 shadow-md">
      <div>
        <div class="md:grid md:grid-cols-2 md:gap-x-2">
          <div v-for="(value, index) in data.job" :key="index" class="mb-4">
            <div class="mb-4 rounded-app p-4 shadow-md">
              <!-- Delete X -->
              <div class="flex justify-end">
                <button type="button" @click="handleDeleteButtonClick(index)">
                  <span class="material-symbols-rounded" aria-hidden="true">
                    close
                  </span>
                  <span class="sr-only">{{ __('Delete') }}</span>
                </button>
              </div>
              <div class="md:grid md:grid-cols-2 md:gap-x-2">
                <div class="my-4 md:my-0">
                  <label class="mb-2 block text-sm text-[#58585E]">
                    {{ __('Country') }} *
                  </label>

                  <InputCountry
                    id="country"
                    v-model:value="value.country"
                    :placeholder="__('Select country')"
                    required
                    :options="data.options.countries"
                    :errors="errors['locations.' + index + '.country']"
                    @update:value="value.city = null"
                  />
                </div>

                <div class="my-4 md:my-0">
                  <label class="mb-2 block text-sm text-[#58585E]">
                    {{ __('City') }} *
                  </label>

                  <InputCity
                    id="city"
                    v-model:value="value.city"
                    :placeholder="__('Select city')"
                    :disabled="!value.country"
                    required
                    :country="value.country"
                    :errors="errors['locations.' + index + '.city']"
                  />
                </div>

                <div class="my-4 md:col-span-2">
                  <Input
                    v-model="value.openings"
                    :value="value.openings"
                    placeholder="0"
                    label="Number of open jobs"
                    type="number"
                    :errors="errors['locations.' + index + '.openings']"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          :content="__('Add city')"
          type="button"
          variation="normal"
          icon="add_box"
          @click="handleAddButtonClick"
        />
        <p class="mt-1 text-xs text-error" v-html="errors.locations"></p>
      </div>

      <Textarea
        v-model="job.job.address"
        :value="job.job.address"
        placeholder="If you add multiple cities, you can add here multiple address but mention also the city."
        label="Office Address"
        class="mt-8"
        :errors="errors.office_address"
        :maxlength="191"
      />
    </div>
  </div>
</template>

<script setup>
  import {
    Button,
    SectionHeader,
    Textarea,
    Input
  } from '../../../../components/global';
  import { InputCity } from '@/components/form/input-location/components/input-city';
  import { InputCountry } from '@/components/form/input-location/components/input-country';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: true
    },
    job: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['update:data']);

  const defaultCountry = props.data.job[0].country;

  function handleAddButtonClick() {
    emits('update:data', {
      ...props.data,
      job: [
        ...props.data.job,
        { country: defaultCountry, city: null, openings: 0 }
      ]
    });
  }

  function handleDeleteButtonClick(index) {
    emits('update:data', {
      ...props.data,
      job: props.data.job.filter((_, i) => i !== index)
    });
  }
</script>
