<template>
  <div
    v-if="isLoading"
    class="md:shadow-card h-fit w-full rounded-lg bg-white px-6 py-8 md:p-14"
  >
    <div class="flex items-center gap-1.5 text-zinc-500">
      <Icon class="animate-spin text-xl" name="progress_activity" />
      <span>{{ __('Loading...') }}</span>
    </div>
  </div>

  <div
    v-else-if="steps.length && currentStep"
    class="mx-auto w-full space-y-4 md:space-y-6"
  >
    <Progress class="w-full" :step="getArrayIndex(currentStep.index)" :steps="steps.length" />

    <div
      class="md:shadow-card h-fit w-full rounded-lg bg-white px-6 py-8 md:p-14"
    >
      <Resume
        v-if="currentStep.slug === 'resume'"
        :hide-back-button="hideBackButton"
        @back="handleBackButtonClick"
        @next="handleNextButtonClick"
      />
      <Introduction
        v-else-if="currentStep.slug === 'introduction'"
        @back="handleBackButtonClick"
        @next="handleNextButtonClick"
      />
      <Career
        v-else-if="currentStep.slug === 'career'"
        @back="handleBackButtonClick"
        @next="handleNextButtonClick"
      />
      <Job
        v-else-if="currentStep.slug === 'job'"
        @back="handleBackButtonClick"
        @next="handleNextButtonClick"
      />
      <Details
        v-else-if="currentStep.slug === 'details'"
        @back="handleBackButtonClick"
        @next="handleNextButtonClick"
      />
      <Inclusivity
        v-else-if="currentStep.slug === 'inclusivity'"
        @back="handleBackButtonClick"
        @next="handleNextButtonClick"
      />
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';
  import { Career } from './components/career';
  import { Details } from './components/details';
  import { Inclusivity } from './components/inclusivity';
  import { Introduction } from './components/introduction';
  import { Job } from './components/job';
  import { Progress } from '../progress';
  import { Resume } from './components/resume';

  const props = defineProps({
    currentStep: {
      type: [Object, null],
      required: true
    },
    languages: {
      type: Array,
      default: () => []
    }
  });

  const emits = defineEmits([
    'update:selected-language',
    'update:current-step',
    'update:is-completed'
  ]);

  const toast = useToast();

  const hideBackButton = computed(() => {
    return props.languages.length === 1;
  });

  const steps = ref([]);
  const isLoading = ref(false);

  async function fetchSteps() {
    isLoading.value = true;

    try {
      const response = await axios.get(route('onboarding.steps'));
      steps.value = response.data.steps;
    } catch {
      toast.error(
        __('An unexpected error has occured. Please try again later.')
      );
    }

    isLoading.value = false;
  }

  function handleBackButtonClick() {
    if (getArrayIndex(props.currentStep.index) > 1) {
      let newCurrentIndex = props.currentStep.index - 1;
      let newCurrentStep = findStepByIndex(newCurrentIndex);
      while (!newCurrentStep && newCurrentIndex > 0) {
        newCurrentStep = findStepByIndex(newCurrentIndex--);
      }
      emits('update:current-step', newCurrentStep);
    } else {
      emits('update:selected-language', null);
    }
  }

  function handleNextButtonClick() {
    if (getArrayIndex(props.currentStep.index) < steps.value.length) {
      let newCurrentIndex = props.currentStep.index;
      let newCurrentStep = findStepByIndex(newCurrentIndex);

      while (newCurrentIndex <= getMaximumIndex() && (!newCurrentStep || newCurrentStep.index == props.currentStep.index)) {
        newCurrentStep = findStepByIndex(newCurrentIndex++);
      }

      emits('update:current-step', newCurrentStep);
    } else {
      emits('update:is-completed', true);
    }
  }

  onMounted(async () => {
    await fetchSteps();

    if (!props.currentStep && steps.value.length) {
      emits('update:current-step', steps.value[0]);
    }
  });

  function findStepByIndex(searchIndex) {
    return steps.value.find((step) => step.index === searchIndex);
  }

  function getArrayIndex(searchIndex) {
    return steps.value.findIndex(item => item.index === searchIndex) + 1;
  }

  function getMaximumIndex() {
    return Math.max(...steps.value.map(item => item.index));
  }
</script>
