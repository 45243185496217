<template>
  <div
    class="shadow-card relative overflow-hidden rounded-2xl bg-white p-6 md:p-8"
  >
    <div
      v-if="progress"
      class="absolute right-0 top-0 rounded-l-full bg-secondary px-3 py-1"
    >
      <span class="block text-xs leading-none text-white">{{ progress }}</span>
    </div>

    <EmptySection v-if="!data.length" :title="__('Add your skills')">
      <template #icon>
        <Icon class="text-xl text-zinc-200" name="star" is-filled />
      </template>
    </EmptySection>

    <div v-else class="space-y-4">
      <div v-for="group in groups" :key="group.category.id" class="space-y-2">
        <span class="block font-medium text-zinc-800">
          {{ group.category.name }}
        </span>

        <div class="flex flex-wrap gap-x-3 gap-y-2">
          <span
            v-for="skill in group.skills"
            :key="skill.id"
            class="rounded-lg border border-secondary px-3 py-1 leading-tight text-zinc-800"
          >
            {{ skill.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { Icon } from '@/components/block/icon';
  import { EmptySection } from '@/pages/components/empty-section';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    }
  });

  const progress = computed(() => {
    return props.fields.total
      ? `${props.fields.completed}/${props.fields.total}`
      : '';
  });

  const groups = computed(() => {
    let groups = {};

    props.data.forEach((skill) => {
      const categoryId = skill.category.id;

      if (!groups[categoryId]) {
        groups[categoryId] = {
          category: skill.category,
          skills: []
        };
      }

      groups[categoryId].skills.push({ id: skill.id, name: skill.name });
    });

    let result = [];

    for (let categoryId in groups) {
      result.push(groups[categoryId]);
    }

    return result;
  });
</script>
