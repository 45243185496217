<template>
  <div class="space-y-12">
    <h1 class="text-5xl font-medium text-zinc-800">
      {{ __(isEditMode ? 'Edit event' : 'Create event') }}
    </h1>

    <form class="space-y-8" @submit.prevent="handleSubmit">
      <Group
        v-model:value="values.group"
        v-model:errors="errors.group"
        :options="options.groups"
      />

      <Details
        v-model:values="values.details"
        v-model:files="files.details"
        v-model:errors="errors.details"
        :options="options"
      />
      
      <Gamification
        v-model:values="values.gamification"
        v-model:errors="errors.gamification"
        :options="options.points"
      />

      <Speakers
        v-model:values="values.speakers"
        v-model:errors="errors.speakers"
        :options="options.speakers"
      />

      <Partners
        v-model:values="values.partners"
        v-model:files="files.partners"
        v-model:errors="errors.partners"
      />

      <div class="flex flex-col gap-2 md:flex-row-reverse md:justify-center">
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { getTimezone } from '@/scripts/date.script';
  import { isValidHttpUrl } from '@/scripts/validate.script';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { Details } from './components/event-form/details';
  import { Gamification } from './components/event-form/gamification';
  import { Group } from './components/event-form/group';
  import { Partners } from './components/event-form/partners';
  import { Speakers } from './components/event-form/speakers';

  const props = defineProps({
    event: {
      type: [Object, null],
      default: null
    },
    options: {
      type: Object,
      required: true
    }
  });

  const toast = useToast();

  const isEditMode = computed(() => {
    return !!event.value;
  });

  const groupIdSearchParam = computed(() => {
    const url = new URL(window.location.href);

    return parseInt(url.searchParams.get('groupId'));
  });

  const localTimezone = computed(() => {
    const timezone = getTimezone();

    return (
      props.options.timezones.find(
        (item) => item.offset === timezone.offset && item.tz === timezone.tz
      ) || null
    );
  });

  const event = ref(JSON.parse(JSON.stringify(props.event)));
  const values = ref({
    group:
      event.value?.group ||
      props.options.groups.find(
        (option) => option.id === groupIdSearchParam.value
      ) ||
      null,
    details: {
      cover: event.value?.details.cover || null,
      photo: event.value?.details.photo || null,
      name: event.value?.details.name || '',
      description: event.value?.details.description || '',
      timezone: event.value?.details.timezone || localTimezone,
      start_date_time: event.value?.details.start_date_time || '',
      end_date_time: event.value?.details.end_date_time || '',
      type: event.value?.details.type || props.options.type[0],
      location: event.value?.details.location || null,
      address: event.value?.details.address || '',
      streaming_platform: event.value?.details.streaming_platform || '',
      attendee_limit: event.value?.details.attendee_limit || '',
      rsvp: event.value?.details.rsvp || ''
    },
    gamification: {
      points: event.value?.gamification.points || null
    },
    speakers: event.value?.speakers || [],
    partners: event.value?.partners || []
  });

  const files = ref({
    details: {
      cover: null,
      photo: null
    },
    partners: event.value?.partners.map(() => null) || []
  });

  const errors = ref({
    group: [],
    details: {
      cover: [],
      photo: [],
      name: [],
      description: [],
      timezone: [],
      start_date_time: [],
      end_date_time: [],
      type: [],
      location: [],
      address: [],
      streaming_platform: [],
      attendee_limit: [],
      rsvp: []
    },
    gamification: {},
    speakers: [],
    partners: {}
  });
  const isLoading = ref(false);

  function isValid(value) {
    if (!value) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'object') {
      for (const prop in value) {
        if (value[prop] === null) {
          return false;
        }
      }
      return true;
    }

    return true;
  }

  async function uploadPhoto(file) {
    const formData = new FormData();

    formData.append('photo', file);

    const response = await axios.post(
      route('redesign.media.uploadPhoto'),
      formData
    );

    return response.data.photo_url;
  }

  function handleCancelButtonClick() {
    history.back();
  }

  async function handleSubmit() {
    const requiredFieldKeys = {
      details: [
        'cover',
        'photo',
        'name',
        'description',
        'timezone',
        'start_date_time',
        'end_date_time',
        'type'
      ],
      partners: ['company_name', 'website', 'logo']
    };

    if (values.value.details.type) {
      if (values.value.details.type.id === 1) {
        requiredFieldKeys.details.push('streaming_platform');
      } else if (values.value.details.type.id === 2) {
        requiredFieldKeys.details.push('location');
        requiredFieldKeys.details.push('address');
      }
    }

    let firstElement;

    requiredFieldKeys.details.forEach((key) => {
      if (!isValid(values.value.details[key])) {
        errors.value.details[key] = [__('This field is required')];

        const element = document.getElementById(key);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (
      values.value.details.streaming_platform &&
      !isValidHttpUrl(values.value.details.streaming_platform)
    ) {
      errors.value.details.streaming_platform = [
        __('Invalid URL. Please ensure the URL is correctly formatted.')
      ];

      const element = document.getElementById('streaming_platform');

      if (element && !firstElement) {
        firstElement = element;
      }
    }

    values.value.partners.forEach((item, index) => {
      requiredFieldKeys.partners.forEach((key) => {
        if (!item[key]) {
          errors.value.partners[`${index}.${key}`] = [
            __('This field is required')
          ];

          const element = document.getElementById(`${index}.${key}`);

          if (element && !firstElement) {
            firstElement = element;
          }
        }
      });

      if (item.website && !isValidHttpUrl(item.website)) {
        errors.value.partners[`${index}.website`] = [
          __('Invalid URL. Please ensure the URL is correctly formatted.')
        ];

        const element = document.getElementById(`${index}.website`);

        if (element && !firstElement) {
          firstElement = element;
        }
      }
    });

    if (
      Object.values(errors.value.details).some((error) => error.length) ||
      Object.values(errors.value.partners).some((error) => error.length)
    ) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      if (files.value.details.cover) {
        values.value.details.cover = await uploadPhoto(
          files.value.details.cover
        );
      }

      if (files.value.details.photo) {
        values.value.details.photo = await uploadPhoto(
          files.value.details.photo
        );
      }

      if (files.value.partners.length) {
        const newPartners = values.value.partners
          .map((partner, index) => ({ partner, index }))
          .filter(({ partner }) => partner.logo.startsWith('data:'));

        const imagePromiseList = newPartners.map(async ({ index }) => {
          const file = files.value.partners[index];
          return file ? await uploadPhoto(file) : null;
        });

        const imageList = await Promise.all(imagePromiseList);

        newPartners.forEach(({ partner }, index) => {
          if (imageList[index]) {
            partner.logo = imageList[index];
          }
        });

        values.value.partners = values.value.partners.map((partner) => {
          if (partner.logo.startsWith('data:')) {
            return newPartners.find(({ partner: p }) => p === partner).partner;
          }

          return partner;
        });
      }

      let response;

      if (isEditMode.value) {
        response = await axios.post(
          route('redesign.company.events.update', { id: event.value.id }),
          values.value
        );
      } else {
        response = await axios.post(
          route('redesign.company.events.store'),
          values.value
        );
      }

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      window.location = route('redesign.company.events.show', {
        id: isEditMode.value ? event.value.id : response.data.id
      });
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([key, value]) => {
          if (key.startsWith('details.')) {
            const fieldKey = key.replace('details.', '');

            if (errors.value.details.hasOwnProperty(fieldKey)) {
              errors.value.details[fieldKey] = value;
            }
          } else if (errors.value.hasOwnProperty(key)) {
            errors.value[key] = value;
          }
        });
      } else {
        toast.error(
          __('An unexpected error has occurred. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }
</script>
