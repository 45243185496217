<template>
  <div class="lg:flex">
    <template v-if="!hideSidebar">
      <TransitionRoot as="template" :show="isOpen">
        <Dialog
          as="div"
          class="relative z-50 block 3xl:hidden"
          @close="handleCloseButtonClick"
        >
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="fixed inset-0 right-16 flex max-w-72 flex-col gap-4 overflow-y-auto bg-white p-4 md:right-0"
            >
              <div class="flex items-start justify-between gap-4">
                <div class="flex shrink-0 grow items-center px-3 py-4">
                  <slot name="logo"></slot>
                </div>

                <button
                  :aria-label="__('Close')"
                  class="-m-2 flex p-2 text-zinc-800"
                  @click="handleCloseButtonClick"
                >
                  <Icon name="close" class="text-lg" />
                </button>
              </div>

              <nav class="flex grow flex-col justify-between gap-8">
                <ul
                  v-for="menu in menus"
                  :key="menu"
                  role="list"
                  class="space-y-1"
                >
                  <li v-for="item in menu" :key="item.name">
                    <Disclosure v-if="item.submenu" v-slot="{ open }" as="div">
                      <DisclosureButton
                        :class="[
                          item.current ? 'bg-zinc-100' : '',
                          'flex w-full items-center gap-3 rounded-md px-3 py-2 text-left text-sm leading-6 text-zinc-800 transition-colors hover:bg-zinc-100'
                        ]"
                      >
                        <Icon
                          :name="item.icon"
                          aria-hidden="true"
                          class="shrink-0 text-xl"
                        />

                        {{ __(item.title) }}

                        <Icon
                          name="expand_more"
                          :class="[
                            open ? 'rotate-180' : 'rotate-0',
                            'ml-auto shrink-0 text-xl'
                          ]"
                        />
                      </DisclosureButton>

                      <DisclosurePanel as="ul" class="mt-1 space-y-1">
                        <li
                          v-for="subItem in item.submenu"
                          :key="subItem.title"
                        >
                          <DisclosureButton
                            as="a"
                            :href="subItem.url"
                            :class="[
                              subItem.current ? 'bg-zinc-100' : '',
                              'flex items-center rounded-md px-3 py-2 pl-11 text-sm leading-6 text-zinc-800 transition-colors hover:bg-zinc-100'
                            ]"
                          >
                            {{ __(subItem.title) }}
                          </DisclosureButton>
                        </li>
                      </DisclosurePanel>
                    </Disclosure>

                    <a
                      v-else
                      :href="item.url"
                      :class="[
                        item.current ? 'bg-zinc-100' : '',
                        'flex items-center gap-3 rounded-md px-3 py-2 text-sm leading-6 text-zinc-800 transition-colors hover:bg-zinc-100'
                      ]"
                    >
                      <Icon
                        :name="item.icon"
                        aria-hidden="true"
                        class="shrink-0 text-xl"
                      />

                      {{ __(item.title) }}
                    </a>
                  </li>
                </ul>
              </nav>
            </DialogPanel>
          </TransitionChild>
        </Dialog>
      </TransitionRoot>

      <div
        v-if="isCollapsed"
        class="sticky left-0 top-0 z-50 h-screen w-fit shrink-0 flex-col overflow-y-auto bg-white p-4 hidden 3xl:!flex"
      >
        <div class="h-16 px-3 py-4">
          <slot name="small-logo"></slot>
        </div>

        <nav class="mt-4 flex grow flex-col justify-between gap-8">
          <ul v-for="menu in menus" :key="menu" role="list" class="space-y-1">
            <li v-for="item in menu" :key="item.name">
              <a
                :href="item.url"
                :class="[
                  item.current ? 'bg-zinc-100' : '',
                  'flex h-10 w-10 items-center justify-center rounded-md text-zinc-800 transition-colors hover:bg-zinc-100'
                ]"
              >
                <Icon
                  :name="item.icon"
                  aria-hidden="true"
                  class="shrink-0 text-xl"
                />
              </a>
            </li>
          </ul>
        </nav>

        <button
          class="mt-1 flex h-10 w-10 items-center justify-center rounded-md text-zinc-800 transition-colors hover:bg-zinc-100"
          @click="handleColapseButtonClick"
        >
          <Icon
            name="keyboard_tab"
            aria-hidden="true"
            class="shrink-0 text-xl"
            :class="isCollapsed ? 'rotate-0' : 'rotate-180'"
          />
        </button>
      </div>

      <div
        v-else
        class="sticky left-0 top-0 z-50 h-screen w-72 shrink-0 flex-col overflow-y-auto bg-white p-4 hidden 3xl:!flex"
      >
        <div class="flex h-16 shrink-0 items-center px-3 py-4">
          <slot name="logo"></slot>
        </div>

        <nav class="mt-4 flex grow flex-col justify-between gap-8">
          <ul v-for="menu in menus" :key="menu" role="list" class="space-y-1">
            <li v-for="item in menu" :key="item.name">
              <Disclosure v-if="item.submenu" v-slot="{ open }" as="div">
                <DisclosureButton
                  :class="[
                    item.current ? 'bg-zinc-100' : '',
                    'flex w-full items-center gap-3 rounded-md px-3 py-2 text-left text-sm leading-6 text-zinc-800 transition-colors hover:bg-zinc-100'
                  ]"
                >
                  <Icon
                    :name="item.icon"
                    aria-hidden="true"
                    class="shrink-0 text-xl"
                  />

                  {{ __(item.title) }}

                  <Icon
                    name="expand_more"
                    :class="[
                      open ? 'rotate-180' : 'rotate-0',
                      'ml-auto shrink-0 text-xl'
                    ]"
                  />
                </DisclosureButton>

                <DisclosurePanel as="ul" class="mt-1 space-y-1">
                  <li v-for="subItem in item.submenu" :key="subItem.title">
                    <DisclosureButton
                      as="a"
                      :href="subItem.url"
                      :class="[
                        subItem.current ? 'bg-zinc-100' : '',
                        'flex items-center rounded-md px-3 py-2 pl-11 text-sm leading-6 text-zinc-800 transition-colors hover:bg-zinc-100'
                      ]"
                    >
                      {{ __(subItem.title) }}
                    </DisclosureButton>
                  </li>
                </DisclosurePanel>
              </Disclosure>

              <a
                v-else
                :href="item.url"
                :class="[
                  item.current ? 'bg-zinc-100' : '',
                  'flex items-center gap-3 rounded-md px-3 py-2 text-sm leading-6 text-zinc-800 transition-colors hover:bg-zinc-100'
                ]"
              >
                <Icon
                  :name="item.icon"
                  aria-hidden="true"
                  class="shrink-0 text-xl"
                />

                {{ __(item.title) }}
              </a>
            </li>
          </ul>
        </nav>

        <button
          class="mt-1 flex items-center gap-3 rounded-md px-3 py-2 text-sm leading-6 text-zinc-800 transition-colors hover:bg-zinc-100"
          @click="handleColapseButtonClick"
        >
          <Icon
            name="keyboard_tab"
            aria-hidden="true"
            class="shrink-0 text-xl"
            :class="isCollapsed ? 'rotate-0' : 'rotate-180'"
          />

          {{ __('Collapse') }}
        </button>
      </div>
    </template>

    <div class="grow overflow-hidden">
      <div
        class="sticky top-0 z-40 flex items-center border-b border-gray-200 bg-white p-4 shadow-sm sm:px-6 lg:px-8"
      >
        <div
          v-if="!hideSidebar"
          class="flex 3xl:!hidden h-8 items-center justify-center border-r border-zinc-800/15 pr-4 sm:pr-6"
        >
          <button
            :aria-label="__('Open')"
            type="button"
            class="-m-2 flex p-2 text-zinc-800"
            @click="handleOpenButtonClick"
          >
            <Icon class="text-xl" name="menu" />
          </button>
        </div>

        <div
          class="ml-auto flex h-8 items-center justify-center border-l border-zinc-800/15 pl-4 lg:pl-6"
        >
          <slot name="shop"></slot>
          <slot name="user"></slot>
        </div>
      </div>

      <div :class="customClasses || 'mx-auto max-w-7xl py-10'">
        <div class="px-4 sm:px-6 lg:px-8">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot
  } from '@headlessui/vue';
  import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel
  } from '@headlessui/vue';
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    sidebartopmenu: {
      type: Object,
      required: true
    },
    sidebarbottommenu: {
      type: Object,
      required: true
    },
    hideSidebar: {
      type: Boolean,
      default: false
    },
    customClasses: {
      type: String,
      default: ''
    }
  });

  const menus = computed(() => {
    return [
      [...JSON.parse(JSON.stringify(props.sidebartopmenu))],
      [...JSON.parse(JSON.stringify(props.sidebarbottommenu))]
    ];
  });

  const isOpen = ref(false);
  const isCollapsed = ref(false);

  function handleOpenButtonClick() {
    isOpen.value = true;
  }

  function handleCloseButtonClick() {
    isOpen.value = false;
  }

  function handleColapseButtonClick() {
    isCollapsed.value = !isCollapsed.value;
    localStorage.setItem('is_collapsed', isCollapsed.value);
  }

  onMounted(() => {
    const isCollapsedLocalStorage = localStorage.getItem('is_collapsed');

    if (isCollapsedLocalStorage) {
      isCollapsed.value = JSON.parse(isCollapsedLocalStorage);
    }
  });
</script>
