export const getValues = (array, field_name) => {
  // if field_name return an array of {"field_name": "value"}
  console.log(array);
  if (field_name) {
    return array.map((item) => {
      return { [field_name]: item[field_name] };
    });
  }

  // else return an array of values
  return array.map((item) => {
    return item.text;
  });
};

export const formatShortDate = (date) => {
  const options = { month: "short", year: "numeric" };
  return new Date(date).toLocaleDateString("en-US", options);
};

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

// export const getLocationsByQuery = async (query, locations) => {
//   if (query.length < 3 || query === undefined) {
//     return;
//   }

//   axios
//     .get(route("cities.find_cities"), {
//       params: {
//         q: query,
//       },
//     })
//     .then((response) => {
//       locations.value = response.data;
//     });
// };
