<template>
  <div>
    <div
      class="h-[70vh] overflow-y-scroll rounded-app border border-gray-200 bg-white sm:h-[69vh] lg:h-[73vh]"
    >
      <div class="px-4 pt-4 sm:flex sm:items-center sm:px-10 sm:pt-8">
        <div class="mb-4 sm:mb-0 sm:mr-4 sm:w-1/3">
          <template v-if="noJobs">
            <p class="color-black text-base font-bold">
              {{ __('No jobs available!') }}
            </p>
          </template>
          <Combobox v-else as="div" v-model="selectedJob">
            <div class="relative">
              <ComboboxInput
                class="w-full rounded-app border border-gray-200 bg-white py-1.5 pl-3 pr-10 font-semibold shadow-sm focus:border-gray-200 focus:ring-0 sm:text-sm sm:leading-6"
                @change="query = $event.target.value"
                :display-value="(job) => job?.title"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
              >
                <span class="material-symbols-rounded group-hover:text-white">
                  expand_more
                </span>
              </ComboboxButton>

              <ComboboxOptions
                v-if="jobs.length > 0"
                class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm"
              >
                <ComboboxOption
                  v-for="job in filteredJobs"
                  :key="job.id"
                  :value="job"
                  as="template"
                  v-slot="{ active, selected }"
                >
                  <li
                    :class="[
                      'relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-black hover:text-white',
                      active ? 'bg-black text-white' : 'text-black'
                    ]"
                  >
                    <span
                      :class="['block truncate', selected && 'font-semibold']"
                    >
                      {{ job.title }}
                    </span>
                    <span
                      v-if="selected"
                      :class="[
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                        active ? 'text-black' : 'text-indigo-600'
                      ]"
                    >
                      <span class="material-symbols-rounded text-secondary">
                        done
                      </span>
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </div>
          </Combobox>
        </div>

        <CandidatesFilter @toggleFilter="toggleFilter"></CandidatesFilter>
      </div>

      <div
        v-if="currentComponent.url === '#contacts-tab'"
        class="block px-4 pt-4 sm:mb-4 sm:mt-6 sm:px-10"
      >
        <Form
          :country_codes="props.country_codes"
          :contact_sources="props.contact_sources"
          :job_id="selectedJob.id"
          :countries="countries"
          :default-country="defaultCountry"
        ></Form>
      </div>

      <div>
        <!-- tabs -->
        <!-- mobile tab select -->
        <div class="block px-4 pt-4 xl:hidden">
          <Listbox as="div" v-model="selectedTabOnMobile">
            <div class="relative">
              <ListboxButton
                class="w-full rounded-app border border-gray-200 bg-white py-1.5 pl-3 pr-10 text-left font-semibold leading-6 shadow-sm focus:border-gray-200 focus:ring-0 sm:text-sm sm:leading-6"
              >
                <span class="block truncate">{{
                  __(selectedTabOnMobile.label)
                }}</span>
                <span
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <span class="material-symbols-rounded group-hover:text-white"
                    >expand_more</span
                  >
                </span>
              </ListboxButton>

              <ListboxOptions
                class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  as="template"
                  @change.prevent="changeTab(tab)"
                  v-for="tab in components"
                  :key="tab.name"
                  :value="tab"
                  v-slot="{ mobileActive, mobileSelected }"
                >
                  <li
                    :class="[
                      mobileActive
                        ? 'bg-indigo-600 text-white'
                        : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    ]"
                  >
                    <span
                      :class="[
                        mobileSelected ? 'font-semibold' : 'font-normal',
                        'block truncate'
                      ]"
                      >{{ __(tab.label) }}</span
                    >

                    <span
                      v-if="mobileSelected"
                      :class="[
                        mobileActive ? 'text-white' : 'text-indigo-600',
                        'absolute inset-y-0 right-0 flex items-center pr-4'
                      ]"
                    >
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </div>
          </Listbox>
        </div>
        <!-- mobile tab select -->

        <div class="hidden flex-auto pt-4 text-sm font-bold xl:block">
          <div class="border-grey-200 border-b">
            <nav class="flex flex-nowrap px-3" aria-label="Tabs">
              <a
                v-for="tab in components"
                @click.prevent="changeTab(tab)"
                :key="tab.name"
                href="#"
                :class="[
                  tab.url === currentComponent.url
                    ? 'ml-0-important min-w-[16.666%] border-brandColor1 text-center font-bold text-brandColor1'
                    : 'ml-0-important min-w-[16.666%] border-transparent text-center text-black hover:border-brandColor1 hover:text-brandColor1',
                  'group flex items-center justify-center whitespace-break-spaces border-b-2 py-4 text-base'
                ]"
              >
                <span class="relative"
                  >{{ __(tab.label) }}
                  <span
                    class="absolute -right-[32px] top-1/2 ml-[0.30rem] hidden -translate-y-1/2 transform rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-bold leading-[1.2rem] text-black group-hover:bg-brandColor1/[.06] md:inline-block"
                    >{{ tab.total }}</span
                  >
                </span>
              </a>
            </nav>
          </div>
        </div>
      </div>
      <!-- end tabs -->

      <template v-for="component in components" :key="component.url">
        <component
          :is="renderComponent(component.url)"
          v-if="currentComponent.url === component.url"
          :data="component.data"
          :columns="component.columns"
          :per_page="component.per_page"
          :total="component.total"
          @loadMoreUsers="loadMoreUsers"
          @open-resume-modal="handleOpenResumeModal"
        ></component>
      </template>
    </div>

    <ResumeModal
      v-if="isResumeModalOpen"
      v-model:username="candidateUsername"
      :username-list="candidateUsernameList"
      @update:applications="handleUpdateApplications"
      @close="handleCloseResumeModal"
      @update:currentIndex="handleCurrentIndexUpdate"
    />
  </div>
  <!--tw-container-->
</template>

<script setup>
  import { ref, reactive, computed, onMounted, provide, watch } from 'vue';
  import { useToast } from 'vue-toastification';
  import axios from 'axios';
  import { useRoute, useRouter } from 'vue-router';

  //partial components are defined in the global scope because i use dynamic render component and vue3 with composition api seems to not like them declared locally
  import {
    Combobox,
    ComboboxLabel,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    ComboboxButton,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions
  } from '@headlessui/vue';
  import Form from './partials/Form.vue';
  import { __ } from '../../../helpers';
  import { ResumeModal } from '@/components/other/resume-modal';

  const componentRoute = useRouter();
  const localRoute = useRoute();
  const toast = useToast();
  const props = defineProps({
    jobs: {
      type: Array,
      required: true
    },
    selected_job: {
      type: Object,
      required: true
    },
    components: {
      type: Array,
      required: true
    },
    country_codes: {
      type: Array,
      required: true
    },
    contact_sources: {
      type: Array,
      required: true
    },
    per_page: {
      type: Number,
      required: true
    },
    recruiter: {
      type: Object,
      required: true
    },
    countries: {
      type: Array,
      default: () => []
    },
    defaultCountry: {
      type: Object,
      default: null
    }
  });

  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  const page = ref(1);
  const components = ref(props.components);
  const componentFilters = ref(null);
  const locationFilter = ref(null);
  const currentComponent = ref(components.value[0]);
  const countRemoved = ref(0);
  const removed = ref(false);
  const selectedJob = ref(
    props.jobs.find((item) => item.id === props.selected_job.id)
  );
  const tabHasChanged = ref(false);
  const query = ref('');
  const noJobs = ref(false);
  const selectedTabOnMobile = ref(currentComponent);
  const blockInitialWatch = ref(true);

  const candidateUsername = ref(null);
  const candidateUsernameList = ref([]);
  const candidateIndex = ref(null);
  const isResumeModalOpen = ref(false);

  function handleOpenResumeModal(value) {
    candidateUsername.value = value.username;
    candidateUsernameList.value = value.usernameList;
    isResumeModalOpen.value = true;
    candidateIndex.value = value.usernameList.indexOf(value.username);
    markApplicationAsSeen(value.applicationId);
  }
  async function markApplicationAsSeen(applicationId) {
    await axios
      .post(route('redesign.cv.mark-application-seen'), {
        applicationId: applicationId
      })
      .then((response) => {
        if (response.data?.success) {
          let currentUser = currentComponent.value.data.find(
            (item) => item.id === applicationId
          );
          currentUser.seen = true;
        }
      });
  }

  function handleCloseResumeModal() {
    candidateUsername.value = null;
    candidateUsernameList.value = [];
    isResumeModalOpen.value = false;
  }

  function handleCurrentIndexUpdate(newIndex) {
    candidateIndex.value = newIndex;
    markApplicationAsSeen(currentComponent.value.data[candidateIndex.value].id);
  }

  watch(
    () => candidateIndex.value,
    (value) => {
      if (
        value >= candidateUsernameList.value.length - 1 &&
        candidateUsernameList.value.length < currentComponent.value.total
      ) {
        loadMoreUsers();
      }
    }
  );

  function handleUpdateApplications(applications) {
    components.value = components.value.map((component) => {
      const jobStillInArray = applications.some(
        (item) => item.job_title === selectedJob.value.title
      );

      //if you are on the same tab and you accepted a job that is not in the returned actions name, then remove the user
      if (component.url === currentComponent.value.url && !jobStillInArray) {
        currentComponent.value.data.splice(candidateIndex.value, 1);
        updateComponentTotal(currentComponent.value.total - 1);

        const currentCandidateUsernameIndex =
          candidateUsernameList.value.indexOf(candidateUsername.value);

        if (currentCandidateUsernameIndex !== -1) {
          candidateUsernameList.value = candidateUsernameList.value.filter(
            (username) => username !== candidateUsername.value
          );

          if (candidateUsernameList.value.length) {
            const nextCandidateUsernameIndex =
              currentCandidateUsernameIndex %
              candidateUsernameList.value.length;

            candidateUsername.value =
              candidateUsernameList.value[nextCandidateUsernameIndex];
          } else {
            isResumeModalOpen.value = false;
          }
        }
      }

      return component;
    });
  }

  onMounted(() => {
    if (!props.jobs.length) {
      noJobs.value = true;
      return;
    }
    setTimeout(() => {
      let navigatedToComponent = props.components.find(
        (c) => c.url === localRoute.hash
      );
      currentComponent.value =
        navigatedToComponent === undefined
          ? props.components[0]
          : navigatedToComponent;
      loadUsers(true);
    }, 0);
  });

  function renderComponent(url) {
    const component = props.components.find((c) => c.url === url);
    return component ? component.name : null;
  }

  function changeTab(changedToComponent) {
    currentComponent.value = changedToComponent;
    resetPagination();
    updateRoute();
    resetFilters();
    loadUsers(true);
  }

  const loadUsers = (refreshFilters) => {
    getUsers()
      .then((usersList) => {
        populateUserList(usersList, false, refreshFilters);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const loadMoreUsers = () => {
    if (!removed.value) {
      page.value += 1;
    }

    removed.value = false;

    getUsers()
      .then((usersList) => {
        populateUserList(usersList, true, false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const loadComponents = async () => {
    return await axios.post(
      route('redesign.company.dashboard.loadComponents', selectedJob.value.id),
      {
        component: currentComponent.value.name
      }
    );
  };
  const getUsers = async () => {
    return await axios.post(
      route('redesign.company.dashboard.loadMore', selectedJob.value.id),
      {
        page: page.value,
        component: currentComponent.value.name,
        filters: {
          location: locationFilter.value
        }
      }
    );
  };

  const getTotalForEachComponent = async () => {
    return await axios.get(
      route('redesign.company.dashboard.totalUsers', selectedJob.value.id)
    );
  };

  const populateUserList = (userData, isLoadMore = true, refreshFilters = true) => {
    //spread new people on existing if loading more
    if (isLoadMore) {
      const newPeople = userData.data.people.filter(
        (newPerson) =>
          !currentComponent.value.data.some(
            (existingPerson) => existingPerson.id === newPerson.id
          )
      );
      currentComponent.value.data = [
        ...currentComponent.value.data,
        ...newPeople
      ];
    } else {
      currentComponent.value.data = userData.data.people;
    }
    if (refreshFilters){
      componentFilters.value = userData.data.filters;
    }
    currentComponent.value.total = userData.data.total;
  };

  const filteredJobs = computed(() =>
    query.value === ''
      ? props.jobs
      : props.jobs.filter((job) => {
          return job.title.toLowerCase().includes(query.value.toLowerCase());
        })
  );

  watch(selectedJob, () => {
    resetPagination();
    loadComponents()
      .then((response) => {
        components.value.splice(0, components.value.length, ...response.data);
        currentComponent.value = components.value[0];
        updateRoute();
        loadUsers();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  });

  watch(selectedTabOnMobile, (newTab, oldTab) => {
    if (isMobile()) {
      if (newTab !== oldTab && blockInitialWatch.value === false) {
        changeTab(newTab);
      }
      blockInitialWatch.value = false;
    }
  });

  const isMobile = () => window.innerWidth <= 768;

  const updateRoute = () => {
    let jobId = selectedJob.value.id;
    componentRoute.push({
      name: 'managementDashboard',
      params: { jobId },
      hash: currentComponent.value.url
    });
  };

  const toggleFilter = (filter) => {
    locationFilter.value = filter;
    resetPagination();
    loadUsers(false);
  };

  const updateComponentTotal = (newTotal) => {
    currentComponent.value.total = newTotal;
  };

  const resetPagination = () => {
    page.value = 1;
  };

  const updateCounters = () => {
    countRemoved.value = countRemoved.value + 1;
    removed.value = true;
    if (countRemoved.value > props.per_page && page.value > 0) {
      page.value = page.value - 1;
      countRemoved.value = 0;
    }
  };

  const resetFilters = () => {
    locationFilter.value = currentComponent.value.filters[0].id;
    tabHasChanged.value = !tabHasChanged.value;
  };

  provide('tabHasChanged', tabHasChanged); // used in filters
  provide('selectedJob', selectedJob); // used in filters
  provide('componentFilters', componentFilters); // used in filters
  provide('loadUsers', loadUsers);
  provide('updateCounters', updateCounters);
  provide('updateComponentTotal', updateComponentTotal);
  provide('recruiter', props.recruiter);
</script>

<style>
  .hasEar::before {
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
  }
</style>
