<template>
  <div class="shadow-card rounded-lg bg-white px-4 py-6 md:px-6 md:py-8">
    <form @submit.prevent="handleSubmit">
      <ul v-if="values.length" class="mb-8 grid gap-6 md:grid-cols-2">
        <li
          v-for="(item, index) in values"
          :key="item"
          class="shadow-element rounded-lg px-2 py-3"
        >
          <div class="flex justify-end">
            <button
              class="-m-1 flex p-1.5 text-zinc-600"
              type="button"
              :aria-label="__('Delete')"
              @click="handleDeleteButtonClick(index)"
            >
              <Icon class="text-xl" name="close" />
            </button>
          </div>
          <div class="mt-2 grid grid-cols-2 gap-4">
            <InputSelect
              :id="`${index}.language`"
              v-model:value="item.language"
              v-model:errors="errors[`${index}.language`]"
              :label="__('Language')"
              :placeholder="__('Language')"
              required
              :options="options.languages"
            />
            <InputSelect
              :id="`${index}.proficiency`"
              v-model:value="item.proficiency"
              v-model:errors="errors[`${index}.proficiency`]"
              :label="__('Proficency')"
              :placeholder="__('Proficency')"
              required
              :options="options.proficiencies"
            />
          </div>
        </li>
      </ul>

      <Button
        class="flex items-center gap-1.5"
        variant="ghost"
        @click="handleAddNewButtonClick"
      >
        <Icon class="text-xl" name="add_box" is-filled />
        <span>{{ __('Add another language') }}</span>
      </Button>

      <span
        v-if="errorMessage"
        class="mt-8 block text-center text-sm text-error"
      >
        {{ errorMessage }}
      </span>

      <div
        class="mt-8 flex flex-col gap-2 md:flex-row-reverse md:justify-center"
      >
        <Button
          class="flex items-center justify-center gap-1.5"
          :type="isLoading ? 'button' : 'submit'"
          :disabled="isLoading"
        >
          <Icon
            v-if="isLoading"
            class="animate-spin text-lg"
            name="progress_activity"
          />
          <span>{{ __(isLoading ? 'Loading...' : 'Save') }}</span>
        </Button>

        <Button variant="ghost" @click="handleCancelButtonClick">
          <span>{{ __('Cancel') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import { __ } from '@/helpers';
  import { Button } from '@/components/block/button';
  import { Icon } from '@/components/block/icon';
  import { InputSelect } from '@/components/form/input-select';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  });

  const emits = defineEmits(['save', 'cancel']);

  const toast = useToast();

  const values = ref(JSON.parse(JSON.stringify(props.data)));
  const errors = ref({});
  const isLoading = ref(false);

  function handleAddNewButtonClick() {
    values.value.push({ language: null, proficiency: null });
  }

  function handleDeleteButtonClick(index) {
    values.value = values.value.filter((_, i) => i !== index);
    errors.value = {};
  }

  function handleCancelButtonClick() {
    emits('cancel');
  }

  async function handleSubmit() {
    const requiredFieldKeys = ['language', 'proficiency'];
    let firstElement;

    values.value.forEach((item, index) => {
      requiredFieldKeys.forEach((key) => {
        if (!item[key]) {
          errors.value[`${index}.${key}`] = [__('This field is required')];

          const element = document.getElementById(`${index}.${key}`);

          if (element && !firstElement) {
            firstElement = element;
          }
        }
      });
    });

    if (Object.values(errors.value).some((error) => error.length)) {
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    isLoading.value = true;

    try {
      const response = await axios.put(
        route('redesign.cv.update.languages'),
        values.value
      );

      if (response?.data?.message) {
        toast.success(response.data.message);
      }

      emits('save', {
        data: values.value,
        fields: response.data.fields,
        sectionPercentage: response.data.section_percentage,
        resumePercentage: response.data.percentage
      });
    } catch (error) {
      if (error?.response?.data?.errors) {
        errors.value = { ...errors.value, ...error.response.data.errors };
      } else {
        toast.error(
          __('An unexpected error has occured. Please try again later.')
        );
      }
    }

    isLoading.value = false;
  }

  onMounted(() => {
    if (!values.value.length) {
      values.value.push({ language: null, proficiency: null });
    }
  });
</script>
