<template>
  <button :style="`width: ${section.total}%`" @click="handleButtonClick">
    <div class="relative h-2 rounded-full bg-secondary/40">
      <div
        class="absolute h-full rounded-full bg-secondary"
        :style="`width: ${section.completed}%`"
      />
    </div>

    <div class="mt-1.5 flex items-center gap-1">
      <Icon
        v-if="section.completed === 100"
        class="text-success"
        name="done_all"
      />
      <span class="text-xs text-zinc-600">{{ section.name }}</span>
    </div>
  </button>
</template>

<script setup>
  import { Icon } from '@/components/block/icon';

  const props = defineProps({
    section: {
      type: Object,
      required: true
    }
  });

  function handleButtonClick() {
    const sectionElement = document.getElementById(props.section.id);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
</script>
