<template>
  <div>
    <div class="grid gap-4 md:grid-cols-2">
      <Country
        v-model:value="country"
        :options="options"
        @update:value="handleCountryUpdate"
      />
      <City
        v-model:value="city"
        :country="country"
        @update:value="handleCityUpdate"
      />
    </div>

    <div v-if="value.length" class="mt-2 flex flex-wrap gap-1">
      <button
        v-for="item in value"
        :key="item.id"
        :aria-label="__('Clear')"
        type="button"
        tabindex="0"
        class="flex items-center gap-1 rounded-lg border border-secondary px-2 py-1 text-sm text-zinc-800 transition-colors hover:bg-secondary/5"
        @click="handleDeleteButtonClick(item)"
      >
        <span>{{
          item.city
            ? `${item.city.name}, ${item.country.name}`
            : item.country.name
        }}</span>

        <Icon class="text-zinc-800" name="close" />
      </button>
    </div>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import { __ } from '@/helpers';
  import { Icon } from '@/components/block/icon';
  import { City } from './components/city';
  import { Country } from './components/country';

  const props = defineProps({
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    defaultCountry: {
      type: [Object, null],
      default: null
    }
  });

  const emits = defineEmits(['update:value', 'update:country']);

  const country = ref(props.defaultCountry);
  const city = ref(null);

  function handleCountryUpdate() {
    city.value = null;
  }

  function handleCityUpdate() {
    if (!props.value.find((item) => item.city.id === city.value.id)) {
      emits('update:value', [
        ...props.value,
        { country: country.value, city: city.value }
      ]);
    }

    country.value = null;
    city.value = null;
  }

  function handleDeleteButtonClick(value) {
    emits(
      'update:value',
      props.value.filter((item) => item.city.id !== value.city.id)
    );
  }

  watch(
    country,
    (value) => {
      emits('update:country', value);
    },
    { deep: true, immediate: true }
  );
</script>
