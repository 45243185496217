<template>
  <div class="container pb-40 sm:mt-10">
    <div :class="isSearchClicked == false ? 'm-auto md:w-2/4' : ''">
      <SearchCandidateResults
        v-if="isSearchClicked == true"
        :filterAvailability="filterAvailability"
        :filterContactDetails="filterContactDetails"
        :filterAbout="filterAbout"
        :degrees="degrees"
        :filterExperience="filterExperience"
        :skills="skills"
        :testedSkills="testedSkills"
        :languages="languages"
        :isRefineClicked="isRefineClicked"
        :candidates="candidates"
        :markMandatory="markMandatory"
        @switchMandatory="switchMandatory"
        @searchAgain="searchAgain"
        @updateCandidates="updateCandidates"
        :page="page"
        :available_pipelines="available_pipelines"
        :available_jobs="available_jobs"
        :config_general_single_company="config_general_single_company"
        :config_display_users_anonymously="config_display_users_anonymously"
        :is-loading="isLoading"
      />
      <!-- <div
        class="col-span-1 flex justify-center items-center"
        v-if="isSearchClicked == false"
      >
        <div
          class="w-1 bg-gray-200 h-full"
          v-if="isSearchClicked == false"
        ></div>
      </div> -->
      <SearchCandidateFilter
        :filterAvailability="filterAvailability"
        :filterAbout="filterAbout"
        :degrees="degrees"
        :filterExperience="filterExperience"
        :skills="skills"
        :testedSkills="testedSkills"
        :languages="languages"
        :isRefineClicked="isRefineClicked"
        :markMandatory="markMandatory"
        :filterContactDetails="filterContactDetails"
        :config_display_users_anonymously="config_display_users_anonymously"
        @switchMandatory="switchMandatory"
        @searchAgain="searchAgain"
        @updateCandidates="updateCandidates"
        @onsearch-click="onSearchClick(value)"
        v-if="isSearchClicked == false"
        class="col-span-5"
      />
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { SearchCandidateFilter, SearchCandidateResults } from './partials';
  import { __ } from '../../helpers';
  import axios from 'axios';
  import { useToast } from 'vue-toastification';

  const toast = useToast();

  // Define props
  const props = defineProps({
    abilities: {
      type: Array,
      required: true
    },
    availabilities: {
      type: Array,
      required: true
    },
    domains: {
      type: Array,
      required: true
    },
    currencies: {
      type: Array,
      required: true
    },
    salary_period: {
      type: Array,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    degrees: {
      type: Array,
      required: true
    },
    skills: {
      type: Array,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    proficiencies: {
      type: Object,
      required: true
    },
    available_pipelines: {
      type: Object,
      required: true
    },
    available_jobs: {
      type: Object,
      required: true
    },
    career_status: {
      type: Array,
      required: true
    },
    config_general_single_company: {
      type: Number,
      required: true
    },
    config_display_users_anonymously: {
      type: Number,
      required: true
    },
    country_codes: {
      type: Array,
      required: true
    },
    default_country: {
      type: Array,
      required: true
    },
    countries: {
      type: Array,
      required: true
    }
  });

  const isRefineClicked = ref(false);
  const isSearchClicked = ref(false);
  const markMandatory = ref(false);
  const isLoading = ref(false);

  // Create a function that receive an array of objects and return an array with the value of "name"
  // This is a helper function to convert the backend data to the format that the filter component needs
  const getValues = (array, field_name) => {
    // if field_name return an array of {"field_name": "value"}
    if (field_name) {
      return array.map((item) => {
        return { [field_name]: item[field_name] };
      });
    }

    // else return an array of values
    return array.map((item) => {
      return {
        name: item.name,
        id: item.id
      };
    });
  };

  const filterAvailability = ref([
    {
      availability: [
        {
          type: 'string',
          title: __('Availability'),
          value: null,
          mandatory: false,
          values: getValues(props.availabilities)
        },
        {
          type: 'string',
          title: __('Location'),
          value: null,
          mandatory: false,
          values: []
        },
        {
          type: 'string',
          title: __('Desired Wage'),
          value: null,
          mandatory: false
        },
        {
          type: 'string',
          title: __('Domain'),
          value: null,
          mandatory: false,
          values: getValues(props.domains)
        },
        {
          type: 'string',
          title: __('Currency'),
          value: null,
          mandatory: false,
          values: getValues(props.currencies)
        },
        {
          type: 'string',
          title: __('Salary type'),
          value: null,
          mandatory: false,
          values: getValues(props.salary_period)
        },
        {
          type: 'string',
          title: __('Departments'),
          value: null,
          mandatory: false,
          values: getValues(props.departments)
        },
        {
          type: 'country',
          title: __('Country'),
          value: null,
          mandatory: false,
          values: props.countries,
          defaultCountry: props.default_country
        }
      ]
    }
  ]);

  const degrees = ref([
    {
      degree: [
        {
          type: 'string',
          title: __('Degree'),
          value: null,
          mandatory: false,
          values: getValues(props.degrees)
        },
        {
          type: 'string',
          title: __('Field of study'),
          value: null,
          mandatory: false
        }
      ]
    }
  ]);

  const filterExperience = ref([
    {
      type: 'string',
      title: __('Years of experience'),
      mandatory: false,
      value: null
    }
  ]);

  const skills = ref([]);

  const testedSkills = ref([]);

  const languages = ref([]);

  const filterContactDetails = ref([
    {
      name: [
        {
          type: 'string',
          title: __('Name'),
          value: null,
          mandatory: false
        }
      ],
      email: [
        {
          type: 'string',
          title: __('Email'),
          value: null,
          mandatory: false
        }
      ],
      country_code: [
        {
          type: 'string',
          title: __('Country Code'),
          value: null,
          mandatory: false,
          values: props.country_codes
        }
      ],
      phone: [
        {
          type: 'string',
          title: __('Phone'),
          value: null,
          mandatory: false
        }
      ]
    }
  ]);

  const filterAbout = ref([
    {
      career_status: [
        {
          type: 'string',
          title: __('Career Status'),
          value: null,
          mandatory: false,
          values: getValues(props.career_status)
        }
      ],
      location: [
        {
          type: 'location',
          title: __('Location'),
          value: { city: null, country: null },
          mandatory: false,
          values: props.countries
        }
      ]
    }
  ]);

  function onSearchClick(value) {
    isSearchClicked.value = !value;
  }

  // Candidates

  // This is the global candidates array
  const candidates = ref([]);

  // This is the page number
  const page = ref(1);

  const updateCandidates = () => {
    // Check if experience is >= 0
    if (
      filterExperience.value[0].value < 0 &&
      filterExperience.value[0].value != null
    ) {
      toast.error(__('Experience must be greater than or equal to 0'));

      return;
    }

    // TODO: JP-285 modify

    // Create an array with all skills values
    const skillsValues = skills.value[0].value
      ? skills.value.map((item) => {
          return {
            name: item.value,
            mandatory: item.mandatory
          };
        })
      : null;

    // Create an array with all tested skills values
    const testedSkillsValues = testedSkills.value[0].value
      ? testedSkills.value.map((item) => {
          return {
            name: item.value,
            mandatory: item.mandatory
          };
        })
      : null;

    const languagesValues = languages.value[0].value
      ? languages.value.map((item) => {
          return {
            name: item.value,
            mandatoryLanguage: item.mandatory,
            proficiency: item.proficiency.value,
            mandatoryProficiency: item.proficiency.mandatory
          };
        })
      : null;

    // Make a GET request to the backend to get the candidates
    // With the specific params and page number

    isLoading.value = true;

    axios
      .get(route('search.results'), {
        params: {
          page: page.value,
          // Text values
          name: filterContactDetails.value[0].name[0].value,
          email: filterContactDetails.value[0].email[0].value,
          phone: filterContactDetails.value[0].phone[0].value,
          salary: filterAvailability.value[0].availability[2].value,
          'study-field': degrees.value[0].degree[1].value,

          // Object values
          degree: degrees.value[0].degree[0].value?.id,
          experience: filterExperience.value[0].value,
          availability: filterAvailability.value[0].availability[0].value?.id,
          country_code: filterContactDetails.value[0].country_code[0].value?.id,
          city: filterAvailability.value[0].availability[1].value?.id,
          domain: filterAvailability.value[0].availability[3].value?.id,
          department: filterAvailability.value[0].availability[6].value?.id,
          currency: filterAvailability.value[0].availability[4].value?.id,
          salary_type: filterAvailability.value[0].availability[5].value?.id,

          // Array values
          skills: skillsValues,
          abilities: testedSkillsValues,
          languages: languagesValues,

          // Mandatory values
          'switch-mandatory': markMandatory.value,
          'switch-availability':
            filterAvailability.value[0].availability[0].mandatory,
          'switch-name': filterContactDetails.value[0].name[0].mandatory,
          'switch-salary':
            filterAvailability.value[0].availability[2].mandatory,
          'switch-city': filterAvailability.value[0].availability[1].mandatory,
          'switch-domain':
            filterAvailability.value[0].availability[3].mandatory,
          'switch-degree': degrees.value[0].degree[0].mandatory,
          'switch-study-field': degrees.value[0].degree[1].mandatory,
          'switch-experience': filterExperience.value[0].mandatory
        }
      })
      .then((response) => {
        // Check if the response is empty
        if (response.data.length == 0) {
          isRefineClicked.value = true;
          page.value = -1;

          return;
        }

        // Add the new candidates to the global candidates array
        candidates.value = [...candidates.value, ...response.data];
        isRefineClicked.value = false;
        page.value++;
      })
      .catch((error) => {
        // Alert the user that there was an error
        // alert(error);
        console.log(error);
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const searchAgain = () => {
    // Check if experience is >= 0
    if (
      filterExperience.value[0].value < 0 &&
      filterExperience.value[0].value != null
    ) {
      toast.error(__('Experience must be greater than or equal to 0'));

      return;
    }

    // smooth go to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    // TODO: JP-285 modify

    // Create an array with all skills values
    const skillsValues = skills.value[0].value
      ? skills.value.map((item) => {
          return {
            name: item.value,
            mandatory: item.mandatory
          };
        })
      : null;

    // Create an array with all tested skills values
    const testedSkillsValues = testedSkills.value[0].value
      ? testedSkills.value.map((item) => {
          return {
            name: item.value,
            mandatory: item.mandatory
          };
        })
      : null;

    const languagesValues = languages.value[0].value
      ? languages.value.map((item) => {
          return {
            name: item.value,
            mandatoryLanguage: item.mandatory,
            proficiency: item.proficiency.value,
            mandatoryProficiency: item.proficiency.mandatory
          };
        })
      : null;

    // Add filters to URL
    // TODO: JP-285 modify
    // decomment this if you want get search params, but for now it's broken because of how data is structured
    //add_filters_to_URL(skillsValues, testedSkillsValues, languagesValues);

    isLoading.value = true;

    // Make a GET request to the backend to get the candidates
    axios
      .get(route('search.results'), {
        params: {
          page: 1,
          // Text values
          name: filterContactDetails.value[0].name[0].value,
          email: filterContactDetails.value[0].email[0].value,
          phone: filterContactDetails.value[0].phone[0].value,
          salary: filterAvailability.value[0].availability[2].value,
          'study-field': degrees.value[0].degree[1].value,

          // Object values
          degree: degrees.value[0].degree[0].value?.id,
          experience: filterExperience.value[0].value,
          availability: filterAvailability.value[0].availability[0].value?.id,
          country_code: filterContactDetails.value[0].country_code[0].value?.id,
          city: filterAvailability.value[0].availability[1].value?.id,
          domain: filterAvailability.value[0].availability[3].value?.id,
          department: filterAvailability.value[0].availability[6].value?.id,
          currency: filterAvailability.value[0].availability[4].value?.id,
          salary_type: filterAvailability.value[0].availability[5].value?.id,
          current_location: filterAbout.value[0].location[0].value?.id,
          career_status: filterAbout.value[0].career_status[0].value?.id,
          country: filterAvailability.value[0].availability[7].value?.id,
          // Array values
          skills: skillsValues,
          abilities: testedSkillsValues,
          languages: languagesValues,

          // Mandatory values
          'switch-mandatory': markMandatory.value,
          'switch-availability':
            filterAvailability.value[0].availability[0].mandatory,
          'switch-name': filterContactDetails.value[0].name[0].mandatory,
          'switch-email': filterContactDetails.value[0].email[0].mandatory,
          'switch-phone': filterContactDetails.value[0].phone[0].mandatory,
          'switch-country-code':
            filterContactDetails.value[0].country_code[0].mandatory,
          'switch-salary':
            filterAvailability.value[0].availability[2].mandatory,
          'switch-city': filterAvailability.value[0].availability[1].mandatory,
          'switch-domain':
            filterAvailability.value[0].availability[3].mandatory,
          'switch-degree': degrees.value[0].degree[0].mandatory,
          'switch-study-field': degrees.value[0].degree[1].mandatory,
          'switch-experience': filterExperience.value[0].mandatory,
          'switch-department':
            filterAvailability.value[0].availability[6].mandatory,
          'switch-currency':
            filterAvailability.value[0].availability[4].mandatory,
          'switch-salary-type':
            filterAvailability.value[0].availability[5].mandatory,
          'switch-current-location': filterAbout.value[0].location[0].mandatory,
          'switch-career-status':
            filterAbout.value[0].career_status[0].mandatory,
          'switch-country':
            filterAvailability.value[0].availability[7].mandatory
        }
      })
      .then((response) => {
        // Check if the response is empty
        if (response.data.length == 0) {
          candidates.value = [];
          isRefineClicked.value = true;
          page.value = -1;

          return;
        }

        // Add the new candidates to the global candidates array
        candidates.value = response.data;
        isRefineClicked.value = false;
        page.value = 2;
      })
      .catch((error) => {
        // Alert the user that there was an error
        // alert(error);
        console.log(error);
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const switchMandatory = () => {
    markMandatory.value = !markMandatory.value;
  };

  const add_filters_to_URL = (
    skillsValues,
    testedSkillsValues,
    languagesValues
  ) => {
    // Add all the filters to the URL
    const url = new URL(window.location.href);

    // clear the URL
    url.search = '';

    // Text values
    if (filterContactDetails.value[0].name[0].value) {
      url.searchParams.set('name', filterContactDetails.value[0].name[0].value);
    }

    if (filterContactDetails.value[0].email[0].value) {
      url.searchParams.set(
        'email',
        filterContactDetails.value[0].email[0].value
      );
    }

    if (filterContactDetails.value[0].phone[0].value) {
      url.searchParams.set(
        'phone',
        filterContactDetails.value[0].phone[0].value
      );
    }

    if (filterAvailability.value[0].availability[2].value) {
      url.searchParams.set(
        'salary',
        filterAvailability.value[0].availability[2].value
      );
    }

    if (degrees.value[0].degree[1].value) {
      url.searchParams.set('study-field', degrees.value[0].degree[1].value);
    }

    // Object values
    if (degrees.value[0].degree[0].value) {
      url.searchParams.set(
        'degree',
        JSON.stringify(degrees.value[0].degree[0].value)
      );
    }

    if (filterExperience.value[0].value) {
      url.searchParams.set(
        'experience',
        JSON.stringify(filterExperience.value[0].value)
      );
    }

    if (filterAvailability.value[0].availability[0].value) {
      url.searchParams.set(
        'availability',
        JSON.stringify(filterAvailability.value[0].availability[0].value)
      );
    }

    if (filterContactDetails.value[0].country_code[0].value) {
      url.searchParams.set(
        'country_code',
        JSON.stringify(filterContactDetails.value[0].country_code[0].value)
      );
    }

    if (filterAvailability.value[0].availability[1].value?.id) {
      url.searchParams.set(
        'city',
        JSON.stringify(filterAvailability.value[0].availability[1].value?.id)
      );
    }

    if (filterAvailability.value[0].availability[3].value) {
      url.searchParams.set(
        'domain',
        JSON.stringify(filterAvailability.value[0].availability[3].value)
      );
    }

    // Array values
    if (skillsValues) {
      url.searchParams.set('skills', JSON.stringify(skillsValues));
    }

    if (testedSkillsValues) {
      url.searchParams.set('abilities', JSON.stringify(testedSkillsValues));
    }

    if (languagesValues) {
      url.searchParams.set('languages', JSON.stringify(languagesValues));
    }

    // Mandatory values
    if (markMandatory.value) {
      url.searchParams.set('switch-mandatory', markMandatory.value);

      url.searchParams.set(
        'switch-availability',
        filterAvailability.value[0].availability[0].mandatory
      );

      url.searchParams.set(
        'switch-name',
        filterContactDetails.value[0].name[0].mandatory
      );
      url.searchParams.set(
        'switch-email',
        filterContactDetails.value[0].email[0].mandatory
      );
      url.searchParams.set(
        'switch-phone',
        filterContactDetails.value[0].phone[0].mandatory
      );
      url.searchParams.set(
        'switch-country-code',
        filterContactDetails.value[0].country_code[0].mandatory
      );

      url.searchParams.set(
        'switch-salary',
        filterAvailability.value[0].availability[2].mandatory
      );
      url.searchParams.set(
        'switch-city',
        filterAvailability.value[0].availability[1].mandatory
      );
      url.searchParams.set(
        'switch-domain',
        filterAvailability.value[0].availability[3].mandatory
      );
      url.searchParams.set(
        'switch-degree',
        degrees.value[0].degree[0].mandatory
      );
      url.searchParams.set(
        'switch-study-field',
        degrees.value[0].degree[1].mandatory
      );
      url.searchParams.set(
        'switch-experience',
        filterExperience.value[0].mandatory
      );
    }

    // Update the URL
    window.history.pushState({}, '', url);
  };

  // TODO: JP-285 modify
  const get_filters_from_URL = () => {
    // Get all the filters from the URL
    const url = new URL(window.location.href);
    const availability_url = url.searchParams.get('availability');
    const name_url = url.searchParams.get('name');
    const email_url = url.searchParams.get('email');
    const country_code_url = url.searchParams.get('country_code');
    const phone_url = url.searchParams.get('phone');
    const salary_url = url.searchParams.get('salary');
    const city_url = url.searchParams.get('city');
    const domain_url = url.searchParams.get('domain');
    const degree_url = url.searchParams.get('degree');
    const experience_url = url.searchParams.get('experience');
    const skills_url = url.searchParams.get('skills');
    const abilities_url = url.searchParams.get('abilities');
    const languages_url = url.searchParams.get('languages');
    const studyField_url = url.searchParams.get('study-field');
    // mandatory
    const switchMandatory_url = url.searchParams.get('switch-mandatory');
    const switchAvailability_url = url.searchParams.get('switch-availability');
    const switchName_url = url.searchParams.get('switch-name');
    const switchEmail_url = url.searchParams.get('switch-email');
    const switchPhone_url = url.searchParams.get('switch-phone');
    const switchCountryCode_url = url.searchParams.get(
      'switch-switch-country-code'
    );
    const switchSalary_url = url.searchParams.get('switch-salary');
    const switchCity_url = url.searchParams.get('switch-city');
    const switchDomain_url = url.searchParams.get('switch-domain');
    const switchDegree_url = url.searchParams.get('switch-degree');
    const switchStudyField_url = url.searchParams.get('switch-study-field');
    const switchExperience_url = url.searchParams.get('switch-experience');

    // Check if at least one filter is set using || operator
    const isFiltered =
      availability_url ||
      name_url ||
      email_url ||
      phone_url ||
      country_code_url ||
      salary_url ||
      city_url ||
      domain_url ||
      degree_url ||
      experience_url ||
      skills_url ||
      abilities_url ||
      languages_url ||
      studyField_url ||
      switchMandatory_url ||
      switchAvailability_url ||
      switchName_url ||
      switchEmail_url ||
      switchPhone_url ||
      switchCountryCode_url ||
      switchSalary_url ||
      switchCity_url ||
      switchDomain_url ||
      switchDegree_url ||
      switchStudyField_url ||
      switchExperience_url;

    // Set the filters
    filterAvailability.value[0].availability[0].value = availability_url;
    filterAvailability.value[0].availability[2].value = salary_url;
    filterAvailability.value[0].availability[1].value = city_url;
    filterAvailability.value[0].availability[3].value = domain_url;
    filterContactDetails.value[0].name[0].value = name_url;
    filterContactDetails.value[0].email[0].value = email_url;
    filterContactDetails.value[0].country_code[0].value = country_code_url;
    filterContactDetails.value[0].phone[0].value = phone_url;
    filterExperience.value[0].value = experience_url;
    degrees.value[0].degree[0].value = degree_url;
    degrees.value[0].degree[1].value = studyField_url;

    // mandatory
    markMandatory.value = Boolean(switchMandatory_url);
    if (switchMandatory_url) {
      filterAvailability.value[0].availability[0].mandatory = JSON.parse(
        switchAvailability_url
      );
      filterContactDetails.value[0].name[0].mandatory =
        JSON.parse(switchName_url);
      filterContactDetails.value[0].email[0].mandatory =
        JSON.parse(switchEmail_url);
      filterContactDetails.value[0].phone[0].mandatory =
        JSON.parse(switchPhone_url);
      filterContactDetails.value[0].country_code[0].mandatory = JSON.parse(
        switchCountryCode_url
      );
      filterAvailability.value[0].availability[2].mandatory =
        JSON.parse(switchSalary_url);
      filterAvailability.value[0].availability[1].mandatory =
        JSON.parse(switchCity_url);
      filterAvailability.value[0].availability[3].mandatory =
        JSON.parse(switchDomain_url);
      degrees.value[0].degree[0].mandatory = JSON.parse(switchDegree_url);
      degrees.value[0].degree[1].mandatory = JSON.parse(switchStudyField_url);
      filterExperience.value[0].mandatory = JSON.parse(switchExperience_url);
    }

    // TODO: JP-285 modify

    // Set the skills
    if (skills_url) {
      // set the skills
      const skillsArray = JSON.parse(skills_url);

      // for each skill in the array, add a new skill
      for (let i = 0; i < skillsArray.length; i++) {
        skills.value.push({
          type: 'string',
          value: skillsArray[i].name,
          mandatory: skillsArray[i].mandatory,
          values: getValues(props.skills)
        });
      }
    } else {
      // if there is no skills in the URL, add a new skill
      skills.value.push({
        type: 'string',
        value: null,
        mandatory: false,
        values: getValues(props.skills)
      });
    }

    // Set the tested skills
    if (abilities_url) {
      // set the skills
      const abilitiesArray = JSON.parse(abilities_url);

      // for each ability in the array, add a new ability
      for (let i = 0; i < abilitiesArray.length; i++) {
        testedSkills.value.push({
          type: 'string',
          value: abilitiesArray[i].name,
          mandatory: abilitiesArray[i].mandatory,
          values: getValues(props.abilities)
        });
      }
    } else {
      // if there is no abilities in the URL, add a new ability
      testedSkills.value.push({
        type: 'string',
        value: null,
        mandatory: false,
        values: getValues(props.abilities)
      });
    }

    // Set the languages
    if (languages_url) {
      const languagesArray = JSON.parse(languages_url);

      // for each language in the array, add a new language
      for (let i = 0; i < languagesArray.length; i++) {
        languages.value.push({
          type: 'language',
          value: languagesArray[i].name,
          mandatory: languagesArray[i].mandatoryLanguage,
          values: getValues(props.languages),
          proficiency: {
            value: languagesArray[i].proficiency,
            mandatory: languagesArray[i].mandatoryProficiency,
            values: Object.values(props.proficiencies)
          }
        });
      }
    } else {
      // if there is no languages in the URL, add a new language
      languages.value.push({
        type: 'language',
        value: null,
        mandatory: false,
        values: getValues(props.languages),
        proficiency: {
          value: null,
          mandatory: false,
          values: Object.values(props.proficiencies)
        }
      });
    }

    if (isFiltered) {
      isSearchClicked.value = true;
      searchAgain();
    }
  };

  onMounted(() => {
    // Get the filters from the URL
    get_filters_from_URL();
  });
</script>
